import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import SubCustomDropdown from '../Common/CustomDropdown';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {configureDefaultReports} from '../Functions/ConfigureDefaultReports';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';
import {calculateAge} from '../Functions/CalculateAge';
import {subExportStandardCSV} from '../Functions/ExportStandardCSV';

// const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const arrowIndicatorIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon-blue.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const dropdownArrow = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/dropdown-arrow.png";
const dropdownArrowDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/dropdown-arrow-dark.png";
const sortIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/sort-icon-dark.png";
const shiftIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/shift-icon-dark.png";
const deleteIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/delete-icon-dark.png";
const addIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png";
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const opportunitiesIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-blue.png";
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class Reporting extends Component {
    constructor(props) {
        super(props)

        this.state = {
          testingParticipationsReport: true,
          numberOfNests: 1,

          showModule: true,
          showModule2: true,
          showNumberedOptions: true,
          reportingResLimit: 8000,
          previewResLimit: 100,
          useTable: true,
          showMultilineCell: false,
          showChallengeVoting: true,

          customInputType: "dropdown",

          opacity: 0,
          transform: 'translate(90%)',

          reportOptions: [],
          timeframeOptions: [],
          functionOptions: ['All','Customer Service','Sales','Marketing','Partnerships','Mergers & Acquisitions','Operations','Strategy','Engineering','Product Management','Data Science','Finance','Design','Legal', 'Other'],
          trackOptions: [],
          challengeOptions: [],
          postingOptions: [{ title: 'All' }],
          workOptions: [{ title: 'All' }],
          projectOptions: [{ title: 'All' }],
          eventOptions: [{ title: 'All' }],
          employerOptions: [{ employerName: 'All' }],
          binaryOptions: ['','Yes','No'],
          staffMemberOptions: [],
          lessonNameOptions: [],
          ageOptions: [],
          operatorOptions: ['','Equal to','Includes','Less than','More than'],
          booleanOptions: ['','true','false'],
          moveInstruxOptions: ['','To the Top','After','To the Bottom'],

          selectedPosting: { title: 'All' },
          selectedWork: { title: 'All' },
          selectedProject: { name: 'All' },
          selectedEvent: { title: 'All' },
          selectedEmployer: { employerName: 'All' },
          selectedChallenge: { employerName: 'All' },

          timeframe: 'Since Inception',
          status: 'All',
          type: 'All',
          function: 'All',

          jobs: [],
          applications: [],
          assessments: [],
          benchmarks: [],
          signups: [],

          menuOptions: [],

          logs: [],

          newCSVMethod: true,

          errorMessage: ''
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)

        this.closeModal = this.closeModal.bind(this)
        this.renderOptions = this.renderOptions.bind(this)
        this.configurePreview = this.configurePreview.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)
        this.sortValues = this.sortValues.bind(this)
        this.moveItems = this.moveItems.bind(this)
        this.menuButtonClicked = this.menuButtonClicked.bind(this)

        this.exportReport = this.exportReport.bind(this)
        this.completeExport = this.completeExport.bind(this)
        this.saveReport = this.saveReport.bind(this)
        this.openReport = this.openReport.bind(this)
        this.deleteReport = this.deleteReport.bind(this)
        this.filterReport = this.filterReport.bind(this)
        this.deleteFilter = this.deleteFilter.bind(this)
        this.deleteColumn = this.deleteColumn.bind(this)
        this.renderEligibleFieldsArray = this.renderEligibleFieldsArray.bind(this)
        this.searchWorkPostings = this.searchWorkPostings.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in subReporting ', this.props.activeOrg, prevProps)

      if (this.props.org !== prevProps.org || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subReporting')

      const org = this.props.org
      const accountCode = this.props.accountCode

      //obtain email from localStorage
      let firstName = localStorage.getItem('firstName');
      let lastName = localStorage.getItem('lastName');
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');


      console.log('show orgFocus: ', orgFocus)

      if (email) {
        console.log('show email 1')

        const timeframe = 'Since Inception'
        const timeframe1 = timeframe
        let timeframe2 = timeframe1
        let timeframe3 = timeframe1
        let timeframe4 = timeframe1
        let timeframe5 = timeframe1

        // let timeframeOptions = ['','Since Inception','January','February','March','April','May','June','July','August','September','October','November','December']
        let timeframeOptions = ['Since Inception']

        let reportOptions = configureDefaultReports(this.state.showModule)

        let ageOptions = ['']
        for (let i = 1; i <= 85; i++) {
          ageOptions.push(i + 15)
        }

        let menuOptions = [
          { icon: sortIconDark, title: 'Sort', shorthand: 'sort' },
          { icon: shiftIconDark, title: 'Move', shorthand: 'move' },
          { icon: deleteIconDark, title: 'Delete', shorthand: 'delete' },
        ]

        let customInputType = "search"
        // let customInputType = "dropdown"

        this.setState({ org, accountCode, firstName, lastName, emailId: email, username, orgFocus, timeframe,
          timeframe1, timeframe2, timeframe3, timeframe4, timeframe5, timeframeOptions, reportOptions,
          ageOptions, menuOptions, customInputType
        })

        if (org) {

          Axios.get('/api/reports', { params: { orgCode: org } })
          .then((response) => {
             console.log('Reports query attempted', response.data);
              if (response.data.success && response.data.reports) {
                console.log('reports query worked')

                if (reportOptions) {
                  reportOptions.shift()
                  reportOptions = response.data.reports.concat(reportOptions)
                  reportOptions.unshift({ name: '' })

                  for (let i = 1; i <= reportOptions.length; i++) {
                    if (this.state.testingParticipationsReport && reportOptions[i - 1].name === 'UNITE-LA Participations Report') {
                      reportOptions[i - 1]['shortname'] = 'participations'
                    }
                  }
                  // reportOptions = reportOptions.splice(1,0,response.data.reports)
                  this.setState({ reportOptions })
                }

              } else {
                console.log('reports query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('Reports query did not work for some reason', error);
          });

          Axios.get('/api/org', { params: { orgCode: org } })
          .then((response) => {
            console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const orgFocus = response.data.orgInfo.orgFocus
              let departmentOptions = null
              if (response.data.orgInfo.departments && response.data.orgInfo.departments.length > 0) {
                departmentOptions = [{ name: 'All'}].concat(response.data.orgInfo.departments)
              }
              const placementPartners = response.data.orgInfo.placementPartners
              this.setState({ orgFocus, departmentOptions, placementPartners })

              const sortAlphabetically = true
              let resLimit = 250
              Axios.get('/api/account/partners', { params: { org, placementPartners, resLimit, sortAlphabetically } })
              .then((response) => {
                console.log('Posted employer query attempted', response.data);

                if (response.data.success) {
                  console.log('posted employer query worked')

                  if (response.data.employers.length !== 0) {
                    // const employerOptions = response.data.employers

                    let employerOptions = [{ employerName: 'All' }]
                    employerOptions = employerOptions.concat(response.data.employers)
                    this.setState({ employerOptions });
                  }

                }

              }).catch((error) => {
                  console.log('Employer query did not work for some reason', error);
              });

            } else {
              console.log('org info query did not work', response.data.message)

            }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });

          Axios.get('/api/pathways', { params: { orgCode: org } })
          .then((response) => {
             console.log('Pathways query attempted', response.data);
              if (response.data.success && response.data.pathways && response.data.pathways.length > 0) {
                console.log('pathways query worked')

                const pathwayOptions = [{ title: 'All'}].concat(response.data.pathways)
                this.setState({ pathwayOptions })

              } else {
                console.log('pathways query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('Pathways query did not work for some reason', error);
          });

          if (this.state.showChallengeVoting && org === 'c2c') {
            Axios.get('/api/challenges', { params: { orgCode: org, postType: 'Challenge' } })
            .then((response) => {
              console.log('Posting challenge query attempted', response.data);

              if (response.data.success) {
                console.log('posting query worked')

                let challengeOptions = response.data.challenges

                if (challengeOptions && challengeOptions.length > 0) {
                  const selectedChallenge = challengeOptions[0]
                  this.setState({ challengeOptions, selectedChallenge })
                }

              } else {
                console.log('org info query did not work', response.data.message)

              }

            }).catch((error) => {
                console.log('Org info query did not work for some reason', error);
            });
          }

          let postingsQueryObject = {}
          if (window.location.pathname.includes('/employers') && accountCode) {
            postingsQueryObject = { accountCode, roleName: 'Admin' }
          } else if (window.location.pathname.includes('/organizations')){
            postingsQueryObject = { orgCode: org, pullPartnerPosts: true, resLimit: 1000, roleName: 'Admin' }
          }
          // console.log('show postingsQueryObject: ', postingsQueryObject)
          if (postingsQueryObject.orgCode || postingsQueryObject.accountCode) {
            Axios.get('/api/postings/user', { params: postingsQueryObject })
            .then((response) => {
              console.log('Posted jobs query attempted', response.data);

                if (response.data.success) {
                  console.log('posted jobs query worked')

                  if (response.data.postings.length !== 0) {

                    let postingOptions = [{ title: 'All' }]
                    let workOptions = [{ title: 'All' }]
                    let projectOptions = [{ name: 'All' }]
                    let eventOptions = [{ title: 'All' }]

                    postingOptions = postingOptions.concat(response.data.postings)
                    for (let i = 1; i <= response.data.postings.length; i++) {
                      if (response.data.postings[i - 1].postType === 'Work' || response.data.postings[i - 1].postType === 'Internship' || response.data.postings[i - 1].postType === 'Individual') {
                        workOptions.push(response.data.postings[i - 1])
                      } else if (response.data.postings[i - 1].postType === 'Project' || response.data.postings[i - 1].postType === 'Assignment' || response.data.postings[i - 1].postType === 'Problem' || response.data.postings[i - 1].postType === 'Challenge') {
                        projectOptions.push(response.data.postings[i - 1])
                      } else if (response.data.postings[i - 1].postType === 'Event') {
                        eventOptions.push(response.data.postings[i - 1])
                      }
                    }

                    //jobs.push(response.data.postings[0])
                    this.setState({ postingOptions, workOptions, projectOptions, eventOptions });
                  }

                } else {
                  console.log('posted jobs query did not work', response.data.message)
                }

            }).catch((error) => {
                console.log('Posted jobs query did not work for some reason', error);
            });
          }

          let caQueryObject = { orgCode: org }
          if (accountCode) {
            caQueryObject = { accountCode }
          }

          Axios.get('/api/customassessments', { params: caQueryObject })
          .then((response) => {
            console.log('Custom assessment query attempted', response.data);

            if (response.data.success) {
              console.log('custom assessment query worked')

              if (response.data.assessments.length !== 0) {

                let assessments = response.data.assessments
                let assessmentOptions = [{ title: '' }].concat(response.data.assessments)
                let adversityListOptions = null
                for (let i = 1; i <= assessments.length; i++) {
                  if (assessments[i - 1].questions && assessments[i - 1].questions.length > 0) {
                    for (let j = 1; j <= assessments[i - 1].questions.length; j++) {
                      if (assessments[i - 1].questions[j - 1].shortname === 'adversityList') {
                        adversityListOptions = assessments[i - 1].questions[j - 1].answerChoices
                      }
                    }
                  }
                }

                this.setState({ assessments, assessmentOptions, adversityListOptions });
              }

            } else {
              console.log('custom assessment query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Posted jobs query did not work for some reason', error);
          });

          const roleNames = ['Admin']
          Axios.get('/api/org/members', { params: { orgCode: org, roleNames } })
          .then((response) => {

            if (response.data.success) {
              console.log('Member query worked', response.data);

              if (response.data.members && response.data.members.length > 0) {
                const staffMemberObjectOptions = response.data.members
                let staffMemberOptions = [""]
                for (let i = 1; i <= response.data.members.length; i++) {
                  if (response.data.members[i - 1] && !staffMemberOptions.includes(response.data.members[i - 1].firstName + " " + response.data.members[i - 1].lastName)) {
                    staffMemberOptions.push(response.data.members[i - 1].firstName + " " + response.data.members[i - 1].lastName)
                  }
                }
                this.setState({ staffMemberObjectOptions, staffMemberOptions })
              }

            } else {
              console.log('no members found', response.data.message)
            }

          }).catch((error) => {
              console.log('Members query did not work', error);
          });

          Axios.get('/api/lessons', { params: { orgCode: org } })
          .then((response) => {

            if (response.data.success) {
              console.log('Lessons query worked', response.data);

              let lessonNameOptions = ['']
              if (response.data.lessons && response.data.lessons.length > 0) {
                for (let i = 1; i <= response.data.lessons.length; i++) {
                  if (response.data.lessons[i - 1].name) {
                    lessonNameOptions.push(response.data.lessons[i - 1].name)
                  }
                }

                this.setState({ lessonNameOptions })
              }

            } else {
              console.log('no lessons found', response.data.message)
            }

          }).catch((error) => {
              console.log('Lessons query did not work', error);
          });
        }

      }

      if (window.innerWidth > 768) {
        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)
      } else {
        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)
      }
    }

    configurePreview(csvData) {
      console.log('configurePreview called')


      this.setState({ previewData: csvData, isLoading: false}, () => {
        // console.log('show header width: ', document.getElementById("pth|0").clientWidth)
        // console.log('show first row width: ', document.getElementById("ptd|1|0").clientWidth)
        // console.log('show first row data: ', document.getElementById("ptd0"))
        // "ptd|" index + "|" + index2
        // console.log('S5: format cells')

        let widestWidths = []
        let tallestHeights = []
        let showMultilineCell = this.state.showMultilineCell
        for (let i = 1; i <= csvData.length; i++) {
          if (csvData[i - 1]) {
            const rowIndex = i - 1

            for (let j = 1; j <= csvData[rowIndex].length; j++) {
              const cellIndex = j - 1

              if (document.getElementById("pth|" + cellIndex.toString())) {
                document.getElementById("pth|" + cellIndex.toString()).style.width = 'auto'
                if (showMultilineCell) {
                  document.getElementById("pth|" + cellIndex.toString()).style.height = 'auto'
                }
              }
              if (document.getElementById("ptd|" + rowIndex.toString() + "|" + cellIndex.toString())) {
                document.getElementById("ptd|" + rowIndex.toString() + "|" + cellIndex.toString()).style.width = 'auto'
                if (showMultilineCell) {
                  document.getElementById("ptd|" + rowIndex.toString() + "|" + cellIndex.toString()).style.height = 'auto'
                }
              }

              if (csvData[rowIndex][cellIndex]) {
                if (rowIndex === 0) {

                  if (document.getElementById("pth|" + cellIndex.toString()).clientWidth) {

                    const cellWidth = document.getElementById("pth|" + cellIndex.toString()).clientWidth
                    if ((!widestWidths[cellIndex]) || (cellWidth > widestWidths[cellIndex])) {
                      widestWidths[cellIndex] = cellWidth
                    }
                  }
                  if (showMultilineCell && document.getElementById("pth|" + cellIndex.toString()).clientHeight) {
                    const cellHeight = document.getElementById("pth|" + cellIndex.toString()).clientHeight
                    if ((!tallestHeights[cellIndex]) || (cellHeight > tallestHeights[cellIndex])) {
                      tallestHeights[cellIndex] = cellHeight
                    }
                  }
                } else {
                  if (document.getElementById("ptd|" + rowIndex.toString() + "|" + cellIndex.toString()).clientWidth) {
                    const cellWidth = document.getElementById("ptd|" + rowIndex.toString() + "|" + cellIndex.toString()).clientWidth
                    // console.log('cellWidth: ', cellWidth)
                    if ((!widestWidths[cellIndex]) || (cellWidth > widestWidths[cellIndex])) {
                      widestWidths[cellIndex] = cellWidth
                    }
                  }

                  if (showMultilineCell && document.getElementById("ptd|" + rowIndex.toString() + "|" + cellIndex.toString()).clientHeight) {
                    const cellHeight = document.getElementById("ptd|" + rowIndex.toString() + "|" + cellIndex.toString()).clientHeight
                    // console.log('cellHeight: ', cellHeight)
                    if ((!tallestHeights[cellIndex]) || (cellHeight > tallestHeights[cellIndex])) {
                      tallestHeights[cellIndex] = cellHeight
                    }
                  }
                }
              }
            }
          }
        }

        for (let i = 1; i <= widestWidths.length; i++) {
          // console.log('show widestWidth: ', i, widestWidths[i - 1])
          const widthIndex = i - 1
          const selectedWidth = widestWidths[widthIndex] + 10 // add 10 buffer

          if (document.getElementById("pth|" + widthIndex.toString())) {
            document.getElementById("pth|" + widthIndex.toString()).style.width = selectedWidth + 'px'
          }

          for (let i = 1; i <= csvData.length; i++) {
            const rowIndex = i - 1
            if (rowIndex || rowIndex === 0) {
              if (document.getElementById("ptd|" + rowIndex.toString() + "|" + widthIndex.toString())) {
                document.getElementById("ptd|" + rowIndex.toString() + "|" + widthIndex.toString()).style.width = selectedWidth + 'px'
              }
            }
          }
        }

        if (showMultilineCell) {
          for (let i = 1; i <= tallestHeights.length; i++) {
            console.log('show tallestHeight: ', i, tallestHeights[i - 1])
            const heightIndex = i - 1
            const selectedHeight = tallestHeights[heightIndex] + 10 // add 10 buffer

            // if (document.getElementById("pth|" + heightIndex.toString())) {
            //   document.getElementById("pth|" + heightIndex.toString()).style.height = selectedHeight + 'px'
            // }

            for (let i = 1; i <= csvData.length; i++) {
              const rowIndex = i - 1
              if (rowIndex || rowIndex === 0) {
                if (document.getElementById("ptd|" + rowIndex.toString() + "|" + heightIndex.toString())) {
                  document.getElementById("ptd|" + rowIndex.toString() + "|" + heightIndex.toString()).style.height = selectedHeight + 'px'
                }
              }
            }
          }
        }

        // console.log('S6: cells formatted')
      });
    }

    completeExport(reportName, csvData, reachedLimit) {
      console.log('completeExport called', reportName, reachedLimit)

      let csvContent = "data:text/csv;charset=utf-8,";

      if (this.state.newCSVMethod) {

        let csvRows = []
        let headers = csvData[0]
        csvRows.push(headers.join(','))
        console.log('about to loop')
        for (const row of csvData) {
          const values = headers.map((header, index) => {

            if (row[index]) {

              const escaped = (''+row[index]).replace(/"/g,'\\"')
              const literalEscaped = `"${escaped}"`
              // console.log('gimme data point: ', index, escaped, literalEscaped, row[index])
              // console.log('show escaped: ', index, literalEscaped)
              return literalEscaped
            } else {
              // console.log('undefined value')
              const undefinedAlt = " "
              return undefinedAlt
            }
          })

          let newRow = values.join(',')
          // console.log('excuse me: ', newRow)
          // newRow = newRow.replace(/;;;/g,",")
          // console.log("que???", typeof newRow)
          // if (newRow && newRow.length > 0) {
          //   console.log("que paso???", typeof newRow)
          //   for (let i = 1; i <= newRow.length; i++) {
          //     if (newRow[i - 1] && newRow[i - 1].includes(";;;")) {
          //       console.log('hmm?', newRow[i - 1])
          //       newRow[i - 1] = newRow[i - 1].replace(/;;;/g,",")
          //     }
          //   }
          // }

          csvRows.push(newRow)
          csvContent += newRow + "\r\n";

          // console.log('show rows: ', csvContent)
        }
        console.log('finished looping')
      } else {

        csvData.forEach(function(rowArray){
           let row = rowArray.join(",");
           csvContent += row + "\r\n";
        });
        console.log('show rows: ', csvContent)
      }

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", reportName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      let errorMessage = null
      if (reachedLimit) {
        errorMessage = "You have reached the " + this.state.reportingResLimit + " reporting limit. All data exceeding " + this.state.reportingResLimit + " will be excluded."
      }

      this.setState({ isLoading: false, errorMessage})
    }

    exportReport = async(reportType, previewMode, passedName, passedValue, passedObject2) => {
    // exportReport(reportType, previewMode, passedName, passedValue, passedObject2) {
      console.log('exportReport called', reportType, previewMode, passedName, passedValue, passedObject2)

      this.setState({ errorMessage: '', isLoading: true })
      this.closeModal()

      if (passedName === 'selectedReport') {
        reportType = passedValue
      }

      let reportName = this.state.selectedReportName
      let selectedReportObject = this.state.selectedReportObject
      if (passedName === 'selectedReport' && passedObject2) {
        reportName = passedObject2.value.name
        selectedReportObject = passedObject2.value
      }

      let resLimit = this.state.reportingResLimit
      if (previewMode) {
        resLimit = this.state.previewResLimit
      }

      const orgCode = this.state.org
      const placementPartners = this.state.placementPartners

      let department = this.state.department
      if (passedName === 'department') {
        department = passedValue
      }

      let pathway = this.state.pathway
      if (passedName === 'pathway') {
        pathway = passedValue
      }

      let accountCode = this.state.accountCode
      let selectedEmployer = this.state.selectedEmployer
      if (passedName === 'selectedEmployer') {
        accountCode = passedValue
      }

      let olderThan = this.state.olderThan
      if (passedName === 'olderThan') {
        olderThan = passedValue
      }

      let youngerThan = this.state.youngerThan
      if (passedName === 'youngerThan') {
        youngerThan = passedValue
      }

      let afterDate = new Date(this.state.afterDate)
      if (passedName === 'afterDate') {
        afterDate = passedValue
      }

      let beforeDate = new Date(this.state.beforeDate)
      if (passedName === 'beforeDate') {
        beforeDate = passedValue
      }

      let assignedStaff = this.state.assignedStaff
      if (passedName === 'assignedStaff') {
        assignedStaff = passedValue
      }

      let assignedStaffEmail = null
      let assignedStaffName = this.state.assignedStaff
      if (passedName === 'assignedStaffName') {
        assignedStaffName = passedValue
      }

      if (assignedStaff) {
        for (let i = 1; i <= this.state.staffMemberObjectOptions.length; i++) {

          if (this.state.staffMemberObjectOptions[i - 1]) {
            const fullName = this.state.staffMemberObjectOptions[i - 1].firstName + ' ' + this.state.staffMemberObjectOptions[i - 1].lastName
            if (fullName === assignedStaff) {
              assignedStaffEmail = this.state.staffMemberObjectOptions[i - 1].email
            }
          }
        }
      }

      let selectedChallenge = this.state.selectedChallenge
      if (passedName === 'selectedChallenge') {
        selectedChallenge = passedValue
      }

      let selectedPosting = this.state.selectedPosting
      if (passedName === 'selectedPosting') {
        selectedPosting = passedValue
      }

      let selectedWork = this.state.selectedWork
      if (passedName === 'selectedWork') {
        selectedWork = passedValue
      }

      let selectedProject = this.state.selectedProject
      if (passedName === 'selectedProject') {
        selectedProject = passedValue
      }

      let selectedEvent = this.state.selectedEvent
      if (passedName === 'selectedEvent') {
        selectedEvent = passedValue
      }

      let approved = this.state.approved
      if (passedName === 'approved') {
        approved = passedValue
      }

      let surveyName = this.state.surveyName
      if (passedName === 'surveyName') {
        surveyName = passedValue
      }

      let lessonName = this.state.lessonName
      if (passedName === 'lessonName') {
        lessonName = passedValue
      }

      let reportFilters = this.state.reportFilters
      if (passedName === 'customFilters') {
        reportFilters = passedValue
      }

      let sortCriteria = this.state.sortCriteria
      if (passedName === 'sortCriteria') {
        sortCriteria = passedValue
      }

      let reportOptions = this.state.reportOptions
      if (passedName === 'sortCriteria') {
        reportOptions = passedValue
      }

      const logType = "Goal"


      if (passedName === 'selectedReport') {

        surveyName = selectedReportObject.surveyName
        lessonName = selectedReportObject.lessonName
        selectedEvent = { title: 'All' }
        if (selectedReportObject.selectedEvent) {
          selectedEvent = selectedReportObject.selectedEvent
        }
        selectedProject = { title: 'All' }
        if (selectedReportObject.selectedProject) {
          selectedProject = selectedReportObject.selectedProject
        }
        selectedWork = { title: 'All' }
        if (selectedReportObject.selectedWork) {
          selectedWork = selectedReportObject.selectedWork
        }
        selectedEmployer = { title: 'All' }
        if (selectedReportObject.selectedEmployer) {
          selectedEmployer = selectedReportObject.selectedEmployer
        }
        // selectedAssignment = null
        selectedChallenge = { title: 'All' }
        if (selectedReportObject.selectedChallenge) {
          selectedEmployer = selectedReportObject.selectedEmployer
        }
        selectedPosting = { title: 'All' }
        if (selectedReportObject.selectedPosting) {
          selectedPosting = selectedReportObject.selectedPosting
        }

        approved = selectedReportObject.approved
        assignedStaffName = selectedReportObject.assignedStaffName
        assignedStaffEmail = selectedReportObject.assignedStaffEmail
        olderThan = selectedReportObject.olderThan
        youngerThan = selectedReportObject.youngerThan
        department = selectedReportObject.department
        pathway = selectedReportObject.pathway

        afterDate = undefined
        if (selectedReportObject.afterDate) {
          afterDate = selectedReportObject.afterDate
        }
        beforeDate = undefined
        if (selectedReportObject.beforeDate) {
          beforeDate = selectedReportObject.beforeDate
        }

        reportFilters = selectedReportObject.reportFilters
        sortCriteria = selectedReportObject.sortCriteria

        if (!window.location.pathname.startsWith('/employers')) {
          accountCode = null
        }

        this.setState({
          surveyName, lessonName, selectedEvent, selectedProject,
          selectedWork, selectedEmployer, selectedChallenge, selectedPosting,
          approved, assignedStaffName, assignedStaffEmail, olderThan, youngerThan,
          department, pathway, afterDate, beforeDate, reportFilters, sortCriteria,
          accountCode
        })

      }

      let errorMessage = 'There is no data to export'

      // console.log('show afterDate: ', afterDate, typeof afterDate, new Date(afterDate))

      let csvData = []
      let fields = null

      if (this.state.showModule) {

        if (this.state.showModule2) {

          const componentState = this.state
          const payload = { reportOptions, reportName, passedName, passedValue, fields, reportType, orgCode, placementPartners, surveyName, lessonName, selectedEvent, selectedProject, selectedWork, approved, logType, assignedStaffName, assignedStaffEmail, olderThan, youngerThan, department, pathway, accountCode, afterDate, beforeDate, reportFilters, sortCriteria, resLimit, csvData, componentState }
          const returnedData = await subExportStandardCSV(payload)
          // console.log('S3: received data')
          if (!returnedData.success) {
            this.setState(returnedData.stateObject)
          } else {
            if (previewMode) {
              // console.log('S4: about to configurePreview')
              this.configurePreview(returnedData.csvData)
            } else {
              this.completeExport(this.state.selectedReportShortname + '-report.csv', returnedData.csvData, returnedData.reachedLimit)
            }
          }

        } else {
          let selectedReport = null
          let headerArray = null
          for (let i = 1; i <= reportOptions.length; i++) {
            if (reportOptions[i - 1].name === reportName) {
              selectedReport = reportOptions[i - 1]
              if (selectedReport && selectedReport.fields) {
                fields = selectedReport.fields
                if (passedName === 'fields') {
                  fields = passedValue
                }
                headerArray = []
                for (let j = 1; j <= fields.length; j++) {
                  headerArray.push(fields[j - 1].name)
                }
              }
            }
          }

          const payload = {
            reportType, orgCode, placementPartners, surveyName, lessonName, selectedEvent, selectedProject,
            selectedWork, approved, logType, assignedStaffName, assignedStaffEmail, olderThan, youngerThan,
            department, pathway, accountCode, afterDate, beforeDate, reportFilters, sortCriteria, resLimit
          }

          Axios.put('/api/reporting/general', payload)
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked in showModule', response.data);

                csvData.push(headerArray)

                let tempData = csvData[0]
                // tempData = tempData.concat(["App Requirements","Application Method","Job Link","Subject Line","Pathways","Departments"])
                // csvData[0] = tempData

                const pullIndividuals = true
                let tempDataArray = response.data[reportType]
                if (reportType === 'placements' && pullIndividuals) {
                  let individuals = []
                  for (let i = 1; i <= tempDataArray.length; i++) {
                    if (tempDataArray[i - 1] && tempDataArray[i - 1].workers) {
                      for (let j = 1; j <= tempDataArray[i - 1].workers.length; j++) {

                        let keys = Object.keys(tempDataArray[i - 1])
                        let values = Object.values(tempDataArray[i - 1])
                        let objectPairs = []

                        if (keys && keys.length > 0) {

                          for (let l = 1; l <= keys.length; l++) {
                            if (keys[l - 1] && keys[i - 1] !== 'workers') {
                              objectPairs.push({ key: keys[l - 1], value: values[l - 1]})
                            }
                          }
                        }

                        let workerKeys = Object.keys(tempDataArray[i - 1].workers[j - 1])
                        let workerValues = Object.values(tempDataArray[i - 1].workers[j - 1])
                        if (workerKeys && workerKeys.length > 0) {
                          for (let l = 1; l <= workerKeys.length; l++) {
                            if (workerKeys[l - 1]) {
                              objectPairs.push({ key: workerKeys[l - 1], value: workerValues[l - 1]})
                            }
                          }
                        }

                        let worker = {}
                        for (let l = 1; l <= objectPairs.length; l++) {
                          worker[objectPairs[l - 1].key] = objectPairs[l - 1].value
                        }

                        individuals.push(worker)
                      }
                    }
                  }
                  // console.log('length: ', individualPlacements.length, individualPlacements[0])
                  tempDataArray = individuals
                }

                let reportDataArray = tempDataArray
                // if (reportType === 'postings') {
                //   reportDataArray = response.data.postings
                // }
                  // console.log('what is the deal', reportDataArray)

                let surveyIndex = null

                if (reportDataArray && reportDataArray.length > 0) {

                  for (let i = 1; i <= reportDataArray.length; i++) {
                    // console.log(i, 'data element')

                    let commaStartingValue = /,/g
                    let commaReplacementValue = ","
                    if (previewMode) {
                      commaReplacementValue = ","
                    }

                    let dataArray = []
                    for (let j = 1; j <= fields.length; j++) {
                      let dataField = ' - '

                      let fieldType = fields[j - 1].type
                      let fieldShortname = fields[j - 1].shortname

                      if (fields[j - 1].altCondition) {
                        if (fields[j - 1].altCondition.split("|")[1] === 'ne' && fields[j - 1][fields[j - 1].altCondition.split("|")[0]] === fields[j - 1].altCondition.split("|")[2]) {
                          fieldType = fields[j - 1].altType
                          fieldShortname = fields[j - 1].altShortname
                        }
                      }

                      if (fieldType === 'String') {
                        dataField = reportDataArray[i - 1][fieldShortname]
                        if (dataField) {

                          dataField = dataField.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
                        }
                      } else if (fieldType === 'Number') {
                        dataField = reportDataArray[i - 1][fieldShortname]
                      } else if (fieldType === 'Boolean') {
                        if (reportDataArray[i - 1][fieldShortname]) {
                          dataField = 'YES'
                        } else {
                          dataField = 'NO'
                        }
                      } else if (fieldType === 'Age') {
                        if (reportDataArray[i - 1][fieldShortname]) {
                          dataField = calculateAge(reportDataArray[i - 1][fieldShortname])
                        }
                      } else if (fieldType === 'Splitter') {
                        dataField = reportDataArray[i - 1][fieldShortname]
                        if (dataField) {
                          const index = Number(dataField.split("|")[1])
                          dataField = dataField[index].trim().replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                        }
                      } else if (fieldType === 'Count') {
                        if (reportDataArray[i - 1][fieldShortname]) {
                          dataField = reportDataArray[i - 1][fieldShortname].length
                        }
                      } else if (fieldType === 'Date') {
                        if (reportDataArray[i - 1][fieldShortname]) {
                          dataField = convertDateToString(new Date(reportDataArray[i - 1][fieldShortname]),"datetime-2")
                        }
                      } else if (fieldType === 'Stringify') {
                        if (reportDataArray[i - 1][fieldShortname]) {
                          dataField = JSON.stringify(reportDataArray[i - 1][fieldShortname]).replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                        }
                      } else if (fieldType === 'Simple Join') {
                        if (reportDataArray[i - 1][fieldShortname] && reportDataArray[i - 1][fieldShortname].length > 0) {
                          for (let l = 1; l <= reportDataArray[i - 1][fieldShortname].length; l++) {
                            const item = reportDataArray[i - 1][fieldShortname][l - 1]
                            if (item) {
                              if (dataField === ' - ') {
                                dataField = item.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                              } else {
                                dataField = dataField + commaReplacementValue + ' ' + item.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                              }
                            }
                          }
                        }
                      } else if (fieldType === 'Object Join') {
                        if (reportDataArray[i - 1][fieldShortname] && reportDataArray[i - 1][fieldShortname].length > 0) {
                          for (let l = 1; l <= reportDataArray[i - 1][fieldShortname].length; l++) {
                            const item = reportDataArray[i - 1][fieldShortname][l - 1]
                            if (item) {
                              if (dataField === ' - ') {
                                dataField = JSON.stringify(item).replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                              } else {
                                dataField = dataField + commaReplacementValue + ' ' + JSON.stringify(item).replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                              }
                            }
                          }
                        }
                      } else if (fieldType === 'Splitter Join') {
                        const aShortname = fieldShortname.split("|")[0]
                        const bShortname = fieldShortname.split("|")[1]
                        const cShortname = fieldShortname.split("|")[2]

                        if (reportDataArray[i - 1][aShortname] && reportDataArray[i - 1][aShortname].length > 0) {
                          for (let l = 1; l <= reportDataArray[i - 1][aShortname].length; l++) {
                            const item = reportDataArray[i - 1][aShortname][l - 1]
                            if (item && item[bShortname]) {
                              let dataItem = item[bShortname].toString()
                              if (cShortname) {
                                dataItem = item[bShortname] + "-" + item[cShortname]
                              }
                              if (dataField === ' - ') {
                                dataField = dataItem.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                              } else {
                                dataField = dataField + commaReplacementValue + ' ' + dataItem.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                              }
                            }
                          }
                        }
                      } else if (fieldType === 'Object') {

                        const aShortname = fieldShortname.split("|")[0]
                        const bShortname = fieldShortname.split("|")[1]

                        if (reportDataArray[i - 1][aShortname]) {
                          if (reportDataArray[i - 1][aShortname] && reportDataArray[i - 1][aShortname][bShortname]) {
                            if (typeof reportDataArray[i - 1][aShortname][bShortname] === 'object') {
                              let keys = Object.keys(reportDataArray[i - 1][aShortname][bShortname])
                              let values = Object.values(reportDataArray[i - 1][aShortname][bShortname])

                              if (keys && keys.length > 0) {
                                for (let l = 1; l <= keys.length; l++) {
                                  if (keys[l - 1]) {
                                    let dataItem = keys[l - 1] + "-" + values[l - 1]

                                    if (dataField === ' - ') {
                                      dataField = dataItem.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                                    } else {
                                      dataField = dataField + commaReplacementValue + ' ' + dataItem.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                                    }
                                  }
                                }
                              }
                            } else {
                              // add to data
                              dataField = reportDataArray[i - 1][aShortname][bShortname]

                            }
                          } else {
                            // add to data
                          }
                        }
                      } else if (fieldType === 'Survey') {
                        console.log('qin survey')
                        if (reportDataArray[i - 1][fieldShortname] && reportDataArray[i - 1][fieldShortname].length > 0) {
                          for (let l = 1; l <= reportDataArray[i - 1][fieldShortname].length; l++) {
                            if (reportDataArray[i - 1][fieldShortname][l - 1]) {

                              const questionName = 'Q-' + reportDataArray[i - 1][fieldShortname][l - 1].question.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," | ").replace(/\n/g," | ").replace(/#/g,"")

                              if (!csvData[0].includes(questionName)) {
                                if (l === 1) {

                                  for (let m = 1; m <= csvData[0].length; m++) {
                                    if (csvData[0][m - 1] === fields[j - 1].name) {
                                      csvData[0].splice(m - 1,1)
                                    }
                                  }

                                  csvData[0].push(questionName)
                                } else {
                                  // csvData[0].splice(surveyIndex + 1,0,questionName)
                                  // surveyIndex + 1
                                  csvData[0].push(questionName)
                                }
                              }

                              if (!reportDataArray[i - 1][fieldShortname][l - 1].answer) {
                                console.log('Z0', csvData, csvData.length )
                                const result = " - "
                                if (l === reportDataArray[i - 1][fieldShortname].length) {
                                  dataField = result
                                } else {
                                  dataArray.push(result)
                                }
                              } else if (typeof reportDataArray[i - 1][fieldShortname][l - 1].answer === 'string') {
                                console.log('Z1', csvData, csvData.length )

                                const result = reportDataArray[i - 1][fieldShortname][l - 1].answer.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," | ").replace(/\n/g," | ").replace(/#/g,"")

                                if (l === reportDataArray[i - 1][fieldShortname].length) {
                                  dataField = result
                                } else {
                                  dataArray.push(result)
                                }

                              } else {
                                console.log('Z2', reportDataArray[i - 1][fieldShortname][l - 1])
                                const result = reportDataArray[i - 1][fieldShortname][l - 1].answer.join().replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," | ").replace(/\n/g," | ").replace(/#/g,"")

                                if (l === reportDataArray[i - 1][fieldShortname].length) {
                                  dataField = result
                                } else {
                                  dataArray.push(result)
                                }

                                if (result === undefined || result === null) {
                                  console.log('result is undefined or null 1')
                                }
                              }
                            }
                          }
                        } else {
                          // delete the header at this index
                          // csvData[0].splice(i - 1,1)

                          if (i === 1 && fields.some(field => field.type === 'Multiple Answers Transposed')) {
                            const tIndex = fields.findIndex(field => field.type === 'Multiple Answers Transposed')
                            const tempShortname = fields[tIndex].shortname
                            const optionShortname = tempShortname + "Options"
                            if (this.state[optionShortname] && this.state[optionShortname].length > 0) {
                              const headerName = csvData[0][j - 1]
                              csvData[0].splice(j - 1,1)
                              csvData[0].push(headerName)
                            }
                          }

                          // headerIndicesToDelete.push(j - 1)
                          console.log('what it do: ', i - 1, j - 1, csvData[0][j - 1], fieldType, fieldShortname)

                        }

                      } else if (fieldType === 'Multiple Answers Transposed') {
                        // console.log("J: ", i, )
                        const optionShortname = fieldShortname + "Options"

                        if (i === 1) {
                          if (this.state[optionShortname] && this.state[optionShortname].length > 0) {
                            for (let l = 1; l <= csvData[0].length; l++) {
                              if (csvData[0][l - 1] === fields[j - 1].name) {
                                csvData[0][l - 1] = csvData[0][l - 1].replace(fields[j - 1].name,this.state[optionShortname][0])
                              }
                            }
                            for (let l = 1; l <= this.state[optionShortname].length; l++) {
                              if (l > 1) {
                                csvData[0].push(this.state[optionShortname][l - 1])
                              }
                            }
                          }
                        }

                        if (this.state[optionShortname] && this.state[optionShortname].length > 0) {
                          for (let l = 1; l <= this.state[optionShortname].length; l++) {
                            console.log("SSDFHJ", this.state[optionShortname][l - 1])
                            if (reportDataArray[i - 1][fieldShortname] && reportDataArray[i - 1][fieldShortname].includes(this.state[optionShortname][l - 1])) {
                              if (l === this.state[optionShortname].length) {
                                dataField = "TRUE"
                              } else {
                                dataArray.push("TRUE")
                              }
                            } else {
                              if (l === this.state[optionShortname].length) {
                                dataField = "FALSE"
                              } else {
                                dataArray.push("FALSE")
                              }
                            }
                          }
                        }
                      }

                      dataArray.push(dataField)

                    }

                    csvData.push(dataArray)

                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  if (previewMode) {
                    this.configurePreview(csvData)
                  }
                  this.setState({ errorMessage: 'There is no data to export', isLoading: false })
                }

              } else {
                console.log('no report data found', response.data.message)

                if (previewMode) {
                  this.configurePreview(csvData)
                }
                this.setState({ errorMessage, isLoading: false})
              }
          }).catch((error) => {
              console.log('Report query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage: 'There is no data to export', isLoading: false})
          });
        }

      } else {

        if (reportType === 'postings') {

          console.log('pull postings')

          // const payload = { params: { reportType, orgCode }}
          const payload = { reportType, orgCode, placementPartners, department, pathway, accountCode, afterDate, beforeDate, resLimit }
          Axios.put('/api/reporting/general', payload)
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "Post Id","Post Type","Title","Featured","Function","Location","Application Components","Application Count","Has Custom Assessment","Status","Description","Hours per Week", "Employer Name","Industry","Employer Type","Employer URL","Employer Employees","Created At","Updated At","Employer Contact First Name","Employer Contact Last Name","Employer Contact Email","Employer Contact Phone","Contributor Email", "Contributor First Name","Contributor Last Name","Contributor Title", "Video Link","Submission Count","Resources","Difficulty Level","Summary","Employer Info","Guidelines","Exhibit Count","Lessons","Disable Downvoting","Submission Deadline","Announcement Date","Evaluation Method","Rules","Event Type","Start Date","End Date","Location","Survey ID", "Will Employer Lead","Location Type","Transportation Details","Org Contact First Name","Org Contact Last Name","Org Contact Email","Org Name"]
                )

                let tempData = csvData[0]
                tempData = tempData.concat(["App Requirements","Application Method","Job Link","Subject Line","Pathways","Departments"])
                csvData[0] = tempData

                const postings = response.data.postings
                if (postings && postings.length > 0) {
                  for (let i = 1; i <= postings.length; i++) {
                    console.log(i, postings[i - 1])

                    //replace commas
                    let commaStartingValue = /,/g
                    let commaReplacementValue = ";"

                    //calculate values
                    let postType = postings[i - 1].postType
                    if (postType === 'Internship' || postType === 'Individual' || postType === 'Track' || postType === 'Work') {

                      postType = 'Internship'
                      const _id = postings[i - 1]._id
                      // console.log('show desc', description)
                      let title = postings[i - 1].title.replace(commaStartingValue,commaReplacementValue)

                      let featured = 'NO'
                      if (postings[i - 1].featured) {
                        featured = 'YES'
                      }

                      let location = postings[i - 1].location.replace(commaStartingValue, commaReplacementValue)
                      let status = 'Inactive'
                      if (postings[i - 1].isActive) {
                        status = 'Active'
                      }

                      let fieldArray = postings[i - 1].field.split("|")
                      let functionValue = ''
                      if (fieldArray[0]) {
                        functionValue = fieldArray[0].trim()
                      }

                      let industry = ''
                      if (fieldArray[1]) {
                        industry = fieldArray[1].trim().replace(commaStartingValue, commaReplacementValue)
                      }

                      let employerType = ''
                      if (postings[i - 1].employerType) {
                        employerType = postings[i - 1].employerType
                      }

                      let employerURL = ''
                      if (postings[i - 1].employerURL) {
                        employerURL = postings[i - 1].employerURL
                      }

                      let employerName = ''
                      if (postings[i - 1].employerName) {
                        employerName = postings[i - 1].employerName.replace(commaStartingValue,commaReplacementValue)
                      }

                      let applicationComponents = postings[i - 1].applicationComponents.join(commaReplacementValue)

                      let applicationCount = 0
                      if (postings[i - 1].applications) {
                        applicationCount = postings[i - 1].applications.length
                      }

                      let hasCustomAssessment = false
                      if (postings[i - 1].customAssessmentId && postings[i - 1].customAssessmentId !== '') {
                        hasCustomAssessment = true
                      }

                      let description = ''
                      if (postings[i - 1].description) {
                        description = postings[i - 1].description.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let hoursPerWeek = postings[i - 1].hoursPerWeek

                      let employerEmployees = ''
                      if (postings[i - 1].employerEmployees) {
                        employerEmployees = postings[i - 1].employerEmployees.replace(commaStartingValue,commaReplacementValue)
                      }

                      const createdAt = postings[i - 1].createdAt
                      const updatedAt = postings[i - 1].updatedAt

                      const employerContactFirstName = postings[i - 1].employerContactFirstName
                      const employerContactLastName = postings[i - 1].employerContactLastName
                      const employerContactEmail = postings[i - 1].employerContactEmail
                      const employerContactPhone = postings[i - 1].employerContactPhone

                      let posterEmail = postings[i - 1].posterEmail

                      csvData.push([
                        _id, postType, title, featured,
                        functionValue, location, applicationComponents,
                        applicationCount, hasCustomAssessment,
                        status, description, hoursPerWeek, employerName, industry,
                        employerType, employerURL, employerEmployees,
                        createdAt, updatedAt,
                        employerContactFirstName, employerContactLastName, employerContactEmail, employerContactPhone,
                        posterEmail,
                      ])

                      let appRequirements = ''
                      if (postings[i - 1].appRequirements) {
                        appRequirements = postings[i - 1].appRequirements.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let applicationMethod = ''
                      if (postings[i - 1].applicationMethod) {
                        applicationMethod = postings[i - 1].applicationMethod.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let jobLink = ''
                      if (postings[i - 1].jobLink) {
                        jobLink = postings[i - 1].jobLink.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let subjectLine = ''
                      if (postings[i - 1].subjectLine) {
                        subjectLine = postings[i - 1].subjectLine.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let pathways = ''
                      if (postings[i - 1].pathways && postings[i - 1].pathways.length > 0) {
                        for (let j = 1; j <= postings[i - 1].pathways.length; j++) {
                          if (postings[i - 1].pathways[j - 1] && postings[i - 1].pathways[j - 1].title) {
                            if (pathways === '') {
                              pathways = postings[i - 1].pathways[j - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            } else {
                              pathways = pathways + '; ' + postings[i - 1].pathways[j - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            }
                          }
                        }
                      }

                      let departments = ''
                      if (postings[i - 1].departments) {
                        for (let j = 1; j <= postings[i - 1].departments.length; j++) {
                          if (postings[i - 1].departments[j - 1] && postings[i - 1].departments[j - 1].name) {
                            if (departments === '') {
                              departments = postings[i - 1].departments[j - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            } else {
                              departments = departments + '; ' + postings[i - 1].departments[j - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            }
                          }
                        }
                      }

                      // .concat([appRequirements,applicationMethod,jobLink,subjectLine])
                      let tempData2 = csvData[csvData.length - 1]
                      tempData2[csvData[0].length - 6] = appRequirements
                      tempData2[csvData[0].length - 5] = applicationMethod
                      tempData2[csvData[0].length - 4] = jobLink
                      tempData2[csvData[0].length - 3] = subjectLine
                      tempData2[csvData[0].length - 2] = pathways
                      tempData2[csvData[0].length - 1] = departments

                      csvData[csvData.length - 1] = tempData2

                    } else if (postType === 'Assignment' || postType === 'Problem' || postType === 'Challenge') {

                      const _id = postings[i - 1]._id
                      // console.log('show desc', description)
                      let title = postings[i - 1].name.replace(commaStartingValue,commaReplacementValue)

                      let featured = 'NO'
                      if (postings[i - 1].featured) {
                        featured = 'YES'
                      }

                      let location = 'N/A'
                      let status = 'Active'
                      if (postings[i - 1].status === 'Inactive') {
                        status = 'Inactive'
                      }

                      let functionValue = ''
                      if (postings[i - 1].functions) {
                        for (let j = 1; j <= postings[i - 1].functions.length; j++) {
                          functionValue = functionValue + postings[i - 1].functions[j - 1].replace(commaStartingValue,commaReplacementValue) + ' '
                        }
                      }

                      let industry = ''
                      if (postings[i - 1].industry) {
                        industry = postings[i - 1].industry.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let employerType = ''
                      if (postings[i - 1].employerType) {
                        employerType = postings[i - 1].employerType.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let employerURL = ''
                      if (postings[i - 1].employerURL) {
                        employerURL = postings[i - 1].employerURL.replace(commaStartingValue,commaReplacementValue)
                      }

                      let employerName = ''
                      if (postings[i - 1].employerName) {
                        employerName = postings[i - 1].employerName.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let applicationComponents = 'N/A'
                      let applicationCount = 'N/A'
                      let hasCustomAssessment = 'N/A'

                      let description = ''
                      if (postings[i - 1].description) {
                        description = postings[i - 1].description.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let hoursPerWeek = 'N/A'
                      let employerEmployees = ''
                      if (postings[i - 1].employerSize) {
                        employerEmployees = postings[i - 1].employerSize.replace(commaStartingValue,commaReplacementValue)
                      }

                      const createdAt = postings[i - 1].createdAt
                      const updatedAt = postings[i - 1].updatedAt

                      const employerContactFirstName = 'N/A'
                      const employerContactLastName = 'N/A'
                      const employerContactEmail = 'N/A'
                      const employerContactPhone = 'N/A'

                      let posterEmail = postings[i - 1].contributorEmail

                      let contributorFirstName = ''
                      if (postings[i - 1].contributorFirstName) {
                        contributorFirstName = postings[i - 1].contributorFirstName
                      }
                      let contributorLastName = ''
                      if (postings[i - 1].contributorLastName) {
                        contributorLastName = postings[i - 1].contributorLastName
                      }
                      let contributorTitle = ''
                      if (postings[i - 1].contributorTitle) {
                        contributorTitle = postings[i - 1].contributorTitle
                      }

                      let videoLink = ''
                      if (postings[i - 1].videoLink) {
                        videoLink = postings[i - 1].videoLink
                      }

                      let submissionCount = 0
                      if (postings[i - 1].submissionProjectIds) {
                        submissionCount = postings[i - 1].submissionProjectIds.length
                      }

                      let resources = ''
                      if (postings[i - 1].resources) {
                        for (let j = 1; j <= postings[i - 1].resources.length; j++) {
                          resources =  resources + postings[i - 1].resources[j - 1].replace(commaStartingValue,commaReplacementValue).replace(/\n/g,"").replace(/#/g,"") + ' '
                        }
                      }

                      let difficultyLevel = ''
                      if (postings[i - 1].difficultyLevel) {
                        difficultyLevel = postings[i - 1].difficultyLevel
                      }

                      let summary = ''
                      if (postings[i - 1].summary) {
                        summary = postings[i - 1].summary.replace(commaStartingValue,commaReplacementValue).replace(/\n/g,"").replace(/#/g,"")
                      }

                      let employerInfo = ''
                      if (postings[i - 1].employerInfo) {
                        employerInfo = postings[i - 1].employerInfo.replace(commaStartingValue,commaReplacementValue).replace(/\n/g,"").replace(/#/g,"")
                      }

                      let guidelines = ''
                      if (postings[i - 1].guidelines) {
                        guidelines = postings[i - 1].guidelines.replace(commaStartingValue,commaReplacementValue).replace(/\n/g,"").replace(/#/g,"")
                      }

                      let exhibitCount = ''
                      if (postings[i - 1].exhibits) {
                        exhibitCount = postings[i - 1].exhibits.length
                      }

                      let lessons = ''
                      if (postings[i - 1].lessons) {
                        lessons = postings[i - 1].lessons.replace(commaStartingValue,commaReplacementValue).replace(/\n/g,"").replace(/#/g,"")
                      }

                      let disableDownvoting = false
                      if (postings[i - 1].disableDownvoting) {
                        disableDownvoting = postings[i - 1].disableDownvoting
                      }

                      let submissionDeadline = ''
                      let announcementDate = ''
                      let evaluationMethod = ''
                      let rules = ''
                      if (postType === 'Challenge') {
                        if (postings[i - 1].submissionDeadline) {
                          submissionDeadline = postings[i - 1].submissionDeadline
                        }

                        if (postings[i - 1].announcementDate) {
                          announcementDate = postings[i - 1].announcementDate
                        }

                        if (postings[i - 1].evaluationMethod) {
                          evaluationMethod = postings[i - 1].evaluationMethod.replace(commaStartingValue,commaReplacementValue).replace(/\n/g,"").replace(/#/g,"")
                        }

                        if (postings[i - 1].rules) {
                          rules = postings[i - 1].rules.replace(commaStartingValue,commaReplacementValue).replace(/\n/g,"").replace(/#/g,"")
                        }
                      }

                      csvData.push([
                        _id, postType, title, featured,
                        functionValue, location, applicationComponents,
                        applicationCount, hasCustomAssessment,
                        status, description, hoursPerWeek, employerName, industry,
                        employerType, employerURL, employerEmployees,
                        createdAt, updatedAt,
                        employerContactFirstName, employerContactLastName, employerContactEmail, employerContactPhone,
                        posterEmail,
                        contributorFirstName, contributorLastName, contributorTitle,
                        videoLink, submissionCount, resources, difficultyLevel, summary, employerInfo, guidelines,
                        exhibitCount, lessons, disableDownvoting,
                        submissionDeadline, announcementDate, evaluationMethod, rules
                      ])

                      let pathways = ''
                      if (postings[i - 1].pathways && postings[i - 1].pathways.length > 0) {
                        for (let j = 1; j <= postings[i - 1].pathways.length; j++) {
                          if (postings[i - 1].pathways[j - 1] && postings[i - 1].pathways[j - 1].title) {
                            if (pathways === '') {
                              pathways = postings[i - 1].pathways[j - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            } else {
                              pathways = pathways + '; ' + postings[i - 1].pathways[j - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            }
                          }
                        }
                      }

                      let departments = ''
                      if (postings[i - 1].departments) {
                        for (let j = 1; j <= postings[i - 1].departments.length; j++) {
                          if (postings[i - 1].departments[j - 1] && postings[i - 1].departments[j - 1].name) {
                            if (departments === '') {
                              departments = postings[i - 1].departments[j - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            } else {
                              departments = departments + '; ' + postings[i - 1].departments[j - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            }
                          }
                        }
                      }

                      // .concat([appRequirements,applicationMethod,jobLink,subjectLine])
                      let tempData2 = csvData[csvData.length - 1]
                      tempData2[csvData[0].length - 2] = pathways
                      tempData2[csvData[0].length - 1] = departments

                      csvData[csvData.length - 1] = tempData2

                    } else if (postType === 'Event') {

                      const _id = postings[i - 1]._id
                      // console.log('show desc', description)
                      let title = postings[i - 1].title.replace(commaStartingValue,commaReplacementValue)
                      let featured = 'NO'
                      if (postings[i - 1].featured) {
                        featured = 'YES'
                      }

                      let location = 'N/A'
                      let status = 'N/A'

                      let functionValue = 'N/A'
                      if (postings[i - 1].functions) {
                        for (let j = 1; j <= postings[i - 1].functions.length; j++) {
                          functionValue = functionValue + postings[i - 1].functions[j - 1].replace(commaStartingValue,commaReplacementValue) + ' '
                        }
                      }

                      let industry = 'N/A'
                      if (postings[i - 1].industry) {
                        industry = postings[i - 1].industry.replace(commaStartingValue,commaReplacementValue)
                      }

                      let employerType = 'N/A'
                      if (postings[i - 1].employerType) {
                        employerType = postings[i - 1].employerType.replace(commaStartingValue,commaReplacementValue)
                      }

                      let employerURL = 'N/A'
                      if (postings[i - 1].employerURL) {
                        employerURL = postings[i - 1].employerURL.replace(commaStartingValue,commaReplacementValue)
                      }

                      let employerName = 'N/A'
                      if (postings[i - 1].employerName) {
                        employerName = postings[i - 1].employerName.replace(commaStartingValue,commaReplacementValue)
                      }

                      let applicationComponents = 'N/A'
                      let applicationCount = 'N/A'
                      let hasCustomAssessment = 'N/A'

                      let description = ''
                      if (postings[i - 1].description) {
                        description = postings[i - 1].description.replace(commaStartingValue,commaReplacementValue).replace(/\n/g,"")
                      }

                      let hoursPerWeek = 'N/A'
                      let employerEmployees = 'N/A'

                      const createdAt = postings[i - 1].createdAt
                      const updatedAt = postings[i - 1].updatedAt

                      const employerContactFirstName = 'N/A'
                      const employerContactLastName = 'N/A'
                      const employerContactEmail = 'N/A'
                      const employerContactPhone = 'N/A'

                      let posterEmail = postings[i - 1].posterEmail

                      let contributorFirstName = 'N/A'
                      let contributorLastName = 'N/A'
                      let contributorTitle = 'N/A'
                      let videoLink = 'N/A'
                      let submissionCount = 'N/A'
                      let resources = 'N/A'
                      let difficultyLevel = 'N/A'
                      let summary = 'N/A'
                      let employerInfo = 'N/A'
                      let guidelines = 'N/A'
                      let exhibitCount = 'N/A'
                      let lessons = 'N/A'

                      let disableDownvoting = 'N/A'

                      let submissionDeadline = ''
                      let announcementDate = ''
                      let evaluationMethod = ''
                      let rules = ''

                      let eventType = ''
                      if (postings[i - 1].eventType) {
                        eventType = postings[i - 1].eventType
                      }

                      // let summary = ''
                      // if (postings[i - 1].summary) {
                      //   summary = postings[i - 1].summary
                      // }

                      let startDate = ''
                      if (postings[i - 1].startDate) {
                        startDate = postings[i - 1].startDate
                      }

                      let endDate = ''
                      if (postings[i - 1].endDate) {
                        endDate = postings[i - 1].endDate
                      }
                      //
                      // let location = ''
                      // if (postings[i - 1].location) {
                      //   location = postings[i - 1].location
                      // }

                      let surveyId = ''
                      if (postings[i - 1].surveyId) {
                        surveyId = postings[i - 1].surveyId
                      }

                      let willEmployerLead = ''
                      if (postings[i - 1].willEmployerLead) {
                        willEmployerLead = postings[i - 1].willEmployerLead.replace(commaStartingValue,commaReplacementValue).replace(/\n/g,"")
                      }

                      let locationType = ''
                      if (postings[i - 1].locationType) {
                        locationType = postings[i - 1].locationType
                      }

                      let transportationDetails = ''
                      if (postings[i - 1].transportationDetails) {
                        transportationDetails = postings[i - 1].transportationDetails.replace(commaStartingValue,commaReplacementValue).replace(/\n/g,"")
                      }

                      let orgContactFirstName = ''
                      if (postings[i - 1].orgContactFirstName) {
                        orgContactFirstName = postings[i - 1].orgContactFirstName
                      }

                      let orgContactLastName = ''
                      if (postings[i - 1].orgContractLastName) {
                        orgContactLastName = postings[i - 1].orgContactLastName
                      }

                      let orgContactEmail = ''
                      if (postings[i - 1].orgContactEmail) {
                        orgContactEmail = postings[i - 1].orgContactEmail
                      }

                      let orgName = ''
                      if (postings[i - 1].orgName) {
                        orgName = postings[i - 1].orgName.replace(commaStartingValue,commaReplacementValue).replace(/\n/g,"")
                      }

                      csvData.push([
                        _id, postType, title, featured,
                        functionValue, location, applicationComponents,
                        applicationCount, hasCustomAssessment,
                        status, description, hoursPerWeek, employerName, industry,
                        employerType, employerURL, employerEmployees,
                        createdAt, updatedAt,
                        employerContactFirstName, employerContactLastName, employerContactEmail, employerContactPhone,
                        posterEmail,
                        contributorFirstName, contributorLastName, contributorTitle,
                        videoLink, submissionCount, resources, difficultyLevel, summary, employerInfo, guidelines,
                        exhibitCount, lessons, disableDownvoting,
                        submissionDeadline, announcementDate, evaluationMethod, rules,
                        eventType, startDate, endDate, location, surveyId,
                        willEmployerLead, locationType, transportationDetails,
                        orgContactFirstName, orgContactLastName, orgContactEmail, orgName
                      ])

                      let pathways = ''
                      if (postings[i - 1].pathways && postings[i - 1].pathways.length > 0) {
                        for (let j = 1; j <= postings[i - 1].pathways.length; j++) {
                          if (postings[i - 1].pathways[j - 1] && postings[i - 1].pathways[j - 1].title) {
                            if (pathways === '') {
                              pathways = postings[i - 1].pathways[j - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            } else {
                              pathways = pathways + '; ' + postings[i - 1].pathways[j - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            }
                          }
                        }
                      }

                      let departments = ''
                      if (postings[i - 1].departments) {
                        for (let j = 1; j <= postings[i - 1].departments.length; j++) {
                          if (postings[i - 1].departments[j - 1] && postings[i - 1].departments[j - 1].name) {
                            if (departments === '') {
                              departments = postings[i - 1].departments[j - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            } else {
                              departments = departments + '; ' + postings[i - 1].departments[j - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            }
                          }
                        }
                      }

                      // .concat([appRequirements,applicationMethod,jobLink,subjectLine])
                      let tempData2 = csvData[csvData.length - 1]
                      tempData2[csvData[0].length - 2] = pathways
                      tempData2[csvData[0].length - 1] = departments

                      csvData[csvData.length - 1] = tempData2

                    } else if (postType === 'Scholarship') {

                      const _id = postings[i - 1]._id
                      // console.log('show desc', description)
                      let title = postings[i - 1].title.replace(commaStartingValue,commaReplacementValue)

                      let featured = 'NO'
                      if (postings[i - 1].featured) {
                        featured = 'YES'
                      }

                      let location = ''

                      let status = 'Inactive'
                      if (postings[i - 1].isActive) {
                        status = 'Active'
                      }

                      // let fieldArray = postings[i - 1].field.split("|")
                      let functionValue = ''
                      // if (fieldArray[0]) {
                      //   functionValue = fieldArray[0].trim()
                      // }

                      let industry = ''
                      // if (fieldArray[1]) {
                      //   industry = fieldArray[1].trim().replace(commaStartingValue, commaReplacementValue)
                      // }

                      let employerType = ''
                      if (postings[i - 1].employerType) {
                        employerType = postings[i - 1].employerType
                      }

                      let employerURL = ''
                      if (postings[i - 1].employerURL) {
                        employerURL = postings[i - 1].employerURL
                      }

                      let employerName = ''
                      if (postings[i - 1].employerName) {
                        employerName = postings[i - 1].employerName.replace(commaStartingValue,commaReplacementValue)
                      }

                      let applicationComponents = ''

                      let applicationCount = 0
                      if (postings[i - 1].applications) {
                        applicationCount = postings[i - 1].applications.length
                      }

                      let hasCustomAssessment = false
                      if (postings[i - 1].customAssessmentId && postings[i - 1].customAssessmentId !== '') {
                        hasCustomAssessment = true
                      }

                      let description = ''
                      if (postings[i - 1].description) {
                        description = postings[i - 1].description.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let hoursPerWeek = ''
                      let employerEmployees = ''

                      const createdAt = postings[i - 1].createdAt
                      const updatedAt = postings[i - 1].updatedAt

                      const employerContactFirstName = ''
                      const employerContactLastName = ''
                      const employerContactEmail = ''
                      const employerContactPhone = ''

                      let posterEmail = postings[i - 1].posterEmail

                      csvData.push([
                        _id, postType, title, featured,
                        functionValue, location, applicationComponents,
                        applicationCount, hasCustomAssessment,
                        status, description, hoursPerWeek, employerName, industry,
                        employerType, employerURL, employerEmployees,
                        createdAt, updatedAt,
                        employerContactFirstName, employerContactLastName, employerContactEmail, employerContactPhone,
                        posterEmail,
                      ])

                      let pathways = ''
                      if (postings[i - 1].pathways && postings[i - 1].pathways.length > 0) {
                        for (let j = 1; j <= postings[i - 1].pathways.length; j++) {
                          if (postings[i - 1].pathways[j - 1] && postings[i - 1].pathways[j - 1].title) {
                            if (pathways === '') {
                              pathways = postings[i - 1].pathways[j - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            } else {
                              pathways = pathways + '; ' + postings[i - 1].pathways[j - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            }
                          }
                        }
                      }

                      let departments = ''
                      if (postings[i - 1].departments) {
                        for (let j = 1; j <= postings[i - 1].departments.length; j++) {
                          if (postings[i - 1].departments[j - 1] && postings[i - 1].departments[j - 1].name) {
                            if (departments === '') {
                              departments = postings[i - 1].departments[j - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            } else {
                              departments = departments + '; ' + postings[i - 1].departments[j - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            }
                          }
                        }
                      }

                      // .concat([appRequirements,applicationMethod,jobLink,subjectLine])
                      let tempData2 = csvData[csvData.length - 1]
                      tempData2[csvData[0].length - 2] = pathways
                      tempData2[csvData[0].length - 1] = departments

                      csvData[csvData.length - 1] = tempData2

                    } else {
                      console.log('there was an error classifying post: ', postType)
                    }
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage: 'There is no data to export', isLoading: false })
                }

              } else {
                console.log('no postings found', response.data.message)

                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Postings query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage: 'There is no data to export', isLoading: false})
          });

        } else if (reportType === 'applications') {
          console.log('in applications')

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, selectedWork, department, pathway, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {
              console.log('about to query reports')
              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "Email","First Name","Last Name","Posting ID","Posting Title","Posting Employer","Posting Location","Picture URL","Cell Number","Alternative Email","Alternative Phone Number","Education Status", "School Name","School Degree","School Major","School Grad Year","School End Date","Zip Code","Previously Interned","Stage","Resume URL","Cover Letter URL","Linked In URL","Portfolio URL","Identification URL","Letter of Recommendation URL","Transcript URL","Date of Birth","Pathway","Address","City","State","Country","Language","Race","Races","Self Described Race","Gender","Preferred Pronoun","Veteran","Work Authorization","Number of Members in Household","Household Income","Foster Youth","Homeless","Incarcerated","Adversity List","Completed Training","Interviewed","Referred","Referral Code","Interest Scores","Trait Scores","Endorsement Count","Experience Hours","Project Hours","Volunteer Hours","Notes","Match Score","Referral Rank","Org Feedback","Is Subsidy Eligible","Referral Message to Employer","Employer Ranking","Employer Feedback","Liked by Employer","Disliked by Employer","Offer Decision by Candidate", "Departments","Pathways","Is Vaccinated","Created At", "Updated At" ]
                )

                if (this.state.org === 'c2c') {
                  let tempData = csvData[0]
                  tempData = tempData.concat(["Political Alignment","State Registration","Current Congressional District","Hometown","Home Congressional District"])
                  csvData[0] = tempData
                }

                if (this.state.org === 'bixel') {
                  let assessments = []
                  for (let i = 1; i <= this.state.assessments[0].questions.length; i++) {
                    assessments.push(this.state.assessments[0].questions[i - 1].name.replace(/,/g,";").replace(/#/g,""))
                  }

                  let tempData = csvData[0]
                  tempData = tempData.concat(assessments)
                  csvData[0] = tempData
                }

                const applications = response.data.applications
                if (applications && applications.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= applications.length; i++) {

                    const email = applications[i - 1].email
                    const firstName = applications[i - 1].firstName
                    const lastName = applications[i - 1].lastName
                    const postingId = applications[i - 1].postingId

                    let postingTitle = ''
                    if (applications[i - 1].postingTitle) {
                      postingTitle = applications[i - 1].postingTitle.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let postingEmployerName = ''
                    if (applications[i - 1].postingEmployerName) {
                      postingEmployerName = applications[i - 1].postingEmployerName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let postingLocation = ''
                    if (applications[i - 1].postingLocation) {
                      postingLocation = applications[i - 1].postingLocation.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let pictureURL = ''
                    if (applications[i - 1].pictureURL) {
                      pictureURL = applications[i - 1].pictureURL
                    }

                    let phoneNumber = ''
                    if (applications[i - 1].phoneNumber) {
                      phoneNumber = applications[i - 1].phoneNumber
                    }

                    let alternativeEmail = ''
                    if (applications[i - 1].alternativeEmail) {
                      alternativeEmail = applications[i - 1].alternativeEmail
                    }

                    let alternativePhoneNumber = ''
                    if (applications[i - 1].alternativePhoneNumber) {
                      alternativePhoneNumber = applications[i - 1].alternativePhoneNumber
                    }

                    let educationStatus = ''
                    if (applications[i - 1].educationStatus) {
                      educationStatus = applications[i - 1].educationStatus
                    }

                    let schoolName = applications[i - 1].schoolName
                    if (applications[i - 1].schoolName) {
                      schoolName = applications[i - 1].schoolName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let degree = applications[i - 1].degree
                    if (applications[i - 1].degree) {
                      degree = applications[i - 1].degree.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let major = ''
                    if (applications[i - 1].major) {
                      // console.log('mic check 2: ', applications[i - 1].email)
                      major = applications[i - 1].major.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }


                    let gradYear = ''
                    if (applications[i - 1].gradYear) {
                      gradYear = applications[i - 1].gradYear.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let endDate = ''
                    if (applications[i - 1].endDate) {
                      endDate = applications[i - 1].endDate.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let zipcode = ''
                    if (applications[i - 1].zipcode) {
                      zipcode = applications[i - 1].zipcode.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let previouslyInterned = ''
                    if (applications[i - 1].previouslyInterned) {
                      previouslyInterned = applications[i - 1].previouslyInterned.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let stage = ''
                    if (applications[i - 1].stage) {
                      stage = applications[i - 1].stage.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let resumeURL = ''
                    if (applications[i - 1].resumeURL) {
                      resumeURL = applications[i - 1].resumeURL.replace(/#/g,"")
                    }

                    let coverLetterURL = ''
                    if (applications[i - 1].coverLetterURL) {
                      coverLetterURL = applications[i - 1].coverLetterURL.replace(/#/g,"")
                    }

                    let linkedInURL = ''
                    if (applications[i - 1].linkedInURL) {
                      linkedInURL = applications[i - 1].linkedInURL.replace(/#/g,"")
                    }

                    let customWebsiteURL = ''
                    if (applications[i - 1].customWebsiteURL) {
                      //this is not right
                      customWebsiteURL = applications[i - 1].customWebsiteURL.replace(/#/g,"")
                    }

                    let identificationURL = ''
                    if (applications[i - 1].identificationURL) {
                      identificationURL = applications[i - 1].identificationURL.replace(/#/g,"")
                    }

                    let letterOfRecommendationURL = ''
                    if (applications[i - 1].letterOfRecommendationURL) {
                      letterOfRecommendationURL = applications[i - 1].letterOfRecommendationURL.replace(/#/g,"")
                    }

                    let transcriptURL = ''
                    if (applications[i - 1].transcriptURL) {
                      transcriptURL = applications[i - 1].transcriptURL.replace(/#/g,"")
                    }

                    let dateOfBirth = ''
                    if (applications[i - 1].dateOfBirth) {
                      dateOfBirth = applications[i - 1].dateOfBirth.replace(/,/g,";").replace(/#/g,"")
                    }

                    let pathway = ''
                    if (applications[i - 1].pathway) {
                      pathway = applications[i - 1].pathway.replace(/,/g,";").replace(/#/g,"")
                    }

                    let address = ''
                    if (applications[i - 1].address) {
                      address = applications[i - 1].address.replace(/,/g,";").replace(/#/g,"")
                    }

                    let city = ''
                    if (applications[i - 1].city) {
                      city = applications[i - 1].city.replace(/,/g,";").replace(/#/g,"")
                    }

                    let state = ''
                    if (applications[i - 1].state) {
                      state = applications[i - 1].state.replace(/,/g,";").replace(/#/g,"")
                    }

                    let country = ''
                    if (applications[i - 1].country) {
                      country = applications[i - 1].country.replace(/,/g,";").replace(/#/g,"")
                    }

                    let language = ''
                    if (applications[i - 1].language) {
                      language = applications[i - 1].language.replace(/,/g,";").replace(/#/g,"")
                    }

                    let race = ''
                    if (applications[i - 1].race) {
                      race = applications[i - 1].race.replace(/,/g,";").replace(/#/g,"")
                    }

                    let races = ''
                    if (applications[i - 1].races && applications[i - 1].races.length > 0) {
                      races = applications[i - 1].races.join(',').replace(/,/g,';')
                    }

                    let selfDescribedRace = ''
                    if (applications[i - 1].selfDescribedRace) {
                      selfDescribedRace = applications[i - 1].selfDescribedRace.replace(/,/g,";").replace(/#/g,"")
                    }

                    let gender = ''
                    if (applications[i - 1].gender) {
                      gender = applications[i - 1].gender.replace(/,/g,";").replace(/#/g,"")
                    }

                    let preferredPronoun = ''
                    if (applications[i - 1].preferredPronoun) {
                      preferredPronoun = applications[i - 1].preferredPronoun.replace(/,/g,";").replace(/#/g,"")
                    }

                    let veteran = ''
                    if (applications[i - 1].veteran) {
                      veteran = applications[i - 1].veteran.replace(/,/g,";").replace(/#/g,"")
                    }

                    let workAuthorization = ''
                    if (applications[i - 1].workAuthorization) {
                      workAuthorization = applications[i - 1].workAuthorization.replace(/,/g,";").replace(/#/g,"")
                    }

                    let numberOfMembers = ''
                    if (applications[i - 1].numberOfMembers) {
                      numberOfMembers = applications[i - 1].numberOfMembers.replace(/,/g,";").replace(/#/g,"")
                    }

                    let householdIncome = ''
                    if (applications[i - 1].householdIncome) {
                      householdIncome = applications[i - 1].householdIncome.replace(/,/g,";").replace(/#/g,"")
                    }

                    let fosterYouth = ''
                    if (applications[i - 1].fosterYouth) {
                      fosterYouth = applications[i - 1].fosterYouth.replace(/,/g,";").replace(/#/g,"")
                    }

                    let homeless = ''
                    if (applications[i - 1].homeless) {
                      homeless = applications[i - 1].homeless.replace(/,/g,";").replace(/#/g,"")
                    }

                    let incarcerated = ''
                    if (applications[i - 1].incarcerated) {
                      incarcerated = applications[i - 1].incarcerated.replace(/,/g,";").replace(/#/g,"")
                    }

                    let adversityList = ''
                    if (applications[i - 1].adversityList && applications[i - 1].adversityList.length > 0) {
                      adversityList = applications[i - 1].adversityList.join(',').replace(/,/g,';')
                    }

                    if (applications[i - 1].isWorkforceReady) {
                      console.log('show workforce: ', applications[i - 1].isWorkforceReady)
                    }

                    let completedWorkforceReady = 'NO'
                    if (applications[i - 1].isWorkforceReady) {
                      completedWorkforceReady = 'YES'
                    }

                    let interviewed = 'NO'
                    if (applications[i - 1].interviews.length && applications[i - 1].interviews.length > 0) {
                      interviewed = 'YES'
                    }

                    let referred = 'NO'
                    if (applications[i - 1].rank) {
                      referred = 'YES'
                    }

                    let referralCode = ''
                    if (applications[i - 1].referralCode) {
                      referralCode = applications[i - 1].referralCode.replace(/,/g,";").replace(/#/g,"")
                    }

                    let interestScoreArray = []
                    let interestScores = ''
                    if (applications[i - 1].interestResults) {
                      if (applications[i - 1].interestResults.length !== 0) {
                        for (let j = 1; j <= applications[i - 1].interestResults.length; j++) {
                          console.log(i)

                          interestScoreArray.push(applications[i - 1].interestResults[j - 1].name + "-" + applications[i - 1].interestResults[j - 1].score)
                        }
                        interestScores = interestScoreArray.join(commaReplacementValue)
                      }
                    }

                    let traitScores = ''
                    if (applications[i - 1].personalityResults) {
                      let fiveFactors = applications[i - 1].personalityResults.fiveFactors
                      traitScores = 'Openness-' + fiveFactors.opennessScore + '; Conscientiousness-' + fiveFactors.conscientiousnessScore + '; Extraversion-' + fiveFactors.extraversionScore + '; Agreeableness-' + fiveFactors.agreeablenessScore + '; Neuroticism' + fiveFactors.neuroticismScore
                    }

                    let endorsementCount = 0
                    if (applications[i - 1].endorsements) {
                      endorsementCount = applications[i - 1].endorsements.length
                    }

                    let experienceHours = ''
                    if (applications[i - 1].experienceHours) {
                      experienceHours = applications[i - 1].experienceHours
                    }

                    let projectHours = ''
                    if (applications[i - 1].projectHours) {
                      projectHours = applications[i - 1].projectHours
                    }

                    let volunteerHours = ''
                    if (applications[i - 1].volunteerHours) {
                      volunteerHours = applications[i - 1].volunteerHours
                    }

                    let notes = ''
                    if (applications[i - 1].notes) {
                      notes = applications[i - 1].notes.replace(/,/g,';').replace(/#/g,';')
                    }

                    let match = ''
                    if (applications[i - 1].match) {
                      match = applications[i - 1].match
                    }

                    let rank = ''
                    if (applications[i - 1].rank) {
                      rank = applications[i - 1].rank
                    }

                    let feedback = ''
                    if (applications[i - 1].feedback) {
                      feedback = applications[i - 1].feedback
                    }

                    let isSubsidyEligible = ''
                    if (applications[i - 1].isSubsidyEligible) {
                      isSubsidyEligible = 'YES'
                    }

                    let employerMessage = ''
                    if (applications[i - 1].employerMessage) {
                      employerMessage = applications[i - 1].employerMessage.replace(/,/g,';').replace(/#/g,';')
                    }

                    let employerRanking = ''
                    if (applications[i - 1].employerRanking) {
                      employerRanking = applications[i - 1].employerRanking
                    }

                    let employerFeedback = ''
                    if (applications[i - 1].employerFeedback) {
                      employerFeedback = applications[i - 1].employerFeedback.replace(/,/g,';').replace(/#/g,';')
                    }

                    let liked = ''
                    if (applications[i - 1].liked) {
                      liked = 'YES'
                    }

                    let disliked = ''
                    if (applications[i - 1].disliked) {
                      disliked = 'YES'
                    }

                    let decision = ''
                    if (applications[i - 1].decision) {
                      decision = applications[i - 1].decision
                    }

                    let departments = ''
                    if (applications[i - 1].departments) {
                      for (let j = 1; j <= applications[i - 1].departments.length; j++) {
                        if (applications[i - 1].departments[j - 1] && applications[i - 1].departments[j - 1].name) {
                          if (departments === '') {
                            departments = applications[i - 1].departments[j - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                          } else {
                            departments = departments + '; ' + applications[i - 1].departments[j - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                          }
                        }
                      }
                    }

                    let pathways = ''
                    if (applications[i - 1].pathways) {
                      for (let j = 1; j <= applications[i - 1].pathways.length; j++) {
                        if (applications[i - 1].pathways[j - 1] && applications[i - 1].pathways[j - 1].title) {
                          if (pathways === '') {
                            pathways = applications[i - 1].pathways[j - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                          } else {
                            pathways = pathways + '; ' + applications[i - 1].pathways[j - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                          }
                        }
                      }
                    }

                    let isVaccinated = ''
                    if (applications[i - 1].isVaccinated) {
                      isVaccinated = applications[i - 1].isVaccinated
                    }

                    const createdAt = applications[i - 1].createdAt
                    const updatedAt = applications[i - 1].updatedAt

                    csvData.push([
                      email, firstName, lastName, postingId, postingTitle, postingEmployerName, postingLocation, pictureURL,
                      phoneNumber, alternativeEmail, alternativePhoneNumber, educationStatus,
                      schoolName, degree, major, gradYear, endDate,
                      zipcode, previouslyInterned, stage, resumeURL, coverLetterURL, linkedInURL, customWebsiteURL,
                      identificationURL, letterOfRecommendationURL, transcriptURL,
                      dateOfBirth, pathway, address, city, state, country, language,
                      race, races, selfDescribedRace, gender, preferredPronoun,
                      veteran, workAuthorization, numberOfMembers, householdIncome,
                      fosterYouth, homeless, incarcerated, adversityList,
                      completedWorkforceReady,interviewed,referred, referralCode,
                      interestScores, traitScores, endorsementCount,
                      experienceHours, projectHours, volunteerHours, notes, match, rank,
                      feedback,isSubsidyEligible, employerMessage, employerRanking, employerFeedback, liked, disliked, decision,
                      departments, pathways, isVaccinated,
                      createdAt, updatedAt
                    ])

                    if (this.state.org === 'c2c') {

                      let politicalAlignment = ''
                      if (applications[i - 1].politicalAlignment) {
                        politicalAlignment = applications[i - 1].politicalAlignment.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let stateRegistration = ''
                      if (applications[i - 1].stateRegistration) {
                        stateRegistration = applications[i - 1].stateRegistration.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let currentCongressionalDistrict = ''
                      if (applications[i - 1].currentCongressionalDistrict) {
                        currentCongressionalDistrict = applications[i - 1].currentCongressionalDistrict.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let hometown = ''
                      if (applications[i - 1].hometown) {
                        hometown = applications[i - 1].hometown.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let homeCongressionalDistrict = ''
                      if (applications[i - 1].homeCongressionalDistrict) {
                        homeCongressionalDistrict = applications[i - 1].homeCongressionalDistrict.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      csvData[csvData.length - 1].push(politicalAlignment, stateRegistration, currentCongressionalDistrict, hometown, homeCongressionalDistrict)
                    }

                    if (applications[i - 1].customAssessmentResults && applications[i - 1].customAssessmentResults.length > 0) {
                      console.log('Z0')
                      for (let j = 1; j <= applications[i - 1].customAssessmentResults.length; j++) {
                        if (applications[i - 1].customAssessmentResults[j - 1]) {
                          if (typeof applications[i - 1].customAssessmentResults[j - 1] === 'string') {
                            console.log('Z1')

                            const result = applications[i - 1].customAssessmentResults[j - 1].replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," | ").replace(/\n/g," | ").replace(/#/g,"")
                            csvData[csvData.length - 1].push(result)

                            let questionName = 'Custom Question ' + j
                            let showNewCustomResponsesHack = true
                            if (showNewCustomResponsesHack && applications[i - 1].newCustomAssessmentResults && applications[i - 1].newCustomAssessmentResults[j - 1]) {
                              if (applications[i - 1].newCustomAssessmentResults[j - 1] && applications[i - 1].newCustomAssessmentResults[j - 1].question) {
                                questionName = 'Q-' + applications[i - 1].newCustomAssessmentResults[j - 1].question.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," | ").replace(/\n/g," | ").replace(/#/g,"")
                              }
                            }
                            // if (applications[i - 1].newCustomAssessmentResults && applications[i - 1].newCustomAssessmentResults[j - 1] && applications[i - 1].newCustomAssessmentResults[j - 1].question) {
                            //   console.log('show qName: ', applications[i - 1].newCustomAssessmentResults[j - 1].question)
                            //   questionName = applications[i - 1].newCustomAssessmentResults[j - 1].question.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," | ").replace(/\n/g," | ").replace(/#/g,"").substring(0,20) + '...'
                            // }

                            if (!csvData[0].includes(questionName)) {
                              csvData[0].push(questionName)
                            }
                          } else {
                            console.log('Z2')
                            const result = applications[i - 1].customAssessmentResults[j - 1].join().replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," | ").replace(/\n/g," | ").replace(/#/g,"")
                            csvData[csvData.length - 1].push(result)
                            if (result === undefined || result === null) {
                              console.log('result is undefined or null 1')
                            }

                            const questionName = 'Question ' + j
                            if (i === 1 && !csvData[0].includes(questionName)) {
                              csvData[0].push(questionName)
                            }
                          }
                        } else {
                          console.log('Z3')

                          csvData[csvData.length - 1].push('N/A')

                          let questionName = 'Custom Question ' + j
                          let showNewCustomResponsesHack = true
                          if (showNewCustomResponsesHack) {
                            if (applications[i - 1].newCustomAssessmentResults[j - 1] && applications[i - 1].newCustomAssessmentResults[j - 1].question) {
                              questionName = 'Q-' + applications[i - 1].newCustomAssessmentResults[j - 1].question.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," | ").replace(/\n/g," | ").replace(/#/g,"")
                            }
                          }

                          if (!csvData[0].includes(questionName)) {
                            csvData[0].push(questionName)
                          }

                        }
                      }
                    } else if (applications[i - 1].newCustomAssessmentResults && applications[i - 1].newCustomAssessmentResults.length > 0) {

                      for (let j = 1; j <= applications[i - 1].newCustomAssessmentResults.length; j++) {
                        if (applications[i - 1].newCustomAssessmentResults[j - 1]) {
                          if (typeof applications[i - 1].newCustomAssessmentResults[j - 1].answer === 'string') {
                            console.log('Z1')

                            const result = applications[i - 1].newCustomAssessmentResults[j - 1].answer.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," | ").replace(/\n/g," | ").replace(/#/g,"")
                            csvData[csvData.length - 1].push(result)

                            let questionName = 'Custom Question ' + j
                            let showNewCustomResponsesHack = true
                            if (showNewCustomResponsesHack && applications[i - 1].newCustomAssessmentResults && applications[i - 1].newCustomAssessmentResults[j - 1]) {
                              if (applications[i - 1].newCustomAssessmentResults[j - 1] && applications[i - 1].newCustomAssessmentResults[j - 1].question) {
                                questionName = 'Q-' + applications[i - 1].newCustomAssessmentResults[j - 1].question.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," | ").replace(/\n/g," | ").replace(/#/g,"")
                              }
                            }
                            // if (applications[i - 1].newCustomAssessmentResults && applications[i - 1].newCustomAssessmentResults[j - 1] && applications[i - 1].newCustomAssessmentResults[j - 1].question) {
                            //   console.log('show qName: ', applications[i - 1].newCustomAssessmentResults[j - 1].question)
                            //   questionName = applications[i - 1].newCustomAssessmentResults[j - 1].question.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," | ").replace(/\n/g," | ").replace(/#/g,"").substring(0,20) + '...'
                            // }

                            if (!csvData[0].includes(questionName)) {
                              csvData[0].push(questionName)
                            }
                          } else {
                            console.log('Z2')
                            const result = applications[i - 1].newCustomAssessmentResults[j - 1].answer.join().replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," | ").replace(/\n/g," | ").replace(/#/g,"")
                            csvData[csvData.length - 1].push(result)
                            if (result === undefined || result === null) {
                              console.log('result is undefined or null 1')
                            }

                            const questionName = 'Question ' + j
                            if (i === 1 && !csvData[0].includes(questionName)) {
                              csvData[0].push(questionName)
                            }
                          }
                        } else {
                          console.log('Z3')

                          csvData[csvData.length - 1].push('N/A')

                          let questionName = 'Custom Question ' + j
                          let showNewCustomResponsesHack = true
                          if (showNewCustomResponsesHack) {
                            if (applications[i - 1].newCustomAssessmentResults[j - 1] && applications[i - 1].newCustomAssessmentResults[j - 1].question) {
                              questionName = 'Q-' + applications[i - 1].newCustomAssessmentResults[j - 1].question.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," | ").replace(/\n/g," | ").replace(/#/g,"")
                            }
                          }

                          if (!csvData[0].includes(questionName)) {
                            csvData[0].push(questionName)
                          }

                        }
                      }
                    }

                    // 3rd party scores - issues with looping
                    let showScores = false
                    if (showScores) {
                      if (applications[i - 1].scores && applications[i - 1].scores.length > 0) {
                        for (let j = 1; j <= applications[i - 1].scores.length; j++) {
                          if (applications[i - 1].scores[j - 1] && applications[i - 1].scores[j - 1].scorer) {

                            // tally by suppScores
                            if (applications[i - 1].scores[j - 1].suppScores) {
                              for (let k = 1; k <= applications[i - 1].scores[j - 1].suppScores.length; k++) {
                                // scores[0].scorer.email firstName, lastName, jobTitle, employerName
                                // scores[0].suppScores[0].category score

                                const result = applications[i - 1].scores[j - 1].suppScores[k - 1].score
                                csvData[csvData.length - 1].push(result)

                                const category = applications[i - 1].scores[j - 1].suppScores[k - 1].category.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," | ").replace(/\n/g," | ").replace(/#/g,"")
                                const scorerFirstName = applications[i - 1].scores[j - 1].scorer.firstName.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," | ").replace(/\n/g," | ").replace(/#/g,"")
                                const scorerLastName = applications[i - 1].scores[j - 1].scorer.lastName.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," | ").replace(/\n/g," | ").replace(/#/g,"")
                                const categoryName = category + 'Score by ' + scorerFirstName + ' ' + scorerLastName

                                if (!csvData[0].includes(categoryName)) {
                                  csvData[0].push(categoryName)
                                }
                              }
                            }

                          } else {
                            console.log('log nothing 4')

                          }
                        }
                      }
                    }
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage: 'There is no data to export', isLoading: false})
                }

              } else {
                console.log('there was a reporting error', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Reporting query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });

        } else if (reportType === 'projectSubmissions') {
          console.log('in submissions')

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, selectedProject, department, pathway, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {
              console.log('about to query reports')
              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "User First Name","User Last Name","User Email","Image URL","Video URL","Project Id","Project Name","Project URL","Project Category","Start Date","End Date","Description","Hours","Total Hours","Focus","Collaborator Count","Grades","Upvote Count","Downvote Count","Created At", "Updated At" ]
                )

                if (this.state.department && this.state.department !== 'All') {
                  csvData[0].push('Department')
                }

                if (this.state.pathway && this.state.pathway !== 'All') {
                  csvData[0].push('Pathway')
                }

                const submissions = response.data.submissions
                if (submissions && submissions.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= submissions.length; i++) {

                    // const _id = submissions[i - 1]._id
                    const userFirstName = submissions[i - 1].userFirstName
                    const userLastName = submissions[i - 1].userLastName
                    const userEmail = submissions[i - 1].userEmail
                    let imageURL = submissions[i - 1].imageURL
                    if (submissions[i - 1].imageURL) {
                      imageURL = submissions[i - 1].imageURL.replace(/#/g,"")
                    }

                    let videoURL = submissions[i - 1].videoURL
                    if (submissions[i - 1].videoURL) {
                      videoURL = submissions[i - 1].videoURL.replace(/#/g,"")
                    }
                    const projectId = submissions[i - 1].projectId
                    let name = ''
                    if (submissions[i - 1].name) {
                      name = submissions[i - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let url = submissions[i - 1].url
                    if (submissions[i - 1].url) {
                      url = submissions[i - 1].url.replace(/#/g,"")
                    }

                    let category = ''
                    if (submissions[i - 1].category) {
                      category = submissions[i - 1].category.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }
                    const startDate = submissions[i - 1].startDate
                    const endDate = submissions[i - 1].endDate
                    let description = ''
                    if (submissions[i - 1].description) {
                      description = submissions[i - 1].description.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }
                    const hours = submissions[i - 1].hours
                    const totalHours = submissions[i - 1].totalHours
                    let focus = ''
                    if (submissions[i - 1].focus) {
                      focus = submissions[i - 1].focus.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }
                    let collaboratorCount = submissions[i - 1].collaboratorCount
                    if (submissions[i - 1].collaborators) {
                      collaboratorCount = submissions[i - 1].collaborators.length
                    }
                    let grades = ''
                    if (submissions[i - 1].grades) {
                      grades = submissions[i - 1].grades.toString().replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                      //   grades: [
                      //     { grade: String, feedback: String, isApproved: Boolean, isTransparent: Boolean,
                      //     contributorFirstName: String, contributorLastName: String, contributorEmail: String, contributorRoleName: String, contributorTitle: String,
                      //     contributorEmployerName: String, updatedAt: Date }
                      //   ]
                    }

                    let upvoteCount = 0
                    if (submissions[i - 1].upvotes) {
                      upvoteCount = submissions[i - 1].upvotes.length
                    }
                    let downvoteCount = 0
                    if (submissions[i - 1].downvotes) {
                      downvoteCount = submissions[i - 1].downvotes.length
                    }
                    const createdAt = submissions[i - 1].createdAt
                    const updatedAt = submissions[i - 1].updatedAt

                    csvData.push([
                      userFirstName, userLastName, userEmail, imageURL, videoURL, projectId, name, url,
                      category, startDate, endDate, description, hours, totalHours, focus, collaboratorCount, grades, upvoteCount, downvoteCount,
                      createdAt, updatedAt
                    ])

                    if (this.state.department && this.state.department !== 'All') {
                      csvData[csvData.length - 1].push(this.state.department)
                    }

                    if (this.state.pathway && this.state.pathway !== 'All') {
                      csvData[csvData.length - 1].push(this.state.pathway)
                    }
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('there was a reporting error', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Reporting query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });
        } else if (reportType === 'rsvps' || reportType === 'attendees') {
          console.log('in rsvps and attendees')

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, selectedEvent, approved, department, pathway, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {
              console.log('about to query reports')
              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "First Name","Last Name","Email","Phone Number","Role Name","Other Role Name","Existing User","Posting Title","School Name","Job Title","Employer Name", "Collaborator Count","Activities","Other Answers","Created At", "Updated At" ]
                )

                csvData[0].push('Department')
                csvData[0].push('Pathway')
                if (reportType === 'attendees') {
                  if (this.state.org === 'unite-la') {
                    csvData[0].push('Certified')
                  } else {
                    csvData[0].push('Passed')
                  }
                }

                // if (this.state.adversityListOptions && this.state.adversityListOptions.length > 0) {
                //   // csvData[0].pop()
                //   csvData[0] = csvData[0].concat(this.state.adversityListOptions)
                // }

                let rsvps = response.data.rsvps
                if (reportType === 'attendees') {
                  rsvps = response.data.attendees
                }

                if (rsvps && rsvps.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= rsvps.length; i++) {

                    const firstName = rsvps[i - 1].firstName
                    const lastName = rsvps[i - 1].lastName
                    const email = rsvps[i - 1].email
                    let phoneNumber = ''
                    if (rsvps[i - 1].phoneNumber) {
                      phoneNumber = rsvps[i - 1].phoneNumber
                    }

                    const roleName = rsvps[i - 1].roleName
                    const otherRoleName = rsvps[i - 1].otherRoleName
                    let existingUser = 'FALSE'
                    if (rsvps[i - 1].existingUser && (rsvps[i - 1].existingUser === true || rsvps[i - 1].existingUser === 'true')) {
                      existingUser = 'TRUE'
                    }

                    let postingTitle = ''
                    if (rsvps[i - 1].postingTitle) {
                      postingTitle = rsvps[i - 1].postingTitle.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let schoolName = ''
                    if (rsvps[i - 1].schoolName) {
                      schoolName = rsvps[i - 1].schoolName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let jobTitle = ''
                    if (rsvps[i - 1].jobTitle && rsvps[i - 1].roleName !== 'Student') {
                      jobTitle = rsvps[i - 1].jobTitle.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let employerName = ''
                    if (rsvps[i - 1].employerName && rsvps[i - 1].roleName !== 'Student') {
                      employerName = rsvps[i - 1].employerName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                      console.log('show employerName: ', rsvps[i - 1].employerName, employerName)
                    }

                    let collaboratorCount = ''
                    if (rsvps[i - 1].collaborators) {
                      collaboratorCount = rsvps[i - 1].collaborators.length
                    }

                    let activities = ''
                    if (rsvps[i - 1].roleName === 'Student' && rsvps[i - 1].studentActivityInterests && rsvps[i - 1].studentActivityInterests.length > 0) {
                      activities = rsvps[i - 1].studentActivityInterests.toString().replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    } else if (rsvps[i - 1].roleName === 'Teacher' && rsvps[i - 1].teacherActivityInterests && rsvps[i - 1].teacherActivityInterests.length > 0) {
                      activities = rsvps[i - 1].teacherActivityInterests.toString().replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    } else if (rsvps[i - 1].roleName === 'Mentor' && rsvps[i - 1].mentorActivityInterests && rsvps[i - 1].mentorActivityInterests.length > 0) {
                      activities = rsvps[i - 1].mentorActivityInterests.toString().replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }
                    console.log('l1', i)
                    let otherAnswers = ''
                    if (rsvps[i - 1].otherAnswers && rsvps[i - 1].otherAnswers.length > 0) {
                      otherAnswers = rsvps[i - 1].otherAnswers.toString().replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }
                    console.log('l2', i)
                    const createdAt = rsvps[i - 1].createdAt
                    const updatedAt = rsvps[i - 1].updatedAt

                    csvData.push([
                      firstName, lastName, email, phoneNumber, roleName, otherRoleName, existingUser, postingTitle, schoolName, jobTitle, employerName,
                      collaboratorCount, activities, otherAnswers,
                      createdAt, updatedAt
                    ])

                    if (rsvps[i - 1].departments && rsvps[i - 1].departments.length > 0) {
                      let departments = []
                      for (let j = 1; j <= rsvps[i - 1].departments.length; j++) {
                        departments.push(rsvps[i - 1].departments[j - 1].name)
                      }
                      csvData[csvData.length - 1].push(departments)
                    } else {
                      csvData[csvData.length - 1].push('')
                    }

                    if (rsvps[i - 1].pathways && rsvps[i - 1].pathways.length > 0) {
                      let pathways = []
                      for (let j = 1; j <= rsvps[i - 1].pathways.length; j++) {
                        if (rsvps[i - 1].pathways[j - 1] && rsvps[i - 1].pathways[j - 1].name) {
                          pathways.push(rsvps[i - 1].pathways[j - 1].name.toString())
                        } else if (rsvps[i - 1].pathways[j - 1] && rsvps[i - 1].pathways[j - 1].title) {
                          pathways.push(rsvps[i - 1].pathways[j - 1].title.toString())
                        } else {
                          pathways.push(rsvps[i - 1].pathways[j - 1].toString())
                        }
                      }
                      csvData[csvData.length - 1].push(pathways)
                    } else {
                      csvData[csvData.length - 1].push('')
                    }

                    if (reportType === 'attendees') {
                      // let approved = ''
                      if (rsvps[i - 1].approved || rsvps[i - 1].approved === false) {
                        // console.log('show up approved: ', rsvps[i - 1].approved)
                        if (rsvps[i - 1].approved) {
                          csvData[csvData.length - 1].push('TRUE')
                        } else {
                          csvData[csvData.length - 1].push('FALSE')
                        }
                        // csvData[csvData.length - 1].push(rsvps[i - 1].approved.toString())
                      } else {
                        csvData[csvData.length - 1].push('')
                      }
                    }

                    // if (this.state.adversityListOptions) {
                    //   let dataArray = []
                    //   for (let j = 1; j <= this.state.adversityListOptions.length; j++) {
                    //     if (rsvps[i - 1].adversityList && RSVPs[i - 1].adversityList.includes(this.state.adversityListOptions[j - 1])) {
                    //       dataArray.push("TRUE")
                    //     } else {
                    //       dataArray.push("FALSE")
                    //     }
                    //   }
                    //   csvData.push(dataArray)
                    //   csvData[csvData.length - 1].push(departments)
                    // }

                    // const coreQuestionCount = csvData[0].length
                    const coreQuestionCount = 5 // dateOfBirth

                    if (rsvps[i - 1].rsvpResponses && rsvps[i - 1].rsvpResponses.length > 0) {
                      for (let j = 1; j <= rsvps[i - 1].rsvpResponses.length; j++) {
                        // const coreQuestionCount = 10
                        const responseIndex = j - 1 + coreQuestionCount

                        let rrObject = rsvps[i - 1].rsvpResponses[responseIndex]
                        if (rrObject && rrObject.question) {
                          // console.log('wtf: ', rsvpResponses[responseIndex])
                          rrObject['question'] = rrObject.question.replace(/\"/g,"")
                        }

                        if (rrObject && !csvData[0].includes(rrObject.question)) {
                          csvData[0].push(rrObject.question)
                        }
                        if (rrObject && rrObject.answer) {
                          csvData[i].push(rrObject.answer.toString().replace(commaStartingValue,commaReplacementValue).replace(/#/g,""))
                        } else {
                          csvData[i].push('')
                        }

                        if (rrObject && (rrObject.questionType === 'Checkbox' || rrObject.questionType === 'Multiple Answer')) {
                          const acs = rrObject.answerChoices
                          if (acs && acs.length > 0) {
                            for (let k = 1; k <= acs.length; k++) {
                              if (acs[k - 1]) {
                                if (!csvData[0].includes(acs[k - 1])) {
                                  csvData[0].push(acs[k - 1])
                                }
                                if (rrObject.answer && rrObject.answer.length > 0 && rrObject.answer.includes(acs[k - 1])) {
                                  csvData[i].push('TRUE')
                                } else {
                                  csvData[i].push('FALSE')
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage: 'There is no data to export', isLoading: false})
                }

              } else {
                console.log('there was a reporting error', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Reporting query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });

        } else if (reportType === 'assessments') {

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "Assessment Id","Title", "Question Titles","Question Types","Question Answer Choices","Created At", "Updated At" ]
                )

                const assessments = response.data.assessments
                if (assessments && assessments.length > 0) {

                  //replace commas
                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= assessments.length; i++) {
                    console.log(i)

                    const assessmentId = assessments[i - 1]._id
                    const title = assessments[i - 1].title

                    let questionTitlesArray = []
                    let questionTypesArray = []
                    let questionAnswerChoicesArray = []

                    for (let j = 1; j <= assessments[i - 1].questions.length; j++) {
                      //console.log(i)

                      questionTitlesArray.push(assessments[i - 1].questions[j - 1].name.replace(commaStartingValue, commaReplacementValue).replace('#','-Sharp'))
                      questionTypesArray.push(assessments[i - 1].questions[j - 1].questionType)
                      if (assessments[i - 1].questions[j - 1].answerChoices.length !== 0) {

                        let ac = assessments[i - 1].questions[j - 1].answerChoices
                        for (let k = 1; k <= ac.length; k++) {
                          //console.log(i)
                          ac[k - 1] = assessments[i - 1].questions[j - 1].answerChoices[k - 1].replace(commaStartingValue, commaReplacementValue)
                        }
                        questionAnswerChoicesArray.push(ac.join("|"))
                      } else {
                        questionAnswerChoicesArray.push("")
                      }

                      console.log('lets see values', j, questionTitlesArray, questionTypesArray, questionAnswerChoicesArray)
                    }

                    let questionTitles = questionTitlesArray.join(commaReplacementValue)
                    let questionTypes = questionTypesArray.join(commaReplacementValue)
                    let questionAnswerChoices = questionAnswerChoicesArray.join(commaReplacementValue)

                    const createdAt = assessments[i - 1].createdAt
                    const updatedAt = assessments[i - 1].updatedAt

                    csvData.push([
                      assessmentId, title,
                      questionTitles, questionTypes, questionAnswerChoices,
                      createdAt, updatedAt,
                    ])
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no postings found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Postings query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });

        } else if (reportType === 'assessmentResults') {

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "Email","First Name", "Last Name","Username","Work Preference Answers","Interest Scores", "Skills Answers","Personality Scores","Top Values of People You Gravitate Towards","Top Employer Values Valued","Created At","Updated At" ]
                )

                const results = response.data.assessmentResults
                if (results && results.length > 0) {

                  for (let i = 1; i <= results.length; i++) {
                    console.log(i)

                    let userId = ''
                    if (results[i - 1].userId) {
                      userId = results[i - 1].userId
                    }

                    let userFirstName = ''
                    if (results[i - 1].userFirstName) {
                      userFirstName = results[i - 1].userFirstName
                    }

                    let userLastName = ''
                    if (results[i - 1].userLastName) {
                      userLastName = results[i - 1].userLastName
                    }

                    let userUsername = ''
                    if (results[i - 1].userUsername) {
                      userUsername = results[i - 1].userUsername
                    }

                    let workPreferenceAnswers = ''
                    if (results[i - 1].workPreferenceAnswers && results[i - 1].workPreferenceAnswers.length > 0) {
                      workPreferenceAnswers = JSON.stringify(results[i - 1].workPreferenceAnswers).replace(/,/g,'').replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                    }

                    let interestScores = ''
                    if (results[i - 1].interestScores && results[i - 1].interestScores.length > 0) {
                      interestScores = JSON.stringify(results[i - 1].interestScores).replace(/,/g,'').replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                    }

                    let newSkillAnswers = ''
                    if (results[i - 1].newSkillAnswers && results[i - 1].newSkillAnswers.length > 0) {
                      newSkillAnswers = JSON.stringify(results[i - 1].newSkillAnswers).replace(/,/g,'').replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                    }

                    let personalityScores = ''
                    if (results[i - 1].personalityScores) {
                      personalityScores = JSON.stringify(results[i - 1].personalityScores).replace(/,/g,'').replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                    }

                    let topGravitateValues = ''
                    if (results[i - 1].topGravitateValues && results[i - 1].topGravitateValues.length > 0) {
                      topGravitateValues = JSON.stringify(results[i - 1].topGravitateValues).replace(/,/g,'').replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                    }

                    let topEmployerValues = ''
                    if (results[i - 1].topEmployerValues && results[i - 1].topEmployerValues.length > 0) {
                      topEmployerValues = JSON.stringify(results[i - 1].topEmployerValues).replace(/,/g,'').replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                    }

                    let createdAt = results[i - 1].createdAt
                    if (!createdAt) {
                      createdAt = ''
                    }

                    let updatedAt = results[i - 1].updatedAt
                    if (!updatedAt) {
                      updatedAt = ''
                    }

                    csvData.push([
                      userId, userFirstName, userLastName, userUsername, workPreferenceAnswers,
                      interestScores, newSkillAnswers, personalityScores, topGravitateValues, topEmployerValues,
                      createdAt, updatedAt
                    ])
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }
                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no postings found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Postings query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });

        } else if (reportType === 'benchmarks') {

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "Benchmark ID", "Title", "Job Title","Job Function","Interests Weight", "Skills Weight","Traits Weight","Interview Weight","Adversity Score Weight","Education Weight","Endorsement Weight","Experience Weight", "Project Weight", "Work Preference Weight","Political Parties","Hometown Weight" ]
                )

                const benchmarks = response.data.benchmarks
                if (benchmarks && benchmarks.length > 0) {

                  for (let i = 1; i <= benchmarks.length; i++) {
                    console.log(i)

                    const _id = benchmarks[i - 1]._id
                    const title = benchmarks[i - 1].title
                    const jobTitle = benchmarks[i - 1].jobTitle
                    const jobFunction = benchmarks[i - 1].jobFunction

                    let interestsWeight = 0
                    for (let j = 1; j <= benchmarks[i - 1].interests.length; j++) {
                      interestsWeight = interestsWeight +  Number(benchmarks[i - 1].interests[j - 1].weight)
                    }

                    let skillsWeight = 0
                    for (let j = 1; j <= benchmarks[i - 1].skills.length; j++) {
                      skillsWeight = skillsWeight +  Number(benchmarks[i - 1].skills[j - 1].weight)
                    }

                    let traitsWeight = 0
                    for (let j = 1; j <= benchmarks[i - 1].traits.length; j++) {
                      traitsWeight = traitsWeight +  Number(benchmarks[i - 1].traits[j - 1].weight)
                    }

                    let interviewWeight = 0
                    if (benchmarks[i - 1].interview) {
                      interviewWeight = benchmarks[i - 1].interview.weight
                    }

                    const adversityScoreWeight = benchmarks[i - 1].adversityScoreWeight
                    const educationWeight = benchmarks[i - 1].educationWeight
                    const endorsementWeight = benchmarks[i - 1].endorsementWeight
                    const experienceWeight = benchmarks[i - 1].experienceWeight
                    const projectWeight = benchmarks[i - 1].projectWeight
                    const workPreferenceWeight = benchmarks[i - 1].workPreferenceWeight

                    const hometownWeight = benchmarks[i - 1].hometownWeight
                    let politicalParties = ''
                    for (let j = 1; j <= benchmarks[i - 1].politicalParties.length; j++) {
                      let politicalParty = benchmarks[i - 1].politicalParties[j - 1]
                      if (politicalParty && politicalParty.title && politicalParty.score) {
                        politicalParties = politicalParties + politicalParty.title + '-' + politicalParty.score + ' '
                      }
                    }

                    csvData.push([
                      _id, title, jobTitle, jobFunction,
                      interestsWeight, skillsWeight, traitsWeight, interviewWeight,
                      adversityScoreWeight, educationWeight, endorsementWeight, experienceWeight,
                      projectWeight, workPreferenceWeight, politicalParties, hometownWeight
                    ])
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no postings found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Postings query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });

        } else if (reportType === 'employers') {

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "Employer Name", "Employer Type","Employer URL","Employer Location","Employer Industry","Employee Count","Employee Growth","Projected Hires","Contact First Name","Contact Last Name","Contact Email","Contact Phone","Contact Title","Subsidy Approved","Subsidy Award","Success Metrics","Volunteer Preferences","Created At","Updated At" ]
                )

                if (this.state.org === 'c2c') {
                  let tempData = csvData[0]
                  tempData = tempData.concat(["Office Type","Political Alignment","State Registration","Congressional District","Additional Information","Host Undocumented"])
                  csvData[0] = tempData
                }

                const employers = response.data.employers
                if (employers && employers.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= employers.length; i++) {
                    console.log(i, ' show employer ', employers[i - 1])

                    let employerName = ''
                    if (employers[i - 1].employerName) {
                      employerName = employers[i - 1].employerName.replace(/,/g,"")
                    }
                    const employerType = employers[i - 1].employerType
                    const employerURL = employers[i - 1].employerURL

                    let employerLocation = employers[i - 1].employerLocation
                    if (employers[i - 1].employerLocation) {
                      employerLocation = employers[i - 1].employerLocation.replace(/,/g,"").replace(/#/g,"")
                    }

                    let employerIndustry = ''
                    if (employers[i - 1].employerIndustry) {
                      employerIndustry = employers[i - 1].employerIndustry.replace(/,/g," | ")
                    }

                    let employeeCount = ''
                    if (employers[i - 1].employeeCount) {
                      employeeCount = employers[i - 1].employeeCount.replace(/,/g,"")
                    }

                    let employeeGrowth = employers[i - 1].employeeGrowth
                    const projectedHires = employers[i - 1].projectedHires

                    const contactFirstName = employers[i - 1].contactFirstName
                    const contactLastName = employers[i - 1].contactLastName
                    const contactEmail = employers[i - 1].contactEmail
                    const contactPhone = employers[i - 1].contactPhone

                    let contactTitle = ''
                    if (employers[i - 1].contactTitle) {
                      contactTitle = employers[i - 1].contactTitle.replace(/,/g," | ")
                    }

                    const subsidyApproved = employers[i - 1].subsidyApproved
                    const subsidyAward = employers[i - 1].subsidyAward

                    let successMetrics = ''
                    if (employers[i - 1].successMetrics) {
                      if (employers[i - 1].successMetrics.length > 0) {
                        successMetrics = employers[i - 1].successMetrics.toString().replace(/,/g," | ")
                      }
                    }

                    let volunteerPreferences = ''
                    if (employers[i - 1].volunteerPreferences) {
                      if (employers[i - 1].volunteerPreferences.length > 0) {
                        volunteerPreferences = employers[i - 1].volunteerPreferences.toString().replace(/,/g," | ")
                      }
                    }

                    const createdAt = employers[i - 1].createdAt
                    const updatedAt = employers[i - 1].updatedAt

                    csvData.push([
                      employerName, employerType, employerURL, employerLocation, employerIndustry,
                      employeeCount, employeeGrowth, projectedHires,
                      contactFirstName, contactLastName, contactEmail, contactPhone, contactTitle,
                      subsidyApproved, subsidyAward,
                      successMetrics, volunteerPreferences, createdAt, updatedAt
                    ])

                    if (this.state.org === 'c2c') {

                      let officeType = ''
                      if (employers[i - 1].officeType) {
                        officeType = employers[i - 1].officeType.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let politicalAlignment = ''
                      if (employers[i - 1].politicalAlignment) {
                        politicalAlignment = employers[i - 1].politicalAlignment.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let stateRegistration = ''
                      if (employers[i - 1].stateRegistration) {
                        stateRegistration = employers[i - 1].stateRegistration.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let congressionalDistrict = ''
                      if (employers[i - 1].congressionalDistrict) {
                        congressionalDistrict = employers[i - 1].congressionalDistrict.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let additionalInformation = ''
                      if (employers[i - 1].additionalInformation) {
                        additionalInformation = employers[i - 1].additionalInformation.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let hostUndocumented = ''
                      if (employers[i - 1].hostUndocumented) {
                        hostUndocumented = employers[i - 1].hostUndocumented.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      // .concat([appRequirements,applicationMethod,jobLink,subjectLine])
                      let tempData2 = csvData[csvData.length - 1]
                      tempData2[csvData[0].length - 6] = officeType
                      tempData2[csvData[0].length - 5] = politicalAlignment
                      tempData2[csvData[0].length - 4] = stateRegistration
                      tempData2[csvData[0].length - 3] = congressionalDistrict
                      tempData2[csvData[0].length - 2] = additionalInformation
                      tempData2[csvData[0].length - 1] = hostUndocumented
                      csvData[csvData.length - 1] = tempData2
                    }
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no postings found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Postings query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });

        } else if (reportType === 'goals') {
          console.log('we in goals')

          Axios.put('/api/reporting/general', { reportType, logType, orgCode, accountCode, placementPartners, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "Title", "First Name", "Last Name","Email","Created","Updated","Goal Type","Description","Start Date","Deadline","Status","Intensity","Budget","Competencies","Success Defined"]
                )

                const logs = response.data.goals
                if (logs && logs.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= logs.length; i++) {

                    let title = ''
                    if (logs[i - 1].title) {
                      title = logs[i - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let creatorFirstName = logs[i - 1].creatorFirstName
                    let creatorLastName = logs[i - 1].creatorLastName
                    let creatorEmail = logs[i - 1].creatorEmail

                    const createdAt = new Date().toString()
                    const updatedAt = new Date().toString()

                    let goalType = ''
                    if (logs[i - 1].goalType) {
                      goalType = logs[i - 1].goalType.name.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let description = ''
                    if (logs[i - 1].description) {
                      description = logs[i - 1].description.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let startDate = ''
                    if (logs[i - 1].startDate) {
                      startDate = new Date(logs[i - 1].startDate).toString()
                    }

                    let deadline = ''
                    if (logs[i - 1].deadline) {
                      deadline = new Date(logs[i - 1].deadline).toString()
                    }

                    let status = ''
                    if (logs[i - 1].status) {
                      status = logs[i - 1].status.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let intensity = ''
                    if (logs[i - 1].intensity) {
                      intensity = logs[i - 1].intensity.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let budget = ''
                    if (logs[i - 1].budget) {
                      budget = logs[i - 1].budget
                    }

                    let competencies = ''
                    if (logs[i - 1].competencies) {
                      for (let j = 1; j <= logs[i - 1].competencies.length; j++) {
                        competencies = competencies + logs[i - 1].competencies[j - 1].name + commaReplacementValue + ' '
                      }
                    }

                    let successDefined = ''
                    if (logs[i - 1].successDefined) {
                      successDefined = logs[i - 1].successDefined.toString().replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    csvData.push([
                      title, creatorFirstName, creatorLastName, creatorEmail, createdAt, updatedAt,
                      goalType, description, startDate, deadline, status, intensity, budget,
                      competencies, successDefined
                    ])

                    console.log('show me employers csvData 1', csvData)
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no postings found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Postings query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });

        } else if (reportType === 'mentorMatches') {
          console.log('we in careerLogs')

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "Mentee First Name", "Mentee Last Name","Mentee Email","Mentor First Name","Mentor Last Name","Mentor Email","Match Score","Active","Start Dates","End Dates","Created At","Updated At"]
                )

                const matches = response.data.mentorMatches
                if (matches && matches.length > 0) {

                  for (let i = 1; i <= matches.length; i++) {

                    const menteeFirstName = matches[i - 1].adviseeFirstName
                    const menteeLastName = matches[i - 1].adviseeLastName
                    const menteeEmail = matches[i - 1].adviseeEmail
                    const mentorFirstName = matches[i - 1].advisorFirstName
                    const mentorLastName = matches[i - 1].advisorLastName
                    const mentorEmail = matches[i - 1].advisorEmail

                    let matchScore = ""
                    if (matches[i - 1].matchScore || matches[i - 1].matchScore === 0) {
                      matchScore = matches[i - 1].matchScore + '%'
                    }

                    let active = "NO"
                    if (matches[i - 1].active) {
                      active = "YES"
                    }
                    console.log('show if active: ', matches[i - 1].active)

                    let startDates = ''
                    if (matches[i - 1].startDates) {
                      for (let j = 1; j <= matches[i - 1].startDates.length; j++) {
                        if (startDates === '') {
                          startDates = matches[i - 1].startDates[j - 1].toString().substring(0,10)
                        } else {
                          startDates = startDates + ' | ' + matches[i - 1].startDates[j - 1].toString().substring(0,10)
                        }
                      }
                      // startDates = matches[i - 1].startDates.join(' | ')
                    }
                    let endDates = ''
                    if (matches[i - 1].endDates) {
                      for (let j = 1; j <= matches[i - 1].endDates.length; j++) {
                        if (endDates === '') {
                          endDates = matches[i - 1].endDates[j - 1].toString().substring(0,10)
                        } else {
                          endDates = endDates + ' | ' + matches[i - 1].endDates[j - 1].toString().substring(0,10)
                        }
                      }
                      // endDates = matches[i - 1].endDates.join(' | ')
                    }

                    const createdAt = matches[i - 1].createdAt
                    const updatedAt = matches[i - 1].updatedAt

                    csvData.push([
                      menteeFirstName, menteeLastName, menteeEmail,
                      mentorFirstName, mentorLastName, mentorEmail,
                      matchScore, active, startDates, endDates, createdAt, updatedAt
                    ])

                    console.log('show me employers csvData 1', csvData)
                  }

                  console.log('show me employers csvData 2', csvData)

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no postings found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Postings query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });

        } else if (reportType === 'members') {

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, pathway, assignedStaffEmail, olderThan, youngerThan, afterDate, beforeDate, resLimit })
          .then((response) => {
              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "First Name", "Last Name","Email","Role Name","Resume","Picture URL","Birth Date","Age","Education Status","School","Degree","Grad Year","Major","Pathway","Job Title","Employer Name", "LinkedIn","Portfolio","Instagram URL", "Facebook URL", "Twitter URL","Education","Badges & Certificates","Alternative Email","Phone Number","Alternative Number","Address","City","State","Zip Code","Country","Language","Race","Races","Self Described Race","Gender","Preferred Pronoun","Veteran Status","Work Authorization","Number of Members in Household","Household Income","Foster Youth","Homeless","Incarcerated","Referrer Name","Referrer Email","Referrer Org","Auto Enroll", "Primary Career Interest","Secondary Career Interest","Tertiary Career Interest","Dream Career","Dream Company", "Career Status", "Five-Year Goal","Industry","Unsubscribed","Unsub Reason","Unsub Other Explanation","Unsub Placed Job Title","Unsub Placed Employer Name", "Public Profile","Headline","Matching Preferences","Matching Use Cases","Interviews","Notes","Feedback","Subsidy Eligible","Passed Workforce Ready Courses","Is WorkReady Certified","Has Work Permit","Is High Potential/Priority","Is Vaccinated","Pathways","Enrollment Status","Created At","Updated At","Adversity List"]
                )

                if (this.state.adversityListOptions && this.state.adversityListOptions.length > 0) {
                  // csvData[0].pop()
                  csvData[0] = csvData[0].concat(this.state.adversityListOptions)
                }

                // age, adversityList

                if (this.state.org === 'c2c') {
                  let tempData = csvData[0]
                  tempData = tempData.concat(["Political Alignment","State Registration","Current Congressional District","Hometown","Home Congressional District","Career Track","DACA Status"])
                  csvData[0] = tempData
                }

                const members = response.data.members
                if (members && members.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= members.length; i++) {
                    console.log(i, ' show signup ', members[i - 1].gradYear)

                    let firstName = members[i - 1].firstName
                    let lastName = members[i - 1].lastName
                    let email = members[i - 1].email
                    const roleName = members[i - 1].roleName
                    const resumeURL = members[i - 1].resumeURL
                    const pictureURL = members[i - 1].pictureURL

                    const dateOfBirth = members[i - 1].dateOfBirth
                    let age = ''
                    if (dateOfBirth) {
                      age = calculateAge(dateOfBirth)
                    }

                    let educationStatus = ''
                    if (members[i - 1].educationStatus) {
                      educationStatus = members[i - 1].educationStatus.replace(/,/g,'')
                    }

                    let school = ''
                    if (members[i - 1].school) {
                      school = members[i - 1].school.replace(/,/g,'').replace(/#/g,"")
                    }
                    // console.log('in school?!', school)

                    let degree = ''
                    if (members[i - 1].degree) {
                      degree = members[i - 1].degree.replace(/,/g,'').replace(/#/g,"")
                    }

                    let gradYear = ''
                    if (members[i - 1].gradYear) {
                      gradYear = members[i - 1].gradYear.replace(/,/g,'').replace(/#/g,"")
                    }

                    let major = ''
                    if (members[i - 1].major) {
                      major = members[i - 1].major.replace(/,/g,'').replace(/#/g,"")
                    }

                    let pathway = ''
                    if (members[i - 1].pathway) {
                      pathway = members[i - 1].pathway.replace(/,/g,'').replace(/#/g,"")
                    }

                    let jobTitle = ''
                    if (members[i - 1].jobTitle) {
                      jobTitle = members[i - 1].jobTitle.replace(/,/g,'').replace(/#/g,"")
                    }

                    let employerName = ''
                    if (members[i - 1].employerName) {
                      employerName = members[i - 1].employerName.replace(/,/g,'').replace(/#/g,"")
                    }

                    let linkedInURL = ''
                    if (members[i - 1].linkedInURL) {
                      linkedInURL = members[i - 1].linkedInURL.replace(/,/g,'').replace(/#/g,"")
                    }

                    let customWebsiteURL = ''
                    if (members[i - 1].customWebsiteURL) {
                      customWebsiteURL = members[i - 1].customWebsiteURL.replace(/,/g,'').replace(/#/g,"")
                    }

                    let instagramURL = ''
                    if (members[i - 1].instagramURL) {
                      instagramURL = members[i - 1].instagramURL.replace(/,/g,'').replace(/#/g,"")
                    }

                    let facebookURL = ''
                    if (members[i - 1].facebookURL) {
                      facebookURL = members[i - 1].facebookURL.replace(/,/g,'').replace(/#/g,"")
                    }

                    let twitterURL = ''
                    if (members[i - 1].twitterURL) {
                      twitterURL = members[i - 1].twitterURL.replace(/,/g,'').replace(/#/g,"")
                    }

                    let education = ''
                    if (members[i - 1].education && members[i - 1].education.length > 0) {
                      education = JSON.stringify(members[i - 1].education).replace(/,/g,'').replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")

                      // school, degree, gradYear, major, pathway
                      for (let j = 1; j <= members[i - 1].education.length; j++) {
                        const edObject = members[i - 1].education[j - 1]

                        // console.log('show me gradYeaar: ', gradYear, major, response.data.user.education)
                        if (edObject) {
                          let tempGradYear = null
                          if (edObject.endDate && edObject.endDate.split("|")) {
                            tempGradYear = edObject.endDate.split(" ")[1]
                          }

                          if (edObject.isContinual) {
                            if (edObject.name) {
                              school = edObject.name.replace(/,/g,'').replace(/#/g,"")
                            }
                            if (tempGradYear) {
                              gradYear = tempGradYear.replace(/,/g,'').replace(/#/g,"")
                            }
                            if (edObject.degree) {
                              degree = edObject.degree.replace(/,/g,'').replace(/#/g,"")
                            }
                            if (edObject.major) {
                              major = edObject.major.replace(/,/g,'').replace(/#/g,"")
                            }

                            // break;
                          } else if ((!tempGradYear || tempGradYear && gradYear && tempGradYear > gradYear) || (tempGradYear && !gradYear)) {
                            if (edObject.name) {
                              school = edObject.name.replace(/,/g,'').replace(/#/g,"")
                            }
                            if (tempGradYear) {
                              gradYear = tempGradYear.replace(/,/g,'').replace(/#/g,"")
                            } else if (edObject.endDate){
                              gradYear = edObject.endDate.replace(/,/g,'').replace(/#/g,"")
                            }
                            if (edObject.degree) {
                              degree = edObject.degree.replace(/,/g,'').replace(/#/g,"")
                            }
                            if (edObject.major) {
                              major = edObject.major.replace(/,/g,'').replace(/#/g,"")
                            }
                          } else {
                            console.log('something else?!?!?!', members[i - 1].email, edObject)
                          }
                        }
                      }
                    }

                    let certificates = ''
                    if (members[i - 1].certificates && members[i - 1].certificates.length > 0) {
                      certificates = JSON.stringify(members[i - 1].certificates).replace(/,/g,'').replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                    }

                    let alternativeEmail = ''
                    if (members[i - 1].alternativeEmail) {
                      alternativeEmail = members[i - 1].alternativeEmail.replace(/,/g,'')
                    }

                    let phoneNumber = ''
                    if (members[i - 1].phoneNumber) {
                      phoneNumber = members[i - 1].phoneNumber.replace(/,/g,'')
                    }

                    let homeNumber = ''
                    if (members[i - 1].alternativePhoneNumber) {
                      homeNumber = members[i - 1].alternativePhoneNumber.replace(/,/g,'')
                    } else if (members[i - 1].homeNumber) {
                      homeNumber = members[i - 1].homeNumber.replace(/,/g,'')
                    }

                    let address = ''
                    if (members[i - 1].address) {
                      address = members[i - 1].address.replace(/,/g,'').replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                    }

                    let city = ''
                    if (members[i - 1].city) {
                      city = members[i - 1].city.replace(/,/g,';').replace(/#/g,"")
                    }

                    let state = ''
                    if (members[i - 1].state) {
                      state = members[i - 1].state.replace(/,/g,';').replace(/#/g,"")
                    }

                    let zipcode = ''
                    if (members[i - 1].zipcode) {
                      zipcode = members[i - 1].zipcode.replace(/,/g,';').replace(/#/g,"")
                    }

                    let country = ''
                    if (members[i - 1].country) {
                      country = members[i - 1].country.replace(/,/g,';').replace(/#/g,"")
                    }

                    let language = ''
                    if (members[i - 1].language) {
                      language = members[i - 1].language.replace(/,/g,';').replace(/#/g,"")
                    }

                    let race = ''
                    if (members[i - 1].race) {
                      race = members[i - 1].race.replace(/,/g,';').replace(/#/g,"").replace(String.fromCharCode(92),String.fromCharCode(92,92));
                    }

                    let races = ''
                    if (members[i - 1].races && members[i - 1].races.length > 0) {
                      races = members[i - 1].races.join(',').replace(/,/g,';')
                    }

                    let selfDescribedRace = ''
                    if (members[i - 1].selfDescribedRace) {
                      selfDescribedRace = members[i - 1].selfDescribedRace.replace(/,/g,';')
                    }

                    const gender = members[i - 1].gender

                    let preferredPronoun = ''
                    if (members[i - 1].preferredPronoun) {
                      preferredPronoun = members[i - 1].preferredPronoun.replace(/,/g,';')
                    }

                    const veteranStatus = members[i - 1].veteranStatus
                    const workAuthorization = members[i - 1].workAuthorization

                    let numberOfMembers = ''
                    if (members[i - 1].numberOfMembers) {
                      numberOfMembers = members[i - 1].numberOfMembers.replace(/,/g,';')
                    }

                    let householdIncome = ''
                    if (members[i - 1].householdIncome) {
                      householdIncome = members[i - 1].householdIncome.replace(/,/g,';')
                    }

                    let fosterYouth = ''
                    if (members[i - 1].fosterYouth) {
                      fosterYouth = members[i - 1].fosterYouth.replace(/,/g,';')
                    }

                    let homeless = ''
                    if (members[i - 1].homeless) {
                      homeless = members[i - 1].homeless.replace(/,/g,';')
                    }

                    let incarcerated = ''
                    if (members[i - 1].incarcerated) {
                      incarcerated = members[i - 1].incarcerated.replace(/,/g,';')
                    }

                    let referrerName = ''
                    if (members[i - 1].referrerName) {
                      referrerName = members[i - 1].referrerName.replace(/,/g,';')
                    }

                    let referrerEmail = ''
                    if (members[i - 1].referrerEmail) {
                      referrerEmail = members[i - 1].referrerEmail.replace(/,/g,';')
                    }

                    let referrerOrg = ''
                    if (members[i - 1].referrerOrg) {
                      referrerOrg = members[i - 1].referrerOrg.replace(/,/g,';').replace(/#/g,"")
                    }

                    let autoEnrollInProgram = ''
                    if (members[i - 1].autoEnrollInProgram) {
                      autoEnrollInProgram = 'TRUE'
                    }

                    let primaryCareerInterest = ''
                    if (members[i - 1].primaryCareerInterest) {
                      primaryCareerInterest = members[i - 1].primaryCareerInterest.replace(/,/g,';').replace(/#/g,"")
                    }

                    let secondaryCareerInterest = ''
                    if (members[i - 1].secondaryCareerInterest) {
                      secondaryCareerInterest = members[i - 1].secondaryCareerInterest.replace(/,/g,';').replace(/#/g,"")
                    }

                    let tertiaryCareerInterest = ''
                    if (members[i - 1].tertiaryCareerInterest) {
                      tertiaryCareerInterest = members[i - 1].tertiaryCareerInterest.replace(/,/g,';').replace(/#/g,"")
                    }

                    let dreamCareer = ''
                    if (members[i - 1].dreamCareer) {
                      dreamCareer = members[i - 1].dreamCareer.replace(/,/g,';').replace(/#/g,"")
                    }

                    let dreamCompany = ''
                    if (members[i - 1].dreamCompany) {
                      dreamCompany = members[i - 1].dreamCompany.replace(/,/g,';').replace(/#/g,"")
                    }

                    let careerStatus = ''
                    if (members[i - 1].careerStatus) {
                      careerStatus = members[i - 1].careerStatus.replace(/,/g,';').replace(/#/g,"")
                    }

                    let fiveYearGoal = ''
                    if (members[i - 1].fiveYearGoal) {
                      fiveYearGoal = members[i - 1].fiveYearGoal.replace(/,/g,';').replace(/#/g,"")
                    }

                    let industry = ''
                    if (members[i - 1].industry) {
                      industry = members[i - 1].industry.replace(/,/g,';').replace(/#/g,"")
                    }

                    let unsubscribed = ''
                    if (members[i - 1].unsubscribed) {
                      unsubscribed = 'YES'
                    }

                    let unsubscribeReason = ''
                    if (members[i - 1].unsubscribeReason) {
                      unsubscribeReason = members[i - 1].unsubscribeReason.replace(/,/g,';')
                    }

                    let otherExplanation = ''
                    if (members[i - 1].otherExplanation) {
                      otherExplanation = members[i - 1].otherExplanation.replace(/,/g,';')
                    }

                    let placedJobTitle = ''
                    if (members[i - 1].placedJobTitle) {
                      placedJobTitle = members[i - 1].placedJobTitle.replace(/,/g,';')
                    }

                    let placedEmployerName = ''
                    if (members[i - 1].placedEmployerName) {
                      placedEmployerName = members[i - 1].placedEmployerName.replace(/,/g,';')
                    }

                    let publicProfile = ''
                    if (members[i - 1].publicProfile) {
                      publicProfile = 'https://www.guidedcompass.com/' + members[i - 1].username + '/profile'
                      // console.log('we got a pp 1: ', publicProfile)
                    }

                    let headline = ''
                    if (members[i - 1].headline) {
                      headline = members[i - 1].headline.replace(/,/g,';')
                    }

                    let matchingPreferences = ''
                    if (members[i - 1].matchingPreferences && members[i - 1].matchingPreferences.length > 0) {
                      for (let j = 1; j <= members[i - 1].matchingPreferences.length; j++) {
                        if (members[i - 1].matchingPreferences[j - 1].name && members[i - 1].matchingPreferences[j - 1].value) {
                          matchingPreferences = matchingPreferences + '; Name: ' + members[i - 1].matchingPreferences[j - 1].name.replace(/,/g,';') + '; Value: ' + members[i - 1].matchingPreferences[j - 1].value
                        }
                      }
                    }

                    let matchingUseCases = ''
                    if (members[i - 1].matchingUseCases && members[i - 1].matchingUseCases.length > 0) {
                      for (let j = 1; j <= members[i - 1].matchingUseCases.length; j++) {
                        if (members[i - 1].matchingUseCases[j - 1].name && members[i - 1].matchingUseCases[j - 1].selected) {
                          matchingUseCases = matchingUseCases + '; Name: ' + members[i - 1].matchingUseCases[j - 1].name.replace(/,/g,';') + '; Selected: ' + members[i - 1].matchingUseCases[j - 1].selected
                        }
                      }
                    }

                    let interviews = ''
                    let notes = ''
                    let feedback = ''
                    let isSubsidyEligible = ''
                    let isWorkforceReady = ''
                    let isWorkReadyCertified = ''

                    if (members[i - 1].evaluations) {
                      for (let j = 1; j <= members[i - 1].evaluations.length; j++) {
                        if (members[i - 1].evaluations[j - 1].orgCode === this.state.org) {
                          if (members[i - 1].evaluations[j - 1].interviews) {
                            for (let k = 1; k <= members[i - 1].evaluations[j - 1].interviews.length; k++) {
                              const iName = 'Name: ' + members[i - 1].evaluations[j - 1].interviews[k - 1].interviewerName
                              const iDate = 'Date: ' + members[i - 1].evaluations[j - 1].interviews[k - 1].date
                              const iScore = 'Score: ' + members[i - 1].evaluations[j - 1].interviews[k - 1].score
                              const iNotes = 'Notes: ' + members[i - 1].evaluations[j - 1].interviews[k - 1].notes
                              interviews = interviews + iName + '; ' + iDate + '; ' + iScore + '; ' + iNotes + '; '
                            }
                          }

                          if (members[i - 1].evaluations[j - 1].notes) {
                            notes = members[i - 1].evaluations[j - 1].notes
                          }

                          if (members[i - 1].evaluations[j - 1].feedback) {
                            feedback = members[i - 1].evaluations[j - 1].feedback
                          }

                          if (members[i - 1].evaluations[j - 1].isSubsidyEligible) {
                            isSubsidyEligible = members[i - 1].evaluations[j - 1].isSubsidyEligible
                          }

                          if (members[i - 1].evaluations[j - 1].isWorkforceReady) {
                            isWorkforceReady = members[i - 1].evaluations[j - 1].isWorkforceReady
                          }

                          if (members[i - 1].evaluations[j - 1].isWorkReadyCertified) {
                            isWorkReadyCertified = members[i - 1].evaluations[j - 1].isWorkReadyCertified
                          }
                        }
                      }
                    }

                    let hasWorkPermit = members[i - 1].hasWorkPermit
                    const isHighPotential = members[i - 1].isHighPotential

                    let isVaccinated = ''
                    if (members[i - 1].isVaccinated) {
                      isVaccinated = members[i - 1].isVaccinated
                    }

                    let pathways = ''
                    if (members[i - 1].pathways && members[i - 1].pathways.length > 0) {
                      for (let j = 1; j <= members[i - 1].pathways.length; j++) {
                        if (members[i - 1].pathways[j - 1].title) {
                          if (pathways !== '') {
                            pathways = pathways + '; ' + members[i - 1].pathways[j - 1].title.replace(/,/g,';')
                          } else {
                            pathways = pathways + members[i - 1].pathways[j - 1].title.replace(/,/g,';')
                          }
                        }
                      }
                    }

                    let enrollmentStatus = ''
                    if (members[i - 1].enrollmentStatus) {
                      enrollmentStatus = members[i - 1].enrollmentStatus
                    }

                    let createdAt = members[i - 1].createdAt
                    if (!createdAt) {
                      createdAt = ''
                    }

                    let updatedAt = members[i - 1].updatedAt
                    if (!updatedAt) {
                      updatedAt = ''
                    }

                    console.log('show the address and number: ', address, phoneNumber)

                    let adversityList = ''
                    if (this.state.adversityListOptions && this.state.adversityListOptions.length > 0) {
                      if (members[i - 1].adversityList && members[i - 1].adversityList.length > 0) {
                        adversityList = members[i - 1].adversityList.join(',').replace(/,/g,';')
                      }

                      let dataArray = [
                        firstName, lastName, email, roleName, resumeURL, pictureURL, dateOfBirth, age,
                        educationStatus, school, degree, gradYear, major, pathway,
                        jobTitle, employerName, linkedInURL, customWebsiteURL,
                        instagramURL, facebookURL, twitterURL, education, certificates,
                        alternativeEmail, phoneNumber, homeNumber,
                        address, city, state, zipcode, country, language,
                        race, races, selfDescribedRace,
                        gender, preferredPronoun, veteranStatus, workAuthorization,
                        numberOfMembers, householdIncome, fosterYouth, homeless, incarcerated,
                        referrerName, referrerEmail, referrerOrg, autoEnrollInProgram,
                        primaryCareerInterest, secondaryCareerInterest, tertiaryCareerInterest,
                        dreamCareer, dreamCompany, careerStatus, fiveYearGoal, industry,
                        unsubscribed, unsubscribeReason, otherExplanation, placedJobTitle, placedEmployerName,
                        publicProfile, headline, matchingPreferences, matchingUseCases,
                        interviews, notes, feedback, isSubsidyEligible, isWorkforceReady,
                        isWorkReadyCertified, hasWorkPermit, isHighPotential, isVaccinated,
                        pathways, enrollmentStatus, createdAt, updatedAt, adversityList
                      ]
                      for (let j = 1; j <= this.state.adversityListOptions.length; j++) {
                        if (members[i - 1].adversityList && members[i - 1].adversityList.includes(this.state.adversityListOptions[j - 1])) {
                          dataArray.push("TRUE")
                        } else {
                          dataArray.push("FALSE")
                        }
                      }
                      csvData.push(dataArray)

                    } else {
                      if (members[i - 1].adversityList && members[i - 1].adversityList.length > 0) {
                        adversityList = members[i - 1].adversityList.join(',').replace(/,/g,';')
                      }

                      csvData.push([
                        firstName, lastName, email, roleName, resumeURL, pictureURL, dateOfBirth, age,
                        educationStatus, school, degree, gradYear, major, pathway,
                        jobTitle, employerName, linkedInURL, customWebsiteURL,
                        instagramURL, facebookURL, twitterURL, education, certificates,
                        alternativeEmail, phoneNumber, homeNumber,
                        address, city, state, zipcode, country, language,
                        race, races, selfDescribedRace,
                        gender, preferredPronoun, veteranStatus, workAuthorization,
                        numberOfMembers, householdIncome, fosterYouth, homeless, incarcerated,
                        referrerName, referrerEmail, referrerOrg, autoEnrollInProgram,
                        primaryCareerInterest, secondaryCareerInterest, tertiaryCareerInterest,
                        dreamCareer, dreamCompany, careerStatus, fiveYearGoal, industry,
                        unsubscribed, unsubscribeReason, otherExplanation, placedJobTitle, placedEmployerName,
                        publicProfile, headline, matchingPreferences, matchingUseCases,
                        interviews, notes, feedback, isSubsidyEligible, isWorkforceReady,
                        isWorkReadyCertified, hasWorkPermit, isHighPotential, isVaccinated,
                        pathways, enrollmentStatus, createdAt, updatedAt, adversityList
                      ])
                    }

                    if (this.state.org === 'c2c') {

                      let politicalAlignment = ''
                      if (members[i - 1].politicalAlignment) {
                        politicalAlignment = members[i - 1].politicalAlignment.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let stateRegistration = ''
                      if (members[i - 1].stateRegistration) {
                        stateRegistration = members[i - 1].stateRegistration.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/,/g,"")
                      }

                      let currentCongressionalDistrict = ''
                      if (members[i - 1].currentCongressionalDistrict) {
                        currentCongressionalDistrict = members[i - 1].currentCongressionalDistrict.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let hometown = ''
                      if (members[i - 1].hometown) {
                        hometown = members[i - 1].hometown.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let homeCongressionalDistrict = ''
                      if (members[i - 1].homeCongressionalDistrict) {
                        homeCongressionalDistrict = members[i - 1].homeCongressionalDistrict.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let careerTrack = ''
                      if (members[i - 1].careerTrack) {
                        careerTrack = members[i - 1].careerTrack.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      let dacaStatus = ''
                      if (members[i - 1].dacaStatus) {
                        dacaStatus = members[i - 1].dacaStatus.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      // .concat([appRequirements,applicationMethod,jobLink,subjectLine])
                      let tempData2 = csvData[csvData.length - 1]
                      tempData2[csvData[0].length - 7] = politicalAlignment
                      tempData2[csvData[0].length - 6] = stateRegistration
                      tempData2[csvData[0].length - 5] = currentCongressionalDistrict
                      tempData2[csvData[0].length - 4] = hometown
                      tempData2[csvData[0].length - 3] = homeCongressionalDistrict
                      tempData2[csvData[0].length - 2] = careerTrack
                      tempData2[csvData[0].length - 1] = dacaStatus
                      csvData[csvData.length - 1] = tempData2
                    }

                  }

                  console.log('show me signup csvData 2', csvData)

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no postings found', response.data.message)

                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Postings query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });

        } else if (reportType === 'scores') {
          console.log('got in scores')

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                const scores = response.data.scores
                const applicationArray = response.data.applicationArray

                let cat1 = ''
                let cat2 = ''
                let cat3 = ''
                let cat4 = ''
                let cat5 = ''
                if (scores[0] && scores[0].suppScores) {
                  if (scores[0].suppScores[0] && scores[0].suppScores[0].category) {
                    cat1 = scores[0].suppScores[0].category.replace(/#/g,"")
                  }
                  if (scores[0].suppScores[1] && scores[0].suppScores[1].category) {
                    cat2 = scores[0].suppScores[1].category.replace(/#/g,"")
                  }
                  if (scores[0].suppScores[2] && scores[0].suppScores[2].category) {
                    cat3 = scores[0].suppScores[2].category.replace(/#/g,"")
                  }
                  if (scores[0].suppScores[3] && scores[0].suppScores[3].category) {
                    cat4 = scores[0].suppScores[3].category.replace(/#/g,"")
                  }
                  if (scores[0].suppScores[4] && scores[0].suppScores[4].category) {
                    cat5 = scores[0].suppScores[4].category.replace(/#/g,"")
                  }
                }

                csvData.push(
                  [ "Scorer First Name", "Scorer Last Name","Scorer Email","Application Id","Applicant First Name","Applicant Last Name","Applicant Email","Overall Score", cat1, cat2, cat3, cat4, cat5]
                )

                if (scores && scores.length > 0) {

                  // let commaStartingValue = /,/g
                  // let commaReplacementValue = ";"

                  for (let i = 1; i <= scores.length; i++) {
                    console.log(i, ' scores ', scores[i - 1])

                    const scorerFirstName = scores[i - 1].scorer.firstName
                    const scorerLastName = scores[i - 1].scorer.lastName
                    const scorerEmail = scores[i - 1].scorer.email
                    const applicationId = applicationArray[i - 1]._id
                    const applicantFirstName = applicationArray[i - 1].firstName
                    const applicantLastName = applicationArray[i - 1].lastName
                    const applicantEmail = applicationArray[i - 1].email
                    const overallScore = scores[i - 1].overallScore

                    // ["Overall Score","Category 1 Score","Category Score 2"]
                    let score1 = ''
                    let score2 = ''
                    let score3 = ''
                    let score4 = ''
                    let score5 = ''

                    if (scores[i - 1].suppScores) {
                      for (let j = 1; j <= scores[i - 1].suppScores.length; j++) {
                        if (scores[i - 1].suppScores[j - 1] && scores[i - 1].suppScores[j - 1].score) {
                          if (j === 1) {
                            score1 = scores[i - 1].suppScores[j - 1].score
                          }
                          if (j === 2) {
                            score2 = scores[i - 1].suppScores[j - 1].score
                          }
                          if (j === 3) {
                            score3 = scores[i - 1].suppScores[j - 1].score
                          }
                          if (j === 4) {
                            score4 = scores[i - 1].suppScores[j - 1].score
                          }
                          if (j === 5) {
                            score5 = scores[i - 1].suppScores[j - 1].score
                          }

                        }
                      }
                    }

                    csvData.push([
                      scorerFirstName, scorerLastName, scorerEmail, applicationId,
                      applicantFirstName, applicantLastName, applicantEmail, overallScore,
                      score1, score2, score3, score4, score5
                    ])
                  }

                  console.log('show me signup csvData 2', csvData)
                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no postings found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Postings query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });

        } else if (reportType === 'challengeVoting') {
          console.log('got in challengeVoiting')

          if (selectedChallenge && selectedChallenge.submissions && selectedChallenge.submissions.length > 0) {

            let submissions = selectedChallenge.submissions

            csvData.push([])
            let maxVoteCount = 0
            for (let i = 1; i <= submissions.length; i++) {
              csvData[0].push(submissions[i - 1].name + ' by ' + submissions[i - 1].userEmail)
              if (submissions[i - 1].upvotes && submissions[i - 1].upvotes.length > maxVoteCount) {
                maxVoteCount = submissions[i - 1].upvotes.length
              }
            }

            for (let i = 1; i <= maxVoteCount; i++) {
              console.log(i, ' show vote ')

              let voteEmails = []
              for (let j = 1; j <= submissions.length; j++) {
                if (submissions[j - 1].upvotes && submissions[j - 1].upvotes[i - 1]) {
                  voteEmails.push(submissions[j - 1].upvotes[i - 1])
                }
              }

              csvData.push(voteEmails)

            }

            if (previewMode) {
              this.configurePreview(csvData)
            } else {
              this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
            }

          } else {
            this.setState({ errorMessage, isLoading: false})
          }
        } else if (reportType === 'placements') {

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, department, pathway, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                let individualPlacements = true

                if (individualPlacements) {
                  csvData.push(
                    [ "First Name","Last Name","Email","Title","Employer Name","Work Type","Employer URL","Hours Per Week","Industry","Work Function","Location","Start Date","End Date","Pay Type","Pay", "Description", "Hiring Manager First Name","Hiring Manager Last Name","Hiring Manager Email","Internal Contact Email","Pathways","Departments","Employment Hour Details","Academic Learning Objectives","Career Development Objectives","Site Visit Schedule","Pathway Placement Type","Picture URL","Birth Date","Age","Education Status","Education","Alternative Email","Phone Number","Alternative Number","Address","City","Zip Code","Race","Races","Self Described Race","Gender","Veteran Status","Work Authorization","Number of Members in Household","Household Income","Not enrolled/attending school",	"Not currently working","LGBTQIA+","First-Generation Immigrant (born in another country without U.S. citizenship)","No parent/guardian completed a four-year college degree (Bachelor’s degree)","Currently/formerly a foster youth",	"Justice Involved","Currently without stable housing (home insecure or homeless)","Pregnant/Parenting","Person with disability","Veteran","Receiving Free or Reduced-Price Lunch",	"Receiving Food Stamps","Receiving TANF",	"Receiving General Assistance","Receiving SSI","Receiving Refugee Cash Assistance","Other Demographic Information","Created At", "Updated At" ]
                  )

                  const placements = response.data.placements
                  if (placements && placements.length > 0) {

                    let commaStartingValue = /,/g
                    let commaReplacementValue = ";"

                    for (let i = 1; i <= placements.length; i++) {

                      if (placements[i - 1].workers) {
                        for (let j = 1; j <= placements[i - 1].workers.length; j++) {
                          let worker = placements[i - 1].workers[j - 1]

                          let firstName = ''
                          if (worker.firstName) {
                            firstName = worker.firstName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                          }

                          let lastName = ''
                          if (worker.lastName) {
                            lastName = worker.lastName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                          }

                          let email = ''
                          if (worker.email) {
                            email = worker.email.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                          }

                          let title = ''
                          if (placements[i - 1].title) {
                            title = placements[i - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                          }

                          let employerName = ''
                          if (placements[i - 1].employerName) {
                            employerName = placements[i - 1].employerName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                          }

                          const workType = placements[i - 1].workType
                          const employerURL = placements[i - 1].employerURL
                          const hoursPerWeek = placements[i - 1].hoursPerWeek
                          const industry = placements[i - 1].industry
                          const workFunction = placements[i - 1].workFunction
                          const location = placements[i - 1].location
                          const startDate = placements[i - 1].startDate
                          let endDate = placements[i - 1].endDate
                          if (placements[i - 1].isPerpetual) {
                            endDate = 'Continual'
                          }
                          const payType = placements[i - 1].payType
                          const pay = placements[i - 1].pay
                          let description = placements[i - 1].description
                          // if (placements[i - 1].description) {
                          //   description = placements[i - 1].description.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                          // }

                          let hiringManagerFirstName = placements[i - 1].hiringManagerFirstName
                          if (placements[i - 1].hiringManagerFirstName) {
                            hiringManagerFirstName = placements[i - 1].hiringManagerFirstName.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                          }
                          let hiringManagerLastName = placements[i - 1].hiringManagerLastName
                          if (placements[i - 1].hiringManagerLastName) {
                            hiringManagerLastName = placements[i - 1].hiringManagerLastName.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                          }
                          const hiringManagerEmail = placements[i - 1].hiringManagerEmail
                          const orgContactEmail = placements[i - 1].orgContactEmail

                          // DPSCD
                          let pathways = ''
                          if (placements[i - 1].pathways && placements[i - 1].pathways.length > 0) {
                            for (let j = 1; j <= placements[i - 1].pathways.length; j++) {
                              if (placements[i - 1].pathways[j - 1] && placements[i - 1].pathways[j - 1].title) {
                                if (pathways === '') {
                                  pathways = placements[i - 1].pathways[j - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                                } else {
                                  pathways = pathways + '; ' + placements[i - 1].pathways[j - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                                }
                              }
                            }
                          }

                          let departments = ''
                          if (placements[i - 1].departments) {
                            for (let j = 1; j <= placements[i - 1].departments.length; j++) {
                              if (placements[i - 1].departments[j - 1] && placements[i - 1].departments[j - 1].name) {
                                if (departments === '') {
                                  departments = placements[i - 1].departments[j - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                                } else {
                                  departments = departments + '; ' + placements[i - 1].departments[j - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                                }
                              }
                            }
                          }

                          let employmentHourDetails = ''
                          let academicLearningObjectives = ''
                          let careerDevelopmentObjectives = ''
                          let siteVisitSchedule = ''
                          let pathwayPlacementType = ''

                          let pictureURL = worker.pictureURL
                          let dateOfBirth = worker.dateOfBirth
                          let age = ''
                          if (dateOfBirth) {
                            age = calculateAge(dateOfBirth)
                          }
                          let educationStatus = worker.educationStatus
                          if (worker.educationStatus) {
                            educationStatus = worker.educationStatus.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                          }
                          let education = ""
                          if (worker.education && worker.education.length > 0) {
                            education = JSON.stringify(worker.education).replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                          }
                          let alternativeEmail = worker.alternativeEmail
                          let phoneNumber = worker.phoneNumber
                          let alternativePhoneNumber = worker.alternativePhoneNumber
                          let address = worker.address
                          let city = worker.city
                          let zipcode = worker.zipcode
                          let race = worker.race
                          let races = worker.races
                          // if (worker.races) {
                          //   races = worker.races.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                          // }
                          let selfDescribedRace = worker.selfDescribedRace
                          let gender = worker.gender
                          let veteranStatus = worker.veteranStatus
                          let workAuthorization = worker.workAuthorization
                          let numberOfMembers = worker.numberOfMembers
                          let householdIncome = worker.householdIncome

                          let notEnrolled = ""
                          let notWorking = ""
                          let lgbtqia = ""
                          let firstGenImmigrant = ""
                          let firstGenCollege = ""
                          let fosterYouth = ""
                          let incarcerated = ""
                          let homeless = ""
                          let pregnant = ""
                          let disabled = ""
                          let veteran = ""
                          let freeLunch = ""
                          let foodStamps = ""
                          let tanf = ""
                          let generalAssistance = ""
                          let securityAssistance = ""
                          let refugeeAssistance = ""
                          if (worker.adversityList && worker.adversityList.length > 0) {
                            if (worker.adversityList.includes("Not enrolled/attending school")) {
                              notEnrolled = "Yes"
                            } else if (worker.adversityList.includes("Not currently working")) {
                              notWorking = "Yes"
                            } else if (worker.adversityList.includes("LGBTQIA+")) {
                              lgbtqia = "Yes"
                            } else if (worker.adversityList.includes("First-Generation Immigrant (born in another country without U.S. citizenship)")) {
                              firstGenImmigrant = "Yes"
                            } else if (worker.adversityList.includes("No parent/guardian completed a four-year college degree (Bachelor’s degree)")) {
                              firstGenCollege = "Yes"
                            } else if (worker.adversityList.includes("Currently/formerly a foster youth")) {
                              fosterYouth = "Yes"
                            } else if (worker.adversityList.includes("Justice Involved")) {
                              incarcerated = "Yes"
                            } else if (worker.adversityList.includes("Currently without stable housing (home insecure or homeless)")) {
                              homeless = "Yes"
                            } else if (worker.adversityList.includes("Pregnant/Parenting")) {
                              pregnant = "Yes"
                            } else if (worker.adversityList.includes("Person with disability")) {
                              disabled = "Yes"
                            } else if (worker.adversityList.includes("Veteran")) {
                              veteran = "Yes"
                            } else if (worker.adversityList.includes("Receiving Free or Reduced-Price Lunch")) {
                              freeLunch = "Yes"
                            } else if (worker.adversityList.includes("Receiving Food Stamps")) {
                              foodStamps = "Yes"
                            } else if (worker.adversityList.includes("Receiving TANF")) {
                              tanf = "Yes"
                            } else if (worker.adversityList.includes("Receiving General Assistance")) {
                              generalAssistance = "Yes"
                            } else if (worker.adversityList.includes("Receiving SSI")) {
                              securityAssistance = "Yes"
                            } else if (worker.adversityList.includes("Receiving Refugee Cash Assistance")) {
                              refugeeAssistance = "Yes"
                            }
                          }

                          let otherDemographicInformation = ""
                          if (worker.adversityList && worker.adversityList.length > 0) {
                            otherDemographicInformation = JSON.stringify(worker.adversityList).replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                          }

                          const createdAt = placements[i - 1].createdAt
                          const updatedAt = placements[i - 1].updatedAt

                          csvData.push([
                            firstName, lastName, email, title, employerName, workType, employerURL, hoursPerWeek, industry, workFunction, location,
                            startDate, endDate, payType, pay, description,
                            hiringManagerFirstName, hiringManagerLastName, hiringManagerEmail, orgContactEmail,
                            pathways, departments, employmentHourDetails,
                            academicLearningObjectives, careerDevelopmentObjectives, siteVisitSchedule, pathwayPlacementType,
                            pictureURL, dateOfBirth, age, educationStatus, education, alternativeEmail, phoneNumber, alternativePhoneNumber,
                            address, city, zipcode, race, races, selfDescribedRace, gender, veteranStatus, workAuthorization,
                            numberOfMembers, householdIncome,
                            notEnrolled, notWorking, lgbtqia, firstGenImmigrant, firstGenCollege, fosterYouth, incarcerated,
                            homeless, pregnant, disabled, veteran, freeLunch, foodStamps, tanf, generalAssistance, securityAssistance, refugeeAssistance,
                            otherDemographicInformation,
                            createdAt, updatedAt
                          ])
                        }
                      }
                    }

                    if (previewMode) {
                      this.configurePreview(csvData)
                    } else {
                      this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                    }

                  } else {
                    this.setState({ errorMessage, isLoading: false})
                  }
                } else {
                  csvData.push(
                    [ "ID","Title","Employer Name","Work Type","Employer URL","Hours Per Week","Industry","Work Function","Location","Start Date", "End Date","Pay Type","Pay", "Description","Placement Count","Workers", "Hiring Manager First Name","Hiring Manager Last Name","Hiring Manager Email","Org Contact Email","Pathways","Departments","Employment Hour Details","Academic Learning Objectives","Career Development Objectives","Site Visit Schedule","Pathway Placement Type", "Created At", "Updated At" ]
                  )

                  const placements = response.data.logs
                  if (placements && placements.length > 0) {

                    let commaStartingValue = /,/g
                    let commaReplacementValue = ";"

                    for (let i = 1; i <= placements.length; i++) {

                      const _id = placements[i - 1]._id

                      let title = ''
                      if (placements[i - 1].title) {
                        title = placements[i - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                      }

                      let employerName = ''
                      if (placements[i - 1].employerName) {
                        employerName = placements[i - 1].employerName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                      }

                      const workType = placements[i - 1].workType
                      const employerURL = placements[i - 1].employerURL
                      const hoursPerWeek = placements[i - 1].hoursPerWeek
                      const industry = placements[i - 1].industry
                      const workFunction = placements[i - 1].workFunction
                      const location = placements[i - 1].location
                      const startDate = placements[i - 1].startDate
                      let endDate = placements[i - 1].endDate
                      if (placements[i - 1].isPerpetual) {
                        endDate = 'Continual'
                      }
                      const payType = placements[i - 1].payType
                      const pay = placements[i - 1].pay
                      const description = placements[i - 1].description

                      let placementCount = 0
                      let workers = []

                      if (placements[i - 1].workers) {
                        placementCount = placements[i - 1].workers.length
                        for (let j = 1; j <= placements[i - 1].workers.length; j++) {
                          if (workers.length > 0) {
                            workers.push(" | " + placements[i - 1].workers[j - 1].email)
                          } else {
                            workers.push(placements[i - 1].workers[j - 1].email)
                          }
                        }
                      }

                      const hiringManagerFirstName = placements[i - 1].hiringManagerFirstName
                      const hiringManagerLastName = placements[i - 1].hiringManagerLastName
                      const hiringManagerEmail = placements[i - 1].hiringManagerEmail
                      const orgContactEmail = placements[i - 1].orgContactEmail

                      // DPSCD
                      let pathways = ''
                      if (placements[i - 1].pathways && placements[i - 1].pathways.length > 0) {
                        for (let j = 1; j <= placements[i - 1].pathways.length; j++) {
                          if (placements[i - 1].pathways[j - 1] && placements[i - 1].pathways[j - 1].title) {
                            if (pathways === '') {
                              pathways = placements[i - 1].pathways[j - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            } else {
                              pathways = pathways + '; ' + placements[i - 1].pathways[j - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            }
                          }
                        }
                      }

                      let departments = ''
                      if (placements[i - 1].departments) {
                        for (let j = 1; j <= placements[i - 1].departments.length; j++) {
                          if (placements[i - 1].departments[j - 1] && placements[i - 1].departments[j - 1].name) {
                            if (departments === '') {
                              departments = placements[i - 1].departments[j - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            } else {
                              departments = departments + '; ' + placements[i - 1].departments[j - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                            }
                          }
                        }
                      }

                      let employmentHourDetails = ''
                      let academicLearningObjectives = ''
                      let careerDevelopmentObjectives = ''
                      let siteVisitSchedule = ''
                      let pathwayPlacementType = ''

                      const createdAt = placements[i - 1].createdAt
                      const updatedAt = placements[i - 1].updatedAt

                      csvData.push([
                        _id, title, employerName, workType, employerURL, hoursPerWeek, industry, workFunction, location,
                        startDate, endDate, payType, pay, description, placementCount, workers,
                        hiringManagerFirstName, hiringManagerLastName, hiringManagerEmail, orgContactEmail,
                        pathways, departments, employmentHourDetails,
                        academicLearningObjectives, careerDevelopmentObjectives, siteVisitSchedule, pathwayPlacementType,
                        createdAt, updatedAt
                      ])
                    }

                    if (previewMode) {
                      this.configurePreview(csvData)
                    } else {
                      this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                    }

                  } else {
                    this.setState({ errorMessage, isLoading: false})
                  }
                }

              } else {
                console.log('no placements found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Placements query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });
        } else if (reportType === 'messages') {

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "ID","Sender First Name","Sender Last Name","Sender Email","Recipient First Name","Recipient Last Name","Recipient Email","Message","Created At" ]
                )

                const logs = response.data.messages
                if (logs && logs.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= logs.length; i++) {

                    const _id = logs[i - 1]._id

                    let senderFirstName = ''
                    if (logs[i - 1].senderFirstName) {
                      senderFirstName = logs[i - 1].senderFirstName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let senderLastName = ''
                    if (logs[i - 1].senderLastName) {
                      senderLastName = logs[i - 1].senderLastName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let senderEmail = ''
                    if (logs[i - 1].senderEmail) {
                      senderEmail = logs[i - 1].senderEmail.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let recipientFirstName = ''
                    let recipientLastName = ''
                    let recipientEmail = ''

                    if (logs[i - 1].recipients[0]) {
                      recipientFirstName = logs[i - 1].recipients[0].firstName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                      recipientLastName = logs[i - 1].recipients[0].lastName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                      recipientEmail = logs[i - 1].recipients[0].email.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let message = ''
                    if (logs[i - 1].message) {
                      message = logs[i - 1].message.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    const createdAt = logs[i - 1].createdAt

                    csvData.push([
                      _id, senderFirstName, senderLastName, senderEmail,
                      recipientFirstName, recipientLastName, recipientEmail,
                      message, createdAt
                    ])
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no placements found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Placements query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });

        } else if (reportType === 'timesheets') {

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "Worker First Name","Worker Last Name","Worker Email","Work Type","Title","Employer Name","Day","Hours","Tasks","Manager First Name","Manager Last Name","Manager Email","Assignments","Projects","Worker Rating","Worker Feedback","Manager Rating","Manager Feedback","Message","Is Submitted","Is Approved","Is Rejected","Week", "Created At","Updated At" ]
                )

                const objects = response.data.timesheets
                if (objects && objects.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= objects.length; i++) {

                    let workerFirstName = ''
                    if (objects[i - 1].workerFirstName) {
                      workerFirstName = objects[i - 1].workerFirstName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let workerLastName = ''
                    if (objects[i - 1].workerLastName) {
                      workerLastName = objects[i - 1].workerLastName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let workerEmail = ''
                    if (objects[i - 1].emailId) {
                      workerEmail = objects[i - 1].emailId.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    const workType = objects[i - 1].workType

                    let title = ''
                    if (objects[i - 1].title) {
                      title = objects[i - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let employerName = ''
                    if (objects[i - 1].employerName) {
                      employerName = objects[i - 1].employerName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let managerFirstName = ''
                    if (objects[i - 1].managerFirstName) {
                      managerFirstName = objects[i - 1].managerFirstName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let managerLastName = ''
                    if (objects[i - 1].managerLastName) {
                      managerLastName = objects[i - 1].managerLastName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let managerEmail = ''
                    if (objects[i - 1].managerEmail) {
                      managerEmail = objects[i - 1].managerEmail.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let assignments = ''
                    if (objects[i - 1].assignments && objects[i - 1].assignments.length > 0) {
                      for (let j = 1; j <= objects[i - 1].assignments.length; j++) {
                        if (objects[i - 1].assignments[j - 1] && objects[i - 1].assignments[j - 1].name) {
                          if (assignments !== '') {
                            assignments = assignments + '; '
                          }
                          assignments = assignments + objects[i - 1].assignments[j - 1].name
                        }
                      }
                    }

                    let projects = ''
                    if (objects[i - 1].projects && objects[i - 1].projects.length > 0) {
                      for (let j = 1; j <= objects[i - 1].projects.length; j++) {
                        if (objects[i - 1].projects[j - 1] && objects[i - 1].projects[j - 1].name) {
                          if (projects !== '') {
                            projects = projects + '; '
                          }
                          projects = projects + objects[i - 1].projects[j - 1].name
                        }
                      }
                    }

                    let workerRating = ''
                    if (objects[i - 1].workerRating) {
                      workerRating = objects[i - 1].workerRating.toString()
                    }

                    let workerFeedback = ''
                    if (objects[i - 1].workerFeedback) {
                      workerFeedback = objects[i - 1].workerFeedback.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let managerRating = ''
                    if (objects[i - 1].managerRating) {
                      managerRating = objects[i - 1].managerRating.toString()
                    }

                    let managerFeedback = ''
                    if (objects[i - 1].managerFeedback) {
                      managerFeedback = objects[i - 1].managerFeedback.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let message = ''
                    if (objects[i - 1].message) {
                      message = objects[i - 1].message.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let isSubmitted = ''
                    if (objects[i - 1].isSubmitted || objects[i - 1].isSubmitted === false) {
                      isSubmitted = objects[i - 1].isSubmitted.toString().toUpperCase()
                    }

                    let isApproved = ''
                    if (objects[i - 1].isApproved || objects[i - 1].isApproved === false) {
                      isApproved = objects[i - 1].isApproved.toString().toUpperCase()
                    }

                    let isRejected = ''
                    if (objects[i - 1].isRejected || objects[i - 1].isRejected === false) {
                      isRejected = objects[i - 1].isRejected.toString().toUpperCase()
                    }

                    let week = ''
                    if (objects[i - 1].startDate && objects[i - 1].endDate) {
                      const startDate = convertDateToString(new Date(objects[i - 1].startDate),"datetime-2")
                      const endDate = convertDateToString(new Date(objects[i - 1].endDate),"datetime-2")
                      week = startDate + " " + endDate
                    }

                    let createdAt = ''
                    if (objects[i - 1].createdAt) {
                      createdAt = convertDateToString(new Date(objects[i - 1].createdAt),"datetime-2")
                    }

                    let updatedAt = ''
                    if (objects[i - 1].updatedAt) {
                      updatedAt = convertDateToString(new Date(objects[i - 1].updatedAt),"datetime-2")
                    }

                    let day = ''
                    let hours = ''
                    let tasks = ''
                    if (objects[i - 1].cards && objects[i - 1].cards.length > 0) {
                      for (let j = 1; j <= objects[i - 1].cards.length; j++) {
                        if (objects[i - 1].cards[j - 1]) {
                          if (objects[i - 1].cards[j - 1].day) {
                            day = objects[i - 1].cards[j - 1].day
                          }
                          if (objects[i - 1].cards[j - 1].hours) {
                            hours = objects[i - 1].cards[j - 1].hours
                          }
                          if (objects[i - 1].cards[j - 1].tasks) {
                            tasks = objects[i - 1].cards[j - 1].tasks
                          }

                          csvData.push([
                            workerFirstName, workerLastName, workerEmail,
                            workType, title, employerName,
                            day, hours, tasks, managerFirstName, managerLastName, managerEmail,
                            assignments, projects,
                            workerRating, workerFeedback, managerRating, managerFeedback, message,
                            isSubmitted, isApproved, isRejected,
                            week, createdAt, updatedAt
                          ])
                        }
                      }
                    }
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no timesheets found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Timesheets query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });

        } else if (reportType === 'courses') {

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "Title","Course Unique ID","Program URL","Image URL","Category","School Name","School URL","Degree Type","Delivery Method","Location","Estimated Hours","Grade Level","Knowledge Level","Main Contact First Name","Main Contact Last Name","Main Contact Email","Main Contact Title","Is Active","Price","Industry","Functions","Pathways","Sample Job Titles","Outcomes","Source","Description", "Created At","Updated At" ]
                )

                const objects = response.data.courses
                if (objects && objects.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= objects.length; i++) {

                    let title = ''
                    if (objects[i - 1].name || objects[i - 1].title) {
                      if (objects[i - 1].name) {
                        title = objects[i - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                      } else if (objects[i - 1].title) {
                        title = objects[i - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                      }
                    }

                    let courseId = ''
                    if (objects[i - 1]._id) {
                      courseId = objects[i - 1]._id.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let programURL = ''
                    if (objects[i - 1].programURL || objects[i - 1].url) {
                      if (objects[i - 1].programURL) {
                        programURL = objects[i - 1].programURL.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                      } else if (objects[i - 1].url && objects[i - 1].source === 'Udemy'){
                        programURL = 'https://www.udemy.com' + objects[i - 1].url.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                      }
                    }

                    let imageURL = ''
                    if (objects[i - 1].imageURL) {
                      imageURL = objects[i - 1].imageURL.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let category = ''
                    if (objects[i - 1].category) {
                      category = objects[i - 1].category.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let schoolName = ''
                    if (objects[i - 1].schoolName) {
                      schoolName = objects[i - 1].schoolName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let schoolURL = ''
                    if (objects[i - 1].schoolURL) {
                      schoolURL = objects[i - 1].schoolURL.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let degreeType = ''
                    if (objects[i - 1].degreeType) {
                      degreeType = objects[i - 1].degreeType.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let programMethod = ''
                    if (objects[i - 1].programMethod) {
                      programMethod = objects[i - 1].programMethod.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let location = ''
                    if (objects[i - 1].location) {
                      location = objects[i - 1].location.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let estimatedHours = ''
                    if (objects[i - 1].estimatedHours) {
                      estimatedHours = objects[i - 1].estimatedHours.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let gradeLevel = ''
                    if (objects[i - 1].gradeLevel) {
                      gradeLevel = objects[i - 1].gradeLevel.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let knowledgeLevel = ''
                    if (objects[i - 1].knowledgeLevel) {
                      knowledgeLevel = objects[i - 1].knowledgeLevel.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let mainContactFirstName = ''
                    if (objects[i - 1].mainContactFirstName) {
                      mainContactFirstName = objects[i - 1].mainContactFirstName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let mainContactLastName = ''
                    if (objects[i - 1].mainContactLastName) {
                      mainContactLastName = objects[i - 1].mainContactLastName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let mainContactEmail = ''
                    if (objects[i - 1].mainContactEmail) {
                      mainContactEmail = objects[i - 1].mainContactEmail.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let mainContactTitle = ''
                    if (objects[i - 1].mainContactTitle) {
                      mainContactTitle = objects[i - 1].mainContactTitle.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let isActive = ''
                    if (objects[i - 1].isActive || objects[i - 1].isActive === false) {
                      isActive = objects[i - 1].isActive.toString().toUpperCase()
                    }

                    let price = 'Free'
                    if (objects[i - 1].price) {
                      price = objects[i - 1].price.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let industry = ''
                    if (objects[i - 1].outcomes) {
                      industry = objects[i - 1].industry.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let functions = ''
                    if (objects[i - 1].functions && objects[i - 1].functions.length > 0) {
                      for (let j = 1; j <= objects[i - 1].functions.length; j++) {
                        if (objects[i - 1].functions[j - 1]) {
                          if (functions !== '') {
                            functions = functions + '; '
                          }
                          functions = functions + objects[i - 1].functions[j - 1]
                        }
                      }
                    }

                    let pathways = ''
                    if (objects[i - 1].pathways && objects[i - 1].pathways.length > 0) {
                      for (let j = 1; j <= objects[i - 1].pathways.length; j++) {
                        if (objects[i - 1].pathways[j - 1]) {
                          if (objects[i - 1].pathways[j - 1].title) {
                            if (pathways !== '') {
                              pathways = pathways + '; '
                            }

                            pathways = pathways + objects[i - 1].pathways[j - 1].title
                          }
                        }
                      }
                    }

                    let sampleJobTitles = ''
                    if (objects[i - 1].outcomes) {
                      sampleJobTitles = objects[i - 1].sampleJobTitles.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let outcomes = ''
                    if (objects[i - 1].outcomes) {
                      outcomes = objects[i - 1].outcomes.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let source = ''
                    if (objects[i - 1].source) {
                      source = objects[i - 1].source.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let description = ''
                    if (objects[i - 1].description || objects[i - 1].headline) {
                      if (objects[i - 1].description) {
                        description = objects[i - 1].description.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                      } else {
                        description = objects[i - 1].headline.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                      }
                    }

                    let createdAt = ''
                    if (objects[i - 1].createdAt) {
                      createdAt = convertDateToString(new Date(objects[i - 1].createdAt),"datetime-2")
                    }

                    let updatedAt = ''
                    if (objects[i - 1].updatedAt) {
                      updatedAt = convertDateToString(new Date(objects[i - 1].updatedAt),"datetime-2")
                    }

                    csvData.push([
                      title, courseId, programURL, imageURL, category, schoolName, schoolURL, degreeType, programMethod,
                      location, estimatedHours, gradeLevel, knowledgeLevel, mainContactFirstName, mainContactLastName,
                      mainContactEmail, mainContactTitle, isActive, price, industry, functions, pathways, sampleJobTitles,
                      outcomes, source, description, createdAt, updatedAt
                    ])

                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no timesheets found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Timesheets query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false })

          });

        } else if (reportType === 'enrollments') {

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "First Name","Last Name","Email","Username","Picture URL","Course ID","Course Name","Course Image URL","Course Category","Course Degree Type","Course School Name","Course School URL","Course Estimated Hours","Course Program Method","Course Description","Is Completed", "Created At","Updated At" ]
                )

                const objects = response.data.enrollments
                if (objects && objects.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= objects.length; i++) {

                    let firstName = ''
                    if (objects[i - 1].firstName) {
                      firstName = objects[i - 1].firstName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let lastName = ''
                    if (objects[i - 1].lastName) {
                      lastName = objects[i - 1].lastName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let email = ''
                    if (objects[i - 1].email) {
                      email= objects[i - 1].email.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let username = ''
                    if (objects[i - 1].username) {
                      username = objects[i - 1].username.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let pictureURL = ''
                    if (objects[i - 1].pictureURL) {
                      pictureURL = objects[i - 1].pictureURL.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let courseId = ''
                    if (objects[i - 1].courseId) {
                      courseId = objects[i - 1].courseId.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let courseName = ''
                    if (objects[i - 1].courseName) {
                      courseName = objects[i - 1].courseName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let courseImageURL = ''
                    if (objects[i - 1].courseImageURL) {
                      courseImageURL = objects[i - 1].courseImageURL.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let courseCategory = ''
                    if (objects[i - 1].courseCategory) {
                      courseCategory = objects[i - 1].courseCategory.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let courseDegreeType = ''
                    if (objects[i - 1].courseDegreeType) {
                      courseDegreeType = objects[i - 1].courseDegreeType.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let courseSchoolName = ''
                    if (objects[i - 1].courseSchoolName) {
                      courseSchoolName = objects[i - 1].courseSchoolName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let courseSchoolURL = ''
                    if (objects[i - 1].courseSchoolURL) {
                      courseSchoolURL = objects[i - 1].courseSchoolURL.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let courseEstimatedHours = ''
                    if (objects[i - 1].courseEstimatedHours) {
                      courseEstimatedHours = objects[i - 1].courseEstimatedHours.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let courseProgramMethod = ''
                    if (objects[i - 1].courseProgramMethod) {
                      courseProgramMethod = objects[i - 1].courseProgramMethod.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let courseDescription = ''
                    if (objects[i - 1].courseDescription) {
                      courseDescription = objects[i - 1].courseDescription.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let isCompleted = ''
                    if (objects[i - 1].isCompleted || objects[i - 1].isCompleted === false) {
                      isCompleted = objects[i - 1].isCompleted.toString().toUpperCase()
                    }

                    let createdAt = ''
                    if (objects[i - 1].createdAt) {
                      createdAt = convertDateToString(new Date(objects[i - 1].createdAt),"datetime-2")
                    }

                    let updatedAt = ''
                    if (objects[i - 1].updatedAt) {
                      updatedAt = convertDateToString(new Date(objects[i - 1].updatedAt),"datetime-2")
                    }

                    csvData.push([
                      firstName, lastName, email, username, pictureURL,
                      courseId, courseName, courseImageURL, courseCategory, courseDegreeType, courseSchoolName,
                      courseSchoolURL, courseEstimatedHours, courseProgramMethod, courseDescription, isCompleted,
                      createdAt, updatedAt
                    ])

                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no timesheets found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Timesheets query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });

        } else if (reportType === 'endorsements') {

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "Recipient First Name","Recipient Last Name","Recipient Email","Recipient Username","Recipient Picture URL","Sender First Name","Sender Last Name","Sender Email","Sender Job Title","Sender Employer Name","Sender Username","Sender Picture URL","Sender Headline","Sender LinkedIn","Sender Has Studied","Sender Hours Studied","Sender Has Worked","Sender Hours Worked","Sender Has Managed","Sender Hours Managed","Sender Has Taught","Sender Hours Taught","Relationship","Overall Rating","Average Rating","Pathway","Competencies","Examples", "Created At","Updated At" ]
                )

                const objects = response.data.endorsements
                if (objects && objects.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= objects.length; i++) {

                    let recipientFirstName = ''
                    if (objects[i - 1].recipientFirstName) {
                      recipientFirstName = objects[i - 1].recipientFirstName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let recipientLastName = ''
                    if (objects[i - 1].recipientLastName) {
                      recipientLastName = objects[i - 1].recipientLastName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let recipientEmail = ''
                    if (objects[i - 1].recipientEmail) {
                      recipientEmail = objects[i - 1].recipientEmail.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let recipientUsername = ''
                    if (objects[i - 1].recipientUsername) {
                      recipientUsername = objects[i - 1].recipientUsername.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let recipientPictureURL = ''
                    if (objects[i - 1].recipientPictureURL) {
                      recipientPictureURL = objects[i - 1].recipientPictureURL.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let senderFirstName = ''
                    if (objects[i - 1].senderFirstName) {
                      senderFirstName = objects[i - 1].senderFirstName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let senderLastName = ''
                    if (objects[i - 1].senderLastName) {
                      senderLastName = objects[i - 1].senderLastName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let senderEmail = ''
                    if (objects[i - 1].senderEmail) {
                      senderEmail = objects[i - 1].senderEmail.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let senderJobTitle = ''
                    if (objects[i - 1].senderJobTitle) {
                      senderJobTitle = objects[i - 1].senderJobTitle.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let senderEmployerName = ''
                    if (objects[i - 1].senderEmployerName) {
                      senderEmployerName = objects[i - 1].senderEmployerName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let senderUsername = ''
                    if (objects[i - 1].senderUsername) {
                      senderUsername = objects[i - 1].senderUsername.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let senderPictureURL = ''
                    if (objects[i - 1].senderPictureURL) {
                      senderPictureURL = objects[i - 1].senderPictureURL.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let senderHeadline = ''
                    if (objects[i - 1].senderHeadline) {
                      senderHeadline = objects[i - 1].senderHeadline.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let senderLinkedIn = ''
                    if (objects[i - 1].senderLinkedIn) {
                      senderLinkedIn = objects[i - 1].senderLinkedIn.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let hasStudied = ''
                    if (objects[i - 1].hasStudied) {
                      hasStudied = objects[i - 1].hasStudied
                    }

                    let hoursStudied = ''
                    if (objects[i - 1].hoursStudied) {
                      hoursStudied = objects[i - 1].hoursStudied
                    }

                    let hasWorked = ''
                    if (objects[i - 1].hasWorked) {
                      hasWorked = objects[i - 1].hasWorked
                    }

                    let hoursWorked = ''
                    if (objects[i - 1].hoursWorked) {
                      hoursWorked = objects[i - 1].hoursWorked
                    }

                    let hasManaged = ''
                    if (objects[i - 1].hasManaged) {
                      hasManaged = objects[i - 1].hasManaged
                    }

                    let hoursManaged = ''
                    if (objects[i - 1].hoursManaged) {
                      hoursManaged = objects[i - 1].hoursManaged
                    }

                    let hasTaught = ''
                    if (objects[i - 1].hasTaught) {
                      hasTaught = objects[i - 1].hasTaught
                    }

                    let hoursTaught = ''
                    if (objects[i - 1].hoursTaught) {
                      hoursTaught = objects[i - 1].hoursTaught
                    }

                    let relationship = ''
                    if (objects[i - 1].relationship) {
                      relationship = objects[i - 1].relationship.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let overallRating = ''
                    if (objects[i - 1].overallRating) {
                      overallRating = objects[i - 1].overallRating.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let averageRating = ''
                    if (objects[i - 1].averageRating) {
                      averageRating = objects[i - 1].averageRating.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let pathway = ''
                    if (objects[i - 1].pathway) {
                      pathway = objects[i - 1].pathway.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let competencies = ''
                    if (objects[i - 1].competencies && objects[i - 1].competencies.length > 0) {
                      competencies = JSON.stringify(objects[i - 1].competencies).replace(/,/g,'').replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                    } else if (objects[i - 1].skillTraits && objects[i - 1].skillTraits.length > 0) {
                      competencies = JSON.stringify(objects[i - 1].skillTraits).replace(/,/g,'').replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                    }

                    let examples = ''
                    if (objects[i - 1].examples && objects[i - 1].examples.length > 0) {
                      examples = JSON.stringify(objects[i - 1].examples).replace(/,/g,'').replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                    }

                    let createdAt = ''
                    if (objects[i - 1].createdAt) {
                      createdAt = convertDateToString(new Date(objects[i - 1].createdAt),"datetime-2")
                    }

                    let updatedAt = ''
                    if (objects[i - 1].updatedAt) {
                      updatedAt = convertDateToString(new Date(objects[i - 1].updatedAt),"datetime-2")
                    }

                    csvData.push([
                      recipientFirstName, recipientLastName, recipientEmail, recipientUsername, recipientPictureURL,
                      senderFirstName, senderLastName, senderEmail, senderJobTitle, senderEmployerName, senderUsername,
                      senderPictureURL, senderHeadline, senderLinkedIn,
                      hasStudied, hoursStudied, hasWorked, hoursWorked, hasManaged, hoursManaged, hasTaught, hoursTaught,
                      relationship, overallRating, averageRating,
                      pathway, competencies, examples,
                      createdAt, updatedAt
                    ])

                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no timesheets found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Timesheets query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false })

          });

        } else if (reportType === 'sessions') {

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "Advisee First Name","Advisee Last Name","Advisee Email","Advisor First Name","Advisor Last Name","Advisor Email","Category","Session Date","Session Method","Hidden to Career-Seeker","Meeting Purpose","Meeting Outcome","Meeting Tags","Notes", "Created At","Updated At" ]
                )

                const objects = response.data.sessions
                if (objects && objects.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= objects.length; i++) {

                    let adviseeFirstName = ''
                    if (objects[i - 1].adviseeFirstName) {
                      adviseeFirstName = objects[i - 1].adviseeFirstName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let adviseeLastName = ''
                    if (objects[i - 1].adviseeLastName) {
                      adviseeLastName = objects[i - 1].adviseeLastName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let adviseeEmail = ''
                    if (objects[i - 1].adviseeEmail) {
                      adviseeEmail = objects[i - 1].adviseeEmail.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let advisorFirstName = ''
                    if (objects[i - 1].advisorFirstName) {
                      advisorFirstName = objects[i - 1].advisorFirstName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let advisorLastName = ''
                    if (objects[i - 1].advisorLastName) {
                      advisorLastName = objects[i - 1].advisorLastName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let advisorEmail = ''
                    if (objects[i - 1].advisorEmail) {
                      advisorEmail = objects[i - 1].advisorEmail.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let category = ''
                    if (objects[i - 1].category) {
                      category = objects[i - 1].category.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let sessionDate = ''
                    if (objects[i - 1].sessionDate) {
                      sessionDate = convertDateToString(new Date(objects[i - 1].sessionDate),"datetime-2")
                    }

                    let sessionMethod = ''
                    if (objects[i - 1].sessionMethod) {
                      sessionMethod = objects[i - 1].sessionMethod.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let hiddenToCareerSeeker = ''
                    if (objects[i - 1].hiddenToCareerSeeker || objects[i - 1].hiddenToCareerSeeker === false) {
                      hiddenToCareerSeeker = objects[i - 1].hiddenToCareerSeeker.toString()
                    }

                    let meetingPurpose = ''
                    if (objects[i - 1].meetingPurpose) {
                      meetingPurpose = objects[i - 1].meetingPurpose.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let meetingOutcome = ''
                    if (objects[i - 1].meetingOutcome) {
                      meetingOutcome = objects[i - 1].meetingOutcome.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let meetingTags = ''
                    if (objects[i - 1].meetingTags) {
                      meetingTags = objects[i - 1].meetingTags.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let notes = ''
                    if (objects[i - 1].notes) {
                      notes = objects[i - 1].notes.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let createdAt = ''
                    if (objects[i - 1].createdAt) {
                      createdAt = convertDateToString(new Date(objects[i - 1].createdAt),"datetime-2")
                    }

                    let updatedAt = ''
                    if (objects[i - 1].updatedAt) {
                      updatedAt = convertDateToString(new Date(objects[i - 1].updatedAt),"datetime-2")
                    }

                    csvData.push([
                      adviseeFirstName, adviseeLastName, adviseeEmail,
                      advisorFirstName, advisorLastName, advisorEmail,
                      category,sessionDate, sessionMethod,
                      hiddenToCareerSeeker, meetingPurpose, meetingOutcome, meetingTags, notes,
                      createdAt, updatedAt
                    ])

                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no timesheets found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Timesheets query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false })

          });

        } else if (reportType === 'referrals') {

          Axios.put('/api/reporting/general', { reportType, orgCode, placementPartners, accountCode, afterDate, beforeDate,  resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "Recipient First Name","Recipient Last Name","Recipient Email","Recipient Username","Recipient Picture URL","Referrer First Name","Referrer Last Name","Referrer Email","Referrer Username","Referrer Picture URL","Referrer LinkedIn URL","Referrer Job Title","Referrer Employer Name","Referrer Partner Org","Referrer Is Existing User","Relationship","Opportunity ID","Opportunity Title","Opportunity Employer Name","Referral Code", "Endorsement Attached","Endorsement Id","Reason","Referral Bonus","Status","Created At","Updated At" ]
                )

                const objects = response.data.referrals
                if (objects && objects.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= objects.length; i++) {

                    let recipientFirstName = ''
                    if (objects[i - 1].recipientFirstName) {
                      recipientFirstName = objects[i - 1].recipientFirstName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let recipientLastName = ''
                    if (objects[i - 1].recipientLastName) {
                      recipientLastName = objects[i - 1].recipientLastName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let recipientEmail = ''
                    if (objects[i - 1].recipientEmail) {
                      recipientEmail = objects[i - 1].recipientEmail.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let recipientUsername = ''
                    if (objects[i - 1].recipientUsername) {
                      recipientUsername = objects[i - 1].recipientUsername.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let recipientPictureURL = ''
                    if (objects[i - 1].recipientPictureURL) {
                      recipientPictureURL = objects[i - 1].recipientPictureURL.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let referrerFirstName = ''
                    if (objects[i - 1].referrerFirstName) {
                      referrerFirstName = objects[i - 1].referrerFirstName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let referrerLastName = ''
                    if (objects[i - 1].referrerLastName) {
                      referrerLastName = objects[i - 1].referrerLastName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let referrerEmail = ''
                    if (objects[i - 1].referrerEmail) {
                      referrerEmail = objects[i - 1].referrerEmail.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let referrerUsername = ''
                    if (objects[i - 1].referrerUsername) {
                      referrerUsername = objects[i - 1].referrerUsername.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let referrerPictureURL = ''
                    if (objects[i - 1].referrerPictureURL) {
                      referrerPictureURL = objects[i - 1].referrerPictureURL.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let referrerLinkedInURL = ''
                    if (objects[i - 1].referrerLinkedInURL) {
                      referrerLinkedInURL = objects[i - 1].referrerLinkedInURL.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let referrerJobTitle = ''
                    if (objects[i - 1].referrerJobTitle) {
                      referrerJobTitle = objects[i - 1].referrerJobTitle.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let referrerEmployerName = ''
                    if (objects[i - 1].referrerEmployerName) {
                      referrerEmployerName = objects[i - 1].referrerEmployerName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let referrerPartnerOrg = ''
                    if (objects[i - 1].referrerPartnerOrg) {
                      referrerPartnerOrg = objects[i - 1].referrerPartnerOrg.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    // isInternal also works
                    let existingUser = ''
                    if (objects[i - 1].existingUser) {
                      existingUser = "YES"
                    } else {
                      existingUser = "NO"
                    }

                    let relationship = ''
                    if (objects[i - 1].relationship) {
                      relationship = objects[i - 1].relationship.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let opportunityId = ''
                    if (objects[i - 1].opportunityId) {
                      opportunityId = objects[i - 1].opportunityId.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let opportunityTitle = ''
                    if (objects[i - 1].opportunityTitle) {
                      opportunityTitle = objects[i - 1].opportunityTitle.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let opportunityEmployerName = ''
                    if (objects[i - 1].opportunityEmployerName) {
                      opportunityEmployerName = objects[i - 1].opportunityEmployerName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let referralCode = ''
                    if (objects[i - 1]._id) {
                      referralCode = objects[i - 1]._id
                    }

                    let attachEndorsement = ''
                    if (objects[i - 1].attachEndorsement) {
                      attachEndorsement = "YES"
                    } else {
                      attachEndorsement = "NO"
                    }

                    let endorsementId = ''
                    if (objects[i - 1].endorsementId) {
                      endorsementId = objects[i - 1].endorsementId.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let reason = ''
                    if (objects[i - 1].reason) {
                      reason = objects[i - 1].reason.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let referralBonus = ''
                    if (objects[i - 1].referralBonus) {
                      referralBonus = objects[i - 1].referralBonus.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let status = ''
                    if (objects[i - 1].status) {
                      status = objects[i - 1].status.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                    }

                    let createdAt = ''
                    if (objects[i - 1].createdAt) {
                      createdAt = convertDateToString(new Date(objects[i - 1].createdAt),"datetime-2")
                    }

                    let updatedAt = ''
                    if (objects[i - 1].updatedAt) {
                      updatedAt = convertDateToString(new Date(objects[i - 1].updatedAt),"datetime-2")
                    }

                    csvData.push([
                      recipientFirstName, recipientLastName, recipientEmail, recipientUsername, recipientPictureURL,
                      referrerFirstName, referrerLastName, referrerEmail, referrerUsername, referrerPictureURL,
                      referrerLinkedInURL, referrerJobTitle, referrerEmployerName, referrerPartnerOrg,
                      existingUser, relationship, opportunityId, opportunityTitle, opportunityEmployerName,
                      referralCode, attachEndorsement, endorsementId, reason, referralBonus, status,
                      createdAt, updatedAt
                    ])
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no timesheets found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Timesheets query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });

        } else if (reportType === 'caseNotes') {

          Axios.put('/api/reporting/general', { reportType, assignedStaffName, orgCode, placementPartners, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked', response.data);

                csvData.push(
                  [ "Date","Staff Member","First Name","Last Name","Email","Role Name","Notes" ]
                )

                const objects = response.data.caseNotes
                if (objects && objects.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= objects.length; i++) {
                    // caseDate, workerName, firstName, lastName, email, notes

                    let caseNotes = objects[i - 1].caseNotes
                    console.log('up in the caseNotes', caseNotes)
                    if (caseNotes) {

                      for (let j = 1; j <= caseNotes.length; j++) {
                        let caseDate = caseNotes[j - 1].caseDate

                        let workerName = ''
                        if (caseNotes[j - 1].workerName) {
                          workerName = caseNotes[j - 1].workerName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                        }

                        let firstName = ''
                        if (objects[i - 1].firstName) {
                          firstName = objects[i - 1].firstName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                        }

                        let lastName = ''
                        if (objects[i - 1].lastName) {
                          lastName = objects[i - 1].lastName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                        }

                        let email = ''
                        if (objects[i - 1].email) {
                          email = objects[i - 1].email.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                        }

                        let roleName = ''
                        if (objects[i - 1].roleName) {
                          roleName = objects[i - 1].roleName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                        }

                        let notes = ''
                        if (caseNotes[j - 1].notes) {
                          notes = caseNotes[j - 1].notes.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                        }
                        // let createdAt = ''
                        // if (objects[i - 1].createdAt) {
                        //   createdAt = convertDateToString(new Date(objects[i - 1].createdAt),"datetime-2")
                        // }

                        csvData.push([
                          caseDate, workerName, firstName, lastName, email, roleName, notes
                        ])
                      }
                    }
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no case notes found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Case notes query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });

        } else if (reportType === 'messageBlasts') {

          Axios.put('/api/reporting/general', { reportType, assignedStaffName, orgCode, placementPartners, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked on message blasts', response.data);

                csvData.push(
                  [ "Recipient First Name","Recipient Last Name","Recipient Email","Recipient Type","Message Type","Message Title","Message Text","Sent","Created At","Updated At" ]
                )

                const objects = response.data.messageBlasts
                if (objects && objects.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= objects.length; i++) {

                    // recipientFirstName, recipientLastName, recipientEmail, recipientType, messageType, messageTitle, messageText, sent, createdAt, updatedAt

                    let recipients = objects[i - 1].recipients

                    if (recipients) {

                      for (let j = 1; j <= recipients.length; j++) {
                        let recipientFirstName = recipients[j - 1].firstName
                        let recipientLastName = recipients[j - 1].lastName
                        let recipientEmail = recipients[j - 1].email
                        let recipientType = objects[i - 1].recipientType
                        let messageType = objects[i - 1].messageType

                        let messageTitle = ''
                        if (objects[j - 1].messageTitle) {
                          messageTitle = objects[j - 1].messageTitle.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                        }

                        let messageText = ''
                        if (objects[j - 1].messageText) {
                          messageText = objects[j - 1].messageText.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                        }

                        let sent = ''
                        if (objects[i - 1].sent || objects[i - 1].sent === false) {
                          if (objects[i - 1].sent) {
                            sent = "TRUE"
                          } else {
                            sent = "FALSE"
                          }
                        }

                        let createdAt = ''
                        if (objects[i - 1].createdAt) {
                          createdAt = convertDateToString(new Date(objects[i - 1].createdAt),"datetime-2")
                        }

                        let updatedAt = ''
                        if (objects[i - 1].updatedAt) {
                          updatedAt = convertDateToString(new Date(objects[i - 1].updatedAt),"datetime-2")
                        }

                        csvData.push([
                          recipientFirstName, recipientLastName, recipientEmail, recipientType,
                          messageType, messageTitle, messageText, sent, createdAt, updatedAt
                        ])
                      }
                    }
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no case notes found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Timesheets query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });
        } else if (reportType === 'pathways') {

          Axios.put('/api/reporting/general', { reportType, assignedStaffName, orgCode, placementPartners, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked on pathways', response.data);

                csvData.push(
                  [ "Title","Job Title","Job Function","Industry","Description","Benchmark Id","Image URL","Videos","Checklist","Is Active","Documentation","Org Code","Account Code","Created At","Updated At" ]
                )

                const objects = response.data.pathways
                if (objects && objects.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= objects.length; i++) {

                    let title = ''
                    if (objects[i - 1].title) {
                      title = objects[i - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let jobTitle = ''
                    if (objects[i - 1].jobTitle) {
                      jobTitle = objects[i - 1].jobTitle.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let jobFunction = ''
                    if (objects[i - 1].jobFunction) {
                      jobFunction = objects[i - 1].jobFunction.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let industry = ''
                    if (objects[i - 1].industry) {
                      industry = objects[i - 1].industry.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let description = ''
                    if (objects[i - 1].description) {
                      description = objects[i - 1].description.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let videos = ''
                    if (objects[i - 1].videos && objects[i - 1].videos.length > 0) {
                      for (let j = 1; j <= objects[i - 1].videos.length; j++) {
                        if (videos === '') {
                          videos = videos + objects[i - 1].videos[j - 1]
                        } else {
                          videos = videos + "; " + objects[i - 1].videos[j - 1]
                        }
                      }
                    }

                    let checklist = ''
                    if (objects[i - 1].checklist && objects[i - 1].checklist.length > 0) {
                      for (let j = 1; j <= objects[i - 1].checklist.length; j++) {
                        if (objects[i - 1].checklist[j - 1] && objects[i - 1].checklist[j - 1].name) {
                          if (videos === '') {
                            checklist = checklist + "1) " + objects[i - 1].checklist[j - 1].name
                          } else {
                            checklist = checklist + "; " + i + ") " + objects[i - 1].checklist[j - 1]
                          }
                        }
                      }
                    }

                    const benchmarkId = objects[i - 1].benchmarkId
                    const imageURL = objects[i - 1].imageURL

                    let isActive = ''
                    if (objects[i - 1].isActive) {
                      isActive = "TRUE"
                    } else {
                      isActive = "FALSE"
                    }

                    const documentation = objects[i - 1].documentation
                    const orgCode = objects[i - 1].orgCode
                    const accountCode = objects[i - 1].accountCode

                    let createdAt = ''
                    if (objects[i - 1].createdAt) {
                      createdAt = convertDateToString(new Date(objects[i - 1].createdAt),"datetime-2")
                    }

                    let updatedAt = ''
                    if (objects[i - 1].updatedAt) {
                      updatedAt = convertDateToString(new Date(objects[i - 1].updatedAt),"datetime-2")
                    }

                    csvData.push([
                      title, jobTitle, jobFunction, industry, description,
                      benchmarkId, imageURL, videos, checklist,
                      isActive, documentation, orgCode, accountCode,
                      createdAt, updatedAt
                    ])
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no case notes found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Timesheets query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });
        } else if (reportType === 'lessons') {

          Axios.put('/api/reporting/general', { reportType, assignedStaffName, orgCode, placementPartners, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked on pathways', response.data);

                csvData.push(
                  [ "Name","Description","Minutes","Internal URL","Student URL","Org Code","Submission Deadline","Custom Assessment Id","Mentor Assessment ID","Created At","Updated At" ]
                )

                const objects = response.data.lessons
                if (objects && objects.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= objects.length; i++) {

                    let name = ''
                    if (objects[i - 1].name) {
                      name = objects[i - 1].name.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    let description = ''
                    if (objects[i - 1].description) {
                      description = objects[i - 1].description.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"").replace(/["']/g, "")
                    }

                    const minutes = objects[i - 1].minutes

                    const url = objects[i - 1].url
                    const studentURL = objects[i - 1].studentURL
                    const orgCode = objects[i - 1].orgCode

                    let submissionDeadline = ''
                    if (objects[i - 1].submissionDeadline) {
                      submissionDeadline = convertDateToString(new Date(objects[i - 1].submissionDeadline),"datetime-2")
                    }

                    const customAssessmentId = objects[i - 1].customAssessmentId
                    const mentorAssessmentId = objects[i - 1].mentorAssessmentId

                    let createdAt = ''
                    if (objects[i - 1].createdAt) {
                      createdAt = convertDateToString(new Date(objects[i - 1].createdAt),"datetime-2")
                    }

                    let updatedAt = ''
                    if (objects[i - 1].updatedAt) {
                      updatedAt = convertDateToString(new Date(objects[i - 1].updatedAt),"datetime-2")
                    }

                    csvData.push([
                      name, description, minutes, url, studentURL, orgCode,
                      submissionDeadline, customAssessmentId, mentorAssessmentId,
                      createdAt, updatedAt
                    ])
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no case notes found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Timesheets query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false })

          });

        } else if (reportType === 'surveySubmissions') {

          let commaStartingValue = /,/g
          let commaReplacementValue = ";"

          Axios.put('/api/reporting/general', { reportType, surveyName, lessonName, orgCode, placementPartners, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked on survey submissions', response.data);

                csvData.push(
                  [ "First Name","Last Name","Email","Role Name","Lesson Name","Survey Name","Created At","Updated At" ]
                )

                const objects = response.data.surveySubmissions
                if (objects && objects.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= objects.length; i++) {

                    let firstName = ''
                    if (objects[i - 1].firstName) {
                      firstName = objects[i - 1].firstName
                    }
                    let lastName = ''
                    if (objects[i - 1].lastName) {
                      lastName = objects[i - 1].lastName
                    }
                    const email = objects[i - 1].emailId
                    const roleName = objects[i - 1].roleName
                    const lessonName = objects[i - 1].lessonName
                    const assessmentName = objects[i - 1].assessmentName

                    let createdAt = ''
                    if (objects[i - 1].createdAt) {
                      createdAt = convertDateToString(new Date(objects[i - 1].createdAt),"datetime-2")
                    }

                    let updatedAt = ''
                    if (objects[i - 1].updatedAt) {
                      updatedAt = convertDateToString(new Date(objects[i - 1].updatedAt),"datetime-2")
                    }

                    csvData.push([
                      firstName, lastName, email, roleName, lessonName, assessmentName,
                      createdAt, updatedAt
                    ])

                    if (objects[i - 1].answers) {
                      let surveyQuestions = []
                      if (response.data.assessment && response.data.assessment.questions) {
                        for (let j = 1; j <= response.data.assessment.questions.length; j++) {
                          if (response.data.assessment.questions[j - 1] && response.data.assessment.questions[j - 1]) {
                            const item = response.data.assessment.questions[j - 1]
                            surveyQuestions.push(item.name)
                            if (!csvData[0].includes(item.name)) {
                              csvData[0].push(item.name)
                            }
                          }
                        }
                      }

                      for (let j = 1; j <= objects[i - 1].answers.length; j++) {
                        if (objects[i - 1].answers[j - 1]) {
                          let question = ''
                          if (objects[i - 1].answers[j - 1].question) {
                            question = objects[i - 1].answers[j - 1].question.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                          }
                          let answer = ''
                          if (objects[i - 1].answers[j - 1].answer) {
                            answer = objects[i - 1].answers[j - 1].answer.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                          }

                          if (surveyQuestions.length < 1) {
                            if (!csvData[0].includes(question)) {
                              csvData[0].push(question)
                              surveyQuestions.push(question)
                            }
                          }

                          // console.log('show me the answer: ', answer)

                          csvData[csvData.length - 1].push(answer)
                        }
                      }
                    }
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no case notes found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Timesheets query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });
        } else if (reportType === 'keyMetrics') {

          let commaStartingValue = /,/g
          let commaReplacementValue = ";"

          Axios.put('/api/reporting/general', { reportType, surveyName, lessonName, orgCode, placementPartners, accountCode, afterDate, beforeDate, resLimit })
          .then((response) => {

              if (response.data.success) {
                console.log('Reporting query worked on survey submissions', response.data);

                csvData.push(
                  [ "Month","Sign Ups","Active Users","Submissions","Grades","Certificates","Placements","Referrals","Endorsements","Documents","Goals","Advising Sessions","RSVPs","Applications","Career Assessments","Created At","Updated At" ]
                )

                const objects = response.data.keyMetrics
                if (objects && objects.length > 0) {

                  let commaStartingValue = /,/g
                  let commaReplacementValue = ";"

                  for (let i = 1; i <= objects.length; i++) {
                    const item = objects[i - 1]

                    const month = convertDateToString(new Date(item.createdAt).getMonth(),'formatMonth') + " " + new Date(item.createdAt).getFullYear()
                    const signUpCount = item.signUpCount
                    const activeUsers = item.activeUsers
                    const submissionCount = item.submissionCount
                    const gradeCount = item.gradeCount
                    const certificateCount = item.certificateCount
                    const placementCount = item.placementCount
                    const referralCount = item.referralCount
                    const endorsementCount = item.endorsementCount
                    const documentCount = item.documentCount
                    const goalCount = item.goalCount
                    const advisingSessionCount = item.advisingSessionCount
                    const rsvpCount = item.rsvpCount
                    const applicationCount = item.applicationCount
                    const assessmentResultCount = item.assessmentResultCount

                    let createdAt = ''
                    if (objects[i - 1].createdAt) {
                      createdAt = convertDateToString(new Date(objects[i - 1].createdAt),"datetime-2")
                    }

                    let updatedAt = ''
                    if (objects[i - 1].updatedAt) {
                      updatedAt = convertDateToString(new Date(objects[i - 1].updatedAt),"datetime-2")
                    }

                    csvData.push([
                      month, signUpCount, activeUsers, submissionCount, gradeCount, certificateCount,
                      placementCount, referralCount, endorsementCount, documentCount, goalCount,
                      advisingSessionCount, rsvpCount, applicationCount, assessmentResultCount,
                      createdAt, updatedAt
                    ])
                  }

                  if (previewMode) {
                    this.configurePreview(csvData)
                  } else {
                    this.completeExport(this.state.selectedReportShortname + '-report.csv', csvData)
                  }

                } else {
                  this.setState({ errorMessage, isLoading: false})
                }

              } else {
                console.log('no case notes found', response.data.message)
                this.setState({ errorMessage, isLoading: false})
              }

          }).catch((error) => {
              console.log('Timesheets query did not work', error);

              if (error && error.message) {
                errorMessage = error.message.toString()
              }

              this.setState({ errorMessage, isLoading: false})
          });
        } else {
          console.log('could not find reporttype', reportType)
          this.setState({ isLoading: false })
        }
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler clicked', event.target.name, event.target.value);

      const passedName = event.target.name
      let passedValue = null
      let passedObject2 = null

      if (event.target.name.includes('timeframe')) {
        console.log('search clicked')
        let nameArray = event.target.name.split("|")
        let identifier = Number(nameArray[1])

        if (identifier === 0) {
          this.setState({ timeframe: event.target.value })
        } else if (identifier === 1) {
          this.setState({ timeframe1: event.target.value })
        } else if (identifier === 2) {
          this.setState({ timeframe2: event.target.value })
        } else if (identifier === 3) {
          this.setState({ timeframe3: event.target.value })
        } else if (identifier === 4) {
          this.setState({ timeframe4: event.target.value })
        } else if (identifier === 5) {
          this.setState({ timeframe5: event.target.value })
        }
      } else if (event.target.name  === 'challenge') {
        let selectedChallenge = null
        for (let i = 1; i <= this.state.challengeOptions.length; i++) {
          if (event.target.value === this.state.challengeOptions[i - 1].name) {
            selectedChallenge = this.state.challengeOptions[i - 1]
          }
        }
        this.setState({ selectedChallenge })

        passedValue = selectedChallenge

      } else if (event.target.name === 'selectedPosting') {
        let selectedPosting = { title: 'All' }
        for (let i = 1; i <= this.state.postingOptions.length; i++) {
          if (this.state.postingOptions[i - 1].title + ' | ' + this.state.postingOptions[i - 1].employerName === event.target.value) {
            selectedPosting = this.state.postingOptions[i - 1]
          }
        }
        this.setState({ selectedPosting })

        passedValue = selectedPosting

      } else if (event.target.name === 'selectedWork') {
        // console.log('we are trying', this.state.selectedType, this.state.modalIsOpen)
        if (this.state.selectedType === 'search' && this.state.modalIsOpen) {

          this.setState({ searchString: event.target.value })
          this.searchWorkPostings(event.target.value)
        } else {
          let selectedWork = { title: 'All' }
          for (let i = 1; i <= this.state.workOptions.length; i++) {
            if (this.state.workOptions[i - 1].title + ' | ' + this.state.workOptions[i - 1].employerName === event.target.value) {
              selectedWork = this.state.workOptions[i - 1]
            }
          }
          this.setState({ selectedWork })

          passedValue = selectedWork
        }

      } else if (event.target.name === 'selectedProject') {
        let selectedProject = { name: 'All' }
        for (let i = 1; i <= this.state.projectOptions.length; i++) {
          if (this.state.projectOptions[i - 1].name === event.target.value) {
            selectedProject = this.state.projectOptions[i - 1]
          }
        }
        this.setState({ selectedProject })

        passedValue = selectedProject
      } else if (event.target.name === 'selectedEvent') {
        // console.log('e1? ', event.target.value)
        const selectedSpecialValue = event.target.value

        let selectedEvent = { title: 'All' }
        for (let i = 1; i <= this.state.eventOptions.length; i++) {
          let tempEventTitle = this.state.eventOptions[i - 1].title
          if (this.state.eventOptions[i - 1].startDate) {
            tempEventTitle = this.state.eventOptions[i - 1].title + ' on ' + convertDateToString(new Date(this.state.eventOptions[i - 1].startDate),'date-2')
          }
          if (tempEventTitle === event.target.value) {
            selectedEvent = this.state.eventOptions[i - 1]
          }
        }
        console.log('e2? ', event.target.value, selectedEvent)
        this.setState({ selectedEvent, selectedSpecialValue })

        passedValue = selectedEvent

      } else if (event.target.name === 'selectedEmployer') {
        // console.log('are we doing this?')
        const selectedSpecialValue = event.target.value

        let selectedEmployer = { title: 'All' }
        let accountCode = this.state.accountCode
        for (let i = 1; i <= this.state.employerOptions.length; i++) {
          if (this.state.employerOptions[i - 1].employerName === event.target.value) {
            selectedEmployer = this.state.employerOptions[i - 1]
            accountCode = this.state.employerOptions[i - 1].accountCode
          }
        }
        this.setState({ selectedEmployer, accountCode, selectedSpecialValue })

        passedValue = accountCode


      } else if (event.target.name === 'selectedReport') {

        let selectedReportName = null
        let selectedReportDescription = null
        let selectedReportShortname = null
        let selectedReportObject = null
        for (let i = 1; i <= this.state.reportOptions.length; i++) {
          console.log('got results: ', i - 1, this.state.reportOptions[i - 1], event.target.value)
          if (this.state.reportOptions[i - 1].name === event.target.value) {
            selectedReportName = event.target.value
            selectedReportDescription = this.state.reportOptions[i - 1].description
            selectedReportShortname = this.state.reportOptions[i - 1].shortname
            selectedReportObject = this.state.reportOptions[i - 1]

            passedValue = selectedReportShortname
            passedObject2 = { name: 'selectedReportObject', value: selectedReportObject }
          }
        }

        this.setState({ [event.target.name]: event.target.value,
          selectedReportName, selectedReportDescription, selectedReportShortname, selectedReportObject
        })

      } else if (event.target.name === 'filterName') {


        let selectedReport = null
        for (let i = 1; i <= this.state.reportOptions.length; i++) {
          if (this.state.reportOptions[i - 1].name === this.state.selectedReport) {
            selectedReport = this.state.reportOptions[i - 1]
          }
        }

        let selectedFilter = null
        if (selectedReport) {

          let fields = selectedReport.fields
          if (this.state.selectedReportShortname === 'participants' && selectedReport.fields[this.state.numberOfNests] && selectedReport.fields[this.state.numberOfNests].length > 0) {
            fields = selectedReport.fields[this.state.numberOfNests]
          }

          for (let i = 1; i <= fields.length; i++) {
            if (fields[i - 1].name === event.target.value) {
              selectedFilter = fields[i - 1]
            }
          }
        }

        this.setState({ [event.target.name]: event.target.value, selectedFilter })
        passedValue = event.target.value
      } else {

        console.log('one of filter options clicked')
        this.setState({ [event.target.name]: event.target.value })
        passedValue = event.target.value

      }

      // if (!this.state.editCustomFilter && !this.state.showMoveMenu && !this.state.showSaveWidget && !this.state.showOpenWidget && event.target.name !== 'afterDate' && event.target.name !== 'beforeDate') {
      //   this.closeModal()
      // }
      //
      // if (this.state.showModule && !this.state.editCustomFilter && !this.state.showMoveMenu && !this.state.showSaveWidget && !this.state.showOpenWidget) {
      //   this.exportReport(this.state.selectedReportShortname, true, passedName, passedValue, passedObject2)
      // }
    }

    searchWorkPostings(searchString) {
      console.log('searchWorkPostings called')

      this.setState({ isLoading: true })

      const orgCode = this.state.org
      const placementPartners = this.state.placementPartners
      const accountCode = this.state.accountCode
      const search = true
      const postTypes = ['Program','Work']
      const pullPartnerPosts = true

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called', orgCode)
        self.setState({ animating: true, errorMessage: null, successMessage: null })

        Axios.get('/api/postings/search', { params: { searchString, orgCode, placementPartners, accountCode, search, postTypes, pullPartnerPosts } })
        .then((response) => {
          console.log('Opportunity search query attempted', response.data);

            if (response.data.success && response.data.postings) {
              console.log('opportunity search query worked')

              let workPostingOptions = response.data.postings
              self.setState({ workPostingOptions, isLoading: false })

            } else {
              console.log('opportunity search query did not work', response.data.message)

              self.setState({ postingOptions: [], isLoading: false, errorMessage: response.data.message })

            }

        }).catch((error) => {
            console.log('Search query did not work for some reason', error);
            self.setState({ isLoading: false, errorMessage: error.toString() })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showEditor: false,
        selectedName: null, selectedShortname: null, selectedReportOptions: null, selectedSubKey: null,
        selectedType: null, selectedSpecialValue: null,
        editCustomFilter: false, selectedFilter: null, editFilterIndex: null,
        showMoveMenu: false, selectedIndex: null, selectedIndex2: null,  showSaveWidget: false,
        showOpenWidget: false, selectedPlaceholder: null
      })
    }

    renderOptions(options) {
      console.log('renderOptions called', options, this.state.selectedShortname)

      let rows = []
      for (let i = 1; i <= options.length; i++) {
        const value = options[i - 1]
        const index = i - 1

        if (this.state.selectedShortname === 'selectedPosting' || this.state.selectedShortname === 'selectedWork') {
          rows.push(<option key={(value.title && value.title !== 'All') ? value.title + ' | ' + value.employerName : value.title} value={(value.title && value.title !== 'All') ? value.title + ' | ' + value.employerName : value.title}>{(value.title && value.title !== 'All') && index + ". "}{(value.title && value.title !== 'All') ? value.title + ' | ' + value.employerName : value.title}{(value.createdAt) && ' ( Created ' + convertDateToString(new Date(value.createdAt),'date-2') + ')'}</option>)
        } else if (this.state.selectedShortname === 'selectedProject') {
          rows.push(<option key={value.name} value={value.name}>{value.name}{(value.createdAt) && ' ( Created ' + convertDateToString(new Date(value.createdAt),'date-2') + ')'}</option>)
        } else if (this.state.selectedShortname === 'selectedEvent') {
          rows.push(<option key={(value.startDate) ? value.title + ' on ' + convertDateToString(new Date(value.startDate),'date-2') : value.title} value={(value.startDate) ? value.title + ' on ' + convertDateToString(new Date(value.startDate),'date-2') : value.title}>{(value.startDate) && index + ". "}{(value.startDate) ? value.title + ' on ' + convertDateToString(new Date(value.startDate),'date-2') : value.title}{(value.startDate) && ' ( Created ' + convertDateToString(new Date(value.createdAt),'date-2') + ')'}</option>)
        } else if (this.state.selectedSubKey) {
          rows.push(<option key={value[this.state.selectedSubKey]} value={(value[this.state.selectedSubKey]) && value[this.state.selectedSubKey]}>{(value[this.state.selectedSubKey] && value[this.state.selectedSubKey] !== 'All') && index + ". "}{(value[this.state.selectedSubKey]) && value[this.state.selectedSubKey]}{(value[this.state.selectedSubKey] && this.state.selectedShortname === "selectedReport" && value._id) && " [Custom Report]"}</option>)
        } else {
          rows.push(<option key={value} value={value}>{(value) && index + ". "}{value}</option>)
        }
      }

      return rows
    }

    toggleMenu(e, menuIndex) {
      console.log('toggleMenu called', e)

      if (e) {
        e.stopPropagation()
      }

      if (this.state.menuIndex || this.state.menuIndex === 0) {
        if (menuIndex === this.state.menuIndex) {
          this.setState({ menuIndex: null, confirmDelete: false })
        } else {
          this.setState({ menuIndex, confirmDelete: false })
        }
      } else {
        this.setState({ menuIndex, confirmDelete: false })
      }
    }

    sortValues(index, index2) {
      console.log('sortValues called', index, index2)

      const shortname = this.state.reportOptions[index].fields[index2].shortname

      let valueName = 1
      if (this.state.sortCriteria && this.state.sortCriteria[shortname] === 1) {
        valueName = -1
      }
      console.log('show the valueName: ', valueName, this.state.sortCriteria)
      const sortCriteria = { [shortname]: valueName }

      this.setState({ sortCriteria, menuIndex: null })
      this.exportReport(this.state.selectedReportShortname, true, "sortCriteria", sortCriteria)

      // reloadReport

      // const values = this.state.csvData
      // let sortValue = type
      //
      // let members = values
      //
      // let sortDescending = this.state.sortDescending
      // if (sortDescending) {
      //   members.sort(function(a, b){
      //       if(a[sortValue] > b[sortValue]) { return -1; }
      //       if(a[sortValue] < b[sortValue]) { return 1; }
      //       return 0;
      //   })
      // } else {
      //   members.sort(function(a, b){
      //       if(a[sortValue] < b[sortValue]) { return -1; }
      //       if(a[sortValue] > b[sortValue]) { return 1; }
      //       return 0;
      //   })
      //   console.log('?!')
      // }
      //
      // sortDescending = !sortDescending
      // this.setState({ members, sortDescending })

      // if (type === 'pair') {
      //
      //   if (sortDescending) {
      //     members.sort(function(a, b) {
      //       let textA = ''
      //       let textB = ''
      //       if (a.pair) {
      //         textA = a.pair
      //       }
      //       if (b.pair) {
      //         textB = b.pair
      //       }
      //       return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
      //     });
      //   } else {
      //     members.sort(function(a, b) {
      //       let textA = ''
      //       let textB = ''
      //       if (a.pair) {
      //         textA = a.pair
      //       }
      //       if (b.pair) {
      //         textB = b.pair
      //       }
      //       return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      //     });
      //   }
      // } else if (type === 'enrollmentStatus') {
      //   if (sortDescending) {
      //     members.sort(function(a, b) {
      //       let textA = ''
      //       let textB = ''
      //       if (a.enrollmentStatus) {
      //         textA = a.enrollmentStatus
      //       }
      //       if (b.enrollmentStatus) {
      //         textB = b.enrollmentStatus
      //       }
      //       return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
      //     });
      //   } else {
      //     members.sort(function(a, b) {
      //       let textA = ''
      //       let textB = ''
      //       if (a.enrollmentStatus) {
      //         textA = a.enrollmentStatus
      //       }
      //       if (b.enrollmentStatus) {
      //         textB = b.enrollmentStatus
      //       }
      //       return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      //     });
      //   }
      // } else if (type === 'phoneNumber') {
      //   if (sortDescending) {
      //     members.sort(function(a, b) {
      //       let textA = ''
      //       let textB = ''
      //       if (a.phoneNumber) {
      //         textA = a.phoneNumber
      //       }
      //       if (b.phoneNumber) {
      //         textB = b.phoneNumber
      //       }
      //       return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
      //     });
      //   } else {
      //     members.sort(function(a, b) {
      //       let textA = ''
      //       let textB = ''
      //       if (a.phoneNumber) {
      //         textA = a.phoneNumber
      //       }
      //       if (b.phoneNumber) {
      //         textB = b.phoneNumber
      //       }
      //       return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      //     });
      //   }
      // } else if (type === 'numeric') {
      //   if (sortDescending) {
      //     members.sort(function(a, b) {
      //       let textA = ''
      //       let textB = ''
      //       if (a.createdAt) {
      //         textA = a.createdAt
      //       }
      //       if (b.createdAt) {
      //         textB = b.createdAt
      //       }
      //       return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
      //     });
      //   } else {
      //     members.sort(function(a, b) {
      //       let textA = ''
      //       let textB = ''
      //       if (a.createdAt) {
      //         textA = a.createdAt
      //       }
      //       if (b.createdAt) {
      //         textB = b.createdAt
      //       }
      //       return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      //     });
      //   }
      // } else {
      //
      //   if (sortDescending) {
      //     members.sort(function(a, b){
      //         if(a[sortValue] > b[sortValue]) { return -1; }
      //         if(a[sortValue] < b[sortValue]) { return 1; }
      //         return 0;
      //     })
      //   } else {
      //     members.sort(function(a, b){
      //         if(a[sortValue] < b[sortValue]) { return -1; }
      //         if(a[sortValue] > b[sortValue]) { return 1; }
      //         return 0;
      //     })
      //     console.log('?!')
      //   }
      // }
      //
      // sortDescending = !sortDescending
      // this.setState({ members, sortDescending })
    }

    menuButtonClicked(action, columnIndex, cellIndex, e, index, index2) {
      console.log('menuButtonClicked called', action, columnIndex, cellIndex, e, index, index2)

      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }

      if (action === 'sort') {
        this.sortValues(index, index2)

      } else if (action === 'move') {
        this.setState({ modalIsOpen: true, showMoveMenu: true, selectedIndex: index, selectedIndex2: index2 })
      } else if (action === 'delete') {
        this.setState({ confirmDelete: !this.state.confirmDelete })
      }
    }

    moveItems() {
      console.log('moveItems called', this.state.moveInstrux, this.state.moveInstrux2)

      if (!this.state.moveInstrux) {
        this.setState({ errorMessage: 'Please add details on where to move this item'})
      } else {
        let reportOptions = this.state.reportOptions
        let selectedElement = reportOptions[this.state.selectedIndex]
        let fields = this.state.reportOptions[this.state.selectedIndex].fields

        if (this.state.selectedIndex2 || this.state.selectedIndex2 === 0) {
          console.log('what is happening 0', this.state.moveInstrux, fields)
          selectedElement = fields[this.state.selectedIndex2]
          fields.splice(this.state.selectedIndex2,1)
          console.log('what is happening 1', this.state.moveInstrux, fields)
        } else {
          // reportOptions.splice(this.state.selectedIndex,1)
          fields.splice(this.state.selectedIndex2,1)
          console.log('what is happening 2', this.state.moveInstrux,  fields)
        }

        if (this.state.moveInstrux === 'To the Top') {

          // if (this.state.selectedIndex2 || this.state.selectedIndex2 === 0) {
          //   fields.unshift(selectedElement)
          //   // reportOptions[this.state.selectedIndex]['fields'] = fields
          // } else {
          //   // reportOptions.unshift(selectedElement)
          //   fields.unshift(selectedElement)
          // }
          // fields.splice(this.state.selectedIndex2,1)
          fields.unshift(selectedElement)
          reportOptions[this.state.selectedIndex]['fields'] = fields

        } else if (this.state.moveInstrux === 'To the Bottom') {

          // fields.push(selectedElement)
          // reportOptions[this.state.selectedIndex]['fields'] = fields

          for (let i = 1; i <= fields.length; i++) {
            if (fields[i - 1].type === "Multiple Answers Transposed" || fields[i - 1].type === "Survey") {
              fields.splice(i - 1,0,selectedElement)
              break;
            }
          }
          reportOptions[this.state.selectedIndex]['fields'] = fields

        } else if (this.state.moveInstrux === 'Before') {

        } else if (this.state.moveInstrux === 'After') {
          // if (this.state.selectedIndex2 || this.state.selectedIndex2 === 0) {
          //   for (let i = 1; i <= fields.length; i++) {
          //     if (fields[i - 1].name === this.state.moveInstrux2) {
          //       fields.splice(i,0,selectedElement)
          //     }
          //   }
          //   reportOptions[this.state.selectedIndex]['fields'] = fields
          // } else {
          //   for (let i = 1; i <= reportOptions.length; i++) {
          //     if (reportOptions[i - 1].name === this.state.moveInstrux2) {
          //       reportOptions.splice(i,0,selectedElement)
          //     }
          //   }
          // }


          for (let i = 1; i <= fields.length; i++) {
            if (fields[i - 1].name === this.state.moveInstrux2) {
              fields.splice(i,0,selectedElement)
            }
          }
          // fields.splice(this.state.selectedIndex2,1)
          reportOptions[this.state.selectedIndex]['fields'] = fields

          // fields.splice(this.state.selectedIndex2,1)
          // fields.push(selectedElement)
          // reportOptions[this.state.selectedIndex]['fields'] = fields
        }
        console.log('show this: ', reportOptions)
        this.setState({ reportOptions, errorMessage: null, menuIndex: null })
        this.closeModal()
        this.exportReport(this.state.selectedReportShortname, true, "reportOptions", reportOptions)

      }
    }

    saveReport() {
      console.log('saveReport called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.selectedReportShortname || this.state.selectedReportShortname === '') {
        this.setState({ errorMessage: 'Please select a report', isSaving: false })
      } else if (!this.state.reportName || this.state.reportName === '') {
        this.setState({ errorMessage: 'Please name your report', isSaving: false })
      } else if (this.state.reportOptions.some(ro => ro.name === this.state.reportName)) {
        this.setState({ errorMessage: 'Please add a unique name for your report', isSaving: false })
      } else {

        let _id = null
        if (this.state.selectedReportObject && this.state.selectedReportObject._id) {
          _id = this.state.selectedReportObject._id
        }
        const firstName = this.state.cuFirstName
        const lastName = this.state.cuLastName
        const email = this.state.emailId
        const username = this.state.username

        const name = this.state.reportName
        const description = this.state.reportDescription
        let shortname = this.state.selectedReportShortname
        let fields = []
        // let reportObject = null
        for (let i = 1; i <= this.state.reportOptions.length; i++) {
          if (this.state.reportOptions[i - 1].shortname === this.state.selectedReportShortname) {
            // reportObject = this.state.reportOptions[i - 1]
            shortname = this.state.reportOptions[i - 1].shortname
            fields = this.state.reportOptions[i - 1].fields
          }
        }
        // const reportType = this.state.selectedReportShortname
        const orgCode = this.state.org
        const placementPartners = this.state.placementPartners
        const department = this.state.department
        const pathway = this.state.pathway
        const accountCode = this.state.accountCode
        const olderThan = this.state.olderThan
        const youngerThan = this.state.youngerThan
        const afterDate = new Date(this.state.afterDate)
        const beforeDate = new Date(this.state.beforeDate)
        const assignedStaff = this.state.assignedStaff
        let assignedStaffEmail = null
        const assignedStaffName = this.state.assignedStaff

        if (assignedStaff) {
          for (let i = 1; i <= this.state.staffMemberObjectOptions.length; i++) {

            if (this.state.staffMemberObjectOptions[i - 1]) {
              const fullName = this.state.staffMemberObjectOptions[i - 1].firstName + ' ' + this.state.staffMemberObjectOptions[i - 1].lastName
              if (fullName === assignedStaff) {
                assignedStaffEmail = this.state.staffMemberObjectOptions[i - 1].email
              }
            }
          }
        }

        const selectedChallenge = this.state.selectedChallenge
        const selectedPosting = this.state.selectedPosting
        const selectedWork = this.state.selectedWork
        const selectedProject = this.state.selectedProject
        const selectedEvent = this.state.selectedEvent
        const selectedEmployer = this.state.selectedEmployer

        const approved = this.state.approved
        const surveyName = this.state.surveyName
        const lessonName = this.state.lessonName
        const reportFilters = this.state.reportFilters
        const sortCriteria = this.state.sortCriteria
        const reportOptions = this.state.reportOptions
        const logType = "Goal"

        const reportPayload = {
          _id, firstName, lastName, email, username, name, description, shortname, fields,
          orgCode, placementPartners, surveyName, lessonName, selectedEvent, selectedProject,
          selectedWork, selectedChallenge, selectedEmployer, selectedPosting,
          approved, logType, assignedStaffName,
          assignedStaffEmail, olderThan, youngerThan,
          department, pathway, accountCode, afterDate, beforeDate, reportFilters, sortCriteria
        }

        Axios.post('/api/reports', reportPayload)
        .then((response) => {
          if (response.data.success) {
            console.log('successfully saved profile')

            this.setState({ isSaving: false, successMessage: response.data.message })
            this.closeModal()

          } else {
            console.log('profile save was not successful')

            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('Saving the info did not work', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      }
    }

    openReport() {
      console.log('openReport called')

      // this.setState({ isSaving: true, errorMessage: null, successMessage: null })
      //
      // if (!this.state.reportToOpen || this.state.reportToOpen === '') {
      //   this.setState({ errorMessage: 'Please select a report', isSaving: false })
      // } else {
      //
      //   let selectedReport = null
      //   for (let i = 1; i <= this.state.reportOptions.length; i++) {
      //     if (this.state.reportOptions[i - 1].shortname === this.state.reportToOpen) {
      //       selectedReport = this.state.reportOptions[i - 1]
      //     }
      //   }
      //
      //   const name = this.state.reportName
      //   const description = this.state.reportDescription
      //
      //   const reportType = this.state.selectedReportShortname
      //   const orgCode = this.state.org
      //   const placementPartners = this.state.placementPartners
      //   const department = this.state.department
      //   const pathway = this.state.pathway
      //   const accountCode = this.state.accountCode
      //   const olderThan = this.state.olderThan
      //   const youngerThan = this.state.youngerThan
      //   const afterDate = new Date(this.state.afterDate)
      //   const beforeDate = new Date(this.state.beforeDate)
      //   const assignedStaff = this.state.assignedStaff
      //   let assignedStaffEmail = null
      //   const assignedStaffName = this.state.assignedStaff
      //
      //   if (assignedStaff) {
      //     for (let i = 1; i <= this.state.staffMemberObjectOptions.length; i++) {
      //
      //       if (this.state.staffMemberObjectOptions[i - 1]) {
      //         const fullName = this.state.staffMemberObjectOptions[i - 1].firstName + ' ' + this.state.staffMemberObjectOptions[i - 1].lastName
      //         if (fullName === assignedStaff) {
      //           assignedStaffEmail = this.state.staffMemberObjectOptions[i - 1].email
      //         }
      //       }
      //     }
      //   }
      //
      //   const selectedChallenge = this.state.selectedChallenge
      //   const selectedPosting = this.state.selectedPosting
      //   const selectedWork = this.state.selectedWork
      //   const selectedProject = this.state.selectedProject
      //   const selectedEvent = this.state.selectedEvent
      //   const approved = this.state.approved
      //   const surveyName = this.state.surveyName
      //   const lessonName = this.state.lessonName
      //   const reportFilters = this.state.reportFilters
      //   const sortCriteria = this.state.sortCriteria
      //   const reportOptions = this.state.reportOptions
      //   const logType = "Goal"
      //
      //
      //   this.setState({
      //     firstName, lastName, email, username, name, description, fields, reportType,
      //     orgCode, placementPartners, surveyName, lessonName, selectedEvent, selectedProject,
      //     selectedWork, approved, logType, assignedStaffName, assignedStaffEmail, olderThan, youngerThan,
      //     department, pathway, accountCode, afterDate, beforeDate, reportFilters, sortCriteria
      //   })
      //
      // }
    }

    deleteReport() {
      console.log('deleteReport called')

      this.setState({ isSaving: false, successMessage: null, errorMessage: null })

      const _id = this.state.selectedReportObject._id

      Axios.delete('/api/reports/' + _id)
      .then((response) => {
        console.log('tried to delete', response.data)
        if (response.data.success) {
          console.log('Report delete worked');

          const isSaving = true
          const successMessage = 'Successfully deleted the report'
          const confirmDelete = false
          const selectedReportObject = null
          const selectedReport = ''
          const selectedReportDescription = null
          const selectedReportShortname = null

          this.setState({
            isSaving, successMessage, confirmDelete, previewData: null,
            selectedReportObject, selectedReport, selectedReportDescription, selectedReportShortname
          })

        } else {
          console.error('an error: ', response.data.message);
          this.setState({ isSaving: false, errorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          this.setState({ isSaving: false, errorMessage: error.toString() })
      });
    }

    filterReport() {
      console.log('filterReport called')

      let reportFilters = this.state.reportFilters
      const name = this.state.filterName
      const operator = this.state.operatorName
      const value = this.state.filterValue
      const type = this.state.selectedFilter.type
      const shortname = this.state.selectedFilter.shortname
      if (this.state.editFilterIndex || this.state.editFilterIndex === 0) {
        reportFilters[this.state.editFilterIndex] = { name, operator, value, type, shortname }
      } else {
        if (reportFilters) {
          reportFilters.push({ name, operator, value, type, shortname })
        } else {
          reportFilters = [{ name, operator, value, type, shortname }]
        }
      }

      this.setState({ reportFilters, selectedFilter: null, filterName: '', operatorName: '', filterValue: '' })
      this.closeModal()
      this.exportReport(this.state.selectedReportShortname, true, "customFilters", reportFilters)

    }

    deleteFilter() {
      console.log('deleteFilter called')

      let reportFilters = this.state.reportFilters

      if (this.state.editFilterIndex || this.state.editFilterIndex === 0) {
        reportFilters.splice(this.state.editFilterIndex, 1)

        this.setState({ reportFilters, selectedFilter: null, filterName: '', operatorName: '', filterValue: '' })
        this.closeModal()
        this.exportReport(this.state.selectedReportShortname, true, "customFilters", reportFilters)
      }
    }

    deleteColumn(index, index2) {
      console.log('deleteColumn called', index, index2)

      // const previewData = this.state.previewData
      let columnName = this.state.previewData[index][index2]
      // console.log('columnName =', columnName)
      this.menuButtonClicked('delete',2)
      this.toggleMenu(null, index2)

      let reportOptions = this.state.reportOptions
      let fields = []

      for (let i = 1; i <= this.state.reportOptions.length; i++) {
        if (this.state.reportOptions[i - 1].shortname === this.state.selectedReportShortname) {
          fields = this.state.reportOptions[i - 1].fields
          console.log('f1')
          for (let j = 1; j <= this.state.reportOptions[i - 1].fields.length; j++) {
            console.log('f2', columnName)
            if (this.state.reportOptions[i - 1].fields[j - 1].name === columnName) {
              console.log('f3', columnName, fields)
              fields.splice(j - 1, 1)
            }
          }
          reportOptions [i - 1]['fields'] = fields
          console.log('f4', columnName, fields)
        }
      }

      this.setState({ reportOptions })

      this.exportReport(this.state.selectedReportShortname, true, 'fields', fields)

    }

    renderEligibleFieldsArray() {
      console.log('renderEligibleFieldsArray called', this.state.selectedReportObject.fields, this.state.selectedReportShortname)

      let eligibleFieldsArray = [{ name: '' }]
      let fields = this.state.selectedReportObject.fields
      if (this.state.selectedReportShortname === 'participants' && this.state.selectedReportObject.fields[this.state.numberOfNests] && this.state.selectedReportObject.fields[this.state.numberOfNests].length > 0) {
        fields = this.state.selectedReportObject.fields[this.state.numberOfNests]
      }

      for (let j = 1; j <= fields.length; j++) {
        // console.log('r2', j)
        if (fields[j - 1].type !== "Multiple Answers Transposed" && fields[j - 1].type !== "Survey") {
          // console.log('r3', j)
          eligibleFieldsArray.push(fields[j - 1])
        }
      }

      return eligibleFieldsArray

    }

    itemClicked(value, type) {
      console.log('itemClicked called', value, type)

      if (type === 'posting') {
        let workPostingOptions = null
        let selectedWork = value
        // for (let i = 1; i <= this.state.workPostingOptions.length; i++) {
        //   if (this.state.workPostingOptions[i - 1].title + ' | ' + this.state.workOptions[i - 1].employerName === '') {
        //     selectedWork = this.state.workPostingOptions[i - 1]
        //   }
        // }

        this.setState({ selectedWork, workPostingOptions, searchString: '' })
      }
    }

    render() {

      let mainClass = "width-70-percent max-width-1400 center-horizontally top-margin-2-percent card"
      let mainStyle = {}
      let subHeaderClass = ""
      let subHeaderStyle = {}
      let subBodyClass = ""
      let subBodyStyle = {}

      if (window.location.pathname.startsWith('/organizations') && !toggleVars.hideAdminSideNav) {
        mainClass = "floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent"
        mainStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform}

        if (this.state.showModule) {
          mainClass = ""
          mainStyle = {}
          subHeaderClass = "floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent"
          subHeaderStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform}
          // subBodyClass = "floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent"
          subBodyClass = "floating-container right-margin-2-percent bottom-margin-2-percent"
          subBodyStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform}
        }
      } else {
        if (this.state.showModule) {
          mainClass = ""
          mainStyle = {}
          subHeaderClass = "width-90-percent max-width-1400 center-horizontally top-margin-2-percent card-clear-padding padding-20"
          subHeaderStyle = {}
          subBodyClass = "width-90-percent max-width-1400 center-horizontally"
          subBodyStyle = {}
        }
      }

      return (
          <div className={mainClass} style={mainStyle}>
            <div>
              <div>
                <div className={subHeaderClass} style={subHeaderStyle}>
                  <div className={(this.state.showModule) ? "calc-column-offset-170" : "full-width"}>
                    <p className="heading-text-2">Reporting</p>
                    <p className="row-5 description-text-2">Export data to CSV files. Note there is a <label className="error-color">{Number(this.state.reportingResLimit).toLocaleString()}</label> row export limit and a <label className="error-color">{Number(this.state.previewResLimit).toLocaleString()}</label> row preview limit.</p>
                  </div>
                  {(this.state.showModule) && (
                    <span>
                      {/*
                      <div className={(this.state.selectedReportShortname) ? "fixed-column-80 top-margin-15" : "fixed-column-80 top-margin-15 wash-out-2"}>
                        <button className="btn btn-squarish full-width" onClick={() => this.exportReport(this.state.selectedReportShortname)}>Export</button>
                      </div>
                      <div className="fixed-column-90 right-padding top-margin-15">
                        <button className="btn btn-squarish-white full-width" onClick={() => this.setState({ modalIsOpen: true, showOpenWidget: true })}>Open</button>
                      </div>
                      <div className={(this.state.selectedReportShortname) ? "fixed-column-80 top-margin-15" : "fixed-column-80 top-margin-15 wash-out-2"}>
                        <button className="btn btn-squarish clear-border full-width" onClick={() => this.setState({ modalIsOpen: true, showSaveWidget: true })}>Save</button>
                      </div>*/}
                    </span>
                  )}
                  <div className="clear" />
                </div>

                <div className={subBodyClass} style={subBodyStyle}>
                  <div className={(this.state.showModule) ? "relative-column-25 card-clear-padding padding-20" : ""}>
                    {(this.state.showModule) ? (
                      <div>
                        <button className="background-button standard-border padding-10 full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showEditor: true, selectedName: "Select a Report", selectedShortname: "selectedReport", selectedReportOptions: this.state.reportOptions, selectedSubKey: "name", selectedType: "dropdown" })}>
                          <div className="calc-column-offset-15 right-padding-5">
                            <p className="description-text-3">Report</p>
                            <p className="description-text-1 cta-color top-padding-5">{this.state.selectedReport}</p>
                          </div>
                          <div className="fixed-column-15 top-margin">
                            <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                          </div>
                          <div className="clear" />
                        </button>

                        {(this.state.selectedReportShortname) && (
                          <div>
                            {(this.state.isLoading) ? (
                              <div className="flex-container flex-center row-30">
                                <div>
                                  <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally" />
                                  <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                                  <p className="center-text cta-color bold-text">{(this.state.orgFocus === 'Placement') ? "Calculating results..." : "Calculating results and work matches..."}</p>
                                </div>
                              </div>
                            ) : (
                              <div>

                                {(this.state.selectedReportShortname === 'applications') && (
                                  <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, showEditor: true, selectedName: "Work or Program Posting", selectedPlaceholder: 'Search for a work or program posting...', selectedShortname: "selectedWork", selectedSpecialValue: (this.state.selectedWork && this.state.selectedWork.title && this.state.selectedWork.title !== 'All') ? this.state.selectedWork.title + ' | ' + this.state.selectedWork.employerName : (this.state.selectedWork) ? this.state.selectedWork.title : "", selectedReportOptions: this.state.workOptions, selectedSubKey: "title", selectedType: this.state.customInputType })}>
                                    <div className="calc-column-offset-15 right-padding-5">
                                      <p className="description-text-3">Work or Program Posting</p>
                                      <p className="description-text-1 cta-color top-padding-5">{(this.state.selectedWork && this.state.selectedWork.title && this.state.selectedWork.title !== 'All') ? this.state.selectedWork.title + ' | ' + this.state.selectedWork.employerName : (this.state.selectedWork) ? this.state.selectedWork.title : ""}</p>
                                    </div>
                                    <div className="fixed-column-15 top-margin">
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                    </div>
                                    <div className="clear" />
                                  </button>
                                )}

                                {(this.state.selectedReportShortname === 'projectSubmissions') && (
                                  <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, showEditor: true, selectedName: "Project Posting", selectedShortname: "selectedProject", selectedSpecialValue: (this.state.selectedProject) ? this.state.selectedProject.name : "", selectedReportOptions: this.state.projectOptions, selectedSubKey: "name", selectedType: "dropdown" })}>
                                    <div className="calc-column-offset-15 right-padding-5">
                                      <p className="description-text-3">Project Posting</p>
                                      <p className="description-text-1 cta-color top-padding-5">{(this.state.selectedProject) ? this.state.selectedProject.name : ""}</p>
                                    </div>
                                    <div className="fixed-column-15 top-margin">
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                    </div>
                                    <div className="clear" />
                                  </button>
                                )}

                                {(this.state.selectedReportShortname === 'rsvps' || this.state.selectedReportShortname === 'attendees') && (
                                  <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, showEditor: true, selectedName: "Event Posting", selectedShortname: "selectedEvent", selectedSpecialValue: (this.state.selectedEvent.startDate) ? this.state.selectedEvent.title + ' on ' + convertDateToString(new Date(this.state.selectedEvent.startDate),'date-2') : this.state.selectedEvent.title, selectedReportOptions: this.state.eventOptions, selectedSubKey: "title", selectedType: "dropdown" })}>
                                    <div className="calc-column-offset-15 right-padding-5">
                                      <p className="description-text-3">Event Posting</p>
                                      <p className="description-text-1 cta-color top-padding-5">{(this.state.selectedEvent.startDate) ? this.state.selectedEvent.title + ' on ' + convertDateToString(new Date(this.state.selectedEvent.startDate),'date-2') : this.state.selectedEvent.title}</p>
                                    </div>
                                    <div className="fixed-column-15 top-margin">
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                    </div>
                                    <div className="clear" />
                                  </button>
                                )}
                                <div className="clear" />
                                {(this.state.selectedReportShortname === 'attendees') && (
                                  <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, showEditor: true, selectedName: (this.state.org === 'unite-la') ? "Certified" : "Passed", selectedShortname: "approved", selectedSpecialValue: this.state.approved, selectedReportOptions: this.state.binaryOptions, selectedSubKey: null, selectedType: "dropdown" })}>
                                    <div className="calc-column-offset-15 right-padding-5">
                                      <p className="description-text-3">{(this.state.org === 'unite-la') ? "Certified" : "Passed"}</p>
                                      <p className="description-text-1 cta-color top-padding-5">{this.state.approved}</p>
                                    </div>
                                    <div className="fixed-column-15 top-margin">
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                    </div>
                                    <div className="clear" />
                                  </button>
                                )}
                                <div className="clear" />
                                {(this.state.selectedReportShortname === 'postings' || this.state.selectedReportShortname === 'applications' || this.state.selectedReportShortname === 'rsvps' || this.state.selectedReportShortname === 'attendees' || this.state.selectedReportShortname === 'placements' || this.state.selectedReportShortname === 'timesheets') && (
                                  <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, showEditor: true, selectedName: "Employer", selectedShortname: "selectedEmployer", selectedSpecialValue: this.state.selectedEmployer.employerName, selectedReportOptions: this.state.employerOptions, selectedSubKey: "employerName", selectedType: "dropdown" })}>
                                    <div className="calc-column-offset-15 right-padding-5">
                                      <p className="description-text-3">Employer</p>
                                      <p className="description-text-1 cta-color top-padding-5">{this.state.selectedEmployer.employerName}</p>
                                    </div>
                                    <div className="fixed-column-15 top-margin">
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                    </div>
                                    <div className="clear" />
                                  </button>
                                )}
                                <div className="clear" />
                                {(this.state.selectedReportShortname === 'challengeVoting') && (
                                  <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, showEditor: true, selectedName: "Challenge", selectedShortname: "challenge", selectedSpecialValue: this.state.selectedChallenge, selectedReportOptions: this.state.challengeOptions, selectedSubKey: "name", selectedType: "dropdown" })}>
                                    <div className="calc-column-offset-15 right-padding-5">
                                      <p className="description-text-3">Challenge</p>
                                      <p className="description-text-1 cta-color top-padding-5">{this.state.selectedChallenge}</p>
                                    </div>
                                    <div className="fixed-column-15 top-margin">
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                    </div>
                                    <div className="clear" />
                                  </button>
                                )}
                                <div className="clear" />
                                {((this.state.selectedReportShortname.includes('postings') || this.state.selectedReportShortname.includes('applications') || this.state.selectedReportShortname.includes('submissions') || this.state.selectedReportShortname.includes('rsvps') || this.state.selectedReportShortname.includes('attendees') || this.state.selectedReportShortname.includes('placements')) && this.state.departmentOptions && this.state.departmentOptions.length > 0) && (
                                  <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, showEditor: true, selectedName: "Department", selectedShortname: "department", selectedSpecialValue: this.state.department, selectedReportOptions: this.state.departmentOptions, selectedSubKey: "name", selectedType: "dropdown" })}>
                                    <div className="calc-column-offset-15 right-padding-5">
                                      <p className="description-text-3">Department</p>
                                      <p className="description-text-1 cta-color top-padding-5">{this.state.department}</p>
                                    </div>
                                    <div className="fixed-column-15 top-margin">
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                    </div>
                                    <div className="clear" />
                                  </button>
                                )}
                                <div className="clear" />
                                {((this.state.selectedReportShortname.includes('postings') || this.state.selectedReportShortname.includes('applications') || this.state.selectedReportShortname.includes('submissions') || this.state.selectedReportShortname.includes('rsvps') || this.state.selectedReportShortname.includes('attendees') || this.state.selectedReportShortname.includes('placements') || this.state.selectedReportShortname.includes('members')) && this.state.pathwayOptions && this.state.pathwayOptions.length > 0) && (
                                  <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, showEditor: true, selectedName: "Pathway", selectedShortname: "pathway", selectedSpecialValue: this.state.pathway, selectedReportOptions: this.state.pathwayOptions, selectedSubKey: "title", selectedType: "dropdown" })}>
                                    <div className="calc-column-offset-15 right-padding-5">
                                      <p className="description-text-3">Pathway</p>
                                      <p className="description-text-1 cta-color top-padding-5">{this.state.pathway}</p>
                                    </div>
                                    <div className="fixed-column-15 top-margin">
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                    </div>
                                    <div className="clear" />
                                  </button>
                                )}
                                <div className="clear" />
                                {((this.state.selectedReportShortname.includes('members')) || this.state.selectedReportShortname.includes('caseNotes')) && (
                                  <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, showEditor: true, selectedName: "Assigned Staff", selectedShortname: "assignedStaff", selectedSpecialValue: this.state.assignedStaff, selectedReportOptions: this.state.staffMemberOptions, selectedSubKey: null, selectedType: "dropdown" })}>
                                    <div className="calc-column-offset-15 right-padding-5">
                                      <p className="description-text-3">Assigned Staff</p>
                                      <p className="description-text-1 cta-color top-padding-5">{this.state.assignedStaff}</p>
                                    </div>
                                    <div className="fixed-column-15 top-margin">
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                    </div>
                                    <div className="clear" />
                                  </button>
                                )}
                                <div className="clear" />
                                {(this.state.selectedReportShortname.includes('members')) && (
                                  <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, showEditor: true, selectedName: "Older Than", selectedShortname: "olderThan", selectedSpecialValue: this.state.olderThan, selectedReportOptions: this.state.ageOptions, selectedSubKey: null, selectedType: "dropdown" })}>
                                    <div className="calc-column-offset-15 right-padding-5">
                                      <p className="description-text-3">Older Than</p>
                                      <p className="description-text-1 cta-color top-padding-5">{this.state.olderThan}</p>
                                    </div>
                                    <div className="fixed-column-15 top-margin">
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                    </div>
                                    <div className="clear" />
                                  </button>
                                )}
                                <div className="clear" />
                                {(this.state.selectedReportShortname.includes('members')) && (
                                  <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, showEditor: true, selectedName: "Younger Than", selectedShortname: "youngerThan", selectedSpecialValue: this.state.youngerThan, selectedReportOptions: this.state.ageOptions, selectedSubKey: null, selectedType: "dropdown" })}>
                                    <div className="calc-column-offset-15 right-padding-5">
                                      <p className="description-text-3">Younger Than</p>
                                      <p className="description-text-1 cta-color top-padding-5">{this.state.youngerThan}</p>
                                    </div>
                                    <div className="fixed-column-15 top-margin">
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                    </div>
                                    <div className="clear" />
                                  </button>
                                )}
                                <div className="clear" />
                                {(this.state.selectedReportShortname.includes('surveySubmissions')) && (
                                  <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, showEditor: true, selectedName: "Survey Name", selectedShortname: "surveyName", selectedSpecialValue: this.state.surveyName, selectedReportOptions: this.state.assessmentOptions, selectedSubKey: "title", selectedType: "dropdown" })}>
                                    <div className="calc-column-offset-15 right-padding-5">
                                      <p className="description-text-3">Survey Name</p>
                                      <p className="description-text-1 cta-color top-padding-5">{this.state.surveyName}</p>
                                    </div>
                                    <div className="fixed-column-15 top-margin">
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                    </div>
                                    <div className="clear" />
                                  </button>
                                )}
                                <div className="clear" />
                                {(this.state.selectedReportShortname.includes('surveySubmissions')) && (
                                  <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, showEditor: true, selectedName: "Lesson Name", selectedShortname: "lessonName", selectedSpecialValue: this.state.lessonName, selectedReportOptions: this.state.lessonNameOptions, selectedSubKey: null, selectedType: "dropdown" })}>
                                    <div className="calc-column-offset-15 right-padding-5">
                                      <p className="description-text-3">Lesson Name</p>
                                      <p className="description-text-1 cta-color top-padding-5">{this.state.lessonName}</p>
                                    </div>
                                    <div className="fixed-column-15 top-margin">
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                    </div>
                                    <div className="clear" />
                                  </button>
                                )}
                                <div className="clear" />

                                <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, showEditor: true, selectedName: "Created After", selectedShortname: "afterDate", selectedReportOptions: null, selectedSubKey: null, selectedType: "date" })}>
                                  <div className="calc-column-offset-15 right-padding-5">
                                    <p className="description-text-3">Created After</p>
                                    <p className="description-text-1 cta-color top-padding-5">{this.state.afterDate}</p>
                                  </div>
                                  <div className="fixed-column-15 top-margin">
                                    <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                  </div>
                                  <div className="clear" />
                                </button>
                                <div className="clear" />
                                <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, showEditor: true, selectedName: "Created Before", selectedShortname: "beforeDate", selectedReportOptions: null, selectedSubKey: null, selectedType: "date" })}>
                                  <div className="calc-column-offset-15 right-padding-5">
                                    <p className="description-text-3">Created Before</p>
                                    <p className="description-text-1 cta-color top-padding-5">{this.state.beforeDate}</p>
                                  </div>
                                  <div className="fixed-column-15 top-margin">
                                    <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                  </div>
                                  <div className="clear" />
                                </button>

                                <div className="clear" />

                                {(this.state.reportFilters && this.state.reportFilters.length > 0) && (
                                  <div>
                                    {this.state.reportFilters.map((value, index) =>
                                      <div key={"reportfilter|" + index}>
                                        <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, editCustomFilter: true, selectedFilter: value, filterName: value.name, operatorName: value.operator, filterValue: value.value, type: value.type, editFilterIndex: index })}>
                                          <div className="calc-column-offset-15 right-padding-5">
                                            <p className="description-text-3">{value.name}</p>
                                            <p className="description-text-1 cta-color top-padding-5">{value.value}</p>
                                          </div>
                                          <div className="fixed-column-15 top-margin">
                                            <img src={arrowIndicatorIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                          </div>
                                          <div className="clear" />
                                        </button>
                                        <div className="clear" />
                                      </div>
                                    )}

                                    <div className="clear" />
                                  </div>
                                )}

                                <button className="background-button standard-border padding-10 full-width left-text top-margin" onClick={() => this.setState({ modalIsOpen: true, editCustomFilter: true })}>
                                  <div className="calc-column-offset-15 right-padding-5 top-margin-5">
                                    <p className="description-text-2 cta-color">Add a Custom Filter</p>
                                  </div>
                                  <div className="fixed-column-15 top-margin-5">
                                    <img src={addIconBlue} alt="GC" className="image-15-fit rotate-90" />
                                  </div>
                                  <div className="clear" />
                                </button>

                                <div className="clear" />
                                {(this.state.errorMessage && this.state.errorMessage!== '') && <p className="row-10 error-color">{this.state.errorMessage}</p>}
                                {(this.state.successMessage && this.state.successMesssage!== '') && <p className="row-10 cta-color">{this.state.successMesssage}</p>}

                                <div className="top-margin-20">
                                  <div className="relative-column-50 right-padding-5">
                                    <button className="btn btn-squarish full-width" onClick={() => this.exportReport(this.state.selectedReportShortname)}>Export</button>
                                  </div>
                                  <div className="relative-column-50 left-padding-5">
                                    <button className="btn btn-squarish-white full-width" onClick={() => this.setState({ modalIsOpen: true, showSaveWidget: true, reportName: this.state.selectedReportName, reportDescription: this.state.selectedReportDescription })}>Save</button>
                                  </div>
                                  <div className="clear" />
                                </div>

                                {(this.state.selectedReportObject && this.state.selectedReportObject._id) && (
                                  <div className="top-margin-20">
                                    {(this.state.confirmDelete) ? (
                                      <div>
                                        <p className="error-color description-text-2 bottom-margin">Are you sure?</p>

                                        <div className="relative-column-50 right-padding-5">
                                          <button className="btn btn-squarish error-background-color error-border full-width" onClick={() => this.deleteReport()}>Yes</button>
                                        </div>
                                        <div className="relative-column-50 left-padding-5">
                                          <button className="btn btn-squarish-white full-width" onClick={() => this.setState({ confirmDelete: false })}>Cancel</button>
                                        </div>
                                        <div className="clear" />
                                      </div>
                                    ) : (
                                      <div>
                                        <button className="btn btn-squarish error-background-color error-border full-width" onClick={() => this.setState({ confirmDelete: true })}>Delete Custom Report</button>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div className="row-10">
                          <SubCustomDropdown dropdownTitle="Select a Report" name="selectedReport" value={this.state.selectedReport} options={this.state.reportOptions} optionSubKey="name" optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                          <div className="clear"/>
                        </div>

                        {(this.state.selectedReportDescription) && (
                          <div className="row-10">
                            <hr />
                            <div className="top-padding-20">
                              <p className="heading-text-4">{ this.state.selectedReport}</p>
                              <p className="row-5 description-text-2">{ this.state.selectedReportDescription}</p>
                            </div>

                            {(this.state.isLoading) ? (
                              <div className="flex-container flex-center row-30">
                                <div>
                                  <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally" />
                                  <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                                  {(this.state.orgFocus === 'Placement') ? (
                                    <p className="center-text cta-color bold-text">Calculating results...</p>
                                  ) : (
                                    <p className="center-text cta-color bold-text">Calculating results and work matches...</p>
                                  )}

                                </div>
                              </div>
                            ) : (
                              <div>
                                {(this.state.selectedReport === 'Applications Report') && (
                                  <SubCustomDropdown dropdownTitle="Work or Program Posting" name="selectedWork" value={(this.state.selectedWork.title && this.state.selectedWork.title !== 'All') ? this.state.selectedWork.title + ' | ' + this.state.selectedWork.employerName : this.state.selectedWork.title} options={this.state.workOptions} optionSubKey="title" optionClass="calc-column-offset-140 curtail-text" formChangeHandler={this.formChangeHandler}/>
                                )}

                                {(this.state.selectedReport === 'Submissions Report') && (
                                  <SubCustomDropdown dropdownTitle="Project Posting" name="selectedProject" value={this.state.selectedProject.name} options={this.state.projectOptions} optionSubKey="title" optionClass="calc-column-offset-140 curtail-text" formChangeHandler={this.formChangeHandler}/>
                                )}

                                {(this.state.selectedReport === 'RSVP Report' || this.state.selectedReport === 'Attendees Report') && (
                                  <SubCustomDropdown dropdownTitle="Event Posting" name="selectedEvent" value={(this.state.selectedEvent.title === 'All') ? this.state.selectedEvent.title : this.state.selectedEvent.title + ' on ' + convertDateToString(new Date(this.state.selectedEvent.startDate),'date-2')} options={this.state.eventOptions} optionSubKey="title" optionClass="calc-column-offset-140 curtail-text" formChangeHandler={this.formChangeHandler} />
                                )}

                                {(this.state.selectedReport === 'Attendees Report') && (
                                  <SubCustomDropdown dropdownTitle={(this.state.org === 'unite-la') ? "Certified" : "Passed"} name="approved" value={this.state.approved} options={this.state.binaryOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                                )}

                                {(this.state.selectedReport === 'Postings Report' || this.state.selectedReport === 'Applications Report' || this.state.selectedReport === 'RSVP Report' || this.state.selectedReport === 'Attendees Report' || this.state.selectedReport === 'Placements Report' || this.state.selectedReport === 'Timesheets Report') && (
                                  <SubCustomDropdown dropdownTitle="Employer" name="selectedEmployer" value={this.state.selectedEmployer.employerName} options={this.state.employerOptions} optionSubKey="employerName" optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                                )}

                                {(this.state.selectedReport === 'Challenge Voting Report') && (
                                  <SubCustomDropdown dropdownTitle="Challenge" name="challenge" value={this.state.selectedChallenge} options={this.state.challengeOptions} optionSubKey="name" optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                                )}
                                {((this.state.selectedReport.includes('Posting') || this.state.selectedReport.includes('Application') || this.state.selectedReport.includes('Submission') || this.state.selectedReport.includes('RSVP') || this.state.selectedReport.includes('Attendees') || this.state.selectedReport.includes('Placement')) && this.state.departmentOptions && this.state.departmentOptions.length > 0) && (
                                  <SubCustomDropdown dropdownTitle="Department" name="department" value={this.state.department} options={this.state.departmentOptions} optionSubKey="name" optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                                )}

                                {((this.state.selectedReport.includes('Posting') || this.state.selectedReport.includes('Application') || this.state.selectedReport.includes('Submission') || this.state.selectedReport.includes('RSVP') || this.state.selectedReport.includes('Attendees') || this.state.selectedReport.includes('Placement') || this.state.selectedReport.includes('Members')) && this.state.pathwayOptions && this.state.pathwayOptions.length > 0) && (
                                  <SubCustomDropdown dropdownTitle="Pathway" name="pathway" value={this.state.pathway} options={this.state.pathwayOptions} optionSubKey="title" optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                                )}

                                {((this.state.selectedReport.includes('Members')) || this.state.selectedReport.includes('Case Notes')) && (
                                  <SubCustomDropdown dropdownTitle="Assigned Staff" name="assignedStaff" value={this.state.assignedStaff} options={this.state.staffMemberOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                                )}

                                {(this.state.selectedReport.includes('Members')) && (
                                  <SubCustomDropdown dropdownTitle="Older Than" name="olderThan" value={this.state.olderThan} options={this.state.ageOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                                )}
                                {(this.state.selectedReport.includes('Members')) && (
                                  <SubCustomDropdown dropdownTitle="Younger Than" name="youngerThan" value={this.state.youngerThan} options={this.state.ageOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                                )}

                                {(this.state.selectedReport.includes('Survey Submissions')) && (
                                  <SubCustomDropdown dropdownTitle="Survey Name" name="surveyName" value={this.state.surveyName} options={this.state.assessmentOptions} optionSubKey={"title"} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                                )}

                                {(this.state.selectedReport.includes('Survey Submissions')) && (
                                  <SubCustomDropdown dropdownTitle="Lesson Name" name="lessonName" value={this.state.lessonName} options={this.state.lessonNameOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                                )}

                                <div className="light-border float-left top-padding-4 right-padding-5 top-margin-15 right-margin height-46">
                                  <div className="display-inline float-left top-padding-8 right-padding-5 left-padding nowrap">
                                    <p className="description-text-color">Created After</p>
                                  </div>
                                  <div className="float-left curtail-text">
                                    <input type="date" className="date-picker white-background clear-border" name="afterDate" value={this.state.afterDate} onChange={this.formChangeHandler}></input>
                                  </div>
                                </div>

                                <div className="light-border float-left top-padding-4 right-padding-5 top-margin-15 right-margin height-46">
                                  <div className="display-inline float-left top-padding-8 right-padding-5 left-padding nowrap">
                                    <p className="description-text-color">Created Before</p>
                                  </div>
                                  <div className="float-left curtail-text">
                                    <input type="date" className="date-picker white-background clear-border" name="beforeDate" value={this.state.beforeDate} onChange={this.formChangeHandler}></input>
                                  </div>
                                </div>

                                <div className="clear" />
                                {(this.state.errorMessage!== '') && <p className="row-10 error-color">{this.state.errorMessage}</p> }

                                <button className="btn btn-squarish top-margin-20" onClick={() => this.exportReport(this.state.selectedReportShortname)}>Export</button>

                                {(this.state.reportingResLimit) && (
                                  <div className="top-margin">
                                    <div className="row-20">
                                      <hr />
                                    </div>

                                    <div className="row-10">
                                      <p className="bottom-margin"><label className="error-color">{Number(this.state.reportingResLimit).toLocaleString()}</label> Export Limit</p>
                                      <p className="description-text-2"><label className="bold-text">Note:</label> For performance purposes, exported records are limited to <label className="error-color">{Number(this.state.reportingResLimit).toLocaleString()}</label>. If you regularly need additional records per export, please contact customer support to set up a faster process.</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}

                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {(this.state.showModule) && (
                    <div className={(this.state.showModule) ? "relative-column-75" : ""}>
                      {(this.state.previewData && this.state.previewData.length > 0) && (
                        <div>
                          <div className="card-clear-padding">
                            {(this.state.useTable) ? (
                              <div className="carousel-3 selected-background-light description-text-3 bold-text">
                                <table className="standard-border">
                                  {this.state.previewData.map((value, index) =>
                                    <tr key={"allPreviewData|" + index}>
                                      {(index === 0) ? (
                                        <th className="display-inline">
                                          {value.map((value2, index2) =>
                                            <div key={"pth|" + index2} id={"pth|" + index2} className="display-inline standard-border relative-position max-width-700">
                                              <button className={(this.state.selectedReportObject.fields && this.state.selectedReportObject.fields[index2] && this.state.selectedReportObject.fields[index2].disableMenu) ? "menu-button left-text no-pointers" : "menu-button left-text"} disabled={(this.state.selectedReportObject.fields && this.state.selectedReportObject.fields[index2] && this.state.selectedReportObject.fields[index2].disableMenu) ? true : false} onClick={(e) => this.toggleMenu(e, index2)}>
                                                <div className="float-left right-padding">
                                                  <p className="left-text">{value2}</p>
                                                </div>

                                                <div className="float-left">
                                                  <div className={(this.state.selectedReportObject.fields && (!this.state.selectedReportObject.fields[index2] || (this.state.selectedReportObject.fields[index2] && this.state.selectedReportObject.fields[index2].name !== value2))) ? "clear-padding padding-3 dark-border rounded-corners wash-out" : "clear-padding padding-3 dark-border rounded-corners"}>
                                                    <img src={dropdownArrowDark} alt="GC" className="image-9-fit" />
                                                  </div>
                                                </div>
                                                <div className="clear" />
                                              </button>

                                              {(this.state.menuIndex === index2) && (
                                                <div className="menu-bottom-full-width top-margin padding-10">
                                                  {this.state.menuOptions.map((item2,optionIndex) =>
                                                    <div key={item2.title}>
                                                      {(item2.title === 'Delete' && this.state.confirmDelete) ? (
                                                        <div>
                                                          <p className="description-text-1 error-color bold-text row-10 left-text">You sure?</p>
                                                          <div className="row-5">
                                                            <button className="btn btn-squarish error-background-color full-width clear-border description-text-3" onClick={() => this.deleteColumn(index, index2)}>Confirm</button>
                                                          </div>
                                                          <div className="row-5">
                                                            <button className="btn btn-squarish white-background full-width cta-color description-text-3" onClick={() => this.menuButtonClicked(item2.shorthand,optionIndex)}>Cancel</button>
                                                          </div>
                                                          <div className="clear" />
                                                        </div>
                                                      ) : (
                                                        <button className="background-button row-10 clear-margin left-text full-width" onClick={() => this.menuButtonClicked(item2.shorthand,optionIndex, null, null, this.state.reportOptions.findIndex(r => r.shortname === this.state.selectedReportShortname), index2)}>
                                                          <div className="fixed-column-25">
                                                            <img src={item2.icon} alt="GC" className="image-14-fit top-margin-3"/>
                                                          </div>
                                                          <div className="calc-column-offset-25">
                                                            <p className={(item2.title === 'Delete') ? "error-color bold-text" : "description-text-"}>{item2.title}</p>
                                                          </div>
                                                          <div className="clear" />
                                                        </button>
                                                      )}
                                                    </div>
                                                  )}
                                                  <div className="clear" />
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </th>
                                      ) : (
                                        <td className="display-inline white-background">
                                          {value.map((value2, index2) =>
                                            <div key={"ptd|" + index + "|" + index2} id={"ptd|" + index + "|" + index2} className={(this.state.showMultilineCell) ? "display-inline standard-border max-width-700 wrap-text-2" : "display-inline standard-border max-width-700 curtail-text"}>
                                              <div className="row-10 horizontal-padding-4 left-text">
                                                <p>{(value2) ? value2 : " - "}</p>
                                              </div>
                                            </div>
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  )}
                                </table>
                              </div>
                            ) : (
                              <div className="carousel-3 selected-background-light description-text-3 bold-text horizontal-padding-4">
                                <div className="fixed-column-40">
                                  <button className="menu-button left-text" onClick={() => this.sortValues('numeric',this.state.members)}>
                                    <p>#</p>
                                  </button>
                                </div>

                                {this.state.previewData.map((value, index) =>
                                  <div key={"allPreviewData|" + index}>
                                    {(index < 100) && (
                                      <div>
                                        {(index === 0) ? (
                                          <div className="display-inline">
                                            {value.map((value2, index2) =>
                                              <div key={"previewHeader" + index2} className="display-inline standard-border">
                                                <button className="menu-button left-text" onClick={() => this.sortValues(value2)}>
                                                  <div className="float-left right-padding">
                                                    <p className="left-text">{value2}</p>
                                                  </div>
                                                  <div className="float-left">
                                                    <button className="background-button clear-padding padding-3 dark-border rounded-corners" onClick={(e) => this.toggleMenu(e, index2)}>
                                                      <img src={dropdownArrowDark} alt="GC" className="image-9-fit" />
                                                    </button>
                                                  </div>
                                                  <div className="clear" />
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <div className="display-inline white-background standard-border">
                                            <div className="fixed-column-40 row-10 horizontal-padding-4">
                                              <p>{index}.</p>
                                            </div>
                                            {value.map((value2, index2) =>
                                              <div key={"previewData|" + index2} className="display-inline">
                                                <button className="background-button row-10 horizontal-padding-4 left-text" onClick={() => this.sortValues(value2)}>
                                                  <p>{value2}</p>
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>


                        </div>
                      )}
                    </div>
                  )}
                  <div className="clear" />
                </div>
              </div>

            </div>

            <div className="clear" />

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
            >
              <div className="full-width">
                {(this.state.showEditor) && (
                  <div key="showEditor" className="full-width">
                    <div className="row-10">
                      <div className="calc-column-offset-20 right-padding">
                        <p className="heading-text-2">{this.state.selectedName}</p>
                      </div>
                      <div className="fixed-column-20 top-margin">
                        <button className="background-button" onClick={() => this.closeModal()}>
                          <img src={closeIcon} alt="GC" className="image-auto-20" />
                        </button>
                      </div>
                      <div className="clear" />

                      <div className="top-padding">
                        {(this.state.selectedType === 'dropdown') ? (
                          <div className="full-width contain-text row-30">
                            <p className="row-5 description-text-color">{(this.state.selectedName && this.state.selectedName.startsWith("Select")) ? "" : "Select "}{this.state.selectedName}</p>

                            <select name={this.state.selectedShortname} className="dropdown" value={(this.state.selectedSpecialValue) ? this.state.selectedSpecialValue : this.state[this.state.selectedShortname]} onChange={this.formChangeHandler}>
                              {this.renderOptions(this.state.selectedReportOptions)}
                            </select>
                          </div>
                        ) : (
                          <div>
                            {(this.state.selectedType === 'search') ? (
                              <div>
                                <p className="row-5 description-text-color">{(this.state.selectedName && this.state.selectedName.startsWith("Select")) ? "" : "Select "}{this.state.selectedName}</p>

                                <div className="light-border top-margin">
                                  <div className="fixed-column-25 top-padding left-padding">
                                    <img src={searchIcon} alt="GC" className="image-15-fit" />
                                  </div>
                                  <div className="calc-column-offset-25 left-padding">
                                    <input type="text" className="text-field clear-border" placeholder={this.state.selectedPlaceholder} name={this.state.selectedShortname} value={this.state.searchString} onChange={this.formChangeHandler}></input>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="clear" />
                                <div className="spacer" />

                                {(this.state.isLoading) && (
                                  <div className="flex-container flex-center row-10">
                                    <div>
                                      <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                                      <div className="spacer" /><div className="spacer"/><div className="spacer"/>
                                      <p className="center-text cta-color bold-text">Retrieving info...</p>
                                    </div>
                                  </div>
                                )}

                                {(this.state.workPostingOptions && this.state.workPostingOptions.length > 0) && (
                                  <div>
                                    <div className="spacer" />
                                    {this.state.workPostingOptions.map(value =>
                                      <div key={value._id} className="row-5">
                                        <button className="background-button full-width" onClick={() => this.itemClicked(value,'posting')}>
                                          <div>
                                            <div className="fixed-column-30">
                                              <img src={opportunitiesIconBlue} alt="GC" className="image-auto-22" />
                                            </div>
                                            <div className="calc-column-offset-30">
                                              <p className="cta-color left-text">{(value.title) ? value.title : value.name}{(value.employerName) && ' @ ' + value.employerName} ({value.postType}){(value.createdAt) && ' ( Created ' + convertDateToString(new Date(value.createdAt),'date-2') + ')'}</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        </button>
                                      </div>)}
                                  </div>
                                )}

                                {(this.state.selectedWork && this.state.selectedWork.title && this.state.selectedWork.title !== 'All') && (
                                  <div>
                                    <div className="float-left">
                                      <div className="close-button-container-1">
                                        <button className="background-button" onClick={() => this.setState({ selectedWork: null })}>
                                          <img src={deniedIcon} alt="GC" className="image-auto-20" />
                                        </button>
                                      </div>
                                      <div className="float-left row-5 right-padding">
                                        <div className="tag-container-5">
                                          <p className="description-text-2">{(this.state.selectedWork.title) ? this.state.selectedWork.title : this.state.selectedWork.name}{(this.state.selectedWork.employerName) && ' @ ' + this.state.selectedWork.employerName}{(this.state.selectedWork.createdAt) && ' ( Created ' + convertDateToString(new Date(this.state.selectedWork.createdAt),'date-2') + ')'}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                )}

                              </div>
                            ) : (
                              <div className="light-border top-margin">
                                <input type="date" className="date-picker white-background clear-border" name={this.state.selectedShortname} value={this.state[this.state.selectedShortname]} onChange={this.formChangeHandler}></input>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="clear"/>
                    </div>

                    <div className="row-10">
                      <button className="btn btn-primary right-margin" onClick={() => this.exportReport(this.state.selectedReportShortname, true, null, null, null)}>Create Preview</button>
                      <button className="btn btn-secondary right-margin" onClick={() => this.closeModal()}>Close Without Preview</button>
                    </div>
                   </div>
                )}

                {(this.state.editCustomFilter) && (
                  <div key="editCustomFilter" className="full-width padding-10">
                    <div className="calc-column-offset-20 right-padding">
                      <p className="heading-text-2">{(this.state.editFilterIndex) ? "Edit" : "Add"} Custom Field</p>
                    </div>
                    <div className="fixed-column-20 top-margin">
                      <button className="background-button" onClick={() => this.closeModal()}>
                        <img src={closeIcon} alt="GC" className="image-auto-20" />
                      </button>
                    </div>
                    <div className="clear" />
                    <p className="error-color description-text-1 top-margin">Note: Surveys and transposed demographic data cannot be used to filter. If these data are included, they are the last columns.</p>

                    <div className="row-30 top-margin">
                      {(this.state.selectedReport) && (
                        <div className="relative-column-40 right-padding-5">

                          <p className="row-10 description-text-color description-text-2">Select a filter</p>
                          <select name="filterName" className="dropdown" value={this.state.filterName} onChange={this.formChangeHandler}>
                            {this.renderEligibleFieldsArray().map((value, index) => <option key={value.name} value={value.name}>{(value.name) ? index + ". " + value.name + " (" + value.type + ")" : ""}</option>)}
                          </select>
                        </div>
                      )}
                      {(this.state.filterName) && (
                        <span>
                          <div className="relative-column-20 left-padding-5 right-padding-5">
                            <p className="row-10 description-text-color description-text-2">Select an Operator</p>
                            <select name="operatorName" className="dropdown" value={this.state.operatorName} onChange={this.formChangeHandler}>
                              {this.state.operatorOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          </div>
                          <div className="relative-column-40 right-padding-5">
                            <p className="row-10 description-text-color description-text-2">Select a value</p>
                            {(this.state.selectedFilter && this.state.selectedFilter.type === 'String') && (
                              <input type="text" className="text-field" placeholder="Add the value..." name={"filterValue"} value={this.state.filterValue} onChange={this.formChangeHandler}></input>
                            )}
                            {(this.state.selectedFilter && this.state.selectedFilter.type === 'Boolean') && (
                              <select name="filterValue" className="dropdown" value={this.state.filterValue} onChange={this.formChangeHandler}>
                                {this.state.booleanOptions.map(value => <option key={value} value={value}>{value}</option>)}
                              </select>
                            )}
                            {(this.state.selectedFilter && this.state.selectedFilter.type === 'Number') && (
                              <input type="number" className="number-field full-width" placeholder="Add the value..." name={"filterValue"} value={this.state.filterValue} onChange={this.formChangeHandler}></input>
                            )}
                            {(this.state.selectedFilter && this.state.selectedFilter.type === 'Date') && (
                              <input type="date" className="date-picker" name="filterValue" value={this.state.filterValue} onChange={this.formChangeHandler}></input>
                            )}
                          </div>
                        </span>
                      )}
                      <div className="clear" />
                    </div>

                    <div className="row-10">
                      <button className="btn btn-primary right-margin" onClick={() => this.filterReport()}>{(this.state.editFilterIndex) ? "Edit" : "Add"} Filter</button>
                      {(this.state.editFilterIndex || this.state.editFilterIndex === 0) && (
                        <button className="btn btn-quaternary right-margin" onClick={() => this.deleteFilter()}>Delete Filter</button>
                      )}
                    </div>
                  </div>
                )}

                {(this.state.showMoveMenu) && (
                  <div key="showMoveMenu" className="full-width padding-20">
                    {(this.state.reportOptions && (this.state.selectedIndex || this.state.selectedIndex === 0)) && (
                      <p className="heading-text-2">Move {this.state.reportOptions[this.state.selectedIndex].fields[this.state.selectedIndex2].name}</p>
                    )}

                    <p className="error-color description-text-1 top-margin">Note: Surveys and transposed demographic data cannot be re-arranged. If included, these data are the last columns.</p>

                   <div className="spacer" /><div className="spacer" />

                   <div className="row-15">
                     <select name="moveInstrux" value={this.state.moveInstrux} onChange={this.formChangeHandler} className="dropdown">
                       {this.state.moveInstruxOptions.map(value =>
                         <option key={value.key} value={value}>{value}</option>
                       )}
                     </select>
                   </div>

                   {(this.state.moveInstrux === 'Before' || this.state.moveInstrux === 'After') && (
                     <div className="row-15">
                       {(this.state.selectedIndex2 || this.state.selectedIndex2 === 0) ? (
                         <select name="moveInstrux2" value={this.state.moveInstrux2} onChange={this.formChangeHandler} className="dropdown">
                           {this.renderEligibleFieldsArray().map(value =>
                             <option key={value.name} value={value.name}>{value.name}</option>
                           )}
                         </select>
                       ) : (
                         <select name="moveInstrux2" value={this.state.moveInstrux2} onChange={this.formChangeHandler} className="dropdown">
                           {this.renderEligibleFieldsArray().map(value =>
                             <option key={value.name} value={value.name}>{value.name}</option>
                           )}
                         </select>
                       )}
                     </div>
                   )}

                   <div className="spacer" /><div className="spacer" />

                   {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p> }
                   {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p> }

                   <button className="btn btn-primary" onClick={() => this.moveItems()}>Move</button>
                   <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Cancel</button>
                  </div>
                )}

                {(this.state.showSaveWidget) && (
                  <div key="showSaveWidget" className="full-width padding-20">
                    <p className="heading-text-2">Save Report</p>
                    <p className="heading-text-5 top-margin-5">{(this.state.selectedReportObject && this.state.selectedReportObject._id) ? "Edit your existing custom report" : "Add a new custom report"}</p>

                   <div className="spacer" /><div className="spacer" />

                   <div className="row-15">
                      <div className="container-left">
                        <label className="profile-label">What would you like to call this report?</label>
                        <input type="text" className="text-field" placeholder="(e.g., My Custom Report)..." name={"reportName"} value={this.state.reportName} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="container-right">
                      </div>
                      <div className="clear" />
                   </div>

                   <div className="row-15">
                     <label className="profile-label">Describe the report</label>
                     <textarea type="text" className="text-field" placeholder="describe the report..." name={"reportDescription"} value={this.state.reportDescription} onChange={this.formChangeHandler}></textarea>
                   </div>

                   <div className="spacer" /><div className="spacer" />

                   {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p> }
                   {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p> }

                   <button className="btn btn-primary" onClick={() => this.saveReport()}>Save</button>
                   <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Cancel</button>
                  </div>
                )}

                {(this.state.showOpenWidget) && (
                  <div key="showSaveWidget" className="full-width padding-20">
                    <p className="heading-text-4">Open Report</p>

                   <div className="spacer" /><div className="spacer" />

                   <div className="row-15">
                      <div className="container-left">
                        <label className="profile-label">Which report would you like to open?</label>
                        <select name="reportToOpen" className="dropdown" value={this.state.reportToOpen} onChange={this.formChangeHandler}>
                          {this.state.reportOptions.map((value, index) => <option key={value.name} value={value.name}>{index + 1}. {value.name}</option>)}
                        </select>
                      </div>
                      <div className="container-right">
                      </div>
                      <div className="clear" />
                   </div>

                   <div className="spacer" /><div className="spacer" />

                   {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p> }
                   {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p> }

                   <button className="btn btn-primary" onClick={() => this.openReport()}>Open</button>
                   <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Cancel</button>
                  </div>
                )}
              </div>
            </Modal>
          </div>

      )
    }
}

export default withRouter(Reporting);


// variable columns are a problem for moving to another column
// filters will not work in some cases (e.g., filtering those receiving tanf)
