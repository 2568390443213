import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import Modal from 'react-modal';
import SubReferSomeone from '../Common/ReferSomeone';

import {requestConnection} from '../Services/FriendRoutes';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const favoritesIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-grey.png';
const favoritesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-blue.png';

class RenderProfiles extends Component {
    constructor(props) {
      super(props)

      this.state = {
        prefix: '/app',
        favorites: [],
        newFavorites: [],
        showSubFunction: true
      }

      this.renderProfiles = this.renderProfiles.bind(this)
      this.favoriteItem = this.favoriteItem.bind(this)
      this.followPerson = this.followPerson.bind(this)
      this.referPerson = this.referPerson.bind(this)
      this.closeModal = this.closeModal.bind(this)
    }

    componentDidMount() {
      //see if user is logged in

      this.retrieveData()

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgName = localStorage.getItem('orgName');
      const roleName = localStorage.getItem('roleName');

      let prefix = '/app'
      if (roleName === 'Mentor') {
        prefix = '/advisor'
      }

      this.setState({ emailId, cuFirstName, cuLastName, username, activeOrg, orgName, prefix })

      if (emailId) {
        Axios.get('/api/users/profile/details', { params: { email: emailId } })
        .then((response) => {
          console.log('User details query 1 attempted', response.data);

          if (response.data.success) {
             console.log('successfully retrieved user details')

             const pictureURL = response.data.user.pictureURL
             const headline = response.data.user.headline

             const jobTitle = response.data.user.jobTitle
             const employerName = response.data.user.employerName
             const notificationPreferences = response.data.user.notificationPreferences

             let schoolName = response.data.user.school

             if (response.data.user.education && response.data.user.education.length > 0) {
               schoolName = response.data.user.education[0].name
               if (response.data.user.education.length > 1) {
                 for (let i = 1; i <= response.data.user.education.length; i++) {
                   if (response.data.user.education[i - 1] && response.data.user.education[i - 1].isContinual) {
                     schoolName = response.data.user.education[i - 1].name
                   }
                 }
               }
             }

             this.setState({ pictureURL, headline, jobTitle, employerName, schoolName,
               notificationPreferences
             });


          } else {
           console.log('no user details data found', response.data.message)
          }

        }).catch((error) => {
           console.log('User details query did not work', error);
        });
      }
    }

    componentDidUpdate(prevProps) {
      // console.log('componentDidUpdate called in renderPosts', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg) {
        this.retrieveData()
      } else if (this.props.favorites !== prevProps.favorites || this.props.members !== prevProps.members || this.props.friends !== prevProps.friends) {
        this.retrieveData()
      } else if (this.props.filterCriteriaArray !== prevProps.filterCriteriaArray) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in renderPosts')

      const favorites = this.props.favorites
      const newFavorites = this.props.newFavorites
      const members = this.props.members
      const friends = this.props.friends
      const headerImageURL = this.props.headerImageURL

      this.setState({ favorites, newFavorites, members, friends, headerImageURL })

    }

    async favoriteItem(e, item, type) {
      console.log('favoriteItem called', e, item, type)

      e.stopPropagation()
      e.preventDefault()

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      if (this.state.showSubFunction) {

        this.setState({ isSaving: true, errorMessage: null, successMessage: null })

        const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,'person')

        if (returnedObject.success) {
          this.setState({ successMessage: returnedObject.message, favorites: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
        } else {
          this.setState({ errorMessage: returnedObject.message, isSaving: false })
        }

      } else {
        let itemId = item._id

        let favoritesArray = this.state.favorites
        if (favoritesArray.includes(itemId)) {
          const index = favoritesArray.indexOf(itemId)
          favoritesArray.splice(index, 1)
        } else {
          favoritesArray.push(itemId)
        }

        Axios.post('/api/favorites/save', {
          favoritesArray, emailId: this.state.emailId
        })
        .then((response) => {
          console.log('attempting to save addition to favorites')
          if (response.data.success) {
            console.log('saved addition to favorites', response.data)

            this.setState({ successMessage: 'Saved as a favorite!', favorites: favoritesArray, isSaving: false })

          } else {
            console.log('did not save successfully')
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false})
        });
      }

    }

    followPerson(e, person) {
      console.log('followPerson called', e, person)

      e.stopPropagation()
      e.preventDefault()

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const senderPictureURL = this.state.pictureURL
      const senderEmail = this.state.emailId
      const senderFirstName = this.state.cuFirstName
      const senderLastName = this.state.cuLastName
      const senderUsername = this.state.username
      const senderHeadline = this.state.headline
      const recipientPictureURL = person.pictureURL
      const recipientEmail = person.email
      const recipientFirstName = person.firstName
      const recipientLastName = person.lastName
      const recipientUsername = person.username
      const recipientHeadline = person.headline
      const relationship = 'Peer'
      const orgCode = this.state.activeOrg
      const orgName = this.state.orgName

      const senderJobTitle = this.state.jobTitle
      const senderEmployerName = this.state.employerName
      const senderSchoolName = this.state.schoolName
      const accompanyingMessage = this.state.accompanyingMessage
      // let unsubscribed = null
      // if (this.state.notificationPreferences && this.state.notificationPreferences.length > 0) {
      //   for (let i = 1; i <= this.state.notificationPreferences.length; i++) {
      //     if (this.state.notificationPreferences[i - 1].slug === 'friend-requests' && this.state.notificationPreferences[i - 1].enabled === false) {
      //       unsubscribed = true
      //     }
      //   }
      // }

      const headerImageURL = this.state.headerImageURL

      let friend = {
        senderPictureURL, senderEmail, senderFirstName, senderLastName, senderUsername, senderHeadline,
        senderJobTitle, senderEmployerName, senderSchoolName,
        recipientPictureURL, recipientEmail, recipientFirstName, recipientLastName, recipientUsername, recipientHeadline,
        relationship, orgCode, orgName, accompanyingMessage, headerImageURL
      }

      const self = this
      async function asyncFollowPerson(unsubscribed) {
        console.log('asyncFollowPerson called')

        friend['unsubscribed'] = unsubscribed

        const returnedValue = await requestConnection(friend)
        console.log('show returnedValue: ', returnedValue)

        if (returnedValue.success) {
          friend['active'] = false
          friend['friend2Email'] = recipientEmail

          let friends = self.state.friends
          if (self.state.friends) {
            friends.push(friend)
          } else {
            friends = [friend]
          }

          console.log('show friends: ', friends)
          self.setState({ successMessage: returnedValue.message, friends })
        } else {
          self.setState({ errorMessage: returnedValue.message })
        }
      }

      // pull notification preferences of the owner
      Axios.get('/api/users/profile/details', { params: { email: recipientEmail } })
      .then((response) => {
        console.log('Profile query for notipreferences attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved profile information')
           const notificationPreferences = response.data.user.notificationPreferences

           let unsubscribed = null
           if (notificationPreferences && notificationPreferences.length > 0) {
             for (let i = 1; i <= notificationPreferences.length; i++) {
               if (notificationPreferences[i - 1].slug === 'incoming-comments' && notificationPreferences[i - 1].enabled === false) {
                 unsubscribed = true
               }
             }
           }

           asyncFollowPerson(unsubscribed)

         } else {
           console.log('no profile data found', response.data.message)

           asyncFollowPerson()
         }

      }).catch((error) => {
         console.log('Profile query did not work', error);
      });
    }

    referPerson(e,person) {
      console.log('referPerson called')

      e.stopPropagation()
      e.preventDefault()

      this.setState({ modalIsOpen: true, showReferSomeone: true })
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showReferSomeone: false })
    }

    renderProfiles() {
      // console.log('renderProfiles called')

      let rows = []

      let cardClass = 'relative-column-33 horizontal-padding-7'
      if (window.innerWidth < 768) {
        cardClass = ''
      }

      if (this.state.members) {
        for (let i = 1; i <= this.state.members.length; i++) {

          const index = i - 1

          let matchScore = this.state.members[i - 1].matchScore

          let profileLink = this.state.prefix + '/profile/' + this.state.members[i - 1].username
          if (this.props.userType === 'Mentors') {
              profileLink = '/advisor/profile/' + this.state.members[i - 1].username
          } else if (window.location.pathname.includes('/organizations')) {
            profileLink = '/organizations/' + this.state.activeOrg + '/members/' + this.state.members[i - 1].username
          }
          // console.log('props me: ', this.props)
          if (this.props.pageSource === 'landingPage') {
            // console.log('t1')
            profileLink = "/" + this.state.members[i - 1].username + '/profile'
          }

          rows.push(
            <div key={i}>
              <div className={cardClass}>
                <Link to={profileLink} state={{ matchScore }} className="background-button full-width standard-color">
                  <div className="card full-width center-horizontally">
                    <div className="flex-container row-direction">
                      <div className="flex-15">
                        {(matchScore) && (
                          <div className="image-full-auto" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Match Score">
                            <CircularProgressbar
                              percentage={matchScore}
                              text={`${matchScore}%`}
                              styles={{
                                path: { stroke: `rgba(110, 190, 250, ${matchScore / 100})` },
                                text: { fill: '#6EBEFA', fontSize: '26px' },
                                trail: { stroke: 'transparent' }
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex-70">
                        {(this.state.members[i - 1].pictureURL) ? (
                          <img src={this.state.members[i - 1].pictureURL} alt="GC" className="profile-image-medium" />
                        ) : (
                          <img src={profileIconDark} alt="GC" className="image-auto-60 center-item" />
                        )}
                      </div>

                      {(this.props.pageSource === 'landingPage') ? (
                        <div className="flex-15 height-40" />
                      ) : (
                        <div className="flex-15">
                          {(this.props.pageSource === 'Profiles') ? (
                            <div>
                              {(this.state.members[i - 1].publicProfile) ? (
                                <div>
                                  {(this.state.members[i - 1].publicProfileExtent) ? (
                                    <div>
                                      {(this.state.members[i - 1].publicProfileExtent === 'Public') ? (
                                        <p className="cta-color description-text-4 full-width wrap-word">{this.state.members[i - 1].publicProfileExtent}</p>
                                      ) : (
                                        <p className="middle-color description-text-4 full-width wrap-word">{this.state.members[i - 1].publicProfileExtent}</p>
                                      )}
                                    </div>
                                  ) : (
                                    <p className="cta-color description-text-4">Public</p>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <p className="error-color description-text-4">Private</p>
                                </div>
                              )}
                            </div>
                          ) : (
                            <button disabled={this.state.isSaving} className="btn background-button" onClick={(e) => this.favoriteItem(e,this.state.members[index]) }>
                              <img src={(this.state.favorites && this.state.favorites.includes(this.state.members[i - 1]._id)) ? favoritesIconBlue : favoritesIconGrey} alt="GC" className="image-auto-20 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Favorite"/>
                            </button>
                          )}
                        </div>
                      )}
                    </div>

                    <p className="full-width center-text heading-text-5 top-padding">{this.state.members[i - 1].firstName} {this.state.members[i - 1].lastName}</p>
                    {(this.state.members[i - 1].school) && (
                      <p className="full-width center-text description-text-2 top-padding">{this.state.members[i - 1].school}{this.state.members[i - 1].gradYear && " '" + this.state.members[i - 1].gradYear}</p>
                    )}
                    {(this.state.members[i - 1].major) && (
                      <p className="full-width center-text description-text-2 top-padding-5">{this.state.members[i - 1].major}</p>
                    )}

                    {(!this.props.hideCTA) && (
                      <div className="top-padding-20">
                        {(this.props.pageSource === 'Profiles') ? (
                          <div>
                            {(this.state.friends && this.state.friends.some(friend => (friend.friend1Email === this.state.members[i - 1].email) || friend.friend2Email === this.state.members[i - 1].email)) ? (
                              <div>
                                {(this.state.friends.some(friend => (friend.friend1Email === this.state.members[i - 1].email || friend.friend2Email === this.state.members[i - 1].email) && friend.active)) ? (
                                  <Link to={this.state.prefix + '/messaging'} state={{ recipient: this.state.members[i - 1]}} className="background-button full-width">
                                    <button className="btn btn-profile white-background cta-border cta-color full-width">
                                      Message
                                    </button>
                                  </Link>
                                ) : (
                                  <button className="btn btn-profile full-width medium-background clear-border no-pointers" disabled={true}>Pending</button>
                                )}
                              </div>
                            ) : (
                              <button className="btn btn-profile full-width" disabled={(this.state.isSaving) ? true : false} onClick={(e) => this.followPerson(e,this.state.members[i - 1])}>Connect</button>
                            )}
                          </div>
                        ) : (
                          <div>
                            {(this.props.pageSource === 'landingPage') ? (
                              <button className="btn btn-profile full-width" onClick={(e) => this.referPerson(e,this.state.members[i - 1])}>
                                Refer
                              </button>
                            ) : (
                              <Link to={this.state.prefix + '/messaging'} state={{ recipient: this.state.members[i - 1]}} className="background-button full-width">
                                <button className="btn btn-profile full-width">
                                  Message
                                </button>
                              </Link>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {(this.props.filterCriteriaArray && this.props.filterCriteriaArray.length > 0 && this.props.filterCriteriaArray.length === this.state.members.length) && (
                      <div className="top-padding">
                        <p className="error-color description-text-2">{this.props.filterCriteriaArray[i - 1].name}: {this.props.filterCriteriaArray[i - 1].criteria}</p>
                      </div>
                    )}

                    <div className="spacer" />
                  </div>
                </Link>
              </div>

              {(i % 3 === 0 && window.innerWidth >= 768) && (
                <div>
                  <div className="clear" />
                  <div className="spacer" />
                </div>
              )}
              {(window.innerWidth < 768) && (
                <div>
                  <div className="clear" />
                  <div className="spacer" />
                </div>
              )}

              <ReactTooltip id="tooltip-placeholder-id" />

            </div>
          )
        }
      }

      return rows

    }

    render() {

      return (
        <div>
          {this.renderProfiles()}
          <div className="clear" />

          <Modal
           isOpen={this.state.modalIsOpen}
           onAfterOpen={this.afterOpenModal}
           onRequestClose={this.closeModal}
           className="modal"
           overlayClassName="modal-overlay"
           contentLabel="Example Modal"
          >
            {(this.state.showReferSomeone) && (
              <div key="showReferSomeone" className="full-width padding-20">
                <SubReferSomeone history={this.props.navigate} selectedOpportunity={this.state.selectedOpportunity}
                  referralBonus={this.state.referralBonus} pageSource={this.props.pageSource} linkedInURL={this.state.linkedInURL}
                  benchmark={this.state.benchmark} relationshipOptions={this.state.relationshipOptions}
                  jobTitle={this.state.jobTitle} employerName={this.state.employerName}
                  orgCode={this.state.activeOrg} orgName={this.state.postingOrgName}
                  orgProgramName={this.state.orgName} orgContactEmail={this.state.postingOrgContactEmail}
                  closeModal={this.closeModal}
                />
              </div>
            )}

          </Modal>
        </div>
      )
    }
}

export default RenderProfiles;
