import React, {Component } from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEditProfileDetails from './Subcomponents/EditProfileDetails';
import withRouter from '../components/Functions/WithRouter';

class EditProfileDetails extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const workModeString  = localStorage.getItem('workMode');
      const orgLogo = localStorage.getItem('orgLogo');

      let workMode = false
      if (workModeString === 'true') {
        workMode = true
      }

      if (email) {

        let objectId = undefined
        if (this.props.location && this.props.location.state) {
          objectId = this.props.location.state.objectId
        }

        let profilePath = window.location.protocol + "//" + window.location.host + "/app/profile/" + username

        this.setState({ emailId: email, username, profilePath, activeOrg, orgFocus,
          workMode, orgLogo, objectId
        });
      }
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in parentEditProfileDetails ', this.props, prevProps)

      // if (this.props.location && this.props.location.pathname !== prevProps.location.pathname) {
      //   // this.setState({ })
      // }
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    render() {

      return (
          <div>
            <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} workMode={this.state.workMode} loadWorkspace={this.loadWorkspace} history={this.props.navigate}/>

            <div className="standard-container-2">
              <SubEditProfileDetails activeOrg={this.state.activeOrg} history={this.props.navigate} location={this.props.location} />
            </div>

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>
      )
    }
}

export default withRouter(EditProfileDetails);
