import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubProjectDetails from './Subcomponents/ProjectDetails';
import withRouter from './Functions/WithRouter';

class AdvProjectDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      if (this.props.params) {
        let { projectId } = this.props.params

        Axios.get('/api/projects/byid', { params: { _id: projectId } })
        .then((response) => {
            console.log('Project query worked', response.data);

            if (response.data.success) {

              const selectedProject = response.data.project
              this.setState({ selectedProject, activeOrg, emailId: email, username,
                orgFocus, roleName, orgLogo })

            } else {
              console.log('no endorsements found: ', response.data.message)
            }

        }).catch((error) => {
            console.log('Story query did not work', error);
        });
      }

      if (window.innerWidth > 768) {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)

      } else {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)

      }
    }

    render() {

        return (
          <div>
            <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />
            {(this.state.selectedProject) && (
              <SubProjectDetails closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedProject={this.state.selectedProject} orgCode={this.state.activeOrg} excludeModal={true} />
            )}

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>
        )
    }
}

export default withRouter(AdvProjectDetails);
