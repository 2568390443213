import React, {Component} from 'react';
import Axios from 'axios';
import SubGoalDetails from '../Common/GoalDetails';
import SubCustomDropdown from '../Common/CustomDropdown';
import SubRenderGoals from '../Common/RenderGoals';
import withRouter from '../Functions/WithRouter';

const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const hideIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/hide-icon.png";

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class Goals extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showModule: true,
          resLimit: 500,
          favorites: [],
          newFavorites: []
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.renderManipulators = this.renderManipulators.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in goals', this.props)

      if (this.props.orgCode !== prevProps.orgCode || this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('t0 will update')
        this.retrieveData()
      } else if (this.props.passedId !== prevProps.passedId) {
        this.retrieveData()
      } else if (this.props.noOrgCode !== prevProps.noOrgCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in goals', this.props)

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      }

      const orgFocus = localStorage.getItem('orgFocus');
      // console.log('show me activeOrg: ', activeOrg)
      this.setState({ emailId, activeOrg, username, cuFirstName, cuLastName, orgFocus, loadingItems: true })

      if (activeOrg || this.props.noOrgCode) {

        let queryParams = { resLimit: this.state.resLimit }
        if (activeOrg) {
          queryParams['orgCode'] = activeOrg
        } else {
          // queryParams = { orgCode: 'guidedcompass' }
          // queryParams = { isPublic: true }
        }

        if (window.location.pathname.startsWith('/goals')) {
          queryParams['publicExtentArray'] = ['Public']
        } else if (window.location.pathname.startsWith('/app/browse-goals')) {
          queryParams['publicExtentArray'] = ['Members Only','Public']
        }
        Axios.get('/api/logs/goals', { params: queryParams })
       .then((response) => {
         console.log('Goals query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved goals')

           const goals = response.data.goals
           const filteredGoals = response.data.goals
           this.setState({ goals, filteredGoals, loadingItems: false })

         } else {
           console.log('no goals data found', response.data.message)
           this.setState({ loadingItems: false })
         }

       }).catch((error) => {
           console.log('Goals query did not work', error);
           this.setState({ loadingItems: false })
       });

       Axios.get('/api/favorites', { params: { emailId } })
      .then((response) => {
        console.log('Favorites query attempted', response.data);

        if (response.data.success) {
          console.log('successfully retrieved favorites')

          const favorites = response.data.favorites
          let newFavorites = []
          if (response.data.newFavorites) {
            newFavorites = response.data.newFavorites
          }

          this.setState({ favorites, newFavorites })

        } else {
          console.log('no favorites data found', response.data.message)
        }

      }).catch((error) => {
          console.log('Favorites query did not work', error);
      });

       if (this.props.passedId) {
         Axios.get('/api/logs/byid', { params: { _id: this.props.passedId } })
        .then((response) => {
          console.log('Goal by id query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved goal')

            this.setState({ modalIsOpen: true, showHelpOutWidget: true, selectedGoal: response.data.log })

          } else {
            console.log('no goals data found', response.data.message)
            this.setState({ loadingItems: false })
          }

        }).catch((error) => {
            console.log('Goal by id query did not work', error);
            this.setState({ loadingItems: false })
        });
       }

       Axios.get('/api/workoptions')
       .then((response) => {
         console.log('Work options query tried', response.data);

         if (response.data.success) {
           console.log('Work options query succeeded')

           // filter: by name, by deadline year, by type, optimized for
           // sort: by deadline

           let functionOptions = response.data.workOptions[0].functionOptions
           let industryOptions = response.data.workOptions[0].industryOptions
            let goalTypeOptions = []
            if (response.data.workOptions[0].goalTypeOptions) {
              for (let i = 1; i <= response.data.workOptions[0].goalTypeOptions.length; i++) {
                if ((orgFocus === 'academy' || orgFocus === 'School') && !response.data.workOptions[0].goalTypeOptions[i - 1].adult) {
                  goalTypeOptions.push(response.data.workOptions[0].goalTypeOptions[i - 1].description)
                } else if ((orgFocus !== 'academy' && orgFocus !== 'School')) {
                  goalTypeOptions.push(response.data.workOptions[0].goalTypeOptions[i - 1].description)
                }
              }
            }

           //filters
           const defaultFilterOption = this.state.defaultFilterOption

           // const gradeFilterOptions = [defaultFilterOption].concat(gradeOptions.slice(1, gradeOptions.length))
           const optimizeFilterOptions = [defaultFilterOption].concat(response.data.workOptions[0].optimizeOptions)
           const functionFilterOptions = [defaultFilterOption].concat(functionOptions.slice(1, functionOptions.length))
           const industryFilterOptions = [defaultFilterOption].concat(industryOptions.slice(1, industryOptions.length))
           const goalTypeFilterOptions = [defaultFilterOption].concat(goalTypeOptions)

           const itemFilters = [
             { name: 'Type', value: defaultFilterOption, options: goalTypeFilterOptions },
             { name: 'Optimize For', value: defaultFilterOption, options: optimizeFilterOptions },
             { name: 'Work Function', value: defaultFilterOption, options: functionFilterOptions },
             { name: 'Industry', value: defaultFilterOption, options: industryFilterOptions },
           ]

           //sort options
           const defaultSortOption = this.state.defaultSortOption

           const itemSorters = [
             { name: 'By Deadline', value: defaultSortOption, options: [defaultSortOption].concat(['Soonest','Latest'])},
           ]

           this.setState({ itemFilters, itemSorters });

         } else {
           console.log('no jobFamilies data found', response.data.message)
         }
       }).catch((error) => {
           console.log('query for work options did not work', error);
       })
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'search') {

        const searchString = event.target.value

        //reset everything
        let itemFilters = this.state.itemFilters
        for (let i = 1; i <= itemFilters.length; i++) {
          itemFilters[i - 1]['value'] = this.state.defaultFilterOption
        }
        let itemSorters = this.state.itemSorters
        for (let i = 1; i <= itemSorters.length; i++) {
          itemSorters[i - 1]['value'] = this.state.defaultSortOption
        }

        this.setState({ searchString, itemFilters, itemSorters, loadingItems: true })
        this.filterResults(event.target.value, '', null, null, true)
      } else if (event.target.name.includes('filter|')) {

        let filters = this.state.itemFilters

        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        let index = 0
        for (let i = 1; i <= filters.length; i++) {
          if (filters[i - 1].name === field) {
            filters[i - 1]['value'] = event.target.value
            index = i - 1
          }
        }

        let itemFilters = filters

        //reset everything
        let searchString = ''
        for (let i = 1; i <= itemFilters.length; i++) {
          if (itemFilters[i - 1].name !== field) {
            itemFilters[i - 1]['value'] = this.state.defaultFilterOption
          }
        }
        let itemSorters = this.state.itemSorters
        for (let i = 1; i <= itemSorters.length; i++) {
          itemSorters[i - 1]['value'] = this.state.defaultSortOption
        }

        this.setState({ filters, itemFilters, loadingItems: true, searchString, itemSorters })

        this.filterResults(this.state.searchString, event.target.value, filters, index, false)
      } else if (event.target.name.includes('sort|')) {

        let sorters = this.state.itemSorters

        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        // let index = 0
        for (let i = 1; i <= sorters.length; i++) {
          if (sorters[i - 1].name === field) {
            sorters[i - 1]['value'] = event.target.value
            // index = i - 1
          }
        }

        let itemSorters = sorters

        //reset everything
        let searchString = ''
        let itemFilters = this.state.itemFilters
        for (let i = 1; i <= itemFilters.length; i++) {
          itemFilters[i - 1]['value'] = this.state.defaultFilterOption
        }

        for (let i = 1; i <= itemSorters.length; i++) {
          if (itemSorters[i - 1].name !== field) {
            itemSorters[i - 1]['value'] = this.state.defaultSortOption
          }
        }

        this.setState({ searchString, itemFilters, itemSorters, loadingItems: true })

        this.sortResults(event.target.value, field)

      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    filterResults(searchString, filterString, filters, index, search) {
      console.log('filterResults called', searchString, filterString, filters, index, search)

      // const emailId = this.state.emailId
      // const orgCode = this.state.org
      const defaultFilterOption = this.state.defaultFilterOption
      const goals = this.state.goals
      const type = this.props.type
      const emailId = this.state.emailId
      const orgCode = this.state.activeOrg

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.put('/api/goals/filter', {  searchString, filterString, filters, defaultFilterOption, index, search, goals, type, emailId, orgCode })
        .then((response) => {
          console.log('Goal filter query attempted', response.data);

            if (response.data.success) {
              console.log('goal filter query worked')

              let filteredGoals = response.data.goals
              const filterCriteriaArray = response.data.filterCriteriaArray
              const sortCriteriaArray = null

              self.setState({ filteredGoals, loadingItems: false, filterCriteriaArray, sortCriteriaArray })

            } else {
              console.log('project filter query did not work', response.data.message)
              self.setState({ loadingItems: false })
            }

        }).catch((error) => {
            console.log('Project filter query did not work for some reason', error);
            self.setState({ loadingItems: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    sortResults(sortString, sortName) {
      console.log('sortResults called', sortString, sortName)

      let goals = this.state.goals
      // let filteredProjects = []

      Axios.put('/api/goals/sort', { sortString, goals, sortName })
      .then((response) => {
        console.log('Goal sort query attempted', response.data);

          if (response.data.success) {
            console.log('project sort query worked')

            let filteredGoals = response.data.goals
            const filterCriteriaArray = null
            const sortCriteriaArray = response.data.sortCriteriaArray

            this.setState({ filteredGoals, loadingItems: false, filterCriteriaArray, sortCriteriaArray })

          } else {
            console.log('goal sort query did not work', response.data.message)
            this.setState({ loadingItems: false })
          }

      }).catch((error) => {
          console.log('Goal sort query did not work for some reason', error);
          this.setState({ loadingItems: false })
      });
    }

    closeModal() {

      this.setState({ modalIsOpen: false, showGoalDetails: false, showHelpOutWidget: false,
        selectedGoal: null
      });

    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    renderManipulators(type) {
      console.log('renderManipulators called')

      if (type === 'filter') {
        let filters = this.state.itemFilters
        console.log('show filters: ', filters)

        if (filters) {

          let rows = []
          for (let i = 1; i <= filters.length; i++) {
            rows.push(
              <div key={filters[i - 1] + i.toString()}>
                <div>
                  <SubCustomDropdown dropdownTitle={filters[i - 1].name} name={"filter|" + filters[i - 1].name} value={filters[i - 1].value} options={filters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                </div>
              </div>
            )
          }

          return rows

        }
      } else if (type === 'sort') {
        let sorters = this.state.itemSorters
        console.log('show sorters: ', sorters)

        if (sorters) {

          let rows = []
          for (let i = 1; i <= sorters.length; i++) {
            rows.push(
              <div key={sorters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={sorters[i - 1].name} name={"sort|" + sorters[i - 1].name} value={sorters[i - 1].value} options={sorters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      }
    }

    render() {

      let passedClassName = "card bottom-margin-30"
      let passedClassName2 = ''
      let passedStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginBottom: '0px', padding: '15px 20px'}
      // const addIconWidth = '45px'
      const passedStyle2 = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginTop: '2%', padding: '20px' }
      // const passedPlaceholder = 'Search projects by name or creator...'
      // const pathname = '/advisor/projects/add'
      let filterClass = "card"

      if (window.location.pathname.startsWith('/goals')) {
        passedClassName = "horizontal-padding bottom-margin-30"
        passedClassName2 = ""
        filterClass = "card standard-border horizontal-margin-30"
      }
      // if (this.props.pageSource === 'BrowseGoals') {
      //   passedClassName = "standard-container-2"
      //   passedStyle = {}
      //   filterClass = "standard-container-2"
      //   passedClassName2 = "standard-container-2 clear-background clear-shadow clear-padding"
      // }

      return (
          <div>
            <div className={passedClassName}>
              <div className="calc-column-offset-25 right-padding">
                <p className="heading-text-2">{(this.state.filteredGoals) ? (this.state.filteredGoals.length === this.state.resLimit) ? this.state.filteredGoals.length + "+ " : this.state.filteredGoals.length + " " : ""}{(!this.state.activeOrg) && " Public "}Goals</p>
              </div>
              <div className="fixed-column-25">
                <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                <div className="row-5">
                  <button onClick={() => this.toggleSearchBar('show')} className="background-button">
                    <img src={(this.state.showingSearchBar) ? hideIcon : searchIcon} alt="GC" className="image-25-fit"/>
                  </button>
                </div>
              </div>
              <div className="clear" />
            </div>

            {(this.state.showingSearchBar) && (
              <div className={filterClass} style={passedStyle2}>
                <div>
                  <div className="filter-field-search full-width">
                      <div className="half-spacer"/>
                      <div className="search-icon-container">
                        <img src={searchIcon} alt="Compass search icon" className="image-auto-20"/>
                      </div>
                      <div className="filter-search-container calc-column-offset-23">
                        <input type="text" className="text-field clear-border" placeholder={"Search goals..."} name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                      </div>
                  </div>
                  <div className="clear" />
                  <div className="spacer" /><div className="spacer" />
                  <hr />
                  <div className="spacer" /><div className="spacer" />
                  <p>Filter</p>
                  <div className="half-spacer" />
                  {(this.renderManipulators('filter'))}

                  <div className="clear" />
                  <div className="spacer" />
                  <hr />
                  <div className="spacer" /><div className="spacer" />
                  <p>Sort</p>
                  <div className="half-spacer" />
                  {(this.renderManipulators('sort'))}
                  <div className="clear" />
                </div>
              </div>
            )}

            {(this.state.loadingItems) ? (
              <div className="flex-container flex-center full-space">
                <div>
                  <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <p className="center-text cta-color bold-text">Loading results...</p>

                </div>
              </div>
            ) : (
              <div className={passedClassName2} style={passedStyle}>
                <SubRenderGoals history={this.props.navigate} filteredGoals={this.state.filteredGoals}
                  profileData={this.state.profileData} activeOrg={this.state.activeOrg}
                  favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                  sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                />
              </div>
            )}

            {(this.state.showGoalDetails || this.state.showHelpOutWidget) && (
              <div>
                <SubGoalDetails history={this.props.navigate} selectedGoal={this.state.selectedGoal} modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} showGoalDetails={this.state.showGoalDetails} showHelpOutWidget={this.state.showHelpOutWidget} profileData={this.state.profileData}
                  orgCode={this.state.activeOrg}
                />
             </div>
            )}

          </div>

      )
    }
}

export default withRouter(Goals);
