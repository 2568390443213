import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubSettings from '../Subcomponents/Settings';
import withRouter from '../Functions/WithRouter';

class OrgSettings extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        const pathwayOptions = ['Elizabeth Learning Center','Banning High School','Foshay High School',
          'Downtown Magnets High School', 'Grover Cleveland High School', 'Hollywood High School']

        const availableAssessments = [
          { name: 'Work Preferences', description: '' }, { name: 'Work Interests', description: '' },
          { name: 'General Interests', description: '' }, { name: 'Personality', description: '' },
          { name: 'Values', description: '' }, { name: 'Skills', description: '' },
          { name: 'Adversity', description: '' }, { name: 'Political', description: '' },
        ]

        this.setState({ cuFirstName, cuLastName, username, emailId: email,
          org, orgFocus, orgLogo, pathwayOptions, availableAssessments
        })

      }
    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

          <div className="standard-container-2">
            <SubSettings email={this.state.emailId} activeOrg={this.state.org} orgFocus={this.state.orgFocus} roleName={this.state.roleName} passActiveOrg={this.passActiveOrg} history={this.props.navigate} />
          </div>

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}


export default withRouter(OrgSettings)
