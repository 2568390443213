import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import withRouter from '../Functions/WithRouter';
import {convertDateToString} from '../Functions/ConvertDateToString';

const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';

class SubmissionDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.saveChanges = this.saveChanges.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in submissionDetails ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.id !== prevProps.id) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in submissionDetails', this.props)
      // greeting, communication, posture and eye contact, appearance, behavioral questions, interest / motivation, competencies

      // const org = this.props.activeOrg
      const accountCode = this.props.accountCode
      const selectedTemplate = this.props.selectedTemplate

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let org = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');

      let isLoading = true

      let subNavCategories = this.state.subNavCategories
      let subNavSelected = 'Ideal Profile'
      if (window.location.pathname.includes('/pages/')) {
        subNavCategories = this.state.benchmarkCategories
        subNavSelected = 'All'
      }

      let prefix = "/organizations/" + org
      if (window.location.pathname.startsWith('/advisor')) {
        prefix = "/advisor"
      }

      this.setState({ isLoading, orgCode: org, selectedTemplate, emailId: email, email,
        username, cuFirstName, cuLastName, subNavCategories, subNavSelected, prefix
      })

      if (this.props.id) {
        Axios.get('/api/submissions/byid', { params: { _id: this.props.id } })
        .then((response) => {
          console.log('Submission query attempted', response.data);

          if (response.data.success) {
            console.log('submission query worked')

            const selectedSubmission = response.data.submission
            this.setState({ selectedSubmission, isLoading: false })

          } else {
            console.log('submission query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Submission query did not work for some reason', error);
        });
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler clicked', event.target.name, event.target.value)

      let selectedSubmission = this.state.selectedSubmission
      selectedSubmission[event.target.name] = event.target.value
      this.setState({ selectedSubmission })
      // this.setState({ [event.target.name]: event.target.value })
    }

    saveChanges() {
      console.log('saveChanges called')

      const _id = this.state.selectedSubmission._id
      const grade = this.state.selectedSubmission.grade
      const gradeExplanation = this.state.selectedSubmission.gradeExplanation
      const updatedAt = new Date()

      const submission = { _id, grade, gradeExplanation, updatedAt }

      Axios.post('/api/submissions', submission)
      .then((response) => {
        console.log('attempting to save submission')
        if (response.data.success) {
          console.log('saved submission', response.data)

          this.setState({ isSaving: false, successMessage: 'Submitted successfully!' })

        } else {
          console.log('did not save successfully')
          this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
        }
      }).catch((error) => {
          console.log('save did not work', error);
          this.setState({ errorMessage: 'there was an error saving submission', isSaving: false })
      });
    }

    render() {
      const value = this.state.selectedSubmission

      return (
          <div className="standard-container-2">
            {(value) && (
              <div className="row-10">
                <div>
                  <div className="row-10">
                    <p className="heading-text-2">{(value.title) ? value.title : (value.courseName) ? value.courseName : (value.lessonName) ? value.lessonName : (value.assessmentName) ? value.assessmentName : ""}</p>
                    <p className="description-text-2 top-margin">Submitted {convertDateToString(new Date(value.createdAt),"datetime-2")}</p>

                    <div className="top-margin-20">
                      <Link to={this.state.prefix + '/members/' + value.username} className="background-button row-10">
                        <div className="fixed-column-40">
                          <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                        </div>
                        <div className="calc-column-offset-40">
                          <p className="heading-text-6">{value.firstName} {value.lastName}</p>
                        </div>
                        <div className="clear" />
                      </Link>
                    </div>
                  </div>

                  {(value.answers && value.answers.length > 0) && (
                    <div className="row-20">
                      {value.answers.map((value2, index2) =>
                        <div key={value2.question} className="top-margin">
                          <div className="row-5">
                            <p className="description-text-3 description-text-color bold-text">{index2 + 1}. {value2.question}</p>
                          </div>
                          <div className="row-5">
                            <p className="description-text-1">{value2.answer}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {(value.details) && (
                    <div className="row-20">
                      <p>{value.details}</p>
                    </div>
                  )}
                </div>

                <hr />

                <div className="row-20">
                  <div className="container-left">
                    <label className="profile-label">Your Grade (0% - 100%)</label>
                    <div className="calc-column-offset-30">
                      <input type="number" className="number-field" min="0" max="100" placeholder="(e.g., 0)" name={"grade"} value={value.grade} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="fixed-column-30 light-background standard-border">
                      <div className="flex-container flex-center height-38">
                        <p className="heading-text-3 center-text">%</p>
                      </div>
                    </div>
                    <div className="clear" />
                  </div>
                  <div className="clear" />

                  {(value.grade) && (
                    <div className="row-10">
                      <label className="profile-label">Your Grade Explanation (Optional)</label>
                      <textarea type="text" className="text-field" placeholder="(e.g., reason for your grade" name={"gradeExplanation"} value={value.gradeExplanation} onChange={this.formChangeHandler}></textarea>
                    </div>
                  )}
                </div>

                <div className="spacer" /><div className="spacer" />

                {(this.state.errorMessage) && <p className="description-text-2 row-5 error-color">{this.state.errorMessage}</p>}
                {(this.state.successMessage) && <p className="description-text-2 row-5 cta-color">{this.state.successMessage}</p>}

                {(this.state.confirmDelete) && (
                  <div className="row-10">
                    <p className='error-color'>Are you sure you want to delete?</p>
                    <div className="row-5">
                      <button className="btn btn-squarish error-background-color error-border-color right-margin" onClick={() => console.log('')}>Delete Permanently</button>
                      <button className="btn btn-squarish white-background cta-color" onClick={() => this.setState({ confirmDelete: false })}>Cancel</button>
                    </div>
                  </div>
                )}

                <button className="btn btn-primary right-margin" onClick={() => this.saveChanges()}>Save Grade</button>
                <button className="btn btn-quaternary" onClick={() => this.setState({ confirmDelete: true })}>Delete Submission</button>

              </div>
            )}
          </div>

      )
    }
}

export default withRouter(SubmissionDetails);
