import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import SubSendEndorsement from '../components/Subcomponents/SendEndorsement';
import withRouter from '../components/Functions/WithRouter';

class EndorsePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#fff";
    //user should not need to be logged in

    let { requestId, orgCode } = this.props.params

    let recipient = null
    if (this.props.location && this.props.location.state) {
      recipient = this.props.location.state.recipient
    }
    console.log('show the values: ')
    this.setState({ requestId, orgCode, recipient })

  }

  render() {
      return (
          <div>
            <header className="story-guide-header">
                <TopNavigation currentPage={"storyGuidePage"}/>
                <div className="hero-text-box">
                <h1 className="light-text">Endorse Someone</h1>
                <p>Let someone know how talented they are for work opportunities.</p>
                <a className="btn btn-full" href="#works">Endorse</a>
                </div>
            </header>

            <div className="row-20">
              <SubSendEndorsement history={this.props.navigate} requestId={this.state.requestId}
                orgCode={this.state.orgCode} recipient={this.state.recipient}
              />
            </div>

            <Footer history={this.props.navigate} />
          </div>
      )
  }
}

export default withRouter(EndorsePage)
