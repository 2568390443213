import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import SubRenderSuggestion from '../Common/RenderSuggestion';
import withRouter from '../Functions/WithRouter';

const profileIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-grey.png';
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";
const linkIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon-blue.png";
const calendarIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/calendar-icon-blue.png";
const timeIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-icon-blue.png";
const industryIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon.png";
const tagIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/tag-icon.png";
const collaborationIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/collaboration-icon-blue.png";
const videoIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/video-icon.png";
const imageIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/image-icon.png";

class ProjectDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
          modalIsOpen: false,
          creatorProfileLink: '',
          imageIndex: 0
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.calculateKeyMetrics = this.calculateKeyMetrics.bind(this)
        this.renderProject = this.renderProject.bind(this)
    }

    componentDidMount(){
        document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

        if (this.state.emailId) {
          //send this logged out user out of the app
        } else {

          this.retrieveData()

        }
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in projectDetails', this.props, prevProps)

      if (this.props.modalIsOpen !== prevProps.modalIsOpen) {
        this.retrieveData()
      } else if (this.props.selectedProject && !prevProps.selectedProject) {
        this.retrieveData()
      } else if (this.props.selectedProject && this.props.selectedProject._id && prevProps.selectedProject && this.props.selectedProject._id !== prevProps.selectedProject._id) {
        this.retrieveData()
      } else if (this.props.orgCode !== prevProps.orgCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in projectDetails:  ', this.props.activeOrg, window.location.pathname, this.props.selectedProject)

      let creatorProfileLink = ''
      let passedState = {}

      if (window.location.pathname.includes('/organizations') && this.props.selectedProject && this.props.selectedProject.userFirstName && this.props.selectedProject.userLastName) {
        creatorProfileLink = '/organizations/' + this.props.orgCode + '/members/' + this.props.selectedProject.userFirstName.toLowerCase() + this.props.selectedProject.userLastName.toLowerCase()
        passedState = { member: null }
      } else if (window.location.pathname.includes('/advisor') && this.props.selectedProject) {

        let userEmail = ''
        if (this.props.selectedProject.emailId && this.props.selectedProject.emailId !== '') {
          userEmail = this.props.selectedProject.emailId
        } else if (this.props.selectedProject.userEmail && this.props.selectedProject.userEmail !== '') {
          userEmail = this.props.selectedProject.userEmail
        }

        creatorProfileLink = '/advisor/advisees/' + userEmail
        passedState = { member: null }

      } else {
        console.log('no link necesssary')
      }

      const modalIsOpen = this.props.modalIsOpen
      const selectedProject = this.props.selectedProject
      const orgCode = this.props.orgCode
      const suggestions = this.props.suggestions

      console.log('show passedState: ', creatorProfileLink, passedState)
      this.setState({ modalIsOpen, selectedProject, orgCode, creatorProfileLink, passedState, suggestions })

    }

    closeModal() {
      console.log('show closeModal in projectDetails')

      // this.setState({ modalIsOpen: false });
      this.props.closeModal()
    }

    calculateKeyMetrics(type) {
      console.log('calculateKeyMetrics', type)

      let keyMetric = 0
      if (type === 'posts') {
        if (this.props.selectedProject.metrics.values.posts && this.props.selectedProject.metrics.values.posts.length > 0) {
          for (let i = 1; i <= this.props.selectedProject.metrics.values.posts.length; i++) {
            keyMetric = keyMetric + 1
          }
        }
      } else if (type === 'views') {
        if (this.props.selectedProject.metrics.values.posts && this.props.selectedProject.metrics.values.posts.length > 0) {
          for (let i = 1; i <= this.props.selectedProject.metrics.values.posts.length; i++) {
            keyMetric = keyMetric + this.props.selectedProject.metrics.values.posts[i - 1].views
          }
        }
      } else if (type === 'likes') {
        if (this.props.selectedProject.metrics.values.posts && this.props.selectedProject.metrics.values.posts.length > 0) {
          for (let i = 1; i <= this.props.selectedProject.metrics.values.posts.length; i++) {
            keyMetric = keyMetric + this.props.selectedProject.metrics.values.posts[i - 1].likes
          }
        }
      } else if (type === 'follows') {
        if (this.props.selectedProject.metrics.values.follows && this.props.selectedProject.metrics.values.follows.length > 0) {
          for (let i = 1; i <= this.props.selectedProject.metrics.values.follows.length; i++) {
            keyMetric = keyMetric + 1
          }
        }
      } else if (type === 'ints') {
        if (this.props.selectedProject.metrics.values.posts && this.props.selectedProject.metrics.values.posts.length > 0) {
          for (let i = 1; i <= this.props.selectedProject.metrics.values.posts.length; i++) {
            keyMetric = keyMetric + this.props.selectedProject.metrics.values.posts[i - 1].interactions
          }
        }
        if (this.props.selectedProject.metrics.values.follows && this.props.selectedProject.metrics.values.follows.length > 0) {
          for (let i = 1; i <= this.props.selectedProject.metrics.values.follows.length; i++) {
            keyMetric = keyMetric + (this.props.selectedProject.metrics.values.follows[i - 1].interactions * this.props.selectedProject.metrics.values.follows[i - 1].accountFollows)
          }
        }
      } else if (type === 'platform') {
        if (this.props.selectedProject.metrics.values.posts && this.props.selectedProject.metrics.values.posts.length > 0) {
          for (let i = 1; i <= this.props.selectedProject.metrics.values.posts.length; i++) {
            keyMetric = 'LinkedIn'
          }
        }
      }

      return keyMetric
    }

    renderProject() {
      console.log('renderProject called')

      return (
        <div key="projectDetails" className="full-width padding-20">
          <Link className="background-button full-width left-text standard-color" disable={true} to={(this.props.selectedProject.userUsername) ? "/" + this.props.selectedProject.userUsername + '/profile' : "/app"}>
            <div className="edit-profile-row">
              <div className="fixed-column-60">
                <img className="profile-thumbnail-2" alt="img" src={(this.props.selectedProject.userPic) ? this.props.selectedProject.userPic : profileIconGrey} />
              </div>
              <div className="calc-column-offset-160">
                <p className="heading-text-5">{this.props.selectedProject.name}</p>

                {(window.location.pathname.includes('/app')) ? (
                  <label className="description-text-3 left-padding-5">by {this.props.selectedProject.userFirstName} {this.props.selectedProject.userLastName}</label>
                ) : (
                  <label className="description-text-3 left-padding-5">by <Link to={this.state.creatorProfileLink} state={this.state.passedState}>{this.props.selectedProject.userFirstName} {this.props.selectedProject.userLastName}</Link></label>
                )}

              </div>
              {(this.props.selectedProject.createdAt) && (
                <div className="fixed-column-100">
                  <p className="description-text-2 right-text">Created: {this.props.selectedProject.createdAt.substring(0,10)}</p>
                </div>
              )}

              <div className="clear" />

            </div>
          </Link>

          <div className="edit-profile-row">
            <div className="col span-2-of-3 box">

              {(this.props.selectedProject.imageURL) && (
                <div>
                  {(!this.props.selectedProject.videoURL || this.state.imageIndex === 0) && (
                    <img src={this.props.selectedProject.imageURL} alt="GC" className="image-full-auto"/>
                  )}
                </div>
              )}

              {(this.props.selectedProject.videoURL) && (
                <div>
                  {(!this.props.selectedProject.imageURL || this.state.imageIndex === 1) && (
                    <div className="video-container">
                      <iframe
                        title="videoLink"
                        className="video-iframe"
                        src={this.props.selectedProject.videoURL}
                        frameBorder="0"
                        name="iframe_video1"
                      />
                    </div>
                  )}
                </div>
              )}

              {(this.props.selectedProject.imageURL && this.props.selectedProject.videoURL) && (
                <div className="profile-links">
                  <ul>
                    <li><button className="background-button" onClick={() => this.setState({ imageIndex: 0 })}><div className={(this.state.imageIndex === 0) ? "cta-border padding-5" : "padding-5"}><img src={imageIcon} alt="GC" className="image-auto-22 horizontal-margin-5"/></div></button></li>
                    <li><button className="background-button" onClick={() => this.setState({ imageIndex: 1 })}><div className={(this.state.imageIndex === 1) ? "cta-border padding-5" : "padding-5"}><img src={videoIcon} alt="GC" className="image-auto-22 horizontal-margin-5"/></div></button></li>
                  </ul>
                </div>
              )}

              <div>
                <p>{this.props.selectedProject.description}</p>
              </div>

              {/*
              {(this.props.selectedProject.metrics && this.props.selectedProject.metrics.name === 'Social Media Marketing') && (
                <div>

                  <div className="spacer" /><div className="spacer" />

                  <hr className="clear-padding right-margin-20"/>

                  <div className="spacer" /><div className="spacer" /><div className="spacer" />

                  <p className="description-text-2 description-text-color half-bold-text">PROJECT METRICS</p>

                  <div className="spacer" /><div className="spacer" />

                  {(this.props.selectedProject.metrics.values) && (
                    <div>

                      <div className="center-text">
                        <div className="col span-1-of-3 clear-padding clear-margin">
                          <p className="heading-text-2 cta-color half-bold-text">{this.calculateKeyMetrics('posts')}</p>
                          <p>Posts</p>
                        </div>
                        <div className="col span-1-of-3 clear-padding clear-margin">
                          <p className="heading-text-2 cta-color half-bold-text">{this.calculateKeyMetrics('views')}</p>
                          <p>Views</p>
                        </div>
                        <div className="col span-1-of-3 clear-padding clear-margin">
                          <p className="heading-text-2 cta-color half-bold-text">{this.calculateKeyMetrics('likes')}</p>
                          <p>Likes</p>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="center-text">
                        <div className="col span-1-of-3 clear-padding clear-margin">
                          <p className="heading-text-2 cta-color half-bold-text">{this.calculateKeyMetrics('follows')}</p>
                          <p>Follows</p>
                        </div>
                        <div className="col span-1-of-3 clear-padding clear-margin">
                          <p className="heading-text-2 cta-color half-bold-text">{this.calculateKeyMetrics('ints')}</p>
                          <p>Ints</p>
                        </div>

                        <div className="clear" />
                      </div>

                      <div className="spacer" /><div className="spacer" />

                    </div>
                  )}
                  {(this.props.selectedProject.metrics.values && this.props.selectedProject.metrics.values.posts && this.props.selectedProject.metrics.values.posts.length > 0) && (
                    <div>

                      <div className="spacer" />
                      <p className="description-text-2 description-text-color half-bold-text underline-text">Posts</p>
                      <div className="half-spacer" />

                      <div className="description-text-2 right-padding-20">
                        <div className="col span-2-of-10 clear-padding clear-margin">
                          <label className="half-bold-text">Date</label>
                        </div>
                        <div className="col span-2-of-10 clear-padding clear-margin">
                          <label className="half-bold-text">Link</label>
                        </div>
                        <div className="col span-3-of-10 clear-padding clear-margin">
                          <label className="half-bold-text">Platform</label>
                        </div>
                        <div className="col span-1-of-10 clear-padding clear-margin">
                          <label className="half-bold-text">Views</label>
                        </div>
                        <div className="col span-1-of-10 clear-padding clear-margin">
                          <label className="half-bold-text">Likes</label>
                        </div>
                        <div className="col span-1-of-10 clear-padding clear-margin">
                          <label className="half-bold-text">Ints</label>
                        </div>
                        <div className="clear" />
                      </div>

                      {this.props.selectedProject.metrics.values.posts.map((value, index) =>
                        <div>
                          <div className="description-text-2 right-padding-20">
                            <div className="col span-2-of-10 clear-padding clear-margin">
                              <p>{(value.postDate) ? value.postDate.substring(0,10) : ""}</p>
                            </div>
                            <div className="col span-2-of-10 clear-padding clear-margin">
                              <a href={value.link} target="_blank">Click Link</a>
                            </div>
                            <div className="col span-3-of-10 clear-padding clear-margin">
                              <p>{value.platform}</p>
                            </div>
                            <div className="col span-1-of-10 clear-padding clear-margin">
                              <p>{value.views}</p>
                            </div>
                            <div className="col span-1-of-10 clear-padding clear-margin">
                              <p>{value.likes}</p>
                            </div>
                            <div className="col span-1-of-10 clear-padding clear-margin">
                              <p>{value.interactions}</p>
                            </div>
                            <div className="clear" />
                          </div>
                        </div>
                      )}

                      <div className="spacer"/><div className="spacer"/>
                    </div>
                  )}

                  {(this.props.selectedProject.metrics.values && this.props.selectedProject.metrics.values.follows && this.props.selectedProject.metrics.values.follows.length > 0) && (
                    <div>

                      <div className="spacer" />
                      <p className="description-text-2 description-text-color half-bold-text underline-text">Account Follows</p>
                      <div className="half-spacer" />

                      <div className="description-text-2 right-padding-20">
                        <div className="col span-2-of-10 clear-padding clear-margin">
                          <label className="half-bold-text">Start Date</label>
                        </div>
                        <div className="col span-2-of-10 clear-padding clear-margin">
                          <label className="half-bold-text">End Date</label>
                        </div>
                        <div className="col span-2-of-10 clear-padding clear-margin">
                          <label className="half-bold-text">Platform</label>
                        </div>
                        <div className="col span-2-of-10 clear-padding clear-margin">
                          <label className="half-bold-text">Follows</label>
                        </div>
                        <div className="col span-2-of-10 clear-padding clear-margin">
                          <label className="half-bold-text">Ints / Acct</label>
                        </div>

                        <div className="clear" />
                      </div>

                      {this.props.selectedProject.metrics.values.follows.map((value, index) =>
                        <div>
                          <div className="description-text-2 right-padding-20">
                            <div className="col span-2-of-10 clear-padding clear-margin">
                              <p>{(value.metricStartDate) ? value.metricStartDate.substring(0,10) : ""}</p>
                            </div>
                            <div className="col span-2-of-10 clear-padding clear-margin">
                              <p>{(value.metricEndDate) ? value.metricEndDate.substring(0,10) : ""}</p>
                            </div>
                            <div className="col span-2-of-10 clear-padding clear-margin">
                              <p>{value.platform}</p>
                            </div>
                            <div className="col span-2-of-10 clear-padding clear-margin">
                              <p>{value.accountFollows}</p>
                            </div>
                            <div className="col span-2-of-10 clear-padding clear-margin">
                              <p>{value.interactions}</p>
                            </div>
                            <div className="clear" />
                          </div>
                        </div>
                      )}

                      <div className="spacer"/><div className="spacer"/>

                    </div>
                  )}


                </div>
              )}*/}
              {(this.props.private && this.props.selectedProject.grades && this.props.selectedProject.grades.length > 0) && (
                <div>

                  <div className="spacer" /><div className="spacer" />

                  <hr className="clear-padding right-margin-20"/>

                  <div className="spacer" /><div className="spacer" /><div className="spacer" />

                  <p className="description-text-2 description-text-color half-bold-text">SUGGESTIONS & FEEDBACK</p>

                  <div className="spacer" /><div className="spacer" />

                  {(this.state.suggestions && this.state.suggestions.length > 0) ? (
                    <div className="top-padding-20">
                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                      {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                      {this.state.suggestions.map((item, optionIndex) =>
                        <div key={item}>
                          <SubRenderSuggestion history={this.props.navigate}
                            suggestion={item} index={optionIndex}
                            inModal={null} sideNav={null} suggestions={this.state.suggestions}
                            closeModal={this.closeModal}
                          />
                        </div>
                      )}
                      <div className="clear" />
                    </div>
                  ) : (
                    <div className="row-20">
                      <p className="error-color">You have no suggestions yet. Share with others to crowdsource suggestions.</p>
                    </div>
                  )}

                  <div>
                    {this.props.selectedProject.grades.map((value, index) =>
                      <div key={value}>
                        {(value.grade || value.feedback) && (
                          <div>
                            <div>
                              <div className="fixed-column-60">
                                <p className="heading-text-2 cta-color half-bold-text">{value.grade}</p>
                              </div>
                              <div className="calc-column-offset-60">
                                <p className="description-text-2">{value.contributorFirstName} {value.contributorLastName} ({value.contributorRoleName}) {(value.contributorTitle) && " - " + value.contributorTitle + " @ " + value.contributorEmployerName}</p>
                                <p className="heading-text-6">{value.feedback}</p>
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="spacer"/><div className="spacer"/>
                          </div>
                        )}
                      </div>
                    )}

                    <div className="spacer"/><div className="spacer"/>
                  </div>
                </div>
              )}
            </div>
            <div className="col span-1-of-3 box description-text-2">

              <div className="row-10">
                <div className="fixed-column-30">
                  <img className="image-auto-17" alt="img" src={linkIconBlue} />
                </div>
                <div className="calc-column-offset-30">
                  <a target="_blank" href={this.props.selectedProject.url}>Click Here for Details</a>
                </div>
                <div className="clear" />
              </div>

              <div className="row-10">
                <div className="fixed-column-30">
                  <img className="image-auto-17" alt="img" src={calendarIconBlue} />
                </div>
                <div className="calc-column-offset-30">
                  <p>{this.props.selectedProject.startDate} - {this.props.selectedProject.endDate}</p>
                </div>
                <div className="clear" />
              </div>

              <div className="row-10">
                <div className="fixed-column-30">
                  <img className="image-auto-17" alt="img" src={timeIconBlue} />
                </div>
                <div className="calc-column-offset-30">
                  <p>{this.props.selectedProject.hours} Hours Invested</p>
                </div>
                <div className="clear" />
              </div>

              <div className="row-10">
                <div className="fixed-column-30">
                  <img className="image-auto-17" alt="img" src={industryIcon} />
                </div>
                <div className="calc-column-offset-30">
                  <p>{this.props.selectedProject.category}{this.props.selectedProject.jobFunction && " | " + this.props.selectedProject.jobFunction}{this.props.selectedProject.industry && " | " + this.props.selectedProject.industry}</p>
                </div>
                <div className="clear" />
              </div>

              {(this.props.selectedProject.skillTags) && (
                <div className="row-10">
                  <div className="fixed-column-30">
                    <img className="image-auto-17" alt="img" src={tagIcon} />
                  </div>
                  <div className="calc-column-offset-30">
                    <p>{this.props.selectedProject.skillTags}</p>
                  </div>
                  <div className="clear" />
                </div>
              )}

              {(this.props.selectedProject.collaborators && this.props.selectedProject.collaborators.length > 0) && (
                <div className="row-10">
                  <div className="fixed-column-30">
                    <img className="image-auto-17" alt="img" src={collaborationIconBlue} />
                  </div>
                  <div className="calc-column-offset-30">
                    {this.props.selectedProject.collaborators.map((value, index) =>
                      <div key={value}>
                        <div>
                          <div>
                            <div className="fixed-column-25 heading-text-2 cta-color half-bold-text">
                              <div className="mini-spacer" /><div className="mini-spacer" />
                              <img className="image-auto-18" alt="img" src={value.pictureURL ? value.pictureURL : profileIconGrey} />
                            </div>
                            <div className="calc-column-offset-25">
                              <p>{value.firstName} {value.lastName} ({value.roleName})</p>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="spacer"/><div className="spacer"/>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              )}
            </div>
            <div className="clear" />
          </div>

          {(!this.props.excludeModal) && (
            <div>
              <div className="spacer" />

              <button className="btn btn-squarish white-background cta-color" onClick={() => this.closeModal()}>
                <div>
                  <div className="float-left top-padding-5"><img className="image-auto-11" alt="img" src={closeIcon} /></div>
                  <div className="float-left left-padding">Close View</div>
                  <div className="clear" />
                </div>
              </button>
            </div>
          )}
        </div>
      )
    }

    render() {

      console.log('rendering projectDetails ')

      return (
          <div>
            {(this.props.excludeModal) ? (
              <div className="standard-container-2">
                {this.renderProject()}
              </div>
            ) : (
              <div>
                {this.state.modalIsOpen && (
                  <Modal
                   isOpen={this.state.modalIsOpen}
                   onAfterOpen={this.afterOpenModal}
                   onRequestClose={this.closeModal}
                   className="modal"
                   overlayClassName="modal-overlay"
                   contentLabel="Example Modal"
                   ariaHideApp={false}
                 >
                  {(this.props.selectedProject) && (
                    <div>
                      {this.renderProject()}
                    </div>
                  )}
                 </Modal>
                )}
              </div>
            )}
          </div>
      )
    }
}


export default withRouter(ProjectDetails);
