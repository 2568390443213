import React, {Component} from 'react';
import Axios from 'axios';
import Switch from 'react-switch';
import withRouter from '../Functions/WithRouter';

const sadFace = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/sad-face.png';
const happyFace = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/happy-face.png';

// let updateDetail = false

class InterviewDetail extends Component {
  constructor(props) {
      super(props)

      this.state = {
        emailId: null,
        username: '',
        cuFirstName: '',
        cuLastName: '',

        selectedTrack: { name: ''},
        interview: { interviewerName: '', date: '', score: ''},
        selectedJob: null,
        interviewDetailsArray: null,
        scoreOptions: ['0','1','2','3','4','5'],
        applicationFormHasChanged: false,

        sections: [],
        checkoffs: [false,false,false,false],
        glows: '',
        grows: '',

        notifyGlow: false,
        notifyGrow: false,

        checklistErrorMessage: '',
        serverPostSuccess: true,
        serverSuccessMessage: '',
        serverErrorMessage: '',
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.renderRubric = this.renderRubric.bind(this)
      this.changeCheckoff = this.changeCheckoff.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.saveApplication = this.saveApplication.bind(this)

  }

  componentDidMount(){
    document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

    this.retrieveData()
  }

  componentWillUnmount() {
    console.log('componentWillUnmount called on detail')

    // updateDetail = true

  }

  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

    if (this.props.org !== prevProps.org || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
      this.retrieveData()
    } else if (this.props.selectedJob !== prevProps.selectedJob || this.props.applications !== prevProps.applications) {
      this.retrieveData()
    } else if (this.props.selectedApplication !== prevProps.selectedApplication && window.location.pathname.includes('/career-seekers')) {
      this.retrieveData()
    }
  }

  retrieveData() {
    console.log('retrieveData called in adminAppDetail', this.props)

    // {console.log('goods: ', this.state.trackOptions, this.state.selectedApplication)}

    // const accountCode = this.props.accountCode
    const org = this.props.org
    // const id = this.props.id
    let selectedApplication = this.props.selectedApplication
    // const candidateId = this.props.candidateId
    // const benchmark = this.props.benchmark
    const selectedJob = this.props.selectedJob
    const trackIndex = this.props.trackIndex
    const selectedTrack = this.props.selectedTrack
    const interview = this.props.interview

    let email = localStorage.getItem('email');
    let username = localStorage.getItem('username');
    let cuFirstName = localStorage.getItem('firstName');
    let cuLastName = localStorage.getItem('lastName');

    if (selectedApplication) {

      let sections = []
      let checkoffs = []
      let glows = ''
      let grows = ''

      let interviewDetailsArray = []

      Axios.get('/api/applications/byid', { params: { applicationId: selectedApplication._id } })
      .then((response) => {
        console.log('Application by id query attempted', response.data);

        if (response.data.success) {
          console.log('application query worked')

          selectedApplication = response.data.application

          if (selectedJob.tracks && selectedJob.tracks.length > 0) {
            if (selectedApplication.interviewDetailsArray) {
              console.log('test 3')
              if (selectedApplication.interviewDetailsArray.length === 0) {
                createNewArray()
                console.log('test 4', interviewDetailsArray, trackIndex, interviewDetailsArray[trackIndex])
                if (interviewDetailsArray && trackIndex && interviewDetailsArray[trackIndex]) {
                  console.log('test 2')
                  sections = interviewDetailsArray[trackIndex].sections
                }
              } else {
                console.log('interviewDetails: ', selectedApplication.interviewDetailsArray, trackIndex)
                interviewDetailsArray = selectedApplication.interviewDetailsArray
                if (interviewDetailsArray[trackIndex]) {
                  sections = interviewDetailsArray[trackIndex].sections
                  checkoffs = interviewDetailsArray[trackIndex].checkoffs
                  glows = interviewDetailsArray[trackIndex].glows
                  grows = interviewDetailsArray[trackIndex].grows
                }
              }
            } else {
              createNewArray()
              console.log('test 1', interviewDetailsArray, trackIndex, interviewDetailsArray[trackIndex])
              if (interviewDetailsArray && trackIndex && interviewDetailsArray[trackIndex]) {

                sections = interviewDetailsArray[trackIndex].sections
              }
            }

            this.setState({
                emailId: email, username, cuFirstName, cuLastName, org,

                selectedApplication, selectedTrack,
                interview, selectedJob, trackIndex,

                sections, checkoffs, glows, grows,
                interviewDetailsArray
            });
          } else {
            let interviewDetails = null
            if (selectedApplication.interviewDetails && selectedApplication.interviewDetails.sections && selectedApplication.interviewDetails.sections.length > 0) {
              interviewDetails = selectedApplication.interviewDetails
              sections = selectedApplication.interviewDetails.sections
              checkoffs = selectedApplication.interviewDetails.checkoffs
              glows = selectedApplication.interviewDetails.glows
              grows = selectedApplication.interviewDetails.grows
            } else {
              console.log('ummm ')
              interviewDetails = createNewArray()
              sections = interviewDetails.sections
              checkoffs = interviewDetails.checkoffs
              glows = interviewDetails.glows
              grows = interviewDetails.grows
            }
            console.log('test 5-', sections)
            this.setState({
                emailId: email, username, cuFirstName, cuLastName, org,

                selectedApplication, selectedTrack,
                interview, selectedJob, trackIndex,

                sections, checkoffs, glows, grows, interviewDetails
            });
          }

        } else {
          console.log('application query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Application query did not work for some reason', error);
      });

      Axios.get('/api/org', { params: { orgCode: org } })
      .then((response) => {
        console.log('Org info query attempted', response.data);

        if (response.data.success) {
          console.log('org info query worked')

          const orgName = response.data.orgInfo.orgName
          const orgURL = response.data.orgInfo.orgURL
          const orgMission = response.data.orgInfo.orgMission

          const orgContactFirstName = response.data.orgInfo.contactFirstName
          const orgContactLastName = response.data.orgInfo.contactLastName
          const orgContactEmail = response.data.orgInfo.contactEmail
          const orgContactTitle = response.data.orgInfo.contactTitle
          const orgProgramName = response.data.orgInfo.orgProgramName

          this.setState({

              org, orgName, orgURL, orgMission, orgProgramName,

              orgContactFirstName, orgContactLastName, orgContactTitle, orgContactEmail,

          });

        } else {
          console.log('org info query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });

      function createNewArray() {
        console.log('creatednewarray')

        let knowledgeQuestions = []
        let communicationQuestions = []
        let passionQuestions = []

        let resumeQuestions = []
        let professionalismQuestions = []

        if (selectedJob.tracks && selectedJob.tracks.length > 0) {

          for (let i = 1; i <= selectedJob.tracks.length; i++) {
            const newTrackIndex = i - 1
            // console.log('show name: ', selectedJob.tracks[newTrackIndex].name)

            if (selectedJob.tracks[newTrackIndex].name.includes('Digital Marketing') || selectedJob.tracks[newTrackIndex].name.includes('Design') || selectedJob.tracks[trackIndex].name.includes('Video')) {
              knowledgeQuestions = [
                { question: 'How has your education helped prepare you for a job in Design, Digital Media, Production, or Marketing/Communications? Which path would like most?', helper: 'What to look for: Communication Skills, applicable courses/projects.'},
                { question: 'Tell me about the best piece of work or project you’ve ever done. Explain your process getting to the completed project or piece.', helper: 'What to look for: Pride in work, ability to explain their design and process, confidence in skills.'},
              ]
              communicationQuestions = [
                { question: 'How would you respond if a client gives you harsh criticism?', helper: 'What to look for: Empathy, Curiosity, Problem-solving skills, Analytical/ Research-Oriented.'},
                { question: 'Tell me about a recent group project you worked on. What was your role? How did you work with other team members?', helper: 'What to look for: teamwork, role defining, accountability, time management.'},
              ]
              passionQuestions = [
                { question: 'Why is working in and with technology important to you?', helper: 'What to look for: Passionate, Driven, Dedicated to the field.'},
                { question: 'Which is your least favorite tech company and why?', helper: 'What to look for: Willingness to answer, reaction, communication skills.'},
              ]
            } else if (selectedJob.tracks[newTrackIndex].name.includes('Help') || selectedJob.tracks[newTrackIndex].name.includes('Customer Service')) {
              knowledgeQuestions = [
                { question: 'Tell me about your troubleshooting process with a common error on a PC or Apple.', helper: 'Did applicant explain process of identifying, planning and resolving an error?'},
                { question: 'How has your education helped prepare you for a job in IT?', helper: 'Look for the applicant to use buzzwords: network admin, system admin, disaster recovery, windows server'},
              ]
              communicationQuestions = [
                { question: 'What would you do if a frustrated customer complained to you about a widely known issue with a computer or a system?', helper: 'What to look for: Empathy, Patience, Problem-solving skills, customer service skills.'},
                { question: 'How important do you think it is to work collaboratively with other IT Support representatives and teams across a company?', helper: 'What to look for: Teamwork, Communication Skills, Desire for business improvement.'},
              ]
              passionQuestions = [
                { question: 'Why is working in IT important to you?', helper: 'What to look for: Passionate, Driven, Dedicated to the field.'},
                { question: 'Which is your least favorite tech company and why?', helper: 'What to look for: Willingness to answer, reaction, communication skills.'},
              ]
            } else if (selectedJob.tracks[newTrackIndex].name.includes('Software') || selectedJob.tracks[newTrackIndex].name.includes('Engineer') || selectedJob.tracks[newTrackIndex].name.includes('Data Science')) {
              knowledgeQuestions = [
                { question: 'How much are you coding on a daily basis? What programming languages do you use the most?', helper: 'What to look for: commitment to coding, comfort around code.'},
                { question: 'What distinguishes a great software engineer from a good one? Do you feel you have those qualities?', helper: 'What to look for: Time management, flexibility, Integrity, patience, specializations, honesty, introspection.'},
              ]
              communicationQuestions = [
                { question: "What's the most important thing to look for or check when reviewing another team member's code?", helper: 'What to look for: Attention to detail, Debugging skills (functional,secure), Problem-solving skills.'},
                { question: 'Tell me about a recent software development group project you worked on. What was your role? How did you work with other team members?', helper: 'What to look for: teamwork, role defining, accountability, time management.'},
              ]
              passionQuestions = [
                { question: 'Why is working in software development important to you?', helper: 'What to look for: Passionate, Driven, Dedicated to the field.'},
                { question: 'Which is your least favorite tech company and why?', helper: 'What to look for: Willingness to answer, reaction, communication skills.'},
              ]
            } else if (selectedJob.tracks[newTrackIndex].name.includes('Sales') || selectedJob.tracks[newTrackIndex].name.includes('Business Development') || selectedJob.tracks[newTrackIndex].name.includes('Operations') || selectedJob.tracks[newTrackIndex].name.includes('Strategy') || selectedJob.tracks[newTrackIndex].name.includes('Finance')) {
              knowledgeQuestions = [
                { question: 'How has your education helped prepare you for a job in Business/Operations or Sales?', helper: 'What to look for: Communication Skills, applicable courses/projects.'},
                { question: 'Tell me about the best project you’ve ever done. Explain your process getting to the completed project.', helper: 'What to look for: Pride in work, ability to explain their project and process, confidence in skills.'},
              ]
              communicationQuestions = [
                { question: "Describe a time that you had to steer someone toward a different course of action than the one they were set on taking?", helper: 'What to look for: Empathy, Patience, Problem-solving skills, Big-picture thinking, attention to detail.'},
                { question: 'In business, you have to work with all types of people in different positions throughout a company. How would you deal with a difficult stakeholder?', helper: 'What to look for: Problem-solving skills, Communication Skills, Ability to diffuse difficult situations.'},
              ]
              passionQuestions = [
                { question: 'Why is working in and with technology important to you?', helper: 'What to look for: Passionate, Driven, Dedicated to the field.'},
                { question: 'Which is your least favorite tech company and why?', helper: 'What to look for: Willingness to answer, reaction, communication skills.'},
              ]
            }
            resumeQuestions = [
              { question: 'Is there anything that you want us to know that is not mentioned on your resume?', helper: 'What to look for: Missing information, informal education and skills.'},
              { question: 'Does the resume show the candidate is a good fit for the career track?', helper: 'What to look for: experience, projects, and extracurricular activities that show relevant interests and skills.'},
            ]
            professionalismQuestions = [
              { question: 'Tell me about your job experience[s] and/or school activities as they relate to your future goals in an Design, Digital Media, Production or Marketing career.', helper: 'What to look for: Communication skills, ability to tell their story.'},
              { question: 'Let’s say you are going to be 30-minutes late to an interview or to work, what are the appropriate next steps?', helper: 'What to look for: Explains what Email/call supervisor/interviewer would say, prepare ahead of time so that it doesn’t happen again.'},
              { question: 'Why do you want to intern with the Bixel Exchange this summer? What specific skills are you looking to improve?', helper: 'What to look for: Demonstrates commitment, recognizes areas for improvement and where to improve.'},
            ]

            sections = [
              { title: "Knowledge, Skills, Abilities", questions: knowledgeQuestions, sectionScore: 0 },
              { title: "Communication, Collaboration, and Problem Solving", questions: communicationQuestions, sectionScore: 0 },
              { title: "Passion for Technology", questions: passionQuestions, sectionScore: 0 },
              { title: "True, Accurate, Error-Free and Complete Resume", questions: resumeQuestions, sectionScore: 0},
              { title: "Demonstrable Interview Skills/Commitment/Professionalism Needed in Internship Program", questions: professionalismQuestions, sectionScore: 0},
            ]
            checkoffs = [false,false,false,false]
            glows = ''
            grows = ''

            // interviewDetailsArray.push({ sections, checkoffs, glows, grows })
            interviewDetailsArray.push({
              sections: [
              { title: "Knowledge, Skills, Abilities", questions: knowledgeQuestions, sectionScore: 0 },
              { title: "Communication, Collaboration, and Problem Solving", questions: communicationQuestions, sectionScore: 0 },
              { title: "Passion for Technology", questions: passionQuestions, sectionScore: 0 },
              { title: "True, Accurate, Error-Free and Complete Resume", questions: resumeQuestions, sectionScore: 0},
              { title: "Demonstrable Interview Skills/Commitment/Professionalism Needed in Internship Program", questions: professionalismQuestions, sectionScore: 0}],
              checkoffs: [false,false,false,false], glows: '', grows: ''
            })
          }
        } else if (selectedJob.field) {
          if (selectedJob.field.includes('Marketing') || selectedJob.field.includes('Design') || selectedJob.field.includes('Video')) {
            knowledgeQuestions = [
              { question: 'How has your education helped prepare you for a job in Design, Digital Media, Production, or Marketing/Communications? Which path would like most?', helper: 'What to look for: Communication Skills, applicable courses/projects.'},
              { question: 'Tell me about the best piece of work or project you’ve ever done. Explain your process getting to the completed project or piece.', helper: 'What to look for: Pride in work, ability to explain their design and process, confidence in skills.'},
            ]
            communicationQuestions = [
              { question: 'How would you respond if a client gives you harsh criticism?', helper: 'What to look for: Empathy, Curiosity, Problem-solving skills, Analytical/ Research-Oriented.'},
              { question: 'Tell me about a recent group project you worked on. What was your role? How did you work with other team members?', helper: 'What to look for: teamwork, role defining, accountability, time management.'},
            ]
            passionQuestions = [
              { question: 'Why is working in and with technology important to you?', helper: 'What to look for: Passionate, Driven, Dedicated to the field.'},
              { question: 'Which is your least favorite tech company and why?', helper: 'What to look for: Willingness to answer, reaction, communication skills.'},
            ]
          } else if (selectedJob.field.includes('Help') || selectedJob.field.includes('Customer Service')) {
            knowledgeQuestions = [
              { question: 'Tell me about your troubleshooting process with a common error on a PC or Apple.', helper: 'Did applicant explain process of identifying, planning and resolving an error?'},
              { question: 'How has your education helped prepare you for a job in IT?', helper: 'Look for the applicant to use buzzwords: network admin, system admin, disaster recovery, windows server'},
            ]
            communicationQuestions = [
              { question: 'What would you do if a frustrated customer complained to you about a widely known issue with a computer or a system?', helper: 'What to look for: Empathy, Patience, Problem-solving skills, customer service skills.'},
              { question: 'How important do you think it is to work collaboratively with other IT Support representatives and teams across a company?', helper: 'What to look for: Teamwork, Communication Skills, Desire for business improvement.'},
            ]
            passionQuestions = [
              { question: 'Why is working in IT important to you?', helper: 'What to look for: Passionate, Driven, Dedicated to the field.'},
              { question: 'Which is your least favorite tech company and why?', helper: 'What to look for: Willingness to answer, reaction, communication skills.'},
            ]
          } else if (selectedJob.field.includes('Software') || selectedJob.field.includes('Engineer') || selectedJob.field.includes('Data Science')) {
            knowledgeQuestions = [
              { question: 'How much are you coding on a daily basis? What programming languages do you use the most?', helper: 'What to look for: commitment to coding, comfort around code.'},
              { question: 'What distinguishes a great software engineer from a good one? Do you feel you have those qualities?', helper: 'What to look for: Time management, flexibility, Integrity, patience, specializations, honesty, introspection.'},
            ]
            communicationQuestions = [
              { question: "What's the most important thing to look for or check when reviewing another team member's code?", helper: 'What to look for: Attention to detail, Debugging skills (functional,secure), Problem-solving skills.'},
              { question: 'Tell me about a recent software development group project you worked on. What was your role? How did you work with other team members?', helper: 'What to look for: teamwork, role defining, accountability, time management.'},
            ]
            passionQuestions = [
              { question: 'Why is working in software development important to you?', helper: 'What to look for: Passionate, Driven, Dedicated to the field.'},
              { question: 'Which is your least favorite tech company and why?', helper: 'What to look for: Willingness to answer, reaction, communication skills.'},
            ]
          } else if (selectedJob.field.includes('Sales') || selectedJob.field.includes('Business Development') || selectedJob.field.includes('Operations') || selectedJob.field.includes('Strategy') || selectedJob.field.includes('Finance')) {
            knowledgeQuestions = [
              { question: 'How has your education helped prepare you for a job in Business/Operations or Sales?', helper: 'What to look for: Communication Skills, applicable courses/projects.'},
              { question: 'Tell me about the best project you’ve ever done. Explain your process getting to the completed project.', helper: 'What to look for: Pride in work, ability to explain their project and process, confidence in skills.'},
            ]
            communicationQuestions = [
              { question: "Describe a time that you had to steer someone toward a different course of action than the one they were set on taking?", helper: 'What to look for: Empathy, Patience, Problem-solving skills, Big-picture thinking, attention to detail.'},
              { question: 'In business, you have to work with all types of people in different positions throughout a company. How would you deal with a difficult stakeholder?', helper: 'What to look for: Problem-solving skills, Communication Skills, Ability to diffuse difficult situations.'},
            ]
            passionQuestions = [
              { question: 'Why is working in and with technology important to you?', helper: 'What to look for: Passionate, Driven, Dedicated to the field.'},
              { question: 'Which is your least favorite tech company and why?', helper: 'What to look for: Willingness to answer, reaction, communication skills.'},
            ]
          }
          resumeQuestions = [
            { question: 'Is there anything that you want us to know that is not mentioned on your resume?', helper: 'What to look for: Missing information, informal education and skills.'},
            { question: 'Does the resume show the candidate is a good fit for the career track?', helper: 'What to look for: experience, projects, and extracurricular activities that show relevant interests and skills.'},
          ]
          professionalismQuestions = [
            { question: 'Tell me about your job experience[s] and/or school activities as they relate to your future goals in an Design, Digital Media, Production or Marketing career.', helper: 'What to look for: Communication skills, ability to tell their story.'},
            { question: 'Let’s say you are going to be 30-minutes late to an interview or to work, what are the appropriate next steps?', helper: 'What to look for: Explains what Email/call supervisor/interviewer would say, prepare ahead of time so that it doesn’t happen again.'},
            { question: 'Why do you want to intern with the Bixel Exchange this summer? What specific skills are you looking to improve?', helper: 'What to look for: Demonstrates commitment, recognizes areas for improvement and where to improve.'},
          ]

          sections = [
            { title: "Knowledge, Skills, Abilities", questions: knowledgeQuestions, sectionScore: 0 },
            { title: "Communication, Collaboration, and Problem Solving", questions: communicationQuestions, sectionScore: 0 },
            { title: "Passion for Technology", questions: passionQuestions, sectionScore: 0 },
            { title: "True, Accurate, Error-Free and Complete Resume", questions: resumeQuestions, sectionScore: 0},
            { title: "Demonstrable Interview Skills/Commitment/Professionalism Needed in Internship Program", questions: professionalismQuestions, sectionScore: 0},
          ]
          checkoffs = [false,false,false,false]
          glows = ''
          grows = ''

          // interviewDetailsArray.push({ sections, checkoffs, glows, grows })
          const interviewDetails = {
            sections: [
            { title: "Knowledge, Skills, Abilities", questions: knowledgeQuestions, sectionScore: 0 },
            { title: "Communication, Collaboration, and Problem Solving", questions: communicationQuestions, sectionScore: 0 },
            { title: "Passion for Technology", questions: passionQuestions, sectionScore: 0 },
            { title: "True, Accurate, Error-Free and Complete Resume", questions: resumeQuestions, sectionScore: 0},
            { title: "Demonstrable Interview Skills/Commitment/Professionalism Needed in Internship Program", questions: professionalismQuestions, sectionScore: 0}],
            checkoffs: [false,false,false,false], glows: '', grows: ''
          }

          return interviewDetails
        }
      }
    }
  }

  formChangeHandler(event) {
    console.log('formChangeHandler called')

    let interview = this.state.interview
    if (event.target.name === 'interviewerName') {
      interview = { interviewerName: event.target.value, date: interview.date, score: interview.score }
      this.setState({ interview, applicationFormHasChanged: true })
    } else if (event.target.name === "date") {
      interview = { interviewerName: interview.interviewerName, date: event.target.value, score: interview.score }
      this.setState({ interview, applicationFormHasChanged: true })
    } else if (event.target.name === "score") {
      interview = { interviewerName: interview.interviewerName, date: interview.date, score: event.target.value }
      this.setState({ interview, applicationFormHasChanged: true })
    } else if (event.target.name === 'glows') {
      let notifyGlow = this.state.notifyGlow
      if (event.target.name === '') {
        notifyGlow = false
      } else {
        notifyGlow = true
      }
      this.setState({ glows: event.target.value, notifyGlow, applicationFormHasChanged: true })
    } else if (event.target.name === 'grows') {
      console.log('wer are in grows')
      let notifyGrow = this.state.notifyGrow
      if (event.target.name === '') {
        console.log('nothings there')
        notifyGrow = false
      } else {
        console.log('somethings there')
        notifyGrow = true
      }

      this.setState({ grows: event.target.value, notifyGrow, applicationFormHasChanged: true })
    } else {
      this.setState({ [event.target.name]: event.target.value, applicationFormHasChanged: true })
    }
  }

  saveApplication() {
    console.log('saveApplication called')

    this.setState({
      serverSuccessMessage: '',
      serverErrorMessage: ''
    })

    if (!this.state.applicationFormHasChanged) {
      console.log('mic check 1')
      this.setState({ serverPostSuccess: false, serverErrorMessage: 'Make changes to form before saving'})
    } else {

      const _id = this.state.selectedApplication._id

      const interview = this.state.interview
      let interviews = this.state.selectedApplication.interviews
      interviews[this.state.trackIndex] = interview

      //configure interview details
      let sections = []
      if (this.state.sections.length !== 0) {
        sections = this.state.sections
      }

      let checkoffs = []
      if (this.state.checkoffs.length !== 0) {
        checkoffs = this.state.checkoffs
      }

      let glows = ''
      if (this.state.glows !== '') {
        glows = this.state.glows
      }

      let grows = ''
      if (this.state.grows !== '') {
        grows = this.state.grows
      }

      const notifyGlow = this.state.notifyGlow
      const notifyGrow = this.state.notifyGrow
      console.log('show me glow and grow notis', notifyGlow, notifyGrow)

      const email = this.state.selectedApplication.email
      const postingEmployerName = this.state.selectedApplication.postingEmployerName
      const firstName = this.state.selectedApplication.firstName

      const interviewDetails = { sections, checkoffs, glows, grows }
      console.log('show me interviewDetails', sections, checkoffs, glows, grows, interviewDetails, this.state.interviewDetailsArray)
      let interviewDetailsArray = this.state.interviewDetailsArray
      console.log('show interviewDetailsArray 1', interviewDetailsArray, this.state.interviewDetailsArray)
      // interviewDetailsArray[this.state.trackIndex] = interviewDetails
      console.log('show interviewDetailsArray 2', interviewDetailsArray, this.state.trackIndex)
      console.log('and show me interviews', interviews)

      const orgName = this.state.orgName
      const orgContactFirstName = this.state.orgContactFirstName
      const orgContactLastName = this.state.orgContactLastName
      const orgContactEmail = this.state.orgContactEmail
      const orgContactTitle = this.state.orgContactTitle
      const orgProgramName = this.state.orgProgramName

      const updatedAt = new Date()

      // /api/applications/update-by-id
      Axios.post('/api/applications/update-by-id', {
        _id, interview, interviewDetails, interviews, interviewDetailsArray,
        glows, notifyGlow, grows, notifyGrow,
        email, postingEmployerName, firstName, orgContactEmail, orgName,
        orgContactFirstName, orgContactLastName, orgContactTitle, orgProgramName,
        updatedAt })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Application save worked', response.data);
          this.setState({
            serverPostSuccess: true,
            serverSuccessMessage: 'Application updated successfully!'
          })

        } else {
          console.error('there was an error updating the application');
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: response.data.message,
          })
        }
      }).catch((error) => {
          console.log('Application save did not work', error);
      });
    }
  }

  renderRubric() {
    console.log('renderRubric called')

    let rows = []

    for (let i = 1; i <= this.state.sections.length; i++) {
      console.log('show sections ', this.state.sections[i - 1])
      const questionIndex = i - 1
      console.log('gimme value pz', questionIndex, this.state.sections[questionIndex])
      let wedge = '5%'
      if (this.state.sections[questionIndex].questions.length === 1) {
        console.log('got in 1')
        wedge = '15%'
      } else if (this.state.sections[questionIndex].questions.length === 2) {
        console.log('got in 2')
        wedge = '30%'
      } else if (this.state.sections[questionIndex].questions.length === 3) {
        console.log('got in 3')
        wedge = '45%'
      }

      rows.push(
        <div key={i}>
          <div className="flex-container row-direction">
            <div className="flex-50 padding-20 standard-border">
              <p className="description-text-2">{i}) {this.state.sections[i - 1].title}</p>

              <div style={{ height: wedge }} />
              <div className="table-container-1">
                <p className="description-text-2">Circle One</p>
                <ul>
                  <li className="display-inline center-horizontally"><img src={sadFace} alt="Compass full star icon" className="image-auto-20 horizontal-margin-10 bottom-margin-negative-4" /></li>
                  <li className="display-inline center-horizontally">
                    <a className="background-button" onClick={() => this.scoreCandidate(1, questionIndex)}>
                      <div className={(this.state.sections[i - 1].sectionScore === 1) ? "horizontal-rating-item padding-5 cta-border circle-radius" : "horizontal-rating-item"} >
                        <p className="description-text-2">1</p>
                    </div>
                    </a>
                  </li>
                  <li className="display-inline center-horizontally">
                    <a className="background-button" onClick={() => this.scoreCandidate(2, questionIndex)}>
                      <div className={(this.state.sections[i - 1].sectionScore === 2) ? "horizontal-rating-item padding-5 cta-border circle-radius" : "horizontal-rating-item"}>
                        <p className="description-text-2">2</p>
                    </div>
                    </a>
                  </li>
                  <li className="display-inline center-horizontally">
                    <a className="background-button" onClick={() => this.scoreCandidate(3, questionIndex)}>
                      <div className={(this.state.sections[i - 1].sectionScore === 3) ? "horizontal-rating-item padding-5 cta-border circle-radius" : "horizontal-rating-item"}>
                        <p className="description-text-2">3</p>
                    </div>
                    </a>
                  </li>
                  <li className="display-inline center-horizontally">
                    <a className="background-button" onClick={() => this.scoreCandidate(4, questionIndex)}>
                      <div className={(this.state.sections[i - 1].sectionScore === 4) ? "horizontal-rating-item padding-5 cta-border circle-radius" : "horizontal-rating-item"}>
                        <p className="description-text-2">4</p>
                    </div>
                    </a>
                  </li>
                  <li className="display-inline center-horizontally">
                    <a className="background-button" onClick={() => this.scoreCandidate(5, questionIndex)}>
                      <div className={(this.state.sections[i - 1].sectionScore === 5) ? "horizontal-rating-item padding-5 cta-border circle-radius" : "horizontal-rating-item"}>
                        <p className="description-text-2">5</p>
                    </div>
                    </a>
                  </li>
                  <li className="display-inline center-horizontally"><img src={happyFace} alt="Compass full star icon" className="image-auto-19 horizontal-margin-10 bottom-margin-negative-4" /></li>
                </ul>
              </div>
            </div>
            <div className="flex-50 standard-border padding-20">
              <ul className="left-padding-20">
                {this.renderQuestions(questionIndex)}
              </ul>
            </div>
          </div>
        </div>
      )
    }

    return rows
  }

  scoreCandidate(score, index) {
    console.log('scoreCandidate called', score, index)

    if (this.state.interviewDetailsArray && this.state.interviewDetailsArray.length > 0 ) {
      let interviewDetailsArray = this.state.interviewDetailsArray
      let sections = interviewDetailsArray[this.state.trackIndex].sections
      sections[index]['sectionScore'] = score

      interviewDetailsArray[this.state.trackIndex]['sections'] = sections
      this.setState({ sections, interviewDetailsArray, applicationFormHasChanged: true })

    } else {

      let sections = this.state.sections
      sections[index].sectionScore = score
      console.log('show sections sdf ', sections, this.state.interviewDetails, this.state.interviewDetailsArray)
      this.setState({ sections, applicationFormHasChanged: true })
    }
  }

  renderQuestions(questionIndex) {
    console.log('renderQuestions called', questionIndex, this.state.sections[questionIndex].questions)

    let rows = []

    for (let i = 1; i <= this.state.sections[questionIndex].questions.length; i++) {
      rows.push(
        <div key={i.toString() + "question"}>
          <li>
            <p className="description-text-3 half-bold-text">{this.state.sections[questionIndex].questions[i - 1].question}</p>
            <p className="description-text-4 half-bold-text">{this.state.sections[questionIndex].questions[i - 1].helper}</p>
            <div className="spacer" />
          </li>
        </div>
      )
    }
    return rows
  }

  changeCheckoff(change, index) {
    console.log('changeCheckoff called', change, index)

    console.log('weird check 1', this.state.checkoffs, this.state.interviewDetailsArray)

    if (this.state.interviewDetailsArray && this.state.interviewDetailsArray.length > 0 ) {
      let interviewDetailsArray = this.state.interviewDetailsArray
      let checkoffs = interviewDetailsArray[this.state.trackIndex].checkoffs
      checkoffs[index] = change

      interviewDetailsArray[this.state.trackIndex]['checkoffs'] = checkoffs
      this.setState({ checkoffs, interviewDetailsArray, applicationFormHasChanged: true })

    } else {
      let checkoffs = this.state.checkoffs
      checkoffs[index] = change
      this.setState({ checkoffs, applicationFormHasChanged: true })

      console.log('weird check 2', this.state.checkoffs, this.state.interviewDetailsArray)
    }
  }

  render() {

    let interviewerName = ''
    let interviewDate = ''
    let interviewScore = ''


    if (this.state.interview) {
      interviewerName = this.state.interview.interviewerName
      interviewDate = this.state.interview.date
      interviewScore = this.state.interview.score
    }

    return (
        <div>
          { (this.state.selectedApplication) ? (
            <div className="interview-details-container">
              <div>
                <div className="spacer" /><div className="spacer" />
                <p className="description-text-2">Interview Details</p>
                <p className="heading-text-3 row-10">{this.state.selectedApplication.firstName} {this.state.selectedApplication.lastName}{(this.state.selectedTrack) && "| " + this.state.selectedTrack.name}</p>
                <div className="spacer" /><div className="spacer" />
                <div className="relative-column-60">
                  <p className="profile-descriptor">Interviewer Name</p>
                  <input type="text" className="text-field" placeholder="Add the name..." name="interviewerName" value={interviewerName} onChange={this.formChangeHandler}></input>
                </div>
                <div className="relative-column-20">
                  <p className="profile-descriptor">Interview Date</p>
                  <input type="date" className="date-field" placeholder="Add a date..." name="date" value={interviewDate} onChange={this.formChangeHandler}></input>
                </div>
                <div className="relative-column-20">
                  <p className="profile-descriptor">Interview Score</p>
                  <select name="score" value={interviewScore} onChange={this.formChangeHandler} className="dropdown">
                    {this.state.scoreOptions.map(value =>
                      <option key={value.key} value={value}>{value}</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="clear"/>

              <div className="spacer" /><div className="spacer" />
              <div>
                <div className="container-left standard-border padding-20 dark-background white-text">
                  <p className="description-text-2">WORK-READINESS COMPETENCY</p>
                </div>
                <div className="container-right standard-border padding-20 dark-background white-text">
                  <p className="description-text-2">INTERVIEW QUESTIONS</p>
                </div>
              </div>
              <div className="clear"/>
              {this.renderRubric()}


              <div className="spacer" /><div className="spacer" />
              <div>
                <div className="table-container-1">
                  <p>Basic Interview Check-Offs</p>
                  <div className="half-spacer" />
                  <ul>
                    <li className="display-inline center-horizontally padding-10">
                      <Switch onChange={(change) => this.changeCheckoff(change, 0)} checked={this.state.checkoffs[0]} id="normal-switch"/>
                      <p className="description-text-2">Arrived on Time</p>
                    </li>
                    <li className="display-inline center-horizontally padding-10">
                    <Switch onChange={(change) => this.changeCheckoff(change, 1)} checked={this.state.checkoffs[1]} id="normal-switch"/>
                      <p className="description-text-2">Wore Appropriate Attire</p>
                    </li>
                    <li className="display-inline center-horizontally padding-10">
                    <Switch onChange={(change) => this.changeCheckoff(change, 2)} checked={this.state.checkoffs[2]} id="normal-switch"/>
                      <p className="description-text-2">Professional with Check-In</p>
                    </li>
                    <li className="display-inline center-horizontally padding-10">
                    <Switch onChange={(change) => this.changeCheckoff(change, 3)} checked={this.state.checkoffs[3]} id="normal-switch"/>
                      <p className="description-text-2">Used Professional Language</p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row-10 horizontal-padding">
                <label className="profile-label">GLOWS (Things they did well on)</label>
                <textarea type="text" className="notes" placeholder="Add positive feedback for this candidate..." name="glows" value={this.state.glows} onChange={(this.formChangeHandler)}></textarea>
              </div>

              <div className="row-10 horizontal-padding">
                <label className="profile-label">GROWS (Things they could improve on)</label>
                <textarea type="text" className="notes" placeholder="Add critical feedback for this candidate..." name="grows" value={this.state.grows} onChange={(this.formChangeHandler)}></textarea>
              </div>

              <div className="left-margin-30">
                <div className="spacer"/><div className="spacer"/>
                <button className="btn btn-primary" onClick={() => this.saveApplication()}>Save</button>
                <div className="spacer"/>
              </div>

              { (this.state.checklistErrorMessage!== '') && <p className="error-message">{this.state.checklistErrorMessage}</p> }
              { (this.state.serverPostSuccess) ? (
                <div className="left-margin-30">
                  <div className="spacer"/><div className="spacer"/>
                  <p className="success-message">{this.state.serverSuccessMessage}</p>
                  <div className="spacer"/>
                </div>
              ) : (
                <div className="left-margin-30">
                  <p className="error-message">{this.state.serverErrorMessage}</p>
                  <div className="spacer"/>
                </div>
              )}
            </div>
          ) : (
            <div className="interview-details-container">
              <div>
                <div className="spacer" /><div className="spacer" />
                <p className="subtitle">The interview details form for this track has not been set up yet</p>
              </div>
            </div>
          )}
        </div>

    )
  }
}

export default withRouter(InterviewDetail);
