import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import TopNavigation from './TopNavigation';

import Clients from './Common/Clients';
import RequestMaterials from './Common/RequestMaterials';

const clientLogo2 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_2.png";
const exampleInternMatch = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-intern-match.png";
const clientLogo7 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_7.png";
const clientLogo10 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_10.jpg";
const clientLogo12 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_12.png";
const opportunitiesIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png";
const candidateSelector1 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/candidate-selector-1.png";

class WorkforcePage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          caseStudies: [],
          panelSelected: 1
        }

        this.closeModal = this.closeModal.bind(this)
    }

    componentDidMount() {
        //see if user is logged in
        console.log('jobs page just mounted')
        window.scrollTo(0, 0)

        let caseStudies = [
          { type: 'Case Study', image: clientLogo12, title: "Helping UNITE-LA Help Schools", url: "https://docs.google.com/document/d/1YYGLTBjvsMhDbakDUX-VJWmMO-mj18Zc1ZpHC0g0AEw/edit", description: "This case study examines our 2-year pilot with the Bixel Exchange LA Tech Talent Pipeline program. This was our second workforce development customer and the customer where we introduced many of the innovative features that make Guided Compass what it is today."},
          { type: 'Case Study', image: clientLogo2, title: "2-Year Partnership with Bixel Exchange LA Tech Talent Pipeline", url: "https://docs.google.com/document/d/1zAV4ra-ONZQBjjV-TSwMt59K_kCZol2vIFy1ORgWN7U/edit", description: "This case study examines our 2-year pilot with the Bixel Exchange LA Tech Talent Pipeline program. This was our second workforce development customer and the customer where we introduced many of the innovative features that make Guided Compass what it is today."},
          { type: 'Case Study', image: clientLogo10, title: "Preparing Low-Income College Students for Internships on Capitol Hill", url: "https://docs.google.com/document/d/1k9OK7lm-VftRXNa9R9d9lWHVzlsnAmyAotru-kdysic/edit?usp=sharing", description: "This case study examines our 2-year pilot with the Bixel Exchange LA Tech Talent Pipeline program. This was our second workforce development customer and the customer where we introduced many of the innovative features that make Guided Compass what it is today."},
          { type: 'Case Study', image: clientLogo7, title: "Helping LAUSD's Grover Cleveland High AOAT Explore Careers", url: "https://docs.google.com/document/d/1KsexiK3JQXGMMu3hO_17wmwfVswVppFK-_zjVBndvwI/edit#", description: "This case study examines our 2-year pilot with the Bixel Exchange LA Tech Talent Pipeline program. This was our second workforce development customer and the customer where we introduced many of the innovative features that make Guided Compass what it is today."}
        ]

        const panel1Points = [
          { title: 'Create Pathway-Level Benchmarks', subtitle: 'Define the ideal candidate for each of the career pathways your program focuses on. Students can apply to the program against this, staff can train career-seekers with this, and employers can (by default) evaluate candidates against this.'},
          { title: 'Tweak Opportunity-Level Benchmarks', subtitle: 'When employers post opportunities, either the employer or internal staff can tweak the ideal candidate for the specific role (e.g. Python developer vs. Generalist Software Developer).'},
          { title: 'Market Benchmark Findings', subtitle: "Use our findings to market to both career-seekers and employers. For career-seekers, it provides a roadmap; for employers, it provides greater assurance that they will have better-fit candidates in their talent pipelines."},
          { title: 'Create Curriculum Around Benchmarks ', subtitle: "Found that most employer partners prefer someone with an interest in sports? Create curriculum around sports. Should ideal candidates be competitive? Make curriculum around competition. Curriculum should be more easily devised when it starts with the benchmarks (i.e., outcomes)."}
        ]

        const panel2Points = [
          { title: 'Build a Community', subtitle: "Guided Compass allows career-seekers to connect with one another and post in a newsfeed. Admins can pin their posts and invite alumni as well!"},
          { title: 'Help Explore Careers', subtitle: 'Career-seekers can start by taking our assessments, then matching to careers and opportunities. By default, Guided Compass has 1,100+ career paths with videos and relevant data.'},
          { title: 'Help Plan Careers', subtitle: 'Career-seekers can set goals, use the financial planning tool, and use our Career Plan Builder. This allows goal-driven career-seekers to receive relevant resources and opportunities.'},
          { title: 'Help Explore Employers', subtitle: 'Career-sekeers can explore the profiles of your employer partners as well, viewing "Day in the Life" videos, assessing their overall match, and interacting with any opportunities they are interested in.'}
        ]

        const panel3Points = [
          { title: 'Recommend Courses and Resources', subtitle: 'Admins can recommend courses. Additionally, Guided Compass integrates with Udemy and Coursera to recommend courses that will fill skill gaps for given career goals.'},
          { title: 'Post Career Events', subtitle: 'Post events (e.g., job shadows, workshops, career fairs) so that you can track RSVPs, attendance, and questions. Career-seekers are given extra points when they apply to employers they interact with at events.' },
          { title: 'Source and Post Project-Based Learning', subtitle: 'Source project prompts from employers or post project prompts yourselves. Career-seekers can submit project solutions individually or in groups, as well as collect feedback.'},
          { title: 'Collect Feedback on Prior Experiences', subtitle: 'Career-seekers have the option of sharing how they feel about their prior experience and projects right in their profile, providing extra data on their level of interest in a career path.'}
        ]

        const panel4Points = [
          { title: 'Source and Post Work Opportunities', subtitle: 'Employers can post on Guided Compass (GC), admins can post on behalf of employers, or employers can integrate their ATS with GC to automatically post on their behalf. Dictate what is required for applications before pushing live and collecting applications.'},
          { title: 'Assess and Recommend Candidates Seamlessly', subtitle: "All posts should have a benchmark attached. This allows Guided Compass to provide a 'match score' for each application. This match score allows programs to easily assess and recommend candidates to employers."},
          { title: 'Collect Feedback from Employers and Career-Seekers', subtitle: "Guided Compass allows you collect feedback from employers and career-seekers in an automated way. Feedback is available to be exported in the reporting section of the admin portal."},
          { title: 'Use Timesheets and Assignments on the Job', subtitle: "Once a someone is hired, programs and employers can use the timesheet functionality to not only track hours, but also to collect feedback each week and what workers did each week."},
        ]

        this.setState({ caseStudies, panel1Points, panel2Points, panel3Points, panel4Points })

    }

    closeModal() {
      console.log('closeModal called')
      this.setState({ modalIsOpen: false })
    }

    render() {

      let panel1ClassName = "white-background standard-border padding-20 center-text full-width"
      let panel2ClassName = "white-background standard-border padding-20 center-text full-width"
      let panel3ClassName = "white-background standard-border padding-20 center-text full-width"
      let panel4ClassName = "white-background standard-border padding-20 center-text full-width"

      if (window.innerWidth > 768) {
        if (this.state.panelSelected === 1) {
          panel1ClassName = "primary-background standard-border padding-20 center-text full-width"
        }
        if (this.state.panelSelected === 2) {
          panel2ClassName = "secondary-background standard-border padding-20 center-text full-width"
        }
        if (this.state.panelSelected === 3) {
          panel3ClassName = "senary-background standard-border padding-20 center-text full-width"
        }
        if (this.state.panelSelected === 4) {
          panel4ClassName = "septary-background standard-border padding-20 center-text full-width"
        }
      } else {
        // redefine for mobile

        panel1ClassName = "white-background standard-border padding-20 center-text full-width"
        panel2ClassName = "white-background standard-border padding-20 center-text full-width"
        panel3ClassName = "white-background standard-border padding-20 center-text full-width"
        panel4ClassName = "white-background standard-border padding-20 center-text full-width"
        if (this.state.panelSelected === 1) {
          panel1ClassName = "primary-background standard-border padding-20 center-text full-width"
        }
        if (this.state.panelSelected === 2) {
          panel2ClassName = "secondary-background standard-border padding-20 center-text full-width"
        }
        if (this.state.panelSelected === 3) {
          panel3ClassName = "senary-background standard-border padding-20 center-text full-width"
        }
        if (this.state.panelSelected === 4) {
          panel4ClassName = "septary-background standard-border padding-20 center-text full-width"
        }
      }

      return (
          <div>
              <TopNavigation currentPage={"workforcePage"}/>

              <header className="career-counselor-header">
                  <div className="hero-text-box">
                    <h1 className="light-text">Workforce</h1>
                    <p>We help workforce development organizations more effectively and efficiently run your work-based learning program.</p>
                    <Link className="btn btn-full" to={'/workforce/checkout'}>Purchase Career-Seeker Licenses</Link>
                    <Link className="btn btn-secondary white-background" to={'/contact'} state={{ reason: "Guided Compass to Work with My Org / School" }}>Contact Us</Link>
                  </div>
              </header>

              <section className="section-features white-background horizontal-padding-3">
                  <div className="row">
                    <h2>How Guided Compass Helps</h2>
                  </div>
                  <div>
                    {(window.innerWidth > 768) && (
                      <div className="relative-column-40 right-padding top-padding">
                        <img src={candidateSelector1} alt="GC" className="image-full-auto" />
                      </div>
                    )}

                    <div className={(window.innerWidth > 768) ? "relative-column-60" : "full-width"}>
                      <div className="row-10">
                        <div className={(window.innerWidth > 768) ? "relative-column-25" : "full-width"}>
                          <button className={(window.innerWidth > 768) ? "background-button full-width" : "background-button full-width"} onClick={() => this.setState({ panelSelected: 1 })}>
                            <div className={panel1ClassName}>
                              <p className="heading-text-2">1</p>
                              <p className="heading-text-4">Clarify Career Pathways</p>
                            </div>
                          </button>
                        </div>

                        <div className={(window.innerWidth > 768) ? "relative-column-25" : "full-width"}>
                          <button className={(window.innerWidth > 768) ? "background-button full-width" : "background-button full-width"} onClick={() => this.setState({ panelSelected: 2 })}>
                            <div className={panel2ClassName}>
                              <p className="heading-text-2">2</p>
                              <p className="heading-text-4">Community & Exploration</p>
                            </div>
                          </button>
                        </div>

                        <div className={(window.innerWidth > 768) ? "relative-column-25" : "full-width"}>
                          <button className={(window.innerWidth > 768) ? "background-button full-width" : "background-button full-width"} onClick={() => this.setState({ panelSelected: 3 })}>
                            <div className={panel3ClassName}>
                              <p className="heading-text-2">3</p>
                              <p className="heading-text-4">Learning & Development</p>
                            </div>
                          </button>
                        </div>

                        <div className={(window.innerWidth > 768) ? "relative-column-25" : "full-width"}>
                          <button className={(window.innerWidth > 768) ? "background-button full-width" : "background-button full-width"} onClick={() => this.setState({ panelSelected: 4 })}>
                            <div className={panel4ClassName}>
                              <p className="heading-text-2">4</p>
                              <p className="heading-text-4">Work Coordinating</p>
                            </div>
                          </button>
                        </div>
                        <div className="clear" />
                      </div>

                      {(this.state.panelSelected === 1 && this.state.panel1Points) && (
                        <div className="bottom-padding">
                          {this.state.panel1Points.map((value, index) =>
                            <div key={value.title} className="top-margin-20">
                              <div className="fixed-column-60 top-margin-5">
                                <img src={opportunitiesIconDark} alt="GC" className="image-auto-40" />
                              </div>
                              <div className="calc-column-offset-60">
                                <p className="heading-text-3">{value.title}</p>
                                <p className="top-padding-5">{value.subtitle}</p>
                              </div>
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      )}

                      {(this.state.panelSelected === 2 && this.state.panel2Points) && (
                        <div className="bottom-padding">
                          {this.state.panel2Points.map((value, index) =>
                            <div key={value.title} className="top-margin-20">
                              <div className="fixed-column-60 top-margin-5">
                                <img src={opportunitiesIconDark} alt="GC" className="image-auto-40" />
                              </div>
                              <div className="calc-column-offset-60">
                                <p className="heading-text-3">{value.title}</p>
                                <p className="top-padding-5">{value.subtitle}</p>
                              </div>
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      )}

                      {(this.state.panelSelected === 3 && this.state.panel3Points) && (
                        <div className="bottom-padding">
                          {this.state.panel3Points.map((value, index) =>
                            <div key={value.title} className="top-margin-20">
                              <div className="fixed-column-60 top-margin-5">
                                <img src={opportunitiesIconDark} alt="GC" className="image-auto-40" />
                              </div>
                              <div className="calc-column-offset-60">
                                <p className="heading-text-3">{value.title}</p>
                                <p className="top-padding-5">{value.subtitle}</p>
                              </div>
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      )}

                      {(this.state.panelSelected === 4 && this.state.panel4Points) && (
                        <div className="bottom-padding">
                          {this.state.panel4Points.map((value, index) =>
                            <div key={value.title} className="top-margin-20">
                              <div className="fixed-column-60 top-margin-5">
                                <img src={opportunitiesIconDark} alt="GC" className="image-auto-40" />
                              </div>
                              <div className="calc-column-offset-60">
                                <p className="heading-text-3">{value.title}</p>
                                <p className="top-padding-5">{value.subtitle}</p>
                              </div>
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="clear" />
                  </div>
              </section>

              <section className="section-workforce">
                <div className="container-left">
                  <p className="heading-text-2 full-width right-text">Want to see the platform in action?</p>
                </div>
                <div className="container-right">
                  <Link to="/help" className="btn white-background stanard-border heading-text-5">View Help Articles</Link>
                </div>
                <div className="clear" />
              </section>

              <section className="section-features white-background">
                  <div className="row">
                      <h2>Benchmarks are the Backbone</h2>
                      <p className="subtitle-container">
                        Guided Compass Benchmarks are customizable, enhanced competency models that go beyond skills to describe the ideal candidate for pathways and work opportunities. Our recommendation algorithms are tied to these Benchmarks.
                      </p>

                      <p className="subtitle-container top-padding-20">
                        Whenever an employer posts, a copy of the "pathway-level" benchmark is attached to the posting and can be customized. For example, a Software Engineering Pathway Benchmark may highly value Python competencies, but a Javascript Developer posting within the pathway may place a relatively lower value on Python competencies. View our <Link to="/help/what-are-benchmarks" target="_blank">Benchmarks Explained Video Here</Link>.
                      </p>

                      <div className="centered-card-image row-30">
                        <img src={exampleInternMatch} alt="Compass save time and money icon" className="image-full-auto" />
                      </div>

                      <div className="row horizontal-padding">
                        <div className="left-padding-20">
                          <p>Some of the customizable match criteria include:</p>
                        </div>
                      </div>

                      <div className="row horizontal-padding">
                        <div className="col span-1-of-3 box">
                          <div className="left-padding-30">
                            <ul>
                              <li>Interests</li>
                              <li>Skills</li>
                              <li>Personality</li>
                              <li>Work Preferences</li>
                            </ul>
                          </div>
                        </div>
                        <div className="col span-1-of-3 box">
                          <div className="left-padding-30">
                            <ul>
                              <li>Interviews / Intangibles</li>
                              <li>Adversity Score</li>
                              <li>Education</li>
                              <li>Employer Interactions</li>
                            </ul>
                          </div>
                        </div>
                        <div className="col span-1-of-3 box">
                          <div className="left-padding-30">
                            <ul>
                              <li>Projects</li>
                              <li>Volunteer Experience</li>
                              <li>Paid Experience</li>
                              <li>Endorsements</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                  </div>
              </section>

              <section className="section-next-generation">
                  <div className="next-generation-container">
                      <div className="next-generation-text-box">
                          <p className="next-generation-title left-text">"85% of jobs in 2030 don’t exist yet."</p>
                          <div className="spacer"/>
                          <p className="next-generation top-padding left-text">- 2017 Dell Report</p>
                      </div>
                  </div>
              </section>

              <section className="section-scenarios white-background">
                <div className="row">
                  <h2>Clients & Partners</h2>
                </div>

                <Clients />

              </section>

              <section className="section-demo light-background">
                  <div className="row">
                      <h2>Customer Success Case Studies</h2>
                      {this.state.caseStudies.map((value, index) =>
                        <div key={value} className="relative-column-33 horizontal-padding top-padding-20">
                          <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, requestMaterials: true, material: value })}>
                            <div className="card full-width">
                              <div className="row-10">
                                <img src={value.image ? value.image : ""} alt="GC" className="image-full-auto standard-border" />
                              </div>
                              <div className="row-10">
                                <p className="heading-text-6">{value.title}</p>
                              </div>
                            </div>
                          </button>
                        </div>
                      )}
                  </div>
              </section>

              <section className="section-join white-background">
                  <div className="join-container">
                      <div className="join-text-box">
                          <p className="join-text">Can Guided Compass help your</p>
                          <p className="join-title right-text">Program?</p>
                      </div>
                      <Link className="btn btn-full join-btn" to={'/contact'} state={{ reason: "Guided Compass to Work with My Org / School" }}>Just Ask</Link>
                  </div>
              </section>


              {/*Footer()*/}
              <Footer history={this.props.navigate} />

              {(this.state.requestMaterials) && (
                <div>
                  <RequestMaterials modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} material={this.state.material}/>
                </div>
              )}
          </div>
      )
    }
}

export default WorkforcePage;
