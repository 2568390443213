import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubEditScheduledMessage from '../Subcomponents/EditScheduledMessage';
import withRouter from '../Functions/WithRouter';

class OrgEditScheduledMessage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      const orgLogo = localStorage.getItem('orgLogo');

      const self = this
      function setInitialState(selectedScheduledMessage) {
        self.setState({ emailId: email, username, cuFirstName, cuLastName, org,
          selectedScheduledMessage, orgLogo
        })
      }

      if (this.props.location && this.props.location.state) {
        console.log('t1')
        setInitialState(this.props.location.state.selectedScheduledMessage)
      } else {

        if (this.props.params && this.props.params.id) {
          console.log('t2')
          // pull message details
          Axios.get('/api/scheduled-messages/byid', { params: { _id: this.props.params.id } })
          .then((response) => {
            if (response.data.success) {
              console.log('Scheduled messaged query worked', response.data);

              setInitialState(response.data.scheduledMessage)

            } else {
              console.log('no scheduled message data found', response.data.message)
              setInitialState()
            }

          }).catch((error) => {
              console.log('Scheduled message query did not work', error);
              setInitialState()
          });
        } else {
          console.log('t3')
          setInitialState()
        }
      }
    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} history={this.props.navigate} />
          <SubEditScheduledMessage selectedScheduledMessage={this.state.selectedScheduledMessage} org={this.state.org} history={this.props.navigate} />

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(OrgEditScheduledMessage)
