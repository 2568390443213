import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { CircularProgressbar } from 'react-circular-progressbar';
import SubAssistantWidget from '../Common/AssistantWidget';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const favoritesIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-grey.png';
const favoritesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-blue.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const linkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon.png';
const pathsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-dark.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const careerIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-blue.png';

class AssessmentDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
          viewIndex: 0,
          subIndex: 1,
          returnLimit: 30,

          nextIndex: 0,
          assessments: [],

          assessmentQuestions: [],

          favorites: [],
          newFavorites: [],
          showSubFunction: true


        }

        this.retrieveData = this.retrieveData.bind(this)
        this.favoriteItem = this.favoriteItem.bind(this)
        this.renderWPData = this.renderWPData.bind(this);
        this.renderMatches = this.renderMatches.bind(this);
        this.segueToNext = this.segueToNext.bind(this);
        this.commandClicked = this.commandClicked.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData(this.props.assessment, this.props.index)
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in assDetails ', this.props.resultsData, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData(this.props.assessment, this.props.index)
      } else if (this.props.index !== prevProps.index) {
        this.retrieveData(this.props.assessment, this.props.index)
      } else if (this.props.assessment !== prevProps.assessment) {
        this.retrieveData(this.props.assessment, this.props.index)
      } else if (this.props.resultsData !== prevProps.resultsData) {
        this.retrieveData(this.props.assessment, this.props.index)
      }
    }

    retrieveData(assessment, index) {
      console.log('retrieveData called within assDetails')

      const assessments = this.props.assessments
      // const index = this.props.index
      // const assessment = this.props.assessment
      const resultsData = this.props.resultsData

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');

      if (assessment) {

        let assessmentTitle = assessment.title
        let assessmentDescription = ''
        // let interestsData = null
        // let skillsData = null
        // let personalityData = null

        let assessmentResults = null
        let assessmentCommands = []

        if (assessmentTitle === 'Work Preferences') {
          assessmentDescription = 'Self-assesses your fit to current and similar work'
          if (resultsData && resultsData[0]) {
            // console.log('show the data: ', resultsData)
            assessmentResults = resultsData[0]

            let functionText = ''
            if (resultsData[0][0] && resultsData[0][0].length > 0) {
              let functionArray = resultsData[0][0]
              if (typeof functionArray === 'string') {
                // console.log('functionArray is a string')
                functionArray = functionArray.replace('[\"',"")
                functionArray = functionArray.replace(']',"")
                // functionArray = functionArray.replace(/\\/g, "")
                functionArray = functionArray.split("\",\"")
                // console.log('functionArray after: ', functionArray)
              }
              for (let i = 1; i <= functionArray.length; i++) {
                let tempFunction = functionArray[i - 1]
                // console.log('show tempFunction: ', tempFunction)
                if (tempFunction && functionText === '') {
                  functionText = tempFunction.toLowerCase()
                } else {
                  // dont worry about stringing together right now
                }
              }
            }

            console.log('show the functionText: ', functionText)

            assessmentCommands = [
              'What career paths would I would do ' + functionText + ' for a living?',
              'What are some top employers for completing ' + functionText + ' tasks?',
              'What kind of salary could I command for doing ' + functionText + '? And who / what company would pay me that salary?',
            ]
          }
        } else if (assessmentTitle === 'Interest Assessment') {
          assessmentDescription = 'Assesses what category of work you may be most interested in'
          if (resultsData && resultsData[1] && resultsData[1].length > 0) {
            assessmentResults = resultsData[1]

            let highScore = { name: '', score: 0 }
            let lowScore = { name: '', score: 1000 }

            for (let i = 1; i <= resultsData[1].length; i++) {
              let tempInterest = resultsData[1][i - 1]
              console.log('show tempInterest: ', tempInterest)

              if (tempInterest && tempInterest.score && tempInterest.score > highScore.score) {
                console.log('????')
                highScore = { name: tempInterest.name.toLowerCase(), score: tempInterest.score }
              }

              if (tempInterest && tempInterest.score && tempInterest.score < lowScore.score) {
                lowScore = { name: tempInterest.name.toLowerCase(), score: tempInterest.score }
              }
            }

            // let relativeScore = ((Number(this.state.resultsData[1][i].score) / 40).toFixed(2) * 100).toString() + '%'
            if (highScore.score) {
              assessmentCommands.push('What career paths would I be interested in as someone who scored high in ' + highScore.name + ' for my strong interest inventory / holland code?')
            }

            if (lowScore.score < 1000) {
              assessmentCommands.push('What career paths would I be interested in as someone who scored low in ' + lowScore.name + ' for my strong interest inventory / holland code?')
            }

            if (highScore.score) {
              assessmentCommands.push('As someone who is high in ' + highScore.name + ' for my strong interest inventory / holland code, can you please list some employers that would hire me at a six-figure salary?')
            }

          }
        } else if (assessmentTitle === 'Skill Self-Assessment') {
          assessmentDescription = 'Self-assessment of hard and soft skills associated with different career paths'
          if (resultsData && resultsData[3] && resultsData[3].length > 0) {
            assessmentResults = resultsData[3]

            let highScore = { name: '', score: 0 }
            if (resultsData[3][0].score) {
              console.log('show tempSkill 1: ', resultsData[3][0])
              highScore = { name: resultsData[3][0].name.toLowerCase(), score: resultsData[3][0].score   }
            }
            // for (let i = 1; i <= resultsData[3].length; i++) {
            //   let tempSkill = resultsData[3][i - 1]
            //   console.log('show tempSkill: ', tempSkill)
            //
            //   if (tempSkill && tempSkill.score && tempSkill.score > highScore.score) {
            //     console.log('????')
            //     highScore = { name: tempSkill.name.toLowerCase(), score: tempSkill.score }
            //   }
            // }

            // let relativeScore = ((Number(this.state.resultsData[1][i].score) / 40).toFixed(2) * 100).toString() + '%'
            if (highScore.score) {
              assessmentCommands.push('What career paths would I be interested in as someone who is highly skilled at ' + highScore.name + '?')
              assessmentCommands.push('As someone who is highly skilled at ' + highScore.name + ', can you please list some employers that would hire me at a six-figure salary?')
              assessmentCommands.push('As someone who is highly skilled at ' + highScore.name + ', what are some business ideas to take advantage of this skill?')
            }
          }
        } else if (assessmentTitle === 'Personality Assessment') {
          assessmentDescription = 'Assesses your personality type relative to different career paths'
          if (resultsData && resultsData[2]) {
            assessmentResults = resultsData[2]

            assessmentCommands = [
              'What career paths would I be interested in as a ' + assessmentResults.myersBriggs + ' Myers Briggs type?',
              'What kind of work environment would I prefer as a ' + assessmentResults.myersBriggs + ' Myers Briggs type?',
              'What employers may I prefer as a ' + assessmentResults.myersBriggs + ' Myers Briggs type?',
            ]
          }
        } else if (assessmentTitle === 'Values Assessment') {
          assessmentDescription = 'Assesses your values and the values of your ideal employer'
          if (resultsData && resultsData[4] && resultsData[4].length > 0) {
            assessmentResults = resultsData[4]

            let highScore = { name: null }
            if (resultsData[4][0].value) {
              highScore = { name: resultsData[4][0].value.toLowerCase() }
            }
            if (highScore.name) {
              assessmentCommands.push('What career paths would I be interested in as someone who highly values people who are ' + highScore.name + '?')
              assessmentCommands.push('What kind of work environment would I prefer as someone who highly values people who are  ' + highScore.name + '?')
              assessmentCommands.push('What employers may I prefer as someone who highly values people who are ' + highScore.name + '?')
            }

            console.log('dfsdfsdf', resultsData[4])
          }
        }

        let nextIndex = index + 1
        if (nextIndex > assessments.length - 1) {
          nextIndex = 0
        }

        if (resultsData && resultsData[index] && resultsData[index].length !== undefined && resultsData[index].length === 0) {
          resultsData[index] = null
        }

        let prefix = '/app/'
        if (window.location.pathname.startsWith('/advisor')) {
          prefix = '/advisor/'
        }

        // console.log('in assessment: ', assessmentTitle)
        this.setState({ emailId: email, username, cuFirstName, cuLastName, activeOrg, orgFocus, assessmentTitle, assessmentDescription,
        resultsData, assessmentResults, assessments, index, nextIndex, assessment, assessmentCommands, prefix })

        Axios.get('/api/assessment/questions')
        .then((response) => {
            //console.log('O*Net worked', response.data.body, this);
            console.log('show me response', response.data)
            if ( response.data.success === true ) {
              console.log('assessment query worked')

              let assessmentQuestions = []

              for (let i = 1; i <= response.data.assessments.workPreferenceQuestions.length; i++) {
                assessmentQuestions.push(response.data.assessments.workPreferenceQuestions[i - 1].name)
              }

              this.setState({ assessmentQuestions })

            } else {
              console.log('Assessment question query failed', response.data.message)
            }

        }).catch((error) => {
            console.log('Assessment question query did not work', error);
        });

        Axios.get('/api/favorites', { params: { emailId: email } })
       .then((response) => {
         console.log('Favorites query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved favorites')

             const favorites = response.data.favorites

             let newFavorites = []
             if (response.data.newFavorites) {
               newFavorites = response.data.newFavorites
             }

             this.setState({ favorites, newFavorites })
           } else {
             console.log('no favorites data found', response.data.message)
           }

       }).catch((error) => {
           console.log('Favorites query did not work', error);
       });

       Axios.get('/api/users/profile/details', { params: { email } })
       .then((response) => {
        console.log('User details query 1 attempted', response.data);

        if (response.data.success) {
           console.log('successfully retrieved user details')

           const topCareerMatches = response.data.user.topCareerMatches
           // const employerMatches = response.data.user.topEmployerMatches
           // const courseMatches = response.data.user.topCourseMatches
           // const eventMatches = response.data.user.topEventMatches
           // const projectMatches = response.data.user.topProjectMatches
           const topWorkMatches = response.data.user.topWorkMatches
           // const peerMatches = response.data.user.topPeerMatches
           // const mentorMatches = response.data.user.topMentorMatches
           // const groupMatches = response.data.user.topGroupMatches

           this.setState({ topCareerMatches, topWorkMatches })

        } else {
         console.log('no user details data found', response.data.message)
        }

      }).catch((error) => {
         console.log('User details query did not work', error);
      });

      Axios.get('/api/org', { params: { orgCode: activeOrg } })
      .then((response) => {
        console.log('Org info query attempted', response.data);

        if (response.data.success) {
          console.log('org info query worked in editLog')

          const disableChatbotPrompts = response.data.orgInfo.disableChatbotPrompts
          // console.log('askfajldkfajsldkfaldsf', disableChatbotPrompts)
          this.setState({ disableChatbotPrompts });

        } else {
          console.log('org info query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });

        Axios.get('/api/assessment/careers', { params: { emailId: email } })
        .then((response) => {
          console.log('Career matches query tried from database, assessmentDetails screen');

          if (response.data.success) {
            console.log('Career matches query succeeded from database, assessmentDetails screen', response.data)

            let matches = null
            let employerMatches = null
            let workMatches = null

            if (assessmentTitle === 'Work Preferences') {
              if (response.data.matches.arePreferenceCareerMatches) {
                matches = response.data.matches.preferenceCareerMatches
                employerMatches = response.data.matches.preferenceEmployerMatches
                console.log('show employerMatches ', response.data.matches.preferencesWorkMatches)
                if (response.data.matches.preferencesWorkMatches) {
                  if (typeof response.data.matches.preferenceWorkMatches[0].work === 'string') {
                    console.log('this work match a string ', response.data.matches.preferenceWorkMatches[0].work)

                    workMatches = []
                    for (let i = 0; i < response.data.matches.preferenceWorkMatches.length; i++) {
                      const workObject = JSON.parse(response.data.matches.preferenceWorkMatches[i].work)
                      workMatches.push({ work: workObject, differential: response.data.matches.preferenceWorkMatches[i].differential})
                    }
                    //workMatches = response.data.matches.interestWorkMatches
                  } else if (response.data.matches.preferenceWorkMatches[0].work) {
                    console.log('is there preferenceWorkmatches? ', response.data.matches.preferenceWorkMatches)
                    workMatches = response.data.matches.preferenceWorkMatches
                  }
                }
              }
            } else if (assessmentTitle === 'Interest Assessment') {
              if (response.data.matches.areInterestCareerMatches) {
                matches = response.data.matches.interestCareerMatches
                employerMatches = response.data.matches.interestEmployerMatches

                if (typeof response.data.matches.interestWorkMatches[0].work === 'string') {
                  console.log('this work match a string ', response.data.matches.interestWorkMatches[0].work)

                  workMatches = []
                  for (let i = 0; i < response.data.matches.interestWorkMatches.length; i++) {
                    const workObject = JSON.parse(response.data.matches.interestWorkMatches[i].work)
                    workMatches.push({ work: workObject, differential: response.data.matches.interestWorkMatches[i].differential})
                  }
                  //workMatches = response.data.matches.interestWorkMatches
                } else if (response.data.matches.interestWorkMatches[0].work) {
                  console.log('is there interestWorkmatches? ', response.data.matches.interestWorkMatches)
                  workMatches = response.data.matches.interestWorkMatches
                }
              }
            } else if (assessmentTitle === 'Skill Self-Assessment') {
              if (response.data.matches.areSkillCareerMatches) {
                matches = response.data.matches.skillCareerMatches
                employerMatches = response.data.matches.skillEmployerMatches
                workMatches = response.data.matches.skillWorkMatches
              }
            } else if (assessmentTitle === 'Personality Assessment') {
              if (response.data.matches.arePersonalityCareerMatches) {
                matches = response.data.matches.personalityCareerMatches
                employerMatches = response.data.matches.personalityEmployerMatches
                workMatches = response.data.matches.personalityWorkMatches
              }

            }

            this.setState({ matches, employerMatches, workMatches })

          } else {
            console.log('no career match data found', response.data.message)

          }
        }).catch((error) => {
            console.log('query for assessment career matches did not work from database', error);
        })
      } else {
        console.log('user navigated directly to this screen')
      }
    }

    async favoriteItem(item) {
      console.log('favoriteItem called', item)

      if (this.state.showSubFunction) {

        const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,"person")

        if (returnedObject.success) {
          this.setState({ serverSuccessPlan: true, serverSuccessMessagePlan: returnedObject.message, favoritesArray: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites })
        } else {
          this.setState({ serverSuccessPlan: false, serverSuccessMessagePlan: returnedObject.message })
        }

      } else {

        let itemId = ''
        if (this.state.viewIndex === 0) {
          itemId = item.partner._id
        } else if (this.state.viewIndex === 1) {
          itemId = item.career._id
        } else if (this.state.viewIndex === 2) {
          itemId = item.pathway._id
        } else if (this.state.viewIndex === 3) {
          console.log('show employer info: ', item.employer)
          itemId = item.employer._id
        } else if (this.state.viewIndex === 4) {
          itemId = item.work._id
        }

        let favoritesArray = this.state.favorites

        if (favoritesArray.includes(itemId)){

          let index = favoritesArray.indexOf(itemId)
          if (index > -1) {
            favoritesArray.splice(index, 1);
          }

          Axios.post('/api/favorites/save', {
            favoritesArray, emailId: this.state.emailId
          })
          .then((response) => {
            console.log('attempting to save favorites')
            if (response.data.success) {
              console.log('saved successfully', response.data)
              //clear values
              this.setState({
                serverSuccessPlan: true,
                serverSuccessMessagePlan: 'Favorite saved!'
              })
            } else {
              console.log('did not save successfully')
              this.setState({
                serverSuccessPlan: false,
                serverErrorMessagePlan: 'error:' + response.data.message
              })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({
                serverSuccessPlan: false,
                serverErrorMessagePlan: 'there was an error saving favorites'
              })
          });

        } else {
          console.log('adding item: ', favoritesArray, itemId)
          favoritesArray.push(itemId)
          Axios.post('/api/favorites/save', {
            favoritesArray, emailId: this.state.emailId
          })
          .then((response) => {
            console.log('attempting to save favorites')
            if (response.data.success) {
              console.log('saved successfully', response.data)
              //clear values
              this.setState({
                serverSuccessPlan: true,
                serverSuccessMessagePlan: 'Favorite saved!'
              })
            } else {
              console.log('did not save successfully')
              this.setState({
                serverSuccessPlan: false,
                serverErrorMessagePlan: 'error:' + response.data.message
              })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({
                serverSuccessPlan: false,
                serverErrorMessagePlan: 'there was an error saving favorites'
              })
          });
        }

        console.log('favorites', favoritesArray)
        this.setState({ favorites: favoritesArray })
      }
    }

    renderResults() {
      console.log('renderResults called', this.state.assessmentTitle, this.state.resultsData)

      let rows = []

      if (this.state.resultsData) {

        if (this.state.assessmentTitle === 'Work Preferences') {
            console.log('testing 4')
            if (this.state.resultsData[0]) {
              rows.push(
                <div key={0}>
                  <div className="spacer" /><div className="spacer" />

                  {this.renderWPData(this.state.resultsData[0])}
                </div>
              )
            } else {
              console.log('there is no data')
              rows.push(
                <div key={0} className="top-margin-30">
                  <p className="center-text">View your results here after you take the assessment</p>
                </div>
              )
            }
        } else if (this.state.assessmentTitle === 'Interest Assessment') {
          console.log('gimme results', this.state.resultsData)

          if (this.state.resultsData[1]) {
            if (this.state.resultsData[1].length !== 0) {
              //const totalScore = Number(this.state.results[0].score) + Number(this.state.results[1].score) + Number(this.state.results[2].score) + Number(this.state.results[3].score) + Number(this.state.results[4].score) + Number(this.state.results[5].score)
              rows.push(
                <div key={0}>
                  <div className="spacer" /><div className="spacer" />
                </div>
              )
              for (let i = 0; i < this.state.resultsData[1].length; i++) {
                console.log('gimme i value for filters', i)
                let relativeScore = ((Number(this.state.resultsData[1][i].score) / 40).toFixed(2) * 100).toString() + '%'
                rows.push(
                  <div key={i} className="row-20">
                    <p className="heading-text-5 bottom-padding">{this.state.resultsData[1][i].name}</p>

                    <p className="heading-text-2 cta-color bottom-padding">{relativeScore}</p>
                    <p>{this.state.resultsData[1][i].description}</p>
                  </div>
                )
              }
            }
          } else {
            console.log('there is no data')
            rows.push(
              <div key={0} className="top-margin-30">
                <p className="center-text">View your results here after you take the assessment</p>
              </div>
            )
          }
        } else if (this.state.assessmentTitle === 'Personality Assessment'){
          //personality assessments
          console.log('in personality assessment')
          if (this.state.resultsData[2]) {
            rows.push(
              <div key={0} className="row-20">
                <div className="spacer" /><div className="spacer" />
                <p className="heading-text-4 bottom-padding">16 Personalities</p>
                <p>Learn about your type here: <a target="_blank" rel="noopener noreferrer" href={"https://www.16personalities.com/" + this.state.resultsData[2].myersBriggs + "-personality"}>https://www.16personalities.com/{this.state.resultsData[2].myersBriggs}-personality</a></p>
                <p className="heading-text-2 cta-color">{this.state.resultsData[2].myersBriggs}</p>

                <div className="spacer" />

                <p className="heading-text-4 row-10">Five Factors</p>
                <p className="">Learn about the big five personality traits: <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Big_Five_personality_traits">https://en.wikipedia.org/wiki/Big_Five_personality_traits</a></p>
                <p className="heading-text-6 row-10">Extraversion Score: <label className="cta-color">{((this.state.resultsData[2].fiveFactors.extraversionScore / 16)*100).toString() + '%'}</label></p>
                <p className="heading-text-6 row-10">Openness Score: <label className="cta-color">{((this.state.resultsData[2].fiveFactors.opennessScore / 16)*100).toString() + '%'}</label></p>
                <p className="heading-text-6 row-10">Conscientiousness Score: <label className="cta-color">{((this.state.resultsData[2].fiveFactors.conscientiousnessScore / 16)*100).toString() + '%'}</label></p>
                <p className="heading-text-6 row-10">Agreeableness Score: <label className="cta-color">{((this.state.resultsData[2].fiveFactors.agreeablenessScore / 16)*100).toString() + '%'}</label></p>
                <p className="heading-text-6 row-10">Neuroticism Score: <label className="cta-color">{((this.state.resultsData[2].fiveFactors.neuroticismScore / 16)*100).toString() + '%'}</label></p>
              </div>
            )
          } else {
            console.log('there is no data')
            rows.push(
              <div key={0} className="top-margin-30">
                <p className="center-text">View your results here after you take the assessment</p>
              </div>
            )
          }
        } else if (this.state.assessmentTitle === 'Values Assessment') {
          console.log('gimme results', this.state.resultsData)

          if (this.state.resultsData[4]) {
            if (this.state.resultsData[4].length !== 0) {
              console.log('in values')
              //const totalScore = Number(this.state.results[0].score) + Number(this.state.results[1].score) + Number(this.state.results[2].score) + Number(this.state.results[3].score) + Number(this.state.results[4].score) + Number(this.state.results[5].score)
              rows.push(
                <div key={0}>
                  <div className="spacer"/><div className="spacer"/>
                </div>
              )
              rows.push(
                <div key={"gravitateValuesTitle"}>
                  <p className="heading-text-4">Top Types of People You Gravitate Towards</p>
                </div>
              )

              let gravitateCounter = 1
              function renderValue(passedData, index) {
                console.log('called renderValue 1')
                if (passedData.valueType) {
                  if (passedData.valueType === 'gravitate') {
                    rows.push(
                      <div key={"gravitateValues" + index} className="row-5 heading-text-6">
                        <p>{gravitateCounter}. {passedData.value}</p>
                      </div>
                    )
                    gravitateCounter = gravitateCounter + 1
                  }
                }
              }

              for (let i = 0; i < this.state.resultsData[4].length; i++) {
                console.log('looping values 1', i, typeof this.state.resultsData[4][i])

                if (typeof this.state.resultsData[4][i] === 'string') {
                  renderValue(JSON.parse(this.state.resultsData[4][i]),i)
                } else {
                  renderValue(this.state.resultsData[4][i],i)
                }
              }

              rows.push(
                <div key={"employerValuesTitle"}>
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <p className="heading-text-4">Top Things You Value In Employers</p>
                </div>
              )

              let employerCounter = 1
              function renderValueTwo(passedData, index) {
                console.log('called renderValue 2')
                if (passedData.valueType) {
                  if (passedData.valueType === 'employer') {
                    rows.push(
                      <div key={"employerValues" + index} className="row-5 heading-text-6">
                        <p>{employerCounter}. {passedData.value}</p>
                      </div>
                    )
                    employerCounter = employerCounter + 1
                  }
                }
              }

              for (let i = 0; i < this.state.resultsData[4].length; i++) {
                console.log('looping values 2', i, this.state.resultsData[4][i].valueType)

                if (typeof this.state.resultsData[4][i] === 'string') {
                  renderValueTwo(JSON.parse(this.state.resultsData[4][i]), i)
                } else {
                  renderValueTwo(this.state.resultsData[4][i],i)
                }
              }
            }
          } else {
            console.log('there is no data')
            rows.push(
              <div key={0} className="top-margin-30">
                <p className="center-text">View your results here after you take the assessment</p>
              </div>
            )
          }
        } else if (this.state.assessmentTitle === 'Skill Self-Assessment') {
          console.log('testing 3')
          if (this.state.resultsData[3] && this.state.resultsData[3].length > 0) {

            function tagBackgroundConverter(score) {
              console.log('tagBackgroundConverter called', score)

              let backgroundColorClass = 'quinary-background'
              if (score === 1) {
                backgroundColorClass = 'quinary-background-light'
              } else if (score === 2) {
                backgroundColorClass = 'tertiary-background-light'
              } else if (score === 3) {
                backgroundColorClass = 'secondary-background-light'
              } else if (score === 4) {
                backgroundColorClass = 'primary-background-light'
              } else if (score === 5) {
                backgroundColorClass = 'senary-background-light'
              }

              return backgroundColorClass
            }
            rows.push(
              <div key={0}>
                <div className="spacer" /><div className="spacer" />
                <p className="heading-text-5">Top Skills</p>
                <p className="description-text-color description-text-1">(Ranked from best to worst)</p>

                <div className="top-padding">
                  {this.state.resultsData[3].map((value, optionIndex) =>
                    <div key={value}>
                      {(this.state.resultsData[3][optionIndex].score && this.state.resultsData[3][optionIndex].score > 0) && (
                        <div className={"row-15 horizontal-padding-5 slightly-rounded-corners float-left right-margin top-margin description-text-2 bold-text " + tagBackgroundConverter(this.state.resultsData[3][optionIndex].score)}>
                          <p>{this.state.resultsData[3][optionIndex].name} - {this.state.resultsData[3][optionIndex].score}</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="clear" />
              </div>
            )
          } else {
            console.log('there is no data')
            rows.push(
              <div key={0} className="top-margin-30">
                <p className="center-text">View your results here after you take the assessment</p>
              </div>
            )
          }
        } else {
          console.log('there is some other assessmentTitle: ', this.state.assessmentTitle)
        }
      }

      return rows
    }

    renderWPData(wp) {
      console.log('renderWPData called', wp)

      let rows = []

      for (let i = 0; i < wp.length; i++) {

        let answer = ''
        if (wp[i]) {
          if (wp[i][0] === '[') {
            console.log('test 1er')
            const removedArrayEnds = wp[i].substring(1,wp[i].length - 1).replace(/"/g,"").replace(/"/g,"").replace(/,/g,", ")
            answer = removedArrayEnds
          } else if (typeof wp[i] === 'object') {
            console.log('test 3er', typeof wp[i], wp[i])
            answer = wp[i].join(", ")
          } else {
            console.log('test 2er', typeof wp[i], wp[i])
            answer = wp[i]
          }

          rows.push(
            <div key={'wpData' + i.toString()} className="row-5">
              {(this.state.assessmentQuestions[i] && wp[i]) && (
                <div>
                  <p className="description-text-2">{this.state.assessmentQuestions[i]}</p>
                  <div className="clear"/>
                  <div className="half-spacer" />
                  <p>{answer}</p>
                  <div className="spacer" /><div className="spacer" />
                </div>
              )}
            </div>
          )
        }
      }

      return rows
    }

    renderMatches() {
      console.log('renderMatches called')

      let rows = []

      if (this.state.subIndex === 0) {
        //mentors
        if (this.state.mentorMatches) {
          for (let i = 1; i <= this.state.mentorMatches.length; i++) {
            console.log(i);

            let match = 'N/A'
            if (Math.sign(100 - this.state.mentorMatches[i - 1].differential) !== -1) {
              match = (100 - Number(this.state.mentorMatches[i - 1].differential)).toFixed(0).toString() + '%'
            }

            let workLine = ''
            if (this.state.mentorMatches[i - 1].partner.targetJobTitle) {
              if (workLine === '') {
                workLine = this.state.mentorMatches[i - 1].partner.targetJobTitle
              } else {
                workLine = workLine + ' | ' + this.state.mentorMatches[i - 1].partner.targetJobTitle
              }
            }

            if (this.state.mentorMatches[i - 1].partner.targetEmployerName) {
              if (workLine === '') {
                workLine = this.state.mentorMatches[i - 1].partner.targetEmployerName
              } else {
                workLine = workLine + ' | ' + this.state.mentorMatches[i - 1].partner.targetEmployerName
              }
            }

            if (workLine === '') {
              workLine = this.state.mentorMatches[i - 1].partner.firstName + ' has not entered their work information yet'
            }

            rows.push(
              <div key={i}>
                <div className="spacer" />

                <Link to={'/advisor/profile/' + this.state.mentorMatches[i - 1].partner.username} state={{ selectedMentor: this.state.mentorMatches[i - 1], source: 'Student' }} className="background-link">
                  <div className="fixed-column-60">
                    <p className="heading-text-2 cta-color">{match}</p>
                  </div>
                  <span className="table-row-header-content-3 curtail-text calc-column-offset-140">
                    <div className="table-row-content-title-3 heading-text-5 curtail-text">
                      <p className="heading-text-6">{this.state.mentorMatches[i - 1].partner.firstName} {this.state.mentorMatches[i - 1].partner.lastName}</p>
                    </div>

                    <div className="clear" />
                    <div>
                      <p className="description-text-1 curtail-text">{workLine}</p>
                    </div>
                  </span>
                </Link>
                <div className="float-left">
                  <span className="float-right width-30">
                    <div className="spacer" /><div className="half-spacer" />
                    <Link to={'/advisor/profile/' + this.state.mentorMatches[i - 1].partner.username} state={{ selectedMentor: this.state.mentorMatches[i - 1] }} className="background-link">
                      <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                    </Link>
                  </span>
                  <span className="float-right width-50">
                    <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                    <button className="btn background-button" onClick={() => this.favoriteItem(this.state.mentorMatches[i - 1]) }>
                      <img src={(this.state.favorites.includes(this.state.mentorMatches[i - 1].partner._id)) ? favoritesIconBlue : favoritesIconGrey} alt="Compass arrow indicator icon" className="image-auto-20"/>
                    </button>
                  </span>
                </div>
                <div className="clear"/>
                <div className="spacer" /><div className="spacer" />
                <hr className="clear-margin clear-padding"/>
                <div className="clear"/>
                <div className="spacer" />
              </div>
            )
          }
        }
      } else if (this.state.subIndex === 1) {
        //careers
        if (this.state.matches) {

          for (let i = 1; i <= this.state.matches.length; i++) {

            const index = i - 1
            const item = this.state.matches[index]

            if (item) {

              if (i < this.state.returnLimit) {
                const matchScore = (100 - item.differential).toFixed(0) + '%'

                rows.push(
                  <div key={index}>
                    <div className="spacer" /><div className="spacer" />

                    <div>
                      <Link to={'/app/careers/' + item.career.name} className="background-link">
                        <span className="fixed-column-60">
                          <label className="heading-text-2">{matchScore}</label>
                        </span>
                        <span className="table-row-header-content-3 curtail-text calc-column-offset-140">
                          <div className="table-row-content-title-3 heading-text-5 curtail-text">
                            <label>{item.career.name}</label>
                          </div>

                          <div className="clear" />
                          <div>
                            <p className="description-text-1 curtail-text">{item.career.jobFamily}</p>
                          </div>
                        </span>
                      </Link>
                      <div className="float-left">
                        <span className="float-right width-30">
                          <div className="spacer" /><div className="half-spacer" />
                          <Link to={'/app/careers/' + item.career._id} className="background-link">
                            <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                          </Link>
                        </span>
                        <span className="float-right width-50">
                          <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                          <button className="btn background-button" onClick={() => this.favoriteItem(item) }>
                            <img src={(this.state.favorites.includes(item.career._id)) ? favoritesIconBlue : favoritesIconGrey} alt="Compass arrow indicator icon" className="image-auto-20" />
                          </button>
                        </span>
                      </div>
                    </div>
                    <div className="clear" />
                  </div>
                )
              }
            } else {
              console.log('there is no item for some reason: ', item)
            }
          }
        }
      } else if (this.state.subIndex === 2) {
        //employers
        if (this.state.employerMatches) {
          for (let i = 1; i <= this.state.employerMatches.length; i++) {

            const index = i - 1
            const item = this.state.employerMatches[index]

            if (item) {
              const matchScore = (100 - item.differential).toFixed(0) + '%'

              if (i < this.state.returnLimit) {

                if (item.employer.isExternal) {
                  rows.push(
                    <div key={index}>
                      <div className="spacer" /><div className="spacer" />

                      <div>
                        <a href={item.employer.url} className="background-link">
                          <span className="fixed-column-60">
                            <label className="heading-text-2">{matchScore}</label>
                          </span>
                          <span className="table-row-header-content-3 curtail-text calc-column-offset-140">
                            <div className="table-row-content-title-3 heading-text-5 curtail-text">
                              <label>{item.employer.name}</label>
                            </div>

                            <div className="clear" />
                            <div>
                              <p className="description-text-1 curtail-text">{item.employer.url}</p>
                            </div>

                          </span>
                        </a>
                        <div className="float-left">
                          <span className="float-right width-30">
                            <div className="spacer" /><div className="half-spacer" />
                            <a href={item.employer.url} className="background-link">
                              <img src={linkIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                            </a>
                          </span>
                          <span className="float-right width-50">
                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                            <button className="btn background-button" onClick={() => this.favoriteItem(item) }>
                              <img src={(this.state.favorites.includes(item.employer._id)) ? favoritesIconBlue : favoritesIconGrey} alt="Compass arrow indicator icon" className="image-auto-20"/>
                            </button>
                          </span>
                        </div>
                      </div>

                      <div className="clear" />

                    </div>
                  )
                } else {

                  rows.push(
                    <div key={index}>
                      <div className="spacer" /><div className="spacer" />

                      <div>
                        <Link to={'/app/employers/' + item.employer._id} state={{ employerDetails: item }} className="background-link">
                          <span className="fixed-column-60">
                            <label className="heading-text-2">{matchScore}</label>
                          </span>
                          <span className="table-row-header-content-3 curtail-text calc-column-offset-140">
                            <div className="table-row-content-title-3 heading-text-5 curtail-text">
                              <label>{item.employer.name}</label>
                            </div>

                            <div className="clear" />
                            <div>
                              <p className="description-text-1 curtail-text">{item.employer.url}</p>
                            </div>>
                          </span>
                        </Link>
                        <div className="float-left">
                          <span className="float-right width-30">
                            <div className="spacer" /><div className="half-spacer" />
                            <Link to={'/app/employers/' + item.employer._id} state={{ employerDetails: item }} className="background-link">
                              <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                            </Link>
                          </span>
                          <span className="float-right width-50">
                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                            <button className="btn background-button" onClick={() => this.favoriteItem(item) }>
                              <img src={(this.state.favorites.includes(item.employer._id)) ? favoritesIconBlue : favoritesIconGrey} alt="Compass arrow indicator icon" className="image-auto-20"/>
                            </button>
                          </span>
                        </div>
                      </div>

                      <div className="clear" />
                    </div>
                  )
                }
              }
            }
          }
        }
      } else if (this.state.subIndex === 3) {
        //internships
        if (this.state.workMatches) {
          for (let i = 1; i <= this.state.workMatches.length; i++) {

            const index = i - 1
            const item = this.state.workMatches[index]
            console.log('show combined work matches ', this.state.workMatches[i - 1])
            if (item) {
              const matchScore = (100 - item.differential).toFixed(0) + '%'

              if (i < this.state.returnLimit) {

                if (item.work.isExternal) {
                  rows.push(
                    <div key={index}>
                      <div className="spacer" /><div className="spacer" />

                      <div>
                        <a href={item.work.jobLink} className="background-link">
                          <span className="fixed-column-50">
                            <label className="heading-text-2">{matchScore}</label>
                          </span>
                          <span className="table-row-header-content-3 curtail-text calc-column-offset-140">
                            <div className="table-row-content-title-3 heading-text-5 curtail-text">
                              <label>{item.work.title}</label>
                            </div>

                            <div className="clear" />
                            <div>
                              <p className="description-text-1 curtail-text">{item.work.employerName}</p>
                            </div>
                          </span>
                        </a>
                        <div className="float-left">
                          <span className="float-right width-30">
                            <div className="spacer" /><div className="half-spacer" />
                            <a href={item.work.jobLink} className="background-link">
                              <img src={linkIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                            </a>
                          </span>
                          <span className="float-right width-50">
                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                            <button className="btn background-button" onClick={() => this.favoriteItem(item) }>
                              <img src={(this.state.favorites.includes(item.work._id)) ? favoritesIconBlue : favoritesIconGrey} alt="Compass arrow indicator icon" className="image-auto-20"/>
                            </button>
                          </span>
                        </div>
                      </div>

                      <div className="clear" />

                    </div>
                  )
                } else {

                  rows.push(
                    <div key={index}>
                      <div className="spacer" /><div className="spacer" />

                      <div>
                        <Link to={'/app/opportunities/' + item.work._id} state={{ objectId: item.work._id }} className="background-link">
                          <span className="fixed-column-60">
                            <label className="heading-text-2">{matchScore}</label>
                          </span>


                          <span className="table-row-header-content-3 curtail-text calc-column-offset-140">
                            <div className="table-row-content-title-3 heading-text-5 curtail-text">
                              <label>{item.work.title}</label>
                            </div>

                            <div className="clear" />
                            <div>
                              <p className="description-text-1 curtail-text">{item.work.employerName}</p>
                            </div>
                          </span>
                        </Link>
                        <div className="float-left">
                          <span className="float-right width-30">
                            <div className="spacer" /><div className="half-spacer" />
                            <Link to={'/app/opportunities/' + item.work._id} state={{ objectId: item._id }} className="background-link">
                              <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                            </Link>
                          </span>
                          <span className="float-right width-50">
                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                            <button className="btn background-button" onClick={() => this.favoriteItem(item) }>
                              <img src={(this.state.favorites.includes(item.work._id)) ? favoritesIconBlue : favoritesIconGrey} alt="Compass arrow indicator icon" className="image-auto-20"/>
                            </button>
                          </span>
                        </div>
                      </div>
                      <div className="clear" />
                    </div>
                  )
                }
              }
            }
          }
        }
      }

      return rows
    }

    segueToNext() {
      console.log('segueToNext called', this.state.assessments[this.state.nextIndex])

      this.retrieveData(this.state.assessments[this.state.nextIndex], this.state.nextIndex)
    }

    commandClicked(command) {
      console.log('commandClicked called')

      this.props.commandClicked(command)

    }

    render() {

      let assessmentsPath = '/app/assessments'
      let assessmentDetailsPath = '/app/assessments/assessment-details'
      if (window.location.pathname.includes('/advisor')) {
        assessmentsPath = '/advisor/assessments'
        assessmentDetailsPath = '/advisor/career-assessments/assessment-details'
      }

      let showCompassAssistant = true
      // if (toggleVars.demoMode || (this.state.activeOrg !== 'fosternation' && this.state.activeOrg !== 'unite-la')) {
      //   showCompassAssistant = true
      // }

      let mainClass = "standard-container-2"
      let subClass = "full-width"
      if (window.innerWidth > 768 && this.state.assessmentResults && showCompassAssistant) {
        mainClass = "relative-column-70 padding-five-percent-left"
        subClass = "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right"
      }

      const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primaryColor');

      return (
        <div>
          <div className={mainClass}>
            <div className={subClass}>
              {(window.innerWidth > 768 && showCompassAssistant && this.state.assessmentResults) && (
                <div className="full-width height-5 primary-background" />
              )}

              <div className={(showCompassAssistant && this.state.assessmentResults) ? "padding-40" : ""}>
                {this.state.assessmentTitle && (
                  <div>
                    <div>
                      <div className="spacer" />
                      <div className="float-left">
                        <div className="half-spacer" /><div className="mini-spacer" />
                        <Link className="secret-link" to={assessmentsPath}>
                          <div>
                            <img src={arrowIndicatorIcon} alt="GC" className="image-auto-14 rotate-180"/>
                          </div>
                        </Link>
                      </div>

                      <div className="float-left">
                        <Link className="secret-link" to={assessmentsPath}>
                          <div>
                            <label className="description-text-1 left-padding">Back to Assessments</label>
                          </div>
                        </Link>
                      </div>
                      <div>
                        <div className="float-right">
                          <div className="mini-spacer" /><div className="mini-spacer" />

                          <button className="background-button" onClick={() => this.segueToNext()}>
                            <div>
                              <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-14"/>
                            </div>
                          </button>
                        </div>
                        <div className="float-right">
                          <button className="background-button" onClick={() => this.segueToNext()}>
                            <div>
                              <label className="description-text-1 right-padding">Next Assessment</label>
                            </div>
                          </button>
                        </div>
                      </div>

                      <div className="clear" />
                    </div>

                    <div className="full-width center-text">
                      <div>
                        <div className="super-spacer"/>
                        <p className="center-text heading-text-2">{this.state.assessmentTitle}</p>
                        <p className="center-text row-10">{this.state.assessmentDescription}</p>

                        <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="half-spacer" />

                        <div className="center-horizontally">
                          <button className="btn btn-primary" onClick={() => this.props.navigate(assessmentDetailsPath + '/' + this.state.assessmentTitle.replace(" ", "-").toLowerCase(), { state: { assessments: this.state.assessments, index: this.state.index, assessment: this.state.assessments[this.state.index], resultsData: this.state.resultsData }})}>{(this.state.resultsData && this.state.resultsData[this.state.index]) ? "Edit" : "Start"}</button>
                        </div>
                        <div className="center-horizontally row-10">
                          <Link to={'/app/exploration'} state={{ subNavSelected: 'Careers', calculateMatches: true }} className="background-button">
                            <div className="padding-10 horizontal-margin-5 cta-border circle-radius">
                              <img src={pathsIconDark} alt="GC" className="image-auto-22"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Calculate top career matches"/>
                            </div>
                          </Link>
                          <Link to={'/app/opportunities'} state={{ calculateMatches: true }} className="background-button">
                            <div className="padding-10 horizontal-margin-5 cta-border circle-radius">
                              <img src={opportunitiesIconDark} alt="GC" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Calculate top opportunity matches"/>
                            </div>
                          </Link>
                        </div>
                        <ReactTooltip id="tooltip-placeholder-id" />

                        <div className="super-spacer"/>
                      </div>
                    </div>

                    <hr className="cta-border-color clear-margin clear-padding" />

                    {this.state.resultsData && (
                      <div>
                        {(this.state.matchesByAssessment) ? (
                          <div className="full-width top-margin-negative-4">
                            <div className={(this.state.viewIndex === 0) ? "half-width selected-tab" : "half-width unselected-tab"}>
                              <a className="background-link" onClick={() => this.setState({ viewIndex: 0 })}>
                                <div className="full-space row-15">
                                  <p>Results</p>
                                </div>
                              </a>
                            </div>
                            <div className={(this.state.viewIndex === 1) ? "half-width selected-tab" : "half-width unselected-tab"}>
                              <a className="background-link" onClick={() => this.setState({ viewIndex: 1})}>
                                <div className="full-space row-15">
                                  <p>Matches</p>
                                </div>
                              </a>
                            </div>
                            <div className="clear" />
                          </div>
                        ) : (
                          <div className="full-width top-margin-negative-4">
                            <div className="full-width selected-tab">
                              <div className="full-space row-15">
                                <p>Results</p>
                              </div>
                            </div>
                            <div className="clear" />
                          </div>
                        )}

                        {(this.state.viewIndex === 0) && (
                          <div>
                            {this.renderResults()}
                          </div>
                        )}

                        {(this.state.viewIndex === 1) && (
                          <div>
                            <div className="spacer" />
                            <div className="full-width">
                              <div className={(this.state.subIndex === 1) ? "left-padding-20 right-padding-20 selected-tab" : "left-padding-20 right-padding-20 unselected-tab"}>
                                <a className="background-link" onClick={() => this.setState({ subIndex: 1})}>
                                  <div className="full-space">
                                    <p>Careers</p>
                                  </div>
                                </a>
                              </div>
                              <div className={(this.state.subIndex === 2) ? "left-padding-20 right-padding-20 selected-tab" : "left-padding-20 right-padding-20 unselected-tab"}>
                                <a className="background-link" onClick={() => this.setState({ subIndex: 2})}>
                                  <div className="full-space">
                                    <p>Employers</p>
                                  </div>
                                </a>
                              </div>
                              <div className={(this.state.subIndex === 3) ? "left-padding-20 right-padding-20 selected-tab" : "left-padding-20 right-padding-20 unselected-tab"}>
                                <a className="background-link" onClick={() => this.setState({ subIndex: 3})}>
                                  <div className="full-space">
                                    <p>Internships</p>
                                  </div>
                                </a>
                              </div>
                              <div className="clear" />
                            </div>
                            {this.renderMatches()}
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {(window.innerWidth > 768 && showCompassAssistant && this.state.assessmentResults) && (
            <div className="relative-column-30 horizontal-padding top-margin-40">
              <div className="left-padding-20">
                {(showCompassAssistant && !this.state.disableChatbotPrompts && this.state.assessmentCommands && this.state.assessmentCommands.length > 0) && (
                  <div>
                    <SubAssistantWidget commands={this.state.assessmentCommands} commandClicked={this.commandClicked} />
                  </div>
                )}

                <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                  <div className="full-width height-5 senary-background" />
                  <div className="padding-20">
                    <p className="heading-text-5">Top Career Matches</p>

                    <div className="spacer" /><div className="half-spacer" />

                    {(this.state.topCareerMatches && this.state.topCareerMatches.length > 0) ? (
                      <div className="top-margin left-text">
                        {this.state.topCareerMatches.map((value2, index2) =>
                          <div key={"topCareerMatch|" + index2}>
                            <Link to={this.state.prefix + 'careers/' + value2.name} state={{ careerSelected: value2 }} className="background-link">
                              <div className="fixed-column-50">
                                {(value2.matchScore || value2.matchScore === 0) ? (
                                  <div className="right-padding">
                                    <CircularProgressbar
                                      value={value2.matchScore}
                                      text={`${value2.matchScore}%`}
                                      styles={{
                                        path: { stroke: `${primaryColor}, ${value2.matchScore / 100}` },
                                        text: { fill: primaryColor, fontSize: '26px' },
                                        trail: { stroke: 'transparent' }
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <img src={careerIcon} alt="GC" className="image-auto-50 top-margin-5 center-item"/>
                                )}
                              </div>
                              <div className="calc-column-offset-70">
                                <p className="heading-text-">{value2.name}</p>
                                <p className="description-text-2 description-text-color">{(value2.jobFamily) ? value2.jobFamily : (value2.jobFunction) ? value2.jobFunction : ""}</p>
                              </div>
                              <div className="fixed-column-20 top-margin-5">
                                <img src={arrowIndicatorIcon} alt="GC" className="image-15-fit pin-right"/>
                              </div>
                              <div className="clear"/>
                            </Link>

                            <div className="row-15">
                              <hr />
                            </div>
                          </div>
                        )}

                        <div className="row-10">
                          <button className="btn btn-squarish" onClick={() => this.props.navigate(this.state.prefix + 'exploration', { state: { subNavSelected: 'Careers', calculateMatches: true }})}>Re-Calculate</button>
                        </div>
                      </div>
                    ) : (
                      <div className="row-10">
                        <p className="description-text-2">Calculate career matches from the career pathways tab</p>

                        <div className="row-10">
                          <button className="btn btn-squarish top-margin-20" onClick={() => this.props.navigate(this.state.prefix + 'exploration', { state: { calculateMatches: true }})}>Calculate</button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                  <div className="full-width height-5 senary-background" />
                  <div className="padding-20">
                    <p className="heading-text-5">Top Work Matches</p>

                    <div className="spacer" /><div className="half-spacer" />

                    {(this.state.topWorkMatches && this.state.topWorkMatches.length > 0) ? (
                      <div className="top-margin left-text">
                        {this.state.topWorkMatches.map((value2, index2) =>
                          <div key={"topWorkMatch|" + index2}>
                            <Link to={this.state.prefix + 'opportunities/' + value2.originalId} className="background-link">
                              <div className="fixed-column-50">
                                {(value2.matchScore || value2.matchScore === 0) ? (
                                  <div className="right-padding">
                                    <CircularProgressbar
                                      value={value2.matchScore}
                                      text={`${value2.matchScore}%`}
                                      styles={{
                                        path: { stroke: `${primaryColor}, ${value2.matchScore / 100}` },
                                        text: { fill: primaryColor, fontSize: '26px' },
                                        trail: { stroke: 'transparent' }
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <img src={opportunitiesIconDark} alt="GC" className="image-auto-50 top-margin-5 center-item"/>
                                )}
                              </div>
                              <div className="calc-column-offset-70">
                                <p className="heading-text-">{value2.title}</p>
                                <p className="description-text-2 description-text-color">{value2.employerName}</p>
                              </div>
                              <div className="fixed-column-20 top-margin-5">
                                <img src={arrowIndicatorIcon} alt="GC" className="image-15-fit pin-right"/>
                              </div>
                              <div className="clear"/>
                            </Link>

                            <div className="row-15">
                              <hr />
                            </div>
                          </div>
                        )}

                        <div className="row-10">
                          <button className="btn btn-squarish top-margin-30" onClick={() => this.props.navigate(this.state.prefix + 'opportunities', { state: { calculateMatches: true }})}>Re-Calculate</button>
                        </div>
                      </div>
                    ) : (
                      <div className="row-10">
                        <p className="description-text-2">Calculate work matches from the opportunities tab.</p>

                        <div className="row-10">
                          <button className="btn btn-squarish top-margin-20" onClick={() => this.props.navigate(this.state.prefix + 'opportunities', { state: { calculateMatches: true }})}>Calculate</button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="clear" />
        </div>

      )
    }
}


export default withRouter(AssessmentDetails);
