import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import PreCheckoutForm from '../Common/PreCheckoutForm';
// import EditSubscription from '../Common/EditSubscription';
import {signUp, signIn} from '../Services/AuthRoutes';
import withRouter from '../Functions/WithRouter';

const checkmarkIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png";
const founderPic = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/founder-pic.png';
const pathsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-dark.png';
const mentoringIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/mentoring-icon-dark.png';
const socialIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-dark.png';
const moneyIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-dark.png';
const courseIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png';
const eventIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/calendar-icon-dark.png';
const projectIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-dark.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const abilitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/abilities-icon-dark.png';
const pipelineIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/pipeline-icon-dark.png';
const experienceIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/experience-icon.png";
const gcLogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/Compass-logo-words.png";

const logo1 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/orgLogos/unite-la-full-logo.png";
const logo2 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/orgLogos/yearup_logo_dark.png";
const logo3 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/orgLogos/ofy-logo-color.png";
const logo4 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/orgLogos/mawdp-color-logo.png";
const logo5 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_14.png";


const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class Subscribe extends Component {
    constructor(props) {
        super(props)

        this.state = {
          newLayout: true,
          pageNumber: 1,
          currentStep: 1,
          stepTotal: 3,
          benefits: [],
          testimonials: [],
          roleTitleOptions: ['Career-Seeker','Educator','Workforce','Employer'],
          binaryOptions: ['','Yes','No'],
          signUpView: false,
          helpEmail: 'help@guidedcompass.com',

          careerSeekerOnboardingGuide: 'https://www.guidedcompass.com/help',
          educatorOnboardingGuide: 'https://www.guidedcompass.com/help',
          employerOnboardingGuide: 'https://www.guidedcompass.com/help',

          acceptableRole: true
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.renderBenefits = this.renderBenefits.bind(this)
        this.passSubscriptionData = this.passSubscriptionData.bind(this)
        this.logIn = this.logIn.bind(this)
        this.logout = this.logout.bind(this)
        this.searchOrgs = this.searchOrgs.bind(this)
        this.searchItemClicked = this.searchItemClicked.bind(this)
        this.segueToHome = this.segueToHome.bind(this)
        this.renderSearchOptions = this.renderSearchOptions.bind(this)

    }

    componentDidMount(){
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode  || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called', this.props.activeOrg)

      const org = this.props.activeOrg
      const passedRoleName = this.props.roleName
      const accountCode = this.props.accountCode

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');

      let orgName = localStorage.getItem('orgName');
      let orgURL = localStorage.getItem('orgURL');
      let orgRegion = localStorage.getItem('orgRegion');
      let orgDescription = localStorage.getItem('orgDescription');
      let employerName = localStorage.getItem('employerName');
      let employerURL = localStorage.getItem('employerURL');
      let employerLocation = localStorage.getItem('employerLocation');
      let employerDescription = localStorage.getItem('employerDescription');
      console.log('vital in subscribe: ', email, cuFirstName, cuLastName, typeof email)

      let roleTitle = null
      let price = null
      let unitText = null
      let benefitHeadline = null
      let benefits = []

      let testimonials = []
      let orgType = null
      let subscriptionType = null
      if (passedRoleName === 'career-seekers' || passedRoleName === 'career-seeker' || passedRoleName === 'Career-Seeker' || passedRoleName === 'Student') {
        roleTitle = 'Career-Seeker'
        price = 9.99
        unitText = '/mo'
        benefitHeadline = 'your career'
        benefits = [
          'A.I. Career Assistant','Full Chat Support','Career Exploration Tools','Career Planning Tools','Financial Management Tools','Work Application Tools','Career Advancement Tools','FutureU Courses'
        ]

        testimonials = [{ body: '"I landed my internship through the Guided Compass platform. It was a great tool for exploring opportunities that are a great fit for me."', author: 'Bianca Esquivias'}]
        subscriptionType = 'Individual'
      } else if (passedRoleName === 'workforce' || passedRoleName === 'Admin') {
        roleTitle = 'Workforce'
        price = 2
        unitText = '/mo/cs'
        benefitHeadline = 'your assistance to career-seekers'
        benefits = [
          'A.I. Assistant','Full Chat Support','PBL / WBL / Mentor Matching','Career Exploration Tools','Career Planning Tools','Financial Management Tools','Work Application Tools','Automated Grading Tools','1K+ Hrs of Career Prep Lessons','Comprehensive Reporting'
        ]
        testimonials = [{ body: '“I’ve met with Creighton weekly over the last few years to create a custom work-based learning portal for both Bixel Exchange and UNITE-LA. We’ve missed few meetings because we are both passionate about the space. I’m consistently impressed with the speed at which new, quality features are added to help us and the opportunity youth we serve. Many of the new features were conceived by him, going over and above our contract. From big-picture ideas to improving processes to fixing bugs, Creighton and the Guided Compass team have truly been a partner in our mission. I would highly recommend Guided Compass for any career development or work-based learning program.”', author: 'Jaideep Updahyay, Program Manager @ UNITE-LA'}]
        orgType = 'workforceOrganization'
        subscriptionType = 'Organization'
      } else if (passedRoleName === 'educators' || passedRoleName === 'educator' || passedRoleName === 'Teacher') {
        roleTitle = 'Educator'
        price = 2
        unitText = '/mo/cs'
        benefitHeadline = 'your assistance to career-seekers'
        testimonials = [{ body: '“Guided Compass was very helpful during our career exploration workshops. It led to a more cohesive experience, and the real-world projects and work opportunities nicely tied the lessons together. The founder was also very high-touch, answering questions and quickly implementing recommended changes. He has become a valued member of our advisory board and has provided useful ideas and feedback to continue improving our curriculum. It is impressive that he was able to get Guided Compass to pass through the LAUSD stringent UDIPP process. Few technology products make it through this process due to the stringent privacy and security measures for the safety of student data.”', author: 'Gabriela Rosillo, Academy Teacher @ Grover Cleveland High Art Academy'}]
        benefits = [
          'A.I. Assistant','Full Chat Support','Customized Portals','PBL / WBL Matching','Career Exploration Tools','Career Planning Tools','Financial Management Tools','Work Application Tools','Grading Automation Tools','1K+ Hrs of Career Prep Lessons','Comprehensive Reporting'
        ]
        orgType = 'educationalIntitution'
        subscriptionType = 'Organization'
      } else if (passedRoleName === 'employers' || passedRoleName === 'employer' || passedRoleName === 'Employer') {
        roleTitle = 'Employer'
        price = 99
        unitText = '/mo'
        benefitHeadline = 'your talent pipeline & recruitment'
        testimonials = [{ body: '"Guided Compass and their partners have been great for screening interns and entry-level appliants. They helped us hire some great people. We are also excited about partnering to build out our talent pipeline and work with training programs on project-based learning curriculum. "', author: 'Emily Alonso, CEO @ Impact Allies'}]
        benefits = [
          'A.I. Assistant','Full Chat Support','Post up to 3 Roles','Search Resume Database','Get Referred Candidates','Design Your Talent Pipeline','Engage Your Pipeline','ATS Integration','Discount on Employee Communities','Comprehensive Reporting'
        ]
        subscriptionType = 'Employer'
      }

      let pageNumber = 1
      let stepTotal = 3
      if (email) {
        pageNumber = 2
        stepTotal = 2
      }

      let acceptableRole = true
      if (roleName) {
        if ((roleName === 'Student' || roleName === 'Career-Seeker') && (passedRoleName !== 'career-seekers' && passedRoleName !== 'career-seeker')) {
          console.log('made it in', roleName, passedRoleName)
          acceptableRole = false
        }
        if ((roleName === 'Teacher' || roleName === 'Counselor' || roleName === 'WBLC' || roleName === 'Work-Based Learning Coordinator' || roleName === 'School Support') && (passedRoleName === 'career-seeker' && passedRoleName === 'career-seekers' && passedRoleName === 'employer' && passedRoleName === 'employers')) {
          acceptableRole = false
        }
        if ((roleName === 'Employer') && (passedRoleName !== 'employers' && passedRoleName !== 'employer')) {
          acceptableRole = false
        }
      }
      console.log('emp values: ', acceptableRole, roleName, passedRoleName)

      this.setState({
        emailId: email, cuFirstName, cuLastName, username, org, orgFocus, orgType, orgName, orgURL, orgRegion, orgDescription,
        accountCode, employerName, employerURL, employerLocation, employerDescription,
        roleName, passedRoleName, roleTitle, price, unitText, benefitHeadline, benefits, testimonials, pageNumber, stepTotal,
        acceptableRole, subscriptionType
      })

      this.adjustWindowHeight()

      if (email) {
        // const subParams = { email, orgCode: org, accountCode, subscriptionType, isActive: true, allOrgAccounts: true }
        // console.log('show subParams: ', subParams)
        // Axios.get('/api/payments/subscription', { params: subParams })
        // .then((response) => {
        //   console.log('Subscription query attempted', response.data);
        //
        //    if (response.data.success) {
        //      console.log('successfully retrieved subscription')
        //
        //      const existingSubscription = response.data.subscription
        //      this.setState({ errorMessage: null, existingSubscription })
        //
        //    } else {
        //      console.log('no sub data found', response.data.message)
        //
        //      // this.setState({ errorMessage: null, isSaving: false, pageNumber: 2, currentStep: 2 })
        //    }
        //
        // }).catch((error) => {
        //    console.log('Sub query did not work', error);
        //
        //    // this.setState({ errorMessage: null, isSaving: false })
        //
        // });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'roleTitle') {

        let roleName = ''
        if (event.target.value === 'Career-Seeker') {
          roleName = 'career-seeker'
        } else if (event.target.value === 'Educator') {
          roleName = 'educator'
        } else if (event.target.value === 'Workforce') {
          roleName = 'workforce'
        } else if (event.target.value === 'Employer') {
          roleName = 'employer'
        }

        this.setState({ openRoleSwitcher: false })
        this.props.navigate('/subscribe/' + roleName)
      } else if (event.target.name === 'orgName') {
        this.setState({ [event.target.name]: event.target.value, animating: true })
        this.searchOrgs(event.target.value,'org')
      } else if (event.target.name === 'employerName') {
        this.setState({ [event.target.name]: event.target.value, animating: true })
        this.searchOrgs(event.target.value,'employer')
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }

      this.adjustWindowHeight()
    }

    searchOrgs(orgName, type) {
      console.log('searchString called', orgName, type)

      let searchURL = '/api/org/search'
      let searchParams = { orgName }
      if (type === 'employer') {
        searchURL = '/api/employers/search'
        searchParams = { employerName: orgName }
      }

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get(searchURL, { params: searchParams })
        .then((response) => {
          console.log('Org search query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved orgs')

              if (response.data) {

                if (type === 'employer') {
                  const employerOptions = response.data.employers
                  self.setState({ employerOptions, animating: false })
                } else {
                  const orgOptions = response.data.organizations
                  self.setState({ orgOptions, animating: false })
                }
              }

            } else {
              console.log('no orgs found', response.data.message)
              self.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('Orgs query did not work', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId2)
        self.state.timerId2 = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type === 'org') {
        const orgObject = passedItem
        const orgName = passedItem.orgName
        const orgURL = passedItem.orgURL
        const orgRegion = passedItem.orgRegion
        const orgDescription = passedItem.orgDescription

        const orgOptions = null

        this.setState({ orgObject, orgName, orgURL, orgRegion, orgDescription, orgOptions })
      } else if (type === 'employer') {
        const employerObject = passedItem
        const employerName = passedItem.employerName
        const employerURL = passedItem.employerURL
        const employerLocation = passedItem.employerLocation
        const employerDescription = passedItem.employerDescription

        const employerOptions = null

        this.setState({ employerObject, employerName, employerURL, employerLocation, employerDescription, employerOptions })
      }
    }

    closeModal() {
      console.log('closeModal in projects: ', this.state.showProjectDetail)

      this.setState({ modalIsOpen: false, showBenefits: false, showPaymentDetails: false });

    }

    renderBenefits() {
      console.log('renderBenefits called')

      let beneficiary = this.state.roleTitle
      if (beneficiary === 'educators') {
        beneficiary = 'workforce'
      }

      if (beneficiary === 'Career-Seeker') {
        return (
          <div key={beneficiary}>
            <div className="row-10">
              <div className="container-left center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={founderPic} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#1: Call w/ CEO</p>
                <p className="row-5 description-text-2">Let's talk about you.</p>
                <p className="row-5 description-text-2">Harvard Business School graduate, 4 years investment experience, 6 years software development, product management, and entrepreneurship experience across industries. Personally conducted hundred of interviews with you.</p>
                <a href="https://www.creightontaylor.com" target="_blank" rel="noopener noreferrer"className="description-text-2">View Background</a>
              </div>
              <div className="container-right center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={pathsIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#2: Explore Paths</p>
                <p className="row-5 description-text-2">View, search, filter, match, and favorite to 1,000+ career paths. View detailed data on competencies, tasks, pay associated with career paths.</p>
                <p className="row-5 description-text-2">View fastest growing industries, highest paid occupations, and other labor market trends!</p>
              </div>
              <div className="clear" />
            </div>

            <div className="row-10">
              <div className="container-left center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={moneyIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#3: Use the Financial Planner</p>
                <p className="row-5 description-text-2">Import career info into our financial planning tool. Use the tool to weigh different career path incomes, expenses, and net worth in different scenarios. Add your expenses and loans to see how much money you'll take home.</p>
              </div>
              <div className="container-right center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={socialIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#4: Connect with Others</p>
                <p className="row-5 description-text-2">Match, follow, and connect with peers, mentors, and groups. See who is similar in interests, values, personality, skills, and more. See who is complimentary. Send them a message, ask for a referral, or give a shoutout!</p>
              </div>
              <div className="clear" />
            </div>

            <div className="row-10">


              <div className="container-left center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={courseIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#5: Hands-On Learning</p>
                <p className="row-5 description-text-2">Create, edit, and get recommended weekly competency-based course schedules based on your career goals. Build and utilize competencies by submitting projects to real world problems. Get constructive feedback on your submissions. Easily import your accomplishments into work applications.</p>

              </div>
              <div className="container-right center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={opportunitiesIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#6: Opportunities</p>
                <p className="row-5 description-text-2">View, search, filter, favorite, get recommended, and apply to different opportunities.</p>
                <p className="row-5 description-text-2">Opportunities include career event, project, and work opportunities. Import your profile information for RSVPs, project submissions, and work applications.</p>
              </div>

              <div className="clear" />
            </div>
          </div>
        )
      } else if (beneficiary === 'Workforce') {
        return (
          <div key={beneficiary}>
            <div className="row-10">
              <div className="container-right center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={pathsIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>
                <p className="heading-text-4 top-padding">#1: Career Exploration</p>
                <p className="row-5 description-text-2">Career-seekers can take career assessments and build up their profile before viewing top career matches and comparing them using the financial planning tool. Use these tools during workshops, and review favorites and financial outcomes during advising sessions. Clarity here make placement easier later.</p>
              </div>
              <div className="container-left center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={mentoringIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#2: Mentor Matching</p>
                <p className="row-5 description-text-2">Guided Compass uses career assessments, competency endorsements, projects, experience, and other informaton to help you match career-seekers to mentors. Students and mentors can log resume reviews, mock interviews, and other advising information in Guided Compass.</p>
              </div>
              <div className="clear" />
            </div>

            <div className="row-10">
              <div className="container-right center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={courseIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>
                <p className="heading-text-4 top-padding">#3: Skill-Based Courses</p>
                <p className="row-5 description-text-2">Career-seekers get skill-based course recommendations to supplement skills they may be weaker in for their desired career path. Recommeend courses for career-seekers to upskill.</p>
              </div>
              <div className="container-left center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={eventIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>
                <p className="heading-text-4 top-padding">#4: Career Events</p>
                <p className="row-5 description-text-2">Post workshops, job shadows, webinars, career fairs, and other career events. Career-seekers can RSVP and print out their Guided Compass profile for mentors and employers.</p>
              </div>
              <div className="clear" />
            </div>

            <div className="row-10">
              <div className="container-right center-text horizontal-padding-3">

                <div className="horizontal-padding-3">
                  <img src={projectIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>
                <p className="heading-text-4 top-padding">#5: Projects</p>
                <p className="row-5 description-text-2">Source and post assignments, problems (i.e., case studies), and challenges for career-seekers to submit projects. Provide public and/or private feedback on those project solutions, and award money.</p>
              </div>
              <div className="container-left center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={opportunitiesIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>
                <p className="heading-text-4 top-padding">#6: Work</p>
                <p className="row-5 description-text-2">Post work opportunites or have employers post work opportunities to your community. Design ideal candidate profiles for pathways. Career-seekers can import their profiles to apply to each posting. Easily refer candidates to employers, gather feedback, and track who gets hired.</p>
              </div>
              <div className="clear" />
            </div>
          </div>
        )
      } else if (beneficiary === 'Employer') {
        return (
          <div key={beneficiary}>
            <div className="row-10">
              <div className="container-right center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={abilitiesIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>
                <p className="heading-text-4 top-padding">#1: Design Ideal Candidates</p>
                <p className="row-5 description-text-2">Design benchmarks: the work preferences, interests, personality, skills, abilities, values, knowledge, education, projects, experience, and interview performance of your ideal entry-level candidates for each department.</p>
              </div>
              <div className="container-left center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={pipelineIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#2: Build Your Talent Pipeline</p>
                <p className="row-5 description-text-2">Our matching algorithm will recommend your employer profile to career seekers as they explore career paths. Those who favorite your profile and mark their profile public, will be viewable in your talent pipeline. Additionally, as an employer, you can favorite top recommended candidates. Lastly, you can invite candidate leads to Guided Compass to see how they fare against your ideal.</p>
              </div>
              <div className="clear" />
            </div>

            <div className="row-10">
              <div className="container-right center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={pipelineIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>
                <p className="heading-text-4 top-padding">#3: Engage Your Talent Pipeline</p>
                <p className="row-5 description-text-2">Invite your talent pipeline to job shadows, webinars, talks, and other career-related events. Additionally, you can invite people to work on assignments, case studies, or challenges you post as a screening mechanism. Engaged candidates earn points for when they apply.</p>
              </div>
              <div className="container-left center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={pipelineIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#4: Get Recommended Candidates</p>
                <p className="row-5 description-text-2">Get algorithmically matched talent pipelines based on your ideal candidate profiles. Engage with that talent pipeline by messaging, inviting to career events, giving assignments, providing feedback on projects, and hiring.</p>
              </div>
              <div className="clear" />
            </div>
          </div>
        )
      }
    }

    passSubscriptionData(subscription) {
      console.log('passSubscriptionData called in subscribe', subscription)

      let altFirstName = localStorage.getItem('firstName');
      let altLastName = localStorage.getItem('lastName');
      console.log('show vitals after subscription: ', this.state.emailId, this.state.cuFirstName, altFirstName, altLastName)

      let currentStep = this.state.stepTotal
      const activeOrg = localStorage.getItem('activeOrg');
      this.setState({ pageNumber: 3, currentStep, org: activeOrg })
      // this.setState({ modalIsOpen: false, showBenefits: false })
      // this.props.passSubscriptionData(subscription)

    }

    async logIn() {
      console.log('logIn called')

      if (this.state.signUpView === false) {
        // signin

        this.setState({ isSaving: true })

        const email = this.state.email
        const password = this.state.password
        const orgFocus = this.state.orgFocus

        const returnedValue = await signIn(email, password, orgFocus)
        console.log('show returnedValue: ', returnedValue)

        if (returnedValue && returnedValue.error && returnedValue.error.message) {

          this.setState({ errorMessage: returnedValue.error.message.toString(), isSaving: false })
        } else if (returnedValue.success === false) {
          this.setState({ errorMessage: returnedValue.message, isSaving: false })
        } else {

          const firstName = returnedValue.user.firstName
          const lastName = returnedValue.user.lastName
          let roleName = returnedValue.user.roleName
          if (roleName === 'Student') {
            roleName = 'Student / Career Seeker'
          }

          const school = returnedValue.user.school
          const jobTitle = returnedValue.user.jobTitle
          // const employerName = returnedValue.user.employerName

          // const email = this.state.email
          const orgCode = returnedValue.user.activeOrg
          const accountCode = returnedValue.user.accountCode
          let subscriptionType = 'Individual'
          if (roleName !== 'Student / Career Seeker' && roleName !== 'Student') {
            subscriptionType = this.state.subscriptionType
          }

          let acceptableRole = true
          if (roleName) {
            if ((roleName === 'Student' || roleName === 'Career-Seeker') && (this.state.roleTitle !== 'Career-Seeker')) {
              acceptableRole = false
            }
            if ((roleName === 'Teacher' || roleName === 'Counselor' || roleName === 'WBLC' || roleName === 'Work-Based Learning Coordinator' || roleName === 'School Support') && (this.state.roleTitle === 'Career-Seeker' || this.state.roleTitle === 'Employer')) {
              acceptableRole = false
            }
            if ((roleName === 'Employer') && (this.state.roleTitle !== 'Employer')) {
              acceptableRole = false
            }
          }

          localStorage.setItem('employerName', returnedValue.employerName)
          localStorage.setItem('employerURL', returnedValue.employerURL)
          localStorage.setItem('employerRegion', returnedValue.employerRegion)
          localStorage.setItem('employerDescription', returnedValue.employerDescription)

          this.setState({ firstName, lastName, emailId: email, roleName, school, jobTitle,
            orgCode, accountCode, subscriptionType, employerName: returnedValue.employerName, employerURL: returnedValue.employerURL,
            employerLocation: returnedValue.employerLocation, employerDescription: returnedValue.employerDescription,
            acceptableRole
          })

          if (orgCode === 'guidedcompass' && returnedValue.orgName) {
            this.setState({
              orgName: returnedValue.orgName, orgURL: returnedValue.orgURL,
              orgRegion: returnedValue.orgRegion, orgDescription: returnedValue.orgDescription

            })

            localStorage.setItem('orgName', returnedValue.orgName)
            localStorage.setItem('orgURL', returnedValue.orgURL)
            localStorage.setItem('orgRegion', returnedValue.orgRegion)
            localStorage.setItem('orgDescription', returnedValue.orgDescription)

          } else {
            Axios.get('/api/org', { params: { orgCode } })
            .then((response) => {
              console.log('Org query attempted afer signin', response.data);

               if (response.data.success) {
                 console.log('successfully retrieved org')

                 const orgName = response.data.orgInfo.orgName
                 this.setState({ orgName })

                 localStorage.setItem('orgName', orgName)
                 localStorage.setItem('orgURL', response.data.orgInfo.orgURL)
                 localStorage.setItem('orgRegion', response.data.orgInfo.orgRegion)
                 localStorage.setItem('orgDescription', response.data.orgInfo.orgDescription)

               } else {
                 console.log('no org data found', response.data.message)
               }

            }).catch((error) => {
               console.log('Org query did not work', error);
               this.setState({ serverErrorMessage: 'No org found'})
            });
          }

          // query for customerId
          Axios.get('/api/payments/subscription', { params: { email, orgCode, accountCode, subscriptionType, isActive: true } })
          .then((response) => {
            console.log('Subscription query attempted under login', response.data);

             if (response.data.success) {
               console.log('successfully retrieved subscription')

               const subscription = response.data.subscription
               const customerId = subscription.customerId

               this.setState({ errorMessage: null, subscription, customerId, isSaving: false, pageNumber: 2, currentStep: 2 })

             } else {
               console.log('no org data found', response.data.message)

               this.setState({ errorMessage: null, isSaving: false, pageNumber: 2, currentStep: 2 })
             }

          }).catch((error) => {
             console.log('Org query did not work', error);

             this.setState({ errorMessage: null, isSaving: false })

          });
        }

      } else {
        console.log('show roleName in signup: ', this.state.roleName)
        this.setState({ isSaving: true })

        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const email = this.state.email
        const password = this.state.password
        const gradYear = this.state.gradYear

        let orgCode = 'guidedcompass'
        let roleName = this.state.roleName
        if (this.state.roleName === 'Student / Career Seeker') {
          roleName = 'Student'
        }

        let orgName = this.state.orgName
        let orgURL = this.state.orgURL
        let orgRegion = this.state.orgRegion
        let orgDescription = this.state.orgDescription
        let employerName = this.state.employerName
        let employerURL = this.state.employerURL
        let employerLocation = this.state.employerLocation
        let employerDescription = this.state.employerDescription

        if (!roleName && this.state.roleTitle) {
          if (this.state.roleTitle === 'Educator') {
            roleName = 'Teacher'
          } else if (this.state.roleTitle === 'Workforce') {
            roleName = 'Work-Based Learning Coordinator'
          } else {
            roleName = this.state.roleTitle
          }
        }

        const otherRoleName = this.state.otherRoleName
        const courseId = this.state.courseId
        const workId = this.state.workId
        const school = this.state.school
        const schoolDistrict = this.state.schoolDistrict
        const accountCode = this.state.accountCode
        const studentBenefits = this.state.studentBenefits
        const teacherBenefits = this.state.teacherBenefits
        const mentorBenefits = this.state.mentorBenefits
        const employerBenefits = this.state.employerBenefits
        const confirmEmail = this.state.confirmEmail

        let jobTitle = this.state.jobTitle

        const returnedValue = await signUp(firstName, lastName, email, password, gradYear, jobTitle, employerName, orgCode, roleName, otherRoleName, courseId, workId, school, schoolDistrict, accountCode, studentBenefits, teacherBenefits, mentorBenefits, employerBenefits, confirmEmail, orgName, orgURL, orgRegion, orgDescription, employerURL, employerLocation, employerDescription)
        console.log('show returnedValue: ', returnedValue)

        if (returnedValue && returnedValue.error && returnedValue.error.message) {
          this.setState({ errorMessage: returnedValue.error.message.toString(), isSaving: false })
        } else if (returnedValue.success === false) {
          this.setState({ errorMessage: returnedValue.message, isSaving: false })
        } else {

          localStorage.setItem('orgName', orgName)
          localStorage.setItem('orgURL', orgURL)
          localStorage.setItem('orgRegion', orgRegion)
          localStorage.setItem('orgDescription', orgDescription)
          localStorage.setItem('employerName', employerName)
          localStorage.setItem('employerURL', employerURL)
          localStorage.setItem('employerLocation', employerLocation)
          localStorage.setItem('employerDescription', employerDescription)

          const email = this.state.email
          // const orgCode = returnedValue.user.activeOrg
          const accountCode = this.state.accountCode
          let subscriptionType = 'Individual'
          if (this.state.roleName !== 'Student / Career Seeker' && this.state.roleName !== 'Student') {
            subscriptionType = this.state.subscriptionType
          }

          this.setState({ orgCode })

          // query for customerId
          Axios.get('/api/payments/subscription', { params: { email, orgCode, accountCode, subscriptionType, isActive: true } })
          .then((response) => {
            console.log('Subscription query attempted for sign up', response.data);

             if (response.data.success) {
               console.log('successfully retrieved subscription')

               const subscription = response.data.subscription
               const customerId = subscription.customerId

               this.setState({ errorMessage: null, subscription, customerId, isSaving: false, pageNumber: 2, currentStep: 2 })

             } else {
               console.log('no sub data found', response.data.message)

               this.setState({ errorMessage: null, isSaving: false, pageNumber: 2, currentStep: 2 })
             }

          }).catch((error) => {
             console.log('Sub query did not work', error);

             this.setState({ errorMessage: null, isSaving: false })

          });
        }
      }
    }

    logout() {
      console.log('logout called')

      localStorage.removeItem('email')//this.props.auth.email
      localStorage.removeItem('username')
      localStorage.removeItem('firstName')
      localStorage.removeItem('lastName')
      localStorage.removeItem('isAdvisor')
      localStorage.removeItem('orgAffiliation')
      localStorage.removeItem('myOrgs')
      localStorage.removeItem('activeOrg')
      localStorage.removeItem('orgName')
      localStorage.removeItem('orgFocus')
      localStorage.removeItem('roleName')
      localStorage.removeItem('studentAliasSingular')
      localStorage.removeItem('studentAliasPlural')
      localStorage.removeItem('mentorAliasSingular')
      localStorage.removeItem('mentorAliasPlural')
      localStorage.removeItem('workMode')
      localStorage.removeItem('accountCode')

      localStorage.removeItem('orgURL')
      localStorage.removeItem('orgRegion')
      localStorage.removeItem('orgDescription')
      localStorage.removeItem('employerName')
      localStorage.removeItem('employerURL')
      localStorage.removeItem('employerRegion')
      localStorage.removeItem('employerDescription')

      const signUpView = false
      this.setState({ signUpView, pageNumber: 1, stepTotal: 3 })

    }

    segueToHome() {
      console.log('segueToHome called')

      let email = localStorage.getItem('email');
      if (!email) {
        this.props.navigate('/')
      } else if ((this.state.roleName === 'Student' || this.state.roleName === 'Career-Seeker')) {
        this.props.navigate('/app')
      } else if (this.state.roleName === 'Educator') {
        this.props.navigate('/advisor/dashboard')
      } else if (this.state.roleName === 'Workforce') {
        this.props.navigate('/organizations/' + this.state.org + '/dashboard')
      } else if (this.state.roleName === 'Employer') {
        this.props.navigate('/employers/' + this.state.accountCode + '/dashboard')
      }

    }

    adjustWindowHeight() {
      console.log('adjustWindowHeight called')

      const self = this
      function officiallyAdjust() {
        self.setState({ adjustWindowHeight: true })
      }

      const delayFilter = () => {
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyAdjust, 100)
      }

      delayFilter();
    }

    renderSearchOptions() {
      console.log('renderSearchOptions called')

      return (
        <div key="renderSearchOptions">
          {(this.state.animating) ? (
            <div className="flex-container flex-center full-space">
              <div>
                <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                <div className="spacer" /><div className="spacer" /><div className="spacer" />
                <p className="center-text cta-color bold-text">Pulling organizations...</p>

              </div>
            </div>
          ) : (
            <div>
              {(this.state.roleTitle === 'Employer') ? (
                <div>
                  {(this.state.employerOptions) && (
                    <div className="card top-margin">
                      {this.state.employerOptions.map((value, optionIndex) =>
                        <div key={value._id} className="left-text bottom-margin-5 full-width">
                          <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value,'employer')}>
                            <div className="full-width">
                              <div className="fixed-column-40">
                                <div className="mini-spacer" />
                                <img src={experienceIcon} alt="Compass employer icon icon" className="image-auto-22" />
                              </div>
                              <div className="calc-column-offset-40">
                                <p className="cta-color">{value.employerName}</p>
                              </div>
                            </div>
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {(this.state.orgOptions) && (
                    <div className="card top-margin">
                      {this.state.orgOptions.map((value, optionIndex) =>
                        <div key={value._id} className="left-text bottom-margin-5 full-width">
                          <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value,'org')}>
                            <div className="full-width">
                              <div className="fixed-column-40">
                                <div className="mini-spacer" />
                                <img src={experienceIcon} alt="Compass employer icon icon" className="image-auto-22" />
                              </div>
                              <div className="calc-column-offset-40">
                                <p className="cta-color">{value.orgName}</p>
                              </div>
                            </div>
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )
    }

    render() {

      let fullHeight = window.innerHeight

      if (this.state.adjustWindowHeight) {
        fullHeight = Math.max(
          window.innerHeight, document.body.scrollHeight, document.body.offsetHeight,
          document.documentElement.scrollHeight, document.documentElement.clientHeight, document.documentElement.offsetHeight
        )
      }

      // let backgroundImage = 'cs-background-image'
      // if (this.state.roleTitle === 'Educator' || this.state.roleTitle === 'Workforce') {
      //   backgroundImage = 'wf-background-image'
      // } else if (this.state.roleTitle === 'Employer') {
      //   backgroundImage = 'emp-background-image'
      // }

      return (
          <div className="front-page-background-image full-view-space" style={{ height: fullHeight }}>
            <div className="row-20 full-width flex-container flex-center">
              <button className="background-button" onClick={() => this.segueToHome()}>
                <img className="image-auto-30" alt="GC" src={gcLogo} />
              </button>
            </div>

            <div className="horizontal-padding-3 center-horizontally">

              {(this.state.roleTitle) ? (
                <div className="row-30">
                  <div className={(this.state.pageNumber > 1) ? "white-background medium-shadow slightly-rounded-corners" : ""}>

                    {(this.state.pageNumber > 1) && (
                      <div className={(window.innerWidth > 768) ? "relative-position relative-column-40 padding-30 min-height-650 height-740 senary-background-light" : "full-width light-background padding-30"}>
                      {/*<div className={(window.innerWidth > 768) ? "relative-column-40 light-background padding-30 min-height-650 " + backgroundImage : "full-width light-background padding-30 min-height-600 " + backgroundImage}>*/}
                        {/*<p className="bold-text center-text">{this.state.roleTitle} Basic Subscription</p>*/}
                        <p className="center-text heading-text-4">Guided Compass Basic</p>
                        <div className="spacer" />
                        <p className="description-text-4 center-text">Are you not a {this.state.roleTitle}{(this.state.roleTitle === 'Workforce') && ' Rep'}? <label className="bold-text cta-color"><button className="background-button" onClick={() => this.setState({ openRoleSwitcher: true })}>Switch Roles</button></label></p>
                        <div className="spacer" />

                        {(this.state.openRoleSwitcher) ? (
                          <div>

                            <div className="row-10">
                              <p className="profile-label">Select a Role</p>
                              <select name="roleTitle" value={this.state.roleTitle} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.roleTitleOptions.map(value =>
                                  <option key={value.key} value={value}>{value}</option>
                                )}
                              </select>
                            </div>

                            <button className="btn btn-squarish" onClick={() => this.setState({ openRoleSwitcher: false })} >Cancel</button>
                          </div>
                        ) : (
                          <div>
                            <div className="top-padding-40 center-text">
                              <div>
                                {/*
                                {(this.state.roleTitle === 'Career-Seeker') && (
                                  <p className="description-text-2 bottom-margin-negative-32 left-margin-negative-65">$</p>
                                )}
                                {(this.state.roleTitle === 'Educator' || this.state.roleTitle === 'Workforce') && (
                                  <p className="description-text-2 bottom-margin-negative-32 left-margin-negative-90">$</p>
                                )}
                                {(this.state.roleTitle === 'Employer') && (
                                  <p className="description-text-2 bottom-margin-negative-32 left-margin-negative-100">$</p>
                                )}*/}

                                <div className="left-padding">
                                  <label className="heading-text-1 cta-color">${this.state.price}</label><label className="description-text-color">{this.state.unitText}</label>
                                </div>
                              </div>

                              <div className="clear" />
                            </div>

                            <div className="bottom-padding center-text">
                              <p className="description-text-2 row-10 bold-text"><label className="italicize-text">Supercharge</label> {this.state.benefitHeadline}</p>
                            </div>

                            <div className="left-text row-30 description-text-2">
                              {this.state.benefits.map((value, index) =>
                                <div key={index}>
                                  <div className="relative-column-50 left-padding-20 top-margin-15">
                                    <div className="row-5">
                                      <div className="fixed-column-20 top-margin-3">
                                        <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                      </div>
                                      <div className="calc-column-offset-20">
                                        <p>{value}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  </div>
                                  {(index % 2 === 1) && (
                                    <div className="clear" />
                                  )}
                                </div>
                              )}
                              <div className="clear" />
                            </div>
                            {/*
                            <div className="center-text full-width top-padding description-text-2">
                              <button className="btn btn-squarish white-background cta-color bold-text" onClick={() => this.setState({ modalIsOpen: true, showBenefits: true })}>Learn More</button>
                            </div>

                            {(this.state.testimonials && this.state.testimonials[0]) && (
                              <div className="right-padding-20 top-margin-60">
                                <div className="float-right full-width description-text-4">
                                  <p className="right-text italicize-text bold-text">{this.state.testimonials[0].body}</p>
                                  <div className="spacer" />
                                  <p className="right-text">- {this.state.testimonials[0].author}</p>
                                </div>
                                <div className="clear" />
                              </div>
                            )}*/}
                            {(window.innerWidth > 768) && (
                              <div className="padding-30 absolute-position flex-container-mobile-column box bottom-0">
                                <div className="flex-20 box top-margin-negative-10">
                                  <img src={logo1} alt="GC" className="image-full-auto center-horizontally wash-out-2" />
                                </div>
                                <div className="flex-20 box">
                                  <img src={logo2} alt="GC" className="image-full-auto center-horizontally wash-out-2" />
                                </div>
                                <div className="flex-20 box">
                                  <img src={logo3} alt="GC" className="image-full-auto center-horizontally wash-out-2" />
                                </div>
                                <div className="flex-20 box">
                                  <img src={logo4} alt="GC" className="image-full-auto center-horizontally wash-out-2" />
                                </div>
                                <div className="flex-20 box top-margin-negative-10">
                                  <img src={logo5} alt="GC" className="image-full-auto center-horizontally wash-out-2" />
                                </div>
                              </div>
                            )}

                          </div>
                        )}
                      </div>
                    )}

                    <div className={(window.innerWidth > 768) ? (this.state.pageNumber > 1) ? "relative-column-60 padding-30" : "full-width padding-30 max-width-700 center-horizontally white-background medium-shadow slightly-rounded-corners" : "full-width padding-30 white-background"}>
                      {(this.state.pageNumber === 1) && (
                        <div>
                          <p className="description-text-3 center-text half-bold-text">STEP {this.state.currentStep} of {this.state.stepTotal}</p>
                          <p className="bold-text center-text">{(this.state.signUpView) ? "Create an Account" : "Sign In"}</p>

                          <div className="spacer" />

                          {(this.state.signUpView) ? (
                            <p className="description-text-3 center-text">Already have an account? <label className="bold-text cta-color"><button className="background-button" onClick={() => this.setState({ signUpView: false })}>Sign In</button></label></p>
                          ) : (
                            <p className="description-text-3 center-text">Don't have an account? <label className="bold-text cta-color"><button className="background-button" onClick={() => this.setState({ signUpView: true })}>Sign Up</button></label></p>
                          )}

                          <div className="spacer" /><div className="spacer" />

                          {(this.state.signUpView) && (
                            <div className="row-10">
                              <div className="container-left">
                                <label className="profile-label">First Name</label>
                                <input className="text-field" type="text" placeholder="First name" name="firstName" value={this.state.firstName} onChange={this.formChangeHandler} />
                              </div>
                              <div className="container-right">
                                <label className="profile-label">Last Name</label>
                                <input className="text-field" type="text" placeholder="Last name" name="lastName" value={this.state.lastName} onChange={this.formChangeHandler} />
                              </div>
                              <div className="clear" />
                            </div>
                          )}

                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Email</label>
                              <input className="text-field" type="text" placeholder="Email" name="email" value={this.state.email} onChange={this.formChangeHandler} />
                            </div>
                            <div className="container-right">
                              <label className="profile-label">Password</label>
                              <input className="text-field login-password" type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.formChangeHandler} />
                            </div>
                            <div className="clear" />
                          </div>

                          {((this.state.roleTitle !== 'Career-Seeker') && this.state.signUpView) && (
                            <div>
                              <div className="row-10">
                                <hr />
                              </div>

                              <div className="row-10">
                                <label className="profile-label">Does your organization already have an account with Guided Compass?</label>
                                <select name="existingOrg" value={this.state.existingOrg} onChange={this.formChangeHandler} className="dropdown">
                                  {this.state.binaryOptions.map(value =>
                                    <option key={value.key} value={value}>{value}</option>
                                  )}
                                </select>
                              </div>

                              {(this.state.existingOrg === 'Yes') && (
                                <div>
                                  <div className="bottom-padding">

                                    <label className="profile-label">Search For Your Org</label>
                                    <div>
                                      <div className={(this.state.orgObject || this.state.employerObject) ? "calc-column-offset-50" : "full-width"}>
                                        <input className="text-field" type="text" placeholder={"Start typing to search..."} name={(this.state.roleTitle === 'Employer') ? "employerName" : "orgName"} value={(this.state.roleTitle === 'Employer') ? this.state.employerName : this.state.orgName} onChange={this.formChangeHandler} />
                                      </div>
                                      {(this.state.orgObject || this.state.employerObject) && (
                                        <div className="fixed-column-50 left-padding top-padding-5">
                                          <img className="image-auto-25" alt="GC" src={checkmarkIcon} />
                                        </div>
                                      )}

                                      <div className="clear" />
                                    </div>

                                    {this.renderSearchOptions()}

                                  </div>
                                </div>
                              )}

                              {(this.state.existingOrg === 'No') && (
                                <div>
                                  <div className="row-10">
                                    <div className="container-left">
                                      <label className="profile-label">{(this.state.roleTitle === 'Employer') ? "Employer Name" : "Organization Name"}</label>
                                      <input className="text-field" type="text" placeholder={(this.state.roleTitle === 'Employer') ? "Employer name" : "Org name"} name={(this.state.roleTitle === 'Employer') ? "employerName" : "orgName"} value={(this.state.roleTitle === 'Employer') ? this.state.employerName : this.state.orgName} onChange={this.formChangeHandler} />
                                      {this.renderSearchOptions()}
                                    </div>
                                    <div className="container-right">
                                      <label className="profile-label">{(this.state.roleTitle === 'Employer') ? "Employer Website" : "Organization Website"}</label>
                                      <input className="text-field" type="text" placeholder={(this.state.roleTitle === 'Employer') ? "Employer website" : "Org website"} name={(this.state.roleTitle === 'Employer') ? "employerURL" : "orgURL"} value={(this.state.roleTitle === 'Employer') ? this.state.employerURL : this.state.orgURL} onChange={this.formChangeHandler} />
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="row-10">
                                    <div className="container-left">
                                      <label className="profile-label">{(this.state.roleTitle === 'Employer') ? "Employer Region (City)" : "Organization Region (City)"}</label>
                                      <input className="text-field" type="text" placeholder={(this.state.roleTitle === 'Employer') ? "Employer region" : "Org region"} name={(this.state.roleTitle === 'Employer') ? "employerLocation" : "orgRegion"} value={(this.state.roleTitle === 'Employer') ? this.state.employerLocation : this.state.orgRegion} onChange={this.formChangeHandler} />
                                    </div>
                                    <div className="container-right">
                                      <label className="profile-label">Your Job Title</label>
                                      <input className="text-field" type="text" placeholder="Job title" name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler} />
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="row-10">
                                    <label className="profile-label">{(this.state.roleTitle === 'Employer') ? "Employer Description" : "Organization Description"}</label>
                                    <textarea className="text-field" type="text" placeholder={(this.state.roleTitle === 'Employer') ? "Employer description" : "Org description"} name={(this.state.roleTitle === 'Employer') ? "employerDescription" : "orgDescription"} value={(this.state.roleTitle === 'Employer') ? this.state.employerDescription : this.state.orgDescription} onChange={this.formChangeHandler} />
                                  </div>
                                </div>
                              )}

                            </div>
                          )}

                          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2">{this.state.errorMessage}</p>}
                          {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2">{this.state.successMessage}</p>}

                          <div className="top-padding-40">
                            <div className="float-right">
                              <button className="btn btn-squarish" disabled={this.state.isSaving} onClick={() => this.logIn()}>Next</button>
                              {/*<button className="btn btn-squarish" onClick={() => this.setState({ pageNumber: 2 })}>Next</button>*/}
                              <div className="spacer" />
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="clear" />
                          <div className="spacer" />

                        </div>
                      )}
                      {(this.state.pageNumber === 2) && (
                        <div>
                          {(this.state.acceptableRole) ? (
                            <div>
                              <p className="description-text-3 center-text half-bold-text">STEP {this.state.currentStep} of {this.state.stepTotal}</p>
                              <p className="bold-text center-text">Enter Payment Details</p>
                              <div className="spacer" />
                              <p className="description-text-3 center-text">You are logged in as {this.state.emailId}. <label className="bold-text cta-color"><button className="background-button" onClick={() => this.logout()}>Sign Out</button></label></p>

                              <PreCheckoutForm history={this.props.navigate} passSubscriptionData={this.passSubscriptionData} pageSource="landingPage" roleName={this.state.roleTitle} orgType={this.state.orgType} orgName={this.state.orgName} orgURL={this.state.orgURL} orgRegion={this.state.orgRegion} orgDescription={this.state.orgDescription}
                                employerName={this.state.employerName} employerURL={this.state.employerURL} employerLocation={this.state.employerLocation} employerDescription={this.state.employerDescription}
                              />
                            </div>
                          ) : (
                            <div>
                              <p className="bold-text center-text">You are logged in as a <label className="italicize-text">{this.state.roleName}</label>. This subscription is for a(n) {this.state.roleTitle}.</p>
                              <div className="spacer" />
                              <p className="description-text-3 center-text">Log out and create a new acccount <label className="bold-text cta-color"><button className="background-button" onClick={() => this.logout()}>here</button></label></p>

                            </div>
                          )}
                        </div>
                      )}
                      {(this.state.pageNumber === 3) && (
                        <div>

                          <p className="description-text-3 center-text half-bold-text">STEP {this.state.currentStep} of {this.state.stepTotal}</p>
                          <p className="bold-text center-text">Start Using Your Subscription</p>
                          <div className="spacer" />

                          {(this.state.roleTitle === 'Career-Seeker' || this.state.roleTitle === 'Student') && (
                            <div>
                              <div className="top-padding-20 description-text-2 center-text">
                                <p>You have successfully subscribed! Look out for your email confirmation. You can reference <a href={this.state.careerSeekerOnboardingGuide} target="_blank" rel="noopener noreferrer"className="bold-text">This Master Guide</a> to get started. If you have questions at any time, please reach out to <label className="cta-color bold-text">{this.state.helpEmail}</label>.</p>
                              </div>

                              <div className="top-padding-40 center-text">
                                <Link to='/app'>
                                  <div className="cta-background-color slightly-rounded-corners row-10 horizontal-padding white-text clear-text-decoration description-text-2 bold-text width-145 center-horizontally">
                                    <p>Get Started</p>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          )}
                          {(this.state.roleTitle === 'Educator' || this.state.roleTitle === 'Workforce') && (
                            <div>
                              <div className="top-padding-20 description-text-2 center-text">
                                <p>You have successfully subscribed! Look out for your email confirmation. You can reference <a href={this.state.educatorOnboardingGuide} target="_blank" rel="noopener noreferrer"className="bold-text">This Master Guide</a> to get started. If you have questions at any time, please reach out to <label className="cta-color bold-text">{this.state.helpEmail}</label>.</p>
                              </div>

                              <div className="top-padding-40 center-text">
                                <Link to={'/organizations/' + this.state.org + '/dashboard'}>
                                  <div className="cta-background-color slightly-rounded-corners row-10 horizontal-padding white-text clear-text-decoration description-text-2 bold-text width-145 center-horizontally">
                                    <p>Get Started</p>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          )}
                          {(this.state.roleTitle === 'Employer') && (
                            <div>
                              <div className="top-padding-20 description-text-2 center-text">
                                <p>You have successfully subscribed! Look out for your email confirmation. If you have questions at any time, please reach out to <label className="cta-color bold-text">{this.state.helpEmail}</label>.</p>
                              </div>

                              <div className="top-padding-40 center-text">
                                <Link to={'/employers/' + this.state.accountCode + '/dashboard'}>
                                  <div className="cta-background-color slightly-rounded-corners row-10 horizontal-padding white-text clear-text-decoration description-text-2 bold-text width-145 center-horizontally">
                                    <p>Get Started</p>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          )}

                        </div>
                      )}

                    </div>
                    <div className="clear" />
                  </div>
                </div>
              ) : (
                <div className="top-padding-20">
                  <div className="card">
                    <p className="error-message">Invalid role</p>
                  </div>
                </div>
              )}

              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
               ariaHideApp={false}
             >

                {(this.state.showBenefits) && (
                  <div key="benefits" className="full-width padding-20">
                    <p className="full-width heading-text-2 center-text">{this.state.roleTitle} Benefits</p>
                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    {this.renderBenefits()}
                  </div>
                )}

                <div className="row-20 center-text">
                 <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>
             </Modal>
            </div>
          </div>
      )
    }
}


export default withRouter(Subscribe);
