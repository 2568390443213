import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
// import SubEmployerBenefits from './Common/EmployerBenefits';

const lausdLogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_11.jpg";
const uniteLALogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_12.png";
const dpscdLogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_14.png";
const blockKnowledgeLogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_15.png";

const exampleInternMatch = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-intern-match.png";
const testimonialBackground3 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/testimonial-background-3.png";
const quote3 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/quote-3.png";
const testimonialBackground4 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/testimonial-background-4.png";
const quote4 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/quote-4.png";

const appleStoreBadge = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/AppleStoreBadge.png';
const googlePlayBadge = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/GooglePlayBadge.png';
const udemyLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/udemy-logo.png';
const courseraLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/coursera-logo.png';
const udacityLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/udacity-logo.png';
const blsLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/bls-logo.png';
const onetLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/o-net-logo.png';
const greenhouseLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/greenhouse-logo.png';
const leverLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/lever-logo.png';
const jobDivaLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/jobdiva-logo.png';
const applicantProLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/applicantPro-logo.jpeg';

class EmployersPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      // this.retrieveData = this.retrieveData.bind(this)
    }

    componentDidMount() {
      //see if user is logged in
      console.log('counselors page just mounted')
      window.scrollTo(0, 0)

      const hcmServices = [
        'Internal Career Pathing','Timesheets','Learning & Development',
        'Employee Engagement','Employee Performance','Employee Referrals'
      ]

      const integrations = [
        { name: 'Greenhouse', category: 'Applicant Tracking Systems', imageURL: greenhouseLogo, description: "Greenhouse provides a recruiting software as a service", url: 'https://support.greenhouse.io/hc/en-us/articles/4403475645723-Guided-Compass-' },
        { name: 'Lever', category: 'Applicant Tracking Systems', imageURL: leverLogo, description: "Lever provides a recruiting software as a service", url: 'https://leverpartner.com/integrations/guided-compass' },
        { name: 'JobDiva', category: 'Applicant Tracking Systems', imageURL: jobDivaLogo, description: "JobDiva provides a recruiting software as a service", url: 'https://www.jobdiva.com' },
        { name: 'ApplicantPro', category: 'Applicant Tracking Systems', imageURL: applicantProLogo, description: "ApplicantPro provides a recruiting software as a service", url: 'https://www.applicantpro.com' },
        { name: 'U.S. Bureau of Statistics', category: 'Labor Market Data', imageURL: blsLogo, description: "The Bureau of Labor Statistics (BLS) is a unit of the United States Department of Labor", url: 'https://www.bls.gov' },
        { name: 'O-Net', category: 'Labor Market Data', imageURL: onetLogo, description: "The Occupational Information Network (O-Net) is a free online database that contains hundreds of job definitions to help students, job seekers, businesses and workforce development professionals to understand today's world of work in the United States.", url: 'https://www.onetonline.org' },
        { name: 'Udemy', category: 'Course Platforms', imageURL: udemyLogo, description: "Udemy is a for-profit Massive Open Online Course (MOOC) provider aimed at professional adults and students", url: 'https://www.udemy.com' },
        { name: 'Coursera', category: 'Course Platforms', imageURL: courseraLogo, description: "Coursera is a U.S.-based Massive Open Online Course (MOOC)", url: 'https://www.coursera.org' },
        { name: 'Udacity', category: 'Course Platforms', imageURL: udacityLogo, description: "Udacity is a U.S.-based Massive Open Online Course (MOOC)", url: 'https://www.udacity.com' },
        { name: 'Apple App Store', category: 'App Stores', imageURL: appleStoreBadge, description: "Apple App Store allows people to download apps on iOS phones and tablets", url: 'https://itunes.apple.com/us/app/guided-compass/id1436670877?ls=1&mt=8' },
        { name: 'Google Play Store', category: 'App Stores', imageURL: googlePlayBadge, description: "Google Play Store allows people to download apps on Android phones and tablets", url: 'https://play.google.com/store/apps/details?id=com.guidedcompass' },
      ]

      this.setState({ hcmServices, integrations })
    }

    componentWillUnmount(){
    }

    render() {

        return (
            <div>
                <TopNavigation currentPage={"employersPage"}/>

                <header className="employer-header">
                  <div className="hero-text-box-shortened">
                    <h1 className="light-text">Employers</h1>
                    <p>Improve talent acquisition, development, and retention</p>
                    <div className="spacer" /><div className="spacer" />

                    <Link className="btn btn-full" to={'/contact'} state={{ reason: 'Schedule Demo of Employer Portal' }}>Schedule a Demo</Link>
                  </div>
                </header>

                <section className="section-features white-background">
                    <div className="row">
                      <h2>Manage Internal Career Pathways</h2>

                      <div className="row-30 horizontal-padding-3">
                        <p className="center-text">Define the ideal competencies, project work, and coursework across each level of your department. Tie in performance evaluations, org charts, career planning, career advising, employee engagement, and team-building with the Guided Compass tool. Services include:</p>

                        {(this.state.hcmServices) && (
                          <div className="top-margin-40 bottom-margin-20">
                            {this.state.hcmServices.map((item, optionIndex) =>
                              <div className="top-margin">
                                <div className="relative-column-33 description-text-color">
                                  <p className="full-width center-text">{item}</p>
                                </div>
                                {(optionIndex % 3 === 2) && (
                                  <div className="clear" />
                                )}
                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        )}
                      </div>

                      <div className="row-10 horizontal-padding-3">
                        <hr />
                      </div>

                      <div className="bottom-margin-30 horizontal-padding-3">
                        {(this.state.integrations) && (
                          <div className="top-margin-40 bottom-margin-20">
                            <p className="center-text">Integrations include:</p>

                            {this.state.integrations.map((item, optionIndex) =>
                              <div className="top-margin bottom-margin-20">
                                <div className="relative-column-33 description-text-color">
                                  <img src={item.imageURL} alt="GC" className="image-auto-40 center-horizontally top-margin bottom-margin"/>
                                  <p className="full-width center-text">{item.name}</p>
                                  <p className="full-width center-text description-text-2">{item.category}</p>
                                </div>
                                {(optionIndex % 3 === 2) && (
                                  <div className="clear" />
                                )}
                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        )}
                      </div>

                      <div className="row-10 horizontal-padding-3">
                        <hr />
                      </div>

                      <div className="center-text top-margin-20">
                        <Link className="btn btn-primary cta-background-color white-text" to={'/contact'} state={{ reason: "Schedule Demo of Employer Portal", roleName: "Employer Representative", body: "I'm interested in scheduling a demo of the Human Capital Management (HCM) version of Guided Compass." }}>Schedule a Demo</Link>
                      </div>

                    </div>
                </section>

                <section className="section-workforce">
                  <div className="container-left">
                    <p className={(window.innerWidth < 768) ? "heading-text-2 full-width center-text" : "heading-text-2 full-width right-text"}>Not sure where to start?</p>
                  </div>
                  <div className={(window.innerWidth < 768) ? "container-right center-text top-margin" : "container-right"}>
                    <Link to={"/help"} state={{ selectedFilters: ["Employer"] }} className="btn white-background heading-text-5">View Help Articles</Link>
                  </div>
                  <div className="clear" />
                </section>

                <section className="section-features white-background">
                    <div className="row">
                      <h2>Easily Hire Screened, Referred Candidates</h2>

                      <div className="spacer" /><div className="spacer" />

                      <div className="row-30 horizontal-padding-3">
                        <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-40"}>
                          <button className="background-button" alt="GC" onClick={() => this.setState({ modalIsOpen: true, enlargeImage: true, selectedImage: 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/top-atss.png' })}>
                            <img src={'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/top-atss.png'} alt="GC" className="image-full-auto" />
                          </button>
                          <p className="description-text-2 bottom-margin">Click the image to enlarge</p>
                        </div>
                        <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-60 left-padding-30"}>
                          <p className="heading-text-3">1) Integrate ATS or Post Opportunities</p>

                          <div className="top-padding-20">
                            <p>We highly recommend an HR Manager approve Guided Compass to integrate with their Applicant Tracking System (ATS). Request an integration <Link to={"/contact"} state={{ reason: "Integrate with Applicant Tracking System", roleName: "Employer Representative" }}>here</Link>. Integrating means Guided Compass can automatically distribute opportunities, and push referred candidates to your posting without you moving a muscle.</p>
                            <p className="top-padding-30">In the absense of an integration, employers can post events, projects, and work opportunities <Link to={(this.state.orgSelected) ? "/employers/post/" + this.state.orgSelected.orgCode : "/employers/post"} target="_blank" className="bold-text">here</Link>.</p>
                          </div>
                        </div>
                        <div className="clear" />

                      </div>
                      <div className="row-30 horizontal-padding-3">
                        <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-60 right-padding-30"}>
                          <p className="heading-text-3">2) Hire Referred Candidates</p>

                          <div className="top-padding-20">
                            <p>Once referred candidates come in, please provide feedback on the candidates. If candidates are hired, we will make a note in our system.</p>
                            <p className="top-padding-30">If you provide feedback via the employer portal, referred candidates come in clickable profile cards that show match scores, summarize hours, and provide insight into intangible elements that indicate a good fit.</p>
                          </div>

                        </div>
                        <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-40"}>
                          <button className="background-button" alt="GC" onClick={() => this.setState({ modalIsOpen: true, enlargeImage: true, selectedImage: 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/EmployerCandidatesSS.png' })}>
                            <img src={'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/EmployerCandidatesSS.png'} alt="GC" className="image-full-auto" />
                          </button>
                          <p className="description-text-2 bottom-margin">Click the image to enlarge</p>
                        </div>
                        <div className="clear" />

                      </div>
                      <div className="row-30 horizontal-padding-3">
                        <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-40"}>
                          <button className="background-button" alt="GC" onClick={() => this.setState({ modalIsOpen: true, enlargeImage: true, selectedImage: exampleInternMatch })}>
                            <img src={exampleInternMatch} alt="GC" className="image-full-auto" />
                          </button>
                          <p className="description-text-2 bottom-margin">Click the image to enlarge</p>
                        </div>
                        <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-60 left-padding-30"}>
                          <p className="heading-text-3">3) Design Ideal Candidates</p>

                          <div className="top-padding-20">
                            <p>While it isn't necessary to customize ideal candidates (i.e., <Link to="/benefits/transparent-pathways" target="_blank" className="bold-text">benchmarks</Link>) for each of your departments, it will help us serve you better candidates.</p>
                            <p className="top-padding-30">Our algorithm scores against your benchmarks, and {(this.state.orgSelected) ? this.state.orgSelected.orgName : "our workforce and education partners"} will use your benchmarks to train and refer candidates.</p>
                          </div>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                </section>

                <div className="row">
                  <hr />
                </div>

                <div>
                  <div className="row top-margin-50 bottom-margin-30">
                    <h4 className="center-text">500+ Education/Workforce Partners Ready to Sponsor, Train, and Refer You The Perfect Candidate</h4>

                    <div className="row-10 flex-container-mobile-column box">
                      <div className="flex-20 box top-padding-5">
                        <img src={'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/orgLogos/yearup_logo_dark.png'} alt="GC" className="image-auto-60 center-horizontally wash-out-2" />
                      </div>
                      <div className="flex-20 box">
                        <img src={uniteLALogo} alt="GC" className="image-auto-60 center-horizontally wash-out-2" />
                      </div>
                      <div className="flex-20 box">
                        <img src={lausdLogo} alt="GC" className="image-auto-60 center-horizontally wash-out-2" />
                      </div>
                      <div className="flex-20 box">
                        <img src={dpscdLogo} alt="GC" className="image-auto-60 center-horizontally wash-out-2" />
                      </div>
                      <div className="flex-20 box">
                        <img src={blockKnowledgeLogo} alt="GC" className="image-auto-60 center-horizontally wash-out-2" />
                      </div>
                    </div>

                  </div>
                </div>

                <section className="section-features orange-background">
                    <div className="row">
                      <h2>Hear From Our Employer Partners</h2>
                    </div>

                    <div className="row top-margin-60">
                      <div className="container-left">
                        <div className="white-background relative-position">
                          <div className="padding-20">
                            <img src={quote3} alt="GC" className="image-auto-30" />
                          </div>

                          <div className="bottom-padding-20 horizontal-padding-5">
                            <p className="heading-text-5">“Guided Compass helped us define our ideal entry-level candidates, before recommending some great candidates for internships and full-time. We hired 5 people for our marketing and content teams through the platform. Our hires are highly engaged and very strong matches for our companies.”</p>
                          </div>

                          <div className="horizontal-padding-5 center-text full-width absolute-position pin-bottom">
                            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/featuredProfilePics/roger_smith.jpg" alt="GC" className="profile-thumbnail-6 center-horizontally" />

                            <div className="white-text bottom-padding-10-percent">
                              <p className="top-padding">Roger Smith</p>
                              <p className="description-text-2">President</p>
                              <p className="description-text-2">All Sports Television Network</p>
                            </div>
                          </div>

                          <img src={testimonialBackground3} alt="GC" className="image-full-auto" />
                        </div>
                      </div>
                      <div className="container-right">
                        <div className="white-background relative-position">
                          <div className="padding-20">
                            <img src={quote4} alt="GC" className="image-auto-30" />
                          </div>

                          <div className="bottom-padding horizontal-padding-5">
                            <p className="heading-text-5">“Guided Compass really helped me narrow down my search for diverse candidates that are a good fit for our firm. After a few events, we hired two interns that we continue to work with today. Creating the benchmarks alone, seemed to be helpful to the diverse communities Guided Compass works in.”</p>
                          </div>

                          <div className="horizontal-padding-5 center-text full-width absolute-position pin-bottom">
                            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/featuredProfilePics/sebastian_de_vivo.jpg" alt="GC" className="profile-thumbnail-6 center-horizontally" />

                            <div className="white-text bottom-padding-10-percent">
                              <p className="top-padding">Sebastian De Vivo</p>
                              <p className="description-text-2">Co-Founder & CEO</p>
                              <p className="description-text-2">List Ventures</p>
                            </div>
                          </div>

                          <img src={testimonialBackground4} alt="GC" className="image-full-auto" />
                        </div>
                      </div>
                      <div className="clear" />
                    </div>
                </section>

                <section className="section-join white-background">
                    <div className="join-container">
                        <div className="join-text-box">
                            <p className="join-text">Want to work with</p>
                            <p className="join-title">Us?</p>
                        </div>
                        <Link className="btn btn-full join-btn" to={'/contact'} state={{ reason: "I Want to Approve Benchmarks for my Org" }}>Schedule Demo</Link>
                    </div>
                </section>

                <Footer history={this.props.navigate} />

            </div>
        )
    }
}

export default EmployersPage;
