import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubSettings from '../Subcomponents/Settings';
import withRouter from '../Functions/WithRouter';

class OrgEditOrganizationProfile extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      const email = localStorage.getItem('email');
      const username = localStorage.getItem('username');

      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');
      let orgLogo = localStorage.getItem('orgLogo');

      if (email) {
        this.setState({ emailId: email, username, org, orgFocus, orgLogo, roleName });
      }

    }

    render() {


      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

          <div className="standard-container-2">
            <SubSettings activeOrg={this.state.org} history={this.props.navigate} />
          </div>

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(OrgEditOrganizationProfile)
