import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubCareerDetails from '../Subcomponents/CareerDetails';
import withRouter from '../Functions/WithRouter';

class OrgCareerDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.commandClicked = this.commandClicked.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org, careerSelected } = this.props.params
      // console.log('careerSelected:', careerSelected);

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      let orgLogo = localStorage.getItem('orgLogo');

      this.setState({ emailId: email, username, org, orgFocus, orgLogo, careerSelected })
    }

    commandClicked(passedCommand) {
      console.log('commandClicked called')

      this.setState({ passedCommand })

    }

    render() {
        return (
            <div>
              <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
              <SubCareerDetails careerSelected={this.state.careerSelected} history={this.props.navigate} commandClicked={this.commandClicked} />

              {(this.state.org) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo,this.state.passedCommand)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(OrgCareerDetails);
