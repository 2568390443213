import React, { Component} from 'react';
import Axios from 'axios';
import SubLogInWidget from '../../components/Common/LogInWidget';
import withRouter from '../Functions/WithRouter';

class LoginForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
          toggleLink: '/',
          gradYearOptions: [],
          pathwayOptions: [],
          roleNameOptions: [],
          acceptableRoles: ['','Student','Career-Seeker','School Support','Worker','Teacher','Counselor','WBLC','Mentor','Employer','Admin'],
        }

        this.retrieveData = this.retrieveData.bind(this)

    }

    componentDidMount() {
        console.log('login form page is mounting')

        this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within login form ', this.props, prevProps)

      if (this.props.roleName !== prevProps.roleName || this.props.orgCode !== prevProps.orgCode || this.props.courseId !== prevProps.courseId || this.props.workId !== prevProps.workId || this.props.fromExternal !== prevProps.fromExternal) {
        this.retrieveData()
      } else if (this.props.fromExternal !== prevProps.fromExternal) {
        this.retrieveData()
      } else if (this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.opportunityId !== prevProps.opportunityId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData in loginform', this.props.opportunityId)

      let orgCode = this.props.orgCode
      const courseId = this.props.courseId
      const workId = this.props.workId
      const accountCode = this.props.accountCode
      let roleName = this.props.roleName
      const opportunityId = this.props.opportunityId
      const path = window.location.pathname

      let toggleLink = ''
      if (path) {
        if (path.includes("join") || path.includes("signup")) {
          toggleLink = path.replace("join","signin").replace("signup","signin")
        } else if (path.includes("signin")){
          toggleLink = path.replace("signin","signup")
        }
      }

      let mobileView = false
      if (window.innerWidth <= 768) {
        mobileView = true
      }

      Axios.get('/api/emails', { params: { orgCode } })
      .then((response) => {
        console.log('Emails query attempted', response.data);

          if (response.data.success) {
            console.log('emails query worked')

            const studentBenefits = response.data.benefits[0].studentBenefits
            const careerSeekerBenefits = response.data.benefits[0].studentBenefits
            const teacherBenefits = response.data.benefits[0].teacherBenefits
            const mentorBenefits = response.data.benefits[0].mentorBenefits
            const employerBenefits = response.data.benefits[0].employerBenefits

            this.setState({ studentBenefits, careerSeekerBenefits, teacherBenefits, mentorBenefits, employerBenefits });

          } else {
            console.log('emails query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Emails query did not work for some reason', error);
      });

      if (roleName && orgCode) {
        roleName = roleName.charAt(0).toUpperCase() + roleName.slice(1)

        let isValid = false
        if (this.state.acceptableRoles.includes(roleName)) {
          if (orgCode !== '') {
            isValid = true
          }
        }

        let gradYearOptions = ['']
        const currentYear = new Date().getFullYear()
        for (let i = 1; i <= 5; i++) {

          gradYearOptions.push(currentYear + i - 1)
        }

        console.log('oppId 2: ', opportunityId)

        this.setState({ roleName, originalRoleName: true, orgCode, path, isValid, courseId, workId, opportunityId,
          accountCode, toggleLink, mobileView, gradYearOptions })

        // Axios.get('/api/org', { params: { orgCode} })
        // .then((response) => {
        //   console.log('Org info query attempted', response.data);
        //
        //     if (response.data.success) {
        //       console.log('org info query worked')
        //
        //       const school = response.data.orgInfo.orgName
        //       const schoolDistrict = response.data.orgInfo.district
        //       const orgName = response.data.orgInfo.orgName
        //       const orgLogoURI = response.data.orgInfo.webLogoURI
        //       const headerImageURL = response.data.orgInfo.headerImageURL
        //       const orgFocus = response.data.orgInfo.orgFocus
        //       const publicOrg = response.data.orgInfo.isPublic
        //       const placementPartners = response.data.orgInfo.placementPartners
        //
        //       let pathwayOptions = ['']
        //       let includePathway = false
        //
        //       this.setState({
        //         school, schoolDistrict, orgName, orgLogoURI, headerImageURL, orgFocus, pathwayOptions, includePathway,
        //         publicOrg, placementPartners
        //       });
        //
        //     } else {
        //       console.log('org info query did not work', response.data.message)
        //     }
        //
        // }).catch((error) => {
        //     console.log('Org info query did not work for some reason', error);
        // });

        // if (courseId) {
        //   Axios.get('/api/courses', { params: { courseIds: [courseId]} })
        //   .then((response) => {
        //     console.log('Course info query attempted', response.data);
        //
        //       if (response.data.success) {
        //         console.log('course info query worked')
        //
        //         if (response.data.courses && response.data.courses.length > 0) {
        //           const courseName = response.data.courses[0].name
        //           this.setState({ courseName })
        //         }
        //
        //       } else {
        //         console.log('course info query did not work', response.data.message)
        //       }
        //
        //   }).catch((error) => {
        //       console.log('Course info query did not work for some reason', error);
        //   });
        // }

      } else if (roleName) {
        // no orgCode
        console.log('show toggle 3: ', toggleLink )
        this.setState({ roleName, originalRoleName: true, path, accountCode, toggleLink, mobileView, orgCode, opportunityId })
      } else {
        // no orgCode or roleName
        const roleNameOptions = ['Student / Career Seeker','Teacher','Work-Based Learning Coordinator','Mentor','Employer Representative','Other']
        this.setState({ path, accountCode, toggleLink, mobileView, orgCode, opportunityId, roleNameOptions })
      }
    }

    render() {

      return (

        <div>
          <SubLogInWidget history={this.props.navigate} type={this.props.type}
            roleName={this.props.roleName} orgCode={this.props.orgCode}
            opportunityId={this.props.opportunityId} opportunityOrg={this.props.opportunityOrg}
            courseId={this.props.courseId} workId={this.props.workId}
            accountCode={this.props.accountCode}
            fromExternal={this.props.fromExternal}
          />
        </div>

      )
    }
}

export default withRouter(LoginForm);
