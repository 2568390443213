
export const calculateAge = (dateOfBirth)=>{
  // console.log('calculateAge called', dateOfBirth)

  if (dateOfBirth) {

    let age = ''
    if (new Date(dateOfBirth)) {
      // difference between dates rounded to the nearest year

      let diff = new Date().getTime() - new Date(dateOfBirth).getTime()

      diff = diff / 1000

      diff = diff / (60 * 60 * 24)

      diff = diff / (365.25)
      
      age = Math.floor(diff)
    }

    return age

  } else {

    return 0
  }
}
