import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubGoalDetails from './Subcomponents/GoalDetails';
import withRouter from './Functions/WithRouter';

class GoalDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.loadWorkspace = this.loadWorkspace.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('opportunity mounted')

      let email = localStorage.getItem('email');
      const orgLogo = localStorage.getItem('orgLogo');

      if (!this.props.location.state) {
        console.log('went directly to goal', this.props.params)

        let { goalId } = this.props.params
        this.setState({ goalId, orgLogo })

      }

      if (this.props.location && this.props.location.state) {

        const { selectedGoal, objectId } = this.props.location.state;
        console.log('show location state: ', this.props.location.state)

        if (selectedGoal) {
          this.setState({ selectedGoal, orgLogo })

        } else if (objectId) {
          this.setState({ goalId: objectId, orgLogo })
        }
      }
    }

    componentDidUpdate(prevProps) {
        console.log('componentDidUpdate called within parentGoalDetails ', this.props, prevProps)

      if (this.props.params && this.props.params.goalId !== prevProps.params.goalId) {
        let selectedGoal = this.props.location.state
        if (this.props.location && this.props.location.state && this.props.location.state.selectedGoal) {
          selectedGoal = this.props.location.state.selectedGoal
        }
        this.setState({ goalId: this.props.params.goalId, selectedGoal })
      }
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    render() {

      return (
        <div>
          <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} loadWorkspace={this.loadWorkspace} goalId={this.state.goalId} />

          <div>
            <SubGoalDetails selectedGoal={this.state.selectedGoal} goalId={this.state.goalId} history={this.props.navigate} />
          </div>

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(GoalDetails)
