import React, {Component} from 'react';
import TopNavigation from './TopNavigation';
import Footer from './Footer';

class PrivacyPolicyPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {

      window.scrollTo(0, 0)
      document.body.style.backgroundColor = "white";

    }

    mobileNav (event) {
        console.log('mobile nav clicked')
        if (this.state.mobileNavClass === "side-nav") {
            this.setState({
                mobileNavClass: 'side-nav-mobile',
                isVisible: false
            })
        } else {
            this.setState({
                mobileNavClass: 'side-nav',
                isVisible: true
            })
        }
    }

    render() {
        return (

          <div>
              <TopNavigation currentPage={"PrivacyPolicyPage"} darkBackground={true} />

              <section className="section-features">
                <div className="row">
                  <h2>Guided Compass Privacy Policy</h2>

                  <div className="row-10">
                    <p>Guided Compass provides a personalized subscription service that allows our members to be recommended, access, and interact with educational and career-related opportunities ("Guided Compass Opportunities") enabled via Internet to certain Internet-connected computers and other devices ("Guided Compass ready devices").</p>
                  </div>

                  <div className="row-10">
                    <h3>What Information We Collect</h3>

                    <p>We collect personal and non-personal data from you when you use our Services. Personal Data is data that includes a personal identifier like your name, email or address, phone number, IP address or device identifier, or is data that could reasonably be linked back to you. It also may include demographic information, geolocation information, educational information, or commercial information.</p>

                    <ul className="left-padding-30 row-10">
                      <li className="row-10"><label className="bold-text">Career-seeker profile data.</label> This data includes email, phone number, first name, last name, school, work experience, trascript, letters of recommendation, interests, activities, and diversity information.</li>
                      <li className="row-10"><label className="bold-text">Usage and log data.</label> When you visit the Site, we log and store your IP Address and technical information about your visit like your browser type and how you progressed through the Site, where you abandoned it, etc. (“Usage Data”). We can use your IP address to determine your general location.</li>
                      <li className="row-10"><label className="bold-text">Mobile data.</label> When you use the Guided Compass mobile app, we collect analytic information about your device, such as IP address, OS version, and clickstream.</li>
                      <li className="row-10"><label className="bold-text">Precise location data.</label> For a limited set of features, we allow users to opt-in to our collection of precise location data (GPS data). We only use this information to enable these features, such as displaying your precise location on a map at a career fair. We do not share precise location data with third parties, and we do not combine precise location data with personal information for advertising purposes.</li>
                      <li className="row-10"><label className="bold-text">Employer data.</label> When Employers create a Guided Compass Employer Account, we request contact information, including email address and telephone number, to provide a point of contact for career-seekers and work-based learning program staff. We will not use your phone number to send any commercial or marketing messages not related to your use of Guided Compass Services to you without your express consent.</li>
                    </ul>
                  </div>

                  <div className="row-10">
                    <h3>How We Use Your Personal Data</h3>

                    <p>We use, process and store your Personal Data to provide the Guided Compass Service and to maximize opportunities to connect you with potential employers. This includes:</p>
                    <ul className="left-padding-30 row-10">
                      <li className="row-10"><label className="bold-text">Career Paths.</label> We use your data to recommend career paths.</li>
                      <li className="row-10"><label className="bold-text">Career Events.</label> We use your data to recommend career events, import your data for RSVPing to career events, and interacting with professionals at these events.</li>
                      <li className="row-10"><label className="bold-text">Projects.</label> We use your data to recommend project opportunities, import your data into any projects you submit for solving employer assignments, problems, and/or challenges (i.e., competitions).</li>
                      <li className="row-10"><label className="bold-text">Work.</label> We use your data to recommend work opportunities, import your data into any projects you submit for solving employer assignments, problems, and/or challenges (i.e., competitions).</li>
                      <li className="row-10"><label className="bold-text">Courses and Training Programs.</label> We use your data to recommend courses and training programs you should consider enrolling in.</li>
                      <li className="row-10"><label className="bold-text">Trends.</label> We use your data to recommend labor market trends and other trends that you may want to consider when choosing considering career paths.</li>
                      <li className="row-10"><label className="bold-text">Financials.</label> We use your data to recommend career goals and project your financials based on career / lifestyle decisions.</li>

                    </ul>
                  </div>

                  <div className="row-10">
                    <h3>When We Share Your Personal Data</h3>

                    <p>We will only share your personal data under the following circumstances:</p>
                    <ul className="left-padding-30 row-10">
                      <li className="row-10">With your consent. For example, you may choose to make your profile public so that we can share it with employers through the Services or choose to apply to a job and we will share your profile information, resume and transcript with the employers you choose. Employers are required to only use Guided Compass data to provide employment services and to safeguard any personal data they receive. You may also choose to make your profile public to specific audiences to help grow your professional brand and network. When posting an employer review or other related content, you may choose to publish your name and profile alongside the content publicly. You may also choose to post the content without any directly identifying information (i.e. you may post “pseudonymously”). Please note, even when posting pseudonymously, it may be possible to infer your identity from the content, and we share the identities of both public and pseudonymous content with work-based learning programs.</li>
                      <li className="row-10">With vendors that are contractually engaged to provide us with services, such as cloud service providers and other services for maintaining our systems, order fulfillment, email management and credit card processing. These companies are obligated by contract to safeguard any personal data they receive from us.</li>
                      <li className="row-10">With vendors that are contractually engaged to provide us with services, such as order fulfillment, email management and credit card processing. These companies are obligated by contract to safeguard any personal data they receive from us.</li>
                      <li className="row-10">With relevant law enforcement bodies if we believe that disclosure is reasonably necessary to comply with a law, regulation, valid legal process (e.g., subpoenas or warrants served on us), or governmental or regulatory request, to enforce or apply the Terms of Use, to protect the security or integrity of the Guided Compass Service, and/or to protect the rights, property, or safety of Guided Compass, its employees, users, or others. If we are going to release your data, we will do our best to provide you with notice in advance by email, unless we are prohibited by law from doing so.</li>
                      <li className="row-10">With professional services, potential purchasers or investors in connection with the sale, merger, bankruptcy, sale of assets or reorganization of our company. We will notify you if a different company will receive your personal data and the promises in this Privacy Policy will apply to your data as transferred to the new entity.</li>
                    </ul>
                  </div>

                  <div className="row-10">
                    <h3>Cookies</h3>

                    <p className="row-10">As a Site visitor, we place a small piece of software referred to as a “session cookie” on your computer. If you use the Services and create an account, we also use this cookie to recognize you on return visits and expedite the login process. You can remove this and other cookies through your browser preferences menu, though the exact method varies depending on the type of browser you use – but note that you won’t be able to log into our service if your cookies are disabled. If you visit from a mobile device, we may reference your device identifier or other information about where your visit originated.</p>

                    <p className="row-10">Our email communications contain web beacons or similar technology which track when the email has been opened or read. This functionality sends the resulting records back to our Service, which may be associated with other information you have provided us.</p>
                  </div>

                  <div className="row-10">
                    <h3>Opt Out of Email Communications</h3>

                    <p className="row-10">To stop receiving notifications or promotions, please click the unsubscribe link found at the bottom of each email or update your account preferences. For EEA users: We only send marketing communications to users located in the EEA with your prior consent. Please see the section “GDPR: Information For EEA Users” below.</p>
                  </div>

                  <div className="row-10">
                    <h3>De-activating your account or deleting your personal data</h3>

                    <p className="row-10">You can choose to deactivate your account so that you are no longer viewable on the Service or you can make your account private. You may also request Guided Compass delete information about you, however we may be obligated as a service provider to your program to retain certain data about you. This means that if they re-send us your data to process on their behalf, we will retain it unless they request we delete it. You can request deactivation or deletion by sending a message to info@guidedcompass.com or by contacting your work-based learning program to ask them to delete your information.</p>
                  </div>

                  <div className="row-10">
                    <h3>Third Party Service Policies</h3>

                    <p className="row-10"><label className="bold-text">Google & YouTube: </label> Guided Compass’s use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank" rel="noopener noreferrer">Google API Services User Data Policy</a>, including the Limited Use requirements. In addition, we utilize the YouTube service to provide fresh video content. Please view their <a href="https://www.youtube.com/t/terms" target="_blank" rel="noopener noreferrer">YouTube Terms of Service</a> and <a href="https://policies.google.com/privacy"  target="_blank" rel="noopener noreferrer">Google Privacy Policy</a> pages.</p>
                  </div>
                </div>
              </section>

              <Footer history={this.props.navigate} />
          </div>

        )
    }
}

export default PrivacyPolicyPage;
