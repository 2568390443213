import React, {Component} from 'react';
import SubChangePassword from '../Common/ChangePassword';
import withRouter from '../Functions/WithRouter';

class ChangePassword extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubChangePassword', this.props, prevProps)

      if (this.props.email !== prevProps.email) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubChangePassword')

      const email = this.props.email
      this.setState({ email })

    }

    render() {
        return (
            <div>
              <div className="standard-container-2">
                <div className="spacer" />
                {(this.state.email) ? (
                  <SubChangePassword history={this.props.navigate} email={this.state.email} />
                ) : (
                  <div>
                    <p className="error-message">There was an error retrieving your account email</p>
                  </div>
                )}
              </div>
            </div>
        )
    }
}


export default withRouter(ChangePassword);
