import React, {Component} from 'react';
import { Link } from 'react-router-dom';
// import Axios from 'axios';

const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';

class BenchmarkDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.returnColorClass = this.returnColorClass.bind(this)

    }

    componentDidMount() {
      //see if user is logged in
      console.log('profilePage just mounted')

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubConfigureEndorsement', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.posts !== prevProps.posts) {
        this.retrieveData()
      } else if (this.props.selectedBenchmark !== prevProps.selectedBenchmark) {
        this.retrieveData()
      } else if (this.props.selectedBenchmark && prevProps.selectedBenchmark) {
        console.log('both are here')
        if (this.props.selectedBenchmark.title !== prevProps.selectedBenchmark.title) {
          this.retrieveData()
        }
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubConfigureProfileMatch', this.props.selectedBenchmark)

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const roleName = localStorage.getItem('roleName');
      let pictureURL = localStorage.getItem('pictureURL');

      let selectedBenchmark = this.props.selectedBenchmark
      let workPreferenceTags = []
      let interests = []
      let traits = []
      let knowledge = []
      if (selectedBenchmark) {
        if (selectedBenchmark.functionsOfInterest && selectedBenchmark.functionsOfInterest.length > 0) {
          workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.functionsOfInterest)
        }
        if (selectedBenchmark.industriesOfInterest && selectedBenchmark.industriesOfInterest.length > 0) {
          workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.industriesOfInterest)
        }
        if (selectedBenchmark.technologyTrends && selectedBenchmark.technologyTrends.length > 0) {
          workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.technologyTrends)
        }
        if (selectedBenchmark.additionalTechnologyTrends && selectedBenchmark.additionalTechnologyTrends.length > 0) {
          workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.additionalTechnologyTrends)
        }
        if (selectedBenchmark.societalProblems && selectedBenchmark.societalProblems.length > 0) {
          workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.societalProblems)
        }
        if (selectedBenchmark.additionalSocietalProblems && selectedBenchmark.additionalSocietalProblems.length > 0) {
          workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.additionalSocietalProblems)
        }
        if (selectedBenchmark.maxPay && selectedBenchmark.maxPay !== '') {
          if (selectedBenchmark.maxPay === 'Wide Range') {
            workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.maxPay + ' of Pay')
          } else {
            workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.maxPay)
          }
        }

        if (selectedBenchmark.interests && selectedBenchmark.interests.length > 0) {
          interests = selectedBenchmark.interests
          interests.sort(function(a,b) {
            return b.score - a.score;
          })
        }

        if (selectedBenchmark.traits && selectedBenchmark.traits.length > 0) {
          traits = selectedBenchmark.traits
          traits.sort(function(a,b) {
            return b.score - a.score;
          })
        }

        if (selectedBenchmark.knowledge && selectedBenchmark.knowledge.length > 0) {
          for (let i = 1; i <= selectedBenchmark.knowledge.length; i++) {
            knowledge.push(selectedBenchmark.knowledge[i - 1].title)
          }
        }
      }

      this.setState({ emailId, cuFirstName, cuLastName, activeOrg, roleName, username, pictureURL,
        selectedBenchmark, workPreferenceTags, interests, traits, knowledge
      })
    }

    renderTags(type, passedArray, limit) {
      console.log('renderTags: ', type, passedArray, limit)

      let backgroundColorClass = "primary-background"
      let includeLink = false
      if (type === 'gravitateValues') {
        backgroundColorClass = "secondary-background"
      } else if (type === 'employerValues') {
        backgroundColorClass = "tertiary-background"
      } else if (type === 'hardSkills') {
        backgroundColorClass = "quaternary-background"
      } else if (type === 'softSkills') {
        backgroundColorClass = "quinary-background"
      } else if (type === 'workPreferenceTags') {
        backgroundColorClass = "nonary-background"
      } else if (type === 'knowledge') {
        backgroundColorClass = "denary-background"
      } else if (type === 'courses') {
        backgroundColorClass = "denary-background"
        includeLink = true
      }

      if (passedArray) {
        if (includeLink) {
          return (
            <div key={type + "|0"}>
              <div className="spacer" />
              {passedArray.map((value, optionIndex) =>
                <div key={type + "|" + optionIndex} className="float-left">
                  {(!limit || (limit && optionIndex < limit)) && (
                    <a className="float-left right-padding-5" target="_blank" rel="noopener noreferrer" href={value.url}>
                      <div className="half-spacer" />
                      <div className={"tag-container-basic " + backgroundColorClass}>
                        <p className="description-text-2 white-text">{value.name}</p>
                      </div>
                      <div className="half-spacer" />
                    </a>
                  )}
                </div>
              )}
            </div>
          )
        } else {
          return (
            <div key={type + "|0"}>
              <div className="spacer" />
              {passedArray.map((value, optionIndex) =>
                <div key={type + "|" + optionIndex} className="float-left">
                  {(!limit || (limit && optionIndex < limit)) && (
                    <div className="float-left right-padding-5">
                      <div className="half-spacer" />
                      <div className={"tag-container-basic " + backgroundColorClass}>
                        <p className="description-text-2 white-text">{value}</p>
                      </div>
                      <div className="half-spacer" />
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        }
      }
    }

    returnColorClass(index,type) {
      console.log('returnColorClass called', index, type)

      let colorClass = "nonary-background"
      if (type === 'text') {
        if (index === 0) {
          colorClass = 'nonary-text'
        } else if (index === 1) {
          colorClass = 'primary-text'
        } else if (index === 2) {
          colorClass = 'twelve-text'
        } else if (index === 3) {
          colorClass = 'denary-text'
        } else if (index === 4) {
          colorClass = 'thirteen-text'
        } else if (index === 5) {
          colorClass = 'secondary-text'
        }
      } else if (type === 'background') {
        if (index === 0) {
          colorClass = 'nonary-background'
        } else if (index === 1) {
          colorClass = 'primary-background'
        } else if (index === 2) {
          colorClass = 'twelve-background'
        } else if (index === 3) {
          colorClass = 'denary-background'
        } else if (index === 4) {
          colorClass = 'thirteen-background'
        } else if (index === 5) {
          colorClass = 'secondary-background'
        }
      }
      return colorClass
    }

    render() {

      return (
        <div key="showBenchmarkDetails" className="full-width">

          <div className="row-10">
            <div className="calc-column-offset-80">
              <p className="heading-text-2">Ideal Candidate Profile</p>
              <p className="description-text-1 description-text-color top-padding">{this.props.title}</p>
            </div>
            <div className="fixed-column-40 left-padding top-padding-5">
              <button className="background-button" onClick={() => this.setState({ showDescription: true })}>
                <img src={questionMarkBlue} alt="GC" className="image-auto-15" />
              </button>
            </div>
            <div className="fixed-column-40 left-padding top-padding-5">
              <button className="background-button" onClick={() => this.props.closeModal()}>
                <img src={closeIcon} alt="GC" className="image-auto-15" />
              </button>
            </div>
            <div className="clear" />
          </div>

           <div className="spacer" />

           {(this.state.showDescription) && (
             <div className="padding-10 error-border top-margin bottom-margin">
                <div className="row-10">
                  <div className="calc-column-offset-40">
                    <p className="description-text-1">The below benchmark (i.e., ideal candidate profile) is meant to provide an approximate gauge of what a successful candidate profile looks like. It's also what is compared to your profile to calculate your match score. Learn more <Link to="/help/what-are-benchmarks" target="_blank">here</Link>.</p>
                  </div>
                  <div className="fixed-column-40">
                    <button className="background-button" onClick={() => this.setState({ showDescription: false })}>
                      <img src={closeIcon} alt="GC" className="image-auto-15" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>
             </div>
           )}

           <div>

            {(this.state.selectedBenchmark) && (
              <div>
                {(this.state.selectedBenchmark.workPreferenceWeight > 0) && (
                  <div>
                    <div className="row-10">
                      <div className="float-left">
                        <p className="heading-text-5">Work Preferences (Self-Assessment)</p>
                      </div>
                      <div className="float-left left-padding">
                        <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.workPreferenceWeight) ? "(" + this.state.selectedBenchmark.workPreferenceWeight + "%)" : "(0%)"}</p>
                      </div>
                      <div className="clear" />
                      <p className="description-text-1 description-text-color top-padding">What the ideal candidate explicitly says they want in their work, work style, and work environment</p>
                    </div>

                    {(this.state.workPreferenceTags && this.state.workPreferenceTags.length > 0) && (
                      <div className="row-10">
                        {this.renderTags('workPreferenceTags', this.state.workPreferenceTags)}
                        <div className="clear" />
                      </div>
                    )}

                    {(this.state.selectedBenchmark.workPreferencesMessage) && (
                      <div className="row-10">
                        <p className="italicize-text">"{this.state.selectedBenchmark.workPreferencesMessage}"</p>
                      </div>
                    )}

                    <div className="row-10">
                      <hr />
                    </div>
                  </div>
                )}
                {(this.state.selectedBenchmark.interestsWeight > 0) && (
                  <div>
                    <div className="row-10">
                      <div className="float-left">
                        <p className="heading-text-5">Interests (Self-Assessment)</p>
                      </div>
                      <div className="float-left left-padding">
                        <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.interestsWeight) ? "(" + this.state.selectedBenchmark.interestsWeight + "%)" : "(0%)"}</p>
                      </div>
                      <div className="clear" />
                      <p className="description-text-1 description-text-color top-padding">Candidate general interests and strong interest inventory</p>
                    </div>

                    {(this.state.selectedBenchmark.generalInterests && this.state.selectedBenchmark.generalInterests.length > 0) && (
                      <div className="row-10">
                        {this.renderTags('generalInterests', this.state.selectedBenchmark.generalInterests)}
                        <div className="clear" />
                      </div>
                    )}

                    {(this.state.interests && this.state.interests.length > 0) && (
                      <div className="standard-border padding-30 top-margin-20">
                        <p className="heading-text-5">Strong Interest Inventory Results</p>
                        <p className="row-5 description-text-2">Strong Interest Inventory is a popular interest assessment used by career counselors. Learn more <a href="https://en.wikipedia.org/wiki/Strong_Interest_Inventory" target="_blank" rel="noopener noreferrer">here</a>.</p>
                        <div className="spacer" /><div className="spacer" />
                        {this.state.interests.map((value, optionIndex) =>
                          <div key={value}>
                            <div className="fixed-column-130">
                              <p className={this.returnColorClass(optionIndex,'text')}>{value.title}</p>
                            </div>
                            <div className="calc-column-offset-190 top-padding-5">
                              <div className="mini-spacer" />
                              <div className="progress-bar clear-border" >
                                <div className={"filler " + this.returnColorClass(optionIndex,'background')} style={(value.score) ? { width: (value.score * 20).toString() + '%' }: { width: '0%' }} />
                              </div>
                            </div>
                            <div className="fixed-column-60">
                              <p className={"full-width right-text " + this.returnColorClass(optionIndex,'text')}>{(value.score) ? (value.score * 20).toString() + '%' : '0%'}</p>
                            </div>
                            <div className="clear" />
                            {(optionIndex + 1 !== this.state.interests.length) && (
                              <div className="row-10">
                                <hr />
                              </div>
                            )}
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    )}

                    <div className="spacer" />

                    {(this.state.selectedBenchmark.interestsMessage) && (
                      <div className="row-10">
                        <p className="italicize-text">"{this.state.selectedBenchmark.interestsMessage}"</p>
                      </div>
                    )}

                    <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                    <div className="row-10">
                      <hr />
                    </div>
                  </div>
                )}

                {(this.state.selectedBenchmark.personalityWeight > 0) && (
                  <div>
                    <div className="row-10">
                      <div className="float-left">
                        <p className="heading-text-5">Personality (Self-Assessment)</p>
                      </div>
                      <div className="float-left left-padding">
                        <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.personalityWeight) ? "(" + this.state.selectedBenchmark.personalityWeight + "%)" : "(0%)"}</p>
                      </div>
                      <div className="clear" />
                      <p className="description-text-1 description-text-color top-padding">The big five, 16 personalities, and other personality traits</p>
                    </div>

                    {(this.state.selectedBenchmark.additionalTraits && this.state.selectedBenchmark.additionalTraits.length > 0) && (
                      <div className="row-10">
                        {this.renderTags('additionalTraits', this.state.selectedBenchmark.additionalTraits)}
                        <div className="clear" />
                      </div>
                    )}

                    {(this.state.selectedBenchmark.myersBriggs) && (
                      <div className="standard-border padding-30 top-margin-20">
                        <p className="heading-text-5">16 Personalities Results</p>
                        <p className="row-5 description-text-2">16 personalities / myers briggs is a popular way for career counselors to distinguish how people have different personalities. Learn more <a href="https://en.wikipedia.org/wiki/Myers–Briggs_Type_Indicator" target="_blank" rel="noopener noreferrer">here</a>.</p>
                        <div className="spacer" />
                        <p className="heading-text-2 cta-color">{this.state.selectedBenchmark.myersBriggs}</p>
                        <div className="clear" />
                      </div>
                    )}

                    {(this.state.traits && this.state.traits.length > 0) && (
                      <div className="standard-border padding-30 top-margin-20">
                        <p className="heading-text-5">Big Five Results</p>
                        <p className="row-5 description-text-2">The Big Five personality traits is a popular way for career counselors and psychologists to distinguish how people have different personalities. Learn more <a href="https://en.wikipedia.org/wiki/Big_Five_personality_traits" target="_blank" rel="noopener noreferrer">here</a>.</p>
                        <div className="spacer" /><div className="spacer" />
                        {this.state.traits.map((value, optionIndex) =>
                          <div key={value}>
                            <div className={(window.innerWidth > 768) ? "fixed-column-180" : "full-width"}>
                              <p className={this.returnColorClass(optionIndex,'text')}>{value.title}</p>
                            </div>
                            <div className={(window.innerWidth > 768) ? "calc-column-offset-240 top-padding-5" : "full-width top-padding-5"}>
                              <div className="mini-spacer" />
                              <div className="progress-bar clear-border" >
                                <div className={"filler " + this.returnColorClass(optionIndex,'background')} style={(value.score) ? { width: (value.score * 20).toString() + '%' }: { width: '0%' }} />
                              </div>
                            </div>
                            <div className="fixed-column-60">
                              <p className={(window.innerWidth > 768) ? "full-width right-text " + this.returnColorClass(optionIndex,'text') : "full-width " + this.returnColorClass(optionIndex,'text')}>{(value.score) ? (value.score * 20).toString() + '%' : '0%'}</p>
                            </div>
                            <div className="clear" />
                            {(optionIndex + 1 !== this.state.traits.length) && (
                              <div className="row-10">
                                <hr />
                              </div>
                            )}
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    )}

                    {(this.state.selectedBenchmark.personalityMessage) && (
                      <div className="row-10">
                        <p className="italicize-text">"{this.state.selectedBenchmark.personalityMessage}"</p>
                      </div>
                    )}

                    <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                    <div className="row-10">
                      <hr />
                    </div>
                  </div>
                )}

                {(this.state.selectedBenchmark.valuesWeight > 0) && (
                  <div>
                    <div className="row-10">
                      <div className="float-left">
                        <p className="heading-text-5">Values (Self-Assessment)</p>
                      </div>
                      <div className="float-left left-padding">
                        <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.valuesWeight) ? "(" + this.state.selectedBenchmark.valuesWeight + "%)" : "(0%)"}</p>
                      </div>
                      <div className="clear" />
                      <p className="description-text-1 description-text-color top-padding">Ranked values on who candidate gravitates towards and what their employer prefers</p>
                      <div className="spacer" /><div className="spacer" />
                    </div>

                    {(this.state.selectedBenchmark.gravitateValues && this.state.selectedBenchmark.gravitateValues.length > 0) && (
                      <div className="row-10">
                        <p>The ideal{(this.state.selectedBenchmark.jobFunction) && " " + this.state.selectedBenchmark.jobFunction.toLowerCase()} candidate gravitates toward people who are:</p>
                        {this.renderTags('gravitateValues', this.state.selectedBenchmark.gravitateValues, 5)}
                        <div className="clear" />
                      </div>
                    )}
                    {(this.state.selectedBenchmark.employerValues && this.state.selectedBenchmark.employerValues.length > 0) && (
                      <div className="row-10">
                        <p>The ideal{(this.state.selectedBenchmark.jobFunction) && " " + this.state.selectedBenchmark.jobFunction.toLowerCase()} candidate wants to work with employers that provide:</p>
                        {this.renderTags('employerValues', this.state.selectedBenchmark.employerValues, 5)}
                        <div className="clear" />
                      </div>
                    )}

                    {(this.state.selectedBenchmark.valuesMessage) && (
                      <div className="row-10">
                        <p className="italicize-text">"{this.state.selectedBenchmark.valuesMessage}"</p>
                      </div>
                    )}

                    <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                    <div className="row-10">
                      <hr />
                    </div>
                  </div>
                )}

                {(this.state.selectedBenchmark.skillsWeight > 0) && (
                  <div>
                    <div className="row-10">
                      <div className="float-left">
                        <p className="heading-text-5">Skills (Self-Assessment)</p>
                      </div>
                      <div className="float-left left-padding">
                        <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.skillsWeight) ? "(" + this.state.selectedBenchmark.skillsWeight + "%)" : "(0%)"}</p>
                      </div>
                      <div className="clear" />
                      <p className="description-text-1 description-text-color top-padding">The top skills that matter</p>
                      <div className="spacer" />
                    </div>

                    <div className="row-10">
                      <div className="container-left">
                        {((this.state.selectedBenchmark.highPriorityHardSkills && this.state.selectedBenchmark.highPriorityHardSkills.length > 0) || (this.state.selectedBenchmark.lowPriorityHardSkills && this.state.selectedBenchmark.lowPriorityHardSkills.length > 0)) && (
                          <div className="row-10">
                            <p>Top hard skills:</p>

                            {(this.state.selectedBenchmark.lowPriorityHardSkills) ? (
                              <div>
                                {this.renderTags('hardSkills', this.state.selectedBenchmark.highPriorityHardSkills.concat(this.state.selectedBenchmark.lowPriorityHardSkills), 6)}
                              </div>
                            ) : (
                              <div>
                                {this.renderTags('hardSkills', this.state.selectedBenchmark.highPriorityHardSkills, 6)}
                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                      <div className="container-right">
                        {((this.state.selectedBenchmark.highPrioritySoftSkills && this.state.selectedBenchmark.highPrioritySoftSkills.length > 0) || (this.state.selectedBenchmark.lowPrioritySoftSkills && this.state.selectedBenchmark.lowPrioritySoftSkills.length > 0)) && (
                          <div className="row-10">
                            <p>Top soft skills:</p>

                            {(this.state.selectedBenchmark.lowPrioritySoftSkills) ? (
                              <div>
                                {this.renderTags('softSkills', this.state.selectedBenchmark.highPrioritySoftSkills.concat(this.state.selectedBenchmark.lowPrioritySoftSkills), 6)}
                              </div>
                            ) : (
                              <div>
                                {this.renderTags('softSkills', this.state.selectedBenchmark.highPrioritySoftSkills, 6)}
                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                      <div className="clear" />
                    </div>

                    {(this.state.selectedBenchmark.skillCourses && this.state.selectedBenchmark.skillCourses.length > 0) && (
                      <div className="row-10">
                        <label className="bold-text">Suggested Courses: </label>

                        {this.state.selectedBenchmark.skillCourses.map((value, optionIndex) =>
                          <span key={value} className="right-padding">
                            <a href={value.url} target="_blank" rel="noopener noreferrer">{value.name}</a>
                            {(optionIndex + 1 !== this.state.selectedBenchmark.skillCourses.length) && (
                              <label>,</label>
                            )}
                          </span>
                        )}
                      </div>
                    )}

                    {(this.state.selectedBenchmark.skillCertifications && this.state.selectedBenchmark.skillCertifications.length > 0) && (
                      <div className="row-10">
                        <label className="bold-text">Suggested Certifications / Badges: </label>

                        {this.state.selectedBenchmark.skillCertifications.map((value, optionIndex) =>
                          <span key={value} className="right-padding">
                            <a href={value.url} target="_blank" rel="noopener noreferrer">{value.name}</a>
                            {(optionIndex + 1 !== this.state.selectedBenchmark.skillCertifications.length) && (
                              <label>,</label>
                            )}
                          </span>
                        )}
                      </div>
                    )}

                    {(this.state.selectedBenchmark.skillsMessage) && (
                      <div className="row-10">
                        <p className="italicize-text">"{this.state.selectedBenchmark.skillsMessage}"</p>
                      </div>
                    )}

                    <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                    <div className="row-10">
                      <hr />
                    </div>
                  </div>
                )}

                {(this.state.selectedBenchmark.educationWeight > 0) && (
                  <div>
                    <div className="row-10">
                      <div className="float-left">
                        <p className="heading-text-5">Education</p>
                      </div>
                      <div className="float-left left-padding">
                        <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.educationWeight) ? "(" + this.state.selectedBenchmark.educationWeight + "%)" : "(0%)"}</p>
                      </div>
                      <div className="clear" />
                      <p className="description-text-1 description-text-color top-padding">The educational components that matter</p>

                    </div>

                    {(this.state.knowledge && this.state.knowledge.length > 0) && (
                      <div className="row-10">
                        {this.renderTags('knowledge', this.state.knowledge)}
                        <div className="clear" />
                      </div>
                    )}

                    <div className="spacer"/>

                    {(this.state.selectedBenchmark.degreeRequirements) && (
                      <div className="row-10">
                        <p><label className="bold-text">Degree Requirements:</label> {this.state.selectedBenchmark.degreeRequirements}</p>
                      </div>
                    )}

                    {(this.state.selectedBenchmark.idealMajors && this.state.selectedBenchmark.idealMajors.length > 0) && (
                      <div className="row-10">
                        <p><label className="bold-text">Ideal Majors:</label> {this.state.selectedBenchmark.idealMajors.toString().replace(/,/g,", ")}</p>
                      </div>
                    )}

                    {(this.state.selectedBenchmark.gpaRange && this.state.selectedBenchmark.gpaRange !== '') && (
                      <div className="row-10">
                        <p><label className="bold-text">GPA Range:</label> {this.state.selectedBenchmark.gpaRange}</p>
                      </div>
                    )}

                    {(this.state.selectedBenchmark.gradYearRange && this.state.selectedBenchmark.gradYearRange !== '') && (
                      <div className="row-10">
                        <p><label className="bold-text">Grad Year Range:</label> {this.state.selectedBenchmark.gradYearRange}</p>
                      </div>
                    )}

                    {(this.state.selectedBenchmark.testScores && this.state.selectedBenchmark.testScores !== '') && (
                      <div className="row-10">
                        <p><label className="bold-text">Standardized Test Scores:</label> {this.state.selectedBenchmark.testScores}</p>
                      </div>
                    )}

                    {(this.state.selectedBenchmark.courseHours && this.state.selectedBenchmark.courseHours !== '') && (
                      <div className="row-10">
                        <p><label className="bold-text">Hours of Relevant Coursework Completed:</label> {this.state.selectedBenchmark.courseHours}</p>
                      </div>
                    )}

                    {(this.state.selectedBenchmark.courses && this.state.selectedBenchmark.courses.length > 0) && (
                      <div className="row-10">
                        <label className="bold-text">Suggested Courses: </label>

                        {this.state.selectedBenchmark.courses.map((value, optionIndex) =>
                          <span key={value} className="right-padding">
                            <a href={value.url} target="_blank" rel="noopener noreferrer">{value.name}</a>
                            {(optionIndex + 1 !== this.state.selectedBenchmark.courses.length) && (
                              <label>,</label>
                            )}
                          </span>
                        )}
                      </div>
                    )}

                    {(this.state.selectedBenchmark.certifications && this.state.selectedBenchmark.certifications.length > 0) && (
                      <div className="row-10">
                        <label className="bold-text">Suggested Certifications / Badges: </label>

                        {this.state.selectedBenchmark.certifications.map((value, optionIndex) =>
                          <span key={value} className="right-padding">
                            <a href={value.url} target="_blank" rel="noopener noreferrer">{value.name}</a>
                            {(optionIndex + 1 !== this.state.selectedBenchmark.certifications.length) && (
                              <label>,</label>
                            )}
                          </span>
                        )}
                      </div>
                    )}

                    <div className="spacer"/>

                    {(this.state.selectedBenchmark.educationMessage) && (
                      <div className="row-10">
                        <p className="italicize-text">"{this.state.selectedBenchmark.educationMessage}"</p>
                      </div>
                    )}

                    <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                    <div className="row-10">
                      <hr />
                    </div>
                  </div>
                )}

                {(this.state.selectedBenchmark.projectWeight > 0) && (
                  <div>
                    <div className="row-10">
                      <div className="float-left">
                        <p className="heading-text-5">Projects</p>
                      </div>
                      <div className="float-left left-padding">
                        <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.projectWeight) ? "(" + this.state.selectedBenchmark.projectWeight + "%)" : "(0%)"}</p>
                      </div>
                      <div className="clear" />
                      <p className="description-text-1 description-text-color top-padding">Importance of relevant projects, and what type of project work</p>
                    </div>

                    {(this.state.selectedBenchmark.projectTags && this.state.selectedBenchmark.projectTags.length > 0) && (
                      <div className="row-10">
                        {this.renderTags('projectTags', this.state.selectedBenchmark.projectTags)}
                        <div className="clear" />
                      </div>
                    )}

                    {(this.state.selectedBenchmark.projectHours && this.state.selectedBenchmark.projectHours !== '') && (
                      <div className="row-10">
                        <p><label className="bold-text">Recommended Invested Hours:</label> {this.state.selectedBenchmark.projectHours}</p>
                      </div>
                    )}

                    {(this.state.selectedBenchmark.exampleProjects && this.state.selectedBenchmark.exampleProjects.length > 0) && (
                      <div className="row-10">
                        <label className="bold-text">Example Impressive Projects: </label>

                        {this.state.selectedBenchmark.exampleProjects.map((value, optionIndex) =>
                          <span key={value} className="right-padding">
                            <a href={value.url} target="_blank" rel="noopener noreferrer">{value.name}</a>
                            {(optionIndex + 1 !== this.state.selectedBenchmark.exampleProjects.length) && (
                              <label>,</label>
                            )}
                          </span>
                        )}
                      </div>
                    )}

                    {(this.state.selectedBenchmark.projectsMessage) && (
                      <div className="row-10">
                        <p className="italicize-text">"{this.state.selectedBenchmark.projectsMessage}"</p>
                      </div>
                    )}

                    <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                    <div className="row-10">
                      <hr />
                    </div>
                  </div>
                )}

                {(this.state.selectedBenchmark.experienceWeight > 0) && (
                  <div>
                    <div className="row-10">
                      <div className="float-left">
                        <p className="heading-text-4">Experience</p>
                      </div>
                      <div className="float-left left-padding">
                        <p className="heading-text-4 cta-color bold-text">{(this.state.selectedBenchmark.experienceWeight) ? "(" + this.state.selectedBenchmark.experienceWeight + "%)" : "(0%)"}</p>
                      </div>
                      <div className="clear" />
                      <p className="description-text-1 description-text-color top-padding">Importance of relevant experience, and what type of experience</p>
                    </div>

                    {(this.state.selectedBenchmark.experienceTags && this.state.selectedBenchmark.experienceTags.length > 0) && (
                      <div className="row-10">
                        {this.renderTags('projectTags', this.state.selectedBenchmark.experienceTags)}
                        <div className="clear" />
                      </div>
                    )}

                    {(this.state.selectedBenchmark.experienceMessage) && (
                      <div className="row-10">
                        <p className="italicize-text">"{this.state.selectedBenchmark.experienceMessage}"</p>
                      </div>
                    )}

                    <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                    <div className="row-10">
                      <hr />
                    </div>
                  </div>
                )}

                {(this.state.selectedBenchmark.resumeWeight > 0) && (
                  <div>
                    <div className="row-10">
                      <div className="float-left">
                        <p className="heading-text-4">Resume</p>
                      </div>
                      <div className="float-left left-padding">
                        <p className="heading-text-4 cta-color bold-text">{(this.state.selectedBenchmark.resumeWeight) ? "(" + this.state.selectedBenchmark.resumeWeight + "%)" : "(0%)"}</p>
                      </div>
                      <div className="clear" />
                      <p className="description-text-1 description-text-color top-padding">What matters on the ideal candidate's resume</p>
                    </div>

                    {(this.state.selectedBenchmark.resumeMessage) && (
                      <div className="row-10">
                        <p className="italicize-text">"{this.state.selectedBenchmark.resumeMessage}"</p>
                      </div>
                    )}

                    <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                    <div className="row-10">
                      <hr />
                    </div>
                  </div>
                )}

                {(this.state.selectedBenchmark.coverLetterWeight > 0) && (
                  <div>
                    <div className="row-10">
                      <div className="float-left">
                        <p className="heading-text-5">Cover Letter</p>
                      </div>
                      <div className="float-left left-padding">
                        <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.coverLetterWeight) ? "(" + this.state.selectedBenchmark.coverLetterWeight + "%)" : "(0%)"}</p>
                      </div>
                      <div className="clear" />
                      <p className="description-text-1 description-text-color top-padding">What matters on the ideal candidate's cover letter</p>
                    </div>

                    {(this.state.selectedBenchmark.coverLetterMessage) && (
                      <div className="row-10">
                        <p className="italicize-text">"{this.state.selectedBenchmark.coverLetterMessage}"</p>
                      </div>
                    )}

                    <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                    <div className="row-10">
                      <hr />
                    </div>
                  </div>
                )}

                {(this.state.selectedBenchmark.interviewWeight > 0) && (
                  <div>
                    <div className="row-10">
                      <div className="float-left">
                        <p className="heading-text-5">Interview</p>
                      </div>
                      <div className="float-left left-padding">
                        <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.interviewWeight) ? "(" + this.state.selectedBenchmark.interviewWeight + "%)" : "(0%)"}</p>
                      </div>
                      <div className="clear" />
                      <p className="description-text-1 description-text-color top-padding">What matters in the ideal candidate's interview</p>
                    </div>

                    <div className="spacer"/>

                    {(this.state.selectedBenchmark.interviewRubric && this.state.selectedBenchmark.interviewRubric.length > 0) && (
                      <div className="row-20">
                        <p className="heading-text-5">Interview Rubric</p>
                        <div className="spacer"/>

                        <div className="standard-border">
                          <div className="bold-text standard-border-bottom ">
                            <div className="relative-column-33 padding-20">
                              <p>Criteria</p>
                            </div>
                            <div className="relative-column-33 padding-20">
                              <p>Sample Questions</p>
                            </div>
                            <div className="relative-column-33 padding-20">
                              <p>Qualities of Great Scores</p>
                            </div>
                            <div className="clear" />
                          </div>

                          {this.state.selectedBenchmark.interviewRubric.map((value, optionIndex) =>
                            <div key={value}>
                              <div className="standard-border-bottom">
                                <div className="relative-column-33 padding-20">
                                  <p className="bold-text">{optionIndex + 1}. {value.criterion}</p>
                                </div>
                                <div className="relative-column-33 padding-20">
                                  {value.questions.map((value2, optionIndex2) =>
                                    <div>
                                      <p>{value2}</p>
                                    </div>
                                  )}
                                </div>
                                <div className="relative-column-33 padding-20">
                                  {value.answers.map((value2, optionIndex2) =>
                                    <div>
                                      <p>{value2}</p>
                                    </div>
                                  )}
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          )}
                        </div>

                      </div>
                    )}

                    {(this.state.selectedBenchmark.interviewMessage) && (
                      <div className="row-10">
                        <p className="italicize-text">"{this.state.selectedBenchmark.interviewMessage}"</p>
                      </div>
                    )}

                    <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                    <div className="row-10">
                      <hr />
                    </div>
                  </div>
                )}

                {(this.state.selectedBenchmark.adversityScoreWeight > 0) && (
                  <div>
                    <div className="row-10">
                      <div className="float-left">
                        <p className="heading-text-5">Diversity</p>
                      </div>
                      <div className="float-left left-padding">
                        <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.adversityScoreWeight) ? "(" + this.state.selectedBenchmark.adversityScoreWeight + "%)" : "(0%)"}</p>
                      </div>
                      <div className="clear" />
                      <p className="description-text-1 description-text-color top-padding">What valuable diverse candidates look like to us</p>
                    </div>

                    {(this.state.selectedBenchmark.adversityScoreMessage) && (
                      <div className="row-10">
                        <p className="italicize-text">"{this.state.selectedBenchmark.adversityScoreMessage}"</p>
                      </div>
                    )}

                    <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                    <div className="row-10">
                      <hr />
                    </div>
                  </div>
                )}

              </div>
            )}
           </div>

         </div>
      )
    }
}

export default BenchmarkDetails;
