import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import AdminSendMessage from '../Subcomponents/AdminSendMessage';
import withRouter from '../Functions/WithRouter';

class OrgNewMessage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.sendMessage = this.sendMessage.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      const orgLogo = localStorage.getItem('orgLogo');

      const { org } = this.props.params

      if (email) {

        if (this.props.location && this.props.location.state) {
          const { selectedApplication } = this.props.location.state;
          this.setState({
              emailId: email,
              username: username,
              cuFirstName: cuFirstName,
              cuLastName: cuLastName,
              selectedApplication, org, orgLogo
          });
        } else {
          this.setState({
              emailId: email,
              username: username,
              cuFirstName: cuFirstName,
              cuLastName: cuLastName, org, orgLogo
          });
        }

        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const orgName = response.data.orgInfo.orgName
            const orgContactEmail = response.data.orgInfo.contactEmail

            this.setState({ orgName, orgContactEmail })

          } else {
            console.log('org info query did not work', response.data.message)
          }
        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler clicked');

      if (event.target.name === 'message') {
        console.log('message clicked')
        this.setState({ message: event.target.value })
      }
    }

    sendMessage() {
      console.log('sendMessage clicked');

      this.setState({
        clientErrorMessage: ''
      })

      if (this.state.message === '') {
        this.setState({ clientErrorMessage: 'please add a message for the recipient' })
      } else {

        const orgName = this.state.orgName
        const orgContactEmail = this.state.orgContactEmail

        //make it so that you see that it is coming from the employer brand name
        Axios.post('/api/notifications/message', { //sessions: sessions
          creatorEmail: this.state.emailId, recipientFirstName: this.state.selectedApplication.firstName,
          recipientLastName: this.state.selectedApplication.lastName, recipientEmail: this.state.selectedApplication.email,
          recipientType: 'Advisee', senderFirstName: this.state.cuFirstName,senderLastName: this.state.cuLastName,
          senderEmail: this.state.emailId, message: this.state.message, orgName, orgContactEmail,
          createdAt: new Date(), updatedAt: new Date() })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Message sent worked', response.data);
            this.setState({
              message: '',

              serverPostSuccess: true,
              serverSuccessMessage: 'Message saved successfully!'
            })
          } else {
            console.log('there was an error sending the message');
            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message,
            })
          }
        }).catch((error) => {
            console.log('Message send did not work', error);
        });
      }
    }

    render() {

        return (
            <div>
              <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
              <AdminSendMessage org={this.state.org} accountCode={this.state.emp} selectedApplication={this.state.selectedApplication} />

              {(this.state.org) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(OrgNewMessage)
