import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import { CircularProgressbar } from 'react-circular-progressbar';;
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SubCustomDropdown from '../Common/CustomDropdown';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import StrictModeDroppable from '../Vendors/StrictModeDroppable';
import {convertDateToString} from '../Functions/ConvertDateToString';
import SubFavorites from '../Subcomponents/Favorites';
import SubCompletions from '../Subcomponents/Completions';
import SubComments from '../Common/Comments';
import SubTakeBasicAssessment from '../Common/TakeBasicAssessment';
import SubAdvisingServices from '../Subcomponents/AdvisingServices';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const addIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';
const recommendIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/recommend-icon.png';
const dragIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/drag-icon.png';
const udemyLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/udemy-logo.png';
const courseraLogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/coursera-logo.png";
const udacityLogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/udacity-logo.png";
const timeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-icon-blue.png';
const moneyIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-blue.png';
const difficultyIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/difficulty-icon-blue.png';
const linkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon.png';
const editIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/edit-icon-dark.png';
const deleteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/delete-icon-dark.png';
const duplicateIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/duplicate-icon.png';
const checkmarkDarkGreyIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-dark-grey.png';
const checkboxChecked = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-checked.png';
const favoritesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-dark.png';
const profileIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-blue.png';
const shareIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/share-icon-dark.png';
const shareIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/share-icon-blue.png';
const ratingsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/ratings-icon-blue.png';
const calendarIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/calendar-icon-blue.png';
const careerMatchesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-blue.png';
const opportunitiesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-blue.png';
const learningObjectivesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/learning-objectives-icon-blue.png';
const favoritesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-blue.png';
const addLessonIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-lesson-icon-blue.png';
const addLessonIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-lesson-icon-dark.png';
const matchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon.png";
const matchIconSelected = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon-selected.png";
const filterIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon.png";
const filterIconSelected = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon-selected.png";
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const linkIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon-blue.png';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const experienceIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/experience-icon.png";
const courseIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png';
const courseIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-blue.png';
const timeIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-icon-dark.png";
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const checkmarkIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon-white.png';
const xIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/x-icon.png';
const assessmentsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assessments-icon-blue.png';
const industryIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

const reorder = (list, startIndex, endIndex) => {

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  console.log('show me result', result)
  // for (let i = 1; i <= result.length; i++) {
  //   console.log(i);
  //
  //   result[i - 1]['rank'] = i
  //
  // }

  return result;
};

const grid = 5;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 0 0`,

  // change background colour if dragging
  background: isDragging ? getComputedStyle(document.documentElement).getPropertyValue('--primaryColor') : 'white',
  border: '1px solid ' + getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),
  color: isDragging ? 'white' : getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#F5F5F5' : 'transparent',
  padding: grid,
  width: null,
});

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

const styles2 = {
    transition: 'all 0.75s ease',
    transform: 'translate(85%)'
};

class CoursesTwo extends Component {
    constructor(props) {
        super(props)

        this.state = {

          newFormat: true,
          hideEnrollmentButton: true,
          disableEdit: true,

          subNavCategories: ['Schedule','Browse','Favorited','Completed','Recommended'],
          subNavSelected: 'Schedule',
          budget: 1000,

          showScheduleResults: false,
          showDragger: true,

          courses: [],
          filteredCourses: [],
          timerId: 0,

          favorites: [],
          newFavorites: [],
          showSubFunction: true,

          favoritedCareers: [],
          favoritedCareerDetails: [],
          favoritedOpportunities: [],
          favoritedOpportunityDetails: [],
          favoritedCourseDetails: [],

          completions: [],
          completedCourseDetails: [],
          recommendations: [],
          recommendedCourseDetails: [],

          learningObjectives: [],
          useCases: [],
          referees: [],

          showProjectDetail: false,

          selectedIndex1: 0,
          selectedIndex2: 0,

          defaultFilterOption: 'All',
          defaultSortOption: 'No Sort Applied',

          memberFilter: '',
          applicationFilter: '',
          assessmentFilter: '',
          favoriteFilter: '',
          passionFilter: '',
          endorsementFilter: '',

          memberFilterOptions: [],
          applicationFilterOptions: [],
          assessmentFilterOptions: ['','Has None','At Least One','Has All'],
          favoriteFilterOptions: ['','Has None','At Least One'],
          passionFilterOptions: ['','Has None','At Least One'],
          endorsementFilterOptions: ['','Has None','At Least One'],
          gradeOptions: [],
          pathwayOptions: [{ name: '' }],
          priceOptions: [],
          durationOptions: [],
          difficultyLevelOptions: [],
          goalTypeOptions: [],
          entrepreneurshipGoalOptions: [],
          industryOptions: [],
          intensityOptions: [],
          categoryOptions: ['','Training Program','Course'],
          degreeTypeOptions: [],
          functionOptions: [],
          programMethodOptions: ['','Online','In-Person','Both Online and In-Person'],
          orgPathwayOptions: [],
          orgPriceOptions: ['','$0','$1 - $100','$101 - $500','$501 - $1,000','$1,001 - $5,000','$5,000+'],
          orgDurationOptions: [],

          schedule: [],
          weekTwo: [],

          goalType: { name: ''},
          applications: [],
          filteredApplications: [],
          selectedJob: null,
          benchmark: null,
          benchmarkOptions: [],
          largestSkillGaps: [],

          showFilters: true,
          unready: true,

          serverPostSuccess: true,
          serverSuccessMessage: '',
          serverErrorMessage: ''

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.subNavClicked = this.subNavClicked.bind(this)

        this.pullCourses = this.pullCourses.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)

        this.favoriteItem = this.favoriteItem.bind(this)
        this.enrollInCourse = this.enrollInCourse.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)

        this.filterResults = this.filterResults.bind(this)
        this.sortResults = this.sortResults.bind(this)
        this.markCompleted = this.markCompleted.bind(this)

        this.renderTags = this.renderTags.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.closeModal = this.closeModal.bind(this)

        this.itemClicked = this.itemClicked.bind(this)
        this.searchItems = this.searchItems.bind(this)
        this.searchItemClicked = this.searchItemClicked.bind(this)
        this.addLearningObjectiveOptions = this.addLearningObjectiveOptions.bind(this)
        this.craftCourseSchedule = this.craftCourseSchedule.bind(this)
        this.getCourseMatches = this.getCourseMatches.bind(this)

        this.onDragEnd = this.onDragEnd.bind(this)
        this.duplicateItem = this.duplicateItem.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.calculateMatches = this.calculateMatches.bind(this)
        this.renderBrowseCourses = this.renderBrowseCourses.bind(this)
        this.renderCourseComponent = this.renderCourseComponent.bind(this)
        this.renderScheduleSetup = this.renderScheduleSetup.bind(this)
        this.calcScheduleDate = this.calcScheduleDate.bind(this)
        this.addCourse = this.addCourse.bind(this)
        this.recommendCourse = this.recommendCourse.bind(this)

        this.renderItems = this.renderItems.bind(this)
        this.renderItem = this.renderItem.bind(this)

        this.embedYouTubeVideo = this.embedYouTubeVideo.bind(this)
        this.retrieveComments = this.retrieveComments.bind(this)
        this.calcCompletions = this.calcCompletions.bind(this)
        this.toggleMetaFilter = this.toggleMetaFilter.bind(this)
        this.buttonClicked = this.buttonClicked.bind(this)
        this.passResponse = this.passResponse.bind(this)

        this.scorecardClicked = this.scorecardClicked.bind(this)

    }

    static defaultProps = { activeOrg: null, accountCode: null, source: '' }

    onDragEnd(result) {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      console.log('show result: ', result)

      let schedule = this.state.schedule
      if (result.source.droppableId === result.destination.droppableId) {
        console.log('sourced from the same week')

        let courses = schedule[result.source.droppableId - 1].courses
        courses = reorder(
          courses,
          result.source.index,
          result.destination.index
        );
        schedule[result.source.droppableId - 1]['courses'] = courses
        console.log('show schedule: ', schedule)
        this.setState({ schedule });
      } else {
        console.log('sourced from another week')

        let newCourses = schedule[result.destination.droppableId - 1].courses
        newCourses.splice(result.destination.index,0,schedule[result.source.droppableId - 1].courses[result.source.index])

        let ogCourses = schedule[result.source.droppableId - 1].courses
        ogCourses.splice(result.source.index,1)

        schedule[result.destination.droppableId - 1]['courses'] = newCourses
        schedule[result.source.droppableId - 1]['courses'] = ogCourses

        this.setState({ schedule });
      }
    }

    componentDidMount(){
        document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
        // status, activities (checklist), assessment
        this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within coursesTwo ', this.props.activeOrg, prevProps.activeOrg)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('were in')
        this.retrieveData()
      } else if (this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      } else if (this.props.subNavSelected !== prevProps.subNavSelected) {
        this.retrieveData()
      } else if (this.props.showImportWidget !== prevProps.showImportWidget) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in coursesTwo', this.props)

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');
      let pathway = localStorage.getItem('pathway');
      const courseNavSelected = localStorage.getItem('courseNavSelected');
      const orgName = localStorage.getItem('orgName');
      const orgContactEmail = localStorage.getItem('orgContactEmail');
      const remoteAuth = localStorage.getItem('remoteAuth');

      let org = this.props.activeOrg
      if (!email && !org && toggleVars.showLMSConfiguration) {
        org = 'guidedcompass'
      }
      // org = 'testignore'

      // console.log('gimme the org email:  ', orgContactEmail)
      const largestSkillGaps = []
      const pathwayOptions = [{ name: '' }]

      const priceOptions = ['','Price-Paid','Price-Free']
      const durationOptions = ['','Short','Medium','Long','Extra Long']
      const difficultyLevelOptions = ['','Beginner','Intermediate','Expert']

      const goalTypeOptions = [
        { name: ''},
        { name: 'Career', description: 'Land a job within a career path (Career)'},
        { name: 'Opportunity', description: 'Get hired for a specific opportunity (Opportunity)'},
        { name: 'Entrepreneurship', description: 'Launch a business / project (Entrepreneurship)'},
        { name: 'Basics', description: 'Basics (Learn fundamental skills most employers value)'},
        { name: 'Stability', description: 'Stability (Learn mid-level+ skills that employers steadily value)'},
        { name: 'Pay', description: 'Pay (Learn mid-level+ skills that employers will pay the most for)'},
        { name: 'Interests', description: 'Interests (Learn skills based on your interest profile)'},
      ]
      // skill gaps, specialize, projects
      // { name: 'Recognition', description: 'Recognition (Learn skills that give consistent social recognition)'},

      const entrepreneurshipGoalOptions = [
        '','Overall Skills','Legal Formation','Market Research','Building a Team','Building an App MVP',
        'Selling Goods / Services Online','Marketing Your Product','Selling Your Product','Raising Funding',
        'Product Design','Forecasting and Budgeting','Other'
      ]

      const intensityOptions =  ['','Aggressive (> 40 hrs / week)','Moderately Aggressive (20 - 40 hrs / week)','Moderate (10 - 20 hrs / week)','Moderately Passive (4 - 10 hrs / week)','Passive (1 - 4 hrs / week)']

      const twoWeeksFromNow = new Date(Date.now() + 12096e5)
      const minYear = twoWeeksFromNow.getFullYear().toString()
      let minMonth = (twoWeeksFromNow.getMonth() + 1)
      if (minMonth < 10) {
        minMonth = '0' + minMonth.toString()
      } else {
        minMonth = minMonth.toString()
      }
      const minDay = twoWeeksFromNow.getDate().toString()
      const minTargetDate = minYear + '-' + minMonth + '-' + minDay

      const maxYear = (new Date().getFullYear() + 1).toString()
      let maxMonth = (new Date().getMonth() + 1)
      if (maxMonth < 10) {
        maxMonth = '0' + maxMonth.toString()
      } else {
        maxMonth = maxMonth.toString()
      }
      const maxDay = new Date().getDate().toString()
      const maxTargetDate = maxYear + '-' + maxMonth + '-' + maxDay

      const targetDate = minTargetDate

      const todayYear = (new Date().getFullYear()).toString()
      let todayMonth = (new Date().getMonth() + 1)
      if (todayMonth < 10) {
        todayMonth = '0' + todayMonth.toString()
      } else {
        todayMonth = todayMonth.toString()
      }
      const todayDay = new Date().getDate().toString()
      const today = todayYear + '/' + todayMonth + '/' + todayDay

      const oneWeekFromNowDate = new Date(new Date().getTime() + (2 * 7) * 24 * 60 * 60 * 1000);
      const futureYear = oneWeekFromNowDate.getFullYear().toString()
      let futureMonth = (oneWeekFromNowDate.getMonth() + 1)
      if (futureMonth < 10) {
        futureMonth = '0' + futureMonth.toString()
      } else {
        futureMonth = futureMonth.toString()
      }
      const futureDay = oneWeekFromNowDate.getDate().toString()
      const oneWeekFromNow = futureYear + '-' + futureMonth + '-' + futureDay

      const showScheduleResults = false
      const schedule = [
        { week: 1, courses: [
          { title: 'Fake Course', headline: 'Fake headline', duration: '1h 20m', price: '$50', difficultyLevel: 'Advanced', url: 'https://www.udemy.com'},
          { title: 'Fake Course 2', headline: 'Fake headline 2', duration: '2h 20m', price: '$100', difficultyLevel: 'Intermediate', url: 'https://www.udemy.com'}
        ]},
        { week: 2, courses: [
          { title: 'Fake Course 3', headline: 'Fake headline 3', duration: '4h 20m', price: '$150', difficultyLevel: 'Beginner', url: 'https://www.udemy.com'},
          { title: 'Fake Course 4', headline: 'Fake headline 4', duration: '5h 20m', price: '$2150', difficultyLevel: 'Beginner', url: 'https://www.udemy.com'},
        ]}

      ]
      const weekTwo = [
        { title: 'Fake Course 3', headline: 'Fake headline 3', duration: '4h 20m', price: '$150', difficultyLevel: 'Beginner', url: 'https://www.udemy.com'},
        { title: 'Fake Course 4', headline: 'Fake headline 4', duration: '5h 20m', price: '$2150', difficultyLevel: 'Beginner', url: 'https://www.udemy.com'},
      ]

      let useCases = [
        { name: 'Career', description: 'Optimize for courses that are helpful for your career path'},
        { name: 'Opportunity', description: 'Optimize for courses that help get hired for a specific opportunity'},
        { name: 'Entrepreneurship', description: 'Optimize for courses that will help you launch a successful business'},
        { name: 'Basics', description: 'Optimize for courses that teach skills that virtually all employers value', selected: false }, // skills
        { name: 'Stability', description: 'Optimize for courses that teach skills that are in constant demand', selected: false }, // job growth, company size
        { name: 'Pay', description: 'Optimize for courses that teach skills employers would pay the most for', selected: false }, // pay
        { name: 'Interests', description: 'Optimize for courses that, based on your interest assessment, you are likely interested in', selected: false }, // interests, personality
      ]

      let subNavCategories = ['Schedule','Browse','Favorited','Completed','Recommended']
      let subNavSelected = 'Schedule'
      if (this.state.newFormat) {
        // subNavCategories = ['Lessons','Resources','Courses']
        subNavCategories = ['Lessons']
        subNavSelected = 'Lessons'

        if (toggleVars.showLMSConfiguration) {
          subNavCategories = ['Courses','Lessons']
          subNavSelected = 'Courses'
        }

        if (org === 'fosternation') {
          subNavCategories = ['Scorecards','Lessons']
          subNavSelected = 'Scorecards'
        }
      }

      if (this.props.subNavSelected) {
        subNavSelected = this.props.subNavSelected

      } else if (courseNavSelected) {
        subNavSelected = courseNavSelected

      }

      if (this.props.roleName === 'Admin') {
        subNavCategories  = ['Browse','Favorited','Recommended']
        subNavSelected = 'Browse'
      }

      if (window.location.pathname.includes('/marketplace')) {
        subNavSelected = 'Programs & Courses'
      }

      let modalIsOpen = null
      if (this.props.modalIsOpen) {
        modalIsOpen = this.props.modalIsOpen
      }
      let showBrowseCourses = null
      if (this.props.showBrowseCourses) {
        showBrowseCourses = this.props.showBrowseCourses
      }

      this.setState({ emailId: email, cuFirstName, cuLastName, username, roleName, org, orgFocus,
        largestSkillGaps, pathwayOptions, priceOptions, durationOptions, difficultyLevelOptions, subNavCategories, subNavSelected, goalTypeOptions,
        entrepreneurshipGoalOptions, intensityOptions, minTargetDate, maxTargetDate, targetDate, today, oneWeekFromNow,
        schedule, weekTwo, showScheduleResults, useCases, orgName, orgContactEmail,
        remoteAuth, modalIsOpen, showBrowseCourses
      })

      if (org || this.props.noOrgCode) {
        console.log('got me an org: ', org)

        if (this.state.newFormat) {

          Axios.get('/api/users/profile/details/' + email)
          .then((response) => {

              if (response.data.success) {
                console.log('User query worked', response.data);

                const pictureURL = response.data.user.pictureURL
                const cohort = response.data.user.cohort

                this.setState({ cohort });

                // secions assumes we're pulling from Clever
                const sections = response.data.user.sections

                let courseIds = []
                if (sections) {
                  const sectionArray = JSON.parse(sections).data
                  for (let i = 1; i <= sectionArray.length; i++) {
                    courseIds.push(sectionArray[i - 1].data.id)
                  }
                }
                console.log('show courseIds: ', courseIds)
                if (org) {
                  Axios.get('/api/lessons', { params: { emailId: null, sortValue: 'Most Recent', orgCode: org, isVisible: true, courseIds, filterGroups: true }})
                  .then((response) => {
                    console.log('Lessons query attempted', response.data);

                      if (response.data.success) {
                        console.log('lessons query worked')

                        let lessons = response.data.lessons
                        if (lessons) {
                          lessons.sort(function(a,b) {
                            return a.rank - b.rank;
                          })
                        }
                        this.setState({ lessons })

                        if (this.props.lessonId) {
                          if (lessons && lessons.length > 0) {

                            let lessonIndex = null
                            for (let i = 1; i <= lessons.length; i++) {
                              if (lessons[i - 1]._id === this.props.lessonId) {
                                lessonIndex = i - 1
                              }
                            }

                            if (lessonIndex || lessonIndex === 0) {
                              this.retrieveComments(lessonIndex)
                            }
                          }
                        }

                      } else {
                        console.log('lessons query did not work', response.data.message)
                      }

                  }).catch((error) => {
                      console.log('lessons query did not work for some reason', error);
                  });
                }

              } else {
                console.log('no advisees found', response.data.message)

              }

          }).catch((error) => {
              console.log('User profile query not work', error);
          });

          Axios.get('/api/submissions', { params: { emailId: email }})
          .then((response) => {
            console.log('Submissions query attempted', response.data);

              if (response.data.success) {
                console.log('submissions query worked')

                const submissions = response.data.submissions
                this.setState({ submissions })

              } else {
                console.log('submissions query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('submissions query did not work for some reason', error);
          });
        }

        Axios.get('/api/workoptions')
          .then((response) => {
            console.log('Work options query tried', response.data);

            if (response.data.success) {
              console.log('Work options query succeeded')

              let industryOptions = ['']
              for (let i = 1; i <= response.data.workOptions[0].industryOptions.length; i++) {
                if (i > 1) {
                  industryOptions.push(response.data.workOptions[0].industryOptions[i - 1])
                }
              }

              let functionOptions = response.data.workOptions[0].functionOptions
              let degreeTypeOptions = response.data.workOptions[0].degreeOptions
              let orgDurationOptions = response.data.workOptions[0].durationOptions

              this.setState({ industryOptions, functionOptions, degreeTypeOptions, orgDurationOptions })

            } else {
              console.log('no industry data found', response.data.message)

            }
        }).catch((error) => {
            console.log('query for work options did not work', error);
        })

        Axios.get('/api/pathways', { params: { orgCode: org } })
        .then((response) => {
          console.log('Pathways query attempted no 1', response.data);

          if (response.data.success) {
            console.log('pathway query worked no 1')

            if (response.data.pathways.length !== 0) {

              const orgPathwayOptions = [{ title: '' }].concat(response.data.pathways)

              this.setState({ orgPathwayOptions });
            }

          } else {
            console.log('pathway query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Pathway query did not work for some reason', error);
        });

        Axios.get('/api/assessment/results', { params: { emailId: email } })
         .then((response2) => {
           console.log('query for assessment results worked');

           if (response2.data.success) {

             console.log('actual assessment results', response2.data)

             let profile = { firstName: cuFirstName, lastName: cuLastName, email }
             profile['workPreferences'] = response2.data.results.workPreferenceAnswers
             profile['interests'] = response2.data.results.interestScores
             profile['personality'] = response2.data.results.personalityScores
             profile['skills'] = response2.data.results.newSkillAnswers
             profile['gravitateValues'] = response2.data.results.topGravitateValues
             profile['employerValues'] = response2.data.results.topEmployerValues

             this.setState({ profile })

             Axios.get('/api/logs', { params: { emailId: email } })
             .then((response) => {
                console.log('log query attempted')
                 if (response.data.success) {
                   console.log('Logs received query worked', response.data);

                   profile['logs'] = response.data.logs
                   this.setState({ profile })

                 } else {
                   console.log('no log data found', response.data.message)
                 }

             }).catch((error) => {
                 console.log('Log query did not work', error);
             });

           } else {
             console.log('no assessment results', response2.data)

             Axios.get('/api/logs', { params: { emailId: email } })
             .then((response) => {
                console.log('log query attempted')
                 if (response.data.success) {
                   console.log('Logs received query worked', response.data);

                   let profile = { firstName: cuFirstName, lastName: cuLastName, email }
                   profile['logs'] = response.data.logs
                   this.setState({ profile })

                 } else {
                   console.log('no log data found', response.data.message)
                 }

             }).catch((error) => {
                 console.log('Log query did not work', error);
             });
           }

       }).catch((error) => {
         console.log('query for assessment results did not work', error);
       })

        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const placementPartners = response.data.orgInfo.placementPartners
            const orgLogo = response.data.orgInfo.webLogoURIColor
            this.setState({ placementPartners, orgLogo })

          } else {
            console.log('org info query did not work', response.data.message)
            //don't allow signups without an org affiliation
            this.setState({ error: { message: 'There was an error finding the organization' } })
          }

        }).catch((error) => {
            console.log('Emails query did not work for some reason', error);
        });

        Axios.get('/api/favorites', { params: { emailId: email } })
       .then((response) => {
         console.log('Favorites query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved favorites')

           const favorites = response.data.favorites
           let newFavorites = []
           if (response.data.newFavorites) {
             newFavorites = response.data.newFavorites
           }

           if (favorites && favorites.length > 0) {
             Axios.get('/api/favorites/detail', { params: { favorites, orgCode: org } })
             .then((response2) => {
               console.log('Favorites detail query attempted', response2.data);

               if (response2.data.success) {
                 console.log('successfully retrieved favorites detail', response2.data.favorites)

                 let favoritedCareers = []
                 let favoritedCareerDetails = []
                 let favoritedOpportunities = []
                 let favoritedOpportunityDetails = []
                 let favoritedCourseDetails = []
                 // for (let i = 1; i <= favoritedCareerDetails.length; i++) {
                 //   favoritedCareers.push(favoritedCareerDetails[i - 1].name)
                 // }

                 for (let i = 1; i <= response.data.favorites.length; i++) {
                   if (response2.data.types[i - 1] && response2.data.types[i - 1] === 'career') {
                     favoritedCareers.push(response2.data.favorites[i - 1].name)
                     favoritedCareerDetails.push(response2.data.favorites[i - 1])
                   } else if (response2.data.types[i - 1] && response2.data.types[i - 1] === 'work') {
                     if (response2.data.favorites[i - 1].postType !== 'Event') {
                       if (response2.data.favorites[i - 1].title && response2.data.favorites[i - 1].title !== '') {
                         favoritedOpportunities.push(response2.data.favorites[i - 1].title)
                       } else {
                         favoritedOpportunities.push(response2.data.favorites[i - 1].name)
                       }

                       favoritedOpportunityDetails.push(response2.data.favorites[i - 1])
                     }
                   } else if (response2.data.types[i - 1] && response2.data.types[i - 1] === 'course') {
                     const favoritedCourse = response2.data.favorites[i - 1]
                     favoritedCourse['favoriteId'] = response.data.favorites[i - 1]
                     favoritedCourseDetails.push(favoritedCourse)
                   }
                 }

                 //query info on those favorites
                 this.setState({ favorites, newFavorites, favoritedCareers, favoritedCareerDetails, favoritedOpportunities, favoritedOpportunityDetails, favoritedCourseDetails })

               } else {
                 console.log('no favorites detail data found', response2.data.message)
               }

             }).catch((error) => {
                 console.log('Favorites detail query did not work', error);
             });
           }

         } else {
           console.log('no favorites data found', response.data.message)
         }

       }).catch((error) => {
           console.log('Favorites query did not work', error);
       });

       Axios.get('/api/completions', { params: { emailId: email } })
      .then((response) => {
        console.log('Completions query attempted', response.data);

        if (response.data.success) {
          console.log('successfully retrieved completions')

          const completions = response.data.completions

          if (completions && completions.length > 0) {
            Axios.get('/api/completions/detail', { params: { completions, orgCode: org } })
            .then((response2) => {
              console.log('Completions detail query attempted', response2.data);

              if (response2.data.success) {
                console.log('successfully retrieved completions detail', response2.data.completions)

                let completedCourseDetails = []

                for (let i = 1; i <= response.data.completions.length; i++) {
                  if (response2.data.types[i - 1] && response2.data.types[i - 1] === 'course') {
                    const completedCourse = response2.data.completions[i - 1]
                    completedCourse['completionId'] = response.data.completions[i - 1]
                    completedCourseDetails.push(completedCourse)
                  }
                }

                this.setState({ completions, completedCourseDetails })

              } else {
                console.log('no completions detail data found', response2.data.message)
              }

            }).catch((error) => {
                console.log('Completions detail query did not work', error);
            });
          }

        } else {
          console.log('no completions data found', response.data.message)
        }

      }).catch((error) => {
          console.log('Favorites query did not work', error);
      });

        let orgCode = 'general'
        if (org === 'c2c' || org === 'dpscd') {
          orgCode = org
        }

        Axios.get('/api/benchmarks', { params: { orgCode } })
        .then((response) => {

          if (response.data.success) {
            console.log('Benchmark query worked', response.data);

            let pathwayOptions = [{name: '', skills: []}]
            let names = []

            for (let i = 1; i <= response.data.benchmarks.length; i++) {

              if (org === 'dpscd') {
                if (response.data.benchmarks[i - 1].pathway === pathway) {

                  // let skillTraits = response.data.benchmarks[i - 1].skills.concat(response.data.benchmarks[i - 1].traits)
                  let skills = response.data.benchmarks[i - 1].skills

                  let skillsObject = []

                  for (let j = 1; j <= skills.length; j++) {
                    if (skills[j - 1].title && skills[j - 1].skillType) {
                      const name = skills[j - 1].title
                      const skillType = skills[j - 1].skillType
                      skillsObject.push({ name, skillType })
                    } else {
                      // not adding traits for now
                      // const name = skills[j - 1].title
                      // const skillType = 'Trait'
                      // console.log('no good: ', name)
                      // skillsObject.push({ name, skillType})
                    }
                  }

                  this.setState({ skills: skillsObject })
                }
              } else {

                let name = response.data.benchmarks[i - 1].jobFunction
                console.log('show the value 1: ', name)
                if (!names.includes(name)) {
                  names.push(name)
                  console.log('show the value 2: ', name)
                  // let skillTraits = response.data.benchmarks[i - 1].skills.concat(response.data.benchmarks[i - 1].traits)
                  let skills = response.data.benchmarks[i - 1].skills

                  let skillsObject = []

                  for (let j = 1; j <= skills.length; j++) {
                    if (skills[j - 1].title && skills[j - 1].skillType) {
                      const name = skills[j - 1].title
                      const skillType = skills[j - 1].skillType
                      skillsObject.push({ name, skillType })
                    } else {
                      // not adding traits for now
                      // const name = skills[j - 1].title
                      // const skillType = 'Trait'
                      // console.log('no good: ', name)
                      // skillsObject.push({ name, skillType})
                    }
                  }

                  pathwayOptions.push({ name, skills: skillsObject })
                } else {


                }
                console.log('show pathwayOptions: ', pathwayOptions)

              }
            }

            this.setState({ pathwayOptions })
          } else {
            console.log('no benchmarks found', response.data.message)

          }

        }).catch((error) => {
            console.log('Benchmark query did not work', error);
        });

       if (toggleVars.showLMSConfiguration) {
         const self = this

         function pullLMSCourses(courseIds) {
           console.log('pullLMSCourses called')

           let orgCodes = [org]
           if (toggleVars.demoMode) {
             orgCodes = [org,'grover-cleveland-charter-high-school-8590']
           } else {
             orgCodes = [org]
           }
           // orgCodes = ['testignore']

           if (self.props.pageSource === 'landingPage') {
             orgCodes = ['guidedcompass']
           }

           let courseQueryObject = { orgCodes, isActive: true, availableToEveryone: true, courseIds, resLimit: 100 }
           console.log('show the params: ', courseQueryObject)
           Axios.get('/api/courses', { params: courseQueryObject })
           .then((response2) => {
             console.log('Courses query attempted in coursesTwo', response2.data);

             if (response2.data.success) {
               console.log('successfully retrieved courses')

               const courses = response2.data.courses
               const filteredCourses = courses
               self.setState({ courses, filteredCourses, animating: false })

             } else {
               console.log('no course data found', response2.data.message)
               self.setState({ animating: false, errorMessage: 'Found no courses that match the criteria'})
             }

           }).catch((error) => {
               console.log('Course query did not work in basic query', error);
               self.setState({ animating: false, errorMessage: 'There was an unknown error retrieving the courses'})
           });
         }

         if (email && this.props.pageSource !== 'landingPage') {
           let enrollmentParams = { emailId: email }
           Axios.get('/api/courses/enrollments', { params: enrollmentParams })
          .then((response) => {
            console.log('Enrollments query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved enrollments')

              let courseIds = []
              for (let i = 1; i <= response.data.enrollments.length; i++) {
                if (response.data.enrollments[i - 1].courseId) {
                  courseIds.push(response.data.enrollments[i - 1].courseId)
                }
              }

              this.setState({ enrollments: response.data.enrollments })
              pullLMSCourses(courseIds)

            } else {
              console.log('no enrollments data found', response.data.message)
            }

          }).catch((error) => {
              console.log('Enrollments query did not work', error);
          });
         } else {
           pullLMSCourses(null)
         }

       } else {

         if (email) {
           let enrollmentParams = { emailId: email }

           Axios.get('/api/courses/enrollments', { params: enrollmentParams })
          .then((response) => {
            console.log('Enrollments query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved enrollments')

              const enrollments = response.data.enrollments
              this.setState({ enrollments })

            } else {
              console.log('no enrollments data found', response.data.message)
            }

          }).catch((error) => {
              console.log('Enrollments query did not work', error);
          });
         }

         if (this.props.source === 'Udemy') {
           console.log('is sourced udemy')
           this.pullCourses(this.state.selectedSkill, this.state.priceValue, this.state.durationValue, this.state.difficultyLevelValue, null, null, null, true)
         } else {
           console.log('not sourced udemy')
           let orgCode = org
           let source = null
           let queryOrgCourses = null
           let queryUdemyCourses = true
           if (this.props.source === 'Coursera' || this.props.source === 'Udacity') {
             orgCode = null
             source = this.props.source
             queryUdemyCourses = false
           }

           let excludeOrgCourses = false
           if (this.props.pageSource === 'landingPage') {
             excludeOrgCourses = true
           }

           let sources = ['Udemy']
           Axios.get('/api/courses', { params: { orgCode, isActive: true, source, excludeOrgCourses, resLimit: 100 } })
           .then((response) => {
             console.log('Org courses info query attempted', response.data);

             if (response.data.success && response.data.courses && response.data.courses.length > 0) {
               console.log('org courses info query worked')

               const courses = response.data.courses
               const filteredCourses = courses
               queryOrgCourses = true
               queryUdemyCourses = false
               const source = this.props.source

               if (this.props.source) {
                 sources = [this.props.source]
               } else {
                 if (excludeOrgCourses) {
                   sources = []
                 } else {
                   sources = ['OrgCourses']
                 }
               }

               this.setState({ courses, filteredCourses, queryOrgCourses, queryUdemyCourses, source, sources })

               if (this.props.pageSource === 'Goal') {
                 if (this.props.competencies && this.props.competencies.length > 0) {
                   // this.getCourseMatches(this.props.selectedGoal)
                   this.pullCourses(this.props.competencies[0].name, this.state.priceValue, this.state.durationValue, this.state.difficultyLevelValue, queryOrgCourses, org, null, queryUdemyCourses, sources)
                 } else {
                   // this.getCourseMatches(this.props.selectedGoal)
                   this.pullCourses(this.state.selectedSkill, this.state.priceValue, this.state.durationValue, this.state.difficultyLevelValue, queryOrgCourses, org, null, queryUdemyCourses, sources)
                 }
               } else {
                 // this.pullCourses(this.state.selectedSkill, this.state.priceValue, this.state.durationValue, this.state.difficultyLevelValue, queryOrgCourses, org, null, queryUdemyCourses)
               }

             } else {
               console.log('org courses info query did not work', response.data.message)

               this.setState({ sources })

               if (this.props.pageSource === 'Goal') {
                 if (this.props.competencies && this.props.competencies.length > 0) {
                   // this.getCourseMatches(this.props.selectedGoal)
                   this.pullCourses(this.props.competencies[0].name, this.state.priceValue, this.state.durationValue, this.state.difficultyLevelValue, queryOrgCourses, null, null, queryUdemyCourses, sources)
                 } else {
                   // this.getCourseMatches(this.props.selectedGoal)
                   this.pullCourses(this.state.selectedSkill, this.state.priceValue, this.state.durationValue, this.state.difficultyLevelValue, queryOrgCourses, null, null, queryUdemyCourses, sources)
                 }
               } else if (queryUdemyCourses) {
                 this.pullCourses(this.state.selectedSkill, this.state.priceValue, this.state.durationValue, this.state.difficultyLevelValue, queryOrgCourses, null, null, queryUdemyCourses, sources)
               }
             }

           }).catch((error) => {
               console.log('org courses query did not work for some reason', error);

               if (this.props.pageSource === 'Goal') {
                 if (this.props.competencies && this.props.competencies.length > 0) {
                   // this.getCourseMatches(this.props.selectedGoal)
                   this.pullCourses(this.props.competencies[0].name, this.state.priceValue, this.state.durationValue, this.state.difficultyLevelValue, queryOrgCourses, null, null, queryUdemyCourses, sources)
                 } else {
                   // this.getCourseMatches(this.props.selectedGoal)
                   this.pullCourses(this.state.selectedSkill, this.state.priceValue, this.state.durationValue, this.state.difficultyLevelValue, queryOrgCourses, null, null, queryUdemyCourses, sources)
                 }
               } else {
                 this.pullCourses(this.state.selectedSkill, this.state.priceValue, this.state.durationValue, this.state.difficultyLevelValue, queryOrgCourses, null, null, queryUdemyCourses, sources)
               }
           });
         }
       }
      }
    }

    pullCourses(searchValue, priceValue, durationValue, difficultyLevelValue, queryOrgCourses, orgCode, filterObject, queryUdemyCourses, sources) {
      console.log('pullCourses called', searchValue, priceValue, durationValue, difficultyLevelValue, queryOrgCourses, orgCode, filterObject, queryUdemyCourses, sources)

      this.setState({ animating: true, errorMessage: null, successMessage: null })

      // const searchValue = 'Excel'
      if (!searchValue) {
        searchValue = this.state.selectedSkill
      }
      const categoryValue = null
      const subcategoryValue = null
      // let priceValue = this.state.priceValue
      if (!priceValue) {
        priceValue = this.state.priceValue
      }
      let ratingValue = null
      if (!ratingValue) {
        ratingValue = 3.0
      }
      // let durationValue = this.state.durationValue
      if (!durationValue) {
        durationValue = this.state.durationValue
      }

      if (difficultyLevelValue) {
        difficultyLevelValue = difficultyLevelValue.toLowerCase()
      }

      let excludeOrgCourses = false
      if (sources && !sources.includes('OrgCourses')) {
        orgCode = null
        excludeOrgCourses = true
      }

      if (sources && sources.length === 1 && sources[0] === 'Udemy') {
        queryUdemyCourses = true
        if (!searchValue) {
          searchValue = "Marketing"
        }
      } else if ((sources && sources.length > 1) || (sources && sources.length === 1 && sources[0] !== 'Udemy')) {
        queryUdemyCourses = false
      }
      const courseQueryObject = { searchValue, categoryValue, subcategoryValue, priceValue, ratingValue, durationValue, difficultyLevelValue, queryOrgCourses, excludeOrgCourses, orgCode, filterObject, queryUdemyCourses, sources, isActive: true, resLimit: 100 }

      Axios.get('/api/courses/search', { params: courseQueryObject })
      .then((response) => {
        console.log('Search courses query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved courses')

            if (response.data.responseData) {

              const courses = response.data.responseData.results
              const filteredCourses = courses
              this.setState({ courses, filteredCourses, sources, queryUdemyCourses, animating: false })
            }

          } else {
            console.log('no course data found', response.data.message)
            this.setState({ sources, queryUdemyCourses, animating: false, errorMessage: 'Found no courses that match the criteria'})
          }

      }).catch((error) => {
          console.log('Course query did not work', error);
          this.setState({ sources, animating: false, errorMessage: 'There was an unknown error retrieving the courses'})
      });
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'search') {
        console.log('in search')
        const searchString = event.target.value
        this.setState({ searchString, animating: true })
        this.filterResults(event.target.value, '', null, null, true, null, this.state.sources)
      } else if (event.target.name === 'searchReferees') {
        const searchString = event.target.value
        this.setState({ searchString, animating: true })
        this.filterResults(event.target.value, '', null, null, true, 'referee',this.state.sources)
      } else if (event.target.name.includes('filter|')) {

        if (this.state.showFilters) {
          const nameArray = event.target.name.split("|")

          if (nameArray[1] === 'price') {
            this.setState({ priceValue: event.target.value })
            this.pullCourses(this.state.selectedSkill, event.target.value.toLowerCase(), this.state.durationValue, this.state.difficultyLevelValue, this.state.queryOrgCourses, this.state.org, null, this.state.queryUdemyCourses, this.state.sources)
          } else if (nameArray[1] === 'duration') {
            this.setState({ durationValue: event.target.value })
            let durationValue = event.target.value
            if (durationValue === 'Extra Long') {
              durationValue = 'extraLong'
            } else {
              durationValue = durationValue.toLowerCase()
            }
            this.pullCourses(this.state.selectedSkill, this.state.priceValue, durationValue, this.state.difficultyLevelValue, this.state.queryOrgCourses, this.state.org, this.state.queryUdemyCourses, this.state.sources)
          } else if (nameArray[1] === 'difficultyLevel') {
            this.setState({ difficultyLevelValue: event.target.value })
            this.pullCourses(this.state.selectedSkill, this.state.priceValue, this.state.durationValue, event.target.value, this.state.queryOrgCourses, this.state.org, this.state.queryUdemyCourses, this.state.sources)
          } else {
            this.setState({ [nameArray[1]]: event.target.value })
            let filterObject = {
              selectedSkill: this.state.selectedSkill,
              priceValue: this.state.priceValue,
              durationValue: this.state.durationValue,
              difficultyLevelValue: this.state.difficultyLevelValue
            }

            filterObject[nameArray[1]] = event.target.value

            this.pullCourses(this.state.selectedSkill, this.state.priceValue, this.state.durationValue, event.target.value, this.state.queryOrgCourses, this.state.org, filterObject, this.state.queryUdemyCourses, this.state.sources)
          }

        } else {

          let filters = this.state.itemFilters

          const nameArray = event.target.name.split("|")
          const field = nameArray[1]

          let index = 0
          for (let i = 1; i <= filters.length; i++) {
            if (filters[i - 1].name === field) {
              filters[i - 1]['value'] = event.target.value
              index = i - 1
            }
          }

          let itemFilters = filters

          //reset everything
          let searchString = ''
          for (let i = 1; i <= itemFilters.length; i++) {
            if (itemFilters[i - 1].name !== field) {
              itemFilters[i - 1]['value'] = this.state.defaultFilterOption
            }
          }
          let itemSorters = this.state.itemSorters
          for (let i = 1; i <= itemSorters.length; i++) {
            itemSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          this.setState({ filters, itemFilters, animating: true, searchString, itemSorters })

          this.filterResults(this.state.searchString, event.target.value, filters, index, false, null, this.state.sources)
        }

      } else if (event.target.name.includes('sort|')) {

        if (this.state.showFilters) {

        } else {
          let sorters = this.state.itemSorters

          const nameArray = event.target.name.split("|")
          const field = nameArray[1]

          // let index = 0
          for (let i = 1; i <= sorters.length; i++) {
            if (sorters[i - 1].name === field) {
              sorters[i - 1]['value'] = event.target.value
              // index = i - 1
            }
          }

          let itemSorters = sorters

          //reset everything
          let searchString = ''
          let itemFilters = this.state.itemFilters
          for (let i = 1; i <= itemFilters.length; i++) {
            itemFilters[i - 1]['value'] = this.state.defaultFilterOption
          }

          for (let i = 1; i <= itemSorters.length; i++) {
            if (itemSorters[i - 1].name !== field) {
              itemSorters[i - 1]['value'] = this.state.defaultSortOption
            }
          }

          this.setState({ searchString, itemFilters, itemSorters, animating: true })

          this.sortResults(event.target.value, field)
        }

      } else if (event.target.name === 'pathway') {

        let selectedPathway = event.target.value
        let largestSkillGaps = []

        for (let i = 1; i <= this.state.pathwayOptions.length; i++) {
          if (this.state.pathwayOptions[i - 1].name === event.target.value) {
            for (let j = 1; j <= this.state.pathwayOptions[i - 1].skills.length; j++) {
              // console.log('show the title: ', this.state.pathwayOptions[i - 1].skillTraits[j - 1])
              largestSkillGaps.push(this.state.pathwayOptions[i - 1].skills[j - 1].name)
            }
          }
        }

        this.setState({ selectedPathway, largestSkillGaps })
      } else if (event.target.name === 'goalType') {
        let goalType = { name: '' }
        for (let i = 1; i <= this.state.goalTypeOptions.length; i++) {
          if (this.state.goalTypeOptions[i - 1].description === event.target.value) {
            goalType = this.state.goalTypeOptions[i - 1]
          }
        }
        this.setState({ goalType })
        if (goalType.name === 'Basics' || goalType.name === 'Stability' || goalType.name === 'Pay' || goalType.name === 'Interests') {
          this.addLearningObjectiveOptions(null, goalType.name.toLowerCase(), event.target.name)
        }
      } else if (event.target.name === 'entrepreneurshipGoal') {
        this.setState({ entrepreneurshipGoal: event.target.value })
        this.addLearningObjectiveOptions(event.target.value, 'entrepreneurship', event.target.name)
      } else if (event.target.name === 'entrepreneurshipIndustry') {
        this.setState({ entrepreneurshipIndustry: event.target.value })
        this.addLearningObjectiveOptions(event.target.value, 'entrepreneurship', event.target.name)
      } else if (event.target.name === 'targetDate') {
        this.setState({ targetDate: event.target.value })
      } else if (event.target.name === 'intensity') {
        this.setState({ intensity: event.target.value })
      } else if (event.target.name === 'budget') {
        this.setState({ budget: event.target.value })
      } else if (event.target.name === 'searchCareers') {
        this.searchItems(event.target.value, 'career')
      } else if (event.target.name === 'searchOpportunities') {
        this.searchItems(event.target.value, 'opportunity')
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchItems(searchString, type) {
      console.log('searchItems called', searchString, type)

      if (type === 'career') {
        if (!searchString || searchString === '') {
          this.setState({ searchString, searchIsAnimating: false, careerOptions: null })
        } else {
          this.setState({ searchString, searchIsAnimating: true })

          const search = true

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            const excludeMissingOutlookData = true
            const excludeMissingJobZone = true

            Axios.put('/api/careers/search', {  searchString, search, excludeMissingOutlookData, excludeMissingJobZone })
            .then((response) => {
              console.log('Careers query attempted', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved careers')

                  if (response.data) {

                    let careerOptions = []
                    if (response.data.careers && response.data.careers.length > 0) {
                      careerOptions = response.data.careers
                    }

                    self.setState({ careerOptions, searchIsAnimating: false })
                  }

                } else {
                  console.log('no career data found', response.data.message)
                  self.setState({ searchIsAnimating: false })
                }

            }).catch((error) => {
                console.log('Career query did not work', error);
                self.setState({ searchIsAnimating: false })
            });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();
        }
      } else if (type === 'opportunity') {
        if (!searchString || searchString === '') {
          this.setState({ searchStringOpportunities: searchString, searchIsAnimating: false, careerOptions: null })
        } else {
          this.setState({ searchStringOpportunities: searchString, searchIsAnimating: true })

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            const orgCode = self.state.org
            const placementPartners = self.state.placementPartners
            const accountCode = self.state.accountCode
            const search = true
            const postTypes = ['Internship','Work','Assignment','Problem','Challenge']

            Axios.get('/api/postings/search', { params: { searchString, orgCode, placementPartners, accountCode, search, postTypes } })
            .then((response) => {
              console.log('Opportunity search query attempted', response.data);

                if (response.data.success) {
                  console.log('opportunity search query worked')

                  let opportunityOptions = response.data.postings
                  self.setState({ opportunityOptions, searchIsAnimating: false })

                } else {
                  console.log('opportunity search query did not work', response.data.message)

                  let opportunityOptions = []
                  self.setState({ opportunityOptions, searchIsAnimating: false })

                }

            }).catch((error) => {
                console.log('Search query did not work for some reason', error);
                self.setState({ searchIsAnimating: false })
            });

            // Axios.put('/api/careers/search', {  searchString, search, excludeMissingOutlookData, excludeMissingJobZone })
            // .then((response) => {
            //   console.log('Careers query attempted', response.data);
            //
            //     if (response.data.success) {
            //       console.log('successfully retrieved careers')
            //
            //       if (response.data) {
            //
            //         let careerOptions = []
            //         if (response.data.careers && response.data.careers.length > 0) {
            //           careerOptions = response.data.careers
            //         }
            //
            //         // THIS IS SUPER UNNECESSARY
            //         const filterCriteriaArray = response.data.filterCriteriaArray
            //         const sortCriteriaArray = null
            //
            //         self.setState({ careerOptions, animating: false })
            //       }
            //
            //     } else {
            //       console.log('no career data found', response.data.message)
            //       self.setState({ animating: false })
            //     }
            //
            // }).catch((error) => {
            //     console.log('Career query did not work', error);
            //     self.setState({ animating: false })
            // });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();
        }
      }
    }

    filterResults(searchString, filterString, filters, index, search, searchType, sources) {
      console.log('filterResults called', searchString, filterString, filters, index, search, searchType, sources)

      if (searchType === 'referee') {

        const orgCode = this.state.org

        const self = this
        function officiallyFilter() {
          console.log('officiallyFilter called')
          self.setState({ animating: true, errorMessage: null, successMessage: null })

          Axios.get('/api/members/search', { params: { searchString, orgCode } })
          .then((response) => {
            console.log('Members query attempted', response.data);

              if (response.data.success) {
                console.log('successfully retrieved members')

                let memberOptions = null
                if (response.data.members) {
                  memberOptions = response.data.members
                }

                self.setState({ memberOptions, animating: false })

              } else {
                console.log('no course data found', response.data.message)
                self.setState({ animating: false, errorMessage: 'No courses were found that match that criteria' })
              }

          }).catch((error) => {
              console.log('Course query did not work in filter results', error);
              self.setState({ animating: false, errorMessage: 'Course query did not work for an unknown reason'})
          });
        }

        const delayFilter = () => {
          console.log('delayFilter called: ')
          clearTimeout(self.state.timerId)
          self.state.timerId = setTimeout(officiallyFilter, 1000)
        }

        delayFilter();

      } else {

        const categoryValue = null
        const subcategoryValue = null
        const priceValue = this.state.priceValue
        let ratingValue = null
        if (!ratingValue) {
          ratingValue = 3.0
        }

        const durationValue = this.state.durationValue
        let difficultyLevelValue = this.state.difficultyLevelValue
        if (difficultyLevelValue) {
          difficultyLevelValue = difficultyLevelValue.toLowerCase()
        }

        const queryOrgCourses = this.state.queryOrgCourses
        let queryUdemyCourses = this.state.queryUdemyCourses
        if (sources && sources.length === 1 && sources[0] === 'Udemy') {
          queryUdemyCourses = true
        } else if ((sources && sources.length > 1) || (sources && sources.length === 1 && sources[0] !== 'Udemy')) {
          queryUdemyCourses = false
        }

        let excludeOrgCourses = false
        let orgCode = this.state.org
        if (sources && !sources.includes('OrgCourses')) {
          orgCode = null
          excludeOrgCourses = true
        }
        console.log('show me the orgCode---', orgCode, sources, this.state.org)
        const filterObject = {
          selectedSkill: this.state.selectedSkill,
          priceValue: this.state.priceValue,
          durationValue: this.state.durationValue,
          difficultyLevelValue: this.state.difficultyLevelValue
        }

        // console.log('show me sources: ', sources)

        const self = this
        function officiallyFilter() {
          console.log('officiallyFilter called')

          // Axios.put('/api/projects/filter', {  searchString, filterString, filters, defaultFilterOption, index, search, projects, type, emailId })
          // .then((response) => {
          //   console.log('Project filter query attempted', response.data);
          //
          //     if (response.data.success) {
          //       console.log('project filter query worked')
          //
          //       let filteredProjects = response.data.projects
          //       const filterCriteriaArray = response.data.filterCriteriaArray
          //       const sortCriteriaArray = null
          //
          //       self.setState({ filteredProjects, animating: false, filterCriteriaArray, sortCriteriaArray })
          //
          //     } else {
          //       console.log('project filter query did not work', response.data.message)
          //       self.setState({ animating: false })
          //     }
          //
          // }).catch((error) => {
          //     console.log('Project filter query did not work for some reason', error);
          //     self.setState({ animating: false })
          // });

          self.setState({ animating: true, errorMessage: null, successMessage: null })

          Axios.get('/api/courses/search', { params: { searchValue: searchString, categoryValue, subcategoryValue, priceValue, ratingValue, durationValue, difficultyLevelValue, queryOrgCourses, orgCode, excludeOrgCourses, filterObject, queryUdemyCourses, sources, isActive: true, resLimit: 100 } })
          .then((response) => {
            console.log('Search courses query attempted', response.data);

              if (response.data.success) {
                console.log('successfully retrieved courses')

                if (response.data.responseData) {

                  const courses = response.data.responseData.results
                  const filteredCourses = courses
                  self.setState({ courses, filteredCourses, sources, queryUdemyCourses, animating: false })
                }

              } else {
                console.log('no course data found', response.data.message)
                self.setState({ sources, queryUdemyCourses, animating: false, errorMessage: 'No courses were found that match that criteria' })
              }

          }).catch((error) => {
              console.log('Course query did not work in search', error);
              self.setState({ sources, animating: false, errorMessage: 'Course query did not work for an unknown reason'})
          });
        }

        const delayFilter = () => {
          console.log('delayFilter called: ')
          clearTimeout(self.state.timerId)
          self.state.timerId = setTimeout(officiallyFilter, 1000)
        }

        delayFilter();
      }
    }

    sortResults(sortString, sortName) {
      console.log('sortResults called', sortString, sortName)

      // let projects = this.state.projects
      // let filteredProjects = []
      //
      // Axios.put('/api/projects/sort', { sortString, projects, sortName })
      // .then((response) => {
      //   console.log('Project sort query attempted', response.data);
      //
      //     if (response.data.success) {
      //       console.log('project sort query worked')
      //
      //       let filteredProjects = response.data.projects
      //       const filterCriteriaArray = null
      //       const sortCriteriaArray = response.data.sortCriteriaArray
      //
      //       this.setState({ filteredProjects, animating: false, filterCriteriaArray, sortCriteriaArray })
      //
      //     } else {
      //       console.log('project sort query did not work', response.data.message)
      //       this.setState({ animating: false })
      //     }
      //
      // }).catch((error) => {
      //     console.log('Project sort query did not work for some reason', error);
      //     this.setState({ animating: false })
      // });
    }

    craftCourseSchedule() {
      console.log('craftCourseSchedule called')

      this.setState({ animating: true, errorMessage: null, successMessage: null, modalIsOpen: false })

      if (!this.state.goalType.name || this.state.goalType.name === '') {
        this.setState({ animating: false, errorMessage: 'Please add a goal type' })
      } else if (this.state.goalType.name === 'Career' && !this.state.selectedCareer) {
        this.setState({  animating: false, errorMessage: 'Please select a career or choose another goal type' })
      } else if (this.state.goalType.name === 'Opportunity' && !this.state.selectedOpportunity) {
        this.setState({  animating: false, errorMessage: 'Please select an opportunity or choose another goal type' })
      } else if (this.state.goalType.name === 'Entrepreneurship' && !this.state.entrepreneurshipGoal) {
        this.setState({  animating: false, errorMessage: 'Please select your primary goal within entrepreneurship or choose another goal type' })
      } else if (this.state.goalType.name === 'Entrepreneurship' && !this.state.entrepreneurshipIndustry) {
        this.setState({  animating: false, errorMessage: 'Please select your primary industry for your venture or choose another goal type' })
      } else if (!this.state.targetDate || this.state.targetDate === '') {
        this.setState({  animating: false, errorMessage: 'Please add a target achievement date' })
      } else if (!this.state.intensity || this.state.intensity === '') {
        this.setState({  animating: false, errorMessage: 'Please select an intensity' })
      } else if (!this.state.learningObjectives || this.state.learningObjectives.length === 0) {
        this.setState({  animating: false, errorMessage: 'Please select a career or opportunity that populates learning objectives. We cannot accept custom learning objectives at this time.' })
      } else if (!this.state.budget || this.state.budget === '') {
        this.setState({  animating: false, errorMessage: 'Please detail your course budget' })
      } else {

        const goalType = this.state.goalType
        const selectedCareer = this.state.selectedCareer
        const selectedOpportunity = this.state.selectedOpportunity
        const entrepreneurshipGoal = this.state.entrepreneurshipGoal
        const entrepreneurshipIndustry = this.state.entrepreneurshipIndustry
        const targetDate = this.state.targetDate
        const intensity = this.state.intensity
        const learningObjectives = this.state.learningObjectives
        const budget = this.state.budget

        Axios.put('/api/courses/schedule', {
          goalType, selectedCareer, selectedOpportunity, entrepreneurshipGoal, entrepreneurshipIndustry,
          targetDate, intensity, learningObjectives, budget
        })
        .then((response) => {
          console.log('Course schedule craft attempted', response.data);

            if (response.data.success) {
              console.log('successfully crafted the schedule')

              if (response.data.schedule) {

                const schedule = response.data.schedule

                let showScheduleResults = false
                if (schedule.length > 0) {
                  showScheduleResults = true
                }

                this.setState({ animating: false, schedule, showScheduleResults  })
              } else {
                this.setState({ animating: false, errorMessage: 'no data?!' })
              }

            } else {
              console.log('failed calculate course schedule', response.data.message)
              this.setState({ animating: false, errorMessage: 'There was an error: ' + response.data.message })
            }

        }).catch((error) => {
            console.log('Course schedule calculation did not work', error);
            this.setState({ animating: false, error: 'Course schedule calculation did not work for some reason' })
        });
      }
    }

    getCourseMatches(selectedGoal) {
      console.log('getCourseMatches called', selectedGoal)

      this.setState({ animating: true, errorMessage: null, successMessage: null, modalIsOpen: false })

      if (!this.state.goalType.name || this.state.goalType.name === '') {
        this.setState({ animating: false, errorMessage: 'Please add a goal type' })
      } else if (this.state.goalType.name === 'Career' && !this.state.selectedCareer) {
        this.setState({  animating: false, errorMessage: 'Please select a career or choose another goal type' })
      } else if (this.state.goalType.name === 'Opportunity' && !this.state.selectedOpportunity) {
        this.setState({  animating: false, errorMessage: 'Please select an opportunity or choose another goal type' })
      } else if (this.state.goalType.name === 'Entrepreneurship' && !this.state.entrepreneurshipGoal) {
        this.setState({  animating: false, errorMessage: 'Please select your primary goal within entrepreneurship or choose another goal type' })
      } else if (this.state.goalType.name === 'Entrepreneurship' && !this.state.entrepreneurshipIndustry) {
        this.setState({  animating: false, errorMessage: 'Please select your primary industry for your venture or choose another goal type' })
      } else if (!this.state.learningObjectives || this.state.learningObjectives.length === 0) {
        this.setState({  animating: false, errorMessage: 'Please select a career or opportunity that populates learning objectives. We cannot accept custom learning objectives at this time.' })
      } else if (!this.state.budget || this.state.budget === '') {
        this.setState({  animating: false, errorMessage: 'Please detail your course budget' })
      } else {

        const goalType = this.state.goalType
        const selectedCareer = this.state.selectedCareer
        const selectedOpportunity = this.state.selectedOpportunity
        const entrepreneurshipGoal = this.state.entrepreneurshipGoal
        const entrepreneurshipIndustry = this.state.entrepreneurshipIndustry
        const learningObjectives = this.state.learningObjectives
        const budget = this.state.budget

        Axios.put('/api/schedule/matches', {
          goalType, selectedCareer, selectedOpportunity, entrepreneurshipGoal, entrepreneurshipIndustry,
          learningObjectives, budget
        })
        .then((response) => {
          console.log('Course matches attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved course matches')

              if (response.data.courses) {

                const courses = response.data.courses
                const filteredCourses = courses

                this.setState({ animating: false, courses, filteredCourses, modalIsOpen: false  })

              } else {
                this.setState({ animating: false, errorMessage: 'Unknown error after pulling courses' })
              }

            } else {
              console.log('failed to calculate courses', response.data.message)
              this.setState({ animating: false, errorMessage: 'There was an error: ' + response.data.message })
            }

        }).catch((error) => {
            console.log('Course calculation did not work', error);
            this.setState({ animating: false, errorMessage: 'Course recommendation calculation did not work for some reason' })
        });
      }
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    closeModal() {
      console.log('closeModal in projects: ', this.state.showProjectDetail)

      this.setState({ modalIsOpen: false, startRecommendation: false, selectedCourse: null,
        showMatchingCriteria: false, showBrowseCourses: false, showScheduleSetup: false,
        selectedIndex: null, selectedIndex1: null, selectedIndex2: null, takeQuiz: false,
        showComments: false, showLessonDetails: false, selectedLesson: null,
        selectedQuestions: null, selectedCustomAssessment: null,
        showCustomQuiz: false, selectedSubmission: null
      });

      if (this.props.toggleShowImportWidget) {
        this.props.toggleShowImportWidget(false)
      }

    }

    itemClicked(index1, index2, item, type) {
      console.log('itemClicked called', index1, index2, item, type)

      if (type === 'activity') {
        let lessons = this.state.lessons
        if (lessons[index1].activities[index2].completions) {
          if (lessons[index1].activities[index2].completions.includes(this.state.emailId)) {
            console.log('remove the item 1: ', lessons[index1].activities[index2].completions)
            const removeIndex = lessons[index1].activities[index2].completions.indexOf(this.state.emailId)
            lessons[index1]['activities'][index2]['completions'].splice(removeIndex,1)
            console.log('remove the item 2: ', lessons[index1].activities[index2].completions)
          } else {
            lessons[index1]['activities'][index2]['completions'].push(this.state.emailId)
          }
        } else {
          lessons[index1]['activities'][index2]['completions'] = [this.state.emailId]
        }

        this.setState({ lessons, isSaving: true })

        // save changes to lesson
        const _id = lessons[index1]._id
        const activities = lessons[index1].activities
        Axios.post('/api/lessons', { _id, activities })
        .then((response) => {
          console.log('attempting to save activities')
          if (response.data.success) {
            console.log('saved activities', response.data)

            this.setState({ successMessage: 'Removed from saved favorites', isSaving: false })

          } else {
            console.log('did not save successfully')
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false })
        });
      }
    }

    subNavClicked(itemSelected) {
      console.log('subNavClicked called', itemSelected)

      this.setState({ subNavSelected: itemSelected })
      localStorage.setItem('courseNavSelected', itemSelected)

    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type === 'career') {

        const searchObject = passedItem
        const searchString = passedItem.name
        const unready = false
        const careerOptions = null

        this.setState({ searchObject, searchString, unready, careerOptions })

      } else if (type === 'opportunity') {
        const searchObject = passedItem
        let searchStringOpportunities = passedItem.name
        if (passedItem.title) {
          searchStringOpportunities = passedItem.title
        }
        const unready = false
        const opportunityOptions = null
        console.log('show searchString: ', searchStringOpportunities)

        this.setState({ searchObject, searchStringOpportunities, unready, opportunityOptions })
      } else if (type === 'referee') {
        const searchObject = passedItem
        let searchString = passedItem.firstName + ' ' + passedItem.lastName
        let memberOptions = null
        this.setState({ searchObject, searchString, memberOptions })
      }
    }

    renderTags(type) {
      console.log('renderTags ', type, this.state.selectedCareer)

      if (type === 'career') {
        const favoritedCareers = this.state.favoritedCareers

        if (favoritedCareers && favoritedCareers.length > 0) {

          return (
            <div key={"favoritedCareers"}>
              <div className="spacer" />
              {favoritedCareers.map((value, optionIndex) =>
                <div key={"career|" + optionIndex} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>

                  <button className="background-button float-left right-padding-5" onClick={() => this.addLearningObjectiveOptions(this.state.favoritedCareerDetails[optionIndex], type)}>
                    <div className="half-spacer" />
                    <div className={(this.state.selectedCareer === value) ? "tag-container-active" : "tag-container-inactive"}>
                      <p className="description-text-2">{value}</p>
                    </div>
                    <div className="half-spacer" />
                  </button>

                </div>
              )}
            </div>
          )
        }
      } else if (type === 'opportunity') {
        const favoritedOpportunities = this.state.favoritedOpportunities
        if (favoritedOpportunities && favoritedOpportunities.length > 0) {
          console.log('about to in', favoritedOpportunities)
          return (
            <div key={"favoritedOpportunities"}>
              <div className="spacer" />
              {favoritedOpportunities.map((value, optionIndex) =>
                <div key={"career|" + optionIndex} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>
                  <button className="background-button float-left right-padding-5" onClick={() => this.addLearningObjectiveOptions(this.state.favoritedOpportunityDetails[optionIndex], type)}>
                    <div className="half-spacer" />
                    <div className={(this.state.selectedOpportunity === value) ? "tag-container-active" : "tag-container-inactive"}>
                      <p className="description-text-2">{value}</p>
                    </div>
                    <div className="half-spacer" />
                  </button>

                </div>
              )}
            </div>
          )
        }
      } else if (type === 'learningObjective') {
        // console.log('in learningObjective', this.state.learningObjectives)
        // abilities, knowledge, , soft skill, hard skil, tech skill
        const learningObjectives = this.state.learningObjectives

        if (learningObjectives && learningObjectives.length > 0) {
          console.log('compare 0')
          return (
            <div key={"learningObjectives"}>
              <div className="spacer" />
              {learningObjectives.map((value, optionIndex) =>
                <div key={"career|" + optionIndex} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>
                  <div>
                    <div className="half-spacer" />
                    {(value.category === 'Ability') && (
                      <div className="tag-container-8 primary-background primary-border white-text">
                        <p className="description-text-2">{value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                      </div>
                    )}
                    {(value.category === 'Knowledge') && (
                      <div className="tag-container-8 secondary-background secondary-border white-text">
                        <p className="description-text-2">{value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                      </div>
                    )}
                    {(value.category === 'Soft Skill') && (
                      <div className="tag-container-8 tertiary-background tertiary-border white-text">
                        <p className="description-text-2">{value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                      </div>
                    )}
                    {(value.category === 'General Hard Skill') && (
                      <div className="tag-container-8 quaternary-background quaternary-border white-text">
                        <p className="description-text-2">{value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                      </div>
                    )}
                    {(value.category === 'Tech Skill') && (
                      <div className="tag-container-8 quinary-background quinary-border white-text">
                        <p className="description-text-2">{value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                      </div>
                    )}

                    <div className="half-spacer" />
                  </div>
                  {console.log('compare 1: ',optionIndex, this.state.selectedCareer, value)}

                </div>
              )}
            </div>
          )

        } else {
          return (
            <div key={"learningObjectives"} className="top-padding-20">
              <p className="description-text-2 error-color">Specify a career path or opportunity above for learning objectives to populate.</p>
            </div>
          )
        }
      } else if (type === 'referees') {
        console.log('in referees', this.state.referees)

        const referees = this.state.referees
        if (referees && referees.length > 0) {
          // console.log('about to in', favoritedOpportunities)
          return (
            <div key={"referees"}>
              <div className="spacer" />
              {referees.map((value, optionIndex) =>
                <div key={"career|" + optionIndex} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>
                  <div className="background-button float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className="tag-container-inactive">
                      <p className="description-text-2">{value.firstName} {value.lastName}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>

                </div>
              )}
            </div>
          )
        }
      }
    }

    removeTag(index, type) {
      console.log('removeTag called', index, type)

      if (type === 'career') {
        let favoritedCareers = this.state.favoritedCareers
        favoritedCareers.splice(index, 1)

        let favoritedCareerDetails = this.state.favoritedCareerDetails
        const careerToRemove = favoritedCareerDetails[index]
        favoritedCareerDetails.splice(index, 1)
        this.setState({ favoritedCareers, favoritedCareerDetails })

        // remove as favorite
        this.favoriteItem(careerToRemove, type)
      } else if (type === 'opportunity') {
        let favoritedOpportunities = this.state.favoritedOpportunities
        favoritedOpportunities.splice(index, 1)

        let favoritedOpportunityDetails = this.state.favoritedOpportunityDetails
        const opportunityToRemove = favoritedOpportunityDetails[index]
        favoritedOpportunityDetails.splice(index, 1)
        this.setState({ favoritedOpportunities, favoritedOpportunityDetails })

        // remove as favorite
        this.favoriteItem(opportunityToRemove, type)
      } else if (type === 'learningObjective') {
        let learningObjectives = this.state.learningObjectives
        learningObjectives.splice(index, 1)
        this.setState({ learningObjectives })
      } else if (type === 'referees') {
        let referees = this.state.referees
        referees.splice(index, 1)
        this.setState({ referees })
      }
    }

    addItem(type) {
      console.log('addItem called', type)

      if (type === 'career') {
        if (this.state.favoritedCareers.includes(this.state.searchString)) {
          this.setState({ errorMessage: 'You have already added this career' })
        } else {

          const searchString = ''
          const searchObject = null
          const unready = true

          let favoritedCareers = this.state.favoritedCareers
          favoritedCareers.unshift(this.state.searchString)

          let favoritedCareerDetails = this.state.favoritedCareerDetails
          favoritedCareerDetails.unshift(this.state.searchObject)

          // const selectedCareer = this.state.searchString

          this.setState({ searchString, searchObject, unready, favoritedCareers, errorMessage: null })

          // favorite item
          this.favoriteItem(this.state.searchObject, type)
          // console.log('pre-add learning: ', this.state.searchObject)
          // populate chart
          this.addLearningObjectiveOptions(this.state.searchObject,type)
        }
      } else if (type === 'opportunity') {
        if (this.state.favoritedOpportunities.includes(this.state.searchStringOpportunities)) {
          this.setState({ errorMessage: 'You have already added this opportunity' })
        } else {

          const searchStringOpportunities = ''
          const searchObject = null
          const unready = true

          let favoritedOpportunities = this.state.favoritedOpportunities
          favoritedOpportunities.unshift(this.state.searchStringOpportunities)

          let favoritedOpportunityDetails = this.state.favoritedOpportunityDetails
          favoritedOpportunityDetails.unshift(this.state.searchObject)

          this.setState({ searchStringOpportunities, searchObject, unready, favoritedOpportunities, errorMessage: null })

          // favorite item
          this.favoriteItem(this.state.searchObject, type)

          // populate chart
          this.addLearningObjectiveOptions(this.state.searchObject,type)
        }
      } else if (type === 'goalDate') {
      } else if (type === 'referee') {
        let referees = this.state.referees
        referees.push(this.state.searchObject)

        let searchString = ''
        let searchObject = null
        this.setState({ referees, searchString, searchObject })

      }
    }

    addLearningObjectiveOptions(selectedItem, type, subType) {
      console.log('addLearningObjectiveOptions called', selectedItem, type)

      if (type === 'career') {
        const selectedCareer = selectedItem.name

        let learningObjectives = []
        // console.log('in career: ')
        if (selectedItem.abilitiesArray) {
          for (let i = 1; i <= selectedItem.abilitiesArray.length; i++) {
            learningObjectives.push({
              category: 'Ability',
              name: selectedItem.abilitiesArray[i - 1].category,
              description: selectedItem.abilitiesArray[i - 1].subcategories.toString(),
            })
          }
        }
        if (selectedItem.knowledgeArray) {
          for (let i = 1; i <= selectedItem.knowledgeArray.length; i++) {
            learningObjectives.push({
              category: 'Knowledge',
              name: selectedItem.knowledgeArray[i - 1].category,
              description: selectedItem.knowledgeArray[i - 1].subcategories.toString(),
            })
          }
        }
        if (selectedItem.personalityData && selectedItem.personalityData.workStyles) {
          for (let i = 1; i <= selectedItem.personalityData.workStyles.length; i++) {
            learningObjectives.push({
              category: 'Soft Skill',
              name: selectedItem.personalityData.workStyles[i - 1],
            })
          }
        }
        if (selectedItem.skillsArray) {
          for (let i = 1; i <= selectedItem.skillsArray.length; i++) {
            learningObjectives.push({
              category: 'General Hard Skill',
              name: selectedItem.skillsArray[i - 1].category,
              description: selectedItem.skillsArray[i - 1].subcategories.toString(),
            })
          }
        }
        if (selectedItem.technologyArray) {
          for (let i = 1; i <= selectedItem.technologyArray.length; i++) {
            learningObjectives.push({
              category: 'Tech Skill',
              name: selectedItem.technologyArray[i - 1].name,
              description: selectedItem.technologyArray[i - 1].examples.toString(),
            })
          }
        }
        console.log('show learningObjectives: ')
        this.setState({ selectedCareer, learningObjectives })
      } else if (type === 'opportunity') {
        let selectedOpportunity = null
        if (selectedItem.title && selectedItem.title !== '') {
          selectedOpportunity = selectedItem.title
        } else {
          selectedOpportunity = selectedItem.name
        }

        this.setState({ searchIsAnimating: true })

        let benchmarkId = null
        let jobFunction = null
        if (selectedItem.benchmarkId) {
          benchmarkId = selectedItem.benchmarkId
        } else {
          if (selectedItem.jobFunction) {
            jobFunction = selectedItem.jobFunction
          } else if (selectedItem.workFunction) {
            jobFunction = selectedItem.workFunction
          } else if (selectedItem.functions && selectedItem.functions[0]) {
            jobFunction = selectedItem.functions[0]
          } else if (selectedItem.field && selectedItem.field.split("|")) {
            jobFunction = selectedItem.field.split("|")[0].trim()
          }
        }

        Axios.get('/api/benchmarks/byid', { params: { _id: benchmarkId, jobFunction } })
        .then((response) => {
          console.log('Benchmarks query by id attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved benchmarks for individual')

              let learningObjectives = []
              if (response.data.benchmark && response.data.benchmark.skills) {
                for (let i = 1; i <= response.data.benchmark.skills.length; i++) {

                  let category = response.data.benchmark.skills[i - 1].skillType
                  if (response.data.benchmark.skills[i - 1].skillType === 'Hard Skill') {
                    category = 'General Hard Skill'
                  }

                  const name = response.data.benchmark.skills[i - 1].title
                  const description = response.data.benchmark.skills[i - 1].description

                  learningObjectives.push({ category, name, description })
                }
              }

              this.setState({ selectedOpportunity, learningObjectives, searchIsAnimating: false })

            } else {
              console.log('no benchmarks by id data found', response.data.message)

              this.setState({ selectedOpportunity, searchIsAnimating: false })
            }

        }).catch((error) => {
            console.log('Benchmarks query by id did not work', error);
            this.setState({ selectedOpportunity, searchIsAnimating: false })
        });
      } else if (type === 'entrepreneurship') {
        let learningObjectives = []
        let entrepreneurshipGoal = this.state.entrepreneurshipGoal
        let entrepreneurshipIndustry = this.state.entrepreneurshipIndustry

        if (subType === 'entrepreneurshipGoal') {
          entrepreneurshipGoal = selectedItem
        } else if (subType === 'entrepreneurshipIndustry') {
          entrepreneurshipIndustry = selectedItem
        }

        learningObjectives.push({ category: 'Ability', name: 'Entrepreneurship' })
        learningObjectives.push({ category: 'Soft Skill', name: 'Time Management' })
        learningObjectives.push({ category: 'General Hard Skill', name: 'Strategic Thinking' })
        learningObjectives.push({ category: 'Soft Skill', name: 'Efficiency' })
        learningObjectives.push({ category: 'Soft Skill', name: 'Resilience' })
        learningObjectives.push({ category: 'Soft Skill', name: 'Communication' })
        learningObjectives.push({ category: 'Soft Skill', name: 'Sales' })
        console.log('show entrepreneurshipGoal: ', entrepreneurshipGoal)
        if (entrepreneurshipGoal && entrepreneurshipGoal !== '') {
          learningObjectives.push({ category: 'General Hard Skill', name: entrepreneurshipGoal })
        }
        if (entrepreneurshipIndustry && entrepreneurshipIndustry !== '') {
          learningObjectives.push({ category: 'Knowledge', name: entrepreneurshipIndustry })
        }

        this.setState({ learningObjectives })
      } else if (type === 'basics') {
        let learningObjectives = []

        learningObjectives.push({ category: 'Soft Skill', name: 'Organize' })
        learningObjectives.push({ category: 'Soft Skill', name: 'Leadership' })
        learningObjectives.push({ category: 'Soft Skill', name: 'Conflict Management' })
        learningObjectives.push({ category: 'Soft Skill', name: 'Decision Making' })
        learningObjectives.push({ category: 'Soft Skill', name: 'Time Management' })
        learningObjectives.push({ category: 'Soft Skill', name: 'Communication' })
        learningObjectives.push({ category: 'Tech Skill', name: 'Microsoft Office / Google Suite' })
        learningObjectives.push({ category: 'General Hard Skill', name: 'Math' })
        learningObjectives.push({ category: 'General Hard Skill', name: 'Writing' })

        this.setState({ learningObjectives })
      } else if (type === 'stability' || type === 'pay' || type === 'interests') {

        const profile = this.state.profile

        Axios.put('/api/learning-objectives', { profile, category: type })
        .then((response) => {
          console.log('Learning objectives query attempted', response.data);

          if (response.data.success) {
            console.log('learning objectives query worked')

            const learningObjectives = response.data.learningObjectives
            this.setState({ learningObjectives })

          } else {
            console.log('learning objectives query did not work', response.data.message)
            //don't allow signups without an org affiliation
            this.setState({ error: { message: 'There was an error finding the learning objectives' } })
          }

        }).catch((error) => {
            console.log('Learning objectives query did not work for some reason', error);
        });
      }
    }

    favoriteItem(item, type,e) {
      console.log('favoriteItem called', item, type)

      e.stopPropagation()

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      if (type) {

        // if (this.state.showSubFunction) {
        //
        //   const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,"course")
        //
        //   if (returnedObject.success) {
        //     this.setState({ successMessage: returnedObject.message, favorites: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
        //   } else {
        //     this.setState({ errorMessage: returnedObject.message, isSaving: false })
        //   }
        //
        // } else {
        // }
        let itemId = item._id

        let favoritesArray = this.state.favorites
        let newFavorites = this.state.newFavorites

        if (favoritesArray.includes(itemId) || this.state.favoritedCourseDetails.some(selectedCourse => selectedCourse.url === item.url)){

          let index = favoritesArray.indexOf(itemId)
          let courseIndex = 0
          let favoritedCourseDetails = this.state.favoritedCourseDetails
          if (this.state.favoritedCourseDetails && this.state.favoritedCourseDetails.some(selectedCourse => selectedCourse.url === item.url)) {
            courseIndex = favoritedCourseDetails.findIndex(x => x.url === item.url);

            if (courseIndex > -1) {
              index = favoritesArray.indexOf(favoritedCourseDetails[courseIndex].favoriteId)
              favoritedCourseDetails.splice(courseIndex, 1);
            }
          }
          console.log('item to remove 1: ', favoritesArray, favoritesArray.length, favoritedCourseDetails.length)
          if (index > -1) {
            favoritesArray.splice(index, 1);
          }
          console.log('item to remove 2: ', favoritesArray, favoritesArray.length, favoritedCourseDetails.length)

          if (newFavorites) {
            let newIndex = newFavorites.findIndex(nf => nf.id === itemId)
            if (newIndex > -1) {
              newFavorites.splice(newIndex, 1)
            }
          }

          Axios.post('/api/favorites/save', {
            favoritesArray, newFavorites, emailId: this.state.emailId
          })
          .then((response) => {
            console.log('attempting to save removal from favorites')
            if (response.data.success) {
              console.log('saved removal from favorites', response.data)

              this.setState({ successMessage: 'Removed from saved favorites', favorites: favoritesArray, favoritedCourseDetails, isSaving: false })

            } else {
              console.log('did not save successfully')
              this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false })
          });

        } else {
          console.log('-----compare it: ', this.state.favoritedCourseDetails, item)
          console.log('adding item: ', favoritesArray, itemId)

          if (type === 'course' && item.id && item.url) { // Udemy course
            // first save the course
            const courseObject = {
              source: 'Udemy', title: item.title, headline: item.headline, udemyId: item.id, url: item.url,
              image_125_H: item.image_125_H, image_240x135: item.image_240x135, image_480x270: item.image_480x270,
              is_paid: item.is_paid, price: item.price, visible_instructors: item.visible_instructors
            }

            Axios.post('/api/courses', courseObject)
            .then((response) => {
              console.log('attempting to save course')
              if (response.data.success) {
                console.log('saved course as new', response.data)
                //clear values

                itemId = response.data._id

                favoritesArray.push(itemId)
                newFavorites.push({ id: itemId, name: item.title, category: 'course' })

                let favoritedCourseDetails = this.state.favoritedCourseDetails
                favoritedCourseDetails.push(item)

                Axios.post('/api/favorites/save', {
                  favoritesArray, newFavorites, emailId: this.state.emailId
                })
                .then((response) => {
                  console.log('attempting to save addition to favorites')
                  if (response.data.success) {
                    console.log('saved addition to favorites', response.data)

                    this.setState({
                      successMessage: 'Saved as a favorite!', favorites: favoritesArray, favoritedCourseDetails, isSaving: false
                    })

                  } else {
                    console.log('did not save successfully')
                    this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
                  }
                }).catch((error) => {
                    console.log('save did not work', error);
                    this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false})
                });
              } else {
                console.log('did not save successfully')

              }
            }).catch((error) => {
                console.log('save did not work', error);
                this.setState({
                  serverSuccessPlan: false,
                  serverErrorMessagePlan: 'there was an error saving favorites', isSaving: false
                })
            });
          } else {
            favoritesArray.push(itemId)
            newFavorites.push({ id: itemId, name: item.title, category: 'course' })

            Axios.post('/api/favorites/save', {
              favoritesArray, newFavorites, emailId: this.state.emailId
            })
            .then((response) => {
              console.log('attempting to save addition to favorites')
              if (response.data.success) {
                console.log('saved addition to favorites', response.data)

                this.setState({ successMessage: 'Saved as a favorite!', favorites: favoritesArray, isSaving: false })

              } else {
                console.log('did not save successfully')
                this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
              }
            }).catch((error) => {
                console.log('save did not work', error);
                this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false})
            });
          }
        }
      }
    }

    enrollInCourse(item, type,e,isCompleted) {
      console.log('enrollInCourse called', item, type)

      // e.preventDefault()
      // e.stopPropagation()

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      let _id = null
      let eIndex = null
      if (this.state.enrollments && this.state.enrollments.some(enrollment => enrollment.courseId === item._id)) {
        eIndex = this.state.enrollments.findIndex(enrollment => enrollment.courseId === item._id)
        _id = this.state.enrollments[eIndex]._id
      }

      if ((isCompleted) || (!_id && !isCompleted)) {
        const firstName = this.state.cuFirstName
        const lastName = this.state.cuLastName
        const email = this.state.emailId
        const username = this.state.username
        const pictureURL = this.state.pictureURL
        const courseId = item._id
        const courseImageURL = item.imageURL
        let courseName = item.name
        if (item.title) {
          courseName = item.title
        }
        const courseCategory = item.category
        const courseDegreeType = item.degreeType
        const courseSchoolName = item.schoolName
        const courseSchoolURL = item.schoolURL
        const courseDescription = item.description
        const courseEstimatedHours = item.estimatedHours
        const courseProgramMethod = item.programMethod
        const courseDifficultyLevel = item.difficultyLevel
        const courseCurriculumItemId = item.curriculumItemId

        const orgCode = this.state.org
        const orgName = this.state.orgName
        // console.log('description? ', courseDescription)
        const createdAt = new Date()
        const updatedAt = new Date()

        const enrollmentObject = {
          _id, firstName, lastName, email, username, pictureURL,
          courseId, courseImageURL, courseName, courseCategory, courseDegreeType,
          courseSchoolName, courseSchoolURL, courseDescription, courseEstimatedHours,
          courseProgramMethod, courseDifficultyLevel, courseCurriculumItemId,
          isCompleted, orgCode, orgName, createdAt, updatedAt
        }

        Axios.post('/api/courses/enrollments', enrollmentObject)
        .then((response) => {
          console.log('attempting to save addition to favorites')
          if (response.data.success) {
            console.log('saved addition to favorites', response.data)

            // let itemId = item._id

            let enrollments = this.state.enrollments

            if (isCompleted && eIndex > -1) {
              // enrollments[eIndex]['isCompleted'] = true

              if (enrollments) {
                enrollments.push(enrollmentObject)
              } else {
                enrollments = [enrollmentObject]
              }

              if (item.degreeType === 'Badge' || item.degreeType === 'Certificate' || item.degreeType === 'Certification') {
                // save as certificate

                const certificate = {
                  courseId: item._id, name: item.name, imageURL: item.imageURL, category: item.category,
                  programURL: item.programURL, schoolURL: item.schoolURL, schoolName: item.schoolName,
                  degreeType: item.degreeType, programMethod: item.programMethod, location: item.location,
                  estimatedHours: item.estimatedHours, description: item.description, gradeLevel: item.gradeLevel,
                  knowledgeLevel: item.knowledgeLevel, functions: item.functions, pathways: item.pathways,
                  price: item.price, isCompleted: true, updateCertificate: true,
                  email: this.state.emailId, createdAt: new Date(), updatedAt: new Date()
                }

                Axios.post('/api/certificates', certificate)
                .then((response) => {
                  console.log('attempting to post certificate')

                  if (response.data.success) {
                    console.log('saved post to certificate', response.data)


                  } else {
                    console.log('did not save certificate successfully')
                    this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
                  }
                }).catch((error) => {
                    console.log('saving certificate did not work', error);
                    this.setState({ errorMessage: 'there was an error saving enrollment certificate', isSaving: false})
                });

              }
            } else {
              if (enrollments && enrollments.some(selectedCourse => selectedCourse.courseId === item._id)) {
                // const index = enrollments.findIndex(selectedCourse => selectedCourse.courseId === item._id)
                // enrollments.splice(index, 1)
              } else if (enrollments) {
                enrollments.push(enrollmentObject)
              } else {
                enrollments = [enrollmentObject]
              }
            }

            this.setState({ successMessage: 'Saved enrollment!', enrollments, isSaving: false })

          } else {
            console.log('did not save enrollment successfully')
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving enrollment', isSaving: false})
        });
      } else {
        this.setState({ isSaving: false, errorMessage: 'You have already enrolled in this course/program'})
      }
    }

    duplicateItem(item, parentIndex, childIndex) {
      console.log('duplicateItem called', item, parentIndex, childIndex)

      let schedule = this.state.schedule
      let courses = schedule[parentIndex].courses
      courses.splice(childIndex,0,item)
      schedule[parentIndex]['courses'] = courses
      console.log('new schedule: ', item, schedule)
      this.setState({ schedule })

    }

    deleteItem(item, parentIndex, childIndex) {
      console.log('deleteItem called', item, parentIndex, childIndex)

      let schedule = this.state.schedule
      let courses = schedule[parentIndex].courses
      courses.splice(childIndex,1)
      schedule[parentIndex]['courses'] = courses
      this.setState({ schedule })

    }

    calculateMatches(matchingView, calcMatches, newPreferences) {
      console.log('calculateMatches called', matchingView, calcMatches, newPreferences)

      // ReactTooltip.hide()

      // if (matchingView) {
      //   this.setState({ matchingView: true })
      // } else {
      //   this.setState({ matchingView: false })
      // }

      if (matchingView) {

        this.setState({ matchingView: true, errorMessage: null })

        if (!this.state.profile) {
          this.setState({ animating: false, matchingView: true, errorMessage: 'Please take career assessments before receiving personalized matches' })
        } else {
          this.setState({ animating: true, modalIsOpen: false })

          const profile = this.state.profile
          const matchingCriteria = this.state.matchingCriteria
          const useCases = this.state.useCases
          const budget = this.state.budget

          let orgCode = this.state.org
          const sources = this.state.sources
          let excludeOrgCourses = false
          if (sources && !sources.includes('OrgCourses')) {
            orgCode = null
            excludeOrgCourses = true
          }

          const queryOrgCourses = this.state.queryOrgCourses
          let queryUdemyCourses = this.state.queryUdemyCourses
          if (sources && sources.length === 1 && sources[0] === 'Udemy') {
            queryUdemyCourses = true
          } else if ((sources && sources.length > 1) || (sources && sources.length === 1 && sources[0] !== 'Udemy')) {
            queryUdemyCourses = false
          }

          const self = this

          function officiallyCalculate() {
            console.log('officiallyCalculate called')

            // query postings on back-end
            Axios.put('/api/courses/matches', { profile, matchingCriteria, useCases, budget, sources, queryOrgCourses, excludeOrgCourses, orgCode, queryUdemyCourses, isActive: true, resLimit: 100 })
            .then((response) => {
              console.log('Course matches attempted', response.data);

                if (response.data.success) {
                  console.log('course match query worked')

                  const matchScores = response.data.matchScores
                  let courses = response.data.courses
                  let filteredCourses = response.data.courses

                  for (let i = 1; i <= courses.length; i++) {
                    if (matchScores[i - 1]) {
                      courses[i - 1]['matchScore'] = matchScores[i - 1]
                      filteredCourses[i - 1]['matchScore'] = matchScores[i - 1]
                    }
                  }

                  self.setState({ animating: false, matchingView: true, matchScores, courses, filteredCourses, queryUdemyCourses })

                } else {
                  console.log('Course match did not work', response.data.message)
                  self.setState({ animating: false, matchingView: true, errorMessage: 'there was an error: ' + response.data.message, queryUdemyCourses })
                }

            }).catch((error) => {
                console.log('Course match did not work for some reason', error);
                self.setState({ animating: false, matchingView: true, errorMessage: 'there was an error' })
            });
          }

          if (newPreferences) {
            console.log('new preferences stated')

            if (this.state.totalPercent !== 100) {
              this.setState({ animating: false, modalIsOpen: true, matchingView: true, errorMessage: 'The sum of matching criteria weights must equal 100' })
            } else {
              const emailId = this.state.emailId
              const matchingPreferences = matchingCriteria
              const matchingUseCases = useCases
              const updatedAt = new Date()

              Axios.post('/api/users/profile/details', {
                emailId, matchingPreferences, matchingUseCases, updatedAt })
              .then((response) => {

                if (response.data.success) {
                  console.log('successfully saved new preferences')
                  officiallyCalculate()

                } else {
                  console.log('request was not successful')
                  officiallyCalculate()
                }
              }).catch((error) => {
                  console.log('Saving the info did not work', error);
                  officiallyCalculate()
              });
            }

          } else {
            officiallyCalculate()
          }
        }
      } else {
        this.setState({ matchingView: false, errorMessage: null })
      }
    }

    renderBrowseCourses(inModal) {
      console.log('renderBrowseCourses called', inModal)

      let className="width-70-percent center-horizontally max-width-1400"

      let classStyles = {}
      if (inModal || this.props.pageSource === 'landingPage' || this.props.pageSource === 'Goal') {
        className = ""
        classStyles = {}
      } else if (window.location.pathname.includes('/marketplace') || window.location.pathname.includes('/business-ideas') || window.location.pathname.includes('/business-ideas')) {
        className= "width-90-percent min-width-300 max-width-900 pin-left bottom-margin-20 left-margin-20"
        if (window.innerWidth < 768) {
          className = "bottom-margin-20"
        }
        classStyles = {}
      }

      let prefix = '/app'
      if (window.location.pathname.includes('/advisor')) {
        prefix = '/advisor'
      }

      let coursePathPrefix = prefix + "/courses/"
      let coursePathField = "id"
      if (this.props.pageSource || window.location.pathname.includes('/marketplace') || window.location.pathname.includes('/business-ideas')) {
        coursePathPrefix = "/courses/"
      }
      if ((this.state.queryOrgCourses && !this.state.queryUdemyCourses) || window.location.pathname.includes('/marketplace') || window.location.pathname.includes('/business-ideas')) {
        coursePathField = "_id"
      }

      return (
        <div key="browseCourses" className={className} style={classStyles}>
          {(window.location.pathname.includes('/marketplace') || window.location.pathname.includes('/business-ideas')) && (
            <div className="card-clear-padding">
              <div className="full-width height-5 primary-background" />

              <div className="padding-20">
                <div className="calc-column-offset-50">
                  <p className="heading-text-2">{this.state.filteredCourses.length} Courses</p>
                </div>
                <div className="fixed-column-50">
                </div>
                <div className="clear" />

              </div>
            </div>
          )}

          <div className="row-15">
            {(this.props.pageSource !== 'Goal') && (
              <div>
                {(!toggleVars.showLMSConfiguration || coursePathPrefix === "/courses/") && (
                  <div className={(this.props.pageSource === 'landingPage') && "row"}>
                    <div className={(this.props.pageSource === 'landingPage') && "full-width"}>
                      {(this.state.matchingView) ? (
                        <div>
                          <div className="search-icon-container top-margin-negative-3 full-width" style={(this.state.matchingView) ? { ...styles2, position: 'absolute' } : { }}>
                            <button type="button" className="background-button float-left" onClick={() => this.calculateMatches(false, false, false)}>
                              <img src={matchIconSelected} alt="GC" className="image-auto-30 right-margin" />
                            </button>
                          </div>
                          <div className="full-width">
                            <button type="button" className="background-button float-left" onClick={() => this.setState({ modalIsOpen: true, showMatchingCriteria: true, showBrowseCourses: false, showScheduleSetup: false, selectedIndex1: null, selectedIndex2: null })}>
                              <div className="float-left right-margin slightly-rounded-corners row-5 horizontal-padding cta-background-color cta-border white-text">
                                <p>Adjust</p>
                              </div>
                            </button>
                            <button type="button" className="background-button float-left" onClick={() => this.calculateMatches(false, false, false)}>
                              <div className="float-left right-padding standard-border slightly-rounded-corners row-5 horizontal-padding">
                                <p>Close</p>
                              </div>
                            </button>

                            <div className="clear" />

                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                          </div>
                        </div>
                      ) : (
                        <div>
                          {(this.props.pageSource === 'landingPage') ? (
                            <div>
                              {(!this.props.hideSearch) && (
                                <div className="filter-field-search full-width white-background">
                                  <div className="search-icon-container">
                                    <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5"/>
                                  </div>
                                  <div className="filter-search-container calc-column-offset-100-static">
                                    <input type="text" className="text-field clear-border" placeholder={"Search..."} name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>

                                  </div>
                                  <div className="search-icon-container top-margin-negative-3">
                                    <button type="button" className="background-button" onClick={() => this.toggleSearchBar('show')}>
                                      <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                                    </button>
                                  </div>
                                </div>
                              )}
                              <div className="clear" />

                              {(!toggleVars.showLMSConfiguration) && (
                                <div className="top-margin-15">
                                  <button className={(this.state.sources && this.state.sources.includes('Udemy')) ? "btn btn-squarish-white" : "btn btn-squarish-white right-margin"} onClick={() => this.toggleMetaFilter('Udemy')} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Filter for Udemy Courses"}>
                                    <img src={udemyLogo} alt="GC" className="image-auto-20 padding-3 float-left right-margin"/>
                                    <p className="float-left">Udemy</p>
                                    <div className="clear" />
                                  </button>
                                  {(this.state.sources && this.state.sources.includes('Udemy')) && (
                                    <div className="display-inline left-margin-negative-14 right-margin cta-background-color cta-border circle-radius top-margin-negative-5 padding-3 height-22 width-22">
                                      <img src={checkmarkIconWhite} alt="GC" className="image-14-fit"/>
                                    </div>
                                  )}
                                  <button className={(this.state.sources && this.state.sources.includes('Coursera')) ? "btn btn-squarish-white" : "btn btn-squarish-white right-margin"} onClick={() => this.toggleMetaFilter('Coursera')} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Filter for Coursera Courses"}>
                                    <img src={courseraLogo} alt="GC" className="image-auto-20 padding-3 float-left right-margin"/>
                                    <p className="float-left">Coursera</p>
                                    <div className="clear" />
                                  </button>
                                  {(this.state.sources && this.state.sources.includes('Coursera')) && (
                                    <div className="display-inline left-margin-negative-14 right-margin cta-background-color cta-border circle-radius top-margin-negative-5 padding-3 height-22 width-22">
                                      <img src={checkmarkIconWhite} alt="GC" className="image-14-fit"/>
                                    </div>
                                  )}
                                  <button className={(this.state.sources && this.state.sources.includes('Udacity')) ? "btn btn-squarish-white" : "btn btn-squarish-white right-margin"} onClick={() => this.toggleMetaFilter('Udacity')} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Filter for Udacity Courses"}>
                                    <img src={udacityLogo} alt="GC" className="image-auto-20 padding-3 float-left right-margin"/>
                                    <p className="float-left">Udacity</p>
                                    <div className="clear" />
                                  </button>
                                  {(this.state.sources && this.state.sources.includes('Udacity')) && (
                                    <div className="display-inline left-margin-negative-14 right-margin cta-background-color cta-border circle-radius top-margin-negative-5 padding-3 height-22 width-22">
                                      <img src={checkmarkIconWhite} alt="GC" className="image-14-fit"/>
                                    </div>
                                  )}
                                  <ReactTooltip id="tooltip-placeholder-id" />
                                </div>
                              )}

                            </div>
                          ) : (
                            <div>
                              <div className="full-width row-5 white-background standard-border medium-shadow">
                                {(!inModal && !window.location.pathname.includes('/organizations/')) && (
                                  <div className={(this.state.matchingView) ? "float-left full-width" : "fixed-column-50 center-item"} style={(this.state.matchingView) ? { ...styles2, position: 'absolute' } : { }}>
                                    <button type="button" className={(this.state.matchingView) ? "background-button float-left" : "background-button full-space bottom-padding"} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Calculate Matches" onClick={() => this.calculateMatches(true, true, false)}>
                                      <img src={(this.state.matchingView) ? matchIconSelected : matchIcon} alt="GC" className="image-auto-30 right-margin center-item top-margin"/>
                                    </button>
                                  </div>
                                )}

                                <div className="filter-field-search clear-margin calc-column-offset-100-static white-background clear-border">
                                  <div className="search-icon-container">
                                    <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5"/>
                                  </div>
                                  <div className="filter-search-container calc-column-offset-100-static top-margin-3">
                                    <input type="text" className="text-field clear-border" placeholder={"Search 10,000+ programs & courses..."} name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                                  </div>
                                </div>

                                {(inModal || window.location.pathname.includes('/organizations/')) && (
                                  <div className="fixed-column-50 height-30" />
                                )}

                                <div className="fixed-column-50 center-item">
                                  <button type="button" className="background-button full-space bottom-padding" onClick={() => this.toggleSearchBar('show')}>
                                    <img src={(this.state.showingSearchBar) ? filterIconSelected : filterIcon} alt="GC" className="image-auto-25 center-item top-margin" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Filter Results"/>
                                  </button>
                                </div>
                                <div className="clear" />
                              </div>

                              {(!this.props.modalIsOpen && !toggleVars.showLMSConfiguration) && (
                                <div className="top-margin-15 flex-container row-direction">
                                  {/*
                                  {(this.state.orgName) && (
                                    <button className={(this.state.sources && this.state.sources.includes('OrgCourses')) ? "btn btn-squarish-white" : "btn btn-squarish-white right-margin"} onClick={() => this.toggleMetaFilter('OrgCourses')} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Filter for " + this.state.orgName + " Recommended Courses"}>
                                      <img src={(this.state.orgLogo) ? this.state.orgLogo : industryIconDark} alt="GC" className="image-auto-20 padding-3 float-left right-margin"/>
                                      <p className="float-left">Recommended</p>
                                      <div className="clear" />
                                    </button>
                                  )}
                                  {(this.state.sources && this.state.sources.includes('OrgCourses')) && (
                                    <span className="row-direction">
                                      <button className={(this.state.sources && this.state.sources.includes('OrgCourses')) ? "btn btn-squarish-white" : "btn btn-squarish-white right-margin"} onClick={() => this.toggleMetaFilter('OrgCourses')} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Filter for " + this.state.orgName + " Recommended Courses"}>
                                        <img src={(this.state.orgLogo) ? this.state.orgLogo : industryIconDark} alt="GC" className="image-auto-20 padding-3 float-left right-margin"/>
                                        <p className="float-left">Recommended</p>
                                        <div className="clear" />
                                      </button>
                                      <div className="left-margin-negative-14 right-margin cta-background-color cta-border circle-radius top-margin-negative-5 padding-3 height-22 width-22">
                                        <img src={checkmarkIconWhite} alt="GC" className="image-14-fit"/>
                                      </div>
                                    </span>
                                  )}*/}
                                  <button className={(this.state.sources && this.state.sources.includes('OrgCourses')) ? "btn btn-squarish-white" : "btn btn-squarish-white right-margin"} onClick={() => this.toggleMetaFilter('OrgCourses')} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Filter for " + this.state.orgName + " Recommended Courses"}>
                                    <img src={(this.state.orgLogo) ? this.state.orgLogo : industryIconDark} alt="GC" className="image-auto-20 padding-3 float-left right-margin"/>
                                    <p className="float-left">Recommended</p>
                                    <div className="clear" />
                                  </button>
                                  {(this.state.sources && this.state.sources.includes('OrgCourses')) && (
                                    <div className="left-margin-negative-14 right-margin cta-background-color cta-border circle-radius top-margin-negative-5 padding-3 height-22 width-22">
                                      <img src={checkmarkIconWhite} alt="GC" className="image-14-fit"/>
                                    </div>
                                  )}

                                  <button className={(this.state.sources && this.state.sources.includes('Udemy')) ? "btn btn-squarish-white" : "btn btn-squarish-white right-margin"} onClick={() => this.toggleMetaFilter('Udemy')} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Filter for Udemy Courses"}>
                                    <img src={udemyLogo} alt="GC" className="image-auto-20 padding-3 float-left right-margin"/>
                                    <p className="float-left">Udemy</p>
                                    <div className="clear" />
                                  </button>
                                  {(this.state.sources && this.state.sources.includes('Udemy')) && (
                                    <div className="left-margin-negative-14 right-margin cta-background-color cta-border circle-radius top-margin-negative-5 padding-3 height-22 width-22">
                                      <img src={checkmarkIconWhite} alt="GC" className="image-14-fit"/>
                                    </div>
                                  )}
                                  <button className={(this.state.sources && this.state.sources.includes('Coursera')) ? "btn btn-squarish-white" : "btn btn-squarish-white right-margin"} onClick={() => this.toggleMetaFilter('Coursera')} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Filter for Coursera Courses"}>
                                    <img src={courseraLogo} alt="GC" className="image-auto-20 padding-3 float-left right-margin"/>
                                    <p className="float-left">Coursera</p>
                                    <div className="clear" />
                                  </button>
                                  {(this.state.sources && this.state.sources.includes('Coursera')) && (
                                    <div className="left-margin-negative-14 right-margin cta-background-color cta-border circle-radius top-margin-negative-5 padding-3 height-22 width-22">
                                      <img src={checkmarkIconWhite} alt="GC" className="image-14-fit"/>
                                    </div>
                                  )}
                                  <button className={(this.state.sources && this.state.sources.includes('Udacity')) ? "btn btn-squarish-white" : "btn btn-squarish-white right-margin"} onClick={() => this.toggleMetaFilter('Udacity')} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Filter for Udacity Courses"}>
                                    <img src={udacityLogo} alt="GC" className="image-auto-20 padding-3 float-left right-margin"/>
                                    <p className="float-left">Udacity</p>
                                    <div className="clear" />
                                  </button>
                                  {(this.state.sources && this.state.sources.includes('Udacity')) && (
                                    <div className="left-margin-negative-14 right-margin cta-background-color cta-border circle-radius top-margin-negative-5 padding-3 height-22 width-22">
                                      <img src={checkmarkIconWhite} alt="GC" className="image-14-fit"/>
                                    </div>
                                  )}
                                  <ReactTooltip id="tooltip-placeholder-id" />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <ReactTooltip id="tooltip-placeholder-id" />
                  </div>
                )}

                {(this.state.showingSearchBar) && (
                  <div className={"box-container-1 white-background"}>
                    <div>
                      <div className="row-10">
                        <label className="profile-label">Filter Options</label>

                        {(!this.state.queryUdemyCourses) ? (
                          <div>
                            <SubCustomDropdown dropdownTitle="Category" name="filter|category" value={this.state.categoryValue} options={this.state.categoryOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                            <SubCustomDropdown dropdownTitle="Degree Type" name="filter|degreeType" value={this.state.degreeTypeValue} options={this.state.degreeTypeOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                            <SubCustomDropdown dropdownTitle="Pathway" name="filter|pathway" value={this.state.orgPathwayValue} options={this.state.orgPathwayOptions} optionSubKey={"title"} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                            <SubCustomDropdown dropdownTitle="Work Function" name="filter|function" value={this.state.functionValue} options={this.state.functionOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                            <SubCustomDropdown dropdownTitle="Industry" name="filter|industry" value={this.state.industryValue} options={this.state.industryOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                            <SubCustomDropdown dropdownTitle="Teaching Method" name="filter|programMethod" value={this.state.programMethodValue} options={this.state.programMethodOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                            <SubCustomDropdown dropdownTitle="Price" name="filter|price" value={this.state.priceValue} options={this.state.orgPriceOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                            <SubCustomDropdown dropdownTitle="Duration" name="filter|duration" value={this.state.durationValue} options={this.state.orgDurationOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                            <SubCustomDropdown dropdownTitle="Difficulty Level" name="filter|difficultyLevel" value={this.state.difficultyLevelValue} options={this.state.difficultyLevelOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                          </div>
                        ) : (
                          <div>
                            <SubCustomDropdown dropdownTitle="Price" name="filter|price" value={this.state.priceValue} options={this.state.priceOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                            <SubCustomDropdown dropdownTitle="Duration" name="filter|duration" value={this.state.durationValue} options={this.state.durationOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                            <SubCustomDropdown dropdownTitle="Difficulty Level" name="filter|difficultyLevel" value={this.state.difficultyLevelValue} options={this.state.difficultyLevelOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                          </div>
                        )}
                        <div className="clear" />
                      </div>

                    </div>
                  </div>
                )}

                <div className="spacer" /><div className="spacer" />
              </div>
            )}

            {(this.state.animating) ? (
              <div className="flex-container flex-center full-space">
                <div>
                  <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <p className="center-text cta-color bold-text">Pulling results...</p>

                </div>
              </div>
            ) : (
              <div className={(this.props.pageSource === 'landingPage') ? "card row" : "card"}>
                {(this.state.filteredCourses && this.state.filteredCourses.length > 0) ? (
                  <div>
                    {this.state.filteredCourses && this.state.filteredCourses.map((value, index) =>
                      <div>
                        <div key={index}>
                          <div className="spacer" />

                          {(this.props.modalIsOpen && this.props.showBrowseCourses) ? (
                            <a href={(this.props.source === 'Udemy') ? 'https://www.udemy.com' + value.url : value.programURL} className={(this.props.pageSource === 'landingPage') ? "background-button calc-column-offset-25" : "background-button calc-column-offset-65"} target="_blank">
                              {(this.renderCourseComponent(value,index))}
                            </a>
                          ) : (
                            <Link to={(value.id) ? coursePathPrefix + value['id'] : (toggleVars.showLMSConfiguration && coursePathPrefix !== "/courses/") ? coursePathPrefix + value['_id'] + '/take' : coursePathPrefix + value['_id']} state={{ selectedCourse: value }} className={(this.props.pageSource === 'landingPage') ? (this.props.disablePointers) ? "background-button calc-column-offset-25 no-pointers standard-color" : "background-button calc-column-offset-25" : "background-button calc-column-offset-65"}>
                              {(this.renderCourseComponent(value,index))}
                            </Link>
                          )}

                          {(this.props.pageSource !== 'landingPage') && (
                            <span>
                              {(inModal) ? (
                                <span className="fixed-column-65 top-margin-5 center-text" >
                                  <button className="btn btn-squarish description-text-2" onClick={() => this.addCourse(value)}>
                                    <p>Add</p>
                                  </button>
                                </span>
                              ) : (
                                <div>
                                  {(this.state.emailId) && (
                                    <span className="fixed-column-40 top-margin-5 center-text" >
                                      {(!window.location.pathname.includes('/advisor')) && (
                                        <div>
                                          {(window.location.pathname.includes('/organizations/')) ? (
                                            <button className="background-button clear-padding clear-margin center-item padding-5" onClick={() => this.setState({ modalIsOpen: true, startRecommendation: true, selectedCourse: value })}>
                                              <img src={(this.state.recommendations.includes(value._id) || this.state.recommendedCourseDetails.some(selectedCourse => selectedCourse.url === value.url)) ? shareIconBlue : shareIconDark} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Recommend Course"/>
                                            </button>
                                          ) : (
                                            <div>
                                              {(value.learningModules && value.learningModules.length > 0) ? (
                                                <div>
                                                  {(this.state.enrollments && this.state.enrollments.some(e => e.courseId === value._id) && this.state.enrollments[this.state.enrollments.findIndex(e => e.courseId === value._id)].isCompleted) ? (
                                                    <div className="cta-border circle-radius width-30 height-30 flex-container flex-center top-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Course is complete">
                                                      <img src={checkmarkIcon} alt="GC" className="image-auto-15 center-item"/>
                                                    </div>
                                                  ) : (
                                                    <div className="width-30 height-30" />
                                                  )}
                                                </div>
                                              ) : (
                                                <div>
                                                  {((value.source === 'Udemy' && !value.orgCode ) || this.state.hideEnrollmentButton) ? (
                                                    <button className="background-button clear-padding clear-margin center-item padding-5" onClick={() => this.markCompleted(value, 'course')}>
                                                      <img src={(this.state.completions.includes(value._id) || this.state.completedCourseDetails.some(selectedCourse => selectedCourse.url && value.url && selectedCourse.url === value.url)) ? checkboxChecked : checkmarkDarkGreyIcon} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Mark as Completed"/>
                                                    </button>
                                                  ) : (
                                                    <button disabled={this.state.isSaving} className="btn background-button" onClick={(e) => this.enrollInCourse(value,'course',e,true) }>
                                                      <img src={(this.state.enrollments && this.state.enrollments.some(enrollment => enrollment.courseId === value._id && enrollment.isCompleted)) ? checkboxChecked : checkmarkDarkGreyIcon} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Mark as Completed"/>
                                                    </button>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      {(!this.state.hideEnrollmentButton) && (
                                        <div>
                                          {((value.source === 'Udemy' && !value.orgCode) || (window.location.pathname.includes('/advisor'))) ? (
                                            <button disabled={this.state.isSaving} className={(window.location.pathname.includes('/advisor')) ? "btn background-button top-margin" : "btn background-button"} onClick={(e) => this.favoriteItem(value,'course',e) }>
                                              <img src={(this.state.favorites.includes(value._id) || this.state.favoritedCourseDetails.some(selectedCourse => selectedCourse.url && value.url && selectedCourse.url === value.url)) ? favoritesIconBlue : favoritesIconDark} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={(window.location.pathname.includes('/advisor')) ? "Favorite" : "Mark as Enrolled"}/>
                                            </button>
                                          ) : (
                                            <button disabled={this.state.isSaving} className="btn background-button" onClick={() => this.enrollInCourse(value,'course') }>
                                              <img src={(this.state.enrollments && this.state.enrollments.some(enrollment => enrollment.courseId === value._id)) ? addLessonIconBlue : addLessonIconDark} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Mark as Enrolled"/>
                                            </button>
                                          )}
                                        </div>
                                      )}
                                      <ReactTooltip id="tooltip-placeholder-id" />
                                    </span>
                                  )}

                                  <div className="fixed-column-25 left-padding" >
                                    <span>
                                      <div className="spacer"/><div className="half-spacer"/>
                                      {(this.props.modalIsOpen && this.props.showBrowseCourses) ? (
                                        <a href={'https://www.udemy.com' + value.url} className="background-button full-width" target="_blank">
                                          <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                                        </a>
                                      ) : (
                                        <Link to={(value.id) ? coursePathPrefix + value['id'] + "/take" : coursePathPrefix + value['_id'] + "/take"} state={{ selectedCourse: value }} className="background-button full-width">
                                          <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                                        </Link>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </span>
                          )}

                          <div className="clear"/>

                          {(this.state.sortCriteriaArray && this.state.sortCriteriaArray[index] && this.state.sortCriteriaArray[index].name) && (
                            <div className="left-padding-70">
                              <div className="half-spacer" />
                              <p className="description-text-2 error-color row-5">{this.state.sortCriteriaArray[index].name}: {this.standardizeValue('sort',index, this.state.sortCriteriaArray[index].criteria)}</p>
                            </div>
                          )}
                          {(this.state.filterCriteriaArray && this.state.filterCriteriaArray[index] && this.state.filterCriteriaArray[index].name) && (
                            <div className="left-padding-70">
                              <div className="half-spacer" />
                              <p className="description-text-2 error-color row-5">{this.state.filterCriteriaArray[index].name}: {this.state.filterCriteriaArray[index].criteria}</p>
                            </div>
                          )}
                          <div className="spacer" /><div className="spacer" />
                          <hr className="clear-margin clear-padding" />
                          <div className="clear"/>
                          <div className="spacer" />
                        </div>

                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="full-width flex-container flex-center padding-40 wash-out-2 top-margin-30">
                      <div>
                        <img src={courseIconDark} alt="GC" className="image-auto-80 center-horizontally" />
                        <p className="heading-text-5 center-text top-margin-30">No courses have been made available to you in this community</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

          </div>
        </div>
      )
    }

    renderCourseComponent(value,index) {
      console.log('renderCourseComponent called')

      let sourceImage = null
      if (value.source === 'Udemy' || value.id) {
        sourceImage = udemyLogo
      } else if (value.source === 'Coursera') {
        sourceImage = courseraLogo
      } else if (value.source === 'Udacity') {
        sourceImage = udacityLogo
      }

      const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primaryColor');

      return (
        <div key="renderCourseComponent">
          <div className="fixed-column-70">
            {(value.matchScore) ? (
              <div className="padding-10">
                <CircularProgressbar
                  value={value.matchScore}
                  text={`${value.matchScore}%`}
                  styles={{
                    path: { stroke: `${primaryColor}, ${value.matchScore / 100}` },
                    text: { fill: primaryColor, fontSize: '26px' },
                    trail: { stroke: 'transparent' }
                  }}
                />
              </div>
            ) : (
              <img src={(value.imageURL) ? value.imageURL : (value.image_125_H) ? value.image_125_H : courseIconDark} alt="GC" className="image-60-auto"/>
            )}

            {(sourceImage) && (
              <div className="top-margin">
                <img src={sourceImage} alt="GC" className="image-60-fit"/>
              </div>
            )}
          </div>
          <div className="calc-column-offset-70">
            <p className="heading-text-5">{(value.name) ? value.name : value.title}</p>
            {(value.schoolName) && (
              <p className="description-text-3 bottom-margin top-margin-5 standard-color">by {value.schoolName}</p>
            )}

            <p className="description-text-1 description-text-color bottom-margin-15">{(value.description) ? value.description : value.headline}</p>

            {(!this.props.disablePointers && this.props.pageSource === 'landingPage') && (
              <div>
                {(value.category) && (
                  <div className="description-text-3">
                    <div className="float-left right-margin">
                      <img src={courseIconBlue} alt="GC" className="image-auto-15 center-item"/>
                    </div>
                    <div className="float-left right-margin">
                      <label className="standard-color">{value.category}</label>
                    </div>
                  </div>
                )}

                {(value.duration) && (
                  <div className="description-text-3">
                    <div className="float-left right-margin">
                      <img src={timeIconBlue} alt="GC" className="image-auto-15 center-item"/>
                    </div>
                    <div className="float-left right-margin">
                      <label className="standard-color">{value.duration}</label>
                    </div>
                  </div>
                )}

                {(value.estimatedHours) && (
                  <div className="description-text-3">
                    <div className="float-left right-margin">
                      <img src={timeIconBlue} alt="GC" className="image-auto-15 center-item"/>
                    </div>
                    <div className="float-left right-margin">
                      <label className="standard-color">{value.estimatedHours} Hours</label>
                    </div>
                  </div>
                )}

                {(value.price) && (
                  <div className="description-text-3">
                    <div className="float-left right-margin">
                      <img src={moneyIconBlue} alt="GC" className="image-auto-15 center-item"/>
                    </div>
                    <div className="float-left right-margin">
                      <label className="standard-color">{(value.price === '0') ? "FREE" : (value.price.startsWith('$')) ? value.price : '$' + value.price}</label>
                    </div>
                  </div>
                )}

                {(value.degreeType) && (
                  <div className="description-text-3">
                    <div className="float-left right-margin">
                      <img src={ratingsIconBlue} alt="GC" className="image-auto-15 center-item"/>
                    </div>
                    <div className="float-left right-margin">
                      <label className="standard-color">{value.degreeType}</label>
                    </div>
                  </div>
                )}

                {(value.programMethod) && (
                  <div className="description-text-3">
                    <div className="float-left right-margin">
                      <img src={profileIconBlue} alt="GC" className="image-auto-15 center-item"/>
                    </div>
                    <div className="float-left right-margin">
                      <label className="standard-color">{value.programMethod}</label>
                    </div>
                  </div>
                )}

                {(value.difficultyLevel) && (
                  <div className="description-text-3">
                    <div className="float-left right-margin">
                      <img src={difficultyIconBlue} alt="GC" className="image-auto-15 center-item"/>
                    </div>
                    <div className="float-left right-margin">
                      <label className="standard-color">{value.difficultyLevel ? value.difficultyLevel : "Beginner"}</label>
                    </div>
                  </div>
                )}

                {(value.gradeLevel) && (
                  <div className="description-text-3">
                    <div className="float-left right-margin">
                      <img src={profileIconBlue} alt="GC" className="image-auto-15 center-item"/>
                    </div>
                    <div className="float-left right-margin">
                      <label className="standard-color">{value.gradeLevel}</label>
                    </div>
                  </div>
                )}

                {(value.rating && value.ratingCount) && (
                  <div className="description-text-3">
                    <div className="float-left right-margin">
                      <img src={ratingsIconBlue} alt="GC" className="image-auto-15 center-item"/>
                    </div>
                    <div className="float-left right-margin">
                      <label className="standard-color">{value.rating} / 5.0 - {value.ratingCount} Ratings</label>
                    </div>
                  </div>
                )}

                {(value.studentCount) && (
                  <div className="description-text-3">
                    <div className="float-left right-margin">
                      <img src={profileIconBlue} alt="GC" className="image-auto-15 center-item"/>
                    </div>
                    <div className="float-left right-margin">
                      <label className="standard-color">{value.studentCount} Students</label>
                    </div>
                  </div>
                )}

                <div className="clear" />
                <div className="half-spacer" />
              </div>
            )}
          </div>
        </div>
      )
    }

    addCourse(course) {
      console.log('addCourse called', course)

      if (this.props.modalIsOpen && this.props.showBrowseCourses) {
        console.log('pass the selected course')

        this.closeModal()
        this.props.importCourse(course,this.props.source)

      } else {
        let schedule = this.state.schedule
        let courses = schedule[0].courses
        if (courses) {
          courses.unshift(course)
        } else {
          courses = [course]
        }

        schedule[0]['courses'] = courses

        let modalIsOpen = false

        this.setState({ schedule, modalIsOpen })
      }
    }

    renderScheduleSetup(type) {
      console.log('renderScheduleSetup called', type)

      return (
        <div key="subScheduleSetup">
          {(type === 'schedule') && (
            <div className="row-10">
              <p className="heading-text-2">Create a Course Schedule</p>
              <p className="ull-width">We'll recommend a suite of skill-based courses based on the careers and opportunities you're targeting.</p>
            </div>
          )}

          <div className="row-40">
            <p className="heading-text-4">1. Select a Goal</p>

            <div>
              <div className="container-left row-10">
                <label className="profile-label">Select a Goal Type</label>
                <select name="goalType" value={this.state.goalType.description} onChange={this.formChangeHandler} className="dropdown">
                  {this.state.goalTypeOptions.map(value =>
                    <option key={value.description} value={value.description}>{value.description}</option>
                  )}
                </select>
                <div className="clear" />
              </div>
            </div>

            <div className="clear" />

            {(this.state.goalType.name === 'Career') && (
              <div>
                <div className="container-left row-10">
                  <label className="profile-label">Select a Career Path</label>
                  <div className="calc-column-offset-70">
                    <input type="text" className="text-field" placeholder="Search 1,000+ career paths..." name="searchCareers" value={this.state.searchString} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="fixed-column-70 left-padding">
                    <button className={(this.state.unready) ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={this.state.unready} onClick={() => this.addItem('career')}>Add</button>
                  </div>
                  <div className="clear" />
                </div>
                <div className="clear" />

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

                {(this.state.searchIsAnimating) ? (
                  <div className="flex-container flex-center full-space">
                    <div>
                      <div className="super-spacer" />

                      <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <p className="center-text cta-color bold-text">Searching...</p>

                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      {(this.state.careerOptions) && (
                        <div className="card top-margin">
                          {this.state.careerOptions.map((value, optionIndex) =>
                            <div key={value._id} className="left-text bottom-margin-5 full-width">
                              <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value, 'career')}>
                                <div className="full-width">
                                  <div className="fixed-column-40">
                                    <div className="mini-spacer" />
                                    <img src={experienceIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                  </div>
                                  <div className="calc-column-offset-40">
                                    <p className="cta-color">{value.name}</p>
                                  </div>
                                </div>
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div>

                      {this.renderTags('career')}
                      <div className="clear" />

                    </div>

                  </div>
                )}
              </div>
            )}
            {(this.state.goalType.name === 'Opportunity') && (
              <div>
                <div className="container-left row-10">
                  <label className="profile-label">Select an Opportunity</label>
                  <div className="calc-column-offset-70">
                    <input type="text" className="text-field" placeholder="Search work opportunities..." name="searchOpportunities" value={this.state.searchStringOpportunities} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="fixed-column-70 left-padding">
                    <button className={(this.state.unready) ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={this.state.unready} onClick={() => this.addItem('opportunity')}>Add</button>
                  </div>
                  <div className="clear" />
                </div>
                <div className="clear" />

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

                {(this.state.searchIsAnimating) ? (
                  <div className="flex-container flex-center full-space">
                    <div>
                      <div className="super-spacer" />

                      <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <p className="center-text cta-color bold-text">Searching...</p>

                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      {(this.state.opportunityOptions) && (
                        <div className="card top-margin">
                          {this.state.opportunityOptions.map((value, optionIndex) =>
                            <div key={value._id} className="left-text bottom-margin-5 full-width">
                              <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value, 'opportunity')}>
                                <div className="full-width">
                                  <div className="fixed-column-40">
                                    <div className="mini-spacer" />
                                    <img src={experienceIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                  </div>
                                  <div className="calc-column-offset-40">
                                    <p className="cta-color">{(value.title) ? value.title : value.name}{value.employerName && " | " + value.employerName}</p>
                                  </div>
                                </div>
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div>

                      {this.renderTags('opportunity')}
                      <div className="clear" />

                    </div>

                  </div>
                )}
              </div>
            )}
            {(this.state.goalType.name === 'Entrepreneurship') && (
              <div>
                <div className="container-left row-10">
                  <label className="profile-label">What area do you need help with?</label>
                  <select name="entrepreneurshipGoal" value={this.state.entrepreneurshipGoal} onChange={this.formChangeHandler} className="dropdown">
                    {this.state.entrepreneurshipGoalOptions.map(value =>
                      <option key={value} value={value}>{value}</option>
                    )}
                  </select>
                  <div className="clear" />
                </div>

                <div className="container-right row-10">
                  <label className="profile-label">Which industry is your business in?</label>
                  <select name="entrepreneurshipIndustry" value={this.state.entrepreneurshipIndustry} onChange={this.formChangeHandler} className="dropdown">
                    {this.state.industryOptions.map(value =>
                      <option key={value} value={value}>{value}</option>
                    )}
                  </select>
                </div>
                <div className="clear" />
              </div>
            )}

            {(type === 'schedule' && this.state.goalType.name && this.state.goalType !== '') && (
              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Target Achievement Date</label>
                  <div>
                    <input type="date" className="date-picker white-background" name="targetDate" value={this.state.targetDate} min={this.state.minTargetDate} max={this.state.maxTargetDate} onChange={this.formChangeHandler}></input>
                  </div>
                  {/*
                  <div className="fixed-column-70 left-padding">
                    <button className={(this.state.unready) ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={this.state.unready} onClick={() => this.addItem('goal')}>Add</button>
                  </div>*/}
                  <div className="clear" />
                </div>
                <div className="container-right">
                  <label className="profile-label">Intensity</label>
                  <select name="intensity" value={this.state.intensity} onChange={this.formChangeHandler} className="dropdown">
                    {this.state.intensityOptions.map(value =>
                      <option key={value} value={value}>{value}</option>
                    )}
                  </select>
                  {/*
                  <div className="fixed-column-70 left-padding">
                    <button className={(this.state.unready) ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={this.state.unready} onClick={() => this.addItem('goal')}>Add</button>
                  </div>*/}
                  <div className="clear" />
                </div>
                <div className="clear" />
              </div>
            )}

            <div className="clear" />

          </div>

          <hr />

          <div className="row-40">
            <p className="heading-text-4">2. Modify Learning Objectives</p>
            <p className="description-text-2">Based on your profile, goals, and skill gaps, we recommend you acquire the following skills and knowledge:</p>

            <div>
              {this.renderTags('learningObjective')}
              <div className="clear" />
            </div>
          </div>

          <hr />

          <div className="top-padding-40">
            <p className="heading-text-4">3. Specify your Budget</p>

            <div className="row-10">
              <div className="float-left standard-border light-background width-22 center-text height-40">
                <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                <p className="heading-text-4 cta-color bold-text full-width">$</p>
              </div>
              <div className="width-85 float-left">
                <input type="number" className="number-field cta-color float-left" placeholder="0" name="budget" value={this.state.budget} onChange={this.formChangeHandler}></input>
              </div>

              <div className="clear" />
              <div className="clear" />
            </div>
          </div>

          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

          {(type === 'schedule') ? (
            <div className="row-30">
              <button className="btn btn-primary" onClick={() => this.craftCourseSchedule()}>Get Recommended Course Schedule</button>
            </div>
          ) : (
            <div className="row-30">
              <button className="btn btn-primary" onClick={() => this.getCourseMatches()}>Get Recommended Courses</button>
            </div>
          )}

        </div>
      )
    }

    calcScheduleDate(type, index) {
      console.log('calcScheduleDate called')

      if (type === 'startDate') {
        const startingDate = new Date(new Date().getTime() + ((index * 7) + 1) * 24 * 60 * 60 * 1000);
        const futureYear = startingDate.getFullYear().toString()
        let futureMonth = (startingDate.getMonth() + 1)
        if (futureMonth < 10) {
          futureMonth = '0' + futureMonth.toString()
        } else {
          futureMonth = futureMonth.toString()
        }
        let futureDay = startingDate.getDate().toString()
        if (futureDay < 10) {
          futureDay = '0' + futureDay.toString()
        } else {
          futureDay = futureDay.toString()
        }
        const startingDateString = futureMonth + '/' + futureDay + '/' + futureYear

        return startingDateString
      } else if (type === 'endDate') {
        const endingDate = new Date(new Date().getTime() + ((index + 1) * 7) * 24 * 60 * 60 * 1000);
        const futureYear = endingDate.getFullYear().toString()
        let futureMonth = (endingDate.getMonth() + 1)
        if (futureMonth < 10) {
          futureMonth = '0' + futureMonth.toString()
        } else {
          futureMonth = futureMonth.toString()
        }
        let futureDay = endingDate.getDate().toString()
        if (futureDay < 10) {
          futureDay = '0' + futureDay.toString()
        } else {
          futureDay = futureDay.toString()
        }
        const endingDateString = futureMonth + '/' + futureDay + '/' + futureYear

        return endingDateString
      }
    }

    markCompleted(item, type) {
      console.log('markCompleted called', item, type)

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      if (type) {
        let itemId = item._id

        let completions = this.state.completions

        if (completions.includes(itemId) || this.state.completedCourseDetails.some(selectedCourse => selectedCourse.url && item.url && selectedCourse.url === item.url)){

          let index = completions.indexOf(itemId)
          let courseIndex = 0
          let completedCourseDetails = this.state.completedCourseDetails
          if (this.state.completedCourseDetails && this.state.completedCourseDetails.some(selectedCourse => selectedCourse.url === item.url)) {
            courseIndex = completedCourseDetails.findIndex(x => x.url === item.url);

            if (courseIndex > -1) {
              index = completions.indexOf(completedCourseDetails[courseIndex].completionId)
              completedCourseDetails.splice(courseIndex, 1);
            }

          }
          // console.log('item to remove 1: ', completions, favoritesArray.length, favoritedCourseDetails.length)
          if (index > -1) {
            completions.splice(index, 1);
          }
          // console.log('item to remove 2: ', favoritesArray, favoritesArray.length, favoritedCourseDetails.length)

          Axios.post('/api/completions/save', {
            completions, emailId: this.state.emailId
          })
          .then((response) => {
            console.log('attempting to save removal from completions')
            if (response.data.success) {
              console.log('saved removal from completions', response.data)

              this.setState({ successMessage: 'Removed from saved completions', completions, completedCourseDetails, isSaving: false })

            } else {
              console.log('did not save successfully')
              this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false })
          });

        } else {

          // console.log('adding item: ', favoritesArray, itemId)

          if (type === 'course' && item.url) {
            // first save the course
            const courseObject = {
              source: 'Udemy', title: item.title, headline: item.headline, udemyId: item.id, url: item.url,
              image_125_H: item.image_125_H, image_240x135: item.image_240x135, image_480x270: item.image_480x270,
              is_paid: item.is_paid, price: item.price, visible_instructors: item.visible_instructors
            }

            Axios.post('/api/courses', courseObject)
            .then((response) => {
              console.log('attempting to save course')
              if (response.data.success) {
                console.log('saved course as new', response.data)
                //clear values

                itemId = response.data._id

                completions.push(itemId)
                let completedCourseDetails = this.state.completedCourseDetails
                completedCourseDetails.push(item)

                Axios.post('/api/completions/save', {
                  completions, emailId: this.state.emailId
                })
                .then((response) => {
                  console.log('attempting to save addition to completions')
                  if (response.data.success) {
                    console.log('saved addition to completions', response.data)

                    this.setState({
                      successMessage: 'Saved as a completion!', completions, completedCourseDetails, isSaving: false
                    })

                  } else {
                    console.log('did not save successfully')
                    this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
                  }
                }).catch((error) => {
                    console.log('save did not work', error);
                    this.setState({ errorMessage: 'there was an error saving completions', isSaving: false})
                });
              } else {
                console.log('did not save successfully')

              }
            }).catch((error) => {
                console.log('save did not work', error);
                this.setState({
                  serverSuccessPlan: false,
                  serverErrorMessagePlan: 'there was an error saving completions', isSaving: false
                })
            });
          } else {
            completions.push(itemId)
            Axios.post('/api/completions/save', {
              completions, emailId: this.state.emailId
            })
            .then((response) => {
              console.log('attempting to save addition to completions')
              if (response.data.success) {
                console.log('saved addition to completions', response.data)

                this.setState({ successMessage: 'Saved as a completion!', completions, isSaving: false })

              } else {
                console.log('did not save successfully')
                this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
              }
            }).catch((error) => {
                console.log('save did not work', error);
                this.setState({ errorMessage: 'there was an error saving completions', isSaving: false})
            });
          }
        }
      }
    }

    recommendCourse() {
      console.log('recommendCourse called')

    }

    renderItems(type) {
      console.log('renderItems called')

      if (type === 'lesson') {
        if (this.state.lessons && this.state.lessons.length > 0) {

          let hideColumns = true
          for (let i = 1; i <= this.state.lessons.length; i++) {
            if (this.state.lessons[i - 1].customAssessmentId) {
              hideColumns = false
            }
          }

          return (
            <div key={'curriculum items'}>
              <div className="card-clear-padding description-text-1">
                <div className="light-background standard-border full-width padding-20 bold-text">
                  <div className="fixed-column-40">
                    <p>#</p>
                  </div>
                  <div className={(window.innerWidth > 768) ? (hideColumns) ? "full-width" : "calc-column-offset-300" : (hideColumns) ? "full-width" : "calc-column-offset-240-static"}>
                    <p>Name</p>
                  </div>
                  {(!hideColumns) && (
                    <div>
                      <div className={(window.innerWidth > 768) ? "fixed-column-100" : "fixed-column-90"}>
                        <p>Deadline</p>
                      </div>
                      <div className={(window.innerWidth > 768) ? "fixed-column-100" : "fixed-column-50"}>
                        <p>Status</p>
                      </div>
                      <div className="fixed-column-60">
                        <p>Survey</p>
                      </div>
                    </div>
                  )}

                  <div className="clear" />
                </div>
                {this.state.lessons.map((item, optionIndex) =>
                  <div key={optionIndex +  'curriculum item'}>
                    <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showLessonDetails: true, selectedIndex: optionIndex })}>
                      <div className="standard-border">
                        <div className={(this.state.submissions.some(s => s.lessonId === item._id)) ? "horizontal-padding-5 row-20 thick-border-left-cta" : "horizontal-padding-5 row-20 thick-border-left-error"}>
                          <div className="fixed-column-40">
                            <p>{optionIndex + 1}.</p>
                          </div>
                          <div className={(window.innerWidth > 768) ? (hideColumns) ? "calc-column-offset-40" : "calc-column-offset-300" : (hideColumns) ? "calc-column-offset-40" : "calc-column-offset-240-static"}>
                            <p>{item.itemName}</p>
                          </div>
                          {(!hideColumns) && (
                            <div>
                              <div className={(window.innerWidth > 768) ? "fixed-column-100" : "fixed-column-90"}>
                                {(item.submissionDeadline) ? (
                                  <p>{convertDateToString(new Date(item.submissionDeadline),'date-2')}</p>
                                ) : (
                                  <p>N/A</p>
                                )}
                              </div>
                              <div className={(window.innerWidth > 768) ? "fixed-column-100" : "fixed-column-50"}>
                                {(window.location.pathname.includes('/advisor')) ? (
                                  <div>
                                    {(item.mentorQuestions && item.mentorQuestions.length > 0) ? (
                                      <div>
                                        {(window.innerWidth > 768) ? (
                                          <div>
                                            {(this.state.submissions.some(s => s.lessonId === item._id)) ? <p className="cta-color">Complete</p> : <p className="error-color">Incomplete</p>}
                                          </div>
                                        ) : (
                                          <div>
                                            {(this.state.submissions.some(s => s.lessonId === item._id)) ? <img src={checkmarkIcon} alt="GC" className="image-auto-20" /> : <img src={xIcon} alt="GC" className="image-auto-20" />}
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div>
                                        <p>N/A</p>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    {(item.assignmentQuestions && item.assignmentQuestions.length > 0) ? (
                                      <div>
                                        {(window.innerWidth > 768) ? (
                                          <div>
                                            {(this.state.submissions.some(s => s.lessonId === item._id)) ? <p className="cta-color">Complete</p> : <p className="error-color">Incomplete</p>}
                                          </div>
                                        ) : (
                                          <div>
                                            {(this.state.submissions.some(s => s.lessonId === item._id)) ? <img src={checkmarkIcon} alt="GC" className="image-auto-20" /> : <img src={xIcon} alt="GC" className="image-auto-20" />}
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div>
                                        <p>N/A</p>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                              <div className="fixed-column-60">
                                {(window.location.pathname.includes('/advisor')) ? (
                                  <div>
                                    {(item.mentorQuestions && item.mentorQuestions.length > 0) ? (
                                      <div>
                                        <button className="btn btn-small cta-background-color full-width top-margin-negative-10" onClick={(e) => this.buttonClicked(item,optionIndex,e)}>Edit</button>
                                      </div>
                                    ) : (
                                      <div>
                                        <p>N/A</p>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    {(item.assignmentQuestions && item.assignmentQuestions.length > 0) ? (
                                      <div>
                                        <button className="btn btn-small cta-background-color full-width top-margin-negative-10" onClick={(e) => this.buttonClicked(item,optionIndex,e)}>Edit</button>
                                      </div>
                                    ) : (
                                      <div>
                                        <p>N/A</p>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="clear" />
                        </div>
                      </div>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )
        }
      } else if (type === 'submission') {
        if (this.state.submissions && this.state.submissions.length > 0) {

          let disableEdit = false
          if (this.state.subNavSelected === 'Scorecards' && this.state.disableEdit) {
            disableEdit = true
          }

          return (
            <div key={'submissions'}>
              <div className="card-clear-padding description-text-1">
                <div className="light-background standard-border full-width padding-20 bold-text">
                  <div className="fixed-column-40">
                    <p>#</p>
                  </div>
                  <div className={(window.innerWidth > 768) ? "calc-column-offset-160" : "calc-column-offset-130"}>
                    <div className={(window.innerWidth > 768) ? "relative-column-50" : "relative-column-50"}>
                      <p>Name</p>
                    </div>
                    <div className={(window.innerWidth > 768) ? "relative-column-50" : "relative-column-50"}>
                      <p>Lesson Name</p>
                    </div>
                  </div>
                  <div className={(window.innerWidth > 768) ? "fixed-column-100" : "fixed-column-70"}>
                    <p>Timestamp</p>
                  </div>
                  <div className="clear" />
                </div>
                {this.state.submissions.map((item, optionIndex) =>
                  <div key={optionIndex +  'curriculum item'}>
                    <button className={(disableEdit) ? "background-button full-width left-text no-pointers" : "background-button full-width left-text"} disabled={(disableEdit) ? true : false} onClick={() => this.scorecardClicked(item, optionIndex)}>
                      <div className="standard-border">
                        <div className="horizontal-padding-5 row-20 thick-border-left-cta">
                          <div className="fixed-column-40">
                            <p>{optionIndex + 1}.</p>
                          </div>
                          <div className={(window.innerWidth > 768) ? "calc-column-offset-160" : "calc-column-offset-130"}>
                            <div className={(window.innerWidth > 768) ? "relative-column-50" : "relative-column-50"}>
                              <p>{item.assessmentName}</p>
                            </div>
                            <div className={(window.innerWidth > 768) ? "relative-column-50" : "relative-column-50"}>
                              <p>{item.lessonName}</p>
                            </div>
                          </div>
                          <div className={(window.innerWidth > 768) ? "fixed-column-100" : "fixed-column-70"}>
                            {(item.createdAt) ? (
                              <p>{convertDateToString(new Date(item.createdAt),'date-2')}</p>
                            ) : (
                              <p>N/A</p>
                            )}
                          </div>
                          <div className="clear" />
                        </div>
                      </div>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )
        }
      }
    }

    renderItem(item, optionIndex, inModal) {
      console.log('renderItem called', item)

      return (
        <div key={optionIndex + ' sub items'} className="padding-20">
          <div className="row-10">
            <div className="calc-column-offset-30">
              <p className="heading-text-2">{optionIndex + 1}. {item.itemName}</p>
            </div>
            <div className="fixed-column-30 top-margin">
              <button className="background-button" onClick={() => this.closeModal()}>
                <img src={deniedIcon} alt="GC" className="image-auto-15" />
              </button>
            </div>
            <div className="clear" />
          </div>

          <div className="description-text-1">
            {(item.submissionDeadline) && (
              <div className="calc-column-offset-90">
                <p>{(item.submissionDeadline) && "Due " + convertDateToString(new Date(item.submissionDeadline),'date-2') + " | "}{(this.state.submissions.some(s => s.lessonId === item._id)) ? <label className="cta-color">Complete</label> : <label className="error-color">Incomplete</label>}</p>
              </div>
            )}

            <div className="fixed-column-90">
              <div className="float-left">
                <div className="mini-spacer" />
                <img className="image-auto-15" alt="GC" src={timeIconDark} />
              </div>
              <div className="float-left left-padding">
                <p className="description-text-1">{item.minutes} Mins</p>
              </div>
              <div className="clear" />
            </div>
            <div className="clear" />
          </div>

          <div className="top-margin-20 description-text-2">
            {(item.studentURL) && (
              <div className="float-left right-padding-2 white-background">
                <div className={(item.url) ? "float-left right-padding-5 top-margin-3" : "float-left right-padding-5 top-margin-3"}>
                  <img className="image-auto-16" alt="GC" src={linkIconBlue} />
                </div>
                <div className="float-left left-padding-5 heading-text-6">
                  <a href={item.studentURL} target="_blank">Link to Details</a>
                </div>
                <div className="clear" />
              </div>
            )}

            {(window.location.pathname.includes('/advisor')) ? (
              <div>
                {(item.mentorQuestions && item.mentorQuestions.length > 0) && (
                  <div className="float-left">
                    {(this.state.submissions.some(s => s.lessonId === item._id)) ? (
                      <div>
                        {((item.canChangeAnswers && item.type === 'Quiz') || (item.type !== 'Quiz')) ? (
                          <div className="float-left">
                            <div className={(item.url) ? "float-left right-padding-5 left-padding-20 top-margin-3" : "float-left right-padding-5 top-margin-3"}>
                              <img className="image-auto-17" alt="GC" src={checkmarkIcon} />
                            </div>
                            <div className="float-left left-padding-5 heading-text-6">
                              <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, takeQuiz: true, selectedIndex: optionIndex, showLessonDetails: false })}>Edit Survey</button>
                            </div>
                            <div className="clear" />
                          </div>
                        ) : (
                          <div className="float-left">
                            <div className="fixed-column-30">
                              <img src={checkmarkIcon} alt="GC" className="image-18-fit" />
                            </div>
                            <div className="calc-column-offset-30 top-padding-3">
                              <p className="heading-text-6 cta-color">Submitted</p>
                            </div>
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="float-left">
                        <div className="float-left">
                          <div className={(item.url) ? "float-left right-padding-5 left-padding-20 top-margin-3" : "float-left right-padding-5 top-margin-3"}>
                            <img className="image-auto-17" alt="GC" src={assessmentsIconBlue} />
                          </div>
                          <div className="float-left left-padding-5 heading-text-6">
                            <button className="background-button cta-color underline-text offset-underline" onClick={() => this.buttonClicked(item,optionIndex,null)}>Take Survey</button>
                            {/*this.setState({ modalIsOpen: true, takeQuiz: true, selectedIndex: optionIndex, selectedLesson: , showLessonDetails: false })*/}
                          </div>
                          <div className="clear" />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div>
                {(item.assignmentQuestions && item.assignmentQuestions.length > 0) && (
                  <div className="float-left">
                    {(this.state.submissions.some(s => s.lessonId === item._id)) ? (
                      <div>
                        {((item.canChangeAnswers && item.type === 'Quiz') || (item.type !== 'Quiz')) ? (
                          <div className="float-left">
                            <div className={(item.url) ? "float-left right-padding-5 left-padding-20 top-margin-3" : "float-left right-padding-5 top-margin-3"}>
                              <img className="image-auto-17" alt="GC" src={checkmarkIcon} />
                            </div>
                            <div className="float-left left-padding-5 heading-text-6">
                              <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, takeQuiz: true, selectedIndex: optionIndex, showLessonDetails: false })}>Edit Survey</button>
                            </div>
                            <div className="clear" />
                          </div>
                        ) : (
                          <div className="float-left">
                            <div className="fixed-column-30">
                              <img src={checkmarkIcon} alt="GC" className="image-18-fit" />
                            </div>
                            <div className="calc-column-offset-30 top-padding-3">
                              <p className="heading-text-6 cta-color">Submitted</p>
                            </div>
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="float-left">
                        <div className="float-left">
                          <div className={(item.url) ? "float-left right-padding-5 left-padding-20 top-margin-3" : "float-left right-padding-5 top-margin-3"}>
                            <img className="image-auto-17" alt="GC" src={assessmentsIconBlue} />
                          </div>
                          <div className="float-left left-padding-5 heading-text-6">
                            <button className="background-button cta-color underline-text offset-underline" onClick={() => this.buttonClicked(item,optionIndex,null)}>Take Survey</button>
                          </div>
                          <div className="clear" />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            <div className="clear" />
          </div>

          {(item.studentURL) && (
            <div>
              {(item.studentURL.includes('youtube.com') || item.studentURL.includes('youtu.be')) && (
                <div>
                  <div className="clear" />
                  <div>
                    <div className="spacer"/><div className="spacer"/>
                    <div className="video-container-4">
                      <iframe
                        title="videoLink"
                        className="video-iframe"
                        src={this.embedYouTubeVideo(item.studentURL)}
                        frameBorder="0"
                      />
                    </div>
                    <div className="clear"/>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="top-margin-30">
            <p className="description-text-2 keep-line-breaks">{item.description}</p>
          </div>

          <div className="description-text-2 top-margin">

            {(item.learningObjectives && item.learningObjectives.length > 0) && (
              <div className="row-10">
                <div className="row-5">
                  <p className="description-text-color">Learning Objectives</p>
                </div>
                <div className="calc-column-offset-150">
                  {item.learningObjectives.map((item2, optionIndex2) =>
                    <div key={item2 + optionIndex2}>
                      <p>{optionIndex2 + 1}. {item2}</p>
                    </div>
                  )}
                </div>
                <div className="clear" />

                <div className="clear" />
              </div>
            )}

            {(item.prerequisites && item.prerequisites.length > 0) && (
              <div className="row-10">
                <div className="row-5">
                  <p className="description-text-color">Prerequisites</p>
                </div>
                <div className="calc-column-offset-150">
                  {item.prerequisites.map((item2, optionIndex2) =>
                    <div key={item2 + optionIndex2}>
                      <a href={item2.url} target="_blank" rel="noopener noreferrer">{optionIndex2 + 1}. {item2.name}</a>
                    </div>
                  )}
                </div>
                <div className="clear" />

                <div className="clear" />
              </div>
            )}

            {(item.exchangeType === 'Lesson Plan') && (
              <div>
                {/*
                {(item.activities && item.activities.length > 0) && (
                  <div className="top-margin-20">
                    {(inModal) ? (
                      <div>
                        <div className="float-left">
                          <div className="mini-spacer" />
                          <img src={skillsIconBlue} alt="GC" className="image-15-fit" />
                        </div>
                        <div className="float-left left-padding">
                          <p>{this.calcCompletions(item.activities)} / {item.activities.length} Activities</p>
                        </div>
                      </div>
                    ) : (
                      <button onClick={() => this.retrieveComments(optionIndex)} className={"background-button left-text clear-margin"} disabled={this.state.isLoading}>
                        <div className="float-left">
                          <div className="mini-spacer" />
                          <img src={skillsIconBlue} alt="GC" className="image-15-fit" />
                        </div>
                        <div className="float-left left-padding">
                          <p>{this.calcCompletions(item.activities)} / {item.activities.length} Activities</p>
                        </div>
                      </button>
                    )}

                  </div>
                )}*/}
                {(item.activities && item.activities.length > 0) && (
                  <div className="row-10">
                    <div className="row-5">
                      <p className="description-text-color">Activities</p>
                    </div>
                    <div className="calc-column-offset-150">
                      {item.activities.map((item2, optionIndex2) =>
                        <div key={item2 + optionIndex2}>
                          <p>{optionIndex2 + 1}. {item2.name}</p>
                        </div>
                      )}
                    </div>
                    <div className="clear" />
                  </div>
                )}
              </div>
            )}

            {(item.resources && item.resources.length > 0) && (
              <div className="row-10">
                <div className="row-5">
                  <p className="description-text-color">Resources</p>
                </div>
                <div className="calc-column-offset-150">
                  {item.resources.map((item2, optionIndex2) =>
                    <div key={item2 + optionIndex2} className="top-margin-5">
                      <a href={item2.url} target="_blank" rel="noopener noreferrer">{optionIndex2 + 1}. {item2.name}</a>
                    </div>
                  )}
                </div>
                <div className="clear" />

                <div className="clear" />
              </div>
            )}
          </div>
        </div>
      )
    }

    embedYouTubeVideo(url) {
      console.log('embedYouTubeVideo called')

      // https://youtu.be/YDAJjw9ocUo
      // https://www.youtube.com/watch?v=YDAJjw9ocUo
      // https://www.youtube.com/embed/uNfiXBEfwt4

      if (url.includes('youtu.be')) {
        const urlArray = url.split('youtu.be')
        const embedURL = 'https://www.youtube.com/embed/' + urlArray[1]
        return embedURL
      } else if (url.includes('youtube.com')) {
        if (url.includes('/embed')) {
          return url
        } else {
          const urlArray = url.split('?v=')
          const embedURL = 'https://www.youtube.com/embed/' + urlArray[1]
          return embedURL
        }
      }
    }

    retrieveComments(index, passedParentPost) {
      console.log('retrieveComments called', index, passedParentPost)

      let parentPostId = ''
      if (passedParentPost) {
        parentPostId = passedParentPost._id
      } else if (index || index === 0) {
        parentPostId = this.state.lessons[index]._id
      }

      // pull comments
      Axios.get('/api/comments', { params: { parentPostId } })
      .then((response) => {
        console.log('Comments query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved comments')

           const comments = response.data.comments
           this.setState({ modalIsOpen: true, showComments: true, selectedIndex: index, comments, passedParentPost })

         } else {
           console.log('no comments data found', response.data.message)
           this.setState({ modalIsOpen: true, showComments: true, selectedIndex: index, comments: [], passedParentPost })
         }
      }).catch((error) => {
         console.log('Comments query did not work', error);
         this.setState({ modalIsOpen: true, showComments: true, selectedIndex: index, comments: [], passedParentPost })
      });
    }

    calcCompletions(activities) {
      console.log('calcCompletions called')

      let completionCount = 0
      for (let i = 1; i <= activities.length; i++) {
        if (activities[i - 1].completions && activities[i - 1].completions.includes(this.state.emailId)) {
          completionCount = completionCount + 1
        }
      }

      return completionCount
    }

    toggleMetaFilter(type) {
      console.log('toggleMetaFilter called', type)

      let sources = this.state.sources
      if (sources && sources.includes(type)) {
        sources.splice(sources.indexOf(type),1)
      } else {
        if (sources) {
          sources.push(type)
        } else {
          sources = [type]
        }
      }
      // this.setState({ sources })
      this.filterResults(this.state.searchString, '', null, null, true, null, sources)
      // searchString, filterString, filters, index, search, searchType, sources

      // if (type === 'OrgCourses') {
      //
      //   this.setState({ showOrgCourses: !this.state.showOrgCourses })
      // } else if (type === 'Udemy') {
      //   this.setState({ showUdemyCourses: !this.state.showUdemyCourses })
      // } else if (type === 'Coursera') {
      //   this.setState({ showCourseraCourses: !this.state.showCourseraCourses })
      // } else if (type === 'Udacity') {
      //   this.setState({ showUdacityCourses: !this.state.showUdacityCourses })
      // }

    }

    buttonClicked(item,optionIndex,e) {
      console.log('buttonClicked called')

      if (e) {
        e.stopPropagation()
      }

      let selectedQuestions = []
      if (window.location.pathname.includes('/advisor')) {
        selectedQuestions = this.state.lessons[optionIndex].mentorQuestions
      } else {
        selectedQuestions = this.state.lessons[optionIndex].assignmentQuestions
      }

      let selectedLesson = this.state.lessons[optionIndex]

      this.setState({ modalIsOpen: true, takeQuiz: true, selectedIndex: optionIndex, showLessonDetails: false,
        selectedLesson, selectedQuestions,
      })
    }

    passResponse(submission, id) {
      console.log('passResponse called', submission, id)

      if (this.state.showCustomQuiz) {

        let submissions = this.state.submissions
        if (id) {
          submissions[this.state.selectedIndex] = submission
        } else {
          // if (submission && !submission._id) {
          //   submission['_id'] = newId
          // }

          if (submissions) {
            submissions.push(submission)
          } else {
            submissions = [submission]
          }
        }

        this.setState({ submissions })

      } else {
        let lessons = this.state.lessons
        lessons[this.state.selectedIndex]['status'] = "Complete"
        this.setState({ lessons })
      }
    }

    scorecardClicked(scorecard, index) {
      console.log('scorecardClicked called')

      let _id = '63b9c32e9f2b1e1de6361d3d'
      if (window.location.pathname.includes('/advisor')) {
        _id = '63b9c776b9cbcc0dbf5144c7'
      }

      // _id = '644d594c656436a9d3ea06d5'

      Axios.get('/api/customassessments/byid', { params: { _id } })
      .then((response) => {
        console.log('Query custom assessment', response.data);

          if (response.data.success) {
            console.log('successfully retrieved custom assessment')

            const customAssessment = response.data.assessment
            const questions = customAssessment.questions
            const selectedSubmission = scorecard
            const selectedIndex = index

            this.setState({ modalIsOpen: true, takeQuiz: true, showCustomQuiz: true,
              selectedCustomAssessment: customAssessment, selectedQuestions: questions,
              selectedSubmission, selectedIndex
            })

          } else {
            console.log('no assessment data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Assessment query did not work', error);
      });
    }

    render() {

      let passedClassName = "standard-container-2"
      let passedStyle = {}

      if (this.props.pageSource === 'landingPage') {
        passedClassName = "card full-width"
        passedStyle = {}
      }

      return (
          <div>
            {(!this.props.modalIsOpen) && (
              <div>
                <div>
                  {(this.props.pageSource !== 'Goal' && !window.location.pathname.includes('/marketplace') && !window.location.pathname.includes('/business-ideas')) && (
                    <div>
                      {(this.props.pageSource !== 'landingPage') && (
                        <div className="width-70-percent center-horizontally max-width-1400 top-margin-2-percent">
                          <div className="full-width white-background">
                            <div className="clear-float">
                              <div className="carousel-3" onScroll={this.handleScroll}>
                                {this.state.subNavCategories.map((value, index) =>
                                  <div className="display-inline">
                                    {(this.state.subNavCategories[index] === this.state.subNavSelected) ? (
                                      <div className="selected-carousel-item-2">
                                        <label key={value}>{value}</label>
                                      </div>
                                    ) : (
                                      <button className="menu-button-2" onClick={() => this.subNavClicked(value)}>
                                        <label key={value}>{value}</label>
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      )}
                      {/*
                      {(this.state.remoteAuth && this.state.lessons) && (
                        <div className="top-margin-2-percent">
                          <div className="standard-container-2 clear-padding" style={(this.state.activeOrg !== 'unite-la') ? {} : {...styles, opacity: this.state.opacity, transform: this.state.transform }}>
                            <div className="full-width row-10 horizontal-padding-5">
                              <p className="heading-text-2">{this.state.lessons.length} Active Lessons</p>
                            </div>
                            <hr />
                          </div>
                        </div>
                      )}*/}
                    </div>
                  )}

                  {(this.state.subNavSelected === 'Schedule') && (
                    <div className={passedClassName} style={passedStyle}>

                      {(this.state.animating) ? (
                        <div className="flex-container flex-center full-space">
                          <div>
                            <div className="super-spacer" />

                            <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                            <div className="spacer" /><div className="spacer" /><div className="spacer" />
                            <p className="center-text cta-color bold-text">Calculating results...</p>

                          </div>
                        </div>
                      ) : (
                        <div>
                          {(this.state.showScheduleResults) ? (
                            <div>
                              <div className="row-15">
                                <div className="calc-column-offset-100-static">
                                  <p className="heading-text-4">Recommended Schedule</p>
                                  <div className="description-text-3 top-padding-5">
                                    {this.state.targetDate && (
                                      <div className="float-left">
                                        <div className="float-left right-margin">
                                          <img src={calendarIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                        </div>
                                        <div className="float-left right-margin">
                                          <label>{this.state.targetDate}</label>
                                        </div>
                                      </div>
                                    )}
                                    {this.state.intensity && (
                                      <div className="float-left">
                                        <div className="float-left right-margin">
                                          <img src={timeIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                        </div>
                                        <div className="float-left right-margin">
                                          <label>{this.state.intensity}</label>
                                        </div>
                                      </div>
                                    )}
                                    {this.state.budget && (
                                      <div className="float-left">
                                        <div className="float-left right-margin">
                                          <img src={moneyIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                        </div>
                                        <div className="float-left right-margin">
                                          <label>{this.state.budget}</label>
                                        </div>
                                      </div>
                                    )}

                                    {this.state.selectedCareer && (
                                      <div className="float-left">
                                        <div className="float-left right-margin">
                                          <img src={careerMatchesIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                        </div>
                                        <div className="float-left right-margin">
                                          <label>{this.state.selectedCareer}</label>
                                        </div>
                                      </div>
                                    )}
                                    {this.state.selectedOpportunity && (
                                      <div className="float-left">
                                        <div className="float-left right-margin">
                                          <img src={opportunitiesIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                        </div>
                                        <div className="float-left right-margin">
                                          <label>{this.state.selectedOpportunity}</label>
                                        </div>
                                      </div>
                                    )}

                                    {this.state.learningObjectives && (
                                      <div className="float-left">
                                        <div className="float-left right-margin">
                                          <img src={learningObjectivesIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                        </div>
                                        <div className="float-left right-margin">
                                          <label>{this.state.learningObjectives.length} Learning Objectives</label>
                                        </div>
                                      </div>
                                    )}

                                    <div className="clear" />
                                  </div>

                                </div>
                                <div className="fixed-column-50">
                                  <div className="flex-container flex-center">
                                    <button type="button" className="background-button" onClick={() => this.setState({ modalIsOpen: true, showMatchingCriteria: false, showBrowseCourses: true, showScheduleSetup: false, selectedIndex1: null, selectedIndex2: null })}>
                                      <img src={addIcon} alt="GC" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add Course"/>
                                    </button>
                                  </div>
                                </div>
                                <div className="fixed-column-50">
                                  <div className="flex-container flex-center">
                                    <button type="button" className="background-button" onClick={() => this.setState({ modalIsOpen: true, showMatchingCriteria: false, showBrowseCourses: false, showScheduleSetup: true, selectedIndex1: null, selectedIndex2: null })}>
                                      <img src={recommendIcon} alt="GC" className="image-auto-22 center-item" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Set Up Schedule"/>
                                    </button>
                                  </div>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="spacer" /><div className="spacer" /><div className="spacer" />

                              {/*
                              <div className="top-margin bottom-padding-40 description-text-2">
                                <p>Drag and drop courses to different weeks to change your schedule</p>
                              </div>*/}

                              {(this.state.showDragger) ? (
                                <div>
                                  <DragDropContext onDragEnd={this.onDragEnd}>
                                    {this.state.schedule && this.state.schedule.map((item, index) =>
                                      <div key={"scheduleIndex" + index}>
                                        {(item.courses) && (
                                          <div className="standard-border">
                                            <div className="padding-10 dark-background white-text bold-text description-text-2">
                                              <div className="fixed-column-70">
                                                <p>Week: {index + 1}</p>
                                              </div>
                                              <div className="calc-column-offset-70">
                                                <p>{this.calcScheduleDate('startDate', index)} - {this.calcScheduleDate('endDate', index)}</p>
                                              </div>
                                              <div className="clear" />
                                            </div>

                                            <StrictModeDroppable droppableId={item.week}>
                                              {(provided, snapshot) => (
                                                <div
                                                  ref={provided.innerRef}
                                                  style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                  {item.courses && item.courses.map((item2, index2) =>
                                                    <Draggable key={index2} draggableId={item.week + "|" + index2} index={index2}>
                                                      {(provided, snapshot) => (
                                                        <div
                                                          ref={provided.innerRef}
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                          style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                          )}
                                                        >
                                                          <div className="standard-border standard-color">
                                                            <div className="padding-10">
                                                              <div className="row-10">
                                                                <div className="fixed-column-50">
                                                                  <div className="row-10">
                                                                    <img src={dragIcon} alt="GC" className="image-auto-20 center-item"/>
                                                                  </div>
                                                                  <div className="row-10">
                                                                    <img src={udemyLogo} alt="GC" className="image-auto-15 center-item top-margin-5"/>
                                                                  </div>
                                                                </div>
                                                                <div className="calc-column-offset-110">
                                                                  <p className="heading-text-6">{item2.title}</p>
                                                                  <p className="description-text-2">{item2.headline}</p>

                                                                  <div className="description-text-3 top-padding-5">
                                                                    {(item2.duration) && (
                                                                      <div>
                                                                        <div className="float-left right-margin">
                                                                          <img src={timeIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                                                        </div>
                                                                        <div className="float-left right-margin">
                                                                          <label>{item2.duration}</label>
                                                                        </div>
                                                                      </div>
                                                                    )}

                                                                    {(item2.price) && (
                                                                      <div>
                                                                        <div className="float-left right-margin">
                                                                          <img src={moneyIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                                                        </div>
                                                                        <div className="float-left right-margin">
                                                                          <label>{item2.price}</label>
                                                                        </div>
                                                                      </div>
                                                                    )}

                                                                    {(item2.difficultyLevel) && (
                                                                      <div>
                                                                        <div className="float-left right-margin">
                                                                          <img src={difficultyIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                                                        </div>
                                                                        <div className="float-left right-margin">
                                                                          <label>{item2.difficultyLevel ? item2.difficultyLevel : "Beginner"}</label>
                                                                        </div>
                                                                      </div>
                                                                    )}

                                                                    {(item2.rating && item2.ratingCount) && (
                                                                      <div>
                                                                        <div className="float-left right-margin">
                                                                          <img src={ratingsIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                                                        </div>
                                                                        <div className="float-left right-margin">
                                                                          <label>{item2.rating}/5.0 - {item2.ratingCount} Ratings</label>
                                                                        </div>
                                                                      </div>
                                                                    )}

                                                                    {(item2.studentCount) && (
                                                                      <div>
                                                                        <div className="float-left right-margin">
                                                                          <img src={profileIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                                                        </div>
                                                                        <div className="float-left right-margin">
                                                                          <label>{item2.studentCount} Students</label>
                                                                        </div>
                                                                      </div>
                                                                    )}

                                                                    <div className="clear" />
                                                                  </div>
                                                                  {/*
                                                                  <div className="row-5">
                                                                    <p className="description-text-3">04/15/2021 - 05/15/2021 (10 hrs / week)</p>
                                                                  </div>*/}

                                                                </div>

                                                                <div className="fixed-column-60">

                                                                  <div>
                                                                    <div className="fixed-column-30 center-text">
                                                                      <button className="background-button clear-padding clear-margin center-item padding-5" onClick={() => this.favoriteItem(item2,'course')}>
                                                                        <img src={(this.state.favorites.includes(item2._id) || this.state.favoritedCourseDetails.some(selectedCourse => selectedCourse.url === item2.url)) ? favoritesIconBlue : favoritesIconDark} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Mark as Enrolled"/>
                                                                      </button>
                                                                    </div>
                                                                    <div className="fixed-column-30 center-text">
                                                                      <button className="background-button clear-padding clear-margin center-item padding-5" onClick={() => this.markCompleted(item2, 'course')}>
                                                                        <img src={(this.state.completions.includes(item2._id) || this.state.completedCourseDetails.some(selectedCourse => selectedCourse.url === item2.url)) ? checkboxChecked : checkmarkDarkGreyIcon} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Mark as Completed"/>
                                                                      </button>
                                                                    </div>

                                                                    <div className="clear" />
                                                                  </div>

                                                                  <div>
                                                                    <div className="fixed-column-30 center-text">
                                                                      <a className="background-button clear-padding clear-margin center-item padding-5" target="_blank" href={'https://www.udemy.com' + item2.url}>
                                                                        <img src={linkIcon} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="View Course Details"/>
                                                                      </a>
                                                                    </div>

                                                                    <div className="fixed-column-30 center-text">
                                                                      <button className="background-button clear-padding clear-margin center-item padding-5" onClick={() => this.duplicateItem(item2, index, index2)}>
                                                                        <img src={duplicateIcon} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Duplicate Course"/>
                                                                      </button>
                                                                    </div>
                                                                    <div className="clear" />
                                                                  </div>

                                                                  <div>
                                                                    {/*
                                                                    <div className="fixed-column-30 center-text">
                                                                      <button className="background-button clear-padding clear-margin center-item padding-5" onClick={() => this.setState({ modalIsOpen: true, showMatchingCriteria: false, showBrowseCourses: true, showScheduleSetup: false, selectedIndex1: index, selectedIndex: index2 })}>
                                                                        <img src={editIconDark} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Edit Course"/>
                                                                      </button>
                                                                    </div>*/}
                                                                    <div className="fixed-column-30 center-text">
                                                                      <button className="background-button clear-padding clear-margin center-item padding-5" onClick={() => this.deleteItem(item2, index, index2)}>
                                                                        <img src={deleteIconDark} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Delete Course"/>
                                                                      </button>
                                                                    </div>
                                                                    <div className="clear" />
                                                                  </div>
                                                                </div>

                                                                <div className="clear" />

                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </Draggable>
                                                  )}
                                                  {provided.placeholder}

                                                </div>
                                              )}
                                            </StrictModeDroppable>

                                          </div>
                                        )}

                                      </div>
                                    )}
                                  </DragDropContext>

                                </div>
                              ) : (
                                <div>
                                  {this.state.schedule && this.state.schedule.map((value,index) =>
                                    <div key={index}>
                                      <div className="top-margin standard-border">
                                        <div className="padding-10 light-background bold-text description-text-2">
                                          <div className="fixed-column-100">
                                            <p>Week {index + 1}</p>
                                          </div>
                                          <div className="calc-column-offset-200">
                                            <p>{this.state.today} - {this.state.oneWeekFromNow}</p>
                                          </div>
                                          {/*
                                          <div className="fixed-column-50 center-text">
                                            <p>Price</p>
                                          </div>
                                          <div className="fixed-column-50 center-text">
                                            <p>Hrs</p>
                                          </div>*/}
                                          <div className="clear" />
                                        </div>

                                        <div className="padding-10">
                                          <div className="row-10">
                                            <div className="fixed-column-40">
                                              <div className="row-10">
                                                <img src={dragIcon} alt="GC" className="image-auto-15 center-item"/>
                                              </div>
                                              <div className="row-10">
                                                <img src={udemyLogo} alt="GC" className="image-auto-15 center-item top-margin-5"/>
                                              </div>
                                            </div>
                                            <div className="calc-column-offset-90">
                                              <p className="heading-text-6">{value.title}</p>
                                              <p className="description-text-2">{value.headline}</p>

                                              <div className="description-text-3 top-padding-5">
                                                <div className="float-left right-margin">
                                                  <img src={timeIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                                </div>
                                                <div className="float-left right-margin">
                                                  <label>{value.duration}</label>
                                                </div>

                                                <div className="float-left right-margin">
                                                  <img src={moneyIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                                </div>
                                                <div className="float-left right-margin">
                                                  <label>{value.price}</label>
                                                </div>

                                                <div className="float-left right-margin">
                                                  <img src={difficultyIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                                </div>
                                                <div className="float-left right-margin">
                                                  <label>{value.difficultyLevel ? value.difficultyLevel : "Beginner"}</label>
                                                </div>

                                                <div className="clear" />
                                              </div>
                                              {/*
                                              <div className="row-5">
                                                <p className="description-text-3">04/15/2021 - 05/15/2021 (10 hrs / week)</p>
                                              </div>*/}

                                            </div>
                                            <div className="fixed-column-50">
                                              <img src={linkIcon} alt="GC" className="image-auto-15 center-item top-margin-5"/>
                                              <img src={editIconDark} alt="GC" className="image-auto-15 center-item top-margin" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="hello world"/>
                                              <img src={deleteIconDark} alt="GC" className="image-auto-15 center-item top-margin"/>
                                            </div>

                                            <div className="clear" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              <ReactTooltip id="tooltip-placeholder-id" />

                            </div>
                          ) : (
                            <div>

                              {this.renderScheduleSetup('schedule')}

                            </div>
                          )}

                        </div>
                      )}

                    </div>
                  )}

                  {(this.state.subNavSelected === 'Browse' || this.state.subNavSelected === 'Courses' || this.state.subNavSelected === 'Programs & Courses') && (
                    <div>
                      {this.renderBrowseCourses()}
                    </div>
                  )}

                  {(this.state.subNavSelected === 'Favorited') && (
                    <div className={passedClassName} style={passedStyle}>
                      <SubFavorites pageSource="courses" />
                    </div>
                  )}

                  {(this.state.subNavSelected === 'Completed') && (
                    <div className={passedClassName} style={passedStyle}>
                      {/*<p>Completed courses, badges, certifications, knowledge acquired</p>*/}
                      <SubCompletions pageSource="courses" />
                    </div>
                  )}

                  {(this.state.subNavSelected === 'Recommended') && (
                    <div className={passedClassName} style={passedStyle}>
                      <div>
                        <div>
                          <div className="full-space row-20">
                            <img src={courseIconDark} alt="GC" className="image-auto-100 center-horizontally" />

                            <div className="row-20">
                              <p className="center-text heading-text-2">Courses</p>
                              <p className="center-text top-padding-20 horizontal-padding">Courses recommended to you by educators, workforce professionals, and mentors will appear here.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {(this.state.subNavSelected === 'Lessons') && (
                    <div className="standard-container-2 clear-background clear-padding clear-shadow">
                      {(this.state.lessons && this.state.lessons.length > 0) ? (
                        <div>
                          {this.renderItems('lesson')}
                          <div className="clear" />
                        </div>
                      ) : (
                        <div>
                          <p className="full-width center-text">No lessons have been posted yet</p>
                        </div>
                      )}

                    </div>
                  )}

                  {(this.state.subNavSelected === 'Scorecards') && (
                    <div className="standard-container-2 clear-background clear-padding clear-shadow">
                      <div>
                        <div className="float-left right-padding">
                          <p className="heading-text-5">{(this.state.submissions && this.state.submissions.length > 0) ? this.state.submissions.length : 0} Scorecards</p>
                        </div>
                        <div className="float-left">
                          <button type="button" className="background-button padding-7 standard-border cta-background-color circle-radius" onClick={() => this.scorecardClicked()}>
                            <img src={addIconWhite} alt="GC" className="image-auto-12" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add Scorecard"/>
                          </button>
                        </div>
                        <div className="clear" />
                      </div>

                      {(this.state.submissions && this.state.submissions.length > 0) ? (
                        <div className="top-margin-30">
                          {this.renderItems('submission')}
                          <div className="clear" />
                        </div>
                      ) : (
                        <div className="card top-margin-30">
                          <p className="full-width">You haven't added any scorecards yet.</p>
                        </div>
                      )}

                    </div>
                  )}

                  {(this.state.subNavSelected === 'Resources') && (
                    <div className="standard-container-2 clear-background clear-padding clear-shadow">
                      {this.renderItems('resource')}
                      <div className="clear" />
                    </div>
                  )}

                  {(this.state.subNavSelected === 'Book Advice') && (
                    <div className="standard-container-2 clear-background clear-padding clear-shadow">
                      <SubAdvisingServices history={this.props.navigate} />
                    </div>
                  )}
                </div>

                {(this.props.pageSource === 'landingPage') && (
                  <div className="clear" />
                )}
              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
           >

            {(this.state.showMatchingCriteria) && (
              <div key="showMatchingCriteria" className="full-width padding-20">
                <p className="heading-text-2">Adjust Matching Criteria</p>

                {this.renderScheduleSetup('course')}

              </div>
            )}

            {(this.state.showBrowseCourses) && (
              <div key="showBrowseCourses" className="full-width padding-20">
                {this.renderBrowseCourses(true)}
              </div>
            )}

            {(this.state.showScheduleSetup) && (
              <div key="showScheduleSetup" className="full-width padding-20">
                {this.renderScheduleSetup('schedule')}
              </div>
            )}

            {(this.state.startRecommendation && this.state.selectedCourse) && (
              <div key="startRecommendation" className="full-width padding-20">
                <div className="bottom-padding">
                  <p className="heading-text-2">Select Career-Seekers to Recomment This Course To</p>
                </div>

                <div className="standard-border padding-5">
                  <a href={'https://www.udemy.com' + this.state.selectedCourse.url} className="background-button calc-column-offset-65" target="_blank">
                    <div className="fixed-column-70 top-padding-5">
                      <img src={(this.state.selectedCourse.imageURL) ? this.state.selectedCourse.imageURL : (this.state.selectedCourse.image_125_H) ? this.state.selectedCourse.image_125_H : courseIconDark} alt="GC" className="image-60-auto"/>
                    </div>
                    <div className="calc-column-offset-70">
                      <p className="heading-text-5">{this.state.selectedCourse.title}</p>
                      <p className="description-text-1 description-text-color">{this.state.selectedCourse.headline}</p>

                      <div className="half-spacer" />

                      {(this.state.selectedCourse.duration) && (
                        <div className="description-text-3">
                          <div className="float-left right-margin">
                            <img src={timeIconBlue} alt="GC" className="image-auto-15 center-item"/>
                          </div>
                          <div className="float-left right-margin">
                            <label>{this.state.selectedCourse.duration}</label>
                          </div>
                        </div>
                      )}

                      {(this.state.selectedCourse.price) && (
                        <div className="description-text-3">
                          <div className="float-left right-margin">
                            <img src={moneyIconBlue} alt="GC" className="image-auto-15 center-item"/>
                          </div>
                          <div className="float-left right-margin">
                            <label>{(this.state.selectedCourse.price === '0') ? "FREE" : this.state.selectedCourse.price}</label>
                          </div>
                        </div>
                      )}

                      {(this.state.selectedCourse.difficultyLevel) && (
                        <div className="description-text-3">
                          <div className="float-left right-margin">
                            <img src={difficultyIconBlue} alt="GC" className="image-auto-15 center-item"/>
                          </div>
                          <div className="float-left right-margin">
                            <label>{this.state.selectedCourse.difficultyLevel ? this.state.selectedCourse.difficultyLevel : "Beginner"}</label>
                          </div>
                        </div>
                      )}

                      {(this.state.selectedCourse.rating && this.state.selectedCourse.ratingCount) && (
                        <div className="description-text-3">
                          <div className="float-left right-margin">
                            <img src={ratingsIconBlue} alt="GC" className="image-auto-15 center-item"/>
                          </div>
                          <div className="float-left right-margin">
                            <label>{this.state.selectedCourse.rating} / 5.0 - {this.state.selectedCourse.ratingCount} Ratings</label>
                          </div>
                        </div>
                      )}

                      {(this.state.selectedCourse.studentCount) && (
                        <div className="description-text-3">
                          <div className="float-left right-margin">
                            <img src={profileIconBlue} alt="GC" className="image-auto-15 center-item"/>
                          </div>
                          <div className="float-left right-margin">
                            <label>{this.state.selectedCourse.studentCount} Students</label>
                          </div>
                        </div>
                      )}

                      <div className="clear" />
                      <div className="half-spacer" />
                      {/*
                      <div className="tag-container-7 top-margin-5">
                        <p className="description-text-3">{value.category}</p>
                        <p className="description-text-3">{value.price}</p>
                      </div>*/}
                    </div>

                  </a>
                  <div className="clear" />
                </div>

                <div className="row-10">
                  <select name={"recommendMethod"} value={this.state.recommendMethod} onChange={this.formChangeHandler} className="dropdown">
                    {['','All ' + this.state.orgName + ' Career-Seekers', 'Specific Individuals'].map(value =>
                      <option key={value} value={value}>{value}</option>
                    )}
                  </select>
                </div>

                {(this.state.recommendMethod === 'Specific Individuals') && (
                  <div>
                    <div className="row-10">
                      <div className="calc-column-offset-100-static">
                        <input type="text" className="text-field" placeholder={"Search members..."} name="searchReferees" value={this.state.searchString} onChange={this.formChangeHandler}/>
                      </div>
                      <div className="fixed-column-100 left-padding">
                        <button className="btn btn-squarish description-text-2" onClick={() => this.addItem('referee')}>Add</button>
                      </div>
                      <div className="clear" />
                    </div>

                    {(this.state.memberOptions && this.state.searchString && this.state.searchString !== '') && (
                      <div>
                        {this.state.memberOptions.map((value, optionIndex2) =>
                          <div key={value._id}>
                            <button className="background-button full-width left-text" onClick={() => this.searchItemClicked(value, 'referee')}>
                              <div className="left-padding full-width row-5">
                                <div className="fixed-column-50">
                                  <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                  <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                                </div>
                                <div className="calc-column-offset-50 top-padding-5">
                                  <p>{value.firstName} {value.lastName}</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </button>
                          </div>
                        )}
                      </div>
                    )}

                    {(this.state.animating) ? (
                      <div className="flex-container flex-center full-space">
                        <div>
                          <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                          <div className="spacer" /><div className="spacer" /><div className="spacer" />
                          <p className="center-text cta-color bold-text">Pulling search results...</p>

                        </div>
                      </div>
                    ) : (
                      <div className="row-10">
                        {this.renderTags('referees')}
                        <div className="clear" />
                      </div>
                    )}

                  </div>
                )}

                <div className="row-10">
                  <button className="btn btn-primary" onClick={() => this.recommendCourse()}>
                    Recommend Course
                  </button>
                </div>

              </div>
            )}

            {(this.state.showComments) && (
              <div key="showComments" className="full-width">

              <div>
                {this.renderItem(this.state.lessons[this.state.selectedIndex], this.state.selectedIndex, true)}
                <div className="clear" />
                <div className="spacer" />

                {(this.state.lessons && this.state.org) && (
                  <SubComments selectedCurriculumPost={this.state.lessons[this.state.selectedIndex]} activeOrg={this.state.org} accountCode={this.state.accountCode} comments={this.state.comments} postingOrgCode={this.state.activeOrg} postingOrgName={this.state.orgName} orgContactEmail={this.state.orgContactEmail} pictureURL={this.state.pictureURL} orgLogo={this.state.orgLogo} history={this.props.navigate} pageSource={"courses"} />
                )}
              </div>
              </div>
            )}

            {(this.state.takeQuiz) && (
              <div key="takeQuiz" className="full-width">
                <SubTakeBasicAssessment history={this.props.navigate} questions={this.state.selectedQuestions} selectedLesson={this.state.selectedLesson} selectedCustomAssessment={this.state.selectedCustomAssessment} selectedSubmission={this.state.selectedSubmission} submissions={this.state.submissions} orgCode={this.state.org}  orgContactEmail={this.state.orgContactEmail} modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} passResponse={this.passResponse} cohort={this.state.cohort}/>
              </div>
            )}

            {(this.state.showLessonDetails) && (
              <div key="showLessonDetails" className="full-width">
                {this.renderItem(this.state.lessons[this.state.selectedIndex], this.state.selectedIndex, true)}
              </div>
            )}

           </Modal>
          </div>
      )
    }
}


export default withRouter(CoursesTwo);
