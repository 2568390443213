import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import SubCustomDropdown from '../Common/CustomDropdown';
import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';

// const addIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png";
const trashIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/trash-icon-dark.png';
// const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
// const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const assignmentsIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assignments-icon-dark.png";
const arrowIndicatorIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png";
const profileIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class Submissions extends Component {
    constructor(props) {
        super(props)

        this.state = {
          submissions: [],
          filters: [],
          defaultFilterOption: 'All'
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.searchItems = this.searchItems.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.toggleConfirmDelete = this.toggleConfirmDelete.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubSubmissionBuilding', this.props, prevProps)

      if (this.props.orgCode !== prevProps.orgCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called', this.props.accountCode)

      let orgCode = this.props.orgCode
      const accountCode = this.props.accountCode

      //obtain email from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgName = localStorage.getItem('orgName');

      if (!orgCode) {
        orgCode = activeOrg
      }

      if (orgCode) {

        // const defaultFilterOption = this.state.defaultFilterOption
        let filters = [
          // { name: 'Urgency', optionSubKey: null, shortname: "filter|urgency", value: defaultFilterOption, options: [defaultFilterOption].concat(this.state.urgencyOptions) },
        ]

        let prefix = "/organizations/" + orgCode
        if (window.location.pathname.startsWith('/advisor')) {
          prefix = "/advisor"
        }

        this.setState({ orgCode, orgName, emailId: email, username, orgFocus, accountCode, filters, prefix })

        // const onlyUpcoming = true
        // Axios.put('/api/submissions/filter', { orgCode })
        Axios.get('/api/submissions', { params: { orgCode }})
        .then((response) => {
          console.log('submission query worked', response.data);

          if (response.data.success) {

            const submissions = response.data.submissions
            this.setState({ submissions })

          } else {
            console.log('no placements data found', response.data.message)
          }

        }).catch((error) => {
            console.log('Placements query did not work', error);
        });
      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'searchString') {
        this.setState({ [event.target.name]: event.target.value, animating: true })
        this.searchItems(event.target.value, this.state.filters)
      } else if (event.target.name.includes('filter|')) {
        let filters = this.state.filters

        for (let i = 1; i <= filters.length; i++) {
          if (filters[i - 1].shortname === event.target.name) {
            filters[i - 1]['value'] = event.target.value
          }
        }

        this.setState({ filters })
        this.searchItems(null,filters)
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
      console.log('gimme the filters: ', this.state.filters)
    }

    searchItems(searchString, filters) {
      console.log('searchItems called', searchString, filters)

      const orgCode = this.state.orgCode
      const self = this
      console.log('show me orgCode: ', orgCode)

      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.put('/api/submissions/filter', { searchString, orgCode, filters }).then((response) => {
          console.log('submissions search filter query attempted');

            if (response.data.success) {
              console.log('submissions search query worked')

              // console.log('second matchScore: ', response.data.postings[0].matchScore, response.data.postings[0].title, response.data.postings[0].name)
              const filterCriteria = response.data.filterCriteria

              const submissions = response.data.submissions
              self.setState({ submissions, animating: false, filterCriteria })

            } else {
              console.log('submissions search query did not work', response.data.message)
              self.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('submissions search query did not work for some reason', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    deleteItem(type, index) {
      console.log('deleteItem called', type, index)

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      let submissions = this.state.submissions
      const _id = this.state.submissions[index]._id
      if (!_id) {
        this.setState({ isSaving: false, errorMessage: "Error: no ID for the submission" })
      } else {
        Axios.delete('/api/submissions/' + _id)
        .then((response) => {
          console.log('tried to delete', response.data)
          if (response.data.success) {
            //save values
            console.log('submission delete worked');

            submissions.splice(index,1)
            this.setState({ submissions, isSaving: false, successMessage: 'Successfully delete the submission',
              confirmDelete: false
            })

          } else {
            console.error('an error: ', response.data.message);
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      }
    }

    toggleConfirmDelete(index) {
      console.log('toggleConfirmDelete called')

      let submissions = this.state.submissions
      if (submissions[index].confirmDelete) {
        submissions[index]['confirmDelete'] = false
      } else {
        submissions[index]['confirmDelete'] = true
      }

      this.setState({ submissions })
    }

    render() {

      return (
          <div>
            <div className="standard-container-2 clear-padding">
              <div className="padding-20">
                <h3 className="normal-weight">{(this.state.submissions) ? this.state.submissions.length + " " : ""}{this.state.orgName} Submissions</h3>
              </div>
            </div>
            {/*
            <div className="max-width-1400 width-70-percent center-horizontally white-background medium-shadow">
              <div className="fixed-column-40 padding-6 left-padding-5 top-padding-5">
                <img src={searchIcon} alt="GC" className="image-auto-28 padding-5" />
              </div>
              <div className="calc-column-offset-95">
                <input type="text" className="text-field clear-border" placeholder="Search..." name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
              </div>

              <div className="fixed-column-55 top-margin-5">
                <button type="button" className="background-button pin-right" onClick={(this.state.showingSearchBar) ? () => this.setState({ showingSearchBar: false }) : () => this.setState({ showingSearchBar: true })}>
                  <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                </button>
              </div>
              <div className="clear" />
            </div>*/}

            {(this.state.showingSearchBar) && (
              <div className="max-width-1400 width-70-percent center-horizontally">
                {this.state.filters.map((value, index) =>
                  <div key="filterContainer">
                    <SubCustomDropdown dropdownTitle={value.name} name={value.shortname} value={value.value} options={value.options} optionSubKey={value.optionSubKey} optionClass="float-left curtail-text relative-position z-index-1" renderDate={value.renderDate} formChangeHandler={this.formChangeHandler} />
                  </div>
                )}
                <div className="clear" />
              </div>
            )}

            {(this.state.showingSearchBar) && (
              <div className="max-width-1400 width-70-percent center-horizontally">
                {this.state.filters.map((value, index) =>
                  <div key="filterContainer">
                    <SubCustomDropdown dropdownTitle={value.name} name={value.shortname} value={value.value} options={value.options} optionSubKey={value.optionSubKey} optionClass="float-left curtail-text relative-position z-index-1" renderDate={value.renderDate} formChangeHandler={this.formChangeHandler} />
                  </div>
                )}
                <div className="clear" />
              </div>
            )}

            <div className="standard-container-2 clear-padding">
              {(this.state.animating) ? (
                <div className="flex-container flex-center full-space padding-40">
                  <div>
                    <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                    <div className="spacer" /><div className="spacer" /><div className="spacer" />
                    <p className="center-text cta-color bold-text">Calculating results...</p>

                  </div>
                </div>
              ) : (
                <div>
                  {(this.state.submissions && this.state.submissions.length > 0) ? (
                    <div>
                      {this.state.submissions.map((value, optionIndex) => (
                        <div className="padding-10 standard-color">
                          <div className="padding-10">
                            <div className="fixed-column-70 top-margin-7">
                              <img className="profile-thumbnail-2" alt="GC" src={(value.pictureURL) ? value.pictureURL : profileIconDark} />
                            </div>
                            <Link className="background-button clear-margin left-text calc-column-offset-140 row-3" to={this.state.prefix + "/submissions/" + value._id}>
                              <div className="full-width">
                                <p className="heading-text-4 cta-color">{value.firstName} {value.lastName}</p>
                                <p className="description-text-2 top-padding-5">Submitted {convertDateToString(new Date(value.createdAt),"date-2")} to {(value.courseName) ? value.courseName : (value.lessonName) ? value.lessonName : value.assessmentName}</p>
                              </div>
                            </Link>
                            <div className="fixed-column-40 top-margin">
                              <button className="background-button clear-margin" onClick={(e) => this.toggleConfirmDelete(optionIndex)}>
                                <img src={trashIconDark} alt="GC" className="image-auto-18"/>
                              </button>
                            </div>
                            <div className="fixed-column-30 top-margin">
                              <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20 pin-right"/>
                            </div>
                            <div className="clear" />

                            {(value.confirmDelete) && (
                              <div className="full-width right-text">
                                <p className="description-text-2 error-color">Are you sure you want to delete?</p>
                                <div className="top-margin">
                                  <button className="btn btn-squarish error-background-color error-border right-margin" onClick={() => this.deleteItem('submission',optionIndex)}>Yes, Delete</button>
                                  <button className="btn btn-squarish-white" onClick={() => this.toggleConfirmDelete(optionIndex)}>Cancel</button>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="spacer" />
                          <div>
                            <div className="half-spacer" />
                            <hr />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      <div className="card flex-container flex-center padding-50">
                        <div className="row-30">
                          <img src={assignmentsIconDark} alt="GC" className="image-auto-80 center-horizontally"/>
                          <p className="center-text heading-text-5 top-margin-30">No Submissions Yet</p>
                          <p className="center-text description-text-color top-margin-20">All submissions will appear here.</p>
                        </div>
                      </div>
                    </div>
                  )}

                </div>
              )}
            </div>
          </div>

      )
    }
}


export default withRouter(Submissions);
