import React, {Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import withRouter from '../Functions/WithRouter';

const favoritesIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/interests-icon.png';
const logIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/log-icon-dark.png';
const socialIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-dark.png';
const moneyIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-dark.png';
const targetIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon.png';
const addProfilePhotoIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-profile-photo-icon.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
// const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const abilitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/abilities-icon-dark.png';
const endorsementIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/endorsement-icon-dark.png';
const publicIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/public-icon.png';
const checkmarkDarkGreyIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-dark-grey.png";
const benchmarksIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-dark.png";
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const resumeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/resume-icon-dark.png';
// const courseIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png';

class EditProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
          allowMultipleFiles: true,
          prefix: '/app'
        }

        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.fetchAllProfileData = this.fetchAllProfileData.bind(this)

    }

    componentDidMount() {
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within edit profile ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.objectId !== prevProps.objectId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called')

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let roleName = localStorage.getItem('roleName');
      let remoteAuth = localStorage.getItem('remoteAuth');

      let prefix = '/app'
      if (window.location.pathname.includes('/advisor')) {
        prefix = '/advisor'
      }

      this.setState({ activeOrg, emailId: email, username, orgFocus, roleName,
        remoteAuth, prefix
      })

      if (window.location.pathname.includes('/advisor')) {

        this.fetchAllProfileData(email, null)

      } else {

        this.setState({ emailId: email, username, activeOrg, orgFocus });

        Axios.get('/api/favorites', { params: { emailId: email } })
        .then((response) => {
          console.log('Favorites query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved favorites', response.data.favorites.length)

              if (response.data.favorites.length > 0) {
                console.log('the array is greater than 0', response.data.favorites.length)

                this.setState({ favorites: response.data.favorites })

              }

            } else {
              console.log('no favorites data found', response.data.message)
            }

        }).catch((error) => {
            console.log('Favorites query did not work', error);
        });

        Axios.get('/api/story', { params: { emailId: email } })
        .then((response) => {
            console.log('Endorsement query worked', response.data);

            if (response.data.success) {

              if (response.data.stories) {
                const endorsements = response.data.stories
                this.setState({ endorsements })
              }
            }

        }).catch((error) => {
            console.log('Story query did not work', error);
        });

        Axios.get('/api/logs/user', { params: { email } })
        .then((response) => {

            if (response.data.success) {
              console.log('Logs received query worked', response.data);

              let logs = response.data.logs
              this.setState({ logs })

            } else {
              console.log('no logs data found', response.data.message)
            }

        }).catch((error) => {
            console.log('Logs query did not work', error);
        });

        Axios.get('/api/assessment/results', { params: { emailId: email } })
         .then((response) => {
           console.log('query for assessment results worked');

           if (response.data.success) {

             console.log('actual assessment results', response.data)

             if (response.data.results) {

               let assessmentCount = 0

               if (response.data.results.workPreferenceAnswers && response.data.results.workPreferenceAnswers.length > 0) {
                 assessmentCount = assessmentCount + 1
               }
               if (response.data.results.interestScores && response.data.results.interestScores.length > 0) {
                 assessmentCount = assessmentCount + 1
               }
               if (response.data.results.skillsScores && response.data.results.skillsScores.length > 0) {
                 assessmentCount = assessmentCount + 1
               }
               if (response.data.results.personalityScores) {
                 assessmentCount = assessmentCount + 1
               }
               if (response.data.results.topGravitateValues && response.data.results.topGravitateValues.length > 0 && response.data.results.topEmployerValues && response.data.results.topEmployerValues.length > 0) {
                 assessmentCount = assessmentCount + 1
               }

               this.setState({ assessmentCount });

             }

           } else {
             console.log('error response', response.data)

             this.setState({ resultsErrorMessage: response.data.message })
           }

       }).catch((error) => {
           console.log('query for assessment results did not work', error);
       })

       this.fetchAllProfileData(email, null)

      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1, transform: 'translate(2%)', isMobile: false
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1, transform: 'translate(2%)', isMobile: true
                }));
          }, 0.3)

      }
    }

    fetchAllProfileData (email, orgDegree) {
      console.log('pulling profile data', email)

      Axios.get('/api/users/profile/details/' + email)
      .then((response) => {
        if (response.data.success) {

          const responseData = response.data

          const firstNameValue = responseData.user.firstName
          const lastNameValue = responseData.user.lastName
          const pictureURL = responseData.user.pictureURL
          const schoolValue = responseData.user.school
          const gradYear = responseData.user.gradYear
          const jobTitle = responseData.user.jobTitle
          const employerName = responseData.user.employerName
          const publicProfile = responseData.user.publicProfile
          const publicProfileExtent = responseData.user.publicProfileExtent
          const oauthUid = responseData.user.oauthUid

          this.setState({
            firstNameValue, lastNameValue, pictureURL, schoolValue, gradYear, jobTitle, employerName,
            publicProfile, publicProfileExtent, oauthUid
          });

        } else {

        }
      }).catch((error) => {
          console.log('Profile pic fetch did not work', error);
      });
    }

    formChangeHandler = event => {

      console.log('show data: ', event.target.name)

      if (event.target.name === 'profilePic') {
        console.log('profilePicSelectedHandler changed', event.target.files[0])

        if (event.target.files[0]) {
          const mbLimit = 10
          if (event.target.files[0].size > mbLimit * 1024 * 1024) {
            console.log('file is too big')

            const errorMessage = 'File must be less than ' + mbLimit + 'MB. This file is ' + (event.target.files[0].size / (1024 * 1024)).toFixed() + 'MB'
            this.setState({ serverSuccessProfilePic: false, serverErrorMessageProfilePic: errorMessage })

          } else {
            console.log('file is small enough', event.target.files[0].size)

            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ profilePicImage: e.target.result });
                console.log('how do i access the image', e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);
            // this.setState({ profilePicFile: event.target.files[0], profilePicHasChanged: true })
            this.saveFile(event.target.name, event.target.files[0])
          }
        }
      } else if (event.target.name === 'pictureURL') {
        //only covers Google Drive for now
        // https://drive.google.com/file/d/1x5MeSXzjC4dbmwfESGpspe7WnmkbNKLB/view?usp=sharing
        // https://drive.google.com/uc?export=view&id=1x5MeSXzjC4dbmwfESGpspe7WnmkbNKLB
        const rawPictureURL = event.target.value

        // .indexOf('<saml:Attribute Name="uid"')
        const startString = rawPictureURL.indexOf('/d/')
        const endString = rawPictureURL.indexOf('/view')

        if (startString > 0 && endString > 0 && rawPictureURL.includes('google')) {
          const pictureId = rawPictureURL.substring(startString + 3, endString)
          const pictureURL = 'https://drive.google.com/uc?export=view&id=' + pictureId

          this.setState({ rawPictureURL, pictureURL, textFormHasChanged: true })
          console.log('show pic values 1: ', rawPictureURL, pictureURL)
        } else {
          const pictureURL = ''
          this.setState({ rawPictureURL, pictureURL, textFormHasChanged: true })
          console.log('show pic values 2: ', rawPictureURL, pictureURL)
        }
      } else {
        console.log('there was an error in formChangeHandler')
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    saveFile(category, passedFile) {
      console.log('saveFile called', category, passedFile)

      const emailId = this.state.emailId
      const fileName = passedFile.name
      let originalName = category + '|' + emailId + '|' + fileName + '|' + new Date()

      let fileData = new FormData();
      // const fileName = 'profileImage'
      // const fileName = 'newFile'
      fileData.append('baseFileName', passedFile, originalName)

      fetch("/api/file-upload", {
          mode: 'no-cors',
          method: "POST",
          body: fileData
      }).then(function (res) {
        console.log('what is the profile pic response');
        if (res.ok) {

          if (category === 'profilePic') {
            const serverSuccessProfilePic = true
            const serverSuccessMessageProfilePic = category.charAt(0).toUpperCase() + category.slice(1) + ' saved successfully!'
            this.setState({ serverSuccessProfilePic, serverSuccessMessageProfilePic, profilePicFile: passedFile })
          } else if (category === 'resume') {
            const serverSuccessResume = true
            const serverSuccessMessageResume = category.charAt(0).toUpperCase() + category.slice(1) + ' saved successfully!'
            this.setState({ serverSuccessResume, serverSuccessMessageResume, resumeFile: passedFile, resumeName: fileName })
          }

          const self = this

          res.json()
          .then(function(data) {
            console.log('show data: ', data)
            let newFilePath = data.filePath
            console.log('show filePath: ', newFilePath)

            let existingFilePath = null
            if (category === 'profilePic') {
              if (self.state.pictureURL) {
                existingFilePath = self.state.pictureURL
              } else if (self.state.profilePicPath) {
                existingFilePath = self.state.profilePicPath
              }
            } else if (category === 'resume') {
              existingFilePath = self.state.resumeURLValue
            }

            // remove existing file
            if (existingFilePath && !self.state.allowMultipleFiles) {
              const deleteArray = existingFilePath.split("amazonaws.com/")
              console.log('show deleteArrary: ', deleteArray)
              const deleteKey = deleteArray[1].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
              console.log('show deleteKey: ', deleteKey)

              Axios.put('/api/file', { deleteKey })
              .then((response) => {
                console.log('tried to delete', response.data)
                if (response.data.success) {
                  //save values
                  console.log('File delete worked');

                  if (category === 'profilePic') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully',
                      profilePicPath: newFilePath, pictureURL: newFilePath
                    })
                  } else if (category === 'resume') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully', resumeURLValue: newFilePath
                    })
                  }

                } else {
                  console.error('there was an error saving the file');
                  self.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: response.data.message,
                  })
                }
              }).catch((error) => {
                  console.log('The saving did not work', error);
                  self.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: error,
                  })
              });
            }
          })

        } else if (res.status === 401) {
          //unauthorized
          this.setState({
              serverSuccessProfilePic: false,
              serverErrorMessageProfilePic: 'There was an error saving profile pic: Unauthorized save.'
          })
        } else if (res.status === 413) {
          //too large

          const errorMessage = 'payload too large'
          console.error(errorMessage);

          this.setState({
              serverSuccessProfilePic: false,
              serverErrorMessageProfilePic: errorMessage
          })
        }
      }.bind(this), function (e) {
        //there was an error
        this.setState({
            serverSuccessProfilePic: false,
            serverErrorMessageProfilePic: 'There was an error saving profile pic:' + e
        })
      }.bind(this));
    }

    render() {

      return (
        <div>

            <div className="clear" />

            <div>
              <div className="row-10">
                <div className="float-left right-padding">
                  <div>
                    <div>
                      <div className="upload-image">
                        <div className="spacer" />
                        <div className="relative-position">
                          <label for="profilePic" className="profile-pic-button">
                            <img src={
                              this.state.profilePicImage ? ( this.state.profilePicImage )
                              : this.state.pictureURL ? ( this.state.pictureURL )
                              : this.state.profilePicPath ? ( this.state.profilePicPath )
                              : ( addProfilePhotoIcon)}
                            alt="GC" for="profilePic" className={(this.state.profilePicImage || this.state.profilePicPath || this.state.pictureURL) ? "profile-image-largish" : "profile-image-largish-squared"}/>
                            {(this.state.profilePicImage || this.state.profilePicPath || this.state.pictureURL) && (
                              <div className="bottom-right-overlay">
                                <div className="bottom-right-subcontainer">
                                  <img src={addIcon} alt="Compass add icon" className="image-auto-18 center-item" />
                                </div>
                                <div className="clear" />
                              </div>
                            )}

                          </label>
                          <input type="file" id="profilePic" name="profilePic" onChange={this.formChangeHandler} accept="image/*" />
                        </div>

                        <div className="clear" />
                        <div className="spacer" />
                        <p className="description-text-2 full-width center-text">Dimensions: 150 x 150</p>
                        <div className="spacer" />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="calc-column-offset-180">
                  <div className="spacer" />
                  <div className="row-5">
                    <p className="heading-text-2">{this.state.firstNameValue} {this.state.lastNameValue}</p>
                  </div>
                  <div className="row-5">

                    <p>Student @ {this.state.schoolValue}{(this.state.gradYear) && " '" + this.state.gradYear.substring(2,4)}</p>
                    {(this.state.oauthUid) && (
                      <div>
                        <p className="error-color top-margin">UID: {this.state.oauthUid}</p>
                      </div>
                    )}
                  </div>

                  {(window.innerWidth > 768) && (
                    <div>
                      {(this.state.publicProfile) ? (
                        <div>

                          <div className="top-padding-20">
                            <Link className="background-button" to={this.state.prefix + '/profile/' + this.state.username} target="_blank">
                              <div className="slightly-rounded-corners cta-background-color white-text medium-shadow description-text-2 row-10 horizontal-padding">
                                Preview Profile
                              </div>
                            </Link>

                            {(this.state.publicProfileExtent === 'Public') && (
                              <Link className="background-button" to={'/' + this.state.username + '/profile'} target="_blank">
                                <div className="slightly-rounded-corners cta-border cta-text-color medium-shadow description-text-2 row-10 horizontal-padding left-margin">
                                  Share Public Profile
                                </div>
                              </Link>
                            )}
                          </div>

                        </div>
                      ) : (
                        <div>
                          <div className="top-padding-20 description-text-2">
                            <p>Your profile is currently private. To change your preferences, <Link to={this.state.prefix + '/edit-profile/public-preferences'}>click here</Link></p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                </div>
                <div className="clear" />

                {(window.innerWidth <= 768) && (
                  <div>
                    {(this.state.publicProfile) ? (
                      <div>
                        <div className="top-margin-20">
                          <Link className="background-button" to={this.state.prefix + '/profile/' + this.state.username} target="_blank">
                            <div className="slightly-rounded-corners cta-background-color white-text medium-shadow description-text-2 row-10 horizontal-padding right-margin">
                              Preview Profile in Portal
                            </div>
                          </Link>

                          {(this.state.publicProfileExtent === 'Public') && (
                            <div>
                              <div className="clear" />
                              <div className="spacer" />
                              <Link className="background-button" to={'/' + this.state.username + '/profile'} target="_blank">
                                <div className="slightly-rounded-corners cta-border cta-text-color medium-shadow description-text-2 row-10 horizontal-padding">
                                  Preview Public Profile
                                </div>
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        {(!this.state.remoteAuth) && (
                          <div className="top-padding-20 description-text-2">
                            <p>Your profile is currently private. To change your preferences, <Link to={this.state.prefix + '/edit-profile/public-preferences'}>click here</Link></p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                { (this.state.serverSuccessProfilePic) ? (
                  <p className="success-message">{this.state.serverSuccessMessageProfilePic}</p>
                ) : (
                  <p className="error-message">{this.state.serverErrorMessageProfilePic}</p>
                )}
              </div>

              <hr className="horizontal-margin-10" />

              <div className="row-15">
                <div className="row-15">
                  <p className="heading-text-4">Core Information</p>
                </div>

                <Link className="secret-link" to={this.state.prefix + '/edit-profile/basics'}>
                  <div className="row-15">
                    <div className="fixed-column-50">
                      <div className="mini-spacer" />
                      <img src={profileIconDark} className="image-auto-25 center-item" alt="GC" />
                    </div>
                    <div className="calc-column-offset-100-static">
                      <p className="heading-text-5">Basic Profile Info <label className="error-color bold-text">*</label></p>
                    </div>
                    <div className="fixed-column-50">
                      <img src={arrowIndicatorIcon} className="image-auto-20 center-item" alt="GC" />
                    </div>
                    <div className="clear" />
                  </div>
                </Link>

                <Link className="secret-link" to={this.state.prefix + '/assessments'}>
                  <div className="row-15">
                    <div className="fixed-column-50">
                      <div className="mini-spacer" />
                      <img src={abilitiesIconDark} className="image-auto-25 center-item" alt="GC" />
                    </div>
                    <div className="calc-column-offset-100-static">
                      <p className="heading-text-5">{(this.state.assessmentCount + this.state.assessmentCount > 0) && this.state.assessmentCount + ' '}Career Assessments <label className="error-color bold-text">*</label></p>
                    </div>
                    <div className="fixed-column-50">
                      <img src={arrowIndicatorIcon} className="image-auto-20 center-item" alt="GC" />
                    </div>
                    <div className="clear" />
                  </div>
                </Link>

                {(!window.location.pathname.includes('/advisor') || (window.location.pathname.includes('/advisor') && this.state.roleName === 'Mentor')) ? (
                  <Link className="secret-link" to={this.state.prefix + '/logs'}>
                    <div className="row-15">
                      <div className="fixed-column-50">
                        <div className="mini-spacer" />
                        <img src={logIconDark} className="image-auto-25 center-item" alt="GC" />
                      </div>
                      <div className="calc-column-offset-100-static">
                        <p className="heading-text-5">{(this.state.logs && this.state.logs.length > 0) && this.state.logs.length + ' '}Career Goals, Advising Sessions, & Other Logs</p>
                      </div>
                      <div className="fixed-column-50">
                        <img src={arrowIndicatorIcon} className="image-auto-20 center-item" alt="GC" />
                      </div>
                      <div className="clear" />
                    </div>
                  </Link>
                ) : (
                  <Link className="secret-link" to={this.state.prefix + '/sessions'}>
                    <div className="row-15">
                      <div className="fixed-column-50">
                        <div className="mini-spacer" />
                        <img src={logIconDark} className="image-auto-25 center-item" alt="GC" />
                      </div>
                      <div className="calc-column-offset-100-static">
                        <p className="heading-text-5">Advising Sessions</p>
                      </div>
                      <div className="fixed-column-50">
                        <img src={arrowIndicatorIcon} className="image-auto-20 center-item" alt="GC" />
                      </div>
                      <div className="clear" />
                    </div>
                  </Link>
                )}

                {(window.location.pathname.startsWith('/app')) && (
                  <Link className="secret-link" to={this.state.prefix + '/documents'}>
                    <div className="row-15">
                      <div className="fixed-column-50">
                        <div className="mini-spacer" />
                        <img src={resumeIconDark} className="image-auto-25 center-item" alt="GC" />
                      </div>
                      <div className="calc-column-offset-100-static">
                        <p className="heading-text-5">My Documents</p>
                      </div>
                      <div className="fixed-column-50">
                        <img src={arrowIndicatorIcon} className="image-auto-20 center-item" alt="GC" />
                      </div>
                      <div className="clear" />
                    </div>
                  </Link>
                )}

              </div>

              <div className="row-15">
                <div className="row-15">
                  <p className="heading-text-4">Other Profile Information</p>
                </div>

                <Link className="secret-link" to={this.state.prefix + '/favorites'}>
                  <div className="row-15">
                    <div className="fixed-column-50">
                      <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                      <img src={favoritesIconGrey} className="image-auto-20 center-item" alt="GC" />
                    </div>
                    <div className="calc-column-offset-100-static">
                      <p className="heading-text-5">{(this.state.favorites && this.state.favorites.length) && this.state.favorites.length + ' '}Favorites</p>
                    </div>
                    <div className="fixed-column-50">
                      <img src={arrowIndicatorIcon} className="image-auto-20 center-item" alt="GC" />
                    </div>
                    <div className="clear" />
                  </div>
                </Link>

                {(!window.location.pathname.includes('/advisor')) && (
                  <Link className="secret-link" to={this.state.prefix + '/completions'}>
                    <div className="row-15">
                      <div className="fixed-column-50">
                        <div className="mini-spacer" />
                        <img src={checkmarkDarkGreyIcon} className="image-auto-22 center-item" alt="GC" />
                      </div>
                      <div className="calc-column-offset-100-static">
                        <p className="heading-text-5">Enrollments & Completed Items</p>
                      </div>
                      <div className="fixed-column-50">
                        <img src={arrowIndicatorIcon} className="image-auto-20 center-item" alt="GC" />
                      </div>
                      <div className="clear" />
                    </div>
                  </Link>
                )}

                <Link className="secret-link" to={this.state.prefix + '/endorsements'}>
                  <div className="row-15">
                    <div className="fixed-column-50">
                      <div className="mini-spacer" />
                      <img src={endorsementIconDark} className="image-auto-25 center-item" alt="GC" />
                    </div>
                    <div className="calc-column-offset-100-static">
                      <p className="heading-text-5">{(this.state.endorsements && this.state.endorsements.length > 0) && this.state.endorsements.length + ' '}Competency Endorsements</p>
                    </div>
                    <div className="fixed-column-50">
                      <img src={arrowIndicatorIcon} className="image-auto-20 center-item" alt="GC" />
                    </div>
                    <div className="clear" />
                  </div>
                </Link>

                {(!window.location.pathname.includes('/advisor')) && (
                  <Link className="secret-link" to={this.state.prefix + '/matches'}>
                    <div className="row-15">
                      <div className="fixed-column-50">
                        <div className="mini-spacer" />
                        <img src={benchmarksIconDark} className="image-auto-22 center-item" alt="GC" />
                      </div>
                      <div className="calc-column-offset-100-static">
                        <p className="heading-text-5">My Top Matches</p>
                      </div>
                      <div className="fixed-column-50">
                        <img src={arrowIndicatorIcon} className="image-auto-20 center-item" alt="GC" />
                      </div>
                      <div className="clear" />
                    </div>
                  </Link>
                )}
              </div>

              {(!window.location.pathname.includes('/advisor') || (window.location.pathname.includes('/advisor') && this.state.roleName === 'Mentor')) && (
                <div className="row-15">
                  <div className="row-15">
                    <p className="heading-text-4">Ancillary Items</p>
                  </div>

                  <Link className="secret-link" to={this.state.prefix + '/edit-profile/public-preferences'}>
                    <div className="row-15">
                      <div className="fixed-column-50">
                        <div className="mini-spacer" />
                        <img src={publicIcon} className="image-auto-25 center-item" alt="GC" />
                      </div>
                      <div className="calc-column-offset-100-static">
                        <p className="heading-text-5">Profile Visibility Preferences</p>
                      </div>

                      <div className="fixed-column-50">
                        <img src={arrowIndicatorIcon} className="image-auto-20 center-item" alt="GC" />
                      </div>
                      <div className="clear" />

                      {(this.state.publicPreferencesErrorMessage && this.state.publicPreferencesErrorMessage !== '') && <p className="description-text-2 error-color half-bold-text left-padding">{this.state.publicPreferencesErrorMessage}</p>}
                      {(this.state.publicPreferencesSuccessMessage && this.state.publicPreferencesSuccessMessage !== '') && <p className="description-text-2 cta-color half-bold-text left-padding">{this.state.publicPreferencesSuccessMessage}</p>}

                    </div>
                  </Link>

                  <Link className="secret-link" to={this.state.prefix + '/my-social-posts/' + this.state.username}>
                    <div className="row-15">
                      <div className="fixed-column-50">
                        <div className="mini-spacer" />
                        <img src={socialIconDark} className="image-auto-22 center-item" alt="GC" />
                      </div>
                      <div className="calc-column-offset-100-static">
                        <p className="heading-text-5">My Social Posts</p>
                      </div>
                      <div className="fixed-column-50">
                        <img src={arrowIndicatorIcon} className="image-auto-20 center-item" alt="GC" />
                      </div>
                      <div className="clear" />
                    </div>
                  </Link>
                </div>
              )}

            </div>
        </div>
      )
    }
}

export default withRouter(EditProfile);
