import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Modal from 'react-modal';
import { CircularProgressbar } from 'react-circular-progressbar';;
import SubEditBenchmark from './EditBenchmark';
import SubCustomDropdown from '../Common/CustomDropdown';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

const noEditIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/no-edit-icon.png';
const exchangeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/exchange-icon-dark.png';
const editIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/edit-icon-dark.png';
const deleteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/delete-icon-dark.png';
const benchmarksIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-blue.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const pipelineIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/pipeline-icon-dark.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const filterIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon.png";
const filterIconSelected = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon-selected.png";
const matchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon.png";
const matchIconSelected = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon-selected.png';
const menuIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/menu-icon-dark.png";

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

const styles2 = {
    transition: 'all 0.75s ease',
    transform: 'translate(85%)'
};

class Benchmarks extends Component {
    constructor(props) {
        super(props)

        this.state = {
            opacity: 0,
            transform: 'translate(90%)',

            benchmarks: [],
            filteredBenchmarks: [],

            itemFilters: [],
            itemSorters: [],

            defaultFilterOption: 'All',
            defaultSortOption: 'No Sort Applied',
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.searchBenchmarks = this.searchBenchmarks.bind(this)
        this.filterResults = this.filterResults.bind(this)
        this.sortResults = this.sortResults.bind(this)
        this.renderBenchmarks = this.renderBenchmarks.bind(this)
        this.closeModal = this.closeModal.bind(this)

        this.calculateMatches = this.calculateMatches.bind(this)
        this.renderManipulators = this.renderManipulators.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)

        this.toggleMenu = this.toggleMenu.bind(this)
        this.menuButtonClicked = this.menuButtonClicked.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.subNavSelected !== prevProps.subNavSelected) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subBenchmarks', this.props)

      const org = this.props.activeOrg
      let accountCode = this.props.accountCode

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      let placementPartners = localStorage.getItem('placementPartners');

      let matchingCriteria = [
        { name: 'Work Function',  description: 'Scores careers / opportunities the highest that match your preferred work functions indicated in your work preferences assessment', value: 20 },
        { name: 'Industry',  description: 'Scores careers / opportunities the highest that match your preferred industries indicated in your work preferences assessment', value: 10 },
        { name: 'Location',  description: 'Scores careers / opportunities the highest that match your preferred location preferences indicated in your work preferences assessment', value: 0 },
        { name: 'Pay',  description: 'Scores high paying careers / opportunities highest', value: 0 },
        { name: 'Stability',  description: 'Scores careers / opportunities the highest with little variability in pay and job growth', value: 0 },
        { name: 'Interests',  description: 'Scores careers / opportunities the highest that match your interest assessment', value: 15 },
        { name: 'Personality',  description: 'Scores careers / opportunities the highest that match your personality assessment', value: 15 },
        { name: 'Values',  description: 'Scores careers / opportunities that the highest match your values assessment', value: 10 },
        { name: 'Skills',  description: 'Scores careers / opportunities that the highest match your skills assessment', value: 20 },
        { name: 'Education',  description: 'Scores careers / opportunities the highest that match your target education level indicated in work preferences', value: 0 },
        { name: 'Projects',  description: 'Scores careers / opportunities the highest that will likely value your project work the highest', value: 5 },
        { name: 'Experience',  description: 'Scores careers / opportunities the highest that will likely value your experience (that you like)', value: 5 },
      ]

      let useCases = [
        { name: 'Purpose', description: 'Optimize for opportunities that may give you the most purpose (e.g., solving a global problem, or large problem I am specifically interested in)', selected: false }, // interests
        { name: 'Stability', description: 'Optimize for opportunities where you will likely have the most job stability.', selected: false }, // job growth, company size
        { name: 'Pay', description: 'Optimize for opportunities where you will be paid the most in the short to medium term.', selected: false }, // pay
        { name: 'Recognition', description: 'Optimize for opportunities where you will be provided positive reinforcement and public praise for your accomplishments.', selected: false }, // social, artistic
        { name: 'Interests', description: 'Optimize for opportunities where you have the most long-term, genuine interest in.', selected: false }, // interests, personality
        { name: 'Competitive Advantage', description: 'Optimize for opportunities where you have the highest competitive advantage amongst candidates in your age group.', selected: false }, // skills
      ]

      const menuOptions = [
        { icon: exchangeIconDark, title: 'Duplicate benchmark', shorthand: 'duplicate' },
        { icon: editIconDark, title: 'Edit benchmark', shorthand: 'edit' },
        { icon: deleteIconDark, title: 'Delete benchmark', shorthand: 'delete' },
      ]

      this.setState({ org, accountCode, emailId: email, username, orgFocus, animating: true,
        matchingCriteria, useCases, menuOptions })

      if (this.props.benchmarks && this.props.benchmarks.length > 0) {
        const benchmarks = this.props.benchmarks
        const filteredBenchmarks = this.props.benchmarks
        this.setState({ benchmarks, filteredBenchmarks, animating: false });
      } else {
        let benchmarkQueryParams = {}
        if (window.location.pathname === '/benchmarks') {

          benchmarkQueryParams = { pathwayLevel: true }

        } else if (window.location.pathname.includes('/app/')) {
          benchmarkQueryParams = { pathwayLevel: true }
          if (org) {

            benchmarkQueryParams['orgCode'] = org
          }
          if (placementPartners) {
            benchmarkQueryParams['orgCode'] = null
            benchmarkQueryParams['orgCodes'] = [org].concat(placementPartners)
          }
        } else {

          let orgCode = null
          let orgCodes = null

          if (this.props.source === 'Admin') {
            orgCode = org
            accountCode = null
            if (placementPartners) {
              orgCode = null
              orgCodes = [org].concat(placementPartners)
            }
          } else if (this.props.source === 'Employer') {
            accountCode = this.props.accountCode
            orgCode = org
          } else if (window.location.pathname.includes('/advisor')) {
            if (placementPartners) {
              orgCode = null
              orgCodes = [org,'general'].concat(placementPartners)
            }
          }

          benchmarkQueryParams = { orgCode, orgCodes, orgFocus, accountCode }

        }
        // console.log('show the query again: ', benchmarkQueryParams)
        Axios.get('/api/benchmarks', { params: benchmarkQueryParams })
        .then((response) => {
          console.log('Benchmarks query within sub attempted', response.data);

          if (response.data.success && response.data.benchmarks) {
            console.log('benchmark query worked')

            const benchmarks = response.data.benchmarks
            const filteredBenchmarks = benchmarks

            this.setState({ benchmarks, filteredBenchmarks, animating: false });

          } else {
            console.log('benchmark query did not work', response.data.message)
            this.setState({ animating: false })
          }

        }).catch((error) => {
            console.log('Benchmark query did not work for some reason', error);
        });
      }

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried');

        if (response.data.success) {
          console.log('Work options query succeeded')

          const functionOptions = response.data.workOptions[0].functionOptions
          const industryOptions = response.data.workOptions[0].industryOptions

          //filters
          const defaultFilterOption = this.state.defaultFilterOption
          const functionFilterOptions = [defaultFilterOption].concat(functionOptions.slice(1, functionOptions.length))
          const industryFilterOptions = [defaultFilterOption].concat(industryOptions.slice(1, industryOptions.length))
          const binaryFilterOptions = [defaultFilterOption].concat(['Employers Only','Exclude Employers'])

          let itemFilters = [
            { name: 'Work Function', value: defaultFilterOption, options: functionFilterOptions },
            { name: 'Industry', value: defaultFilterOption, options: industryFilterOptions },
            { name: 'Organization Type', value: defaultFilterOption, options: binaryFilterOptions },
          ]

          const itemSorters = []

          this.setState({ itemFilters, itemSorters });
        }
      });

      Axios.get('/api/users/profile/details', { params: { email } })
      .then((response) => {
        console.log('User details query attempted', response.data);

        if (response.data.success) {
           console.log('successfully retrieved user details')

           Axios.get('/api/assessment/results', { params: { emailId: email } })
            .then((response2) => {
              console.log('query for assessment results worked');

              if (response2.data.success) {

                console.log('actual assessment results', response2.data)

                let profile = response.data.user
                profile['workPreferences'] = response2.data.results.workPreferenceAnswers
                profile['interests'] = response2.data.results.interestScores
                profile['personality'] = response2.data.results.personalityScores
                profile['skills'] = response2.data.results.newSkillAnswers
                profile['gravitateValues'] = response2.data.results.topGravitateValues
                profile['employerValues'] = response2.data.results.topEmployerValues

                // let matchingCriteria = this.state.matchingCriteria
                if (response.data.user.matchingPreferences && response.data.user.matchingPreferences.length > 0) {
                  matchingCriteria = response.data.user.matchingPreferences
                }
                if (response.data.user.matchingUseCases && response.data.user.matchingUseCases.length > 0) {
                  useCases = response.data.user.matchingUseCases
                }

                this.setState({ profile, matchingCriteria, useCases })

                // console.log('show pageSource here: ', this.props.pageSource)
                // if (this.props.pageSource === 'Goal') {
                //   this.calculateMatches(true, true, false)
                // }

              } else {
                console.log('no assessment results', response2.data)

                // let matchingCriteria = this.state.matchingCriteria
                if (response.data.user.matchingPreferences && response.data.user.matchingPreferences.length > 0) {
                  matchingCriteria = response.data.user.matchingPreferences
                }
                if (response.data.user.matchingUseCases && response.data.user.matchingUseCases.length > 0) {
                  useCases = response.data.user.matchingUseCases
                }
                this.setState({ matchingCriteria, useCases })
              }

          }).catch((error) => {
              console.log('query for assessment results did not work', error);
              // let matchingCriteria = this.state.matchingCriteria
              if (response.data.user.matchingPreferences && response.data.user.matchingPreferences.length > 0) {
                matchingCriteria = response.data.user.matchingPreferences
              }
              if (response.data.user.matchingUseCases && response.data.user.matchingUseCases.length > 0) {
                useCases = response.data.user.matchingUseCases
              }
              this.setState({ matchingCriteria, useCases })
          })

           // Axios.get('/api/org', { params: { orgCode: activeOrg } })
           // .then((response2) => {
           //   console.log('Org info query attempted within nested opportunities', response2.data);
           //
           //   if (response2.data.success) {
           //     console.log('org info query worked!')
           //
           //     if (response2.data.orgInfo.placementPartners) {
           //       placementPartners = response2.data.orgInfo.placementPartners
           //     }
           //
           //   } else {
           //     console.log('there was an error findign the org')
           //
           //   }
           // }).catch((error) => {
           //    console.log('Error finding the org', error);
           // });

        } else {
         console.log('no user details data found', response.data.message)
        }

      }).catch((error) => {
         console.log('User details query did not work', error);
      });

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'searchString') {
        this.setState({ [event.target.name]: event.target.value, animating: true })
        this.searchBenchmarks(event.target.value)
      } else if (event.target.name.includes('filter|')) {

        let type = 'All'
        let index = 0

        let itemFilters = this.state.itemFilters

        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        for (let i = 1; i <= itemFilters.length; i++) {
          if (itemFilters[i - 1].name === field) {
            itemFilters[i - 1]['value'] = event.target.value
            index = i - 1
          }
        }

        //reset everything
        let searchString = ''

        // let allSorters = this.state.allSorters
        // for (let i = 1; i <= allSorters.length; i++) {
        //   allSorters[i - 1]['value'] = this.state.defaultSortOption
        // }

        this.setState({ itemFilters, animating: true, searchString })

        this.filterResults(this.state.searchString, event.target.value, itemFilters, index, false, type)

      } else if (event.target.name.includes('sort|')) {

        let type = 'All'
        let itemSorters = this.state.itemSorters
        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        // let index = 0
        for (let i = 1; i <= itemSorters.length; i++) {
          if (itemSorters[i - 1].name === field) {
            itemSorters[i - 1]['value'] = event.target.value
            // index = i - 1
          }
        }

        for (let i = 1; i <= itemSorters.length; i++) {
          if (itemSorters[i - 1].name !== field) {
            itemSorters[i - 1]['value'] = this.state.defaultSortOption
          }
        }

        let searchString = ''
        this.setState({ searchString, itemSorters, animating: true })

        this.sortResults(event.target.value, field, type)
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchBenchmarks(searchString) {
      console.log('searchBenchmarks called ', searchString)

      let benchmarkQueryParams = {}
      if (window.location.pathname === '/benchmarks') {
        benchmarkQueryParams = { searchString, pathwayLevel: true }
      } else if (window.location.pathname.includes('/app/')) {
        const orgCode = null
        const accountCode = this.state.accountCode
        benchmarkQueryParams = { searchString, orgCode, accountCode, pathwayLevel: true }
      } else {
        const orgCode = this.state.org
        const accountCode = this.state.accountCode
        benchmarkQueryParams = { searchString, orgCode, accountCode }
      }

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/benchmarks/search', { params: benchmarkQueryParams })
        .then((response) => {
          console.log('Benchmarks search query attempted', response.data);

            if (response.data.success) {
              console.log('benchmark search query worked')

              const filteredBenchmarks = response.data.benchmarks
              self.setState({ filteredBenchmarks, animating: false });

            } else {
              console.log('benchmark search query did not work', response.data.message)
              self.setState({ filteredBenchmarks: this.state.benchmarks, animating: false })
            }

        }).catch((error) => {
            console.log('Benchmark search query did not work for some reason', error);
            self.setState({ animating: false, errorMessage: error.toString() })
        });

      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    filterResults(searchString, filterString, filters, index, search, type) {
      console.log('filterResults called', searchString, filterString, filters, index, search, type)

      // let benchmarks = this.state.benchmarks

      const orgCode = null
      const pathwayLevel = true

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/benchmarks/filter', { params: { filters, orgCode, pathwayLevel } })
        .then((response) => {
          console.log('Benchmarks filter query attempted', response.data);

            if (response.data.success) {
              console.log('benchmark filter query worked')

              const filteredBenchmarks = response.data.benchmarks
              self.setState({ filteredBenchmarks, animating: false });

            } else {
              console.log('benchmark filter query did not work', response.data.message)
              self.setState({ filteredBenchmarks: this.state.benchmarks, animating: false })
            }

        }).catch((error) => {
            console.log('Benchmark filter query did not work for some reason', error);
            self.setState({ animating: false, errorMessage: error.toString() })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    sortResults(sortString, sortName) {
      console.log('sortResults called', sortString, sortName)

      let benchmarks = this.state.benchmarks

      if (sortName === 'Match') {
        console.log('in benchmark match')

      } else {

        Axios.put('/api/benchmarks/sort', { sortString, benchmarks, sortName })
        .then((response) => {
          console.log('Benchmarks sort query attempted', response.data);

            if (response.data.success) {
              console.log('benchmarks sort query worked')

              // THIS IS SUPER UNNECESSARY
              const filterCriteriaArray = null
              const sortCriteriaArray = response.data.sortCriteriaArray

              const filteredBenchmarks = response.data.benchmakrs
              this.setState({ filteredBenchmarks, animating: false, filterCriteriaArray, sortCriteriaArray })

            } else {
              console.log('benchmark sort query did not work', response.data.message)
              this.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('Benchmark sort query did not work for some reason', error);
            this.setState({ animating: false })
        });
      }
    }

    renderBenchmarks() {
      console.log('renderBenchmarks called')

      let rows = [];

      if (this.state.filteredBenchmarks && this.state.filteredBenchmarks.length > 0 ) {

        for (let i = 1; i <= this.state.filteredBenchmarks.length; i++) {
          // console.log(i);

          const index = i - 1
          let title = this.state.filteredBenchmarks[i - 1].title
          if ((window.location.pathname.includes('/app/') || window.location.pathname === '/benchmarks') && this.state.filteredBenchmarks[i - 1].pathway) {
            title  = this.state.filteredBenchmarks[i - 1].pathway
          }

          let subtitle = this.state.filteredBenchmarks[i - 1].jobFunction
          if (this.state.filteredBenchmarks[i - 1].jobTitle) {
            subtitle = subtitle + ' | ' + this.state.filteredBenchmarks[i - 1].jobTitle
          }

          if (this.state.filteredBenchmarks[i - 1].accountCode) {
            subtitle = subtitle + ' | Employer-Generated'
          }

          let pathname = ''
          let showModal = false
          let passedState = { selectedTemplate: this.state.filteredBenchmarks[i - 1] }
          if (this.props.source === 'Admin') {
            pathname = '/organizations/' + this.state.org + '/benchmarks/' + this.state.filteredBenchmarks[i - 1]._id
            passedState = { selectedTemplate: this.state.filteredBenchmarks[i - 1] }
          } else if (this.props.source === 'Employer') {
            pathname = '/employers/' + this.props.accountCode + '/benchmarks/' + this.state.filteredBenchmarks[i - 1]._id
            passedState = { selectedTemplate: this.state.filteredBenchmarks[i - 1] }
          } else if (window.location.pathname === '/benchmarks') {
            pathname = '/employers/pages/' + this.state.filteredBenchmarks[i - 1].accountCode + '/benchmarks/' + this.state.filteredBenchmarks[i - 1]._id
            passedState = { selectedTemplate: this.state.filteredBenchmarks[i - 1] }
          } else if (window.location.pathname.includes('/walkthrough')) {
            showModal = true
            passedState = { selectedTemplate: this.state.filteredBenchmarks[i - 1] }
          } else if (window.location.pathname.includes('/app/')) {
            pathname = '/app/benchmarks/' + this.state.filteredBenchmarks[i - 1]._id
            passedState = { selectedTemplate: this.state.filteredBenchmarks[i - 1] }
          } else if (window.location.pathname.includes('/advisor/')) {
            pathname = '/advisor/benchmarks/' + this.state.filteredBenchmarks[i - 1]._id
            passedState = { selectedTemplate: this.state.filteredBenchmarks[i - 1] }
          }

          let midClassName = "calc-column-offset-150 left-padding-20"
          if ((this.state.filteredBenchmarks[i - 1].pathwayLevel && !window.location.pathname.includes('/pathways')) || this.state.filteredBenchmarks[i - 1].orgCode !== this.state.org) {
            midClassName = "calc-column-offset-190 left-padding-20"
          }

          const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primaryColor');
          // console.log('show matchScore -----: ', matchScore, primaryColor)

          rows.push(
            <div key={i} >
              <Link className="secret-link" onClick={(showModal) ? () => this.setState({ modalIsOpen: true, showEditBenchmark: true, selectedBenchmark: this.state.filteredBenchmarks[index] }) : () => console.log('')} to={pathname} state={passedState}>
                <span className="fixed-column-60">
                  <div className="spacer" />

                  {(this.state.matchScores && this.state.matchScores[i - 1]) ? (
                    <div className="padding-10">
                      <CircularProgressbar
                        value={this.state.matchScores[i - 1]}
                        text={`${this.state.matchScores[i - 1]}%`}
                        styles={{
                          path: { stroke: `${primaryColor}, ${this.state.matchScores[i - 1] / 100}` },
                          text: { fill: primaryColor, fontSize: '26px' },
                          trail: { stroke: 'transparent' }
                        }}
                      />
                    </div>
                  ) : (
                    <img src={(this.state.filteredBenchmarks[i - 1].imageURL) ? this.state.filteredBenchmarks[i - 1].imageURL : benchmarksIconBlue} alt="GC" className="image-50-fit center-horizontally"/>
                  )}
                </span>
                <span className={midClassName}>
                  <div className="spacer" />
                  <p className="heading-text-5">{title}</p>
                  <p className="description-text-2 top-padding-5">{subtitle}</p>
                </span>
                {(this.state.filteredBenchmarks[i - 1].pathwayLevel || this.state.filteredBenchmarks[i - 1].orgCode !== this.state.org) && (
                  <span className="fixed-column-40 top-margin">
                    {(this.state.filteredBenchmarks[i - 1].pathwayLevel) && (
                      <div>
                        <img src={pipelineIconDark} alt="GC" className="image-auto-22 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This is a pathway-level benchmark"/>
                      </div>
                    )}

                    {(this.state.filteredBenchmarks[i - 1].orgCode !== this.state.org && !window.location.pathname.includes('/app/') && window.location.pathname !== '/benchmarks') && (
                      <div>
                        <img src={noEditIcon} alt="GC" className="image-auto-22 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This entry can't be edited because it is being shared from a different organization"/>
                      </div>
                    )}
                    <ReactTooltip id="tooltip-placeholder-id" />
                  </span>
                )}
                {(!window.location.pathname.includes('/app/') && window.location.pathname !== '/benchmarks') && (
                  <div className="fixed-column-50 left-padding top-margin-5 relative-position">
                    <button className="background-button clear-margin clear-padding light-background row-10 horizontal-padding-7 standard-border slightly-rounded-corners" onClick={(e) => this.toggleMenu(e,i - 1)}>
                      <img src={menuIconDark} alt="GC" className="image-15-auto"/>
                    </button>

                    {(this.state.filteredBenchmarks[i - 1].showMenu) && (
                      <div className="menu-bottom-right top-margin">
                        {this.state.menuOptions.map((item,optionIndex) =>
                          <div key={item.title}>
                            {((item.title.includes('Delete') && this.state.filteredBenchmarks[i - 1].orgCode === this.state.org) || !item.title.includes('Delete')) && (
                              <div>
                                {(item.title === 'Delete benchmark' && this.state.filteredBenchmarks[i - 1].confirmDelete) ? (
                                  <div>
                                    <p className="description-text-2 error-color bold-text top-margin">You sure?</p>
                                    <button className="btn btn-squarish error-background-color right-margin clear-border description-text-3" onClick={() => this.deleteItem(i - 1)}>Confirm</button>
                                    <button className="btn btn-squarish white-background cta-color description-text-3" onClick={(e) => this.menuButtonClicked(e, item.shorthand,i - 1)}>Cancel</button>
                                  </div>
                                ) : (
                                  <button className="background-button row-10 clear-margin left-text full-width" onClick={(e) => this.menuButtonClicked(e, item.shorthand,i - 1)}>
                                    <div className="fixed-column-25">
                                      <img src={item.icon} alt="GC" className="image-auto-14 top-margin-3 contain-object-fit"/>
                                    </div>
                                    <div className="calc-column-offset-25">
                                      <p className={(item.title === 'Delete benchmark') ? "description-text-2 error-color bold-text" : "description-text-2"}>{item.title}</p>
                                    </div>
                                    <div className="clear" />
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                <span className="fixed-column-40 top-margin">
                  <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22 pin-right"/>
                </span>
                <div className="clear" />
              </Link>

              {(this.state.filteredBenchmarks[i - 1].successMessage) && (
                <div className="row-5 left-padding">
                  <p className="description-text-2 cta-color full-width">{this.state.filteredBenchmarks[i - 1].successMessage}</p>
                </div>
              )}

              <div className="spacer" /><div className="spacer" /><div className="spacer" />
              <hr />
              <div className="spacer" /><div className="spacer" />
            </div>
          )

        }

      } else {
        //show empty state screen

      }

      return rows;
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showEditBenchmark: false, selectedBenchmark: null, showMatchingCriteria: false })
    }

    renderManipulators(type) {
      console.log('renderManipulators called')

      if (type === 'filter') {
        let filters = this.state.itemFilters

        if (filters) {

          let rows = []
          for (let i = 1; i <= filters.length; i++) {
            rows.push(
              <div key={filters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={filters[i - 1].name} name={"filter|" + filters[i - 1].name} value={filters[i - 1].value} options={filters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      } else if (type === 'sort') {
        let sorters = this.state.itemSorters

        if (sorters) {

          let rows = []
          for (let i = 1; i <= sorters.length; i++) {
            rows.push(
              <div key={sorters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={sorters[i - 1].name} name={"sort|" + sorters[i - 1].name} value={sorters[i - 1].value} options={sorters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} forceArrow={true} />
              </div>
            )
          }

          return rows

        }
      }
    }

    calculateMatches(matchingView, calcMatches, newPreferences) {
      console.log('calculateMatches called', matchingView, calcMatches, newPreferences)

      if (matchingView) {

        this.setState({ matchingView: true, errorMessage: null })

        if (this.state.benchmarks) {

          if (!this.state.profile) {
            this.setState({ animating: false, matchingView: true, errorMessage: 'Please take career assessments before receiving personalized matches' })
          } else {
            this.setState({ animating: true, modalIsOpen: false })

            // orgCode: queryOrgCode, placementPartners, postType, postTypes, pathway
            const profile = this.state.profile
            const orgCode = this.state.org
            const placementPartners = this.state.placementPartners

            const pathway = this.state.pathway

            let postType = this.state.postType
            let postTypes = this.state.postTypes
            postType = null
            postTypes = null
            const pathwayLevel = true

            const matchingCriteria = this.state.matchingCriteria
            const useCases = this.state.useCases

            const self = this

            function officiallyCalculate() {
              console.log('officiallyCalculate called')

              const hourlyPayOptions = self.state.hourlyPayOptions
              const annualPayOptions = self.state.annualPayOptions
              const employeeCountOptions = self.state.employeeCountOptions
              const hourOptions = self.state.hourOptions

              Axios.put('/api/benchmarks/matches', {
                profile, orgCode, placementPartners, postType, postTypes, pathway, matchingCriteria, useCases,
                annualPayOptions, hourlyPayOptions, employeeCountOptions, hourOptions, pathwayLevel })
              .then((response) => {
                console.log('Benchmarks matches attempted', response.data);

                  if (response.data.success) {
                    console.log('benchmarks match query worked')

                    let matchScores = response.data.matchScores
                    const benchmarks = response.data.benchmarks
                    const filteredBenchmarks = response.data.benchmarks

                    self.setState({ animating: false, matchingView: true, matchScores, benchmarks, filteredBenchmarks })

                  } else {
                    console.log('Benchmarks match did not work', response.data.message)
                    self.setState({ animating: false, matchingView: true, errorMessage: 'there was an error: ' + response.data.message })
                  }

              }).catch((error) => {
                  console.log('Benchmark match did not work for some reason', error);
                  self.setState({ animating: false, matchingView: true, errorMessage: 'there was an error' })
              });
            }

            if (newPreferences) {
              console.log('new preferences stated')

              // totalPercent must equal 100
              if (this.state.totalPercent !== 100) {
                this.setState({ animating: false, modalIsOpen: true, matchingView: true, errorMessage: 'The sum of matching criteria weights must equal 100' })
              } else {
                const emailId = this.state.emailId
                const matchingPreferences = matchingCriteria
                const matchingUseCases = useCases
                const updatedAt = new Date()

                Axios.post('/api/users/profile/details', {
                  emailId, matchingPreferences, matchingUseCases, updatedAt })
                .then((response) => {

                  if (response.data.success) {
                    console.log('successfully saved new preferences')
                    officiallyCalculate()

                  } else {
                    console.log('request was not successful')
                    officiallyCalculate()
                  }
                }).catch((error) => {
                    console.log('Saving the info did not work', error);
                    officiallyCalculate()
                });
              }
            } else {
              officiallyCalculate()
            }
          }
        }
      } else {
        this.setState({ matchingView: false, errorMessage: null })
      }
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    toggleMenu(e, index) {
      console.log('toggleMenu called')

      e.stopPropagation()
      e.preventDefault()

      let filteredBenchmarks = this.state.filteredBenchmarks
      if (this.state.filteredBenchmarks[index].showMenu) {
        filteredBenchmarks[index]['showMenu'] = false
      } else {
        filteredBenchmarks[index]['showMenu'] = true
      }

      this.setState({ filteredBenchmarks })

    }

    menuButtonClicked(e, action, index) {
      console.log('menuButtonClicked called', action, index)

      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }

      if (action === 'duplicate') {
        let pathname = '/organizations/' + this.state.org + '/benchmarks/add'
        if (window.location.pathname.includes('/advisor')) {
          pathname = '/advisor/benchmarks/add'
        }

        let selectedTemplate = this.state.filteredBenchmarks[index]
        this.props.navigate(pathname, { state: { selectedTemplate, duplicate: true }})
      } else if (action === 'edit') {
        let pathname = '/organizations/' + this.state.org + '/benchmarks/' + this.state.filteredBenchmarks[index]._id
        if (window.location.pathname.includes('/advisor')) {
          pathname = '/advisor/benchmarks/' + this.state.filteredBenchmarks[index]._id
        }
        this.props.navigate(pathname, { state: { selectedTemplate: this.state.filteredBenchmarks[index] }})

      } else if (action === 'delete') {
        let filteredBenchmarks = this.state.filteredBenchmarks
        if (filteredBenchmarks[index].confirmDelete) {
          filteredBenchmarks[index]['confirmDelete'] = false
        } else {
          filteredBenchmarks[index]['confirmDelete'] = true
        }
        this.setState({ filteredBenchmarks })

      }
    }

    deleteItem(index) {
      console.log('deleteItem called', index)

      let filteredBenchmarks = this.state.filteredBenchmarks
      const _id = filteredBenchmarks[index]._id

      Axios.delete('/api/benchmarks/' + _id)
      .then((response) => {
        console.log('tried to  delete', response.data)
        if (response.data.success) {
          //save values
          console.log('Benchmark delete worked')

          filteredBenchmarks.splice(index,1)
          this.setState({ filteredBenchmarks, isSaving: false })

        } else {
          console.error('there was an error deleting the benchmark');

          filteredBenchmarks[index]['errorMessage'] = response.data.message
          this.setState({ filteredBenchmarks, isSaving: false })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          filteredBenchmarks[index]['errorMessage'] = error.toString()
          this.setState({ filteredBenchmarks, isSaving: false })
      });
    }

    render() {

      let cardClass = "floating-container card top-margin-2-percent right-margin-2-percent"
      let cardStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform}
      let searchClass = "floating-container card top-margin-2-percent right-margin-2-percent clear-padding"
      let searchStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform}
      if (window.location.pathname === '/benchmarks') {
        cardClass = "card"
        cardStyle = {}
        searchStyle = {}
      } else if (window.location.pathname.includes('/app/')) {
        cardClass = "card"
        cardStyle = {}
        searchStyle = {}
      } else if (window.location.pathname.includes('/walkthrough')) {
        cardClass = "top-margin-30"
        cardStyle = {}
        searchStyle = {}
      } else if (this.props.renderSideNav === false || (window.location.pathname.startsWith('/advisor') && toggleVars.hideSideNav)) {
        cardClass = "white-background width-70-percent vertical-margin-2-percent padding-40 medium-shadow max-width-1400 center-horizontally"
        cardStyle = {}
        searchClass = "max-width-1400 width-70-percent center-horizontally"
        searchStyle = {}
      } else if (window.location.pathname.startsWith('/organizations') && toggleVars.hideAdminSideNav) {
        cardClass = "card width-70-percent vertical-margin-2-percent max-width-1400 center-horizontally"
        cardStyle = {}
        searchClass = "max-width-1400 width-70-percent center-horizontally"
        searchStyle = {}
      }

      let addBenchmarkPath = ''
      if (this.props.source === 'Admin') {
        addBenchmarkPath = '/organizations/' + this.props.activeOrg + '/benchmarks/add'
      } else if (this.props.source === 'Employer') {
        addBenchmarkPath = '/employers/' + this.props.accountCode + '/benchmarks/add'
      } else if (window.location.pathname.includes('/advisor')) {
        addBenchmarkPath = '/advisor/benchmarks/add'
      }

      let mainWidth = "calc-column-offset-65"

      return (
          <div>
            {(window.location.pathname.includes('/app')) && (
              <div>
                <div>
                  <div className={(this.props.pageSource === 'landingPage') && "row"}>
                    <div className={(this.props.pageSource === 'landingPage') && "full-width white-background"}>
                      {(this.state.matchingView) ? (
                        <div>
                          <div className="search-icon-container top-margin-negative-3 full-width" style={(this.state.matchingView) ? { ...styles2, position: 'absolute' } : { }}>
                            <button type="button" className="background-button float-left" onClick={() => this.calculateMatches(false, false, false)}>
                              <img src={matchIconSelected} alt="GC" className="image-auto-30 right-margin" />
                            </button>
                          </div>
                          <div className="full-width">
                            <button type="button" className="background-button float-left" onClick={() => this.setState({ modalIsOpen: true, showMatchingCriteria: true })}>
                              <div className="float-left right-margin slightly-rounded-corners row-5 horizontal-padding cta-background-color cta-border white-text">
                                <p>Adjust</p>
                              </div>
                            </button>
                            <button type="button" className="background-button float-left" onClick={() => this.calculateMatches(false, false, false)}>
                              <div className="float-left right-padding standard-border slightly-rounded-corners row-5 horizontal-padding">
                                <p>Close</p>
                              </div>
                            </button>

                            <div className="clear" />

                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                          </div>
                        </div>
                      ) : (
                        <div>
                          {(this.props.pageSource === 'landingPage') ? (
                            <div className="filter-field-search full-width white-background">
                              <div className="search-icon-container">
                                <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5"/>
                              </div>
                              <div className="filter-search-container calc-column-offset-100-static">
                                <input type="text" className="text-field clear-border" placeholder={"Search..."} name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
                              </div>
                              <div className="search-icon-container top-margin-negative-3">
                                <button type="button" className="background-button" onClick={() => this.toggleSearchBar('show')}>
                                  <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                                </button>
                              </div>

                            </div>
                          ) : (
                            <div className="full-width row-5 white-background standard-border medium-shadow">
                              <div className={(this.state.matchingView) ? "float-left full-width" : "fixed-column-50 center-item"} style={(this.state.matchingView) ? { ...styles2, position: 'absolute' } : { }}>
                                <button type="button" className={(this.state.matchingView) ? "background-button float-left" : "background-button full-space bottom-padding"} onClick={() => this.calculateMatches(true, true, false)}>
                                  <img src={(this.state.matchingView) ? matchIconSelected : matchIcon} alt="GC" className="image-auto-30 right-margin center-item top-margin" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Calculate Matches"/>
                                </button>
                              </div>
                              <div className="filter-field-search clear-margin calc-column-offset-100-static white-background clear-border">
                                <div className="search-icon-container">
                                  <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5"/>
                                </div>
                                <div className="filter-search-container calc-column-offset-100-static">
                                  <input type="text" className="text-field clear-border" placeholder={"Search..."} name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
                                </div>
                              </div>
                              <div className="fixed-column-50 center-item">
                                <button type="button" className="background-button full-space bottom-padding" onClick={() => this.toggleSearchBar('show')}>
                                  <img src={(this.state.showingSearchBar) ? filterIconSelected : filterIcon} alt="GC" className="image-auto-25 center-item top-margin" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Filter Results"/>
                                </button>
                              </div>
                              <div className="clear" />
                            </div>
                          )}
                          <ReactTooltip id="tooltip-placeholder-id" />

                        </div>
                      )}
                    </div>
                  </div>

                  {(this.state.showingSearchBar) && (
                    <div className={"row box-container-1 white-background"}>
                      <div>

                        <div>
                          <div className="spacer" /><div className="spacer" />
                          <hr />
                          <div className="spacer" /><div className="spacer" />
                        </div>

                        <p>Filter</p>
                        <div className="half-spacer" />
                        {(this.renderManipulators('filter'))}

                        <div className="clear" />
                        <div className="spacer" />
                        <hr />
                        <div className="spacer" /><div className="spacer" />
                        <p>Sort</p>
                        <div className="half-spacer" />
                        {(this.renderManipulators('sort'))}
                        <div className="clear" />

                      </div>
                    </div>
                  )}

                  <div className="spacer" /><div className="spacer" />
                </div>
              </div>
            )}
            {(window.location.pathname !== '/benchmarks' && !window.location.pathname.includes('/walkthrough') && !window.location.pathname.includes('/app/')) && (
              <div className={cardClass} style={cardStyle}>
                <div className={mainWidth}>
                  <p className="heading-text-2">{(this.state.filteredBenchmarks && this.state.filteredBenchmarks.length > 0) && this.state.filteredBenchmarks.length + " "}Benchmarks</p>
                </div>

                {(window.location.pathname !== '/benchmarks') && (
                  <div>
                    <div className="fixed-column-40 top-padding-5">
                      <button className="background-button" onClick={(this.state.showSearchBar) ? () => this.setState({ showSearchBar: false }) : () => this.setState({ showSearchBar: true })}>
                        <img src={searchIcon} alt="GC" className="image-auto-25"/>
                      </button>
                    </div>
                    <div className="fixed-column-25 top-padding-5">
                      <Link to={addBenchmarkPath}>
                        <img src={addIcon} alt="GC" className="image-auto-25"/>
                      </Link>
                    </div>

                  </div>
                )}
                <div className="clear" />

                {(window.location.pathname.includes('/employers')) ? (
                  <div>
                    <p className="description-text-color top-padding">Specify benchmarks to build talent pipelines for each of your departments or attach to specific opportunities. Educators, workforce professionals, and career-seekers in diverse communities will use this to prepare for roles at your company. Our algorithm ties to these benchmarks, providing a pathway for candidates to prepare.</p>
                  </div>
                ) : (
                  <p className="description-text-2 row-5 standard-color">Create a point system that acts as a grading rubric for the match score calculated for pathways, events, projects, and work opportunities.</p>
                )}
              </div>
            )}

            {(!window.location.pathname.includes('/walkthrough')) && (
              <div>
                {(window.location.pathname === '/benchmarks') ? (
                  <div>
                    <div className="row bottom-margin-20">
                      <div className="filter-field-search full-width white-background">
                        <div className="search-icon-container">
                          <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5" />
                        </div>
                        <div className="filter-search-container calc-column-offset-100-static">
                          <input type="text" className="text-field clear-border" placeholder="Search..." name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
                        </div>
                        {/*
                        <div className="search-icon-container top-margin-negative-3">
                          <button type="button" className="background-button" onClick={() => this.toggleSearchBar('show')}>
                            <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                          </button>
                        </div>*/}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {(this.state.showSearchBar) && (
                      <div className="bottom-padding-40">
                        <div className={searchClass} style={searchStyle}>
                          <div className="filter-field-search full-width white-background clear-margin">
                            <div className="search-icon-container">
                              <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5" />
                            </div>
                            <div className="filter-search-container calc-column-offset-100-static">
                              <input type="text" className="text-field clear-border" placeholder="Search..." name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
                            </div>
                            {/*
                            <div className="search-icon-container top-margin-negative-3">
                              <button type="button" className="background-button" onClick={() => this.toggleSearchBar('show')}>
                                <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                              </button>
                            </div>*/}
                          </div>
                        </div>
                      </div>
                    )}

                    {(this.props.renderSideNav === false) && (
                      <div className="clear" />
                    )}

                  </div>
                )}
              </div>
            )}

            {(this.state.animating) ? (
              <div className="flex-container flex-center full-space white-background">
                <div>
                  <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <p className="center-text cta-color bold-text">Calculating results...</p>

                </div>
              </div>
            ) : (
              <div className={cardClass} style={cardStyle}>
                {this.renderBenchmarks()}
              </div>
            )}

            <div className="clear"/>

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
           >

            {(this.state.showEditBenchmark) && (
              <div className="full-width padding-30">
                <SubEditBenchmark selectedTemplate={this.state.selectedBenchmark} history={this.props.navigate} />
              </div>
            )}

            {(this.state.showMatchingCriteria) && (
              <div key="showMatchingCriteria" className="full-width padding-20">
                <p className="heading-text-2">Adjust Matching Criteria</p>
                <div className="spacer" />

                <div className="row-10">
                  <div className="flex-container">
                    <button className="background-button flex-50" type="button" onClick={() => this.setState({ customAdjustment: false })}>
                      <div className={(this.state.customAdjustment) ? "cta-border center-item row-15 horizontal-padding center-text" : "cta-border center-item row-15 horizontal-padding center-text cta-background-color white-text"}>
                        Adjust by Needs
                      </div>
                    </button>
                    <button className="background-button flex-50" type="button" onClick={() => this.setState({ customAdjustment: true })}>
                      <div className={(this.state.customAdjustment) ? "cta-border center-item row-15 horizontal-padding center-text cta-background-color white-text" : "cta-border center-item row-15 horizontal-padding center-text"}>
                        Custom Adjust
                      </div>
                    </button>

                  </div>
                  <div className="spacer" />
                </div>

                {(this.state.customAdjustment) ? (
                  <div>

                    {(this.state.matchingCriteria) && (
                      <div>
                        {this.state.matchingCriteria.map((value ,index) =>
                          <div key={"c" + index}>
                            <div className="calc-column-offset-100-static">
                              <p className="half-bold-text">{index + 1}. {value.name}</p>
                              <div className="half-spacer" />
                              <p className="description-text-3">{value.description}</p>
                            </div>
                            <div className="fixed-column-100 right-text">
                              <div className="fixed-column-70">
                                <input type="number" className="text-field heading-text-2 cta-color bold-text full-width right-text standard-border" min="0" max="100" placeholder="0" name={"custom|" + index} value={value.value} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="fixed-column-30">
                                <div className="mini-spacer"/><div className="mini-spacer"/>
                                <p className="heading-text-2 cta-color bold-text">%</p>
                              </div>
                            </div>

                            <div className="clear" />
                            <div className="spacer" /><div className="half-spacer" />

                          </div>
                        )}

                        <div>
                          <hr className="cta-border-color"/>
                          <div className="spacer" />
                          <div className="float-left full-width right-text">
                            <p className="heading-text-2 cta-color bold-text">{this.state.totalPercent}%</p>
                          </div>
                          <div className="clear" />
                          {(this.state.totalPercent !== 100) && (
                            <div className="full-width">
                              <p className="error-message right-text">Please adjust percentages to equal 100%</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>

                    {(this.state.useCases) && (
                      <div>
                        {this.state.useCases.map((value ,index) =>
                          <div key={"u" + index} className={(value.name === 'Purpose') && "wash-out-2"}>
                            <div className="calc-column-offset-50">
                              <p className="half-bold-text">{index + 1}. {value.name}</p>
                              <div className="half-spacer" />
                              <p className="description-text-3">{value.description}</p>
                            </div>
                            <div className="fixed-column-50 horizontally-center center-text top-padding-5">

                              <button disabled={(value.name === 'Purpose') ? true : false} onClick={() => this.itemClicked('useCase|' + index, true)} type="button" className="background-button">
                                {(value.selected) ? (
                                  <div className="circle-option-container-2 cta-border center-text" >
                                    <div className="circle-selected-2"/>
                                  </div>
                                ) : (
                                  <div className="circle-option-container-2 standard-border center-text" />
                                )}
                              </button>

                              {/*
                              <select name={"useCases|" + index} className="dropdown description-text-2" value={value.value} onChange={this.formChangeHandler}>
                                  {['Optimize','Ignore','De-Optimize'].map(value => <option key={value} value={value}>{value}</option>)}
                              </select>*/}
                            </div>

                            <div className="clear" />
                            <div className="spacer" /><div className="half-spacer" />

                          </div>
                        )}

                      </div>
                    )}
                  </div>
                )}

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color">{this.state.errorMessage}</p>}

                <div className="row-20 center-text">
                  <button className="btn btn-primary right-margin" onClick={() => this.calculateMatches(true, true, true)}>Apply Changes</button>

                  <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>

              </div>
            )}

           </Modal>
          </div>

      )
    }
}

export default withRouter(Benchmarks);
