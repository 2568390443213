import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';
import { socket } from '../Vendors/Socket';
import SubSpeechToText from '../Vendors/SpeechToText';
import SubTextToSpeech from '../Vendors/TextToSpeech';
import SubAvatar from '../Common/Avatar';
import Modal from 'react-modal';

const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';

const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const reloadIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/reload-icon-blue.png';
const coverLetterIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/cover-letter-icon-dark.png';
const resumeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/resume-icon-dark.png';
const industryIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon.png';
const chatbotIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/chatbot-icon-blue.png';
const activelyMessagingGif = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/actively_messaging_gif.gif';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const callWaitingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/call-waiting-gif.gif';

class RenderChat extends Component {
    constructor(props) {
      super(props)

      this.state = {
        showModule: true,
        prefix: '/app',
        scrollOnAllUpdates: true,

        showVoiceButton: false,
        showImportButton: false,
        questionIndex: 0,


        showHumanChatInAdmin: false,

        showGradePromptSpecifics: false,
        testSpeechKit: true,
        showBot: false,
        useRealTimeChat: true,

        disableHumanChatForUnderaged: true,
        includeBotAsOption: true,

        importDocTypeOptions: ['','Cover Letter','Career Plan','Business Plan'],

      }

      this.renderMessage = this.renderMessage.bind(this)
      this.scrollToBottom = this.scrollToBottom.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.filterResults = this.filterResults.bind(this)
      this.sendMessage = this.sendMessage.bind(this)
      this.postBotMessage = this.postBotMessage.bind(this)
      this.updateUI = this.updateUI.bind(this)

      this.renderChatBox = this.renderChatBox.bind(this)
      this.searchItems = this.searchItems.bind(this)
      this.searchItemClicked = this.searchItemClicked.bind(this)
      this.addItem = this.addItem.bind(this)
      this.pullProfileItems = this.pullProfileItems.bind(this)
      this.toggleMessageMenu = this.toggleMessageMenu.bind(this)
      this.renderMessageMenu = this.renderMessageMenu.bind(this)
      this.itemClicked = this.itemClicked.bind(this)
      this.importToProfile = this.importToProfile.bind(this)
      this.toggleDocumentsWidget = this.toggleDocumentsWidget.bind(this)

      this.resetWelcomeMessage = this.resetWelcomeMessage.bind(this)
      this.renderHelpWidget = this.renderHelpWidget.bind(this)
      this.openModal = this.openModal.bind(this)
      this.joinRoom = this.joinRoom.bind(this)

      this.commandClicked = this.commandClicked.bind(this)
      this.importToMessage = this.importToMessage.bind(this)
      this.toggleHelpPage = this.toggleHelpPage.bind(this)
      this.goBack = this.goBack.bind(this)
      this.closeModal = this.closeModal.bind(this)
      this.passCommand = this.passCommand.bind(this)

      this.child = React.createRef()

    }

    componentDidMount() {
      //see if user is logged in
      console.log('componentDidMount in subrenderChat', socket._callbacks)

      socket.on('receive_message', (data) => {
        console.log('received message from socket server: ', data);

        if (data.room === this.state.room) {
          this.updateUI(data)

          if (data.messageObject && data.messageObject.adhocBotPrompt) {
            this.postBotMessage(data.messageObject, true)
          }
        }
      });
      socket.on('error_sending_message', (data) => {
        console.log('error sending message: ', data);

        this.setState({ errorMessage: data.message, isSaving: false })

      });

      this.retrieveData()

      if (this.props.passedCommand) {
        const self = this
        function officiallyPassCommand() {
          console.log('officiallyPassCommand called')
          self.passCommand()
        }
        const delayFilter = () => {
          console.log('delayFilter called: ')
          clearTimeout(self.state.timerId)
          self.state.timerId = setTimeout(officiallyPassCommand, 1000)
        }

        delayFilter();
      }
    }

    componentWillUnmount() {
      console.log('componentWillUnmount called in subRenderChat')

      socket.off('receive_message');
      socket.off('error_sending_message');

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in subRenderChat', this.props, prevProps)

      if (this.props.messages !== prevProps.messages) {
        this.retrieveData()
      } else if (prevProps.messages && (this.props.messages.length !== prevProps.messages.length)) {
        this.retrieveData()
      } else if (this.props.errorMessage !== prevProps.errorMessage || this.props.successMessage !== prevProps.successMessage) {
        this.retrieveData()
      } else if (this.props.activelyMessaging !== prevProps.activelyMessaging || this.props.searchIsAnimating !== prevProps.searchIsAnimating) {
        this.retrieveData()
      } else if (prevProps.showHelpWidget && this.props.showHelpWidget && prevProps.showHelpWidget) {
        this.toggleHelpPage()
      } else if (this.props.passedCommand !== prevProps.passedCommand) {
        this.passCommand()

        // this.setState({ showHelpPrompt: false, showBackButton: false })
      // } else if (this.props.questionIndex !== prevProps.questionIndex) {
      //   this.retrieveData()
      }

      if (this.state.scrollOnAllUpdates) {
        this.scrollToBottom();
      }
    }

    retrieveData() {
      console.log('retrieveData called in renderMessages')

      const messages = this.props.messages
      const recipients = this.props.recipients

      const headline = this.props.headline
      const schoolName = this.props.schoolName
      const jobTitle = this.props.jobTitle
      const employerName = this.props.employerName
      const notificationPreferences = this.props.notificationPreferences
      const room = this.props.room
      const accountCode = this.props.accountCode

      const showHumanChatInAdmin = this.props.showHumanChatInAdmin
      const customAssessment = this.props.customAssessment
      const hideAnswerChoices = this.props.hideAnswerChoices
      const disableFreeformChatbotMessaging = this.props.disableFreeformChatbotMessaging
      const enableFreeformChatbotMessaging = this.props.enableFreeformChatbotMessaging
      const questionIndex = this.props.questionIndex
      const showImportWidget = this.props.showImportWidget
      const showHelpWidget = this.props.showHelpWidget
      const showChat = this.props.showChat
      const showChatBot = this.props.showChatBot
      const sessionUserId = this.props.sessionUserId
      const orgName = this.props.orgName
      const orgChatEmail = this.props.orgChatEmail
      const orgLogo = this.props.orgLogo
      const selectedInbox = this.props.selectedInbox
      const assistantChatCount = this.props.assistantChatCount
      const assistantChatLimit = this.props.assistantChatLimit
      const courseId = this.props.courseId
      const questionId = this.props.questionId
      const selectedMember = this.props.selectedMember
      const doublePassData = this.props.doublePassData
      const smartGoalDescription = this.props.smartGoalDescription
      const businessIdeaDescription = this.props.businessIdeaDescription
      const _id = this.props._id
      const docType = this.props.docType
      const fileName = this.props.fileName
      const docContent = this.props.docContent

      const errorMessage = this.props.errorMessage
      const successMessage = this.props.successMessage
      const activelyMessaging = this.props.activelyMessaging
      const searchIsAnimating = this.props.searchIsAnimating

      // const popularCommands = this.props.popularCommands
      const passedCommandDraft = this.props.passedCommandDraft
      const craftPrompt = this.props.craftPrompt

      let emailId = localStorage.getItem('email');
      if (!emailId) {
        emailId = this.props.emailId
      }
      let roleName = localStorage.getItem('roleName');
      let username = localStorage.getItem('username');
      if (!username) {
        username = this.props.username
      }
      let cuFirstName = localStorage.getItem('firstName');
      if (!cuFirstName) {
        cuFirstName = this.props.cuFirstName
      }

      let cuLastName = localStorage.getItem('lastName');
      if (!cuLastName) {
        cuLastName = this.props.cuLastName
      }
      let activeOrg = localStorage.getItem('activeOrg');
      let pictureURL = localStorage.getItem('pictureURL');
      if (pictureURL === 'undefined') {
        pictureURL = null
      }

      let prefix = '/app'
      if (window.location.pathname.includes('/advisor')) {
        prefix = '/advisor'
      } else if (window.location.pathname.includes('/organizations')) {
        prefix = '/organizations/' + activeOrg
      }

      this.setState({ messages, recipients, activeOrg, username, cuFirstName, cuLastName, pictureURL,
        headline, schoolName, jobTitle, employerName, accountCode, notificationPreferences, room,
        emailId, roleName, prefix, showHumanChatInAdmin, customAssessment, hideAnswerChoices,
        disableFreeformChatbotMessaging, enableFreeformChatbotMessaging, questionIndex,
        showImportWidget, showHelpWidget, showChat, showChatBot, sessionUserId,
        orgName, orgChatEmail, orgLogo, selectedInbox, assistantChatCount, assistantChatLimit, courseId, questionId,
        selectedMember, _id, docType, fileName, docContent, errorMessage, successMessage, activelyMessaging, searchIsAnimating,
        passedCommandDraft, craftPrompt
      })

      const commandQueryObject = { onlyAI: true, onlyElite: true }
      Axios.get('/api/commands', { params: commandQueryObject })
      .then((response) => {
        console.log('Commands query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved commands')

           let csCommands = []
           let intermediaryCommands = []
           let employerCommands = []

           for (let i = 1; i <= response.data.commands.length; i++) {
             if (response.data.commands[i - 1].roleNames) {
               if (response.data.commands[i - 1].roleNames.includes('Career-Seeker')) {
                 csCommands.push(response.data.commands[i - 1])
               } else if (response.data.commands[i - 1].roleNames.includes('Intermediary Staff')) {
                 intermediaryCommands.push(response.data.commands[i - 1])
               } else if (response.data.commands[i - 1].roleNames.includes('Employer')) {
                 employerCommands.push(response.data.commands[i - 1])
               }
             }
           }

           this.setState({ csCommands, intermediaryCommands, employerCommands })

         } else {
           console.log('no commands found', response.data.message)
         }
      }).catch((error) => {
         console.log('Commands query did not work', error);
      });
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value, this.state.showChatBot)

      if (event.target.name === 'messageDraft' && this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Search Organizations') {
        this.setState({ [event.target.name]: event.target.value, searchIsAnimating: true })
        this.searchItems(event.target.name,event.target.value)
      } else if (event.target.name === 'membersSearchText') {
        this.setState({ [event.target.name]: event.target.value, searchIsAnimating: true })
        this.searchItems(event.target.name,event.target.value)
      } else if (event.target.name === 'selectedDocument') {
        let selectedDocument = null
        for (let i = 1; i <= this.state.docOptions.length; i++) {
          console.log('comparing one: ', this.state.docOptions[i - 1].fileName, event.target.value)
          if (this.state.docOptions[i - 1].fileName === event.target.value) {
            console.log('goddup')
            selectedDocument = this.state.docOptions[i - 1]
          }
        }
        this.setState({ selectedDocument })
      } else if (event.target.name === 'profileItemType') {

        if (this.state.selectedMember) {
          this.setState({ [event.target.name]: event.target.value })
          this.pullProfileItems(event.target.name, event.target.value)
        } else {
          this.setState({ [event.target.name]: event.target.value })
        }
      } else if (event.target.name === 'search') {
        console.log('in search')
        const searchString = event.target.value
        this.setState({ searchString, recipient: null, animating: true })
        this.filterResults(event.target.value, null, null, true)

      } else {
        this.setState({ [event.target.name]: event.target.value, memberOptions: null })

        if (event.target.name === 'messageDraft' && event.target.value) {
          let originalTextArray = null
          if (this.state.messageDraft) {
            originalTextArray = this.state.messageDraft.split(" ")
          }
          const textArray = event.target.value.split(" ")

          let searchString = null
          let searchIndex = null
          for (let i = 1; i <= textArray.length; i++) {
            if (!originalTextArray || textArray[i - 1] !== originalTextArray[i - 1]) {

              if (textArray[i - 1].startsWith("@")) {

                searchIndex = i - 1
                searchString = textArray[i - 1].slice(1,textArray[i - 1].length)

              }
            }
          }

          if (searchString) {
            this.setState({ searchIndex })
            this.filterResults(searchString, null, null, true)
          }

          // if (textArray[textArray.length - 1].startsWith("@")) {
          //   // event.target.value.endsWith("@")
          //
          //   console.log('search members and show username')
          //   const searchIndex = textArray.length - 1
          //   this.setState({ searchIndex })
          //   const searchString = textArray[textArray.length - 1].slice(1,textArray[textArray.length - 1].length)
          //   this.filterResults(searchString, null, null, true)
          //
          // }
        }
      }
    }

    filterResults(searchString, filterName, filterValue, search) {
      console.log('filterResults called', searchString, filterName, filterValue, search)

      this.setState({ animating: true })
      let roleNames = ['Student','Career-Seeker','Mentor','Admin']
      if (this.state.userType === 'Mentors') {
        roleNames = ['Mentor']
      }

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        const orgCode = self.state.activeOrg
        const excludeCurrentUser = true
        const emailId = self.state.emailId
        const limit = 5

        Axios.get('/api/members/search', { params: { searchString, orgCode, roleNames, excludeCurrentUser, emailId, limit } })
        .then((response) => {
          console.log('Opportunity search query attempted', response.data);

            if (response.data.success) {
              console.log('opportunity search query worked')

              let memberOptions = response.data.members
              self.setState({ memberOptions, filteredResults: true, animating: false })

            } else {
              console.log('opportunity search query did not work', response.data.message)

              let memberOptions = []
              self.setState({ memberOptions, animating: false })

            }

        }).catch((error) => {
            console.log('Search query did not work for some reason', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    searchItems(name,value) {
      console.log('searchItems called', name, value)

      if (name === 'messageDraft') {
        const orgName = value
        const self = this
        function officiallyFilter() {
          console.log('officiallyFilter called')

          Axios.get('/api/org/search', { params: { orgName, resLimit: 50 } })
          .then((response) => {
            console.log('Org search query attempted', response.data);

            if (response.data.success) {
              console.log('org search query worked')

              const orgOptions = response.data.organizations
              self.setState({ orgOptions, searchIsAnimating: false });

            } else {
              console.log('organization search query did not work', response.data.message)
              self.setState({ searchIsAnimating: false, orgOptions: [] })
            }
          }).catch((error) => {
              console.log('Organization search query did not work for some reason', error);
              self.setState({ searchIsAnimating: false, orgOptions: [] })
          });
        }

        const delayFilter = () => {
          console.log('delayFilter called: ')
          clearTimeout(self.state.timerId)
          self.state.timerId = setTimeout(officiallyFilter, 1000)
        }

        delayFilter();
      } else if (name === 'membersSearchText') {
        const searchString = value
        const orgCode = this.state.activeOrg

        const self = this
        function officiallyFilter() {
          console.log('officiallyFilter called')
          self.setState({ animating: true, errorMessage: null, successMessage: null })

          Axios.get('/api/members/search', { params: { searchString, orgCode } })
          .then((response) => {
            console.log('Members query attempted', response.data);

              if (response.data.success) {
                console.log('successfully retrieved members')

                let memberOptions = null
                if (response.data.members) {
                  memberOptions = response.data.members
                }

                self.setState({ memberOptions, searchIsAnimating: false })

              } else {
                console.log('no course data found', response.data.message)
                self.setState({ searchIsAnimating: false, errorMessage: 'No courses were found that match that criteria' })
              }

          }).catch((error) => {
              console.log('Course query did not work in filter results', error);
              self.setState({ searchIsAnimating: false, errorMessage: 'Course query did not work for an unknown reason'})
          });
        }

        const delayFilter = () => {
          console.log('delayFilter called: ')
          clearTimeout(self.state.timerId)
          self.state.timerId = setTimeout(officiallyFilter, 1000)
        }

        delayFilter();
      }
    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type === 'org') {

        const searchObject = passedItem
        const messageDraft = passedItem.orgName
        const unready = false
        const orgOptions = null
        const errorMessage = null
        const successMessage = null

        this.setState({ searchObject, messageDraft, unready, orgOptions, errorMessage,
          successMessage })

      } else if (type === 'member') {
        const searchObject = passedItem
        let membersSearchText = passedItem.firstName + ' ' + passedItem.lastName
        let memberOptions = null
        this.setState({ searchObject, membersSearchText, memberOptions })
      } else if (type === 'memberreference') {
        let selectedMembers = this.state.selectedMembers
        if (selectedMembers) {
          selectedMembers.push(passedItem)
        } else {
          selectedMembers = [passedItem]
        }

        let messageDraft = this.state.messageDraft
        let searchIndex = this.state.searchIndex
        if (messageDraft) {
          let textArray = messageDraft.split(" ")
          textArray[searchIndex] = '@'+ passedItem.username
          messageDraft = textArray.join(" ")

        }

        let memberOptions = null
        searchIndex = null
        this.setState({ selectedMembers, messageDraft, memberOptions, searchIndex })
      }
    }

    addItem(type) {
      console.log('addItem called', type)

      let selectedMember = this.state.searchObject
      let membersSearchText = ''
      let searchObject = null

      this.setState({ selectedMember, membersSearchText, searchObject })

    }

    pullProfileItems(name, value) {
      console.log('pullProfileItems called', name, value)

      this.setState({ isLoading: true, errorMessage: null, successMessage: null })

      let emailId = this.state.emailId
      if (this.state.selectedMember) {
        emailId = this.state.selectedMember.email
      }

      if (value.endsWith('Career Assessment')) {
        if (this.state.aResultsOptions && this.state.aResultsOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.aResultsOptions, isLoading: false })
        } else {
          Axios.get('/api/assessment/results', { params: { emailId } })
          .then((response) => {
           console.log('query for assessment results worked');

           if (response.data.success) {

             console.log('actual assessment results', response.data)

             const workPreferenceAnswers = response.data.results.workPreferenceAnswers
             const interestScores = response.data.results.interestScores
             const personalityScores = response.data.results.personalityScores
             const skillsAnswers = response.data.results.skillsAnswers
             const newSkillAnswers = response.data.results.newSkillAnswers
             const skillsScores = response.data.results.skillsScores
             const topEmployerValues = response.data.results.topEmployerValues
             const topGravitateValues = response.data.results.topGravitateValues

             let aResultsOptions = []
             let combinedMessageArray = []
             if (workPreferenceAnswers && workPreferenceAnswers.length > 0) {
               const message = "I'm interested in the following functions: " + workPreferenceAnswers[0] + ", and the following industries: " + workPreferenceAnswers[1] + "."
               aResultsOptions.push({ title: 'Work Preference Results', message })
               combinedMessageArray.push(message)
             }
             if (interestScores && interestScores.length > 0) {
               let message = "My strong interest inventory scores are: "
               for (let i = 1; i <= interestScores.length; i++) {
                 if (i === interestScores.length) {
                  message = message + "and {" + interestScores[i - 1].name + ":" + ((interestScores[i - 1].score / 40) * 100) + "%}."
                 } else {
                  message = message + "{" + interestScores[i - 1].name + ":" + ((interestScores[i - 1].score / 40) * 100) + "%}, "
                 }
               }
               aResultsOptions.push({ title: 'Work Interest Results', message })
               combinedMessageArray.push(message)
             }
             if (personalityScores) {
               let message = ''
               if (personalityScores.myersBriggs) {
                 message = message + personalityScores.myersBriggs + ' personality score, '

                 if (personalityScores.fiveFactors) {
                   message = message + ((personalityScores.fiveFactors.opennessScore / 16) * 100) + '% openness score, '
                   message = message + ((personalityScores.fiveFactors.conscientiousnessScore / 16) * 100) + '% conscientiousness score, '
                   message = message + ((personalityScores.fiveFactors.extraversionScore / 16) * 100) + '% extraversion score, '
                   message = message + ((personalityScores.fiveFactors.agreeablenessScore / 16) * 100) + '% agreeableness score, '
                   message = message + ((personalityScores.fiveFactors.neuroticismScore / 16) * 100) + '% neuroticism score.'
                 }
               }

               aResultsOptions.push({ title: 'Personality Results', message })
               combinedMessageArray.push(message)
             }
             if (newSkillAnswers && newSkillAnswers.length > 0) {
               let message = "My top skills are: "
               for (let i = 1; i <= 5; i++) {
                 if (newSkillAnswers[i - 1]) {
                   if (i === 5) {
                     message = message + "and {" + newSkillAnswers[i - 1].name + ":" + ((newSkillAnswers[i - 1].score / 5) * 100) + "%}."
                   } else {
                     message = message + "{" + newSkillAnswers[i - 1].name + ":" + ((newSkillAnswers[i - 1].score / 5) * 100) + "%}, "
                   }
                 }
               }

               aResultsOptions.push({ title: 'Skills Results', message })
               combinedMessageArray.push(message)
             }
             if (topGravitateValues && topGravitateValues.length > 0) {
               let message = "My top values that I gravitate toward are: "
               for (let i = 1; i <= 5; i++) {
                 if (topGravitateValues[i - 1]) {
                   if (i === 5) {
                     message = message + "and " + topGravitateValues[i - 1] + "."
                   } else {
                     message = message + topGravitateValues[i - 1] + ", "
                   }
                 }
               }
               aResultsOptions.push({ title: 'Values Results', message })
               combinedMessageArray.push(message)
             }
             if (aResultsOptions && aResultsOptions.length > 0) {
               aResultsOptions.unshift({ title: 'All Assessment Results', message: combinedMessageArray.join(" ")})
             }

             const profileItemOptions = [{ title: '' }].concat(aResultsOptions)

             this.setState({ workPreferenceAnswers, interestScores, personalityScores,
               newSkillAnswers, topGravitateValues, topEmployerValues,
               aResultsOptions, profileItemOptions, isLoading: false
             })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for assessment results did not work', error);
         })
        }
      } else if (value.endsWith('Certificate')) {
        // console.log("@sdfsdf", this.state.certificateOptions)
        this.setState({ profileItemOptions: this.state.certificateOptions, isLoading: false })
        // if (this.state.certificateOptions && this.state.certificateOptions.length > 0) {
        //   this.setState({ profileItemOptions: this.state.certificateOptions, isLoading: false })
        // } else {
        //
        //   // Axios.get('/api/users/profile/details', { params: { email: emailId } })
        //   // .then((response) => {
        //   //   console.log('User details query 1 attempted', response.data);
        //   //
        //   //   if (response.data.success && (response.data.user.assistantChatCount || response.data.user.assistantChatCount === 0)) {
        //   //    console.log('successfully retrieved user details')
        //   //
        //   //    assistantChatCount = response.data.user.assistantChatCount
        //   //    this.setState({ assistantChatCount })
        //   //
        //   //   } else {
        //   //    console.log('no user details data found', response.data.message)
        //   //   }
        //   //
        //   // }).catch((error) => {
        //   //    console.log('User details query did not work', error);
        //   // });
        // }
      } else if (value.endsWith('Demographics')) {
        this.setState({ profileItemOptions: this.state.demographicsOptions, isLoading: false })
      } else if (value.endsWith('Document')) {
        if (this.state.docOptions && this.state.docOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.docOptions, isLoading: false })
        } else {
          Axios.get('/api/documents', { params: { emailId } })
          .then((response) => {
           console.log('query for documents worked');

           if (response.data.success) {

             // console.log('actual assessment results', response.data)

             let docOptions = []
             for (let i = 1; i <= response.data.documents.length; i++) {
               let tempDoc = response.data.documents[i - 1]
               tempDoc['title'] = tempDoc.fileName

               const docType = tempDoc.docType

               delete tempDoc._id
               delete tempDoc.username
               delete tempDoc.slug
               delete tempDoc.selectedSections
               delete tempDoc.docType
               delete tempDoc.docContent.docType
               delete tempDoc.orgCode
               delete tempDoc.createdAt
               delete tempDoc.updatedAt
               delete tempDoc.__v

               tempDoc['message'] = "My " + docType + " in JSON: " + JSON.stringify(tempDoc)
               docOptions.push(tempDoc)
             }

             const profileItemOptions = [{ title: '' }].concat(docOptions)
             this.setState({ docOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for assessment results did not work', error);
         })
        }
      } else if (value.endsWith('Endorsement')) {
        if (this.state.endorsementOptions && this.state.endorsementOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.endorsementOptions, isLoading: false })
        } else {
          Axios.get('/api/story', { params: { emailId } })
          .then((response) => {
           console.log('query for endorsements worked');

           if (response.data.success) {

             console.log('actual results', response.data)

             let endorsementOptions = []
             let combinedMessageArray = []
             for (let i = 1; i <= response.data.stories.length; i++) {
               let tempDoc = response.data.stories[i - 1]
               tempDoc['title'] = 'Endorsement from ' + response.data.stories[i - 1].senderFirstName + ' ' + response.data.stories[i - 1].senderLastName + ' on ' + convertDateToString(new Date(response.data.stories[i - 1].createdAt),"date-2")


               let competencies = []
               if (tempDoc && tempDoc.competencies && tempDoc.competencies.length > 0) {
                 for (let j = 1; j <= tempDoc.competencies.length; j++) {
                   if (tempDoc.competencies[j - 1]) {
                     const name = tempDoc.competencies[j - 1].name
                     const rating = (tempDoc.competencies[j - 1].rating / 5) * 100
                     competencies.push({ name, rating })
                   }
                 }
               }

               let message = ''
               if (competencies.length > 0) {
                 competencies.sort(function(a,b) {
                   return b.rating - a.rating;
                 })
                 competencies = JSON.stringify(competencies.slice(0,10))
                 message = "My " + tempDoc.relationship + " endorsed me on the following competencies: " + competencies + "."
               }

               if (message) {
                 tempDoc['message'] = message
                 endorsementOptions.push(tempDoc)
                 combinedMessageArray.push(message)
               }
             }

             if (response.data.stories.length > 0) {
               endorsementOptions.unshift({ title: 'All Endorsements', message: combinedMessageArray.join(" Also, ")})
             }

             const profileItemOptions = [{ title: '' }].concat(endorsementOptions)
             this.setState({ endorsementOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for endorsements did not work', error);
         })
        }

      } else if (value.endsWith('Education')) {
        this.setState({ profileItemOptions: this.state.educationOptions, isLoading: false })
      } else if (value.endsWith('Experience')) {
        if (this.state.experienceOptions && this.state.experienceOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.experienceOptions, isLoading: false })
        } else {
          Axios.get('/api/experience', { params: { emailId } })
          .then((response) => {
           console.log('query for experience worked', response.data);

           if (response.data.success) {

             // console.log('actual assessment results', response.data)

             let experienceOptions = []
             let combinedMessageArray = []
             for (let i = 1; i <= response.data.experience.length; i++) {
               let tempDoc = response.data.experience[i - 1]
               // tempDoc['title'] = 'Endorsement from ' + response.data.stories[i - 1].senderFirstName + ' ' + response.data.stories[i - 1].senderLastName + ' on ' + convertDateToString(new Date(response.data.stories[i - 1].createdAt),"date-2")
               tempDoc['title'] = response.data.experience[i - 1].jobTitle + ' @ ' + response.data.experience[i - 1].employerName

               let message = "I worked as a " + tempDoc.jobTitle + " @ " + tempDoc.employerName + " from " + tempDoc.startDate + " to " + tempDoc.endDate + " " + tempDoc.hoursPerWeek + " hours per week."
               if (tempDoc.jobFunction && tempDoc.industry) {
                 message = message + " It was in the " + tempDoc.jobFunction + " functional area and the " + tempDoc.industry + " industry."
               }
               message = message + " My job description was as follows: {"  + tempDoc.description + "}."
               console.log("?@?!?", message)
               tempDoc['message'] = message
               experienceOptions.push(tempDoc)
               combinedMessageArray.push(message)
             }

             if (experienceOptions) {
               experienceOptions.unshift({ title: 'All Experience', message: combinedMessageArray.join(" Also, ")})
             }

             const profileItemOptions = [{ title: '' }].concat(experienceOptions)
             this.setState({ experienceOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for experience did not work', error);
         })
        }
      } else if (value.endsWith('Extracurricular')) {
        if (this.state.extraOptions && this.state.extraOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.extraOptions, isLoading: false })
        } else {
          Axios.get('/api/extras', { params: { emailId } })
          .then((response) => {
           console.log('query for extra worked', response.data);

           if (response.data.success) {

             // console.log('actual assessment results', response.data)

             let extraOptions = []
             let combinedMessageArray = []
             for (let i = 1; i <= response.data.extras.length; i++) {
               let tempDoc = response.data.extras[i - 1]
               // tempDoc['title'] = 'Endorsement from ' + response.data.stories[i - 1].senderFirstName + ' ' + response.data.stories[i - 1].senderLastName + ' on ' + convertDateToString(new Date(response.data.stories[i - 1].createdAt),"date-2")
               tempDoc['title'] = response.data.extras[i - 1].name

               const extraType = response.data.extras[i - 1].type

               delete tempDoc._id
               delete tempDoc.emailId
               delete tempDoc.name
               delete tempDoc.activityName
               delete tempDoc.type
               delete tempDoc.bullets
               delete tempDoc.orgCode
               delete tempDoc.createdAt
               delete tempDoc.updatedAt
               delete tempDoc.__v

               let message = ''
               if (extraType === 'Award') {
                 message = "I received the following award: " + JSON.stringify(tempDoc) + "."
               } else {
                 message = "I had the following extracurricular activity: " + JSON.stringify(tempDoc) + "."
               }
               tempDoc['message'] = message
               extraOptions.push(tempDoc)
               combinedMessageArray.push(message)
             }

             if (extraOptions) {
               extraOptions.unshift({ title: 'All Extracurricular Activities & Awards', message: combinedMessageArray.join(" Also, ")})
             }

             const profileItemOptions = [{ title: '' }].concat(extraOptions)
             this.setState({ extraOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for extras did not work', error);
         })
        }
      } else if (value.endsWith('Favorite')) {

        if (this.state.favoriteOptions && this.state.favoriteOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.favoriteOptions, isLoading: false })
        } else if (this.state.favorites && this.state.favorites.length > 0) {

          Axios.get('/api/favorites/detail', { params: { favorites: this.state.favorites } })
          .then((response) => {
           console.log('query for favorites worked', response.data);

           if (response.data.success && response.data.favorites && response.data.favorites.length > 0) {

             let favoriteOptions = []
             let combinedMessageArray = []
             for (let i = 1; i <= response.data.favorites.length; i++) {
               let tempDoc = response.data.favorites[i - 1]
               if (response.data.favorites[i - 1].name) {
                 tempDoc['title'] = response.data.favorites[i - 1].name
               }
               if (tempDoc.title) {
                 let message = 'I love the ' + response.data.favorites[i - 1].title
                 if (response.data.favorites[i - 1].employerName) {
                   message = message + ' @ ' + response.data.favorites[i - 1].employerName
                 }

                 let type = response.data.types[i - 1]
                 if (type === 'work') {
                   type = 'work opportunity'
                 }

                 message = message + ' ' + type + '.'
                 tempDoc['message'] = message
                 favoriteOptions.push(tempDoc)
                 combinedMessageArray.push(message)
               }
             }

             if (favoriteOptions) {
               favoriteOptions.unshift({ title: 'All Favorites', message: combinedMessageArray.join(" Also, ")})
             }

             const profileItemOptions = [{ title: '' }].concat(favoriteOptions)
             this.setState({ favoriteOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for favoritees did not work', error);
         })

         // Axios.get('/api/favorites/detail', { params: { favorites: response.data.favorites, orgCode, orgFocus } })
         // .then((response2) => {
         //   console.log('Favorites detail query attempted', response2.data);
         //
         //   if (response2.data.success) {
         //     console.log('successfully retrieved favorites detail', response2.data.favorites)
         //
         //     //query info on those favorites
         //     this.setState({
         //       types: response2.data.types,
         //       filteredTypes: response2.data.types,
         //       favorites: response2.data.favorites,
         //       filteredFavorites: response2.data.favorites,
         //       favoriteIds: response.data.favorites
         //     })
         //
         //   } else {
         //     console.log('no favorites detail data found', response2.data.message)
         //   }
         //
         // }).catch((error) => {
         //     console.log('Favorites detail query did not work', error);
         // });
        }
      } else if (value === 'Goal') {
        if (this.state.goalOptions && this.state.goalOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.goalOptions, isLoading: false })
        } else {
          Axios.get('/api/logs/goals', { params: { emailId } })
          .then((response) => {
           console.log('query for goals worked', response.data);

           if (response.data.success) {

             // console.log('actual assessment results', response.data)

             let goalOptions = []
             let combinedMessageArray = []
             for (let i = 1; i <= response.data.goals.length; i++) {
               let tempDoc = response.data.goals[i - 1]
               // tempDoc['title'] = 'Endorsement from ' + response.data.stories[i - 1].senderFirstName + ' ' + response.data.stories[i - 1].senderLastName + ' on ' + convertDateToString(new Date(response.data.stories[i - 1].createdAt),"date-2")
               let message = ''
               if (tempDoc.goalType.name === 'Alternatives') {
                 message = message + "My goal is to choose between alternatives by " + convertDateToString(new Date(tempDoc.deadline),"date-2") + "."
                 message = message + " The question is {" + tempDoc.pollQuestion + "}"
                 message = message + " One the one hand, I can choose {" + tempDoc.aName + "}. The case for this is { " + tempDoc.aCase + "}."
                 message = message + " On the other hand, I can choose {" + tempDoc.bName + "}. The case for this is { " + tempDoc.bCase + "}."
               } else {
                 message = message + 'My goal is to {' + tempDoc.description + '}. I would classify it as a {' + tempDoc.goalType.description + '} goal.'
                 if (tempDoc.intensity && tempDoc.deadline) {
                   message = message + ' I will work ' + tempDoc.intensity + ' to achieve this goal by ' + convertDateToString(new Date(tempDoc.deadline),"date-2") + "."
                 }
               }
               if (tempDoc.status) {
                 message = message + " I am " + tempDoc.status + "."
               }
               tempDoc['message'] = message
               goalOptions.push(tempDoc)
               combinedMessageArray.push(message)
             }

             if (goalOptions) {
               goalOptions.unshift({ title: 'All Goals', message: combinedMessageArray.join(" Also, ")})
             }
             // description, deadline, goalType.description, intensity, successDefined, status
             const profileItemOptions = [{ title: '' }].concat(goalOptions)
             this.setState({ goalOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for goals did not work', error);
         })
        }
      } else if (value.endsWith('Notes')) {
        if (this.state.notesOptions && this.state.notesOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.notesOptions, isLoading: false })
        } else {
          Axios.get('/api/logs/notes', { params: { emailId } })
          .then((response) => {
           console.log('query for notes worked', response.data);

           if (response.data.success) {

             // console.log('actual assessment results', response.data)

             let notesOptions = []
             for (let i = 1; i <= response.data.notes.length; i++) {
               let tempDoc = response.data.notes[i - 1]

               if (response.data.notes[i - 1].generalNotes.length < 50) {
                 tempDoc['title'] = response.data.notes[i - 1].generalNotes
               } else {
                 tempDoc['title'] = response.data.notes[i - 1].generalNotes.substring(0,50) + '...'
               }

               tempDoc['message'] = response.data.notes[i - 1].generalNotes
               notesOptions.push(tempDoc)
             }

             const profileItemOptions = [{ title: '' }].concat(notesOptions)
             this.setState({ notesOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for goals did not work', error);
         })
        }
      } else if (value.endsWith('Passion')) {
        if (this.state.passionOptions && this.state.passionOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.passionOptions, isLoading: false })
        } else {
          Axios.get('/api/logs/passions', { params: { emailId } })
          .then((response) => {
           console.log('query for passions worked', response.data);

           if (response.data.success) {

             // console.log('actual assessment results', response.data)

             let passionOptions = []
             let combinedMessageArray = []
             for (let i = 1; i <= response.data.passions.length; i++) {
               let tempDoc = response.data.passions[i - 1]
               tempDoc['title'] = response.data.passions[i - 1].passionTitle

               let message = 'My passion is ' + tempDoc.passionTitle + '.'
               if (tempDoc.passionReason) {
                 message = message + "The reason I'm passionate about this is: {" + tempDoc.passionReason + "}."
               }
               tempDoc['message'] = message
               passionOptions.push(tempDoc)
               combinedMessageArray.push(message)
             }

             if (passionOptions) {
               passionOptions.unshift({ title: 'All Passions', message: combinedMessageArray.join(" Also, ")})
             }

             const profileItemOptions = [{ title: '' }].concat(passionOptions)
             this.setState({ passionOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for passions did not work', error);
         })
        }
      } else if (value.endsWith('Project')) {
        if (this.state.projectOptions && this.state.projectOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.projectOptions, isLoading: false })
        } else {
          Axios.get('/api/projects', { params: { emailId } })
          .then((response) => {
           console.log('query for projects worked', response.data);

           if (response.data.success) {

             // console.log('actual assessment results', response.data)

             let projectOptions = []
             let combinedMessageArray = []
             for (let i = 1; i <= response.data.projects.length; i++) {
               let tempDoc = response.data.projects[i - 1]
               tempDoc['title'] = response.data.projects[i - 1].name

               let message = "I have worked on my {" + tempDoc.title + "} project for { " + tempDoc.hours + " hours}"
               if (tempDoc.collaborators && tempDoc.collaborators.length > 0) {
                 message = message + " with " + tempDoc.collaborators.length + " teammate(s)/collaborator(s)"
               }
               message = message + ". Here is my description of the project {" + tempDoc.description + "}."

               tempDoc['message'] = message
               projectOptions.push(tempDoc)
               combinedMessageArray.push(message)
             }

             if (projectOptions) {
               projectOptions.unshift({ title: 'All Projects', message: combinedMessageArray.join(" Also, ")})
             }

             const profileItemOptions = [{ title: '' }].concat(projectOptions)
             this.setState({ projectOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for projects did not work', error);
         })
        }
      }

    }

    renderMessage(message, cu) {
      // console.log('renderMessage called')

      if (message && message.length > 0) {
        if (message.startsWith('<img src=')) {
          // let styleObject = ""
          if (!cu) {
            message = message.replace(/margin-left: auto;/g,"margin-right: auto;")
          }
        } else {
          let styleObject = "font-size: 70%; white-space: pre-wrap;"
          if (cu) {
            styleObject = styleObject + "text-align: right;"
          } else {
            styleObject = styleObject + "text-align: left;"
          }

          const Rexp = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;
          message = message.replace(Rexp,"<a href='$1' target='_blank'>$1</a>");
          message = '<div style="' + styleObject + '">' + message + '</div>'
        }
      }

      return message

    }

    scrollToBottom = () => {
      if (this.messagesEnd) {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
      }
    }

    async sendMessage(passedMessage, passedRoom, passedShowChatBot, preparedResponse) {
      console.log('sendMessage called', passedMessage, passedRoom, passedShowChatBot, preparedResponse, this.state.showChatBot)
      // console.log('show socket object: ', socket._callbacks, socket._callbacks.$error_sending_message)
      // if (socket._callbacks.$error_sending_message) {
      //   console.log("there is a listener!")
      // }

      let initialState = { isSaving: true, activelyMessaging: false, errorMessage: null, successMessage: null, memberOptions: null }
      // if (this.state.showChatBot || passedShowChatBot) {
      //   initialState = { isSaving: true, activelyMessaging: true, errorMessage: null, successMessage: null }
      // }

      this.setState(initialState)

      let messageDraft = this.state.messageDraft
      if (passedMessage) {
        messageDraft = passedMessage
      }

      if (!this.state.recipients || this.state.recipients.length === 0) {
        this.setState({ errorMessage: 'Please add at least one recipient', isSaving: false, activelyMessaging: false })
      } else if (!this.state.cuFirstName || this.state.cuFirstName === '') {
        this.setState({ errorMessage: 'There was an error retrieving your first name. Please sign in.', isSaving: false, activelyMessaging: false })
      } else if (!this.state.cuLastName || this.state.cuLastName === '') {
        this.setState({ errorMessage: 'There was an error retrieving your last name. Please sign in.', isSaving: false, activelyMessaging: false })
      } else if (!this.state.emailId || this.state.emailId === '') {
        this.setState({ errorMessage: 'There was an error retrieving your email. Please sign in.', isSaving: false, activelyMessaging: false })
      } else if (!messageDraft || messageDraft === '') {
        this.setState({ isSaving: false, activelyMessaging: false, errorMessage: 'Please add a message' })
      } else if (this.state.showChat && this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Search Organizations' && !this.state.searchObject) {
        this.setState({ isSaving: false, activelyMessaging: false, errorMessage: 'Please search and select an organization' })
      } else {

        if (this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Search Organizations') {
          this.itemClicked('Short Answer',messageDraft, null)
          this.setState({ messageDraft: '', isSaving: false, activelyMessaging: false })
        } else {

          // from HelpPrompt
          const cuFirstName = this.state.cuFirstName
          const cuLastName = this.state.cuLastName
          const pictureURL = this.state.pictureURL
          const username = this.state.username
          const roleName = this.state.roleName
          const prompt = messageDraft
          let sessionUserId = this.state.sessionUserId
          const orgName = this.state.orgName

          const emailId = this.state.emailId
          const message = this.state.messageDraft

          const senderFirstName = this.state.cuFirstName
          const senderLastName = this.state.cuLastName
          const senderEmail = this.state.emailId

          const senderPictureURL = this.state.pictureURL
          const senderHeadline = this.state.headline
          const senderUsername = this.state.username
          const senderSchoolName = this.state.schoolName
          const senderJobTitle = this.state.jobTitle
          const senderEmployerName = this.state.employerName
          const senderRoleName = this.state.roleName

          const orgCode = this.state.activeOrg
          const accountCode = this.state.accountCode

          const recipients = this.state.recipients

          // let members = [{ firstName: this.state.cuFirstName, lastName: this.state.cuLastName, email: this.state.emailId, pictureURL: this.state.pictureURL, username: this.state.username, roleName: this.state.roleName,
          //   notificationPreferences: this.state.notificationPreferences
          // }]
          // members = members.concat(recipients)

          let members = [{ firstName: cuFirstName, lastName: cuLastName, email: emailId, pictureURL, username, roleName, sessionUserId, notificationPreferences: this.state.notificationPreferences
          }]
          members = members.concat(recipients)

          let messageThreadURL = null
          let messageThreadURLs = []
          if (!window.location.pathname.includes('/organization') && this.state.showChat) {
            messageThreadURL = window.location.protocol + "//" + window.location.host + '/organizations/' + orgCode + '/messaging'
          } else {
            for (let i = 1; i <= recipients.length; i++) {
              if (!recipients[i - 1].roleName || recipients[i - 1].roleName === 'Student' || recipients[i - 1].roleName === 'Career-Seeker') {
                messageThreadURL = window.location.protocol + "//" + window.location.host + '/app/messaging'
                messageThreadURLs.push(window.location.protocol + "//" + window.location.host + '/app/messaging')
              } else if (recipients[i - 1].roleName === 'Advisor' || recipients[i - 1].roleName === 'Teacher' || recipients[i - 1].roleName === 'Mentor') {
                messageThreadURL = window.location.protocol + "//" + window.location.host + '/advisor/messaging'
                messageThreadURLs.push(window.location.protocol + "//" + window.location.host + '/advisor/messaging')
              } else if (recipients[i - 1].roleName === 'WBLC' || recipients[i - 1].roleName === 'Work-Based Learning Coordinator' || recipients[i - 1].roleName === 'Admin') {
                messageThreadURL = window.location.protocol + "//" + window.location.host + '/organizations/' + orgCode + '/messaging'
                messageThreadURLs.push(window.location.protocol + "//" + window.location.host + '/organizations/' + orgCode + '/messaging')
              } else if (recipients[i - 1].roleName === 'Employer' || recipients[i - 1].roleName === 'Employer Representative') {
                messageThreadURL = window.location.protocol + "//" + window.location.host + '/employers/' + recipients[i - 1].accountCode + '/messaging'
                messageThreadURLs.push(window.location.protocol + "//" + window.location.host + '/employers/' + recipients[i - 1].accountCode + '/messaging')
              }
            }
          }

          if (window.location.pathname.includes('/organizations') && this.state.showHumanChatInAdmin) {
            senderFirstName = this.state.orgName
            senderLastName = ''
            senderEmail = this.state.orgChatEmail
            senderUsername = this.state.activeOrg
            senderRoleName = 'Admin'
            senderPictureURL = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/employerLogos/employerLogo%7Cguidedcompass%7CLogo-600.png%7CSat%20Aug%2014%202021%2011%3A19%3A04%20GMT-0700%20%28Pacific%20Daylight%20Time%29'
            if (this.state.orgLogo) {
              senderPictureURL = this.state.orgLogo
            }

            senderHeadline = null
            senderSchoolName = null
            senderJobTitle = null
            senderEmployerName = null
            sessionUserId = null

            let recipientMemberIndex = 0

            // console.log('wait inbox: ', this.state.selectedInbox)
            if (this.state.selectedInbox && this.state.selectedInbox.members) {
              members = this.state.selectedInbox.members
              if (members.length > 0) {

                if (members[0].username === this.state.activeOrg) {
                  recipientMemberIndex = 1
                  console.log('t1')
                } else {
                  recipientMemberIndex = 0
                }
                console.log('t3', members[recipientMemberIndex].firstName)
                recipients = [{
                  firstName: members[recipientMemberIndex].firstName,
                  lastName: members[recipientMemberIndex].lastName,
                  email: members[recipientMemberIndex].email,
                  username: members[recipientMemberIndex].username,
                  pictureURL: members[recipientMemberIndex].pictureURL,
                  roleName: members[recipientMemberIndex].roleName,
                  relationship: 'Admin'
                }]

              }
            } else {
              // in the rare case where org starts the chat

            }
          }

          let room = this.state.room
          if (passedRoom) {
            room = passedRoom
          }


          let orgMessage = false
          let onWaitlist = false
          let orgUnread = true
          if (!window.location.pathname.startsWith('/organizations') && this.state.showChat) {
            orgMessage = true
            onWaitlist = true
            orgUnread = true
          } else if (window.location.pathname.startsWith('/organizations') && this.state.showChat && this.state.showHumanChatInAdmin) {
            orgMessage = true
            onWaitlist = false
            orgUnread = false
          }

          let assistantChatCount = 0
          if (this.state.assistantChatCount || this.state.assistantChatCount === 0) {
            assistantChatCount = Number(this.state.assistantChatCount)
          }
          assistantChatCount = assistantChatCount + 1
          console.log('log assistantChatCount before saving: ', assistantChatCount)
          let assistantChatLimit = this.state.assistantChatLimit

          let adhocBotPrompt = false
          if (message && message.toLowerCase().startsWith("@compass")) {
            adhocBotPrompt = true
          }

          let platform = 'web'
          let messageType = null
          if (this.state.showChatBot || passedShowChatBot) {
            messageType = 'Bot'
          } else if (adhocBotPrompt) {
            messageType = 'Bot'
          }

          if (adhocBotPrompt) {
            let botProfile = {
              firstName: "Compass", lastName: "Assistant", email: "creightontaylor+40@gmail.com", username: "compass",
              pictureURL: "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/employerLogos/employerLogo%7Cguidedcompass%7CLogo-600.png%7CSat%20Aug%2014%202021%2011%3A19%3A04%20GMT-0700%20%28Pacific%20Daylight%20Time%29",
              roleName: 'Admin'
            }
            if (members.findIndex(m => m.email === botProfile.email) > -1) {

            } else {
              members.push(botProfile)
              recipients.push(botProfile)
            }

          }
          const courseId = this.props.courseId
          const questionId = this.props.questionId
          console.log('sm-1', this.state.selectedMembers)
          let profileEmail = this.state.emailId
          let sendAsAdvisor = false
          if (this.state.selectedMember) {
            console.log('sm0', this.state.selectedMember)
            if (this.state.selectedMember.email !== emailId) {
              sendAsAdvisor = true
            }
            // console.log('what happened here? ', sendAsAdvisor, this.state.selectedMember.email, emailId)
            if (sendAsAdvisor) {
              profileEmail = this.state.selectedMember.email
            }
          } else if (this.state.selectedMembers) {
            console.log('sm1')
            const textArray = message.split(" ")
            for (let i = 1; i <= this.state.selectedMembers.length; i++) {
              console.log('sm2')
              for (let j = 1; j <= textArray.length; j++) {
                console.log('sm3', this.state.selectedMembers[i - 1].username, textArray[j - 1])

                let testerText = textArray[j - 1]
                if (testerText.startsWith("@")) {
                  testerText = testerText.slice(1,testerText.length)

                  if (this.state.selectedMembers[i - 1].username === testerText) {
                    console.log('sm4')
                    if (this.state.selectedMembers[i - 1].username !== 'compass' && testerText !== 'compass') {
                      console.log('sm5')
                      if (roleName !== 'Student' && roleName !== 'Career-Seeker') {
                        console.log('sm6')
                        // only take the first user tagged
                        if (!sendAsAdvisor) {
                          sendAsAdvisor = true
                          profileEmail = this.state.selectedMembers[i - 1].email
                        }
                      }
                    }
                  }
                }

              }
            }
          }

          let unsubscribed = false

          const createdAt = new Date()
          const updatedAt = new Date()

          const sendObject = {
            emailId, profileEmail, prompt, message: prompt, senderFirstName, senderLastName, senderEmail,
            senderPictureURL, senderHeadline, senderUsername, senderSchoolName,
            senderJobTitle, senderEmployerName, senderRoleName,
            orgCode, orgName, accountCode, recipients, members, messageThreadURL, messageThreadURLs, unsubscribed,
            orgMessage, onWaitlist, orgUnread,
            sessionUserId, room, assistantChatCount, assistantChatLimit, platform, messageType, adhocBotPrompt,
            preparedResponse, courseId, questionId, createdAt, updatedAt
          }

          let messageObject = sendObject

          // const messageData = {
          //   emailId, message, senderFirstName, senderLastName, senderEmail,
          //   senderPictureURL, senderHeadline, senderUsername, senderSchoolName,
          //   senderJobTitle, senderEmployerName, senderRoleName,
          //   orgCode, accountCode, recipients, members, messageThreadURL, messageThreadURLs, unsubscribed,
          //   room, createdAt, updatedAt
          // }

          if (this.state.showChatBot || passedShowChatBot) {
            console.log('in showChatBot')

            orgMessage = false
            onWaitlist = false
            orgUnread = false

            let messages = this.state.messages
            if (messageObject) {
              if (messages) {
                messages.push(messageObject)
              } else {
                messages = [messageObject]
              }
            }

            this.setState({ messageDraft: '', newMessage: false,
              inboxSelected: 0, messages, showReturnMessage: true
            })

            this.postBotMessage(sendObject, false)

          } else {
            console.log('show me the sendObject: ', this.state.showChatBot, passedShowChatBot, sendObject)
            // console.log('----! showChatBot', this.state.showChatBot)

            if (this.state.useRealTimeChat && socket._callbacks && socket._callbacks.$error_sending_message) {
              this.setState({ tempSendObject: sendObject })
              await socket.emit("send_message", sendObject)
            } else {
              Axios.post('/api/messages/send', sendObject)
              .then((response) => {
                console.log('attempting to send message, but not in real-time', message, senderEmail)

                if (response.data.success) {
                  console.log('sent the message', response.data)

                  this.updateUI(response.data)

                  if (sendObject && sendObject.adhocBotPrompt) {

                    this.postBotMessage(sendObject, true)
                  }

                } else {
                  console.log('did not save user message successfully', response.data)
                  this.setState({ errorMessage: 'returned error:' + response.data.message, isSaving: false, activelyMessaging: false })
                }
              }).catch((error) => {
                  console.log('save did not work', error);
                  this.setState({ errorMessage: 'there was an error sending the message', isSaving: false, activelyMessaging: false })
              });
            }

            // if (this.state.useRealTimeChat) {
            //   await socket.emit("send_message", sendObject)
            // } else {
            //   Axios.post('/api/messages/send', sendObject)
            //   .then((response) => {
            //     console.log('attempting to save new message')
            //
            //     if (response.data.success) {
            //       console.log('saved new message', response.data)
            //
            //       // this.updateUI(response.data)
            //
            //       const returnedMessage = response.data.messageObject
            //       let messages = this.state.messages
            //       if (returnedMessage) {
            //         if (messages) {
            //           messages.push(returnedMessage)
            //         } else {
            //           messages = [returnedMessage]
            //         }
            //       }
            //
            //       this.setState({ successMessage: null, isSaving: false, activelyMessaging: false, messageDraft: '', newMessage: false, messages })
            //
            //     } else {
            //       console.log('did not save successfully')
            //       this.setState({ errorMessage: response.data.message, isSaving: false, activelyMessaging: false })
            //     }
            //   }).catch((error) => {
            //       console.log('save did not work', error);
            //       this.setState({ errorMessage: 'there was an error sending the message', isSaving: false, activelyMessaging: false })
            //   });
            // }
          }
        }
      }
    }

    postBotMessage(sendObject, adhocBotPrompt) {
      console.log('postBotMessage called')

      this.setState({ activelyMessaging: true })

      if (adhocBotPrompt) {
        // delete sendObject._id
        // sendObject['adhocBotPrompt'] = adhocBotPrompt
        // sendObject['emailId'] = this.state.emailId
        //
        // let profileEmail = this.state.emailId
        // if (this.state.selectedMember) {
        //   let sendAsAdvisor = false
        //   if (this.state.selectedMember.email !== emailId) {
        //     sendAsAdvisor = true
        //   }
        //   if (sendAsAdvisor) {
        //     profileEmail = this.state.selectedMember.email
        //   }
        // }
        // sendObject['profileEmail'] = profileEmail
        // sendObject['room'] = this.state.room
        //
        // if (!sendObject.members) {
        //   let members = recipients
        //   recipients.push(
        //     { firstName: sendObject.senderFirstName, lastName: sendObject.senderLastName, email: sendObject.senderEmail, username: sendObject.senderUsername, pictureURL: sendObject.senderPictureURL }
        //   )
        //   sendObject['members'] = members
        // }
        //
        // let assistantChatCount = 0
        // if (this.state.assistantChatCount || this.state.assistantChatCount === 0) {
        //   assistantChatCount = Number(this.state.assistantChatCount)
        // }
        // assistantChatCount = assistantChatCount + 1
        //
        // let assistantChatLimit = this.state.assistantChatLimit
        //
        // sendObject['assistantChatCount'] = assistantChatCount
        // sendObject['assistantChatLimit'] = assistantChatLimit
        //
        // sendObject['prompt'] = sendObject.message
        if (this.state.tempSendObject) {
          sendObject = this.state.tempSendObject
          sendObject['createdAt'] = new Date()
          sendObject['updatedAt'] = new Date()
        }

        // switch sender to bot

      }

      Axios.post('/api/messages/ai/send', sendObject)
      .then((response) => {
        console.log('attempting to send message to ai')

        if (response.data.success) {
          console.log('successfully send message to ai', response.data)

          let responseObject = response.data.responseObject
          if (responseObject && Array.isArray(responseObject.message)) {
            responseObject['message'] = responseObject['message'][0]
          }
          let messages = this.state.messages

          if (responseObject) {
            messages.push(responseObject)
          }

          let assistantChatCount = sendObject.assistantChatCount
          let assistantChatLimit = sendObject.assistantChatLimit

          let modalHeading2 = this.state.modalHeading2
          if (response.data.assistantChatCount) {
            assistantChatCount = response.data.assistantChatCount
            localStorage.setItem('assistantChatCount', response.data.assistantChatCount)
            if (!adhocBotPrompt) {
              modalHeading2 = (assistantChatLimit - assistantChatCount).toLocaleString() + " Response(s) Remaining. Upgrade for More."
            }
          }

          // console.log('---ai response: ', messages, )

          this.setState({
            successMessage: null, isSaving: false, activelyMessaging: false, messageDraft: '',
            newMessage: false, inboxSelected: 0, messages, assistantChatCount, modalHeading2,
            tempSendObject: null
          })

          if (!adhocBotPrompt) {
            if (this.child && this.child.current) {
              console.log('show me the current: ', this.child)
              this.child.current.speakText(responseObject.message);
            }

            if (this.props.doublePassData) {
              this.props.doublePassData()
            }

            const jobTitle = this.state.jobTitle
            const employerName = this.state.employerName
            const smartGoalDescription = this.state.smartGoalDescription
            const smartGoalStatus = this.state.smartGoalStatus
            const businessIdeaDescription = this.state.businessIdeaDescription

            if (window.location.pathname.startsWith('/app') && (jobTitle || employerName || smartGoalDescription || smartGoalStatus || businessIdeaDescription)) {

              const variables = { jobTitle, employerName, smartGoalDescription, smartGoalStatus, businessIdeaDescription }
              const orgName = this.state.orgName

              // const promptObject = {
              //   firstName: cuFirstName, lastName: cuLastName, email: emailId,
              //   username, pictureURL, variables, orgCode, orgName, createdAt, updatedAt
              // }
              const promptObject = {
                firstName: this.state.cuFirstName, lastName: this.state.cuLastName, email: this.state.emailId,
                username: this.state.username, pictureURL: this.state.pictureURL, variables,
                orgCode: this.state.activeOrg, orgName: this.state.orgName, createdAt, updatedAt
              }

              Axios.post('/api/prompt-variables', promptObject)
              .then((response) => {
                console.log('attempting to save prompt variables')
                if (response.data.success) {
                  console.log('saved prompt variables', response.data)
                } else {
                  console.log('did not save prompt variables')
                }
              }).catch((error) => {
                  console.log('saving prompt variables', error);
              });
            }
          }

        } else {
          console.log('did not save chatbot message successfully', response.data)
          this.setState({ errorMessage: response.data.message, isSaving: false, activelyMessaging: false, tempSendObject: null })
        }
      }).catch((error) => {
          console.log('save did not work', error);
          this.setState({ errorMessage: 'there was an error sending the message: ' + error.toString(), isSaving: false, activelyMessaging: false, tempSendObject: null })
      });
    }

    // async sendMessage2(passedMessage, passedRoom, passedShowChatBot, preparedResponse) {
    //   console.log('sendMessage2 called', passedMessage, passedRoom, passedShowChatBot, preparedResponse)
    //
    //   this.setState({ isSaving: true, activelyMessaging: true, errorMessage: null, successMessage: null })
    //
    //   let messageDraft = this.state.messageDraft
    //   if (passedMessage) {
    //     messageDraft = passedMessage
    //   }
    //
    //   if (!messageDraft || messageDraft === '') {
    //     this.setState({ isSaving: false, activelyMessaging: false, errorMessage: 'Please add a message' })
    //   } else if (this.state.showChat && this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Search Organizations' && !this.state.searchObject) {
    //     this.setState({ isSaving: false, activelyMessaging: false, errorMessage: 'Please search and select an organization' })
    //   } else {
    //
    //     if (this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Search Organizations') {
    //       this.itemClicked('Short Answer',messageDraft, null)
    //       this.setState({ messageDraft: '', isSaving: false, activelyMessaging: false })
    //     } else {
    //
    //       const cuFirstName = this.state.cuFirstName
    //       const cuLastName = this.state.cuLastName
    //       const emailId = this.state.emailId
    //       const pictureURL = this.state.pictureURL
    //       const username = this.state.username
    //       const roleName = this.state.roleName
    //
    //       // const emailId = this.state.emailId
    //       const prompt = messageDraft
    //
    //       let senderFirstName = this.state.cuFirstName
    //       let senderLastName = this.state.cuLastName
    //       let senderEmail = this.state.emailId
    //       let senderPictureURL = this.state.pictureURL
    //       let senderHeadline = this.state.headline
    //       let senderUsername = this.state.username
    //       let senderSchoolName = this.state.schoolName
    //       let senderJobTitle = this.state.jobTitle
    //       let senderEmployerName = this.state.employerName
    //       let senderRoleName = this.state.roleName
    //       let sessionUserId = this.state.sessionUserId
    //
    //       let recipients = [this.state.recipient]
    //
    //       let members = [{ firstName: cuFirstName, lastName: cuLastName, email: emailId, pictureURL, username, roleName, sessionUserId,
    //       }]
    //       members = members.concat(recipients)
    //       // console.log("----- members", members)
    //       if (window.location.pathname.includes('/organizations') && this.state.showHumanChatInAdmin) {
    //         senderFirstName = this.state.orgName
    //         senderLastName = ''
    //         senderEmail = this.state.orgChatEmail
    //         senderUsername = this.state.activeOrg
    //         senderRoleName = 'Admin'
    //         senderPictureURL = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/employerLogos/employerLogo%7Cguidedcompass%7CLogo-600.png%7CSat%20Aug%2014%202021%2011%3A19%3A04%20GMT-0700%20%28Pacific%20Daylight%20Time%29'
    //         if (this.state.orgLogo) {
    //           senderPictureURL = this.state.orgLogo
    //         }
    //
    //         senderHeadline = null
    //         senderSchoolName = null
    //         senderJobTitle = null
    //         senderEmployerName = null
    //         sessionUserId = null
    //
    //         let recipientMemberIndex = 0
    //
    //         // console.log('wait inbox: ', this.state.selectedInbox)
    //         if (this.state.selectedInbox && this.state.selectedInbox.members) {
    //           members = this.state.selectedInbox.members
    //           if (members.length > 0) {
    //             console.log('compare the two: ', members[0].username, this.state.activeOrg)
    //             if (members[0].username === this.state.activeOrg) {
    //               recipientMemberIndex = 1
    //               console.log('t1')
    //             } else {
    //               recipientMemberIndex = 0
    //             }
    //             console.log('t3', members[recipientMemberIndex].firstName)
    //             recipients = [{
    //               firstName: members[recipientMemberIndex].firstName,
    //               lastName: members[recipientMemberIndex].lastName,
    //               email: members[recipientMemberIndex].email,
    //               username: members[recipientMemberIndex].username,
    //               pictureURL: members[recipientMemberIndex].pictureURL,
    //               roleName: members[recipientMemberIndex].roleName,
    //               relationship: 'Admin'
    //             }]
    //
    //           }
    //         } else {
    //           // in the rare case where org starts the chat
    //
    //         }
    //       }
    //
    //       let room = this.state.room
    //       if (passedRoom) {
    //         room = passedRoom
    //       }
    //
    //       const orgCode = this.state.activeOrg
    //       const orgName = this.state.orgName
    //       const accountCode = this.state.accountCode
    //
    //       let messageThreadURL = null
    //       if (!window.location.pathname.includes('/organization') && this.state.showChat) {
    //         messageThreadURL = window.location.protocol + "//" + window.location.host + '/organizations/' + orgCode + '/messaging'
    //       } else {
    //         if (this.state.recipient) {
    //           if (!this.state.recipient.roleName || this.state.recipient.roleName === 'Student' || this.state.recipient.roleName === 'Career-Seeker') {
    //             messageThreadURL = window.location.protocol + "//" + window.location.host + '/app/messaging'
    //           } else if (this.state.recipient.roleName === 'Advisor' || this.state.recipient.roleName === 'Teacher' || this.state.recipient.roleName === 'Mentor') {
    //             messageThreadURL = window.location.protocol + "//" + window.location.host + '/advisor/messaging'
    //           } else if (this.state.recipientRoleName === 'WBLC' || this.state.recipient.roleName === 'Work-Based Learning Coordinator' || this.state.recipient.roleName === 'Admin') {
    //             messageThreadURL = window.location.protocol + "//" + window.location.host + '/organizations/' + orgCode + '/messaging'
    //           } else if (this.state.recipientRoleName === 'Employer' || this.state.recipient.roleName === 'Employer Representative') {
    //             messageThreadURL = window.location.protocol + "//" + window.location.host + '/employers/' + this.state.recipient.accountCode + '/messaging'
    //           }
    //         }
    //       }
    //
    //       let orgMessage = false
    //       let onWaitlist = false
    //       let orgUnread = true
    //       if (!window.location.pathname.startsWith('/organizations') && this.state.showChat) {
    //         orgMessage = true
    //         onWaitlist = true
    //         orgUnread = true
    //       } else if (window.location.pathname.startsWith('/organizations') && this.state.showChat && this.state.showHumanChatInAdmin) {
    //         orgMessage = true
    //         onWaitlist = false
    //         orgUnread = false
    //       }
    //
    //       let assistantChatCount = 0
    //       if (this.state.assistantChatCount || this.state.assistantChatCount === 0) {
    //         assistantChatCount = Number(this.state.assistantChatCount)
    //       }
    //       assistantChatCount = assistantChatCount + 1
    //       console.log('log assistantChatCount before saving: ', assistantChatCount)
    //       let assistantChatLimit = this.state.assistantChatLimit
    //
    //       let platform = 'web'
    //       let messageType = null
    //       if (this.state.showChatBot || passedShowChatBot) {
    //         messageType = 'Bot'
    //       }
    //
    //       const courseId = this.props.courseId
    //       const questionId = this.props.questionId
    //
    //       let profileEmail = emailId
    //       if (this.state.selectedMember) {
    //         profileEmail = this.state.selectedMember.email
    //       }
    //
    //       const createdAt = new Date()
    //       const updatedAt = new Date()
    //
    //       const sendObject = {
    //         emailId, profileEmail, prompt, message: prompt, senderFirstName, senderLastName, senderEmail,
    //         senderPictureURL, senderHeadline, senderUsername, senderSchoolName,
    //         senderJobTitle, senderEmployerName, senderRoleName,
    //         orgCode, orgName, accountCode, recipients, members, messageThreadURL, orgMessage, onWaitlist, orgUnread,
    //         sessionUserId, room, assistantChatCount, assistantChatLimit, platform, messageType,
    //         preparedResponse, courseId, questionId, createdAt, updatedAt
    //       }
    //
    //       let messageObject = sendObject
    //       console.log('did we get the bot right: ', this.state.showChatBot)
    //       if (this.state.showChatBot || passedShowChatBot) {
    //         console.log('in showChatBot')
    //
    //         orgMessage = false
    //         onWaitlist = false
    //         orgUnread = false
    //
    //         let messages = this.state.messages
    //         if (messageObject) {
    //           if (messages) {
    //             messages.push(messageObject)
    //           } else {
    //             messages = [messageObject]
    //           }
    //         }
    //
    //         this.setState({ successMessage: null, messageDraft: '', newMessage: false,
    //           inboxSelected: 0, messages, showReturnMessage: true
    //         })
    //
    //         Axios.post('/api/messages/ai/send', sendObject)
    //         .then((response) => {
    //           console.log('attempting to send message to ai', prompt, senderEmail)
    //
    //           if (response.data.success) {
    //             console.log('successfully send message to ai', response.data)
    //
    //             let responseObject = response.data.responseObject
    //             if (responseObject && Array.isArray(responseObject.message)) {
    //               responseObject['message'] = responseObject['message'][0]
    //             }
    //             let messages = this.state.messages
    //
    //             if (responseObject) {
    //               messages.push(responseObject)
    //             }
    //
    //             let modalHeading2 = this.state.modalHeading2
    //             if (response.data.assistantChatCount) {
    //               assistantChatCount = response.data.assistantChatCount
    //               localStorage.setItem('assistantChatCount', response.data.assistantChatCount)
    //               modalHeading2 = (assistantChatLimit - assistantChatCount).toLocaleString() + " Response(s) Remaining. Upgrade for More."
    //             }
    //
    //             // console.log('---ai response: ', messages, )
    //
    //             this.setState({
    //               successMessage: null, isSaving: false, activelyMessaging: false, messageDraft: '',
    //               newMessage: false, inboxSelected: 0, messages, assistantChatCount, modalHeading2
    //             })
    //
    //             if (this.child && this.child.current) {
    //               console.log('show me the current: ', this.child)
    //               this.child.current.speakText(responseObject.message);
    //             }
    //
    //             if (this.props.passData) {
    //               this.props.passData()
    //             }
    //
    //             const jobTitle = this.state.jobTitle
    //             const employerName = this.state.employerName
    //             const smartGoalDescription = this.state.smartGoalDescription
    //             const smartGoalStatus = this.state.smartGoalStatus
    //             const businessIdeaDescription = this.state.businessIdeaDescription
    //
    //             if (window.location.pathname.startsWith('/app') && (jobTitle || employerName || smartGoalDescription || smartGoalStatus || businessIdeaDescription)) {
    //
    //               const variables = { jobTitle, employerName, smartGoalDescription, smartGoalStatus, businessIdeaDescription }
    //               const orgName = this.state.orgName
    //
    //               const promptObject = {
    //                 firstName: cuFirstName, lastName: cuLastName, email: emailId,
    //                 username, pictureURL, variables, orgCode, orgName, createdAt, updatedAt
    //               }
    //
    //               Axios.post('/api/prompt-variables', promptObject)
    //               .then((response) => {
    //                 console.log('attempting to save prompt variables')
    //                 if (response.data.success) {
    //                   console.log('saved prompt variables', response.data)
    //                 } else {
    //                   console.log('did not save prompt variables')
    //                 }
    //               }).catch((error) => {
    //                   console.log('saving prompt variables', error);
    //               });
    //             }
    //
    //           } else {
    //             console.log('did not save successfully')
    //             this.setState({ errorMessage: response.data.message, isSaving: false, activelyMessaging: false })
    //           }
    //         }).catch((error) => {
    //             console.log('save did not work', error);
    //             this.setState({ errorMessage: 'there was an error sending the message: ' + error.toString(), isSaving: false, activelyMessaging: false })
    //         });
    //       } else {
    //         console.log('show me the sendObject: ', this.state.showChatBot, passedShowChatBot, sendObject)
    //         console.log('----! showChatBot', this.state.showChatBot)
    //
    //         if (this.state.useRealTimeChat) {
    //           await socket.emit("send_message", sendObject)
    //         } else {
    //           Axios.post('/api/messages/send', sendObject)
    //           .then((response) => {
    //             console.log('attempting to save new message')
    //
    //             if (response.data.success) {
    //               console.log('saved new message', response.data)
    //
    //               // this.updateUI(response.data)
    //
    //               const returnedMessage = response.data.messageObject
    //               let messages = this.state.messages
    //               if (returnedMessage) {
    //                 if (messages) {
    //                   messages.push(returnedMessage)
    //                 } else {
    //                   messages = [returnedMessage]
    //                 }
    //               }
    //
    //               this.setState({ successMessage: null, isSaving: false, activelyMessaging: false, messageDraft: '', newMessage: false, messages })
    //
    //             } else {
    //               console.log('did not save successfully')
    //               this.setState({ errorMessage: response.data.message, isSaving: false, activelyMessaging: false })
    //             }
    //           }).catch((error) => {
    //               console.log('save did not work', error);
    //               this.setState({ errorMessage: 'there was an error sending the message', isSaving: false, activelyMessaging: false })
    //           });
    //         }
    //       }
    //     }
    //   }
    // }

    updateUI(data) {
      console.log('updatedUI called: ', data, this.state.inboxSelected, this.state.inboxes)

      const returnedMessage = data.messageObject
      let messages = this.state.messages
      if (returnedMessage) {
        if (messages) {
          messages.push(returnedMessage)
        } else {
          messages = [returnedMessage]
        }
      }

      this.setState({ successMessage: null, isSaving: false, activelyMessaging: false, messageDraft: '', messages })

      if (this.props.updateInboxes) {
        this.props.updateInboxes(data)
      }

      if (!this.state.scrollOnAllUpdates) {
        this.scrollToBottom()
      }
    }

    renderChatBox(fromPromptDesigner, fromCourse, fromSocialChat) {
      console.log('renderChatBox called', fromPromptDesigner, fromCourse, fromSocialChat)

      return (
        <div key="chatbox">
          <div>
            {(this.state.messages && this.state.messages.length > 0) ? (
              <div className={(fromCourse) ? "" : (fromPromptDesigner) ? "row-10 standard-border horizontal-padding-4 calc-height-140-of-100" : (fromSocialChat) ? "row-10 standard-border horizontal-padding-4 calc-height-180-of-98" : "row-10 standard-border horizontal-padding-4 calc-height-340-of-100"}>
                <div className="full-space overflow-y-auto">
                  {this.state.messages.map((value, optionIndex) =>
                    <div key={value._id} className={(value.showMenu) ? "left-text full-width bottom-padding-20 medium-background" : "left-text full-width bottom-padding-20"}>
                      {((window.location.pathname.includes('/organizations') && this.state.showHumanChatInAdmin) ? (value.senderUsername === this.state.activeOrg) : (value.senderEmail === this.state.emailId)) ? (
                        <div className="left-padding full-width top-padding">
                          <div className="fixed-column-50">
                            <p className="description-text-5 bold-text description-text-color top-padding">{convertDateToString(value.createdAt,"daysAgo")}</p>
                          </div>

                          <div className="calc-column-offset-90">
                            <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : (value.senderUsername) ? '/' + value.senderUsername + '/profile/' : '/'} target="_blank" className={(this.state.emailId && !this.state.emailId.includes('yourname@placeholder.com')) ? "background-button full-width standard-color" : "background-button full-width standard-color no-pointers"} disabled={(this.state.emailId && !this.state.emailId.includes('yourname@placeholder.com')) ? false : true}>
                              <p className="description-text-2 bold-text full-width right-text">{(value.senderFirstName) ? value.senderFirstName + ' ' + value.senderLastName : "Your Name"}</p>
                            </Link>

                            <button className={(this.state.emailId && !this.state.emailId.includes('yourname@placeholder.com')) ? "background-button float-right" : "background-button float-right no-pointers"} disabled={(this.state.emailId && !this.state.emailId.includes('yourname@placeholder.com')) ? false : true} onClick={() => this.toggleMessageMenu(optionIndex)}>
                              <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message,true)}}></div>
                            </button>
                            <div className="clear" />

                            {(value.showMenu) && (
                              <div>
                                {this.renderMessageMenu(true, optionIndex)}
                              </div>
                            )}

                            {(value.importConfirmationMessage) && (
                              <div className="row-10">
                                <p className="cta-color description-text-2 right-text">{value.importConfirmationMessage}</p>
                              </div>
                            )}

                          </div>
                          <div className="fixed-column-40">
                            <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                            <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : '/' + value.senderUsername + '/profile'} target="_blank" className="background-button full-width standard-color">
                              <img src={(value.senderPictureURL) ? value.senderPictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30 pin-right" />
                            </Link>
                          </div>
                          <div className="clear" />
                        </div>
                      ) : (
                        <div className="left-padding full-width top-padding">
                          <div className="fixed-column-40">
                            <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                            <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : this.state.prefix + '/profile/' + value.senderUsername} target="_blank" className="background-button full-width standard-color">
                              <img src={(value.senderPictureURL) ? value.senderPictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                            </Link>
                          </div>

                          <div className="calc-column-offset-40">
                            <div>
                              <div className="calc-column-offset-50">
                                <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : this.state.prefix + '/profile/' + value.senderUsername} target="_blank" className={(this.state.emailId && !this.state.emailId.includes('yourname@placeholder.com')) ? "background-button full-width standard-color" : "background-button full-width standard-color no-pointers"} disabled={(this.state.emailId && !this.state.emailId.includes('yourname@placeholder.com')) ? false : true}>
                                  <p className="description-text-2 bold-text">{value.senderFirstName} {value.senderLastName}</p>
                                </Link>
                              </div>
                              <div className="fixed-column-50">
                                <p className="description-text-5 bold-text description-text-color top-padding">{convertDateToString(value.createdAt,"daysAgo")}</p>
                              </div>
                              <div className="clear" />
                            </div>

                            <button className={(this.state.emailId && !this.state.emailId.includes('yourname@placeholder.com')) ? "background-button" : "background-button no-pointers"} disabled={(this.state.emailId && !this.state.emailId.includes('yourname@placeholder.com')) ? false : true} onClick={() => this.toggleMessageMenu(optionIndex)}>
                              <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message)}}></div>
                            </button>
                            <div className="clear" />

                            {(value.showMenu) && (
                              <div>
                                {this.renderMessageMenu(false, optionIndex)}
                              </div>
                            )}

                            {(value.importConfirmationMessage) && (
                              <div className="row-10">
                                <p className="cta-color description-text-2">{value.importConfirmationMessage}</p>
                              </div>
                            )}

                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="clear" />

                  {(this.state.activelyMessaging) && (
                    <img src={activelyMessagingGif} alt="GC" className="image-auto-30" />
                  )}

                  {(this.state.awaitingConnection) && (
                    <img src={callWaitingGIF} alt="GC" className="image-auto-80 pin-right" />
                  )}

                  {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-3 error-color row-5">{this.state.errorMessage}</p>}
                  {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-3 cta-color row-5">{this.state.successMessage}</p>}

                  {(this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Multiple Choice' && !this.state.hideAnswerChoices) && (
                    <div className="flex-container row-direction">
                      <div className="flex-25">
                      </div>
                      <div className="flex-75 flex-container row-direction justify-end flex-wrap">
                        {this.state.customAssessment.questions[this.state.questionIndex].answerChoices.map((item2, optionIndex2) =>
                          <div key={"answerChoice|" + optionIndex2}>
                            <button className="background-button" onClick={() => this.itemClicked('Multiple Choice',item2, optionIndex2)}>
                              <div className="row-7 horizontal-padding-5 description-text-3 cta-border cta-color slightly-rounded-corners left-margin bottom-margin">
                                <p>{item2}</p>
                              </div>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div ref={(el) => { this.messagesEnd = el; }} />
                </div>
              </div>
            ) : (
              <div>

                {(fromSocialChat) ? (
                  <div>
                    {/*
                    {(this.state.recipients && this.state.recipients.length > 0) && (
                      <div className="top-padding-10-percent">
                        <p className="full-width top-padding heading-text-6 horizontal-padding center-text">No Messages Yet</p>
                        <p className="full-width description-text-3 center-text top-padding">There are no existing messages on this chat thread.</p>
                      </div>
                    )}*/}

                    <div className="row-10 standard-border horizontal-padding-4 calc-height-180-of-98">
                      <div className="top-padding-10-percent">
                        <p className="full-width top-padding heading-text-6 horizontal-padding center-text">No Messages Yet</p>
                        <p className="full-width description-text-3 center-text top-padding">There are no existing messages on this chat thread.</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row-10 standard-border horizontal-padding-4 calc-height-400-of-95">
                    {/*
                    {(this.state.recipient) && (
                      <div className="padding-30">
                        <img src={(this.state.recipient.pictureURL) ? this.state.recipient.pictureURL : profileIconDark} className="profile-thumbnail-4 center-item" alt="GC" />
                        <div>
                          <p className="full-width top-padding heading-text-6 horizontal-padding center-text">{this.state.recipient.firstName} {this.state.recipient.lastName}</p>
                          <p className="full-width description-text-3 center-text top-padding">The {(this.state.showChatBot) ? "Compass Assistant is here to help" : (this.state.orgName) ? this.state.orgName + " is here to help" : "Guided Compass is here to help"}.</p>
                        </div>
                      </div>
                    )}*/}
                    <div className="padding-30">
                      <img src={(this.state.recipient && this.state.recipient.pictureURL) ? this.state.recipient.pictureURL : profileIconDark} className="profile-thumbnail-4 center-item" alt="GC" />
                      <div>
                        <p className="full-width top-padding heading-text-6 horizontal-padding center-text">{(this.state.recipient) ? this.state.recipient.firstName + " " + this.state.recipient.lastName : "No Messages Yet"}</p>
                        <p className="full-width description-text-3 center-text top-padding">{(this.state.showChatBot) ? "Compass Assistant is here to help" : (this.state.orgName) ? this.state.orgName + " is here to help" : "Guided Compass is here to help"}.</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          {(this.state.memberOptions && this.state.memberOptions.length > 0) && (
            <div>
              {(this.state.animating) ? (
                <div className="flex-container flex-center full-space">
                  <div>
                    <div className="super-spacer" />

                    <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                    <div className="spacer" /><div className="spacer" /><div className="spacer" />
                    <p className="center-text cta-color bold-text">Searching...</p>

                  </div>
                </div>
              ) : (
                <div className="card-clear-padding padding-10">
                  {this.state.memberOptions.map((value, optionIndex) =>
                    <div key={"mo|" + value.email}>
                      <button className="background-button full-width left-text row-10" onClick={() => this.searchItemClicked(value, 'memberreference')}>
                        <div className="fixed-column-40">
                          <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-25" />
                        </div>
                        <div className="calc-column-offset-40 top-padding-3">
                          <p className="description-text-2"><label className="bold-text">{value.firstName} {value.lastName}</label> ({value.username})</p>
                        </div>
                        <div className="clear" />
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {console.log('rc1')}
          {(fromSocialChat) ? (
            <div>
              <div className="row-10 standard-border horizontal-padding-4">
                <div className="fixed-column-30 top-margin-5">
                  <button className="background-button" onClick={(this.state.showModalHelpWidget) ? () => this.setState({ modalIsOpen: true, showModalHelpWidget: false  }) : () => this.setState({ modalIsOpen: true, showModalHelpWidget: true })} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add A.I. to your chat experience">
                    <div className={(this.state.showModalHelpWidget)? "error-border padding-5 circle-radius" : "cta-border padding-5 circle-radius"}>
                      <img src={(this.state.showModalHelpWidget) ? closeIcon : chatbotIconBlue} alt="GC" className="image-15-fit" />
                    </div>
                  </button>
                </div>
                <div className="calc-column-offset-100-static">
                  <textarea className="text-field description-text-2 clear-border height-30" type="text" onKeyPress={(e) => (e.key === 'Enter') ? this.sendMessage() : console.log('key clicked')} placeholder="Write a message..." name="messageDraft" value={this.state.messageDraft} onChange={this.formChangeHandler} />
                </div>
                <div className="fixed-column-70">
                  <button className={(this.state.messageDraft && this.state.messageDraft !== '' && !this.state.isSaving) ? "btn btn-small" : "btn btn-small medium-background standard-border"} disabled={(this.state.messageDraft && this.state.messageDraft !== '' && !this.state.isSaving) ? false : true} onClick={() => this.sendMessage()}>Send</button>
                </div>
                <div className="clear" />
                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-3 error-color row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-3 cta-color row-5">{this.state.successMessage}</p>}
              </div>
            </div>
          ) : (
            <div>
              {console.log('rc2', this.state.customAssessment, this.state.questionIndex, this.state.disableFreeformChatbotMessaging, fromPromptDesigner, fromCourse)}
              {((!this.state.customAssessment && !this.state.disableFreeformChatbotMessaging && !fromPromptDesigner && !fromCourse) || (this.state.enableFreeformChatbotMessaging && !fromPromptDesigner && !fromCourse) || (this.state.customAssessment && (this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Short Answer' || this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Chat with Human' || this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Chat with Casper' || this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Search Organizations') && !this.state.disableFreeformChatbotMessaging && !fromPromptDesigner&& !fromCourse)) && (
                <div className="horizontal-padding-4">
                  {console.log('rc3')}

                  <div className="row-10">
                    {(this.state.showVoiceButton) && (
                      <div className="fixed-column-40">
                        <SubSpeechToText passData={this.passData} />
                      </div>
                    )}
                    {(this.state.showImportButton) && (
                      <div className="fixed-column-40">
                        <button className="background-button" onClick={(this.state.showImportWidget) ? () => this.props.passData({ showImportWidget: false }) : () => this.props.passData({ showImportWidget: true, showHelpWidget: false, showBackButton: true, modalHeading1: "Import Profile Information", modalHeading2: "Select and import your profile info to A.I."  })} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Import profile information">
                          <div className={(this.state.showImportWidget)? "error-border padding-5 circle-radius" : "cta-border padding-5 circle-radius"}>
                            <img src={(this.state.showImportWidget) ? closeIcon : addIconBlue} alt="GC" className="image-15-fit" />
                          </div>
                        </button>
                      </div>
                    )}
                    <div className="fixed-column-40">
                      <button className="background-button" onClick={(this.state.showHelpWidget) ? () => this.setState({ showHelpWidget: false  }) : () => this.toggleHelpPage()} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Get help, tips, and pointers">
                        <div className={(this.state.showHelpWidget)? "error-border padding-5 circle-radius" : "cta-border padding-5 circle-radius"}>
                          <img src={(this.state.showHelpWidget) ? closeIcon : questionMarkBlue} alt="GC" className="image-15-fit" />
                        </div>
                      </button>
                    </div>
                    <div className={(this.state.showVoiceButton) ? "calc-column-offset-190 right-padding" : (this.state.showImportButton) ? "calc-column-offset-150 right-padding" : "calc-column-offset-110 right-padding"}>
                      <textarea className="text-field description-text-2 clear-border height-30 light-background" type="text" onKeyPress={(e) => (e.key === 'Enter') ? this.sendMessage() : console.log('key clicked')} placeholder={(this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType !== 'Short Answer' && this.state.customAssessment.questions[this.state.questionIndex].placeholder) ? this.state.customAssessment.questions[this.state.questionIndex].placeholder : "Write a message..."} name="messageDraft" value={this.state.messageDraft} onChange={this.formChangeHandler} />
                    </div>
                    <div className="fixed-column-70">
                      <button className={(this.state.messageDraft && this.state.messageDraft !== '') ? "btn btn-small" : "btn btn-small medium-background standard-border"} disabled={(this.state.messageDraft && this.state.messageDraft !== '' && !this.state.isSaving) ? false : true} onClick={() => this.sendMessage()}>Send</button>
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.searchIsAnimating) ? (
                    <div className="flex-container flex-center full-space">
                      <div>
                        <div className="super-spacer" />

                        <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                        <div className="spacer" /><div className="spacer" /><div className="spacer" />
                        <p className="center-text cta-color bold-text">Searching...</p>

                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        {(this.state.orgOptions && this.state.orgOptions.length > 0) && (
                          <div className="cta-border padding-20 top-margin bottom-margin">
                            {this.state.orgOptions.map((value, optionIndex) =>
                              <div key={value._id} className="left-text bottom-margin-5 full-width">
                                <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value, 'org')}>
                                  <div className="full-width">
                                    <div className="fixed-column-40">
                                      <div className="mini-spacer" />
                                      <img src={industryIcon} alt="GC" className="image-auto-22" />
                                    </div>
                                    <div className="calc-column-offset-40">
                                      <p className="cta-color">{value.orgName}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {((this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Reset Welcome Prompt') || (fromPromptDesigner)) && (
                <div className="horizontal-padding-4">
                  <button className="background-button full-width left-text row-10" onClick={() => this.resetWelcomeMessage(fromPromptDesigner)}>
                    <div className="fixed-column-30">
                      <img className="image-auto-20" alt="GC" src={reloadIconBlue} />
                    </div>
                    <div className="calc-column-offset-30">
                      <p className="description-text-1 cta-color">{(fromPromptDesigner) ? "Back to Prompt Designer" : "Reset the Welcome Message"}</p>
                    </div>
                    <div className="clear" />
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )
    }

    toggleMessageMenu(index) {
      console.log('toggleMessageMenu in subRenderChat', index, this.state.emailId)

      let messages = this.state.messages
      messages[index]['showMenu'] = !this.state.messages[index].showMenu
      this.setState({ messages })

    }

    renderMessageMenu(cu, index) {
      console.log('renderMessageMenu called')

      return (
        <div>
          <div className={(cu) ? "top-margin-20 padding-10 white-background rounded-corners float-right" : "top-margin-20 padding-10 white-background rounded-corners right-margin-20 float-left"}>
            <button className={(cu) ? "background-button left-margin-20" : "background-button right-margin-20"} onClick={() => this.importToProfile(index,'notes')} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Import to Notes">
              <img src={coverLetterIconDark} className="image-auto-16" alt="GC" />
            </button>
            <button className={(cu) ? "background-button left-margin-20" : "background-button right-margin-20"} onClick={() => this.toggleDocumentsWidget(index)} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Import to Documents">
              <img src={resumeIconDark} className="image-auto-16" alt="GC" />
            </button>
          </div>

          {(this.state.messages[index].showDocumentsMenu) && (
            <div>
              <div className="clear" />

              <div className={(cu) ? "top-margin-20 padding-10 white-background rounded-corners float-right" : "top-margin-20 padding-10 white-background rounded-corners right-margin-20 float-left"}>
                <div className={(cu) ? "float-right left-padding-20" : "float-left right-padding-20"}>
                  <label className="profile-label">Document Type<label className="error-color bold-text">*</label></label>
                  <select name="docType" className="dropdown description-text-2" value={this.state.docType} onChange={this.formChangeHandler}>
                    {this.state.importDocTypeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                  </select>
                </div>
                <div className={(cu) ? "float-right left-padding-20" : "float-left right-padding-20"}>
                  <label className="profile-label">Select an Existing Document?<label className="error-color bold-text">*</label></label>
                  <select name="existingDoc" className="dropdown description-text-2" value={this.state.existingDoc} onChange={this.formChangeHandler}>
                    {['','Yes','No'].map(value => <option key={value} value={value}>{value}</option>)}
                  </select>
                </div>

                {(this.state.docType === 'Resume') && (
                  <div className={(cu) ? "float-right left-padding-20" : "float-left right-padding-20"}>
                    <label className="profile-label">Select What Section to Import<label className="error-color bold-text">*</label></label>
                  </div>
                )}

                {(this.state.existingDoc === 'Yes') && (
                  <div className={(cu) ? "float-right" : "float-left"}>
                    <label className="profile-label">Select a Saved Document<label className="error-color bold-text">*</label></label>
                    <select name="selectedDocument" className="dropdown description-text-2" value={(this.state.selectedDocument) ? this.state.selectedDocument.fileName : ""} onChange={this.formChangeHandler}>
                      {this.state.docOptions.map(value => <option key={value.fileName} value={value.fileName}>{value.fileName}</option>)}
                    </select>
                  </div>
                )}

                {(this.state.existingDoc === 'No') && (
                  <div className={(cu) ? "float-right" : "float-left"}>
                    <label className="profile-label">Name the New Document<label className="error-color bold-text">*</label></label>
                    <input className="text-field description-text-2 height-40" type="text" placeholder="(e.g., My First Resume)..." name="fileName" value={this.state.fileName} onChange={this.formChangeHandler} />
                  </div>
                )}
                <div className="clear" />

                <div className="top-margin">
                  <button className="btn btn-squarish" onClick={() => this.importToProfile(index,'documents')}>Import</button>
                </div>

              </div>
            </div>
          )}
          <div className="clear" />
        </div>
      )
    }

    itemClicked(type,item,index) {
      console.log('itemClicked called', type, item, index)

      if (!this.state.customAssessment) {
        this.setState({ errorMessage: "Could not find the script to respond" })
      } else {
        const customAssessment = this.state.customAssessment

        let recipient = this.state.recipients
        if (this.state.recipients && this.state.recipients.length > 0) {
          recipient = this.state.recipients[0]
        }

        const cuFirstName = this.state.cuFirstName
        const cuLastName = this.state.cuLastName
        const emailId = this.state.emailId
        const pictureURL = this.state.pictureURL
        const username = this.state.username
        const roleName = this.state.roleName

        let selectedAnswer = null
        if (type === 'Short Answer') {
          selectedAnswer = item
        } else {
          selectedAnswer = customAssessment.questions[this.state.questionIndex].answerChoices[index]
        }

        // change questionIndex
        let questionIndex = this.state.questionIndex
        // questionIndex = questionIndex + 1
        console.log('qi1', questionIndex)
        for (let i = 1; i <= customAssessment.questions.length; i++) {
          // test if next questions have conditionals
          const question = customAssessment.questions[i - 1]
          // console.log('i me: ', i - 1, this.state.questionIndex, question.conditional, question.conditionNumber, this.state.questionIndex + 1, question.conditionValue, selectedAnswer)
          let conditionValue = question.conditionValue
          if (conditionValue) {
            conditionValue = conditionValue.replace(/{{orgName}}/g,this.state.orgName)
          }
          console.log('qi2', questionIndex, i, question.conditional, conditionValue, selectedAnswer)
          if (i - 1 > this.state.questionIndex && question.conditional && question.conditionNumber === (this.state.questionIndex + 1) && (conditionValue === selectedAnswer || conditionValue === 'N/A')) {
            questionIndex = i - 1
          }
        }
        console.log('qi3', questionIndex)
        if (customAssessment.questions[questionIndex].questionType === 'Chat with Casper' || (window.location.pathname.startsWith('/organizations') && !this.state.showHumanChatInAdmin)) {
          recipient['firstName'] = "Compass"
          recipient['lastName'] = " Assistant"
          recipient['email'] = 'creightontaylor+40@gmail.com'
          recipient['username'] = 'compass'
          recipient['pictureURL'] = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/employerLogos/employerLogo%7Cguidedcompass%7CLogo-600.png%7CSat%20Aug%2014%202021%2011%3A19%3A04%20GMT-0700%20%28Pacific%20Daylight%20Time%29'
          recipient['roleName'] = 'Admin'
        }

        let messages = this.state.messages
        messages.push({
          emailId, message: '<div style="width: fit-content; margin-left: auto; border: 1px solid black; background-color: black; padding: 7px 20px; border-radius: 5px; color: white;"><p>' + selectedAnswer + '</p></div>',
          senderFirstName: cuFirstName, senderLastName: cuLastName, senderEmail: emailId,
          senderPictureURL: pictureURL, senderUsername: username,
          senderHeadline: this.state.headline,
          senderSchoolName: this.state.schoolName, senderJobTitle: this.state.jobTitle,
          senderEmployerName: this.state.employerNane, senderRoleName: roleName,
          orgCode: this.state.activeOrg, accountCode: null,
          recipients: [{ firstName: recipient.firstName, lastName: recipient.lastName,
            email: recipient.email, pictureURL: recipient.pictureURL,
            username: recipient.username, roleName: recipient.roleName
          }],
          createdAt: new Date(), updatedAt: new Date()
        })

        let autoResponse = customAssessment.questions[questionIndex].name
        if (customAssessment.questions[this.state.questionIndex].questionType === 'Search Organizations') {
          autoResponse = autoResponse.replace(/{{orgCode}}/g,this.state.searchObject.orgCode)
          autoResponse = autoResponse.replace(/{{orgContactEmail}}/g,this.state.searchObject.contactEmail)
        }
        // console.log('my check in: ', customAssessment.questions[questionIndex].questionType, autoResponse)
        // console.log('show my autoResponse plz: ', customAssessment, questionIndex, this.state.questionIndex, autoResponse)
        // add question to messages
        messages.push({
          emailId: recipient.email, message: autoResponse,
          senderFirstName: recipient.firstName, senderLastName: recipient.lastName,
          senderEmail: recipient.email, senderPictureURL: recipient.pictureURL,
          senderHeadline: recipient.headline, senderUsername: recipient.username,
          senderSchoolName: recipient.schoolName, senderJobTitle: recipient.jobTitle,
          senderEmployerName: recipient.employerName, senderRoleName: recipient.roleName,
          orgCode: this.state.activeOrg, accountCode: null,
          recipients: [{ firstName: cuFirstName, lastName: cuLastName, email: emailId, pictureURL, username, roleName }],
          createdAt: new Date(), updatedAt: new Date()
        })

        this.setState({ messages, questionIndex })

        if (selectedAnswer === 'Submit a Question, Bug, or Issue') {
          this.props.openModal('showTicketWindow')
        }

        if (customAssessment.questions[questionIndex].questionType === 'Chat with Human') {
          this.joinRoom()
        }

        if (customAssessment.questions[questionIndex].questionType === 'Chat with Casper') {
          this.setState({ showChatBot: true })
          this.props.openModal('showChatBot')
        }
      }
    }

    importToProfile(index, type) {
      console.log('importToProfile called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      let messages = this.state.messages
      messages[index]['showMenu'] = !this.state.messages[index].showMenu

      let postURL = null
      let postObject = {}
      if (type === 'notes') {
        messages[index]['importedToNotes'] = true
        messages[index]['importConfirmationMessage'] = 'You have successfully imported this message into your notes!'
        postURL = '/api/logs/notes'

        const _id = this.state._id
        const creatorEmail = this.state.emailId
        const creatorFirstName = this.state.cuFirstName
        const creatorLastName = this.state.cuLastName
        const creatorUsername = this.state.username
        const creatorPictureURL = this.state.pictureURL
        const creatorRoleName = this.state.roleName
        const logType = 'Notes'
        const generalNotes = messages[index].message
        const orgCode = this.state.activeOrg
        const createdAt = new Date()
        const updatedAt = new Date()

        postObject = {
          creatorEmail, creatorFirstName, creatorLastName, creatorUsername, creatorPictureURL,
          creatorRoleName, logType, generalNotes, orgCode, createdAt, updatedAt
        }
      } else {
        messages[index]['importedToDocuments'] = true
        messages[index]['importConfirmationMessage'] = 'You have successfully imported and saved this as a document!'
        postURL = '/api/documents/save'

        const _id = this.state._id

        const email = this.state.emailId
        const firstName = this.state.cuFirstName
        const lastName = this.state.cuLastName
        const username = this.state.username
        const pictureURL = this.state.pictureURL
        const roleName = this.state.roleName

        let docType = this.state.docType

        let fileName = this.state.fileName
        let selectedSections = ['Summary','Education','Experience','Projects','Awards']
        let fileId = this.state.fileId
        let slug = null
        let docContent = this.state.docContent

        if (this.state.selectedDocument && this.state.existingDoc === 'Yes') {
          fileName = this.state.selectedDocument.fileName
          selectedSections = this.state.selectedDocument.selectedSections
          fileId = this.state.selectedDocument._id
          docContent = this.state.selectedDocument.docContent
          docType = this.state.selectedDocument.docType

          if (docType === 'Resume') {
            slug = 'resumes'
          } else if (docType === "Cover Letter") {
            slug = 'cover-letters'
            docContent['textBody'] = messages[index].message
          } else if (docType === "Career Plan") {
            slug = 'career-plans'
            if (docContent.sections) {
              docContent.sections.push({ title: 'Imported from Chat', body: messages[index].message })
            } else {
              docContent['sections'] = [{ title: 'Imported from Chat', body: messages[index].message }]
            }
          } else if (docType === "Financial Plan") {
            slug = 'financial-plans'
          } else if (docType === "Business Plan") {
            slug = 'business-plans'
            docContent['title'] = "My Imported Business Plan"
            if (docContent.sections) {
              docContent.sections.push({ title: 'Imported from Chat', body: messages[index].message })
            } else {
              docContent['sections'] = [{ title: 'Imported from Chat', body: messages[index].message }]
            }
          } else if (docType === "Mock Interview") {
            slug = 'mock-interviews'
          }
        } else if (this.state.existingDoc === 'No') {
          docContent = {}
          docContent['docType'] = docType
          docContent['docName'] = fileName
          docContent['firstName'] = firstName
          docContent['lastName'] = lastName
          docContent['email'] = email
          docContent['username'] = username

          docContent['currentDate'] = new Date()
          docContent['recipientFirstName'] = 'Jon'
          docContent['recipientLastName'] = 'Schmoe'
          docContent['recipientOrg'] = 'Schmoe Enterprises'
          docContent['addressLine1'] = '123 Schmoe Lane'
          docContent['city'] = 'Los Angeles'
          docContent['state'] = 'CA'
          docContent['zipCode'] = '90210'
          docContent['senderFirstName'] = firstName
          docContent['senderLastName'] = lastName
          docContent['creatorFirstName'] = 'Jon'
          docContent['creatorLastName'] = 'Schmoe'

          if (docType === 'Resume') {
            slug = 'resumes'
          } else if (docType === "Cover Letter") {
            slug = 'cover-letters'
            docContent['textBody'] = messages[index].message
          } else if (docType === "Career Plan") {
            slug = 'career-plans'
            docContent['title'] = "My Imported Career Plan"
            docContent['sections'] = [{ title: 'Imported from Chat', body: messages[index].message }]
          } else if (docType === "Financial Plan") {
            slug = 'financial-plans'
          } else if (docType === "Business Plan") {
            slug = 'business-plans'
            docContent['title'] = "My Imported Business Plan"
            docContent['sections'] = [{ title: 'Imported from Chat', body: messages[index].message }]
          } else if (docType === "Mock Interview") {
            slug = 'mock-interviews'
          }
        }

        const orgCode = this.state.activeOrg
        const createdAt = new Date()
        const updatedAt = new Date()

        postObject = {
          email, firstName, lastName, username, pictureURL, roleName, orgCode,
          docType, slug, fileName, selectedSections,
          docContent, fileId, createdAt, updatedAt
        }
      }

      // this.setState({ messages })

      const createdAt = new Date()
      const updatedAt = new Date()

      Axios.post(postURL, postObject).then((response) => {
        console.log('attempting to improve to profile')
        if (response.data.success) {
          console.log('successfully imported')

          // const successMessage = 'Successfully imported the message to your profile'
          this.setState({ isSaving: false, messages })

        } else {
          console.log('did not successfully update the rankings', response.data.message)
          this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
        }
      }).catch((error) => {
          console.log('save rankings did not work', error);
          this.setState({ errorMessage: error.toString(), isSaving: false })
      });
    }

    toggleDocumentsWidget(index) {
      console.log('toggleDocumentsWidget called', index)

      let messages = this.state.messages
      messages[index]['showDocumentsMenu'] = !this.state.messages[index].showDocumentsMenu
      this.setState({ messages })

      if (!this.state.docOptions || this.state.docOptions.length === 0) {
        // const onlyUpcoming = true
        Axios.get('/api/documents', { params: { emailId: this.state.emailId }})
        .then((response) => {
          console.log('Documents query worked', response.data);

          if (response.data.success && response.data.documents && response.data.documents.length > 0) {

            const docOptions = response.data.documents
            const selectedDocument = docOptions[0]
            this.setState({ docOptions, selectedDocument })

          } else {
            console.log('no documents data found', response.data.message)
            this.setState({ docOptions: [] })
          }

        }).catch((error) => {
            console.log('Documents query did not work', error);
        });
      }
    }

    renderHelpWidget() {
      console.log('renderHelpWidget called')

      return (
        <div key="renderHelpWidget">
          <div className="row-5">
            <p className="description-text-1">Individuals and their teachers, counselors, coordinators, administrators, mentors, and parents can use Generative A.I. powered Compass Assistant to provide personalized advice, generate documents, and complete tasks.</p>
          </div>
          <div className="row-20">
            <p className="bold-text">Why it is innovative</p>
            <ol className="description-text-1 top-margin-5 left-padding-20">
              <li>As an individual, Compass is aware of your profile information (e.g., interests, personality, skills, values, goals, education, experience, etc...) and conversational history when you ask it a question or give it a prompt.</li>
              <li>As an advisor (e.g., teacher, counselor, mentor, administrator), Compass is aware of your advisee's profile information and conversational history when you ask it a question or prompt.</li>
              <li>As a user, tag in Compass Assistant in the middle of a chat conversation with one or more individuals by starting your message with @compass.</li>
              <li>As an administrator, customize Compass to better fit your program and pull reports on it is used.</li>
            </ol>
          </div>
          <div className="row-20">
            <p className="bold-text">How it Works</p>
            <ol className="description-text-1  top-margin-5 left-padding-20">
              <li>Start your message with @compass to submit a question or prompt to Compass Assistant.</li>
              <li>When a prompt has been submitted, Guided Compass submits all of your profile, activities, and conversational history to the A.I. on the back-end and returns the response.</li>
              <li>If you're an advisor, ask about your advisee by adding an additional @ symbol and tagging your advisee (e.g., @jondoe).</li>
            </ol>
          </div>
          <div className="row-20">
            <p className="bold-text">Examples</p>
            <ol className="description-text-1  top-margin-5 left-padding-20">
              <li>@Compass what should I be when I grow up</li>
              <li>@Compass what should @jondoe be when he grows up.</li>
              <li>@Compass generate a draft of a letter of recommendation for @jondoe.</li>
              <li>@Compass create a business plan that I can get behind.</li>
              <li>@Compass generate a career and financial plan for me.</li>
            </ol>
          </div>
          {(this.state.csCommands && this.state.csCommands.length > 0) && (
            <div className="row-15 top-margin-20">
              <p className="bold-text">{this.state.csCommands.length} Popular Career-Seeker Commands</p>

              <div className="description-text-2 top-margin-5">
                {this.state.csCommands.map((item, optionIndex) =>
                  <div key={"csCommands|" + optionIndex} className="top-margin-30">
                    <div className="calc-column-offset-90 right-padding-20 bottom-margin-20">
                      <p>{optionIndex + 1}. {item.name}</p>
                    </div>
                    <div className="fixed-column-90 top-margin-negative-5 bottom-margin-20">
                      <button className="btn btn-squarish" onClick={() => this.commandClicked(item)}>Try Me</button>
                    </div>
                    <div className="clear" />

                    <hr />

                  </div>
                )}
              </div>
            </div>
          )}
          {(this.state.intermediaryCommands && this.state.intermediaryCommands.length > 0) && (
            <div className="row-15 top-margin-20">
              <p className="bold-text">{this.state.intermediaryCommands.length} Popular Intermediary (e.g., Teacher, Mentor, Administrator, etc...) Commands</p>

              <div className="description-text-2 top-margin-5">
                {this.state.intermediaryCommands.map((item, optionIndex) =>
                  <div key={"intermediaryCommands|" + optionIndex} className="top-margin-30">
                    <div className="calc-column-offset-90 right-padding-20 bottom-margin-20">
                      <p>{optionIndex + 1}. {item.name}</p>
                    </div>
                    <div className="fixed-column-90 top-margin-negative-5 bottom-margin-20">
                      <button className="btn btn-squarish" onClick={() => this.commandClicked(item)}>Try Me</button>
                    </div>
                    <div className="clear" />

                    <hr />

                  </div>
                )}
              </div>
            </div>
          )}
          {(this.state.employerCommands && this.state.employerCommands.length > 0) && (
            <div className="row-15">
              <p className="bold-text">{this.state.employerCommands.length} Popular Employer (Talent Acquisition) Commands</p>

              <div className="description-text-2 top-margin-5">
                {this.state.employerCommands.map((item, optionIndex) =>
                  <div key={"employerCommands|" + optionIndex} className="top-margin-30">
                    <div className="calc-column-offset-90 right-padding-20 bottom-margin-20">
                      <p>{optionIndex + 1}. {item.name}</p>
                    </div>
                    <div className="fixed-column-90 top-margin-negative-5 bottom-margin-20">
                      <button className="btn btn-squarish" onClick={() => this.commandClicked(item)}>Try Me</button>
                    </div>
                    <div className="clear" />

                    <hr />

                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )
    }

    resetWelcomeMessage(fromPromptDesigner) {
      console.log('resetWelcomeMessage called')

      if (fromPromptDesigner) {
        this.setState({ showReturnMessage: false })
      } else {
        let questionIndex = 0

        let messages = this.state.messages
        let recipient = this.state.recipient
        if (this.state.recipients && this.state.recipients.length === 1) {
          recipient = this.state.recipients[0]
        }
        let cuFirstName = this.state.cuFirstName
        let cuLastName = this.state.cuLastName
        let emailId = this.state.emailId
        let pictureURL = this.state.pictureURL
        let username = this.state.username
        let roleName = this.state.roleName

        let message = 'N/A'
        if (this.state.customAssessment && this.state.customAssessment.questions) {
          message = this.state.customAssessment.questions[questionIndex].name
        }

        messages.push({
          emailId: recipient.email, message,
          senderFirstName: recipient.firstName, senderLastName: recipient.lastName,
          senderEmail: recipient.email, senderPictureURL: recipient.pictureURL,
          senderHeadline: recipient.headline, senderUsername: recipient.username,
          senderSchoolName: recipient.schoolName, senderJobTitle: recipient.jobTitle,
          senderEmployerName: recipient.employerName, senderRoleName: recipient.roleName,
          orgCode: this.state.activeOrg, accountCode: null,
          recipients: [{ firstName: cuFirstName, lastName: cuLastName, email: emailId, pictureURL, username, roleName }],
          enableFreeformChatbotMessaging: false,
          createdAt: new Date(), updatedAt: new Date()
        })

        const hideAnswerChoices = false

        this.setState({ questionIndex, messages, hideAnswerChoices })
        this.openModal('showChat')
      }
    }

    openModal(type,messageDraft, openChatBotOnly, showPromptDesigner, showGradePrompt) {
      console.log('openModal called in subRenderChat', type, messageDraft, openChatBotOnly, showPromptDesigner, showGradePrompt)

      let modalIsOpen = true
      if (this.props.chatOnly) {
        modalIsOpen = false
      }
      let showHelpPrompt = false
      let showTicketWindow = false
      let showChat = false
      let showChatBot = false
      let showChatPanel = false
      let lastUnreadMessage = null
      let modalHeading1 = null
      let modalHeading2 = null
      let modalHeading3 = null
      let showModalBackButton = true
      let recipient = this.state.recipient
      if (this.state.recipients && this.state.recipients.length === 0) {
        recipient = this.state.recipients[0]
      }
      let messages = this.state.messages
      let enableFreeformChatbotMessaging = this.state.enableFreeformChatbotMessaging
      const customAssessment = this.state.customAssessment
      let hideAnswerChoices = this.state.hideAnswerChoices
      let passedMessageDraft = this.state.messageDraft
      let documentType = null
      let gradingRubricName = "Default Grading Rubric"

      let includeSkills = true
      let includeEducation = true
      let includeExperience = true
      let includeAssessments = false
      let includeEndorsements = true
      let includeFeedback = false
      let includeSections = false
      let includeGoals = false

      if (this.props.passedCommandDraft && showPromptDesigner) {
        if (this.props.docContent) {
          documentType = this.props.docContent.docType

          if (documentType === 'Career Plan') {
            includeAssessments = true
            includeSections = true
            includeGoals = true
          } else if (documentType === 'Financial Plan') {
            includeGoals = true
          } else if (documentType === 'Business Plan') {
            includeGoals = true
            includeAssessments = true
          } else if (documentType === 'Mock Interview Doc') {
            includeEndorsements = false
            includeAssessments = false
          }
        }
      }

      if (type === 'showChat') {
        showChat = true
        modalHeading1 = "Guided Compass Conversation"
        if (this.state.orgName) {
          modalHeading1 = this.state.orgName + " Conversation"
        }
        // modalHeading2 = "Chat between 9am - 5pm PT. Avg wait time: 5 mins."
        if (this.state.orgName) {
          recipient['firstName'] = this.state.orgName
        } else {
          recipient['firstName'] = 'Guided Compass'
        }
        recipient['lastName'] = ''
        recipient['pictureURL'] = this.state.orgLogo
      } else if (type === 'showChatBot') {
        showChatBot = true
        modalHeading1 = "Compass Assistant"
        modalHeading2 = (this.state.assistantChatLimit - this.state.assistantChatCount).toLocaleString() + " Response(s) Remaining. Upgrade for More."
        recipient['firstName'] = "Compass"
        recipient['lastName'] = "Assistant"
        recipient['email'] = "creightontaylor+40@gmail.com"
        recipient['username'] = "compass"
        recipient['pictureURL'] = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/employerLogos/employerLogo%7Cguidedcompass%7CLogo-600.png%7CSat%20Aug%2014%202021%2011%3A19%3A04%20GMT-0700%20%28Pacific%20Daylight%20Time%29'

        if (messageDraft || (!messageDraft && openChatBotOnly)) {
          hideAnswerChoices = true
        }

        if (!messageDraft && openChatBotOnly) {
          enableFreeformChatbotMessaging = true
          const openingMessage = "Waiving in Compass Assistant A.I. Write a question, prompt, or command."

          messages = [{
            emailId: recipient.email, message: openingMessage,
            senderFirstName: recipient.firstName, senderLastName: recipient.lastName,
            senderEmail: recipient.email, senderPictureURL: recipient.pictureURL,
            senderHeadline: recipient.headline, senderUsername: recipient.username,
            senderSchoolName: recipient.schoolName, senderJobTitle: recipient.jobTitle,
            senderEmployerName: recipient.employerName, senderRoleName: recipient.roleName,
            orgCode: this.state.activeOrg, accountCode: null,
            recipients: [{ firstName: this.state.cuFirstName, lastName: this.state.cuLastName, email: this.state.emailId, pictureURL: this.state.pictureURL, username: this.state.username, roleName: this.state.roleName }],
            createdAt: new Date(), updatedAt: new Date()
          }]

        }
      } else if (type === 'showHelpPrompt') {
        showHelpPrompt = true
        modalHeading1 = "Hello!"
        if (this.state.loggedIn && this.state.cuFirstName) {
          modalHeading1 = "Hi " + this.state.cuFirstName
        }
        modalHeading2 = "How can we help?"
        showModalBackButton = false
      } else if (type === 'showTicketWindow') {
        showTicketWindow = true
        modalHeading1 = "Submit a Ticket"
        modalHeading2 = "Could be a question, issue, or bug."
      } else if (type === 'showChatPanel') {
        showChatPanel = true
        modalHeading1 = this.state.orgName + " Chat Requests"

        // modalHeading2 = "Chat between 9am - 5pm PT. Avg wait time: 5 mins."
        if (this.state.orgName) {
          recipient['firstName'] = this.state.orgName
        } else {
          recipient['firstName'] = 'Guided Compass'
        }
        recipient['lastName'] = ''
        recipient['pictureURL'] = this.state.orgLogo
      }
      console.log('what are the rules: ', showChat, showChatBot, showHelpPrompt, showChatPanel)
      // this.setState({ modalIsOpen, showHelpPrompt, showTicketWindow, showChat, showChatBot, showChatPanel,
      //   lastUnreadMessage, modalHeading1, modalHeading2, modalHeading3, showModalBackButton,
      //   recipient, customAssessment, hideAnswerChoices, messages, enableFreeformChatbotMessaging,
      //   showPromptDesigner, documentType,
      //   includeSkills, includeEducation, includeExperience, includeAssessments, includeEndorsements,
      //   includeFeedback, includeSections, includeGoals
      // })

      this.setState({
        modalIsOpen, showHelpPrompt, showTicketWindow, showChat, showChatBot, showChatPanel,
          lastUnreadMessage, modalHeading1, modalHeading2, modalHeading3, showModalBackButton,
          recipient, customAssessment, hideAnswerChoices, messages, enableFreeformChatbotMessaging,
          showPromptDesigner, showGradePrompt, documentType, gradingRubricName,
          includeSkills, includeEducation, includeExperience, includeAssessments, includeEndorsements,
          includeFeedback, includeSections, includeGoals
      }, () => {
        if (this.props.passedCommandDraft && showPromptDesigner) {
          this.props.craftPrompt(this.props.docContent, null, null, null, null)
        }
      });
    }

    joinRoom(inbox) {
      console.log('joinRoom called', inbox)

      const username = this.state.username
      let user1Id = null
      let user2Id = null
      if (window.location.pathname.startsWith('/organizations')) {
        if (inbox.members && inbox.members.length > 0) {
          if (inbox.members[0].firstName === this.state.orgName) {
            user1Id = inbox.members[1].sessionUserId
            if (inbox.members[1].email) {
              user1Id = inbox.members[1].email
            }
          } else {
            user1Id = inbox.members[0].sessionUserId
            if (inbox.members[0].email) {
              user1Id = inbox.members[0].email
            }
          }
        }
        user2Id = this.state.activeOrg
      } else {
        user1Id = this.state.sessionUserId
        if (this.state.emailId) {
          user1Id = this.state.emailId
        }
        if (this.state.activeOrg) {
          user2Id = this.state.activeOrg
        } else {
          user2Id = 'guidedcompass'
        }
      }

      let memberEmails = [user1Id,user2Id]
      memberEmails.sort()
      const room =  memberEmails.join("|")
      socket.emit('join_room', { username, room })
      this.setState({ room })
      if (!window.location.pathname.includes('/organizations')) {
        this.setState({ showChatBot: false })
        const messageDraft = '<img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/call-waiting-gif.gif" alt="GC" style="margin-left: auto; width: auto; height: 80px;" />'
        this.sendMessage(messageDraft, room)
      }
    }

    toggleHelpPage() {
      console.log('toggleHelpPage called')

      if (this.state.showHelpWidget) {
        // pass data back to change the title to help page
        this.props.passData({
          modalHeading1: "Hi " + this.state.cuFirstName,
          modalHeading2: "How can we help?",
          showCancelButton: false, showHelpWidget: false
        }, null, true)

        // toggle to showHelpWidget
        this.setState({ showHelpWidget: false, showBackButton: false })
      } else {
        // pass data back to change the title to help page
        this.props.passData({
          modalHeading1: "Using Compass A.I. Assistant",
          modalHeading2: "Learn how to better utilize our A.I. career chatbot.",
          showCancelButton: true, showHelpWidget: true
        }, null, true)

        // toggle to showHelpWidget
        this.setState({ showHelpWidget: true, showBackButton: true })
      }

    }

    importToMessage(selectedItem, type) {
      console.log('importToMessage called')

      if (type === 'profileItem') {
        for (let i = 1; i <= this.state.profileItemOptions.length; i++) {
          if (this.state.profileItemOptions[i - 1].title === this.state.profileItem) {
            selectedItem = this.state.profileItemOptions[i - 1]
          }
        }
      }

      if (selectedItem) {
        let messageDraft = selectedItem.message
        if (this.state.messageDraft) {
          messageDraft = this.state.messageDraft + ' ' + selectedItem.message
        }
        this.setState({ messageDraft })
        this.goBack()
      }
    }

    goBack() {
      console.log('goBack called')

      const modalHeading1 = "Compass Assistant"
      const modalHeading2 = (this.state.assistantChatLimit - this.state.assistantChatCount).toLocaleString() + " Response(s) Remaining. Upgrade for More."

      this.setState({ showHelpWidget: false, showImportWidget: false, showBackButton: false, modalHeading1, modalHeading2 })

    }

    commandClicked(item) {
      console.log('commandClicked called')

      this.importToMessage({ message: item.name, type: 'popularCommand' })
      this.closeModal()

    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showModalHelpWidget: false })
    }

    passCommand() {
      console.log('passCommand to subRenderChat', this.props.passedCommand)

      let messageDraft = null
      if (this.props.passedCommand) {
        messageDraft = this.props.passedCommand
      }

      let preparedResponse = null
      if (this.props.preparedResponse) {
        preparedResponse = this.props.preparedResponse
      }


      // this.openModal('showChatBot', messageDraft, null)
      this.props.openModal('showChatBot', messageDraft, null)

      this.itemClicked('Multiple Choice','Chat with A.I. Assistant', 0)
      this.sendMessage(messageDraft, null, true, preparedResponse)


      // this.itemClicked('Multiple Choice',this.state.customAssessment.questions[this.state.questionIndex].answerChoices[0], 0)
      // {this.state.customAssessment.questions[this.state.questionIndex].answerChoices.map((item2, optionIndex2) =>
      //   <div key={"answerChoice|" + optionIndex2}>
      //     <button className="background-button" onClick={() => this.itemClicked('Multiple Choice',item2, optionIndex2)}>
      //       <div className="row-7 horizontal-padding-5 description-text-3 cta-border cta-color slightly-rounded-corners left-margin bottom-margin">
      //         <p>{item2}</p>
      //       </div>
      //     </button>
      //   </div>
      // )}
    }

    render() {

      let recipientProfileLink = '/'
      let recipientProfileLinkPrefix = ''
      let cuProfileLink = this.state.prefix + '/profile/' + this.state.username
      if (this.state.recipients && this.state.recipients.length > 0) {
        // show multiple profile links
        recipientProfileLinkPrefix = this.state.prefix + '/profile/'
        if (window.location.pathname.includes('/advisor')) {
          if (this.state.roleName === 'Mentor') {
            recipientProfileLinkPrefix = '/advisor/profile/'
            cuProfileLink = '/advisor/profile/' + this.state.username
          } else {
            recipientProfileLinkPrefix = '/advisor/advisees/'
            cuProfileLink = '/advisor/advisees/' + this.state.emailId
          }
        }
      }

      return (
        <div>
          {(this.state.showBackButton) ? (
            <div className="padding-15 calc-height-340-of-100">
              {/*
              {(this.state.showImportWidget) && (
                <div>
                  {this.renderImportWidget()}
                </div>
              )}*/}
              {(this.state.showHelpWidget) && (
                <div>

                  {this.renderHelpWidget()}

                  <div>
                    <button className="background-button full-width left-text row-10" onClick={() => this.resetWelcomeMessage(null)}>
                      <div className="fixed-column-30">
                        <img className="image-auto-20" alt="GC" src={reloadIconBlue} />
                      </div>
                      <div className="calc-column-offset-30">
                        <p className="description-text-1 cta-color">Reset the Welcome Message</p>
                      </div>
                      <div className="clear" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              {(this.props.fromHelpPrompt) ? (
                <div>
                  {this.renderChatBox(this.props.fromPromptDesigner, this.props.fromCourse)}
                </div>
              ) : (
                <div>
                  {(this.state.showModule) ? (
                    <div>
                      {/*
                      <div className="row-10 standard-border horizontal-padding-4 calc-height-180-of-98">
                        <div className="full-space overflow-y-auto">
                          {this.renderChatBox(null, null, true)}
                        </div>
                      </div>*/}
                      {this.renderChatBox(null, null, true)}
                    </div>
                  ) : (
                    <div>
                      <div className="row-10 standard-border horizontal-padding-4 calc-height-180-of-98">
                        {(this.state.messages && this.state.messages.length > 0) ? (
                          <div className="full-space overflow-y-auto">
                            {this.state.messages.map((value, optionIndex) =>
                              <div key={value._id + 'key'} className="left-text full-width bottom-padding-20">
                                {(value.senderEmail === this.state.emailId) ? (
                                  <div className="left-padding full-width top-padding">
                                    <div className="fixed-column-50">
                                      <p className="description-text-5 bold-text description-text-color top-padding">{convertDateToString(value.createdAt,"daysAgo")}</p>
                                      {/*
                                      <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                      <Link to={cuProfileLink} target="_blank" className="background-button full-width standard-color">
                                        <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconDark} alt="Compass employer icon icon" className="profile-thumbnail-1" />
                                      </Link>*/}
                                    </div>
                                    <div className="calc-column-offset-90">
                                      <Link to={cuProfileLink} target="_blank" className={(this.state.emailId && !this.state.emailId.includes('yourname@placeholder.com')) ? "background-button full-width standard-color" : "background-button full-width standard-color no-pointers"} disabled={(this.state.emailId && !this.state.emailId.includes('yourname@placeholder.com')) ? false : true}>
                                        <p className="description-text-2 bold-text full-width right-text">{this.state.cuFirstName} {this.state.cuLastName}</p>
                                      </Link>

                                      <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message,true)}}></div>

                                    </div>
                                    <div className="fixed-column-40">
                                      <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                      <Link to={cuProfileLink} target="_blank" className="background-button full-width standard-color">
                                        <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30 pin-right" />
                                      </Link>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                ) : (
                                  <div className="left-padding full-width top-padding">
                                    <div className="fixed-column-40">
                                      <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                      <Link to={this.state.prefix + '/profile/' + value.senderUsername} target="_blank" className={(this.state.emailId && !this.state.emailId.includes('yourname@placeholder.com')) ? "background-button full-width standard-color" : "background-button full-width standard-color no-pointers"} disabled={(this.state.emailId && !this.state.emailId.includes('yourname@placeholder.com')) ? false : true}>
                                        <img src={(value.senderPictureURL) ? value.senderPictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                                      </Link>
                                    </div>
                                    <div className="calc-column-offset-40">
                                      <div>
                                        <div className="calc-column-offset-50">
                                          <Link to={this.state.prefix + '/profile/' + value.senderUsername} target="_blank" className="background-button full-width standard-color">
                                            <p className="description-text-2 bold-text">{value.senderFirstName} {value.senderLastName}</p>
                                          </Link>
                                        </div>
                                        <div className="fixed-column-50">
                                          <p className="description-text-5 bold-text description-text-color top-padding">{convertDateToString(value.createdAt,"daysAgo")}</p>
                                        </div>
                                        <div className="clear" />
                                      </div>

                                      <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message)}}></div>
                                      {/*
                                      <p className="description-text-3 left-text">{value.message}</p>*/}
                                    </div>
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                            )}
                            <div className="clear" />

                            {(this.state.activelyMessaging) && (
                              <img src={activelyMessagingGif} alt="GC" className="image-auto-30" />
                            )}

                            <div ref={(el) => { this.messagesEnd = el; }} />
                          </div>
                        ) : (
                          <div>
                            {(this.state.recipients && this.state.recipients.length > 0) && (
                              <div className="top-padding-10-percent">
                                <p className="full-width top-padding heading-text-6 horizontal-padding center-text">No Messages Yet</p>
                                <p className="full-width description-text-3 center-text top-padding">There are no existing messages on this chat thread.</p>
                                {/*
                                {(this.state.recipients.length > 3) ? (
                                  <div>
                                    <p className="full-width top-padding heading-text-6 horizontal-padding center-text">There are {this.state.recipients.length} recipients in this thread.</p>
                                  </div>
                                ) : (
                                  <div>
                                    {this.state.recipients.map((value, optionIndex) =>
                                      <div key={value.email} className={"row-10"}>
                                        <Link to={recipientProfileLinkPrefix + value.username} target="_blank" className="background-button full-width standard-color">
                                          <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="profile-thumbnail-4 center-item" alt="GC" />
                                          <div>
                                            <p className="full-width top-padding heading-text-6 horizontal-padding center-text">{value.firstName} {value.lastName}</p>
                                            <p className="full-width description-text-3 center-text top-padding">You two are not connected to each other.</p>
                                          </div>
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                )}*/}
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="row-10 standard-border horizontal-padding-4">
                        <div className="calc-column-offset-70">
                          <input className="text-field description-text-2 clear-border" type="text" onKeyPress={(e) => (e.key === 'Enter') ? this.sendMessage() : console.log('key clicked')} placeholder="Write a message..." name="messageDraft" value={this.state.messageDraft} onChange={this.formChangeHandler} />
                        </div>
                        <div className="fixed-column-70">
                          <button className={(this.state.messageDraft && this.state.messageDraft !== '') ? "btn btn-small" : "btn btn-small medium-background standard-border"} disabled={(this.state.messageDraft && this.state.messageDraft !== '' && !this.state.isSaving) ? false : true} onClick={() => this.sendMessage()}>Send</button>
                        </div>
                        <div className="clear" />
                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-3 error-color row-5">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-3 cta-color row-5">{this.state.successMessage}</p>}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}


          <Modal
           isOpen={this.state.modalIsOpen}
           onAfterOpen={this.afterOpenModal}
           onRequestClose={this.closeModal}
           className="modal"
           overlayClassName="modal-overlay"
           contentLabel="Example Modal"
         >
           {(this.state.showModalHelpWidget) && (
             <div className="padding-20">
              <p className="heading-text-2">Using Compass A.I. Assistant</p>
              <div className="spacer" /><div className="spacer" />

              {this.renderHelpWidget()}
             </div>
           )}
          </Modal>
        </div>
      )
    }
}

export default withRouter(RenderChat);

// @compass, what should be my career goals?
// @compass, what jobs should I pursue that I make at least {{ salary }} per year?

// @compass
