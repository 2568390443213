import React, {Component} from 'react';
import Modal from 'react-modal';
import Axios from 'axios';
import Switch from 'react-switch';
import withRouter from '../Functions/WithRouter';

const xIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/x-icon.png';
const profileIconBig = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-big.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const careerPathIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class EditProject extends Component {
    constructor(props) {
        super(props)

        this.state = {
          selectedProject: {},
          selectedIndex: 0,

          projectCategoryOptions: [], dateOptions: [], collaboratorOptions: [], hourOptions: [], functionOptions: [], industryOptions: [],
          metricOptions: [{}], platformOptions: [],

          keyMetric1: 0,
          keyMetric2: 0,
          keyMetric3: 0,

          posts: [],
          follows: [],
          // followers: []

        }

        this.changeContinual = this.changeContinual.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.inviteCollaborators = this.inviteCollaborators.bind(this)
        this.renderCollaborators = this.renderCollaborators.bind(this)
        this.renderValues = this.renderValues.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.addItem = this.addItem.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.selectItem = this.selectItem.bind(this)
        this.calculatePreviousMonth = this.calculatePreviousMonth.bind(this)
        this.searchItems = this.searchItems.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData(this.props.selectedProject, this.props.selectedIndex)
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in editProject')

      if (this.props.selectedProject && !prevProps.selectedProject) {
        this.retrieveData(this.props.selectedProject, this.props.selectedIndex)
      } else if (this.props.selectedProject && this.props.selectedProject._id && prevProps.selectedProject && this.props.selectedProject._id !== prevProps.selectedProject._id) {
        this.retrieveData(this.props.selectedProject, this.props.selectedIndex)
      } else if (this.props.selectedIndex !== prevProps.selectedIndex) {
        this.retrieveData(this.props.selectedProject, this.props.selectedIndex)
      } else if (this.props.selectedOpportunity !== prevProps.selectedOpportunity) {
        this.retrieveData(this.props.selectedProject, this.props.selectedIndex)
      }
    }

    retrieveData(selectedProject, selectedIndex) {
      console.log('retrieveData called in EditProject:  ', selectedProject)

      const submitted = this.props.submitted
      const selectedOpportunity = this.props.selectedOpportunity

      const projectCategoryOptions = this.props.projectCategoryOptions
      const dateOptions = this.props.dateOptions
      const collaboratorOptions = this.props.collaboratorOptions
      const hourOptions = this.props.hourOptions
      const functionOptions = this.props.functionOptions
      const industryOptions = this.props.industryOptions
      const userPic = this.props.userPic
      const publicProfile = this.props.publicProfile
      const publicProfileExtent = this.props.publicProfileExtent

      const metricOptions = [{},{ name: 'Social Media Marketing'}]
      const platformOptions = ['','Instagram','Twitter','Snapchat','TikTok','Facebook','LinkedIn']

      // essentials
      const emailId = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const username = localStorage.getItem('username');
      const roleName = localStorage.getItem('roleName');

      console.log('show activeOrg within EditProject: ', activeOrg)

      // unload metrics
      let posts = []
      let follows = []

      if (selectedProject.metrics) {

        selectedProject['metricSet'] = selectedProject.metrics.name
        posts = selectedProject.metrics.values.posts
        follows = selectedProject.metrics.values.follows
      }

      this.setState({
        selectedProject, selectedIndex, selectedOpportunity, submitted,
        projectCategoryOptions, dateOptions, collaboratorOptions, hourOptions, functionOptions, industryOptions, metricOptions,
        platformOptions, publicProfile, publicProfileExtent,
        cuFirstName, cuLastName, activeOrg, emailId, userPic, username, roleName,
        posts, follows
      })
    }

    formChangeHandler = event => {

      console.log('formChangeHandler called: ', event.target.name, event.target.value)

      // if (this.props.formChangeHandler) {
      //   this.props.formChangeHandler(event)
      // }

      let stateObject = null
      if (event.target.name.includes('projectTitle')) {

        let selectedProject = this.state.selectedProject
        selectedProject['name'] = event.target.value
        stateObject = { selectedProject }

        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })
      } else if (event.target.name.includes('projectURL')) {

        let selectedProject = this.state.selectedProject
        selectedProject['url'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })
      } else if (event.target.name.includes('projectImageURL')) {
        console.log('in imageURL')
        let selectedProject = this.state.selectedProject
        selectedProject['imageURL'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })
      } else if (event.target.name.includes('projectVideoURL')) {
        console.log('in videoURL')
        let selectedProject = this.state.selectedProject
        selectedProject['videoURL'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })
      } else if (event.target.name.includes('projectCategory')) {

        let selectedProject = this.state.selectedProject
        selectedProject['category'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })
      } else if (event.target.name.includes('projectDescription')) {

        let selectedProject = this.state.selectedProject
        selectedProject['description'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })
      } else if (event.target.name.includes('startDate')) {

        let selectedProject = this.state.selectedProject
        selectedProject['startDate'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })
      } else if (event.target.name.includes('endDate')) {

        let selectedProject = this.state.selectedProject
        selectedProject['endDate'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })
      } else if (event.target.name.includes('collaboratorEmail')) {
        const collaboratorEmail = event.target.value
        stateObject = { collaboratorEmail }
      } else if (event.target.name.includes('collaboratorCount')) {

        let selectedProject = this.state.selectedProject
        selectedProject['collaboratorCount'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })
      } else if (event.target.name.includes('collaborator')) {

        let selectedProject = this.state.selectedProject
        let collaborators = selectedProject.collaborators
        if (collaborators) {
          console.log('do we ever edit collaborators?')
          // collaborators[index] = event.target.value
        } else {
          collaborators = [event.target.value]
        }

        selectedProject['collaborators'] = collaborators
        stateObject = { selectedProject }

        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })
      } else if (event.target.name.includes('projectHours')) {

        let selectedProject = this.state.selectedProject
        selectedProject['hours'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })
      } else if (event.target.name.includes('projectTotalHours')) {

        let selectedProject = this.state.selectedProject
        selectedProject['totalHours'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })
      } else if (event.target.name.includes('projectFocus')) {
        let selectedProject = this.state.selectedProject
        selectedProject['focus'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })
      } else if (event.target.name.includes('skillTags')) {

        let selectedProject = this.state.selectedProject
        selectedProject['skillTags'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })

      } else if (event.target.name.includes('industryTags')) {

        let selectedProject = this.state.selectedProject
        selectedProject['industryTags'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })
      } else if (event.target.name.includes('projectFunction')) {

        let selectedProject = this.state.selectedProject
        selectedProject['jobFunction'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })

      } else if (event.target.name.includes('projectIndustry')) {

        let selectedProject = this.state.selectedProject
        selectedProject['industry'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })

      } else if (event.target.name === 'metricSet') {

        let selectedProject = this.state.selectedProject
        selectedProject['metricSet'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })

      // } else if (event.target.name === 'postDate') {
      //   this.setState({ postDate: event.target.value, projectHasChanged: true })
      // } else if (event.target.name === 'link') {
      //   this.setState({ link: event.target.value, projectHasChanged: true })
      // } else if (event.target.name === 'platform') {
      //   this.setState({ platform: event.target.value, projectHasChanged: true })
      // } else if (event.target.name === 'views') {
      //   this.setState({ views: event.target.value, projectHasChanged: true })
      // } else if (event.target.name === 'likes') {
      //   this.setState({ likes: event.target.value, projectHasChanged: true })
      // } else if (event.target.name === 'interactions') {
      //   this.setState({ interactions: event.target.value, projectHasChanged: true })
      // } else if (event.target.name === 'accountFollows') {
      //   this.setState({ accountFollows: event.target.value, projectHasChanged: true })
      // } else if (event.target.name === 'metricStartDate') {
      //   this.setState({ metricStartDate: event.target.value, projectHasChanged: true })
      // } else if (event.target.name === 'metricEndDate') {
      //   this.setState({ metricEndDate: event.target.value, projectHasChanged: true })
      } else if (event.target.name === 'projectCareerPath') {

        let selectedProject = this.state.selectedProject
        selectedProject['careerPath'] = event.target.value
        stateObject = { selectedProject }
        // let projectHasChanged = true
        // this.setState({ selectedProject, projectHasChanged })

        this.searchItems(event.target.value)
      } else {
        console.log('there was an error')
        // this.setState({ [event.target.name]: event.target.value })
        stateObject = { [event.target.name]: event.target.value }
      }

      if (stateObject) {
        stateObject['projectHasChanged'] = true
        this.setState(stateObject)
        if (this.props.passProjectState) {
          this.props.passProjectState(stateObject,this.state.selectedIndex)
        }
      }

    }

    searchItems(searchString, type) {
      console.log('searchItems called', searchString, type)

      if (!searchString || searchString === '') {
        this.setState({ searchString, searchIsAnimating: false, careerOptions: null })
      } else {
        this.setState({ searchString, searchIsAnimating: true })

        const search = true

        const self = this
        function officiallyFilter() {
          console.log('officiallyFilter called')

          const excludeMissingOutlookData = true
          const excludeMissingJobZone = true

          Axios.put('/api/careers/search', {  searchString, search, excludeMissingOutlookData, excludeMissingJobZone })
          .then((response) => {
            console.log('Careers query attempted', response.data);

              if (response.data.success) {
                console.log('successfully retrieved careers')

                if (response.data) {

                  let careerOptions = []
                  if (response.data.careers && response.data.careers.length > 0) {
                    careerOptions = response.data.careers
                  }

                  self.setState({ careerOptions, searchIsAnimating: false })
                }

              } else {
                console.log('no career data found', response.data.message)
                self.setState({ searchIsAnimating: false })
              }

          }).catch((error) => {
              console.log('Career query did not work', error);
              self.setState({ searchIsAnimating: false })
          });
        }

        const delayFilter = () => {
          console.log('delayFilter called: ')
          clearTimeout(self.state.timerId)
          self.state.timerId = setTimeout(officiallyFilter, 1000)
        }

        delayFilter();
      }
    }

    changeContinual(index, change, type) {
      console.log('changeContinual called ', index, change, type)

      let selectedProject = this.state.selectedProject
      selectedProject['isContinual'] = change

      const projectHasChanged = true
      this.setState({ selectedProject, projectHasChanged })
    }

    inviteCollaborators(index) {
      console.log('inviteCollaborators called', index)

      if (this.state.collaboratorEmail && this.state.collaboratorEmail !== '') {

        this.setState({ collaboratorErrorMessage: null })

        if (!this.state.collaboratorEmail.includes('@')) {
          this.setState({ collaboratorErrorMessage: 'Please add a valid email' })
        } else {
          //check if user is on GC

          const email = this.state.collaboratorEmail
          let collaboratorEmail = ''

          const projectHasChanged = true

          Axios.get('/api/users/profile/details/' + email)
          .then((response) => {
            if (response.data.success) {

              const responseData = response.data

              const pictureURL = responseData.user.pictureURL
              const firstName = responseData.user.firstName
              const lastName = responseData.user.lastName
              const roleName = responseData.user.roleName

              let collaborators = this.state.selectedProject.collaborators
              if (collaborators) {
                collaborators.push({ pictureURL, firstName, lastName, email, roleName, joined: true })
              } else {
                collaborators = [{ pictureURL, firstName, lastName, email, roleName, joined: true }]
              }

              let selectedProject = this.state.selectedProject
              selectedProject['collaborators'] = collaborators

              this.setState({ selectedProject, collaboratorEmail, projectHasChanged })

            } else {
              let collaborators = this.state.selectedProject.collaborators
              if (collaborators) {
                collaborators.push({ pictureURL: '', firstName: 'Collaborator', lastName: '#' + collaborators.length, email, roleName: 'Student', joined: false })
              } else {
                collaborators = [{ pictureURL: '', firstName: 'Collaborator', lastName: '#1', email, roleName: 'Student', joined: false }]
              }

              let selectedProject = this.state.selectedProject
              selectedProject['collaborators'] = collaborators

              this.setState({ selectedProject, collaboratorEmail, projectHasChanged })
            }
          }).catch((error) => {
              console.log('User fetch did not work', error);
              let collaborators = this.state.selectedProject.collaborators
              if (collaborators) {
                collaborators.push({ pictureURL: '', firstName: 'Collaborator', lastName: '#' + collaborators.length, email, roleName: 'Student', joined: false })
              } else {
                collaborators = [{ pictureURL: '', firstName: 'Collaborator', lastName: '#1', email, roleName: 'Student', joined: false }]
              }

              let selectedProject = this.state.selectedProject
              selectedProject['collaborators'] = collaborators

              this.setState({ selectedProject, collaboratorEmail, projectHasChanged })
          });
        }
      }
    }

    renderCollaborators() {
      console.log('renderCollaborators called')

      let rows = []
      let collaborators = this.state.selectedProject.collaborators
      if (collaborators) {
        for (let i = 1; i <= collaborators.length; i++) {

          const index = i - 1

          rows.push(
            <div key={"collaborator" + i.toString()}>
              <div className="spacer" /><div className="half-spacer" />

              <div className="fixed-column-50">
                <img src={collaborators[i - 1].pictureURL ? collaborators[i - 1].pictureURL : profileIconBig} alt="pic" className="profile-thumbnail-2"/>
              </div>
              <div className="calc-column-offset-100 left-padding">
                <p>{collaborators[i - 1].firstName} {collaborators[i - 1].lastName} ({collaborators[i - 1].email})</p>
                <div className="half-spacer" />
                {(collaborators[i - 1].joined) ? (
                  <p className="description-text-2">{collaborators[i - 1].roleName}</p>
                ) : (
                  <p className="description-text-2">(This user has not joined Guided Compass)</p>
                )}
              </div>
              <div className="fixed-column-50">
                <div className="spacer" />
                <a className="background-link" onClick={() => this.removeItem(index,'collaborator')}>
                  <img src={xIcon} alt="pic" className="image-auto-20"/>
                </a>
              </div>
              <div className="clear" />
            </div>
          )
        }
      }

      return rows
    }

    saveProject() {
      console.log('saveProject', this.state.projectHasChanged)

      this.setState({ clientErrorMessage: '', serverErrorMessage: '', serverSuccessMessage: '', disableSubmit: true })

      if (!this.state.projectHasChanged && !this.state.selectedOpportunity) {
        console.log('no detected changes')
        this.props.finishedEditing(this.state.selectedIndex, false)
      } else {
        console.log('starting save')

        if (!this.state.selectedProject.name || this.state.selectedProject.name === '') {
          console.log('a1')
          this.setState({ clientErrorMessage: 'please add a project name', disableSubmit: false })
        } else if (!this.state.selectedProject.url || this.state.selectedProject.url === '') {
          console.log('a2')
          this.setState({ clientErrorMessage: 'please add a url / web link for this project', disableSubmit: false})
        } else if (!this.state.selectedProject.url.includes("http")) {
          console.log('a3')
          this.setState({ clientErrorMessage: 'please add a valid url', disableSubmit: false})
        } else if (!this.state.selectedProject.category || this.state.selectedProject.category === '') {
          console.log('a4')
          this.setState({ clientErrorMessage: 'please add a category for this project', disableSubmit: false})
        } else if (!this.state.selectedProject.startDate || this.state.selectedProject.startDate === '') {
          console.log('a5')
          this.setState({ clientErrorMessage: 'please indicate when you started working on this project', disableSubmit: false})
        } else if (!this.state.selectedProject.endDate && !this.state.selectedProject.isContinual) {
          console.log('a6')
          this.setState({ clientErrorMessage: 'please indicate when you stopped working on this project', disableSubmit: false})
        } else if (this.state.selectedProject.endDate === '' && !this.state.selectedProject.isContinual) {
          console.log('a7')
          this.setState({ clientErrorMessage: 'please indicate when you stopped working on this project', disableSubmit: false})
        } else if (!this.state.selectedProject.hours || this.state.selectedProject.hours === '') {
          console.log('a8 ')
          this.setState({ clientErrorMessage: 'please add the number of hours you worked on this project', disableSubmit: false})
        } else {
          console.log('actually saving project')

          const emailId = this.state.emailId
          const userFirstName = this.state.cuFirstName
          const userLastName = this.state.cuLastName
          const userPic = this.state.userPic
          const userUsername = this.state.username
          const userRoleName = this.state.roleName

          const _id = this.state.selectedProject._id
          const name = this.state.selectedProject.name
          const url = this.state.selectedProject.url
          const category = this.state.selectedProject.category
          const description = this.state.selectedProject.description
          const startDate = this.state.selectedProject.startDate
          let endDate = this.state.selectedProject.endDate
          const isContinual = this.state.selectedProject.isContinual

          if (isContinual) {

            const currentMonth = new Date().getMonth()
            const year = new Date().getFullYear()

            let month = ''

            if (currentMonth === 0) {
              month = 'January'
            } else if (currentMonth === 1) {
              month = 'February'
            } else if (currentMonth === 2) {
              month = 'March'
            } else if (currentMonth === 3) {
              month = 'April'
            } else if (currentMonth === 4) {
              month = 'May'
            } else if (currentMonth === 5) {
              month = 'June'
            } else if (currentMonth === 6) {
              month = 'July'
            } else if (currentMonth === 7) {
              month = 'August'
            } else if (currentMonth === 8) {
              month = 'September'
            } else if (currentMonth === 9) {
              month = 'October'
            } else if (currentMonth === 10) {
              month = 'November'
            } else if (currentMonth === 11) {
              month = 'December'
            }

            endDate = month + ' ' + year
          }

          const collaborators = this.state.selectedProject.collaborators
          let collaboratorCount = this.state.selectedProject.collaboratorCount
          if (this.state.activeOrg !== 'c2c' && collaborators) {
            collaboratorCount = collaborators.length
          }

          const hours = this.state.selectedProject.hours
          const totalHours = this.state.selectedProject.totalHours
          const focus = this.state.selectedProject.focus
          const skillTags = this.state.selectedProject.skillTags
          //const industryTags = this.state.selectedProject.industryTags
          const careerPath = this.state.selectedProject.careerPath
          const jobFunction = this.state.selectedProject.jobFunction
          const industry = this.state.selectedProject.industry
          console.log('test 2')
          const orgCode = this.state.activeOrg
          const imageURL = this.state.selectedProject.imageURL
          const videoURL = this.state.selectedProject.videoURL

          let metrics = null
          if (this.state.selectedProject.metricSet) {
            // posts: postDate, link, platform, views, likes, interactions
            // follows: metricStartDate, metricEndDate, platform, accountFollows, interactions

            const posts = this.state.posts
            const follows = this.state.follows
            metrics = { name: this.state.selectedProject.metricSet, values: { posts, follows }}

          }

          let publicExtent = 'Private'
          if (this.state.publicProfile && this.state.publicProfileExtent === 'Public') {
            publicExtent = 'Public'
          } else if (this.state.publicProfile && this.state.publicProfileExtent === 'Only Connections and Members') {
            publicExtent = 'Members Only'
          }
          console.log('show me values publc', this.state.publicProfile, this.state.publicProfileExtent)
          const projectObject = {
            _id, emailId, userFirstName, userLastName, userPic, userUsername, userRoleName, name, url, category, description, startDate, endDate, isContinual, collaborators, collaboratorCount,
            imageURL, videoURL, hours, totalHours, focus, skillTags, careerPath, jobFunction, industry, orgCode, metrics, publicExtent
          }

          Axios.post('/api/projects', projectObject)
          .then((response) => {
            console.log('test 4')
            if (response.data.success) {
              //save values
              console.log('Project save worked ', response.data);
              //report whether values were successfully saved

              if (!this.state.selectedOpportunity) {
                this.setState({ disableSubmit: false })
                this.props.finishedEditing(this.state.selectedIndex, true)
                if (window.location.pathname.includes('/apply')) {
                  this.props.passData('project', projectObject, this.state.selectedIndex)
                }
              } else {
                // submit to opportunity

                const cuFirstName = this.state.cuFirstName
                const cuLastName = this.state.cuLastName
                const contributorFirstName = this.state.selectedOpportunity.contributorFirstName
                const contributorLastName = this.state.selectedOpportunity.contributorLastName
                const contributorEmail = this.state.selectedOpportunity.contributorEmail
                const postingId = this.state.selectedOpportunity._id
                const postingName = this.state.selectedOpportunity.name
                const projectId = response.data._id
                const projectName = name
                const orgContactEmail = this.state.orgContactEmail

                const orgCode = this.state.activeOrg
                // const url = this.state.projectURL
                // const category = this.state.projectCategory
                // const description = this.state.projectDescription
                // const startDate = this.state.startDate
                // const endDate = this.state.endDate
                // const collaborators = this.state.collaborators
                // const collaboratorCount = this.state.collaboratorCount
                // const hours = this.state.projectHours

                //save submission
                Axios.post('/api/projects/submit', {
                  emailId, cuFirstName, cuLastName, userPic, contributorFirstName, contributorLastName, contributorEmail,
                  postingId, postingName, projectId, projectName, orgContactEmail, totalHours, focus, metrics,
                  orgCode, url, category, description, startDate, endDate, collaborators, collaboratorCount, hours,
                  imageURL, videoURL
                })
                .then((response) => {

                  if (response.data.success) {
                    //save values
                    console.log('Project submit worked here ', response.data);

                    let serverSuccessMessage: 'Project successfully submitted!'
                    if (this.state.submitted) {
                      serverSuccessMessage = 'Project successfully updated!'
                    }

                    const selectedProject = {}

                    this.setState({ disableSubmit: false, serverSuccessMessage, selectedProject })
                    this.props.finishedSubmitting(this.state.selectedProject)

                  } else {
                    console.log('project did not save successfully')
                    this.setState({ serverErrorMessage: response.data.message, disableSubmit: false })
                  }

                }).catch((error) => {
                    console.log('Project save did not work', error);
                    this.setState({ serverErrorMessage: error, disableSubmit: false })
                });
              }

            } else {
              console.log('project did not save successfully')
              this.setState({

                  serverSuccessText: false,
                  serverErrorMessageText: response.data.message, disableSubmit: false
              })
            }

          }).catch((error) => {
              console.log('Project save did not work', error);
              this.setState({ serverSuccessText: false, serverErrorMessage: error, disableSubmit: false })
          });
        }
      }
    }

    closeModal() {

      this.setState({ modalIsOpen: false, showCareerPath: false, showJobFunction: false,
        showIndustry: false, showMetricsInfo: false, addMetric: false, skillTagsInfo: false
      });
    }

    addItem(type) {
      console.log('addItem called', type)

      this.setState({ errorMessage: null, successMessage: null })

      if (type === 'Social Media Posts') {
        let posts = this.state.posts

        let postDate = this.state.postDate
        let link = this.state.link
        let platform = this.state.platform
        let views = this.state.views
        let likes = this.state.likes
        let interactions = this.state.interactions

        if (!postDate || postDate === '') {
          this.setState({ errorMessage: 'Please add a post date'})
        } else if (!link || link === '') {
          this.setState({ errorMessage: 'Please add a link'})
        } else if (!platform || platform === '') {
          this.setState({ errorMessage: 'Please add a platform'})
        } else if (!views || views === '') {
          this.setState({ errorMessage: 'Please add views'})
        } else if (!likes || likes === '') {
          this.setState({ errorMessage: 'Please add likes'})
        } else if (!interactions || interactions === '') {
          this.setState({ errorMessage: 'Please add interactions'})
        } else {
          // it works

          let successMessage = 'Successfully added post. Save the project to save changes.'
          if (this.state.editIndex || this.state.editIndex === 0) {
            posts[this.state.editIndex] = { postDate, link, platform, views, likes, interactions }
            successMessage = 'Successfully edited post. Save the project to save changes.'
          } else {
            posts.push({ postDate, link, platform, views, likes, interactions })
          }

          let editIndex = null
          postDate = ''
          link = ''
          platform = ''
          views = ''
          likes = ''
          interactions = ''

          this.setState({ editIndex, posts, postDate, link, platform, views, likes, interactions, successMessage
          })
        }


        // let keyMetric1 = this.state.keyMetric1
        // let keyMetric2 = this.state.keyMetric2 + 10
        // let keyMetric3 = this.state.keyMetric3 + 10
        //
        // this.setState({ posts, keyMetric1, keyMetric2, keyMetric3 })
      } else if (type === 'Your Follows of Other Social Media Accounts') {
        let follows = this.state.follows

        let metricStartDate = this.state.metricStartDate
        let metricEndDate = this.state.metricEndDate
        let platform = this.state.platform
        let accountFollows = this.state.accountFollows
        let interactions = this.state.interactions

        if (!metricStartDate || metricStartDate === '') {
          this.setState({ errorMessage: 'Please add a start date for the date range'})
        } else if (!metricEndDate || metricEndDate === '') {
          this.setState({ errorMessage: 'Please add an end date for the date range'})
        } else if (!platform || platform === '') {
          this.setState({ errorMessage: 'Please add a platform'})
        } else if (!accountFollows || accountFollows === '') {
          this.setState({ errorMessage: 'Please add follows'})
        } else if (!interactions || interactions === '') {
          this.setState({ errorMessage: 'Please add interactions'})
        } else {
          // it works

          let successMessage = 'Successfully added follows. Save the project to save changes.'
          if (this.state.editIndex || this.state.editIndex === 0) {
            follows[this.state.editIndex] = { metricStartDate, metricEndDate, platform, accountFollows, interactions }
            successMessage = 'Successfully edited follows. Save the project to save changes.'
          } else {
            follows.push({ metricStartDate, metricEndDate, platform, accountFollows, interactions })
          }

          let editIndex = null

          metricStartDate = ''
          metricEndDate = ''
          platform = ''
          accountFollows = ''
          interactions = ''

          this.setState({ editIndex, metricStartDate, metricEndDate, platform, accountFollows, interactions, successMessage
          })

        }
      // } else if (type === 'Followers Gained By Platform') {
      //   let followers = this.state.followers
      //   followers.push({})
      //
      //   let keyMetric1 = this.state.keyMetric1 + 30
      //   let keyMetric2 = this.state.keyMetric2
      //   let keyMetric3 = this.state.keyMetric3
      //
      //   this.setState({ followers, keyMetric1, keyMetric2, keyMetric3 })
      }
    }

    removeItem(type, index) {
      console.log('removeItem called', type, index)

      this.setState({ errorMessage: null, successMessage:  null })

      if (type === 'collaborator') {
        let selectedProject = this.state.selectedProject
        let collaborators = this.state.selectedProject[index].collaborators
        collaborators.splice(index, 1)
        selectedProject['collaborators'] = collaborators
        this.setState({ selectedProject })
      } else if (type === 'Social Media Posts') {
        let posts = this.state.posts
        posts.splice(this.state.editIndex, 1)

        let editIndex = null
        let successMessage = 'Metric successfully deleted'
        this.setState({ posts, editIndex, successMessage })

      } else if (type === 'Your Follows of Other Social Media Accounts') {
        let follows = this.state.follows
        follows.splice(this.state.editIndex,1)

        let posts = this.state.posts
        posts.splice(this.state.editIndex, 1)

        let editIndex = null
        let successMessage = 'Metric successfully deleted'
        this.setState({ follows, editIndex, successMessage })
      }
    }

    selectItem(valueType, index) {
      console.log('selectItem called', valueType, index)

      this.setState({ errorMessage: null, successMessage: null })

      if (valueType === 'Social Media Posts') {
        // assign values

        const postDate = this.state.posts[index].postDate
        const link = this.state.posts[index].link
        const platform = this.state.posts[index].platform
        const views = this.state.posts[index].views
        const likes = this.state.posts[index].likes
        const interactions = this.state.posts[index].interactions

        const modalIsOpen = true
        const showJobFunction = false
        const showIndustry = false
        const showMetricsInfo = false
        const addMetric = true
        const editIndex = index
        const metricType = valueType

        this.setState({ postDate, link, platform, views, likes, interactions,
          modalIsOpen, showJobFunction, showIndustry, showMetricsInfo, addMetric, editIndex, metricType
        })

      } else if (valueType === 'Your Follows of Other Social Media Accounts') {
        // follows

        const metricStartDate = this.state.follows[index].metricStartDate
        const metricEndDate = this.state.follows[index].metricEndDate
        const platform = this.state.follows[index].platform
        const accountFollows = this.state.follows[index].accountFollows
        const interactions = this.state.follows[index].interactions

        const modalIsOpen = true
        const showJobFunction = false
        const showIndustry = false
        const showMetricsInfo = false
        const addMetric = true
        const editIndex = index
        const metricType = valueType

        this.setState({ metricStartDate, metricEndDate, platform, accountFollows, interactions,
          modalIsOpen, showJobFunction, showIndustry, showMetricsInfo, addMetric, editIndex, metricType
        })

      }
    }

    calculatePreviousMonth(startDate) {
      console.log('calculatePreviousMonth called', startDate)

      // startDate is formatted November 2020
      let previousMonthDate = startDate
      let month = startDate.split(" ")[0]
      const months = ['January','Febuary','March','April','May','June','July','August','October','November','December']

      for (let i = 1; i <= months.length; i++) {
        if (month === months[i - 1]) {
          let previousIndex = i - 2
          if (previousIndex < 0) {
            previousIndex = 11
          }
          const newMonth = months[previousIndex].substring(0,3)
          previousMonthDate =  newMonth + ' ' + startDate.split(" ")[1]
        }
      }

      return previousMonthDate
    }

    renderValues(valueType) {
      console.log('renderValues called', valueType)

      if (valueType === 'Social Media Posts') {

        return (
          <div key={valueType}>
            {(this.state.posts.length > 0) && (
              <div>
                <div className="right-padding-20 description-text-2">
                  <div className="col span-2-of-10 clear-padding clear-margin">
                    <label className="half-bold-text">Date</label>
                  </div>
                  <div className="col span-3-of-10 clear-padding clear-margin">
                    <label className="half-bold-text">Link</label>
                  </div>
                  <div className="col span-2-of-10 clear-padding clear-margin">
                    <label className="half-bold-text">Platform</label>
                  </div>
                  <div className="col span-1-of-10 clear-padding clear-margin">
                    <label className="half-bold-text">Views</label>
                  </div>
                  <div className="col span-1-of-10 clear-padding clear-margin">
                    <label className="half-bold-text">Likes</label>
                  </div>
                  <div className="col span-1-of-10 clear-padding clear-margin">
                    <label className="half-bold-text">Interactions</label>
                  </div>
                  <div className="clear" />
                </div>

                {this.state.posts.map((value, index) =>
                  <div>
                    <a className="background-link cta-color half-bold-text" onClick={() => this.selectItem(valueType, index)}>
                      <div className="right-padding-20 description-text-2">
                        <div className="col span-2-of-10 clear-padding clear-margin">
                          <p>{(value.postDate) ? value.postDate.substring(0,10) : ""}</p>
                        </div>
                        <div className="col span-3-of-10 clear-padding clear-margin">
                          <p>{value.link}</p>
                        </div>
                        <div className="col span-2-of-10 clear-padding clear-margin">
                          <p>{value.platform}</p>
                        </div>
                        <div className="col span-1-of-10 clear-padding clear-margin">
                          <p>{value.views}</p>
                        </div>
                        <div className="col span-1-of-10 clear-padding clear-margin">
                          <p>{value.likes}</p>
                        </div>
                        <div className="col span-1-of-10 clear-padding clear-margin">
                          <p>{value.interactions}</p>
                        </div>
                        <div className="clear" />
                      </div>
                    </a>
                  </div>
                )}

                <div className="spacer" /><div className="spacer" />
              </div>
            )}

            <div className="clear" />
          </div>
        )
      } else if (valueType === 'Your Follows of Other Social Media Accounts') {

        return (
          <div key={valueType}>
            {(this.state.follows.length > 0) && (
              <div>
                <div className="right-padding-20 description-text-2">
                  <div className="col span-2-of-10 clear-padding clear-margin">
                    <label className="half-bold-text">Start Date</label>
                  </div>
                  <div className="col span-2-of-10 clear-padding clear-margin">
                    <label className="half-bold-text">End Date</label>
                  </div>
                  <div className="col span-2-of-10 clear-padding clear-margin">
                    <label className="half-bold-text">Platform</label>
                  </div>
                  <div className="col span-2-of-10 clear-padding clear-margin">
                    <label className="half-bold-text">Accts Followed</label>
                  </div>
                  <div className="col span-2-of-10 clear-padding clear-margin">
                    <label className="half-bold-text">Interactions / Acct</label>
                  </div>

                  <div className="clear" />
                </div>

                {this.state.follows.map((value, index) =>
                  <div>
                    <a className="background-link cta-color half-bold-text" onClick={() => this.selectItem(valueType, index)}>
                      <div className="right-padding-20 description-text-2">
                        <div className="col span-2-of-10 clear-padding clear-margin">
                          <p>{(value.metricStartDate) ? value.metricStartDate.substring(0,10) : ""}</p>
                        </div>
                        <div className="col span-2-of-10 clear-padding clear-margin">
                          <p>{(value.metricEndDate) ? value.metricEndDate.substring(0,10) : ""}</p>
                        </div>
                        <div className="col span-2-of-10 clear-padding clear-margin">
                          <p>{value.platform}</p>
                        </div>
                        <div className="col span-2-of-10 clear-padding clear-margin">
                          <p>{value.accountFollows}</p>
                        </div>
                        <div className="col span-2-of-10 clear-padding clear-margin">
                          <p>{value.interactions}</p>
                        </div>
                        <div className="clear" />
                      </div>
                    </a>
                  </div>
                )}

                <div className="spacer" /><div className="spacer" />

              </div>
            )}

            <div className="clear" />
          </div>
        )
      }
    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type === 'career') {

        const searchObject = passedItem
        // const projectCareerPath = passedItem.name
        let selectedProject = this.state.selectedProject
        selectedProject['careerPath'] = passedItem.name

        const projectCareerPathReady = true
        const careerOptions = null



        this.setState({ searchObject, selectedProject, projectCareerPathReady, careerOptions })

      }
    }


    render() {

      return (
        <div>
          {(!this.state.selectedOpportunity) && (
            <div>
              <div className="flex-container">
                <div className="flex-75">
                  {(this.state.selectedProject.name && this.state.selectedProject.name !== '') ? (
                    <p className="heading-text-5">Edit {this.state.selectedProject.name}</p>
                  ) : (
                    <p className="heading-text-5">Edit project below</p>
                  )}
                </div>
                <div className="flex-25 right-text right-padding-20">
                  <button type="button" onClick={() => this.saveProject()} className="background-button clear-margin">
                    <div>
                      <p className="heading-text-5 cta-color">Done</p>
                    </div>
                  </button>
                </div>
              </div>

              <div className="spacer" /><div className="half-spacer" />
            </div>
          )}

          <div className="edit-profile-row">
            <label className="profile-label">Project Title<label className="error-color bold-text">*</label></label>
            <input className="text-field" type="text" placeholder="Project Title" name={"projectTitle"} value={this.state.selectedProject.name} onChange={this.formChangeHandler} />
          </div>

          <div className="edit-profile-row">
            <label className="profile-label">Project URL<label className="error-color bold-text">*</label></label>
            <p className="description-text-3 bottom-padding">Add a link from your own website or a file sharing site like Google Drive that details your project.</p>

            <input className="text-field" type="text" placeholder="" name={"projectURL"} value={this.state.selectedProject.url} onChange={this.formChangeHandler} />
            <label className="error-color">Please make sure that the link allows anyone to view the contents!</label>
            {(this.state.selectedProject.url && this.state.selectedProject.url !== '' && !this.state.selectedProject.url.startsWith('http')) && (
              <div>
                <p className="error-message">Please start your link with http</p>
              </div>
            )}
          </div>

          <div>
            <div className="edit-profile-row">
              <label className="profile-label">Project Header Image (Optional) (Dimensions: 600px x 250px)</label>
              <p className="description-text-2 bottom-padding">Add a link to a large landscape image that showcases or represents your project. If entered correctly, the image will appear below the entry field.</p>

              <input className="text-field" type="text" placeholder="" name={"projectImageURL"} value={this.state.selectedProject.imageURL} onChange={this.formChangeHandler} />

              {(this.state.selectedProject.imageURL) && (
                <div className="row-5">
                  <img src={this.state.selectedProject.imageURL} alt="GC" className="image-full-auto" />
                </div>
              )}

              <label className="profile-label">Video URL (Optional)</label>
              <p className="description-text-2 bottom-padding">Add a link to a video demonstration of your project. Use the "Embed" version of the link. If entered correctly, the video will appear below the entry field.</p>

              <input className="text-field" type="text" placeholder="" name={"projectVideoURL"} value={this.state.selectedProject.videoURL} onChange={this.formChangeHandler} />

              {(this.state.selectedProject.videoURL) && (
                <div className="row-5">
                  <div className="video-container">
                    <iframe
                      title="videoLink"
                      className="video-iframe"
                      src={this.state.selectedProject.videoURL}
                      frameBorder="0"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="edit-profile-row">
            <label className="profile-label">Project Description</label>
            <textarea className="text-field" name={"projectDescription"} placeholder="Project description" value={this.state.selectedProject.description} onChange={this.formChangeHandler}/>
          </div>

          <div className="edit-profile-row">
            <label className="profile-label">Are you still working on this project?</label>
            <Switch
              onChange={(change) => this.changeContinual(this.state.selectedIndex, change,'project')}
              checked={this.state.selectedProject.isContinual}
              id="normal-switch"
            />
          </div>

          <div className="edit-profile-row">
            <div className="name-container">
              <label className="profile-label">Start Date<label className="error-color bold-text">*</label></label>
              <select name={"startDate"} className="dropdown" value={this.state.selectedProject.startDate} onChange={this.formChangeHandler}>
                  {this.state.dateOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
              </select>
            </div>

            {(this.state.selectedProject.isContinual) ? (
              <div className="name-container">
                {(!this.state.isMobile) && (
                  <div>
                    <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />
                  </div>
                )}

                <p className="heading-text-5">Still Working On This</p>
              </div>
            ) : (
              <div className="name-container">
                <label className="profile-label">End Date<label className="error-color bold-text">*</label></label>
                <select name={"endDate"} className="dropdown" value={this.state.selectedProject.endDate} onChange={this.formChangeHandler}>
                    {this.state.dateOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                </select>
              </div>
            )}

            <div className="clear" />
          </div>

          <div className="edit-profile-row">
            <div className="name-container">
              {(this.state.activeOrg !== 'c2c') ? (
                <div>
                  <label className="profile-label">Add Collaborators</label>
                  <div>
                    <div className="calc-column-offset-80">
                      <input className="text-field" type="text" placeholder="Add email..." name={"collaboratorEmail"} value={this.state.collaboratorEmail} onChange={this.formChangeHandler} />
                    </div>
                    {(this.state.selectedOpportunity) ? (
                      <div className="fixed-column-80 left-padding">
                        <button type="button" className={(this.state.collaboratorEmail && this.state.collaboratorEmail !== '') ? "btn btn-squarish" : "btn btn-squarish clear-border unselected-background"} onClick={() => this.inviteCollaborators(this.state.selectedIndex)}>Add</button>
                      </div>
                    ) : (
                      <div className="fixed-column-80 left-padding">
                        <button type="button" className={(this.state.collaboratorEmail && this.state.collaboratorEmail !== '') ? "btn btn-squarish" : "btn btn-squarish clear-border unselected-background"} disabled={this.state.isSaving} onClick={() => this.inviteCollaborators(this.state.selectedIndex)}>Add</button>
                      </div>
                    )}

                    <div className="clear" />
                  </div>

                  {(this.state.collaboratorErrorMessage) && <p className="error-message">{this.state.collaboratorErrorMessage}</p>}

                  <div>
                    {this.renderCollaborators(this.state.selectedIndex)}
                  </div>
                </div>
              ) : (
                <div>
                  <label className="profile-label"># of Collaborators (Not Including You)</label>
                  <select name={"collaboratorCount"} className="dropdown" value={this.state.selectedProject.collaboratorCount} onChange={this.formChangeHandler}>
                      {this.state.collaboratorOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                  </select>
                </div>
              )}
            </div>
            <div className="name-container">
              <label className="profile-label">Number of Hours I Committed<label className="error-color bold-text">*</label></label>
              <select name={"projectHours"} className="dropdown" value={this.state.selectedProject.hours} onChange={this.formChangeHandler}>
                  {this.state.hourOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
              </select>
            </div>
            <div className="clear" />
          </div>

          {(this.state.selectedProject.collaborators && this.state.selectedProject.collaborators.length > 0) && (
            <div className="edit-profile-row">
              <div className="name-container">
                <label className="profile-label">Number of Total Hours Across Team Members<label className="error-color bold-text">*</label></label>
                <select name={"projectTotalHours"} className="dropdown" value={this.state.selectedProject.totalHours} onChange={this.formChangeHandler}>
                    {this.state.hourOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                </select>
              </div>
              <div className="name-container">
                <label className="profile-label">Did you focus on something different than team members?<label className="error-color bold-text">*</label></label>
                <input className="text-field" type="text" placeholder="Your focus during the project..." name={"projectFocus"} value={this.state.selectedProject.focus} onChange={this.formChangeHandler} />
              </div>
              <div className="clear" />
            </div>
          )}

          <div className="edit-profile-row">

            <div>
              <div className="float-left">
                <label className="profile-label">Skill Tags</label>
              </div>
              <div>
                <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                <div className="float-left left-margin noti-bubble-info-7-9">
                  <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showJobFunction: false, showIndustry: false, showMetricsInfo: false, addMetric: false, skillTagsInfo: true })}>
                    <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                  </a>
                </div>

              </div>
              <div className="clear" />
            </div>
            <input className="text-field" type="text" placeholder="add skills acquired separated by commas" name={"skillTags"} value={this.state.selectedProject.skillTags} onChange={this.formChangeHandler} />
          </div>

          <div className="row-10">
            <div className="container-left">
              <label className="profile-label">Project Category<label className="error-color bold-text">*</label></label>
              <select name={"projectCategory"} className="dropdown" value={this.state.selectedProject.category} onChange={this.formChangeHandler}>
                  {this.state.projectCategoryOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
              </select>
            </div>

            <div className="container-right">
              <div className="float-left">
                <label className="profile-label">Select a Career Path</label>
              </div>
              <div>
                <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                <div className="float-left left-margin noti-bubble-info-7-9">
                  <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showCareerPath: true })}>
                    <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                  </a>
                </div>

              </div>
              <div className="clear" />

              {/*<input className="text-field" type="text" placeholder="Search career paths..." name={"projectCareerPath"} value={this.state.projectCareerPath} onChange={this.formChangeHandler} />*/}

              <div className="standard-border">
                <div className="fixed-column-35">
                  <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                  <img src={searchIcon} alt="Compass search icon" className="image-auto-18 left-margin"/>
                </div>
                <div className="calc-column-offset-35">
                  <input type="text" className="text-field clear-border" placeholder={"Search career path..."} name="projectCareerPath" value={this.state.selectedProject.careerPath} onChange={this.formChangeHandler}/>
                </div>
                <div className="clear" />
              </div>

              {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
              {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

              {(this.state.searchIsAnimating) ? (
                <div className="flex-container flex-center full-space">
                  <div>
                    <div className="super-spacer" />

                    <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                    <div className="spacer" /><div className="spacer" /><div className="spacer" />
                    <p className="center-text cta-color bold-text">Searching...</p>

                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    {(this.state.careerOptions) && (
                      <div className="card top-margin">
                        {this.state.careerOptions.map((value, optionIndex) =>
                          <div key={value._id} className="left-text bottom-margin-5 full-width">
                            <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value, 'career')}>
                              <div className="full-width">
                                <div className="fixed-column-40">
                                  <div className="mini-spacer" />
                                  <img src={careerPathIconDark} alt="Compass employer icon icon" className="image-auto-22" />
                                </div>
                                <div className="calc-column-offset-40">
                                  <p className="cta-color">{value.name}</p>
                                </div>
                              </div>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {/*
                  <div>

                    {this.renderTags('career')}
                    <div className="clear" />

                  </div>*/}

                </div>
              )}
            </div>
            <div className="clear" />
          </div>

          <div className="row-10">
            <div className="container-left">
              <div className="float-left">
                <label className="profile-label">Closest Job Function</label>
              </div>
              <div>
                <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                <div className="float-left left-margin noti-bubble-info-7-9">
                  <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showJobFunction: true, showIndustry: false, showMetricsInfo: false, addMetric: false })}>
                    <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                  </a>
                </div>
              </div>
              <div className="clear" />

              <select name={"projectFunction"} className="dropdown" value={this.state.selectedProject.jobFunction} onChange={this.formChangeHandler}>
                  {this.state.functionOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
              </select>
            </div>
            <div className="container-right">
              <div className="float-left">
                <label className="profile-label">Closest Industry</label>
              </div>
              <div>
                <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                <div className="float-left left-margin noti-bubble-info-7-9">
                  <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showJobFunction: false, showIndustry: true, showMetricsInfo: false, addMetric: false })}>
                    <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                  </a>
                </div>

              </div>
              <div className="clear" />

              <select name={"projectIndustry"} className="dropdown" value={this.state.selectedProject.industry} onChange={this.formChangeHandler}>
                  {this.state.industryOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
              </select>
            </div>

            <div className="clear" />
          </div>
          {/*{console.log('Carrie, what is wrong in EditProject: ', this.state.selectedProject, this.state.selectedProject.category, this.state.activeOrg)}
          {(this.state.selectedProject.category === 'Marketing' && this.state.activeOrg === 'unite-la') && (
          {(this.state.selectedProject.category === 'Marketing') && (
            <div>
              {console.log('Carrie x2: ', this.state.selectedProject, this.state.selectedProject.category, this.state.activeOrg)}
              <div className="spacer" /><div className="half-spacer" />
              <hr className="clear-margin clear-padding cta-border-color" />
              <div className="spacer" /><div className="half-spacer" />

              <div className="edit-profile-row">
                <div className="float-left">
                  <label className="profile-label heading-text-3">Add Project Metrics & Milestones</label>
                </div>
                <div>
                  <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                  <div className="float-left left-margin noti-bubble-info-7-9">
                    <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showJobFunction: false, showIndustry: false, showMetricsInfo: true, addMetric: false })}>
                      <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                    </a>
                  </div>

                </div>
                <div className="clear" />
              </div>

              <div>
                <div className="col span-1-of-3 box clear-padding">
                  <label className="profile-label">{this.state.selectedProject.category} Metrics Set<label className="error-color bold-text">*</label></label>
                  <select name="metricSet" className="dropdown" value={this.state.selectedProject.metricSet} onChange={this.formChangeHandler}>
                      {this.state.metricOptions.map(value => <option key={value.name} value={value.name}>{value.name}</option>)}
                  </select>
                </div>
                <div className="clear" />
              </div>

              {(this.state.selectedProject.metricSet === 'Social Media Marketing') && (
                <div>
                  <div>
                    <div>
                      <div className="float-left">
                        <label className="profile-label">1. Add Your Social Media Posts</label>
                      </div>
                      <div>
                        <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        <div className="float-left left-margin noti-bubble-info">
                          <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showJobFunction: false, showIndustry: false, showMetricsInfo: false, addMetric: true, editIndex: null, metricType: 'Social Media Posts' })}>
                            <img src={addIconBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                          </a>
                        </div>

                      </div>
                      <div className="clear" />
                    </div>

                    {this.renderValues("Social Media Posts")}

                  </div>

                  <div>
                    <div>
                      <div className="float-left">
                        <label className="profile-label">2. Add Your Follows of Other Social Media Accounts</label>
                      </div>
                      <div>
                        <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        <div className="float-left left-margin noti-bubble-info">
                          <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showJobFunction: false, showIndustry: false, showMetricsInfo: false, addMetric: true, editIndex: null, metricType: 'Your Follows of Other Social Media Accounts' })}>
                            <img src={addIconBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                          </a>
                        </div>

                      </div>
                      <div className="clear" />
                    </div>

                    {this.renderValues("Your Follows of Other Social Media Accounts")}

                  </div>

                </div>
              )}

            </div>
          )}*/}

          {(this.state.selectedOpportunity) ? (
            <div>
              {this.state.clientErrorMessage !== '' && <p className="error-message">{this.state.clientErrorMessage}</p>}
              {this.state.serverSuccessMessage !== '' && <p className="success-message">{this.state.serverSuccessMessage}</p>}
              {this.state.serverErrorMessage !== '' && <p className="error-message">{this.state.serverErrorMessage}</p>}
              <button className={(this.state.disableSubmit) ? "btn btn-primary clear-border unselected-background" : "btn btn-primary"} disabled={this.state.disableSubmit} onClick={() => this.saveProject()}>{this.state.submitted ? "Update Your Solution" : "Submit Your Solution"}</button>
            </div>
          ) : (
            <div>
              {(window.location.pathname.includes('/apply')) ? (
                <div>
                  <div className="spacer" /><div className="half-spacer" />
                  {this.state.clientErrorMessage !== '' && <p className="error-message">{this.state.clientErrorMessage}</p>}
                  {this.state.serverSuccessMessage !== '' && <p className="success-message">{this.state.serverSuccessMessage}</p>}
                  {this.state.serverErrorMessage !== '' && <p className="error-message">{this.state.serverErrorMessage}</p>}

                  <div className="bottom-padding">
                    <button className="btn btn-secondary" type="button" disabled={this.state.disableSubmit} onClick={() => this.saveProject()}>Save Project</button>
                  </div>
                </div>
              ) : (
                <div>

                  <div className="spacer" /><div className="half-spacer" />

                  {(this.state.clientErrorMessage && this.state.clientErrorMessage !== '') && <p className="error-message top-margin">{this.state.clientErrorMessage}</p>}
                  {(this.state.serverSuccessMessage && this.state.serverSuccessMessage !== '') && <p className="success-message top-margin">{this.state.serverSuccessMessage}</p>}
                  {(this.state.serverErrorMessage && this.state.serverErrorMessage !== '') && <p className="error-message top-margin">{this.state.serverErrorMessage}</p>}

                  <div className="row-20">
                    <button className="btn btn-secondary pin-right" type="button" disabled={this.state.disableSubmit} onClick={() => this.saveProject()}>Save Project & Collapse</button>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="spacer" /><div className="spacer" />


          <Modal
           isOpen={this.state.modalIsOpen}
           onAfterOpen={this.afterOpenModal}
           onRequestClose={this.closeModal}
           className="modal"
           overlayClassName="modal-overlay"
           contentLabel="Example Modal"
           ariaHideApp={false}
         >

           {(this.state.showCareerPath) && (
             <div key="showJobFunction" className="full-width padding-20">
               <p className="heading-text-2">Career Path</p>
               <div className="spacer" />
               <p>Tagging a <label className="half-bold-text cta-color">career path</label>, if you make your project public, allows your project to be discoverable in each of the career path pages. <a href={window.location.protocol + "//" + window.location.host + "/app/careers/Chief Executives"} target="_blank">Chief Executives</a>, for example.</p>
             </div>
           )}

          {(this.state.showJobFunction) && (
            <div key="showJobFunction" className="full-width padding-20">
              <p className="heading-text-2">Job Function</p>
              <div className="spacer" />
              <p>We define <label className="half-bold-text cta-color">job functions</label> as a category of work that requires similar skills. It can be thought of as synonymous with "departments" within a company. Functions can be the same across different industries. Examples of functions include sales, marketing, finance, engineering, and design.</p>
            </div>
          )}

          {(this.state.showIndustry) && (
            <div key="showIndustry" className="full-width padding-20">
              <p className="heading-text-2">Industry</p>
              <div className="spacer" />
              <p>We define <label className="half-bold-text cta-color">industry</label> as a category of companies that are related based on their primary business activitiees. Companies are generally grouped by their sources of revenue. For example, Nike would fall under "Fashion & Apparel" and Netflix would fall under "Other Entertainment".</p>
            </div>
          )}

          {(this.state.showMetricsInfo) && (
            <div key="showMetricsInfo" className="full-width padding-20">
              <p className="heading-text-2">Metrics Explained</p>
              <div className="spacer" /><div className="spacer" /><div className="half-spacer" />
              <p>Companies are simply the combination of many large projects with defined roles. We think of school and personal projects this way.</p>
              <div className="spacer" /><div className="spacer" />
              <p>This <label className="half-bold-text cta-color">metrics</label> section is an opportunity to align your projects with employers'. Just like employees do at companies, report the key metrics that will impress. At some point, your metrics will either be strong enough to either generate funding for a business or be strong enough to impress employers to hire you.</p>
              <div className="spacer" />
            </div>
          )}

          {(this.state.addMetric) && (
            <div key="addMetric" className="full-width padding-20">

              {(this.state.metricType === 'Social Media Posts') && (
                <div>
                  <p className="heading-text-2">Add Social Media Post</p>
                  <div className="spacer" />

                  <div className="row-5">
                    <div className="container-left">
                      <label className="profile-label">Date</label>
                      <input type="date" className="date-picker" placeholder=" date" name="postDate" value={(this.state.postDate) ? this.state.postDate.substring(0,10) : ""} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Link</label>
                      <input className="text-field" type="text" placeholder="Add a link" name="link" value={this.state.link} onChange={this.formChangeHandler} />
                      {console.log('show link: ', this.state.link)}
                      {(this.state.link && this.state.link !== '' && !this.state.link.startsWith('http')) && (
                        <div>
                          <p className="error-message">Please start your link with http</p>
                        </div>
                      )}
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-5">
                    <div className="col span-1-of-2 clear-padding right-padding">
                      <label className="profile-label">Platform</label>
                      <select name="platform" className="dropdown" value={this.state.platform} onChange={this.formChangeHandler}>
                        {this.state.platformOptions.map(value => <option key={value} value={value}>{value}</option>)}
                      </select>
                    </div>
                    <div className="col span-1-of-6 clear-padding right-padding">
                      <label className="profile-label">Views</label>
                      <input className="number-field" type="number" placeholder="" name="views" value={this.state.views} onChange={this.formChangeHandler} />
                    </div>
                    <div className="col span-1-of-6 clear-padding right-padding">
                      <label className="profile-label">Likes</label>
                      <input className="number-field" type="number" placeholder="" name="likes" value={this.state.likes} onChange={this.formChangeHandler} />
                    </div>
                    <div className="col span-1-of-6 clear-padding right-padding">
                      <label className="profile-label">Interactions</label>
                      <input className="number-field" type="number" placeholder="" name="interactions" value={this.state.interactions} onChange={this.formChangeHandler} />
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="spacer" />

                  {(this.state.errorMessage) && <p className="error-message">{this.state.errorMessage}</p>}
                  {(this.state.successMessage) && <p className="success-message">{this.state.successMessage}</p>}

                  <div>
                    <div className="float-left right-padding">
                      <button type="button" className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.addItem(this.state.metricType)}>{(this.state.editIndex || this.state.editIndex === 0) ? "Edit" : "Add"}</button>
                    </div>
                    {(this.state.editIndex || this.state.editIndex === 0) && (
                      <div className="float-left right-padding">
                        <button type="button" className="btn btn-quaternary" disabled={this.state.isSaving} onClick={() => this.removeItem(this.state.metricType)}>Delete</button>
                      </div>
                    )}
                    <div className="clear" />
                  </div>

                  <div className="spacer" /><div className="spacer" />

                  {this.renderValues(this.state.metricType)}

                </div>
              )}
              {(this.state.metricType === 'Your Follows of Other Social Media Accounts') && (
                <div>
                  <p className="heading-text-2">Add Accounts You Follow Over a Date Range</p>
                  <div className="spacer" />

                  <div className="row-5">
                    <div className="col span-3-of-10 clear-padding clear-margin">
                      <label className="profile-label">Start Date</label>
                      <input type="date" className="date-picker" placeholder="date" name="metricStartDate" value={(this.state.metricStartDate) ? this.state.metricStartDate.substring(0,10) : ""} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="col span-3-of-10 clear-padding clear-margin">
                      <label className="profile-label">End Date</label>
                      <input type="date" className="date-picker" placeholder="date" name="metricEndDate" value={(this.state.metricEndDate) ? this.state.metricEndDate.substring(0,10) : ""} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="col span-4-of-10 clear-padding clear-margin">
                      <label className="profile-label">Platform</label>
                      <select name="platform" className="dropdown" value={this.state.platform} onChange={this.formChangeHandler}>
                        {this.state.platformOptions.map(value => <option key={value} value={value}>{value}</option>)}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-5">

                    <div className="col span-3-of-10 clear-padding clear-margin">
                      <label className="profile-label">Accts Followed</label>
                      <input className="number-field" type="number" placeholder="" name="accountFollows" value={this.state.accountFollows} onChange={this.formChangeHandler} />
                    </div>
                    <div className="col span-3-of-10 clear-padding clear-margin">
                      <label className="profile-label">Interactions / Acct</label>
                      <input className="number-field" type="number" placeholder="" name="interactions" value={this.state.interactions} onChange={this.formChangeHandler} />
                    </div>
                    <div className="clear" />
                  </div>

                  <div>
                    <div className="float-left right-padding">
                      <button type="button" className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.addItem(this.state.metricType)}>{(this.state.editIndex || this.state.editIndex === 0) ? "Edit" : "Add"}</button>
                    </div>
                    {(this.state.editIndex || this.state.editIndex === 0) && (
                      <div className="float-left right-padding">
                        <button type="button" className="btn btn-quaternary" disabled={this.state.isSaving} onClick={() => this.removeItem(this.state.metricType)}>Delete</button>
                      </div>
                    )}
                    <div className="clear" />
                  </div>

                  <div className="spacer" /><div className="spacer" />

                  {this.renderValues(this.state.metricType)}

                </div>
              )}

            </div>
          )}

          {(this.state.skillTagsInfo) && (
            <div key="showIndustry" className="full-width padding-20">
              <p className="heading-text-2">Skill Tags Info</p>
              <div className="spacer" />
              <p><label className="half-bold-text cta-color">Skill Tags</label> allow you to list the skills related to your project separated by commas. For example, for design project, you may want to tag wireframing, Adobe Photoshop, and flow chart. This allows the reviewer to better understand your skills and allows you to receive better recommendations.</p>
            </div>
          )}

          <div className="row-20">
           <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
          </div>
         </Modal>
        </div>
      )
    }
}


export default withRouter(EditProject);
