export const bootUser = (navigate, roleName, bootPath)=>{
  console.log('called bootUser')

  let delayId = null

  function officiallyBoot() {
    navigate(bootPath)
  }

  const delayBoot = () => {
    clearTimeout(delayId)
    delayId = setTimeout(officiallyBoot, 1000)
  }

  delayBoot();

}
