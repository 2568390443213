import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubSideNav from './Common/SideNav';
import SubNewsFeed from './Subcomponents/NewsFeed';
import {toggleVars} from './Functions/ToggleVars';
import withRouter from './Functions/WithRouter';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class AdvNewsFeed extends Component {
    constructor(props) {
      super(props)

      this.state = {
          opacity: 0,
          transform: 'translate(90%)',
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      console.log('show activeOrg 0: ', activeOrg, roleName)
      if (email) {
        console.log('show me activeOrg 1: ', activeOrg)

        this.setState({ activeOrg, emailId: email, username, orgFocus, roleName,
          orgLogo })

        Axios.get('/api/notifications', { params: { emailId: email } })
        .then((response) => {
          console.log('Notifications query worked', response.data);

          if (response.data.success) {

            this.setState({ notifications: response.data.notifications })


          } else {
            console.log('no pathways data found', response.data.message)
          }

        }).catch((error) => {
            console.log('Pathways query did not work', error);
        });
      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    render() {

      return (
          <div>
            <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus}  roleName={this.state.roleName} history={this.props.navigate} />

            {(!toggleVars.hideSideNav) && (
              <SubSideNav selectedComponent={"AdvNewsFeed"} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus}  workMode={this.state.workMode} roleName={this.state.roleName} finishedQuery={this.state.finishedQuery} />
            )}

            <div className={(toggleVars.hideSideNav) ? "width-70-percent max-width-1400 top-margin-2-percent center-horizontally" : "calc-column-offset-260-of-91"} style={(toggleVars.hideSideNav) ? {} : {...styles, opacity: this.state.opacity, transform: this.state.transform, height: 'auto' }}>
              <SubNewsFeed history={this.props.navigate} postId={this.state.postId} />
            </div>
            <div className="clear" />

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(AdvNewsFeed)
