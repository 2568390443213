import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// const homeIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/home-icon-grey.png';
const dashboardIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/dashboard-icon-grey.png';
const pathsIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-grey.png';
// const opportunitiesIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-grey.png';
const assessmentsIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assessments-icon-grey.png';
const benchmarksIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-grey.png';
const pipelineIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/pipeline-icon-grey.png';
// const favoritesIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-grey.png';
const messagesIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/messages-icon-grey.png';
// const storyIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/input-icon-grey.png';
// const submitIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/send-icon-grey.png';
const jobsIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/jobs-icon-grey.png';
const logIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/log-icon-grey.png';
const profileIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-grey.png';
const mentoringIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/mentoring-icon-grey.png';
const studentsIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/student-icon-grey.png';
const projectsIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-grey.png';
const placedIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-grey.png';
const approvedIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/approved-icon.png';
const employersIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon-grey.png';
const teacherIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/teacher-icon-grey.png';
const reportingIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/reporting-icon-grey.png';
// const homeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/home-icon-blue.png';
const dashboardIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/dashboard-icon-blue.png';
const profileIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-blue.png';
const assessmentsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assessments-icon-blue.png';
const pathsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-blue.png';
// const opportunitiesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-blue.png';
// const favoritesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-blue.png';
// const storyIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/input-icon-blue.png';
// const submitIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/send-icon-blue.png';
const logIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/log-icon-blue.png';
const benchmarksIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-blue.png';
const pipelineIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/pipeline-icon-blue.png';
const approvedIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/approved-icon-blue.png';
const studentsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/student-icon-blue.png';
const mentoringIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/mentoring-icon-blue.png';
const projectsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-blue.png';
const placedIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-blue.png';
const jobsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/jobs-icon-blue.png';
const employersIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon.png';
const messagesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/messages-icon-blue.png';
const teacherIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/teacher-icon-blue.png';
const reportingIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/reporting-icon-blue.png';
const courseIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-blue.png";
const courseIconGrey = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-grey.png";
const assignmentsIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assignments-icon-blue.png";
const assignmentsIconGrey = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assignments-icon-grey.png";
const socialIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-blue.png";
const socialIconGrey = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-grey.png";
const newsFeedIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/news-feed-icon-blue.png";
const newsFeedIconGrey = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/news-feed-icon-grey.png";
// const exchangeIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/exchange-icon-blue.png";
// const exchangeIconGrey = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/exchange-icon-grey.png";
const timeMoneyIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-money-icon-blue.png";
const timeMoneyIconGrey = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-money-icon-grey.png";
const referIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/refer-icon-blue.png";
const referIconGrey = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/refer-icon-grey.png";

class SideNav extends Component {
    constructor(props) {
      super(props)
      this.state = {
        showModule: true,
        showPipeline: true

      }

      this.retrieveData = this.retrieveData.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called in SubSideNav')

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      } else if (this.props.selectedComponent !== prevProps.selectedComponent || this.props.orgFocus !== prevProps.orgFocus || this.props.activeOrg !== prevProps.activeOrg || this.props.workMode !== prevProps.workMode) {
        this.retrieveData()
      } else if (this.props.finishedQuery !== prevProps.finishedQuery || this.props.finishedCareerPaths !== prevProps.finishedCareerPaths || this.props.finishedApplications !== prevProps.finishedApplications) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within sideNav', this.props.selectedComponent, this.props.activeOrg, this.props.accountCode)

      const selectedComponent = this.props.selectedComponent
      const activeOrg = this.props.activeOrg
      const orgFocus = this.props.orgFocus
      const accountCode = this.props.accountCode
      const roleName = this.props.roleName
      const workMode = this.props.workMode

      let emailId = localStorage.getItem('email');
      let studentAliasPlural = localStorage.getItem('studentAliasPlural');
      let mentorAliasPlural = localStorage.getItem('mentorAliasPlural');

      this.setState({ selectedComponent, activeOrg, orgFocus, accountCode, roleName,
        workMode, emailId, studentAliasPlural, mentorAliasPlural
      })

    }

    render() {

      // const navHeight = document.documentElement.scrollHeight

      let navHeight = window.innerHeight
      if (this.props.finishedQuery) {
        navHeight = document.documentElement.scrollHeight
        navHeight = Math.max(
          window.innerHeight, document.body.scrollHeight, document.body.offsetHeight,
          document.documentElement.scrollHeight, document.documentElement.clientHeight, document.documentElement.offsetHeight
        )
      }
      // console.log('show navHeight 2: ', navHeight, this.props.finishedQuery, document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.offsetHeight)

      let navClass = "sub-side-nav"
      let navStyle = { height: navHeight, backgroundColor: 'transparent' }
      // if (this.state.showModule) {
      //   navClass = "side-nav-2"
      //   navStyle = {}
      // }

      if (window.location.pathname.includes('/app')) {
        // do nothing
      } else if (window.location.pathname.includes('/advisor')) {

        return (
          <nav className={navClass} style={navStyle}>
            {(this.state.roleName === 'Academy Lead' || this.state.roleName === 'Teacher' || this.state.roleName === 'School Support' || this.state.roleName === 'Counselor' || this.state.roleName === 'Staff (Non-Classroom)') && (
              <div className="side-nav-container">
                  <ul className="side-nav-items-1">
                      <div className="side-nav-item"><li><Link to={'/advisor/dashboard'}>
                          <img src={(this.state.selectedComponent === 'AdvDashboard') ? dashboardIconBlue : dashboardIconGrey} alt="Compass dashboard icon" />
                          <p className={(this.state.selectedComponent === 'AdvDashboard') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Dashboard</p>
                      </Link></li></div>
                      <div className="side-nav-item"><li><Link to={'/advisor/edit-profile'}>
                          <img src={(this.state.selectedComponent === 'AdvEditProfile') ? profileIconBlue : profileIconGrey} alt="Compass profile icon" />
                          <p className={(this.state.selectedComponent === 'AdvEditProfile') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Edit Profile</p>
                      </Link></li></div>

                  </ul>

                  <hr />

                  <ul className="side-nav-items-2">
                    <div className="side-nav-item"><li><Link to={'/advisor/learning-modules'}>
                        <img src={(this.state.selectedComponent === 'AdvLessons') ? assignmentsIconBlue : assignmentsIconGrey} alt="GC" />
                        <p className={(this.state.selectedComponent === 'AdvLessons') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Learning Modules</p>
                    </Link></li></div>
                    {/*
                    <div className="side-nav-item"><li><Link to={'/advisor/exchange'}>
                        <img src={(this.state.selectedComponent === 'AdvExchange') ? exchangeIconBlue : exchangeIconGrey} alt="GC" />
                        <p className={(this.state.selectedComponent === 'AdvExchange') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Lesson Exchange</p>
                    </Link></li></div>*/}
                    <div className="side-nav-item"><li><Link to={'/advisor/quizzes'}>
                        <img src={(this.state.selectedComponent === 'AdvAssessments') ? assessmentsIconBlue : assessmentsIconGrey} alt="GC" />
                        <p className={(this.state.selectedComponent === 'AdvAssessments') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Quizzes</p>
                    </Link></li></div>
                    {/*
                    <div className="side-nav-item"><li><Link to={'/advisor/courses'}>
                      <img src={(this.state.selectedComponent === 'AdvCourses') ? courseIconBlue : courseIconGrey} alt={"GC"} />
                      <p className={(this.state.selectedComponent === 'AdvCourses') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Courses</p>
                    </Link></li></div>*/}
                  </ul>

                  <hr />

                  <ul className="side-nav-items-2">
                      <div className="side-nav-item"><li><Link to={'/advisor/postings'}>
                          <img src={(this.state.selectedComponent === 'AdvPostings') ? jobsIconBlue : jobsIconGrey} alt="Compass profile icon" />
                          <p className={(this.state.selectedComponent === 'AdvPostings') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Postings</p>
                      </Link></li></div>
                      <div className="side-nav-item"><li><Link to={'/advisor/placements'}>
                          <img src={(this.state.selectedComponent === 'AdvPlacements') ? placedIconBlue : placedIconGrey} alt="GC" />
                          <p className={(this.state.selectedComponent === 'AdvPlacements') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Placements</p>
                      </Link></li></div>
                      <div className="side-nav-item"><li><Link to={'/advisor/benchmarks'}>
                          <img src={(this.state.selectedComponent === 'AdvBenchmarks') ? benchmarksIconBlue : benchmarksIconGrey} alt="GC" />
                          <p className={(this.state.selectedComponent === 'AdvBenchmarks') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Benchmarks</p>
                      </Link></li></div>
                      <div className="side-nav-item"><li><Link to={'/advisor/pathways'}>
                          <img src={(this.state.selectedComponent === 'AdvPathways') ? pathsIconBlue : pathsIconGrey} alt={"GC"} />
                          <p className={(this.state.selectedComponent === 'AdvPathways') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Pathways</p>
                      </Link></li></div>

                  </ul>

                  <hr />

                  <ul className="side-nav-items-2">

                      <div className="side-nav-item"><li><Link to={'/advisor/advisees'}>
                          <img src={(this.state.selectedComponent === 'AdvAdvisees') ? studentsIconBlue : studentsIconGrey} alt="GC" />
                          <p className={(this.state.selectedComponent === 'AdvAdvisees') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Students</p>
                      </Link></li></div>

                      {(this.state.orgFocus !== 'Placement') && (
                        <div>
                          <div className="side-nav-item"><li><Link to={'/advisor/mentors'}>
                              <img src={(this.state.selectedComponent === 'AdvMentors') ? mentoringIconBlue : mentoringIconGrey} alt="Compass mentors icon" />
                              <p className={(this.state.selectedComponent === 'AdvMentors') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Mentors</p>
                          </Link></li></div>
                          <div className="side-nav-item"><li><Link to={'/advisor/employers'}>
                              <img src={(this.state.selectedComponent === 'AdvEmployers') ? employersIconBlue : employersIconGrey} alt="Compass mentors icon" />
                              <p className={(this.state.selectedComponent === 'AdvEmployers') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Employers</p>
                          </Link></li></div>
                        </div>
                      )}

                  </ul>

                  <hr />

                  <ul className="side-nav-items-2">
                      <div className="side-nav-item"><li><Link to={'/advisor/goals'}>
                          <img src={(this.state.selectedComponent === 'AdvGoals') ? logIconBlue : logIconGrey} alt={"GC"} />
                          <p className={(this.state.selectedComponent === 'AdvGoals') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Goals</p>
                      </Link></li></div>
                      <div className="side-nav-item"><li><Link to={'/advisor/news-feed'}>
                          <img src={(this.state.selectedComponent === 'AdvNewsFeed') ? newsFeedIconBlue : newsFeedIconGrey} alt={"GC"} />
                          <p className={(this.state.selectedComponent === 'AdvNewsFeed') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>News Feed</p>
                      </Link></li></div>
                      <div className="side-nav-item"><li><Link to={'/advisor/groups'}>
                          <img src={(this.state.selectedComponent === 'AdvGroups') ? socialIconBlue : socialIconGrey} alt="Compass projects icon" className="image-auto-36 top-padding-5" />
                          <p className={(this.state.selectedComponent === 'AdvGroups') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Groups</p>
                      </Link></li></div>
                      {/*
                      <div className="side-nav-item"><li><Link to={'/advisor/scheduled-messages'}>
                          <img src={(this.state.selectedComponent === 'AdvScheduledMessages') ? messagesIconBlue : messagesIconGrey} alt="Compass projects icon" className="image-auto-36 top-padding-5" />
                          <p className={(this.state.selectedComponent === 'AdvScheduledMessages') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Scheduled Messages</p>
                      </Link></li></div>*/}

                  </ul>

                  <hr />

                  <ul className="side-nav-items-2">
                    <div className="side-nav-item"><li><Link to={'/advisor/projects'}>
                        <img src={(this.state.selectedComponent === 'AdvProjects') ? projectsIconBlue : projectsIconGrey} alt="Compass projects icon" className="image-auto-36 top-padding-5" />
                        <p className={(this.state.selectedComponent === 'AdvProjects') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Student Projects</p>
                    </Link></li></div>
                    <div className="side-nav-item"><li><Link to={'/advisor/endorsements'}>
                        <img src={(this.state.selectedComponent === 'AdvEndorsements') ? approvedIconBlue : approvedIconGrey} alt="Compass endorsements icon" />
                        <p className={(this.state.selectedComponent === 'AdvEndorsements') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Endorsements</p>
                    </Link></li></div>
                    <div className="half-spacer" />
                  </ul>

                  <hr />

                  <ul className="side-nav-items-2">

                    <div className="side-nav-item"><li><Link to={'/advisor/reporting'}>
                        <img src={(this.state.selectedComponent === 'AdvReporting') ? reportingIconBlue : reportingIconGrey} alt={"Guided Compass Reporting"} />
                        <p className={(this.state.selectedComponent === 'AdvReporting') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Reporting</p>
                    </Link></li></div>
                  </ul>
              </div>
            )}

            {(this.state.orgFocus === 'Mentor' || this.state.roleName === 'Mentor' || this.state.roleName === 'Admin') && (
              <div className="side-nav-container">
                  <ul className="side-nav-items-1">
                    <div className="side-nav-item"><li><Link to={'/advisor/dashboard'}>
                        <img src={(this.state.selectedComponent === 'AdvDashboard') ? dashboardIconBlue : dashboardIconGrey} alt="Compass dashboard icon" />
                        <p className={(this.state.selectedComponent === 'AdvDashboard') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Dashboard</p>
                    </Link></li></div>

                    <div className="side-nav-item"><li><Link to={'/advisor/edit-profile'}>
                        <img src={(this.state.selectedComponent === 'AdvEditProfile') ? profileIconBlue : profileIconGrey} alt="Compass profile icon" />
                        <p className={(this.state.selectedComponent === 'AdvEditProfile') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Edit Profile</p>
                    </Link></li></div>
                    <div className="side-nav-item"><li><Link to={'/advisor/endorsements'}>
                        <img src={(this.state.selectedComponent === 'AdvEndorsements') ? approvedIconBlue : approvedIconGrey} alt="Compass endorsements icon" />
                        <p className={(this.state.selectedComponent === 'AdvEndorsements') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Endorsements</p>
                    </Link></li></div>
                  </ul>
              </div>
            )}
          </nav>
        )
      } else if (window.location.pathname.includes('/organizations')) {

        return (
          <nav className="sub-side-nav" style={{ height: navHeight }}>
            <div className='side-nav-container'>
                <ul className="side-nav-items-1">
                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/dashboard'}>
                      <img src={(this.state.selectedComponent === 'OrgDashboard') ? dashboardIconBlue : dashboardIconGrey} alt="Guided Compass dashboard icon" />
                      <p className={(this.state.selectedComponent === 'OrgDashboard') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Dashboard</p>
                  </Link></li></div>
                </ul>

                <hr />

                <ul className="side-nav-items-2">
                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/postings'}>
                      <img src={(this.state.selectedComponent === 'OrgJobs') ? jobsIconBlue : jobsIconGrey} alt={"GC"} />
                      <p className={(this.state.selectedComponent === 'OrgJobs') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Postings</p>
                  </Link></li></div>

                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/assessments'}>
                      <img src={(this.state.selectedComponent === 'OrgAssessments') ? assessmentsIconBlue : assessmentsIconGrey} alt={"GC"}/>
                      <p className={(this.state.selectedComponent === 'OrgAssessments') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Assessments</p>
                  </Link></li></div>

                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/benchmarks'}>
                      <img src={(this.state.selectedComponent === 'OrgBenchmarks') ? benchmarksIconBlue : benchmarksIconGrey} alt={"GC"}/>
                      <p className={(this.state.selectedComponent === 'OrgBenchmarks') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Benchmarks</p>
                  </Link></li></div>

                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/placements'}>
                      <img src={(this.state.selectedComponent === 'OrgPlacements') ? placedIconBlue : placedIconGrey} alt="GC" className="image-auto-36"/>
                      <p className={(this.state.selectedComponent === 'OrgPlacements') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Placements</p>
                  </Link></li></div>

                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/pathways'}>
                      <img src={(this.state.selectedComponent === 'OrgPathways') ? pathsIconBlue : pathsIconGrey} alt={"GC"} />
                      <p className={(this.state.selectedComponent === 'OrgPathways') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Pathways</p>
                  </Link></li></div>
                </ul>

                <div className="spacer" />
                <hr />

                <ul className="side-nav-items-2">

                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/career-seekers'}>
                    <img src={(this.state.selectedComponent === 'OrgStudents') ? studentsIconBlue : studentsIconGrey} alt="GC" />
                    <p className={(this.state.selectedComponent === 'OrgStudents') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>{(this.state.studentAliasPlural) ? this.state.studentAliasPlural : "Career-Seekers"}</p>
                  </Link></li></div>

                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/educators'}>
                      <img src={(this.state.selectedComponent === 'OrgEducators') ? teacherIconBlue : teacherIconGrey} alt="Compass educators icon" className="image-auto-40" />
                      <p className={(this.state.selectedComponent === 'OrgEducators') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Educators</p>
                  </Link></li></div>

                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/mentors'}>
                      <img src={(this.state.selectedComponent === 'OrgMentors') ? mentoringIconBlue : mentoringIconGrey} alt="Compass mentors icon" />
                      <p className={(this.state.selectedComponent === 'OrgMentors') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>{(this.state.mentorAliasPlural) ? this.state.mentorAliasPlural : "Mentors"}</p>
                  </Link></li></div>

                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/employers'}>
                      <img src={(this.state.selectedComponent === 'OrgEmployers') ? employersIconBlue : employersIconGrey} alt={"Guided Compass Employers Icon"}/>
                      <p className={(this.state.selectedComponent === 'OrgEmployers') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Employers</p>
                  </Link></li></div>
                </ul>

                <div className="spacer" />
                <hr />

                <div>

                  <ul className="side-nav-items-2">
                    <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/learning-modules'}>
                      <img src={(this.state.selectedComponent === 'OrgLearningModules') ? assignmentsIconBlue : assignmentsIconGrey} alt={"GC"} />
                      <p className={(this.state.selectedComponent === 'OrgLearningModules') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Learning Modules</p>
                    </Link></li></div>
                    <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/courses'}>
                      <img src={(this.state.selectedComponent === 'OrgCourses') ? courseIconBlue : courseIconGrey} alt={"GC"} />
                      <p className={(this.state.selectedComponent === 'OrgCourses') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Courses</p>
                    </Link></li></div>
                    <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/sessions'}>
                      <img src={(this.state.selectedComponent === 'OrgSessions') ? mentoringIconBlue : mentoringIconGrey} alt={"GC"} />
                      <p className={(this.state.selectedComponent === 'OrgSessions') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Sessions</p>
                    </Link></li></div>
                  </ul>

                  <div className="spacer" />
                  <hr />
                </div>

                <ul className="side-nav-items-2">
                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/goals'}>
                      <img src={(this.state.selectedComponent === 'OrgGoals') ? logIconBlue : logIconGrey} alt={"GC"} />
                      <p className={(this.state.selectedComponent === 'OrgGoals') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Goals</p>
                  </Link></li></div>
                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/projects'}>
                      <img src={(this.state.selectedComponent === 'OrgProjects') ? projectsIconBlue : projectsIconGrey} alt="Compass projects icon" className="image-auto-36 top-padding-5"/>
                      <p className={(this.state.selectedComponent === 'OrgProjects') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Projects</p>
                  </Link></li></div>
                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/endorsements'}>
                      <img src={(this.state.selectedComponent === 'OrgEndorsements') ? approvedIconBlue : approvedIconGrey} alt={"GC"} />
                      <p className={(this.state.selectedComponent === 'OrgEndorsements') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Endorsements</p>
                  </Link></li></div>
                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/referrals'}>
                      <img src={(this.state.selectedComponent === 'OrgReferrals') ? referIconBlue : referIconGrey} alt={"GC"} />
                      <p className={(this.state.selectedComponent === 'OrgReferrals') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Referrals</p>
                  </Link></li></div>
                  <div className="spacer" />
                  <hr />
                </ul>

                <ul className="side-nav-items-2">
                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/news-feed'}>
                      <img src={(this.state.selectedComponent === 'OrgNewsFeed') ? newsFeedIconBlue : newsFeedIconGrey} alt={"GC"} />
                      <p className={(this.state.selectedComponent === 'OrgNewsFeed') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>News Feed</p>
                  </Link></li></div>
                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/groups'}>
                      <img src={(this.state.selectedComponent === 'OrgGroups') ? socialIconBlue : socialIconGrey} alt={"GC"} />
                      <p className={(this.state.selectedComponent === 'OrgGroups') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Groups</p>
                  </Link></li></div>
                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/message-blasts'}>
                      <img src={(this.state.selectedComponent === 'OrgScheduledMessages') ? messagesIconBlue : messagesIconGrey} alt={"GC"} />
                      <p className={(this.state.selectedComponent === 'OrgScheduledMessages') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Message Blasts</p>
                  </Link></li></div>
                  {(this.state.showModule) && (
                    <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/timesheets'}>
                      <img src={(this.state.selectedComponent === 'OrgTimesheets') ? timeMoneyIconBlue : timeMoneyIconGrey} alt={"GC"} />
                      <p className={(this.state.selectedComponent === 'OrgTimesheets') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Timesheets</p>
                    </Link></li></div>
                  )}
                  <div className="spacer" />
                  <hr />
                </ul>

                <ul className="side-nav-items-2">

                  <div className="side-nav-item"><li><Link to={'/organizations/' + this.state.activeOrg + '/reporting'}>
                      <img src={(this.state.selectedComponent === 'OrgReporting') ? reportingIconBlue : reportingIconGrey} alt={"Guided Compass Reporting"} />
                      <p className={(this.state.selectedComponent === 'OrgReporting') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Reporting</p>
                  </Link></li></div>
                </ul>
            </div>
          </nav>
        )
      } else if (window.location.pathname.includes('/employers')) {
        console.log('got the org: ', this.state.activeOrg)
        return (
          <nav className="sub-side-nav" style={{ height: navHeight }}>
            <div className='side-nav-container'>
              <ul className="side-nav-items-1">
                <div className="side-nav-item"><li><Link to={'/employers/' + this.state.accountCode + '/dashboard'}>
                    <img src={(this.state.selectedComponent === 'EmpDashboard') ? dashboardIconBlue : dashboardIconGrey} alt="Compass dashboard icon" />
                    <p className={(this.state.selectedComponent === 'EmpDashboard') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Dashboard</p>
                </Link></li></div>
                <div className="side-nav-item"><li><Link to={'/employers/' + this.state.accountCode + '/postings'}>
                    <img src={(this.state.selectedComponent === 'EmpJobs') ? jobsIconBlue : jobsIconGrey} alt="Compass jobs icon" />
                    <p className={(this.state.selectedComponent === 'EmpJobs') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Postings</p>
                </Link></li></div>
                {(this.state.showPipeline && this.props.activeOrg === 'guidedcompass') && (
                  <div>
                    <div className="side-nav-item"><li><Link to={'/employers/' + this.state.accountCode + '/benchmarks'}>
                        <img src={(this.state.selectedComponent === 'EmpBenchmarks') ? benchmarksIconBlue : benchmarksIconGrey} alt="GC"/>
                        <p className={(this.state.selectedComponent === 'EmpBenchmarks') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Benchmarks</p>
                    </Link></li></div>
                    <div className="side-nav-item"><li><Link to={'/employers/' + this.state.accountCode + '/pipelines'}>
                        <img src={(this.state.selectedComponent === 'EmpPipelines') ? pipelineIconBlue : pipelineIconGrey} alt="Compass benchmarks icon"/>
                        <p className={(this.state.selectedComponent === 'EmpPipelines') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Pipelines</p>
                    </Link></li></div>
                    <div className="side-nav-item"><li><Link to={'/employers/' + this.state.accountCode + '/communities'}>
                        <img src={(this.state.selectedComponent === 'EmpCommunities') ? socialIconBlue : socialIconGrey} alt="GC" />
                        <p className={(this.state.selectedComponent === 'EmpCommunities') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Communities</p>
                    </Link></li></div>
                    <div className="side-nav-item"><li><Link to={'/employers/' + this.state.accountCode + '/social-posts'}>
                        <img src={(this.state.selectedComponent === 'EmpSocialPosts') ? newsFeedIconBlue : newsFeedIconGrey} alt="GC" />
                        <p className={(this.state.selectedComponent === 'EmpSocialPosts') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Social Posts</p>
                    </Link></li></div>
                  </div>
                )}

                <div className="side-nav-item"><li><Link to={'/employers/' + this.state.accountCode + '/hires'}>
                    <img src={(this.state.selectedComponent === 'EmpHires') ? placedIconBlue : placedIconGrey} alt="GC"/>
                    <p className={(this.state.selectedComponent === 'EmpHires') ? "side-nav-text heading-text-6 cta-color" : "side-nav-text heading-text-6 unselected-color"}>Hires</p>
                </Link></li></div>
              </ul>
            </div>
          </nav>
        )
      } else {
        return (
          <div>
            <p>There was an error detecting the source of this component</p>
          </div>
        )
      }
    }
}

export default SideNav;
