import React, {Component } from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEditProfileDetails from './Subcomponents/EditProfileDetails';
import withRouter from './Functions/WithRouter';

class AdvEditProfileDetails extends Component {
    constructor(props) {
      super(props)
      this.state = {
          opacity: 0,
          transform: 'translate(90%)',
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        let profilePath = window.location.protocol + "//" + window.location.host + "/advisor/profile/" + username

        this.setState({ activeOrg, emailId: email, username, orgFocus, roleName,
          profilePath, orgLogo
        })

      }

      if (window.innerWidth > 768) {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)

      } else {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)
      }
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in parentEditProfileDetails ', this.props, prevProps)

      if (this.props.location.pathname !== prevProps.location.pathname) {
        // this.setState({ })
      }
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    render() {

      return (
          <div>
            <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

            <div className="standard-container-2">
              <SubEditProfileDetails activeOrg={this.state.activeOrg} history={this.props.navigate} location={this.props.location} />
            </div>

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>
      )
    }
}

export default withRouter(AdvEditProfileDetails);
