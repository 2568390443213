import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEditCourseLesson from './Subcomponents/EditCourseLesson';
import withRouter from './Functions/WithRouter';

class AdvEditCourseLesson extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('just mounted daddy log in adv')

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      // let courseName = null
      // if (this.props.params) {
      //   courseName = this.props.params.name
      // }
      let courseId = this.state.courseId
      let learningModules = this.state.learningModules
      let selectedIndex = null
      let selectedIndex2 = null
      if (this.props.location && this.props.location.state) {
        courseId = this.props.location.state.courseId
        learningModules = this.props.location.state.learningModules
        selectedIndex = this.props.location.state.selectedIndex
        selectedIndex2 = this.props.location.state.selectedIndex2
      }

      this.setState({ activeOrg, emailId: email, username, orgFocus, roleName, orgLogo,
        courseId, learningModules, selectedIndex, selectedIndex2
      });
    }

    render() {

      return (
          <div>
              <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

              <SubEditCourseLesson org={this.state.activeOrg} courseId={this.state.courseId} learningModules={this.state.learningModules} selectedIndex={this.state.selectedIndex} selectedIndex2={this.state.selectedIndex2} />

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
          </div>

      )
    }
}

export default withRouter(AdvEditCourseLesson);
