import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import Axios from 'axios';

import ProfileCard from '../Subcomponents/ProfileCard';

const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';

class ConfigureProfileMatch extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.prepareProfileCard = this.prepareProfileCard.bind(this)
        this.calculateMatches = this.calculateMatches.bind(this)

    }

    componentDidMount() {
      //see if user is logged in
      console.log('profilePage just mounted')

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubConfigureEndorsement', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.posts !== prevProps.posts) {
        this.retrieveData()
      } else if (this.props.selectedBenchmark !== prevProps.selectedBenchmark) {
        this.retrieveData()
      } else if (this.props.selectedBenchmark && prevProps.selectedBenchmark) {
        console.log('both are here')
        if (this.props.selectedBenchmark.title !== prevProps.selectedBenchmark.title) {
          this.retrieveData()
        }
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubConfigureProfileMatch', this.props.selectedBenchmark)

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const roleName = localStorage.getItem('roleName');
      let pictureURL = localStorage.getItem('pictureURL');

      this.setState({ emailId, cuFirstName, cuLastName, activeOrg, roleName, username, pictureURL})

      if (this.props.selectedBenchmark) {

        let selectedBenchmark = this.props.selectedBenchmark

        if ((selectedBenchmark.highPriorityHardSkills && selectedBenchmark.highPriorityHardSkills.length > 0) || (selectedBenchmark.lowPriorityHardSkills && selectedBenchmark.lowPriorityHardSkills.length > 0)) {
          let skills = []
          const highPriorityHardSkills = this.props.selectedBenchmark.highPriorityHardSkills
          const lowPriorityHardSkills = this.props.selectedBenchmark.lowPriorityHardSkills
          const highPrioritySoftSkills = this.props.selectedBenchmark.highPrioritySoftSkills
          const lowPrioritySoftSkills = this.props.selectedBenchmark.lowPrioritySoftSkills

          if (highPriorityHardSkills) {
            for (let i = 1; i <= highPriorityHardSkills.length; i++) {
              skills.push({ title: highPriorityHardSkills[i - 1], skillType: 'Hard Skill', score: 5, weight: 1 })
            }
          }
          if (lowPriorityHardSkills) {
            for (let i = 1; i <= lowPriorityHardSkills.length; i++) {
              skills.push({ title: lowPriorityHardSkills[i - 1], skillType: 'Hard Skill', score: 5, weight: 1 })
            }
          }
          if (highPrioritySoftSkills) {
            for (let i = 1; i <= highPrioritySoftSkills.length; i++) {
              skills.push({ title: highPrioritySoftSkills[i - 1], skillType: 'Soft Skill', score: 5, weight: 1 })
            }
          }
          if (lowPriorityHardSkills) {
            for (let i = 1; i <= lowPrioritySoftSkills.length; i++) {
              skills.push({ title: lowPrioritySoftSkills[i - 1], skillType: 'Soft Skill', score: 5, weight: 1 })
            }
          }

          selectedBenchmark['skills'] = skills

        }

        this.setState({ selectedBenchmark })
        this.prepareProfileCard(emailId,activeOrg,this.props.title,this.props.workFunction,selectedBenchmark)
      }
    }

    prepareProfileCard(email,org,title,workFunction,selectedBenchmark) {
      console.log('prepareProfileCard called', selectedBenchmark)

      // selectedApplication, workPreferenceResults, wpQuestions
      Axios.get('/api/users/profile/details', { params: { email } })
      .then((response) => {
        console.log('User details query 1 attempted', response.data);

        if (response.data.success) {
           console.log('successfully retrieved user details')

           let userDetails = response.data.user
           if (userDetails.education && userDetails.education[0]) {
             userDetails['schoolName'] = userDetails.education[0].name
             userDetails['major'] = userDetails.education[0].major
             userDetails['gradYear'] = userDetails.education[0].gradYear
             userDetails['match'] = 100
           }

           let selectedApplication = userDetails
           const selectedJob = { postType: 'Work', subPostType: 'Internship', title, workFunction }

           this.setState({ userDetails, selectedJob })

           Axios.get('/api/assessment/results', { params: { emailId: email } })
            .then((response2) => {
              console.log('query for assessment results worked');

              if (response2.data.success) {

                selectedApplication['workPreferenceResults'] = response2.data.results.workPreferenceAnswers
                selectedApplication['interestResults'] = response2.data.results.interestScores
                selectedApplication['personalityResults'] = response2.data.results.personalityScores
                selectedApplication['newSkillAnswers'] = response2.data.results.newSkillAnswers
                selectedApplication['topGravitateValues'] = response2.data.results.topGravitateValues
                selectedApplication['topEmployerValues'] = response2.data.results.topEmployerValues
                this.setState({ selectedApplication })

                // const selectedApplication = {
                //   firstName: 'Example Ideal', lastName: 'Candidate', email: 'fakeemail@email.com', schoolName: 'Sample School', gradYear: '2022',
                //   pictureURL, major: 'Business', workPreferenceResults, personalityResults,
                //   intangiblesText, interview, interviewDetails, interviewQuestions, totalHours, degreeRequirements,
                //   gpaRange, gradYearRange, courseHours,  politicalAlignment, hometown, homeCongressionalDistrict,
                //   match: 100, createdAt, updatedAt
                //
                // }

              } else {
                console.log('no assessment results', response2.data)

              }

           }).catch((error) => {
              console.log('query for assessment results did not work', error);

           })

           Axios.get('/api/projects', { params: { emailId: email, includeCollaborations: true } })
           .then((response2) => {
            console.log('Projects query attempted', response2.data);

              if (response2.data.success && response2.data.projects) {
                console.log('successfully retrieved projects')

                // let profile = response.data.user
                selectedApplication['projects'] = response2.data.projects
                this.setState({ selectedApplication })

              } else {
                console.log('no project data found', response2.data.message)
              }

           }).catch((error) => {
              console.log('Project query did not work', error);
           });

           Axios.get('/api/experience', { params: { emailId: email } })
           .then((response2) => {
            console.log('Experience query attempted', response2.data);

              if (response2.data.success && response2.data.experience) {
                console.log('successfully retrieved experience')

                // let profile = response.data.user
                selectedApplication['experience'] = response2.data.experience
                this.setState({ selectedApplication })

              } else {
                console.log('no experience data found', response2.data.message)
              }

           }).catch((error) => {
              console.log('Experience query did not work', error);
           });

           Axios.get('/api/story', { params: { emailId: email } })
           .then((response2) => {
              console.log('Endorsement query worked', response2.data);

              if (response2.data.success && response2.data.stories) {

                selectedApplication['endorsements'] = response2.data.stories
                this.calculateMatches(selectedApplication,selectedJob,selectedBenchmark)

              } else {
                console.log('no endorsements found: ', response2.data.message)
                this.calculateMatches(selectedApplication,selectedJob,selectedBenchmark)
              }

           }).catch((error) => {
              console.log('Story query did not work', error);
           });

        } else {
         console.log('no user details data found', response.data.message)
        }

      }).catch((error) => {
         console.log('User details query did not work', error);
      });
    }

    calculateMatches(application,selectedJob,benchmark) {
      console.log('calculateMatches called', application, selectedJob, benchmark)

      Axios.post('/api/applications/matches', { application, selectedJob, benchmark })
      .then((response) => {
        console.log('application matches query attempted')
        if (response.data.success) {
          //save values
          console.log('Application matches save worked', response.data);

          const selectedApplication = response.data.application
          this.setState({ selectedApplication })

        } else {
          console.log('application matches did not work', response.data.message)
        }
      }).catch((error) => {
          console.log('Application matches did not work for some reason', error);
      });
    }

    render() {

      return (
        <div key="showDescription" className="full-width">
           {(this.state.selectedApplication) && (
             <div className="row-10">
               <div className="calc-column-offset-40">
                 <p className="heading-text-3">{this.props.title} {this.props.matchType} Match</p>
                 <p className="description-text-1 top-padding">{this.state.selectedApplication.match}% match is calculated by comparing benchmark designed by administrators to your profile.</p>
               </div>
               <div className="fixed-column-40 left-padding top-padding-5">
                 <button className="background-button" onClick={() => this.props.closeModal()}>
                   <img src={closeIcon} alt="GC" className="image-auto-15" />
                 </button>
               </div>
               <div className="clear" />
             </div>
           )}

           <div className="spacer" />

           {(this.state.selectedApplication) && (
             <ProfileCard selectedApplication={this.state.selectedApplication} workPreferenceResults={this.state.workPreferenceResults} selectedJob={this.state.selectedJob} benchmark={this.state.selectedBenchmark} benchmarkOptions={null} wpQuestions={this.state.wpQuestions} source={null} trackBenchmark={null} matchIndex={null} activeOrg={this.state.org} accountCode={null} />
           )}

         </div>
      )
    }
}

export default ConfigureProfileMatch;
