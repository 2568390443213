import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Axios from 'axios';
// import { Tooltip as ReactTooltip } from 'react-tooltip';

import SubHelpOutWidget from './HelpOutWidget';

import {convertDateToString} from '../Functions/ConvertDateToString';

const deniedIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png';
const projectsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-dark.png';
// const projectsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-blue.png';
const careerMatchesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png';
const educationIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/education-icon.png";
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';

class GoalDetails extends Component {
    constructor(props) {
      super(props)
      this.state = {
        showModule: true,

        selectedPeople: [],
        selectedLinks: [],
        selectedTimes: [],
        selectedProjects: [],
        selectedCareers: [],
        linkCategoryOptions: ['','Video','Event','Course','Article','Report','Job Opportunity','Other']
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.renderTaggedItem = this.renderTaggedItem.bind(this)
      this.renderTags = this.renderTags.bind(this)
      this.addItem = this.addItem.bind(this)
      this.removeItem = this.removeItem.bind(this)
      this.closeModal = this.closeModal.bind(this)
      this.searchItems = this.searchItems.bind(this)
      this.searchItemClicked = this.searchItemClicked.bind(this)
      this.formatStartDate = this.formatStartDate.bind(this)
      this.configureLink = this.configureLink.bind(this)
      this.renderDetails = this.renderDetails.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called within subEditSubscription')

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in commonGoalDetails', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      } else if (this.props.selectedGoal !== prevProps.selectedGoal) {
        this.retrieveData()
      } else if (this.props.orgCode !== prevProps.orgCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within commonGoalDetails', this.props)

      const email = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      }
      const accountCode = localStorage.getItem('accountCode');

      // const subscriptionType = null
      const selectedGoal = this.props.selectedGoal
      const modalIsOpen = this.props.modalIsOpen
      const showGoalDetails = this.props.showGoalDetails
      const showHelpOutWidget = this.props.showHelpOutWidget
      const profileData = this.props.profileData
      const excludeModal = this.props.excludeModal

      // let loggedIn = false
      // if (cuFirstName && email) {
      //   loggedIn = true
      // }

      this.setState({
        emailId: email, email, cuFirstName, cuLastName, orgFocus, roleName, activeOrg,
        accountCode, selectedGoal, modalIsOpen, showGoalDetails,
        showHelpOutWidget, excludeModal, profileData
      })

      if (!this.props.profileData && this.props.selectedGoal) {
        Axios.get('/api/users/profile/details', { params: { email: this.props.selectedGoal.creatorEmail } })
        .then((response) => {
          console.log('User details query 1 attempted', response.data);

          if (response.data.success) {
             console.log('successfully retrieved user details')

             let profileData = response.data.user
             this.setState({ profileData });

          } else {
           console.log('no user details data found', response.data.message)
          }

        }).catch((error) => {
           console.log('User details query did not work', error);
        });
      }

      if (selectedGoal && selectedGoal.orgCode) {
        // get orgContactEmail
        Axios.get('/api/org', { params: { orgCode: selectedGoal.orgCode } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const goalOrgCode = selectedGoal.orgCode
            const goalOrgName = selectedGoal.orgName
            const goalOrgContactEmail = response.data.orgInfo.contactEmail
            this.setState({ goalOrgCode, goalOrgName, goalOrgContactEmail });

          } else {
            console.log('org info query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name.includes("people|")) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let selectedPeople = this.state.selectedPeople
        selectedPeople[index][name] = event.target.value
        this.setState({ selectedPeople })
      } else if (event.target.name.includes("link|")) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let selectedLinks = this.state.selectedLinks
        selectedLinks[index][name] = event.target.value
        this.setState({ selectedLinks })
      } else if (event.target.name.includes("time|")) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let selectedTimes = this.state.selectedTimes
        selectedTimes[index][name] = event.target.value
        this.setState({ selectedTimes })
      } else if (event.target.name.includes("project|")) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let selectedProjects = this.state.selectedProjects
        selectedProjects[index][name] = event.target.value
        this.setState({ selectedProjects })
      } else if (event.target.name === 'searchCareers') {
        this.searchItems(event.target.value, 'career')
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchItems(searchString, type) {
      console.log('searchItems called', searchString, type)

      if (type.includes('career')) {
        if (!searchString || searchString === '') {
          this.setState({ searchCareers: searchString, searchIsAnimatingCareers: false, careerOptions: null })
        } else {

          let searchIsAnimatingCareers = true

          this.setState({ searchCareers: searchString, searchIsAnimatingCareers })

          const search = true

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            const excludeMissingOutlookData = true
            const excludeMissingJobZone = true

            Axios.put('/api/careers/search', {  searchString, search, excludeMissingOutlookData, excludeMissingJobZone })
            .then((response) => {
              console.log('Careers query attempted', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved careers')

                  if (response.data) {

                    let careerOptions = []
                    if (response.data.careers && response.data.careers.length > 0) {
                      careerOptions = response.data.careers
                    }

                    self.setState({ careerOptions, searchIsAnimatingCareers: false })
                  }

                } else {
                  console.log('no career data found', response.data.message)
                  let careerOptions = []
                  self.setState({ careerOptions, searchIsAnimatingCareers: false })
                }

            }).catch((error) => {
                console.log('Career query did not work', error);

                let careerOptions = []
                self.setState({ careerOptions, searchIsAnimatingCareers: false })
            });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();
        }
      }
    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type.includes('career')) {

        let searchObject = passedItem
        let searchCareers = passedItem.name
        let careerOptions = null
        let unready = false

        this.setState({ searchObject, searchCareers, careerOptions, unready })

      }
    }

    renderTaggedItem(item, type, answer) {
      console.log('renderTaggedItem called', item, type, answer)

      let defaultProfileItemIcon = projectsIconDark
      if (type === 'project') {
        defaultProfileItemIcon = projectsIconDark
      } else if (type === 'career') {
        defaultProfileItemIcon = careerMatchesIconDark
      } else if (type === 'competency') {
        defaultProfileItemIcon = educationIcon
      } else if (type === 'work') {
        defaultProfileItemIcon = opportunitiesIconDark
      }

      let itemObject = item.aItem
      if (answer === 'b') {
        itemObject = item.bItem
      }

      if (type === 'project') {

        return (
          <div key="taggedProjectItem">
            <Link to={'/app/projects/' + itemObject._id} className="background-button standard-color full-width">
              {(answer === 'a') ? (
                <div>
                  <div className="calc-column-offset-80 heading-text-5 right-text">
                    <p>A: {item.aName}</p>
                  </div>
                  <div className="fixed-column-80 heading-text-5">
                    {(item.aValue) && (
                      <p className="bold-text right-text cta-color">${item.aValue}</p>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="fixed-column-80 heading-text-5">
                    {(item.bValue) ? (
                      <p className="bold-text cta-color">${item.bValue}</p>
                    ) : (
                      <div className="width-40 height-30" />
                    )}
                  </div>
                  <div className="calc-column-offset-80 heading-text-5">
                    <p className="full-width right-text">B: {item.bName}</p>
                  </div>
                </div>
              )}

              <div className="clear" />
            </Link>

            <div className="row-5">
              <div className="bottom-padding">
                <div className="cta-border">
                  <Link to={'/app/projects/' + itemObject._id} className={(answer === 'a') ? "background-button standard-color padding-20 full-width" : "background-button standard-color padding-20 full-width right-text"}>
                    {(answer === 'a') ? (
                      <div className="padding-20">
                        <div className="fixed-column-60">
                          <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                        </div>
                        <div className="calc-column-offset-60">
                          <p>{itemObject.name}</p>
                          <p className="description-text-3 description-text-color">{itemObject.category} | {itemObject.hours} Hours</p>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <div className="padding-20">
                        <div className="calc-column-offset-60 right-padding">
                          <p>{itemObject.name}</p>
                          <p className="description-text-3 description-text-color">{itemObject.category} | {itemObject.hours} Hours</p>
                        </div>
                        <div className="fixed-column-60">
                          <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                        </div>
                        <div className="clear" />
                      </div>
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      } else if (type === 'work') {
        return (
          <div key="taggedWorkItem">
            <Link to={'/app/opportunities/' + itemObject._id} className="background-button standard-color padding-20 full-width">
              <div className="calc-column-offset-80">
                {(answer === 'a') ? (
                  <p>A: {item.aName}</p>
                ) : (
                  <p>B: {item.bName}</p>
                )}
              </div>
              <div className="fixed-column-80">
                {(answer === 'a') ? (
                  <p className="bold-text right-text cta-color">${item.aValue}</p>
                ) : (
                  <p className="bold-text right-text cta-color">${item.bValue}</p>
                )}
              </div>
              <div className="clear" />
            </Link>

            <div className="row-5">
              <div className="cta-border">
                <Link to={'/app/opportunities/' + itemObject._id} className="background-button standard-color padding-20 full-width">
                  <div className="padding-20">
                    <div className="fixed-column-50">
                      <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-40-fit" />
                    </div>
                    <div className="calc-column-offset-50">
                      {(itemObject.title) ? (
                        <p>{itemObject.title}</p>
                      ) : (
                        <p>{itemObject.name}</p>
                      )}

                      {(itemObject.employerName) && (
                        <p className="description-text-3 description-text-color">{itemObject.employerName}</p>
                      )}

                    </div>
                    <div className="clear" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )
      } else if (type === 'career') {
        return (
          <div key="taggedCareerItem">
            <Link to={'/app/careers/' + itemObject.name} className="background-button standard-color padding-20 full-width">
              <div className="calc-column-offset-80">
                {(answer === 'a') ? (
                  <p>A: {item.aName}</p>
                ) : (
                  <p>B: {item.bName}</p>
                )}
              </div>
              <div className="fixed-column-80">
                {(answer === 'a') ? (
                  <p className="bold-text right-text cta-color">${item.aValue}</p>
                ) : (
                  <p className="bold-text right-text cta-color">${item.bValue}</p>
                )}
              </div>
              <div className="clear" />
            </Link>

            <div className="bottom-padding">
              <div className="cta-border">
                <Link to={'/app/careers/' + itemObject.name} className="background-button standard-color padding-20 full-width">
                  <div className="padding-20">
                    <div className="fixed-column-60">
                      <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                    </div>
                    <div className="calc-column-offset-60">
                      <p>{itemObject.name}</p>
                      <label className="description-text-3 description-text-color">{itemObject.jobFamily}</label>

                      {(itemObject.marketData) && (
                        <label className="description-text-3 description-text-color"> | ${Number(itemObject.marketData.pay).toLocaleString()} avg pay</label>
                      )}
                    </div>
                    <div className="clear" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )
      } else if (type === 'competency') {
        return (
          <div key="taggedCompetencyItem">
            <div className="bottom-padding">
              <div className="calc-column-offset-80">
                {(answer === 'a') ? (
                  <p>A: {item.aName}</p>
                ) : (
                  <p>B: {item.bName}</p>
                )}
              </div>
              <div className="fixed-column-80">
                {(answer === 'a') ? (
                  <p className="bold-text right-text cta-color">${item.aValue}</p>
                ) : (
                  <p className="bold-text right-text cta-color">${item.bValue}</p>
                )}
              </div>
              <div className="clear" />
            </div>

            <div className="bottom-padding">
              <div className="cta-border">
                <div className="standard-color padding-20 full-width">
                  <div>
                    <div className="fixed-column-60">
                      <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                    </div>
                    <div className="calc-column-offset-60">
                      <p>{itemObject.name}</p>
                      <label className="description-text-3 description-text-color">{itemObject.category}</label>

                      {(itemObject.description) && (
                        <div>
                          <div className="clear" />
                          <label className="description-text-3 description-text-color">{itemObject.description}</label>
                        </div>
                      )}
                    </div>
                    <div className="clear" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )

      }
    }

    renderTags(passedArray, type, editMode, inModal) {
      // console.log('renderTags  called', passedArray, type, editMode)

      if (passedArray && passedArray.length > 0) {
        let backgroundColorClass = ''
        if (type === 'careers' || type === 'functions' || type === 'industries') {
          backgroundColorClass = 'primary-background-light'
        } else if (type === 'opportunities') {
          backgroundColorClass = 'secondary-background-light'
        } else if (type === 'competencies') {
          backgroundColorClass = 'tertiary-background-light'
        } else if (type === 'hours') {
          backgroundColorClass = 'quaternary-background-light'
        } else if (type === 'payRanges') {
          backgroundColorClass = 'quinary-background-light'
        } else if (type === 'schools') {
          backgroundColorClass = 'senary-background-light'
        } else if (type === 'majors') {
          backgroundColorClass = 'septary-background-light'
        }

        return (
          <div key={type + "|0"} className={(inModal) && "display-inline center-text"}>
            <div className={(inModal) ? "display-inline center-text" : "top-margin"}>
              {passedArray.map((value, optionIndex) =>
                <div key={type + "|" + optionIndex} className={(inModal) ? "display-inline center-text" : "float-left"}>
                  {(optionIndex < 3) && (
                    <div>
                      {(editMode) && (
                        <div className="close-button-container-1" >
                          <button className="background-button" onClick={() => this.removeItem(type, optionIndex)}>
                            <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                          </button>
                        </div>
                      )}
                      <div className={(inModal) ? "display-inline right-padding-5 center-text" : "float-left right-padding-5"}>
                        <div className="half-spacer" />
                        <div className={"rounded-corners row-7 horizontal-padding-5 " + backgroundColorClass}>
                          {(typeof value === 'object') ? (
                            <div>
                              {(value.title) && (
                                <p className="description-text-2">{value.title}</p>
                              )}
                              {(value.name) && (
                                <p className="description-text-2">{value.name}</p>
                              )}
                            </div>
                          ) : (
                            <p className="description-text-2">{value}</p>
                          )}
                        </div>
                        <div className="half-spacer" />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )
      }
    }

    addItem(type) {
      console.log('addItem called', type)

      if (type === 'career') {
        if (this.state.selectedCareers && this.state.selectedCareers.includes(this.state.searchCareers)) {
          this.setState({ errorMessage: 'You have already added this career path' })
        } else {

          const searchCareers = ''
          const searchObject = null
          const unready = true

          let selectedCareers = this.state.selectedCareers
          selectedCareers.unshift(this.state.searchCareers)

          // let selectedCareerDetails = this.state.selectedCareerDetails
          // selectedCareerDetails.unshift(this.state.searchObject)

          // const selectedCareer = this.state.searchString
          this.setState({ searchCareers, searchObject, unready, selectedCareers, errorMessage: null })

        }
      } else if (type === 'entity') {
        let selectedPeople = this.state.selectedPeople
        selectedPeople.push({ firstName: '', lastName: '', email: '', relationship: '', reason: '' })
        this.setState({ selectedPeople })
      } else if (type === 'link') {
        let selectedLinks = this.state.selectedLinks
        selectedLinks.push({ category: '', url: '' })
        this.setState({ selectedLinks })
      } else if (type === 'time') {
        let selectedTimes = this.state.selectedTimes
        selectedTimes.push({ time: '' })
        this.setState({ selectedTimes })
      } else if (type === 'project') {
        let selectedProjects = this.state.selectedProjects
        selectedProjects.push({ name: '', description: '' })
        this.setState({ selectedProjects })

      }
    }

    removeItem(type, index) {
      console.log('removeItem called', type, index)

      if (type === 'entity') {
        let selectedPeople = this.state.selectedPeople
        selectedPeople.splice(index,1)
        this.setState({ selectedPeople })
      } else if (type === 'link') {
        let selectedLinks = this.state.selectedLinks
        selectedLinks.splice(index,1)
        this.setState({ selectedLinks })
      } else if (type === 'time') {
        let selectedTimes = this.state.selectedTimes
        selectedTimes.splice(index,1)
        this.setState({ selectedTimes })
      } else if (type === 'project') {
        let selectedProjects = this.state.selectedProjects
        selectedProjects.splice(index,1)
        this.setState({ selectedProjects })
      } else if (type === 'careers') {
        let selectedCareers = this.state.selectedCareers
        selectedCareers.splice(index,1)
        this.setState({ selectedCareers })
      }
    }

    closeModal() {
      console.log('closeModal called')

      if (this.state.intervalId) {
        clearInterval(this.state.intervalId)
      }

      this.setState({ modalIsOpen: false, showGoalDetails: false, showHelpOutWidget: false, intervalId: null });
      if (!this.state.excludeModal) {
        this.props.closeModal()
      }
    }

    formatStartDate(startDate) {
      console.log('formatStartDate called', startDate)

      // is string
      startDate = startDate.substring(5,7) + '/' + startDate.substring(8,10) + '/' + startDate.substring(0,4)
      return startDate
    }

    configureLink(goal) {
      console.log('configureLink')

      if (window.location.pathname.startsWith('/organizations')) {
        return '/organizations/' + this.state.activeOrg + '/members/' + goal.creatorUsername
      } else if (window.location.pathname.includes('/advisor')) {
        return '/advisor/members/' + goal.creatorUsername
      } else if (window.location.pathname.startsWith('/goals') || window.location.pathname.includes('/profile')) {
        return '/' + goal.creatorUsername + '/profile'
      } else if (window.location.pathname.startsWith('/app/')) {
        return '/app/profile/' + goal.creatorUsername
      } else {
        return '/' + goal.creatorUsername + '/profile'
      }
    }

    renderDetails() {
      console.log('renderDetails called')

      return (
        <div key="goalDetails">
          <p className="heading-text-2 center-text">{this.state.selectedGoal.title}</p>

          {(this.state.selectedGoal.goalType) && (
            <p className="full-width center-text top-margin description-text-1">{this.state.selectedGoal.goalType.description} Goal by <Link to={this.configureLink(this.state.selectedGoal)} target={(!window.location.pathname.includes('/app/')) && "_blank"}>{this.state.selectedGoal.creatorFirstName} {this.state.selectedGoal.creatorLastName}</Link></p>
          )}

          {(this.state.selectedGoal.startDate) ? (
            <p className="full-width center-text top-margin description-text-2">{this.formatStartDate(this.state.selectedGoal.startDate)} - {convertDateToString(new Date(this.state.selectedGoal.deadline),"date-2")}</p>
          ) : (
            <p className="full-width center-text top-margin description-text-2">Deadline: {convertDateToString(new Date(this.state.selectedGoal.deadline),"date-2")}</p>
          )}

          {(this.state.selectedGoal.description) && (
            <p className="top-margin-20 full-width center-text">{this.state.selectedGoal.description}</p>
          )}

          {(this.state.selectedGoal.goalType && this.state.selectedGoal.goalType.name === 'Alternatives') && (
            <div>
              {(this.state.selectedGoal.pollQuestion) && (
                <p className="heading-text-4 top-margin-40 full-width center-text">{this.state.selectedGoal.pollQuestion}</p>
              )}

              <div className="top-margin-40">
                <div className="calc-column-offset-30-of-50">

                  {(this.state.selectedGoal.aItem) && (
                    <div>
                      <div>
                        {(this.state.selectedGoal.comparisonType === 'Projects') && (
                          <div>
                            {this.renderTaggedItem(this.state.selectedGoal, 'project', 'a')}
                          </div>
                        )}
                        {(this.state.selectedGoal.comparisonType === 'Careers') && (
                          <div>
                            {this.renderTaggedItem(this.state.selectedGoal, 'career','a')}
                          </div>
                        )}
                        {(this.state.selectedGoal.comparisonType === 'Competencies') && (
                          <div>
                            {this.renderTaggedItem(this.state.selectedGoal, 'competency','a')}
                          </div>
                        )}
                        {(this.state.selectedGoal.comparisonType === 'Jobs') && (
                          <div>
                            {this.renderTaggedItem(this.state.selectedGoal, 'work','a')}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {(this.state.selectedGoal.aLinks && this.state.selectedGoal.aLinks.length > 0) && (
                    <div>
                      <p className="top-margin">Relevant Links</p>
                      {this.state.selectedGoal.aLinks.map((item, optionIndex) =>
                        <div>
                          <a href={item} target="_blank" rel="noopener noreferrer">{optionIndex + 1}. {item}</a>
                        </div>
                      )}
                    </div>
                  )}

                  <p className="top-margin-20">{this.state.selectedGoal.aCase}</p>

                </div>
                <div className="fixed-column-60">
                  <p className="full-width center-text heading-text-2">VS</p>
                </div>
                <div className="calc-column-offset-30-of-50">
                  {(this.state.selectedGoal.bItem) && (
                    <div>
                      <div>
                        {(this.state.selectedGoal.comparisonType === 'Projects') && (
                          <div>
                            {this.renderTaggedItem(this.state.selectedGoal, 'project', 'b')}
                          </div>
                        )}
                        {(this.state.selectedGoal.comparisonType === 'Careers') && (
                          <div>
                            {this.renderTaggedItem(this.state.selectedGoal, 'career','b')}
                          </div>
                        )}
                        {(this.state.selectedGoal.comparisonType === 'Competencies') && (
                          <div>
                            {this.renderTaggedItem(this.state.selectedGoal, 'competency','b')}
                          </div>
                        )}
                        {(this.state.selectedGoal.comparisonType === 'Jobs') && (
                          <div>
                            {this.renderTaggedItem(this.state.selectedGoal, 'work','b')}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {(this.state.selectedGoal.bLinks && this.state.selectedGoal.bLinks.length > 0) && (
                    <div>
                      <p className="top-margin full-width right-text">Relevant Links</p>
                      {this.state.selectedGoal.bLinks.map((item, optionIndex) =>
                        <div className="full-width right-text">
                          <a href={item} target="_blank" rel="noopener noreferrer">{optionIndex + 1}. {item}</a>
                        </div>
                      )}
                    </div>
                  )}

                  <p className="top-margin-20 full-width right-text">{this.state.selectedGoal.bCase}</p>
                </div>
                <div className="clear" />
              </div>
            </div>
          )}

          <div className="full-width center-text display-inline top-margin-20">
            {this.renderTags(this.state.selectedGoal.selectedCareers, 'careers', null, true)}
            {this.renderTags(this.state.selectedGoal.selectedOpportunities, 'opportunities', null, true)}
            {this.renderTags(this.state.selectedGoal.competencies, 'competencies', null, true)}
            {this.renderTags(this.state.selectedGoal.selectedFunctions, 'functions', null, true)}
            {this.renderTags(this.state.selectedGoal.selectedIndustries, 'industries', null, true)}
            {this.renderTags(this.state.selectedGoal.selectedHours, 'hours', null, true)}
            {this.renderTags(this.state.selectedGoal.selectedPayRanges, 'payRanges', null, true)}
            {this.renderTags(this.state.selectedGoal.selectedSchools, 'schools', null, true)}
            {this.renderTags(this.state.selectedGoal.selectedMajors, 'majors', null, true)}
            <div className="clear" />
          </div>

          <div>
            {(this.state.selectedGoal.intensity) && (
              <div className="top-margin-20 relative-column-33 center-text">
                <p className="description-text-2 description-text-color row-10">Intensity</p>
                <p>{this.state.selectedGoal.intensity}</p>
              </div>
            )}

            {(this.state.selectedGoal.budget) && (
              <div className="top-margin-20 relative-column-33 center-text">
                <p className="description-text-2 description-text-color row-10">Budget</p>
                <p>{this.state.selectedGoal.budget}</p>
              </div>
            )}

            {(this.state.selectedGoal.status) && (
              <div className="top-margin-20 relative-column-33 center-text">
                <p className="description-text-2 description-text-color row-10">Status</p>
                <p>{this.state.selectedGoal.status}</p>
              </div>
            )}
            <div className="clear" />
          </div>

          {(this.state.selectedGoal.successDefined) && (
            <div className="top-margin-20">
              <p className="description-text-2 description-text-color row-10">Success Defined</p>
              <p>{this.state.selectedGoal.successDefined}</p>
            </div>
          )}

          {(this.state.selectedGoal.strategies && this.state.selectedGoal.strategies.length > 0) && (
            <div className="standard-border padding-20 top-margin-20">
             <p className="heading-text-6">Strategies & Tactics</p>
             {this.state.selectedGoal.strategies.map((item, optionIndex) =>
               <div key={ item.name} className="row-10">
                 <p className="description-text-1">Strategy #{optionIndex + 1}: {item.name}</p>
                 {(item.tactics && item.tactics.length > 0) && (
                   <div className="row-10 left-padding-40">
                     {item.tactics.map((item2, optionIndex2) =>
                       <div key={item2}>
                         <p className="description-text-3">Tactic #{optionIndex2 + 1}: {item2}</p>
                       </div>
                     )}
                   </div>
                 )}
               </div>
             )}

            </div>
          )}

          {(this.state.selectedGoal.progress && this.state.selectedGoal.progress.length > 0) && (
            <div className="standard-border padding-20 top-margin-20">
             <p className="heading-text-6">Progress</p>
             {this.state.selectedGoal.progress.map((item, optionIndex) =>
               <div key={item.value} className="row-10">
                 <div className="fixed-column-120">
                   <p className="description-text-1">{item.date}</p>
                 </div>
                 <div className="calc-column-offset-120 left-padding">
                   <p className="description-text-1">{item.value}</p>
                 </div>
                 <div className="clear" />

               </div>
             )}

            </div>
          )}

          <div className="top-margin-40 center-text">
            <button className="btn btn-primary right-margin" onClick={() => this.setState({ modalIsOpen: true, showGoalDetails: false, showHelpOutWidget: true })}>Help Out</button>
            {(!this.state.excludeModal) && (
              <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Cancel</button>
            )}

          </div>
        </div>
      )
    }

    render() {

      return (
        <div>
          {(this.state.selectedGoal) && (
            <div>
              {(this.state.excludeModal) && (
                <div>
                   {this.renderDetails()}
                </div>
              )}

              <Modal
               isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} onRequestClose={this.closeModal}
               className="modal" overlayClassName="modal-overlay" contentLabel="Example Modal" ariaHideApp={false}
              >
                <div key="showGoalDetails">
                  {(this.state.showGoalDetails) && (
                    <div className="full-width padding-20">
                       {this.renderDetails()}
                    </div>
                  )}

                  {(this.state.showHelpOutWidget) && (
                    <div>
                      <SubHelpOutWidget profileData={this.state.profileData} selectedGoal={this.state.selectedGoal}
                        closeModal={(this.state.excludeModal) ? this.closeModal : this.props.closeModal} history={this.props.navigate}
                        selectedPeople={this.state.selectedPeople} selectedLinks={this.state.selectedLinks}
                        selectedTimes={this.state.selectedTimes} selectedProjects={this.state.selectedProjects}
                        selectedCareers={this.state.selectedCareers} orgCode={this.state.activeOrg}
                      />
                    </div>
                  )}
                </div>
              </Modal>
            </div>
          )}
        </div>
      )
    }
}

export default GoalDetails;
