import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
// import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubCoursesTwo from './Subcomponents/CoursesTwo';
import withRouter from './Functions/WithRouter';

class AdvLearning extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');

      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let remoteAuth = localStorage.getItem('remoteAuth');
      let authType = localStorage.getItem('authType');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        this.setState({ activeOrg, emailId: email, username, orgFocus,
          cuFirstName, cuLastName, roleName, orgLogo,
          remoteAuth, authType
        })
      }
    }

    render() {
        return (
            <div>
              <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} roleName={this.state.roleName} orgFocus={this.state.orgFocus} history={this.props.navigate} />
              <SubCoursesTwo activeOrg={this.state.activeOrg} />

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>
        )
    }
}

export default withRouter(AdvLearning);
