import React, {Component} from 'react';
import Axios from 'axios';
import YouTube from 'react-youtube';
import Switch from 'react-switch';
import withRouter from '../Functions/WithRouter';

const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const pathsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-dark.png';
const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png';

class EditPathway extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showModule: true,

          subNavCategories: ['All','Videos','About','Ideal Profile','Courses','People','Employers','Opportunities','Comments'],
          subNavSelected: 'All',
          functionOptions: [],
          industryOptions: [],
          benchmarkOptions: [],
          players: [],
          degreeOptions: [],
          programMethodOptions: ['','Online','In-Person','Both Online and In-Person'],
          courseOptions: [],
          trainingProgramOptions: [],
          individualCourseOptions: [],
          projectOptions: [],
          eventOptions: [],
          checklistCategoryOptions: [],

          selectedBenchmark: { title: ''},
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.addItem = this.addItem.bind(this)
        this.videoCallback = this.videoCallback.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.configureVideo = this.configureVideo.bind(this)
        this.saveChanges = this.saveChanges.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.saveFile = this.saveFile.bind(this)
        this.saveCourseChanges = this.saveCourseChanges.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ')

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.selectedTemplate || this.props.benchmarkId) {
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubEditBenchmark', this.props)

      const org = this.props.activeOrg
      const accountCode = this.props.accountCode
      // const selectedTemplate = this.props.selectedTemplate

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');

      this.setState({ emailId: email, username, cuFirstName, cuLastName, org, accountCode })

      if (org) {
        const self = this
        function unloadObject(selectedPathway) {
          console.log('unloadObject called')

          if (self.props.duplicate) {
            delete selectedPathway._id
            selectedPathway['orgCode'] = org
            selectedPathway['title'] = selectedPathway.title + " (Duplicate)"
          }

          const _id = selectedPathway._id
          const pathwayIconURI = selectedPathway.imageURL
          const title = selectedPathway.title
          const jobTitle = selectedPathway.jobTitle
          const jobFunction = selectedPathway.jobFunction
          const industry = selectedPathway.industry
          const description = selectedPathway.description
          const benchmarkId = selectedPathway.benchmarkId
          const videos = selectedPathway.videos
          const checklist = selectedPathway.checklist
          // const courses = selectedPathway.courses
          const isActive = selectedPathway.isActive
          const documentation = selectedPathway.documentation
          const stats = selectedPathway.stats
          const programs = selectedPathway.programs

          self.setState({ _id, pathwayIconURI, title, jobTitle, jobFunction, industry, description,
            benchmarkId, checklist, videos, isActive, documentation, stats, programs
          })

          if (benchmarkId) {
            Axios.get('/api/benchmarks/byid', { params: { _id: benchmarkId } })
            .then((response) => {
              console.log('Benchmark query attempted', response.data);

                if (response.data.success) {
                  console.log('benchmark query worked')

                  const selectedBenchmark = response.data.benchmark
                  self.setState({ selectedBenchmark })

                } else {
                  console.log('benchmark query did not work', response.data.message)

                }

            }).catch((error) => {
                console.log('Benchmark query did not work for some reason', error);

            });
          }

          if (selectedPathway) {
            // pull pathway courses
            Axios.get('/api/courses', { params: { pathwayId: selectedPathway._id, orgCode: org } })
            .then((response) => {

                if (response.data.success) {
                  console.log('Course details for pathway query worked', response.data);

                  if (response.data.courses) {
                    let courses = response.data.courses
                    self.setState({ courses })
                  }
                } else {
                  console.log('no courses found for pathway', response.data.message)
                }

            }).catch((error) => {
                console.log('Course query did not work for pathway', error);
            });
          }
        }

        if (this.props.pathwayId) {
          // console.log('in pathwayId: ', this.props.pathwayId)
          Axios.get('/api/pathways/byid', { params: { _id: this.props.pathwayId } })
          .then((response) => {
            console.log('Pathway query attempted', response.data);

              if (response.data.success) {
                console.log('pathway query worked')

                unloadObject(response.data.pathway)

              } else {
                console.log('pathway query did not work', response.data.message)

              }

          }).catch((error) => {
              console.log('Pathway query did not work for some reason', error);

          });
        } else if (this.props.selectedTemplate) {
          console.log('passed an existing pathway')
          unloadObject(this.props.selectedTemplate)
        }

        Axios.get('/api/benchmarks', { params: { orgCodes: [org, 'general'], accountCode } })
        .then((response) => {
          console.log('Benchmarks query attempted no 1', response.data);

          if (response.data.success) {
            console.log('benchmark query worked no 1')

            if (response.data.benchmarks.length !== 0) {

              let benchmarkOptions = response.data.benchmarks
              benchmarkOptions.unshift({ title: 'No Benchmark Added'})
              this.setState({ benchmarkOptions });
            }

          } else {
            console.log('benchmark query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Benchmark query did not work for some reason', error);
        });

        Axios.get('/api/courses', { params: { orgCode: org } })
        .then((response) => {

            if (response.data.success) {
              console.log('Course details query worked', response.data);

              if (response.data.courses) {
                let courseOptions = [{ name: '' }].concat(response.data.courses)

                let trainingProgramOptions = [{ name: '' }]
                let individualCourseOptions = [{ name: '' }]
                for (let i = 1; i <= courseOptions.length; i++) {
                  if (courseOptions[i - 1].category === 'Training Program') {
                    trainingProgramOptions.push(courseOptions[i - 1])
                  } else {
                    individualCourseOptions.push(courseOptions[i - 1])
                  }
                }

                this.setState({ courseOptions, trainingProgramOptions, individualCourseOptions })

              }
            } else {
              console.log('no courses found', response.data.message)
            }

        }).catch((error) => {
            console.log('Course query did not work', error);
        });

        if (org) {
          console.log('trying to post: ', org)

          Axios.get('/api/postings/user', { params: { orgCode: org, postType: ['Project','Assignment','Problem','Challenge'], onlyProjects: true } })
          .then((response) => {
            console.log('working on it', response.data.message)
            if (response.data.success) {
              console.log('Posting details query worked', response.data);
              const projectOptions = [{ name: '' }].concat(response.data.postings)
              this.setState({ projectOptions })

            } else {
              console.log('no postings found', response.data.message)
            }

          }).catch((error) => {
              console.log('Posting query did not work', error);
          });
        }


        Axios.get('/api/workoptions')
        .then((response) => {
          console.log('Work options query tried', response.data);

          if (response.data.success) {
            console.log('Work options query succeeded')

            let degreeOptions = response.data.workOptions[0].degreeOptions
            degreeOptions = degreeOptions.concat(['Certificate','Badge','Certification'])

            let functionOptions = response.data.workOptions[0].functionOptions
            // functionOptions.shift()
            let industryOptions = response.data.workOptions[0].industryOptions
            // industryOptions.shift()
            let workDistanceOptions = response.data.workOptions[0].workDistanceOptions
            // workDistanceOptions.shift()
            let hoursPerWeekOptions = response.data.workOptions[0].hoursPerWeekOptions
            // hoursPerWeekOptions.shift()

            let hourlyPayOptions = response.data.workOptions[0].hourlyPayOptions
            // hourlyPayOptions.shift()
            let annualPayOptions = response.data.workOptions[0].annualPayOptions
            // annualPayOptions.shift()

            let genderOptions = response.data.workOptions[0].genderOptions
            genderOptions.unshift('')
            genderOptions[genderOptions.length - 1] = "Not Important (N/A)"

            let raceOptions = response.data.workOptions[0].raceOptions
            raceOptions.unshift('')
            raceOptions[raceOptions.length - 1] = "Not Important (N/A)"

            let veteranOptions = response.data.workOptions[0].veteranOptions
            veteranOptions.unshift('')
            veteranOptions[veteranOptions.length - 1] = "Not Important (N/A)"

            let lowIncomeOptions = response.data.workOptions[0].lowIncomeOptions
            lowIncomeOptions.unshift('')
            lowIncomeOptions[lowIncomeOptions.length - 1] = "Not Important (N/A)"

            let fosterYouthOptions = response.data.workOptions[0].fosterYouthOptions
            fosterYouthOptions.unshift('')
            fosterYouthOptions[fosterYouthOptions.length - 1] = "Not Important (N/A)"

            let homelessOptions = response.data.workOptions[0].homelessOptions
            homelessOptions.unshift('')
            homelessOptions[homelessOptions.length - 1] = "Not Important (N/A)"

            let incarceratedOptions = response.data.workOptions[0].incarceratedOptions
            incarceratedOptions.unshift('')
            incarceratedOptions[incarceratedOptions.length - 1] = "Not Important (N/A)"

            let lgbtqiaOptions = response.data.workOptions[0].lgbtqiaOptions
            lgbtqiaOptions.unshift('')
            lgbtqiaOptions[lgbtqiaOptions.length - 1] = "Not Important (N/A)"

            let disabilityOptions = response.data.workOptions[0].disabilityOptions
            disabilityOptions.unshift('')
            disabilityOptions[disabilityOptions.length - 1] = "Not Important (N/A)"

            let firstGenImmigrantOptions = response.data.workOptions[0].firstGenImmigrantOptions
            firstGenImmigrantOptions.unshift('')
            firstGenImmigrantOptions[firstGenImmigrantOptions.length - 1] = "Not Important (N/A)"

            let firstGenCollegeStudentOptions = response.data.workOptions[0].firstGenCollegeStudentOptions
            firstGenCollegeStudentOptions.unshift('')
            firstGenCollegeStudentOptions[firstGenCollegeStudentOptions.length - 1] = "Not Important (N/A)"

            let politicalAlignmentOptions = response.data.workOptions[0].politicalAlignmentOptions
            if (politicalAlignmentOptions) {
              politicalAlignmentOptions.unshift('')
            }

            let unitedStateOptions = response.data.workOptions[0].unitedStateOptions
            if (unitedStateOptions) {
              unitedStateOptions.unshift('')
            }

            let districtOptions = []
            for (let i = 0; i <= 40; i++) {
              districtOptions.push(i)
            }

            let checklistCategoryOptions = [''].concat(response.data.workOptions[0].checklistCategoryOptions)

            this.setState({ degreeOptions, functionOptions, industryOptions, workDistanceOptions, hoursPerWeekOptions,
              hourlyPayOptions, annualPayOptions, politicalAlignmentOptions, unitedStateOptions, districtOptions,
              genderOptions, raceOptions, veteranOptions, lowIncomeOptions, fosterYouthOptions, homelessOptions,
              incarceratedOptions, lgbtqiaOptions, disabilityOptions, firstGenImmigrantOptions, firstGenCollegeStudentOptions,
              checklistCategoryOptions
            })
          }
        });
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler clicked', event.target.name, event.target.value)

      if (event.target.name.includes('video')) {
        const index = Number(event.target.name.split("|")[1])
        let videos = this.state.videos
        videos[index] = event.target.value
        this.setState({ videos })
      } else if (event.target.name === 'pathwayIcon') {
        console.log('pathwayIcon SelectedHandler changed')

        if (event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ pathwayIconImage: e.target.result });
                console.log('how do i access the image', e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);
            this.setState({ pathwayIconFile: event.target.files[0], formHasChanged: true })
            // this.saveFile(event.target.name, event.target.files[0])
        }
      } else if (event.target.name.includes('selectedBenchmark')) {
        let selectedBenchmark = this.state.selectedBenchmark
        if (this.state.benchmarkOptions) {
          for (let i = 1; i <= this.state.benchmarkOptions.length; i++) {
            // console.log('compare the options: ', this.state.benchmarkOptions[i - 1].title, this.state.selectedBenchmark)
            if (this.state.benchmarkOptions[i - 1].title === event.target.value) {
              selectedBenchmark = this.state.benchmarkOptions[i - 1]
            }
          }
        }
        this.setState({ selectedBenchmark })

      } else if (event.target.name.includes('level|')) {

        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let levels = this.state.levels

        if (name === 'benchmark') {
          console.log('in benchmark: ')

          let selectedBenchmark = levels[index][name]
          if (this.state.benchmarkOptions) {
            for (let i = 1; i <= this.state.benchmarkOptions.length; i++) {
              // console.log('compare the options: ', this.state.benchmarkOptions[i - 1].title, this.state.selectedBenchmark)
              if (this.state.benchmarkOptions[i - 1].title === event.target.value) {
                selectedBenchmark = this.state.benchmarkOptions[i - 1]
              }
            }
          }

          levels[index][name] = selectedBenchmark
        } else if (name === 'stat') {
          const childName = event.target.name.split("|")[2]
          const parentIndex = event.target.name.split("|")[3]
          const childIndex = event.target.name.split("|")[4]

          let stats = []
          if (levels[parentIndex]) {
            stats = levels[parentIndex].stats
          }

          stats[childIndex][childName] = event.target.value
          levels[parentIndex][name] = stats
        } else if (name === 'learningPathway') {

          if (event.target.name.split("|")[2] === 'resource') {
            console.log('up in the resource')
            // level|learningPathway|resource|category|0|0|0 Training Program
            const parentIndex = Number(event.target.name.split("|")[4])
            const childIndex = Number(event.target.name.split("|")[5])
            const grandChildIndex = Number(event.target.name.split("|")[6])
            const grandChildName = event.target.name.split("|")[3]

            if (levels[parentIndex]) {
              if (levels[parentIndex].learningPathways[childIndex]) {
                let resources = levels[parentIndex]['learningPathways'][childIndex].resources
                resources[grandChildIndex][grandChildName] = event.target.value
                levels[parentIndex]['learningPathways'][childIndex]['resources'] = resources
              }
            }

            // "level|learningPathway|value|resource|category|" + optionIndex + "|" + optionIndex2 + "|" + optionIndex3
          } else {
            const childName = event.target.name.split("|")[2]
            const parentIndex = event.target.name.split("|")[3]
            const childIndex = event.target.name.split("|")[4]

            let learningPathways = []
            if (levels[parentIndex]) {

              learningPathways = levels[parentIndex].learningPathways
            }

            learningPathways[childIndex][childName] = event.target.value
            levels[parentIndex][name] = learningPathways
          }
        } else {
          levels[index][name] = event.target.value
        }

        this.setState({ levels })
      } else if (event.target.name.includes('stat|')) {

        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        console.log('jmmm', name, index)

        let stats = this.state.stats
        stats[index][name] = event.target.value
        this.setState({ stats })
      } else if (event.target.name.includes('program|')) {

        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let programs = this.state.programs
        programs[index][name] = event.target.value
        this.setState({ programs })
      } else if (event.target.name.includes("checklist|")) {
        let checklist = this.state.checklist

        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        checklist[index][name] = event.target.value
        this.setState({ checklist })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    addItem(type, value) {
      console.log('addItem called', type, value)

      if (type === 'video') {
        let videos = this.state.videos
        if (videos) {
          videos.push('')
        } else {
          videos = ['']
        }
        this.setState({ videos })
      } else if (type === 'course') {
        let courses = this.state.courses

        if (!this.state.courseName) {
          this.setState({ errorMessage: "Please add a course name"})
        } else {
          // let courseObject = { name: this.state.courseName }
          // if (this.state.courseURL) {
          //   courseObject['url'] = this.state.courseURL
          // }
          let courseObject = { name: value }
          for (let i = 1; i <= this.state.courseOptions.length; i++) {
            // console.log('compare the two: ', this.state.courseOptions[i - 1].name, value)
            if ((this.state.courseOptions[i - 1].name + ' from ' + this.state.courseOptions[i - 1].schoolName) === value) {
              courseObject = this.state.courseOptions[i - 1]
              courseObject['courseId'] = this.state.courseOptions[i - 1]._id
            }
          }

          // console.log('courseObject: ', courseObject)
          if (courses) {
            courses.push(courseObject)
          } else {
            courses = [courseObject]
          }

          let courseName = ''
          let courseURL = ''

          this.setState({ courses, courseName, courseURL })

          if (this.state._id) {
            this.saveCourseChanges(courseObject,'add')
          }

        }
      } else if (type === 'stat') {
        let stats = this.state.stats
        if (stats) {
          stats.push({ name: '', value: ''})
        } else {
          stats = [{ name: '', value: ''}]
        }
        this.setState({ stats })
      } else if (type === 'program') {
        let programs = this.state.programs
        if (programs) {
          programs.push({ name: '', value: ''})
        } else {
          programs = [{ name: '', value: ''}]
        }
        this.setState({ programs })
      } else if (type === 'level') {
        let levels = this.state.levels

        let defaultLevel = { name: '', stats: [{ name: 'Pay Range', value: '$0 - $100,000'},{ name: 'Job Openings', value: '50,000 - 100,000'},{ name: 'Projected Job Openings', value: '50,000 - 100,000'},{ name: 'Education', value: 'Bachelors Degree and Independent Coursework'},{ name: 'Experience', value: '0 - 2 Years'}]}
        if (levels) {
          levels.push(defaultLevel)
          // levels.push({ name: '', value: ''})
        } else {
          levels = [defaultLevel]
          // levels = [{ name: '', value: ''}]
        }
        this.setState({ levels })

      } else if (type.includes('level|')) {
        let levels = this.state.levels
        const index = Number(type.split('|')[2])
        if (type.split('|')[1] === 'stat') {

          let stats = levels[index].stats
          if (stats) {
            stats.push({ name: '', value: ''})
          } else {
            stats = [{ name: '', value: ''}]
          }
          levels[index]['stats'] = stats
        } else if (type.split('|')[1] === 'learningPathway') {
          // console.log('show stuff: ', type, index)
          // 'level|learningPathway|resource|' + optionIndex + '|' + optionIndex2)

          if (type.split('|')[2] === 'resource') {
            const parentIndex = Number(type.split('|')[3])
            let learningPathways = levels[parentIndex].learningPathways
            const childIndex = Number(type.split('|')[4])
            let resources = learningPathways[childIndex].resources
            if (resources) {
              resources.push({ category: '' })
            } else {
              resources = [{ category: '' }]
            }
            levels[parentIndex]['learningPathways'][childIndex]['resources'] = resources

          } else {
            let learningPathways = levels[index].learningPathways
            if (learningPathways) {
              learningPathways.push({ name: '', learnerType: '', resources: [] })
            } else {
              learningPathways = [{ name: '', learnerType: '', resources: [] }]
            }
            levels[index]['learningPathways'] = learningPathways
          }

        }

        this.setState({ levels })

      } else if (type === 'checklist') {
        let checklist = this.state.checklist
        if (checklist) {
          checklist.push({ name: null, url: null, category: null })
        } else {
          checklist = [{ name: null, url: null, category: null }]
        }
        this.setState({ checklist })
      }
    }

    videoCallback(event, callback) {
      console.log('videoCallback called', event, callback)

      if (callback === 'onReady') {
        const players = this.state.players;
        players.push(event.target);
        this.setState({ players });
        console.log('players: ', event.target.id)
      } else if (callback === 'onPlay') {
        this.state.players.forEach((player) => {
          if (player.id !== event.target.id) {
            player.pauseVideo();
          }
        });
      }
    }

    removeItem(type, index) {
      console.log('removeItem called', type, index)

      if (type === 'video') {
        let videos = this.state.videos
        videos.splice(index,1)
        this.setState({ videos })
      } else if (type === 'stat') {
        let stats = this.state.stats
        stats.splice(index,1)
        this.setState({ stats })
      } else if (type === 'program') {
        let programs = this.state.programs
        programs.splice(index,1)
        this.setState({ programs })
      } else if (type === 'level') {
        let levels = this.state.levels
        levels.splice(index,1)
        this.setState({ levels })
      } else if (type.includes('level|')) {
        let levels = this.state.levels
        const name = type.split('|')[1]
        let childIndex = Number(type.split('|')[2])
        if (name === 'stat') {

          let stats = levels[index].stats
          console.log('show childIndex 1: ', stats, childIndex, stats[childIndex])
          stats.splice(childIndex,1)
          console.log('show childIndex 2: ', stats, childIndex, stats[childIndex])
          levels[index]['stats'] = stats
        } else if (name === 'learningPathway') {
          let learningPathways = levels[index].learningPathways

          if (type.split('|')[2] === 'resource') {
            childIndex = Number(type.split('|')[3])
            const grandChildIndex = Number(type.split('|')[4])

            let resources = learningPathways[childIndex]['resources']
            resources.splice(grandChildIndex,1)
            learningPathways[childIndex]['resources'] = resources
          } else {
            learningPathways.splice(childIndex,1)
          }
          // 'level|learningPathway|resource|' + optionIndex2 + '|' + optionIndex3, optionIndex


          levels[index]['learningPathways'] = learningPathways
        }
        // levels.splice(index,1)
        this.setState({ levels })
      } else if (type === 'checklist') {
        let checklist = this.state.checklist
        checklist.splice(index,1)
        this.setState({ checklist })
      }
    }

    renderTags(type) {
      console.log('renderTags called', type)

      if (this.state.courses) {
        console.log('course: ', this.state.courses)
        return (
          <div key="tags">
            {this.state.courses.map((value, optionIndex) =>
              <div key={"tag" + optionIndex} className="float-left">
                <div className="close-button-container-1" >
                  <button className="background-button" onClick={() => this.removeTag(optionIndex, type)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <div>
                  <div className="half-spacer" />

                  <div className="tag-container-8 primary-background primary-border white-text">
                    <p className="description-text-2">{value.name}{(value.schoolName) && " from " + value.schoolName}</p>
                  </div>
                  <div className="half-spacer" />
                </div>
              </div>
            )}
          </div>
        )
      }
    }

    removeTag(index, type) {
      console.log('removeTag called', index, type)

      if (type === 'course') {
        const courseObject = this.state.courses[index]
        let courses = this.state.courses
        courses.splice(index, 1)
        this.setState({ courses })
        this.saveCourseChanges(courseObject,'remove')
      }
    }

    saveCourseChanges(course,action) {
      console.log('saveCourseChanges called')

      this.setState({ isSaving: false, successMessage: null, errorMessage: null })

      const sourceId = this.state._id
      const title = this.state.title

      const courseSaveObject = {
        courseId: course._id, action, pathway: { sourceId, title }
      }

      Axios.post('/api/courses/pathways', courseSaveObject)
      .then((response) => {
        console.log('Attempting to save pathway to courses')
        if (response.data.success) {
          //save values
          console.log('Pathway to courses save worked in individual');

          this.setState({ successMessage: 'Successfully course changes saved', isSaving: false })
        } else {
          console.log('Pathway to courses save did not work', response.data.message)
          this.setState({ isSaving: false, errorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('Pathway to courses save did not work for some reason', error);
          this.setState({ isSaving: false, errorMessage: error.toString() })
      });
    }

    configureVideo(videoLink) {
      console.log('configureVideo called', videoLink)

      if (videoLink) {
        if (videoLink.includes("v=")) {
          videoLink = videoLink.split("v=")[1]
        } else if (videoLink.includes("/embed")) {
          videoLink = videoLink.split("/embed/")[1]
        }

        return videoLink
      }
    }

    saveChanges() {
      console.log('saveChanges called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })
      if (!this.state.title || this.state.title === '') {
        this.setState({ errorMessage: 'Please add a pathway title', isSaving: false })
      } else if (!this.state.jobTitle || this.state.jobTitle === '') {
        this.setState({ errorMessage: 'Please add a job title', isSaving: false })
      } else if (!this.state.industry || this.state.industry === '') {
        this.setState({ errorMessage: 'Please add an industry', isSaving: false })
      } else if (!this.state.description || this.state.description === '') {
        this.setState({ errorMessage: 'Please add a description', isSaving: false })
      } else if (!this.state.selectedBenchmark || this.state.selectedBenchmark.title === '' || this.state.selectedBenchmark.title === 'No Benchmark Added') {
        this.setState({ errorMessage: 'Please add a benchmark', isSaving: false })
      } else {
        // console.log('show me selectedBenchmark: ', this.state.selectedBenchmark)
        const _id = this.state._id
        const title = this.state.title
        const jobTitle = this.state.jobTitle
        const jobFunction = this.state.jobFunction
        const industry = this.state.industry
        const description = this.state.description
        let benchmarkId = null
        if (this.state.benchmarkOptions) {
          for (let i = 1; i <= this.state.benchmarkOptions.length; i++) {
            if (this.state.benchmarkOptions[i - 1].title === this.state.selectedBenchmark.title) {
              benchmarkId = this.state.benchmarkOptions[i - 1]._id
            }
          }
        }

        const videos = this.state.videos
        const checklist = this.state.checklist
        const courses = this.state.courses
        const orgCode = this.state.org
        let isActive = this.state.isActive
        if (!isActive) {
          isActive = true
        }

        const documentation = this.state.documentation
        const stats = this.state.stats
        const programs = this.state.programs

        const createdAt = new Date()
        const updatedAt = new Date()

        const saveObject = {
          _id, title, jobTitle, jobFunction, industry, description, benchmarkId, videos, checklist,
          courses, orgCode, isActive,
          documentation, stats, programs,
          createdAt, updatedAt
        }

        Axios.post('/api/pathways', saveObject)
        .then((response) => {
          console.log('Attempting to save pathway')
          if (response.data.success) {
            //save values
            console.log('Pathway save worked in individual');

            this.setState({ successMessage: 'Successfully saved', isSaving: false })

            // if (this.state.pathwayIconFile) {
            //   this.saveFile("pathwayIcon", this.state.pathwayIconFile, response.data._id)
            // }
            //
            // if (this.props.duplicate && this.props.navigate.goBack) {
            //   this.props.navigate(-1)
            // }

            if (this.state.showModule) {
              if (!_id) {
                const courseSaveObject = {
                  courses, pathway: { sourceId: response.data._id, title }
                }

                Axios.post('/api/courses/pathways', courseSaveObject)
                .then((response) => {
                  console.log('Attempting to save pathway to courses')
                  if (response.data.success) {
                    //save values
                    console.log('Pathway to courses save worked in individual');

                    this.setState({ successMessage: 'Successfully saved', isSaving: false })

                    if (this.state.pathwayIconFile) {
                      this.saveFile("pathwayIcon", this.state.pathwayIconFile, response.data._id)
                    }

                    if (this.props.duplicate && this.props.navigate.goBack) {
                      this.props.navigate(-1)
                    }

                  } else {
                    console.log('Pathway to courses save did not work', response.data.message)
                    // this.setState({ isSaving: false, errorMessage: response.data.message })
                  }
                }).catch((error) => {
                    console.log('Pathway to courses save did not work for some reason', error);
                    // this.setState({ isSaving: false, errorMessage: error.toString() })
                });
              } else {
                if (this.state.pathwayIconFile) {
                  this.saveFile("pathwayIcon", this.state.pathwayIconFile, response.data._id)
                }

                if (this.props.duplicate && this.props.navigate.goBack) {
                  this.props.navigate(-1)
                }
              }
            } else {
              if (this.state.pathwayIconFile) {
                this.saveFile("pathwayIcon", this.state.pathwayIconFile, response.data._id)
              }

              if (this.props.duplicate && this.props.navigate.goBack) {
                this.props.navigate(-1)
              }
            }

          } else {
            console.log('Pathway save did not work', response.data.message)
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('Pathway save did not work for some reason', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      }
    }

    deleteItem() {
      console.log('deletePost')

      const _id = this.state._id
      Axios.delete('/api/pathways/' + _id)
      .then((response) => {
        console.log('tried to delete pathway', response.data)
        if (response.data.success) {
          //save values
          console.log('Pathway delete worked');

          this.props.navigate(-1)
          // this.props.navigate("/organizations/" + this.state.org + "/pathways")

        } else {
          console.error('there was an error deleting the pathway');
          this.setState({ errorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          this.setState({ errorMessage: error.toString() })
      });
    }

    saveFile(category, passedFile, pathwayId) {
      console.log('saveFile called', category, passedFile, pathwayId)

      const fileName = passedFile.name
      let originalName = category + '|' + pathwayId + '|' + fileName + '|' + new Date()

      let fileData = new FormData();
      // const fileName = 'profileImage'
      // const fileName = 'newFile'
      fileData.append('baseFileName', passedFile, originalName)

      fetch("/api/file-upload", {
          mode: 'no-cors',
          method: "POST",
          body: fileData
      }).then(function (res) {
        console.log('what is the logo response');
        if (res.ok) {

          const serverPostSuccess = true
          const serverSuccessMessage = category.charAt(0).toUpperCase() + category.slice(1) + ' saved successfully!'
          if (category === 'pathwayIcon') {
            this.setState({ serverPostSuccess, serverSuccessMessage, pathwayIconFile: passedFile })
          }

          const self = this

          res.json()
          .then(function(data) {
            console.log('show data: ', data)
            let newFilePath = data.filePath
            console.log('show filePath: ', newFilePath)

            let existingFilePath = null
            if (category === 'pathwayIcon') {
              existingFilePath = self.state.pathwayIconPath
            }

            // remove existing file
            if (existingFilePath) {
              const deleteArray = existingFilePath.split("amazonaws.com/")
              console.log('show deleteArrary: ', deleteArray)
              const deleteKey = deleteArray[1].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
              console.log('show deleteKey: ', deleteKey)

              Axios.put('/api/file', { deleteKey })
              .then((response) => {
                console.log('tried to delete', response.data)
                if (response.data.success) {
                  //save values
                  console.log('File delete worked');

                  if (category === 'pathwayIcon') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully', pathwayIconPath: newFilePath
                    })
                  }

                } else {
                  console.error('there was an error saving the file');
                  self.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: response.data.message,
                  })
                }
              }).catch((error) => {
                  console.log('The saving did not work', error);
                  self.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: error,
                  })
              });
            }
          })

        } else if (res.status === 401) {
          //unauthorized
          this.setState({
              serverPostSuccess: false,
              serverErrorMessage: 'There was an error saving logo: Unauthorized save.'
          })
        }
      }.bind(this), function (e) {
        //there was an error
        this.setState({
            serverPostSuccess: false,
            serverErrorMessage: 'There was an error saving logo:' + e
        })
      }.bind(this));
    }

    render() {

      return (
          <div>
              <div className={(window.location.pathname.includes('/walkthrough')) ? "" : "standard-container-2"}>
                <div>
                  <div className="spacer"/><div className="spacer"/>

                  <div>
                    <div className="calc-column-offset-50">
                      <p className="heading-text-2">{(this.state._id) ? "Edit Pathway" : "Create a Pathway"}</p>
                    </div>
                    <div className="fixed-column-50">
                      {(this.state.orgLogo && !window.location.pathname.includes('/organizations') && this.state.org !== 'guidedcompass') && (
                        <img src={this.state.orgLogo} alt="GC" className="image-auto-40" />
                      )}
                    </div>
                    <div className="clear" />
                    {(!window.location.pathname.includes('/organizations') && this.state.org !== 'guidedcompass') && (
                      <div>
                        {(this.state.orgName) && (
                          <p className="description-text-2">for {this.state.orgName}</p>
                        )}
                      </div>
                    )}
                  </div>

                  {(window.location.pathname.includes('/organizations')) ? (
                  <p className="profile-descriptor">Design and save pathways for career-seekers.</p>
                  ) : (
                    <div className="row-15">
                      <p className="description-text-1">Design and save pathways for career-seekers.</p>
                    </div>
                  )}

                  {(this.props.duplicate) && (
                    <div className="row-10">
                      <p className="description-text-2 error-color bold-text">This is a duplicated posting. Saving this will create a new posting.</p>
                    </div>
                  )}

                </div>

                <div className="row-10">
                  <div className="container-left">
                    <p className="profile-label">Add an icon for this pathway (Optional)</p>
                    <div className="relative-position">
                      <label for="pathwayIcon" className="profile-pic-button">
                        <img src={ this.state.pathwayIconImage ? ( this.state.pathwayIconImage )
                          : this.state.pathwayIconURI ? ( this.state.pathwayIconURI )
                          : ( pathsIconDark)}
                        alt="GC" for="pathwayIcon" className="logo-image-largish"/>
                        <div className="bottom-right-overlay">
                          <div className="bottom-right-subcontainer">
                            <img src={addIconBlue} alt="GC" className="image-auto-18"/>
                          </div>
                          <div className="clear" />
                        </div>
                      </label>
                      <input type="file" id="pathwayIcon" name="pathwayIcon" onChange={this.formChangeHandler} accept="image/*" />
                    </div>

                    <div className="clear" />

                    <div className="row-10 description-text-4 full-width">
                      <p>Preferred Dimensions: 150 x 150</p>
                    </div>

                    { (this.state.serverPostSuccess) ? (
                      <p className="success-message">{this.state.serverSuccessMessage}</p>
                    ) : (
                      <p className="error-message">{this.state.serverErrorMessage}</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="row-10">
                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Pathway Title <label className="error-color bold-text">*</label></label>
                      <input type="text" className="text-field" placeholder="Title your pathway..." name="title" value={this.state.title} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Example Job Title <label className="error-color bold-text">*</label></label>
                      <input type="text" className="text-field" placeholder="Software Developer..." name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-10">
                    <div className="container-left">
                        <label className="profile-label">Work Function <label className="error-color bold-text">*</label></label>
                        <select name="jobFunction" value={this.state.jobFunction} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.functionOptions.map(value =>
                            <option key={value} value={value}>{value}</option>
                          )}
                        </select>
                    </div>
                    <div className="container-right">
                        <label className="profile-label">Industry<label className="error-color">*</label></label>
                        <select name="industry" value={this.state.industry} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.industryOptions.map(value =>
                            <option key={value} value={value}>{value}</option>
                          )}
                        </select>
                    </div>
                    <div className="clear"/>
                  </div>

                  <div className="row-10">
                    <p className="profile-label">Description<label className="error-color">*</label></p>
                    <textarea className="text-field" type="text" placeholder="Description..." name={"description"} value={this.state.description} onChange={this.formChangeHandler} />
                  </div>

                  <div className="row-10">
                    <p className="profile-label">Overall Ideal Candidate (Benchmark)<label className="error-color">*</label></p>
                    <select name="selectedBenchmark" value={this.state.selectedBenchmark.title} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.benchmarkOptions.map(value =>
                        <option key={value.title} value={value.title}>{value.title}</option>
                      )}
                    </select>
                  </div>

                  <div className="row-10">
                    <p className="description-text-color top-padding">Link to Full Documentation (Optional)</p>
                    <p className="description-text-3 bottom-margin">Documentation includes all supporting data and details on the career pathway</p>
                    <input type="text" className="adjacent-text-field" placeholder="Http..." name={"documentation"} value={this.state.documentation} onChange={this.formChangeHandler}></input>
                    {(this.state.documentation && this.state.documentation !== '' && !this.state.documentation.startsWith('http')) && (
                      <div>
                        <p className="error-message">Please start your link with http</p>
                      </div>
                    )}
                  </div>

                  <div className="row-10">
                    <div className="float-left">
                      <p className="profile-label">Stats (Overview)</p>
                    </div>
                    <div className="float-left left-padding top-padding">
                      <button className="background-button" onClick={() => this.addItem('stat')}>
                        <div className="circle-radius cta-border padding-5">
                          <img src={addIcon} alt="GC" className="image-auto-10" />
                        </div>
                      </button>
                    </div>
                    <div className="clear" />
                    <p className="description-text-1">Relevant stats that demonstrate a need for the pathway</p>

                    <div className="spacer" />

                    {(this.state.stats && this.state.stats.length > 0) && (
                      <div>
                        {this.state.stats.map((value, optionIndex) =>
                          <div key={"stat|" + optionIndex} className="top-margin bottom-margin">
                            <div className="top-margin">
                              <div className="fixed-column-30 top-padding">
                                <button className="background-button" onClick={() => this.removeItem('stat', optionIndex)}>
                                  <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                                </button>
                              </div>
                              <div className="calc-column-offset-30">
                                <div className="container-left">
                                  <input type="text" className="text-field" placeholder="Name of stat" name={"stat|name|" + optionIndex} value={value.name} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="container-right">
                                  <input type="text" className="text-field" placeholder="Value of stat" name={"stat|value|" + optionIndex} value={value.value} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="clear" />
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="clear"/>
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    )}
                  </div>

                  <div className="row-10">
                    <div className="float-left">
                      <p className="profile-label">Videos</p>
                    </div>
                    <div className="float-left left-padding top-padding">
                      <button className="background-button" onClick={() => this.addItem('video')}>
                        <div className="circle-radius cta-border padding-5">
                          <img src={addIcon} alt="GC" className="image-auto-10" />
                        </div>
                      </button>
                    </div>
                    <div className="clear" />
                    <p className="description-text-1">Day in the life of this profession/industry, interview tips, and relevant skills.</p>

                    <div className="spacer" />

                    {(this.state.videos && this.state.videos.length > 0) && (
                      <div>
                        {this.state.videos.map((value, optionIndex) =>
                          <div key={"video|" + optionIndex} className="top-margin bottom-margin">

                            <div className="top-margin">
                              <div className="fixed-column-30 top-padding">
                                <button className="background-button" onClick={() => this.removeItem('video', optionIndex)}>
                                  <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                                </button>
                              </div>
                              <div className="calc-column-offset-30">
                                <input type="text" className="adjacent-text-field" placeholder="Http..." name={"video|" + optionIndex} value={value} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="clear" />
                            </div>

                            {(value && value !== '' && !value.startsWith('http')) && (
                              <div>
                                <p className="error-message">Please start your link with http</p>
                              </div>
                            )}

                            <div>
                              <div className="video-container-4">
                                <YouTube
                                  videoId={this.configureVideo(value)}
                                  id={value._id}
                                  className="video-iframe-4"
                                  containerClassName={""}
                                  opts={(e) => this.videoCallback(e,'opts')}
                                  onReady={(e) => this.videoCallback(e,'onReady')}
                                  onPlay={(e) => this.videoCallback(e,'onPlay')}
                                  onPause={(e) => this.videoCallback(e,'onPause')}
                                  onEnd={(e) => this.videoCallback(e,'onEnd')}
                                  onError={(e) => this.videoCallback(e,'onError')}
                                  onStateChange={(e) => this.videoCallback(e,'onStateChange')}
                                  onPlaybackRateChange={(e) => this.videoCallback(e,'onPlaybackRateChange')}
                                  onPlaybackQualityChange={(e) => this.videoCallback(e,'onPlaybackQualityChange')}
                                />
                              </div>
                              <div className="clear"/>

                            </div>
                            <div className="clear"/>
                            <div className="super-spacer" /><div className="super-spacer" />
                            <div className="super-spacer" /><div className="super-spacer" />
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    )}
                  </div>

                  <div className="clear" />

                  <div className="row-10">
                    <div className="float-left">
                      <p className="profile-label">Training Programs & Courses (Overview)</p>
                    </div>
                    {/*
                    <div className="float-left left-padding top-padding">
                      <button className="background-button" onClick={() => this.addItem('program')}>
                        <div className="circle-radius cta-border padding-5">
                          <img src={addIcon} alt="GC" className="image-auto-10" />
                        </div>
                      </button>
                    </div>*/}
                    <div className="clear" />
                    <p className="description-text-1">Programs that serve this pathway</p>

                    <div className="spacer" />
                    {/*<label className="profile-label">Work Function <label className="error-color bold-text">*</label></label>*/}
                    <div>
                      <div className="calc-column-offset-80 right-padding">
                        <select name="courseName" value={this.state.courseName} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.courseOptions.map(value =>
                            <option key={(value.name) ? value.name + ' from ' + value.schoolName : ''} value={(value.name) ? value.name + ' from ' + value.schoolName : ''}>{(value.name) ? value.name + ' from ' + value.schoolName : ''}</option>
                          )}
                        </select>
                      </div>
                      <div className="fixed-column-80">
                        <button className="btn btn-squarish" onClick={() => this.addItem('course',this.state.courseName)}>Add</button>
                      </div>
                      <div className="clear" />
                    </div>

                    {this.renderTags('course')}

                    <div className="clear" />

                    {/*
                    {(this.state.programs && this.state.programs.length > 0) && (
                      <div>
                        {this.state.programs.map((value, optionIndex) =>
                          <div key={"program|" + optionIndex} className="top-margin bottom-margin">
                            <div className="top-margin standard-border padding-20">
                              <div className="fixed-column-30 top-padding">
                                <button className="background-button" onClick={() => this.removeItem('program', optionIndex)}>
                                  <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                                </button>
                              </div>
                              <div className="calc-column-offset-60 height-30">
                                <p className="full-width center-text heading-text-5">{(value.name) ? value.name : "Training Program #" + (optionIndex + 1)}</p>
                              </div>
                              <div className="fixed-column-30 height-30">
                              </div>
                              <div className="clear" />

                              <div className="row-10">
                                <div className="container-left">
                                  <label className="profile-label">Program Name</label>
                                  <input type="text" className="text-field" placeholder="(e.g., Harvard University)" name={"program|name|" + optionIndex} value={value.name} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="container-right">
                                  <label className="profile-label">Link to Program (URL)</label>
                                  <input type="text" className="text-field" placeholder="https://pll.harvard.edu/course/cs50s-introduction-game-development?delta=0" name={"program|programURL|" + optionIndex} value={value.programURL} onChange={this.formChangeHandler}></input>
                                  {(value.programURL && value.programURL !== '' && !value.programURL.startsWith('http')) && (
                                    <div className="top-padding-5">
                                      <p className="error-color description-text-2">Please enter a valid website link that starts with http</p>
                                    </div>
                                  )}
                                </div>
                                <div className='clear' />
                              </div>

                              <div className="row-10">
                                <div className="container-left">
                                  <label className="profile-label">Program/Course Provider Name</label>
                                  <input type="text" className="text-field" placeholder="(e.g., Harvard University)" name={"program|schoolName|" + optionIndex} value={value.schoolName} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="container-right">
                                  <label className="profile-label">Link to Program/Course Provider (URL)</label>
                                  <input type="text" className="text-field" placeholder="https://pll.harvard.edu/course/cs50s-introduction-game-development?delta=0" name={"program|schoolURL|" + optionIndex} value={value.schoolURL} onChange={this.formChangeHandler}></input>
                                  {(value.schoolURL && value.schoolURL !== '' && !value.schoolURL.startsWith('http')) && (
                                    <div className="top-padding-5">
                                      <p className="error-color description-text-2">Please enter a valid website link that starts with http</p>
                                    </div>
                                  )}
                                </div>
                                <div className='clear' />
                              </div>

                              <div className="row-10">
                                <div className="container-left">
                                  <label className="profile-label">Degree Type</label>
                                  <select name={"program|degreeType|" + optionIndex} value={value.degreeType} onChange={this.formChangeHandler} className="dropdown">
                                    {this.state.degreeOptions.map(value =>
                                      <option key={value.key} value={value}>{value}</option>
                                    )}
                                  </select>
                                </div>
                                <div className="container-right">
                                  <label className="profile-label">Virtual vs. In Person</label>
                                  <select name={"program|programMethod|" + optionIndex} value={value.programMethod} onChange={this.formChangeHandler} className="dropdown">
                                    {this.state.programMethodOptions.map(value =>
                                      <option key={value.key} value={value}>{value}</option>
                                    )}
                                  </select>
                                </div>
                                <div className='clear' />
                              </div>

                              <div className="row-10">
                                <div className="container-left">
                                  <label className="profile-label">Career Pathway</label>
                                  <input type="text" className="text-field" placeholder="(e.g., Software Development)" name={"program|careerPathway|" + optionIndex} value={value.careerPathway} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="container-right">
                                  <label className="profile-label">Sample Job Title Outcomes</label>
                                  <input type="text" className="text-field" placeholder="(e.g., Marketing Associate)t" name={"program|sampleJobTitles|" + optionIndex} value={value.sampleJobTitles} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className='clear' />
                              </div>

                            </div>

                            <div className="clear"/>
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    )}*/}
                  </div>

                  <div className="row-10">
                    <div className="float-left">
                      <p className="profile-label">Levels</p>
                    </div>
                    <div className="float-left left-padding top-padding">
                      <button className="background-button" onClick={() => this.addItem('level')}>
                        <div className="circle-radius cta-border padding-5">
                          <img src={addIcon} alt="GC" className="image-auto-10" />
                        </div>
                      </button>
                    </div>
                    <div className="clear" />
                    <p className="description-text-1">Increases in levels come with a bump in pay and responsibilities.</p>

                    <div className="spacer" />

                    {(this.state.levels && this.state.levels.length > 0) && (
                      <div>
                        {this.state.levels.map((value, optionIndex) =>
                          <div key={"stat|" + optionIndex}>
                            <button className="background-button" onClick={() => this.removeItem('level', optionIndex)}>
                              <img src={deniedIcon} alt="GC" className="image-auto-20" />
                            </button>
                            <div className="standard-border padding-20 top-margin-negative-20 bottom-margin left-margin">
                              <div className="row-10">
                                <div className="full-width">
                                  <div className="container-left">
                                    <label className="profile-label">Level Name</label>
                                    <input type="text" className="text-field" placeholder="(e.g., Beginner)" name={"level|name|" + optionIndex} value={value.name} onChange={this.formChangeHandler}></input>
                                  </div>
                                  <div className="clear" />
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="row-10">
                                <label className="profile-label">Ideal Candidate (Benchmark)</label>
                                <select name={"level|benchmark|" + optionIndex} value={(value.benchmark) ? value.benchmark.title : ""} onChange={this.formChangeHandler} className="dropdown">
                                  {this.state.benchmarkOptions.map(value =>
                                    <option key={value.title} value={value.title}>{value.title}</option>
                                  )}
                                </select>
                              </div>

                              <div className="row-10">
                                <div className="float-left">
                                  <p className="profile-label">Stats</p>
                                </div>
                                <div className="float-left left-padding top-padding">
                                  <button className="background-button" onClick={() => this.addItem('level|stat|' + optionIndex)}>
                                    <div className="circle-radius cta-border padding-5">
                                      <img src={addIcon} alt="GC" className="image-auto-10" />
                                    </div>
                                  </button>
                                </div>
                                <div className="clear" />

                                {(value.stats && value.stats.length > 0) && (
                                  <div>
                                    {value.stats.map((value2, optionIndex2) =>
                                      <div key={"stat|" + optionIndex} className="top-margin bottom-margin">
                                        <div className="top-margin">
                                          <div className="fixed-column-30 top-padding">
                                            <button className="background-button" onClick={() => this.removeItem('level|stat|' + optionIndex2, optionIndex)}>
                                              <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                                            </button>
                                          </div>
                                          <div className="calc-column-offset-30">
                                            <div className="container-left">
                                              <input type="text" className="text-field" placeholder="Name of stat" name={"level|stat|name|" + optionIndex + "|" + optionIndex2} value={value2.name} onChange={this.formChangeHandler}></input>
                                            </div>
                                            <div className="container-right">
                                              <input type="text" className="text-field" placeholder="Value of stat" name={"level|stat|value|" + optionIndex + "|" + optionIndex2} value={value2.value} onChange={this.formChangeHandler}></input>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                          <div className="clear" />
                                        </div>

                                        <div className="clear"/>
                                      </div>
                                    )}
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>

                              <div className="clear"/>

                              <div className="row-10">
                                <div className="float-left">
                                  <p className="profile-label">Learning Pathways</p>
                                </div>
                                <div className="float-left left-padding top-padding">
                                  <button className="background-button" onClick={() => this.addItem('level|learningPathway|' + optionIndex)}>
                                    <div className="circle-radius cta-border padding-5">
                                      <img src={addIcon} alt="GC" className="image-auto-10" />
                                    </div>
                                  </button>
                                </div>
                                <div className="clear" />

                                {(value.learningPathways && value.learningPathways.length > 0) && (
                                  <div>
                                    {value.learningPathways.map((value2, optionIndex2) =>
                                      <div key={"stat|" + optionIndex} className="top-margin bottom-margin">
                                        <div className="top-margin">
                                          <div className="fixed-column-20 z-index-1 relative-position">
                                            <button className="background-button" onClick={() => this.removeItem('level|learningPathway|' + optionIndex2, optionIndex)}>
                                              <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                                            </button>
                                          </div>
                                          <div className="calc-column-offset-20 standard-border padding-20 left-margin-negative-10 top-margin">
                                            <div className="container-left">
                                              <label className="profile-label">Name</label>
                                              <input type="text" className="text-field" placeholder="Name of learning pathway" name={"level|learningPathway|name|" + optionIndex + "|" + optionIndex2} value={value2.name} onChange={this.formChangeHandler}></input>
                                            </div>
                                            <div className="container-right">
                                              <label className="profile-label">Learning Style</label>
                                              <select name={"level|learningPathway|learningStyle|" + optionIndex + "|" + optionIndex2} value={value2.learningStyle} onChange={this.formChangeHandler} className="dropdown">
                                                {['', 'Self-Directed','Project-Focused','Competency-Focused','Broad Education'].map(value =>
                                                  <option key={value} value={value}>{value}</option>
                                                )}
                                              </select>
                                            </div>
                                            <div className="clear" />

                                            <div className="row-10">
                                              <div className="float-left">
                                                <p className="profile-label">Learning Resources</p>
                                              </div>
                                              <div className="float-left left-padding top-padding">
                                                <button className="background-button" onClick={() => this.addItem('level|learningPathway|resource|' + optionIndex + '|' + optionIndex2)}>
                                                  <div className="circle-radius cta-border padding-5">
                                                    <img src={addIcon} alt="GC" className="image-auto-10" />
                                                  </div>
                                                </button>
                                              </div>
                                              <div className="clear" />

                                              {(value2.resources && value2.resources.length > 0) && (
                                                <div>
                                                  {value2.resources.map((value3, optionIndex3) =>
                                                    <div key={"stat|" + optionIndex} className="top-margin bottom-margin">
                                                      <div className="top-margin">
                                                        <div className="fixed-column-30 top-padding">
                                                          <button className="background-button" onClick={() => this.removeItem('level|learningPathway|resource|' + optionIndex2 + '|' + optionIndex3, optionIndex)}>
                                                            <img src={deniedIcon} alt="GC" className="image-auto-20" />
                                                          </button>
                                                        </div>
                                                        <div className="calc-column-offset-30">
                                                          <div className="container-left">
                                                            <select name={"level|learningPathway|resource|category|" + optionIndex + "|" + optionIndex2 + "|" + optionIndex3} value={value3.category} onChange={this.formChangeHandler} className="dropdown">
                                                              {['','Training Program','Course','Project','Event'].map(value =>
                                                                <option key={value} value={value}>{value}</option>
                                                              )}
                                                            </select>
                                                          </div>
                                                          {(value3.category) && (
                                                            <div className="container-right">
                                                              {(value3.category === 'Training Program') && (
                                                                <select name={"level|learningPathway|resource|name|" + optionIndex + "|" + optionIndex2 + "|" + optionIndex3} value={value3.name} onChange={this.formChangeHandler} className="dropdown">
                                                                  {this.state.trainingProgramOptions.map(value =>
                                                                    <option key={(value.name) ? value.name + ' from ' + value.schoolName : ''} value={(value.name) ? value.name + ' from ' + value.schoolName : ''}>{(value.name) ? value.name + ' from ' + value.schoolName : ''}</option>
                                                                  )}
                                                                </select>
                                                              )}
                                                              {(value3.category === 'Course') && (
                                                                <select name={"level|learningPathway|resource|name|" + optionIndex + "|" + optionIndex2 + "|" + optionIndex3} value={value3.name} onChange={this.formChangeHandler} className="dropdown">
                                                                  {this.state.individualCourseOptions.map(value =>
                                                                    <option key={(value.name) ? value.name + ' from ' + value.schoolName : ''} value={(value.name) ? value.name + ' from ' + value.schoolName : ''}>{(value.name) ? value.name + ' from ' + value.schoolName : ''}</option>
                                                                  )}
                                                                </select>
                                                              )}
                                                              {(value3.category === 'Project') && (
                                                                <select name={"level|learningPathway|resource|name|" + optionIndex + "|" + optionIndex2 + "|" + optionIndex3} value={value3.name} onChange={this.formChangeHandler} className="dropdown">
                                                                  {this.state.projectOptions.map(value =>
                                                                    <option key={(value.name) ? value.name + ' from ' + value.schoolName : ''} value={(value.name) ? value.name + ' from ' + value.schoolName : ''}>{(value.name) ? value.name + ' from ' + value.schoolName : ''}</option>
                                                                  )}
                                                                </select>
                                                              )}
                                                              {(value3.category === 'Event') && (
                                                                <select name={"level|learningPathway|resource|name|" + optionIndex + "|" + optionIndex2 + "|" + optionIndex3} value={value3.name} onChange={this.formChangeHandler} className="dropdown">
                                                                  {this.state.eventOptions.map(value =>
                                                                    <option key={(value.name) ? value.name + ' from ' + value.schoolName : ''} value={(value.name) ? value.name + ' from ' + value.schoolName : ''}>{(value.name) ? value.name + ' from ' + value.schoolName : ''}</option>
                                                                  )}
                                                                </select>
                                                              )}

                                                            </div>
                                                          )}
                                                          <div className="clear" />
                                                        </div>
                                                        <div className="clear" />

                                                        <div className="clear" />
                                                      </div>

                                                      <div className="clear"/>
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </div>

                                          </div>
                                          <div className="clear" />
                                        </div>

                                        <div className="clear"/>
                                      </div>
                                    )}
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                            </div>

                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    )}
                  </div>

                  <div className="row-10">
                    <div className="float-left">
                      <p className="profile-label">Checklist</p>
                    </div>
                    <div className="float-left left-padding top-padding">
                      <button className="background-button" onClick={() => this.addItem('checklist')}>
                        <div className="circle-radius cta-border padding-5">
                          <img src={addIcon} alt="GC" className="image-auto-10" />
                        </div>
                      </button>
                    </div>
                    <div className="clear" />
                    <p className="description-text-1">When career-seekers adopt this pathway, this checklist will automatically be made available to them.</p>

                    <div className="spacer" />

                    {(this.state.checklist && this.state.checklist.length > 0) && (
                      <div>
                        {this.state.checklist.map((item, index) =>
                          <div key={"checklist|" + index} className="top-margin bottom-margin">
                            <div className="fixed-column-30 top-margin">
                              <p>{index + 1}.</p>
                            </div>
                            <span className="calc-column-offset-60">
                              <div className="relative-column-33 right-padding-5">
                                <input type="text" className="text-field description-text-2" placeholder="Name this item" name={"checklist|name|" + index} value={item.name} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="relative-column-33 left-padding-5 right-padding-5">
                                <input type="text" className="text-field description-text-2" placeholder="Add a link to your item" name={"checklist|url|" + index} value={item.url} onChange={this.formChangeHandler}></input>
                                {(item.url && item.url !== '' && !item.url.startsWith('http')) && (
                                  <div className="top-padding-5">
                                    <p className="error-color description-text-2">Please add a valid link that starts with http</p>
                                  </div>
                                )}
                              </div>
                              <div className="relative-column-33 left-padding-5">
                                <select name={"checklist|category|" + index} value={item.category} onChange={this.formChangeHandler} className="white-background description-text-2">
                                  {this.state.checklistCategoryOptions.map(value =>
                                    <option key={value} value={value}>{value}</option>
                                  )}
                                </select>
                              </div>
                            </span>
                            <div className="fixed-column-30">
                              <button className="background-button top-margin" onClick={() => this.removeItem('checklist', index)}>
                                <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                              </button>
                            </div>

                            <div className="clear"/>
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    )}
                  </div>

                  {/*
                  <div className="row-10">
                    <p className="profile-label">Courses</p>
                    <div className="spacer" />

                    <div className="row-10">
                      <div className="relative-column-40 right-padding">
                        <input type="text" className="adjacent-text-field" placeholder="Course name..." name={"courseName"} value={this.state.courseName} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="relative-column-60">
                        <div className="calc-column-offset-80 right-padding">
                          <input type="text" className="adjacent-text-field" placeholder="Course website link..." name={"courseURL"} value={this.state.courseURL} onChange={this.formChangeHandler}></input>
                          {(this.state.courseURL && this.state.courseURL !== '' && !this.state.courseURL.startsWith('http')) && (
                            <div className="top-padding-5">
                              <p className="error-color description-text-2">Please enter a valid website link that starts with http</p>
                            </div>
                          )}
                        </div>
                        <div className="fixed-column-80">
                          <button className="btn btn-squarish" onClick={() => this.addItem('course')}>Add</button>
                        </div>
                        <div className="clear" />
                      </div>
                      <div className="clear" />

                      {this.renderTags('course')}
                      <div className="clear" />

                    </div>
                  </div>*/}

                  {/*
                  <div className="row-10">
                    <p className="profile-label">Members</p>
                  </div>*/}

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label clear-padding">Is Visible?</label>
                      <label className="profile-descriptor">Make this pathway {(this.state.isActive) ? "invisible" : "visible"} to career-seekers</label>
                      <div className="clear" />
                      <div className="spacer" /><div className="half-spacer"/>
                      <Switch
                        onChange={(change) => this.setState({ isActive: change, formHasChanged: true })}
                        checked={this.state.isActive}
                        id="normal-switch"
                      />
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                  {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                  {(this.state.confirmDelete === true) ? (
                    <div>
                      <div>
                        <p className="error-message">Are you sure you want to delete this pathway?</p>
                      </div>
                      <div className="clear" />
                      <div className="float-left right-padding-20">
                        <button className="btn btn-quaternary" onClick={() => this.deleteItem()}>Yes, Delete</button>
                      </div>
                      <div className="float-left right-padding-20">
                        <button className="btn btn-secondary" onClick={() => this.setState({ confirmDelete: false })}>Cancel</button>
                      </div>
                      <div className="clear" />
                    </div>
                  ) : (
                    <div className="row-10">
                      <div className="float-left right-padding-20">
                        <button className="btn btn-primary"  disabled={this.state.isSaving} onClick={() => this.saveChanges()}>{(this.state._id) ? "Save Changes" : "Create Pathway"}</button>
                      </div>
                      {(this.state._id) && (
                        <div className="float-left right-padding-20">
                          <button className="btn btn-quaternary"  disabled={this.state.isSaving} onClick={() => this.setState({ confirmDelete: true })}>Delete Pathway</button>
                        </div>
                      )}

                      <div className="clear" />
                    </div>
                  )}

                </div>

              </div>
              {/*
              <div className="standard-container-2 clear-padding">
                <div className="carousel" onScroll={this.handleScroll}>
                  {this.state.subNavCategories.map((value, index) =>
                    <div className="carousel-item-container">
                      {(value === this.state.subNavSelected) ? (
                        <div className="selected-carousel-item">
                          <label key={value}>{value}</label>
                        </div>
                      ) : (
                        <button className="menu-button" onClick={() => this.setState({ subNavSelected: value })}>
                          <label key={value}>{value}</label>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {((this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Videos')) && (
                <div className="standard-container-2">
                  <div className="row-10">
                    <div className="float-left">
                      <p className="heading-text-3">Videos</p>
                    </div>
                    <div className="float-left left-padding top-padding-5">
                      <button className="background-button" onClick={() => this.addItem('video')}>
                        <div className="circle-radius cta-border padding-5">
                          <img src={addIcon} alt="GC" className="image-auto-10" />
                        </div>
                      </button>
                    </div>
                    <div className="clear" />
                    <p className="description-text-1">Day in the life of this profession/industry, interview tips, and relevant skills.</p>

                    <div className="spacer" />

                    {(this.state.videos && this.state.videos.length > 0) && (
                      <div>
                        {this.state.videos.map((value, optionIndex) =>
                          <div key={"video|" + optionIndex} className="top-margin bottom-margin">
                            <div className="fixed-column-30 top-padding">
                              <button className="background-button" onClick={() => this.removeItem('video', optionIndex)}>
                                <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                              </button>
                            </div>
                            <div className="calc-column-offset-30">
                              <input type="text" className="adjacent-text-field" placeholder="Http..." name={"video|" + optionIndex} value={value} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="clear" />

                            {(value && value !== '' && !value.startsWith('http')) && (
                              <div>
                                <p className="error-message">Please start your link with http</p>
                              </div>
                            )}

                            <div className="top-margin-20">
                              <div className="video-container-4">
                                <YouTube
                                  videoId={value}
                                  id={value._id}
                                  className="video-iframe-4"
                                  containerClassName={""}
                                  opts={(e) => this.videoCallback(e,'opts')}
                                  onReady={(e) => this.videoCallback(e,'onReady')}
                                  onPlay={(e) => this.videoCallback(e,'onPlay')}
                                  onPause={(e) => this.videoCallback(e,'onPause')}
                                  onEnd={(e) => this.videoCallback(e,'onEnd')}
                                  onError={(e) => this.videoCallback(e,'onError')}
                                  onStateChange={(e) => this.videoCallback(e,'onStateChange')}
                                  onPlaybackRateChange={(e) => this.videoCallback(e,'onPlaybackRateChange')}
                                  onPlaybackQualityChange={(e) => this.videoCallback(e,'onPlaybackQualityChange')}
                                />
                              </div>
                              <div className="clear"/>

                            </div>
                            <div className="clear"/>
                            <div className="super-spacer" /><div className="super-spacer" />
                            <div className="super-spacer" /><div className="spacer" />
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    )}
                  </div>

                  <div className="clear" />

                </div>
              )}

              {((this.state.subNavSelected === 'All' || this.state.subNavSelected === 'About')) && (
                <div className="standard-container-2">
                  <div className="row-10">
                    <div className="float-left">
                      <p className="heading-text-3">About</p>
                    </div>
                    <div className="float-left left-padding top-padding-5">
                      <button className="background-button" onClick={() => this.addItem('video')}>
                        <div className="circle-radius cta-border padding-5">
                          <img src={addIcon} alt="GC" className="image-auto-10" />
                        </div>
                      </button>
                    </div>
                    <div className="clear" />
                    <p className="description-text-1">Day in the life of this profession/industry, interview tips, and relevant skills.</p>

                    <div className="spacer" />

                    <p>description</p>
                  </div>
                </div>
              )}*/}

          </div>

      )
    }
}

export default withRouter(EditPathway);
