import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubAddWorkspaces from './Subcomponents/AddWorkspaces';
import {bootUser} from './Functions/BootUser';
// import {toggleVars} from './Functions/ToggleVars';
import withRouter from './Functions/WithRouter';

class AddWorkspaces extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.passOrgs = this.passOrgs.bind(this)
      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('opportunity mounted')

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');

      let username = localStorage.getItem('username');
      const orgLogo = localStorage.getItem('orgLogo');

      let passedOppId = null
      let activeOrg = null
      if (this.props.params) {
        let { orgCode, opportunityId } = this.props.params
        passedOppId = opportunityId
        activeOrg = orgCode
      }

      this.setState({ email, username, orgLogo, activeOrg, opportunityId: passedOppId, orgCode: activeOrg })
    }

    passOrgs(activeOrg, myOrgs) {
      console.log('passOrgs called', activeOrg, myOrgs )

      if (myOrgs) {
        this.setState({ activeOrg, myOrgs })
      } else {
        this.setState({ activeOrg })
      }
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    render() {

      return (
        <div>
          <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} myOrgs={this.state.myOrgs}  loadWorkspace={this.loadWorkspace} history={this.props.navigate}/>

          <div>
            <SubAddWorkspaces opportunityId={this.state.opportunityId} orgCode={this.state.orgCode} history={this.props.navigate} passOrgs={this.passOrgs}/>
          </div>

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}

        </div>
      )
    }
}

export default withRouter(AddWorkspaces);
