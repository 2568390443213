import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubEditCourseLesson from '../Subcomponents/EditCourseLesson';
import withRouter from '../Functions/WithRouter';

class OrgEditCourseLesson extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      // let roleName = localStorage.getItem('roleName');
      // let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let orgLogo = localStorage.getItem('orgLogo');

      let org = null
      // let courseName = null
      if (this.props.params) {
        org = this.props.params.org
        // courseName = this.props.params.name
      }

      let courseId = this.state.courseId
      let learningModules = this.state.learningModules
      let selectedIndex = null
      let selectedIndex2 = null
      if (this.props.location && this.props.location.state) {
        courseId = this.props.location.state.courseId
        learningModules = this.props.location.state.learningModules
        selectedIndex = this.props.location.state.selectedIndex
        selectedIndex2 = this.props.location.state.selectedIndex2
      }

      this.setState({
        emailId: email, org, orgFocus, orgLogo,
        courseId, learningModules, selectedIndex, selectedIndex2
      });
    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
          <SubEditCourseLesson org={this.state.org} courseId={this.state.courseId} learningModules={this.state.learningModules} selectedIndex={this.state.selectedIndex} selectedIndex2={this.state.selectedIndex2} />

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(OrgEditCourseLesson)
