import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEditCourse from './Subcomponents/EditCourse';
import withRouter from './Functions/WithRouter';

class AdvEditCourse extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('just mounted daddy log in adv')

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      let courseId = null
      if (this.props.params) {
        courseId = this.props.params.id
      }

      this.setState({ activeOrg, emailId: email, username, orgFocus, roleName, orgLogo,
        courseId
      });
    }

    render() {

      return (
          <div>
              <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

              <SubEditCourse courseId={this.state.courseId} selectedCourse={this.state.selectedCourse} orgCode={this.state.activeOrg} />

              {/*
              <EditCourse closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedCourse={this.state.selectedCourse}
                orgCode={this.state.org} orgName={this.state.orgName} gradeLevelOptions={this.state.gradeLevelOptions}
                knowledgeLevelOptions={this.state.knowledgeLevelOptions} industryOptions={this.state.industryOptions}
                functionOptions={this.state.functionOptions} addNewCourse={this.addNewCourse} updateCourse={this.updateCourse}
                courseOptions={this.state.courses} history={this.props.navigate}
              />*/}

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
          </div>

      )
    }
}

export default withRouter(AdvEditCourse);
