import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubChangeEmail from './Subcomponents/ChangeEmail';
import withRouter from './Functions/WithRouter';

class EmpChangeEmail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            emailId: null,
            username: '',

        }

        this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { emp } = this.props.params

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');

      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      let orgMode = true
      if (!activeOrg || activeOrg === '') {
        orgMode = false
      }

      if (email) {

        this.setState({ emailId: email, username, activeOrg, orgLogo, orgMode, emp });

      }
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {

      return (
        <div>
          <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />
          <SubChangeEmail email={this.state.emailId} accountCode={this.state.emp} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}


export default withRouter(EmpChangeEmail)
